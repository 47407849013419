import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-successful-name-change',
  templateUrl: './successful-name-change.component.html',
  styleUrls: ['./successful-name-change.component.css']
})
export class SuccessfulNameChangeComponent implements OnInit{

  @Input() oldFullName: string;
  @Input() newFullName: string;
  @Input() motive: string;
  @Input() date: Date;
  @Output() backClicked = new EventEmitter<void>();

  passengerForm: FormGroup;

  ngOnInit(): void {
    console.log(this.onBackClick);
  }

  onBackClick() {
    this.backClicked.emit();
  }
}

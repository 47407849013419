import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import swal from 'sweetalert2';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';

@Component({
  selector: 'app-status-ticket-search',
  templateUrl: './status-ticket-search.component.html',
  styleUrls: ['./status-ticket-search.component.css']
})
export class StatusTicketSearchComponent implements OnInit {

  maxDate: Date;
  minDate: Date;

  fechaMinima: Date = new Date();
  fechaMaxima: Date = new Date();
  radioButton = 1;
  fileName = '';
  searchTicketForm1: UntypedFormGroup;
  searchTicketForm3: UntypedFormGroup;
  private sidebarMenu = new SidebarMenuModel(SidebarMenuModel.MENU_STATUSTICKET, SidebarMenuModel.MENU_STATUSTICKET);
  searchTicketForm2: UntypedFormGroup;
  isLoadingButton = false;
  searchByTicketNumber = false;
  ticketNumber = 0;
  ticketDate: String;
  constructor(private fb: UntypedFormBuilder, private translateService: TranslateService, private sidebarShareDataService: SidebarShareDataService) { }

  ngOnInit(): void {
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.buscarAcuerdoForm();
    /* MCPMLT-4497 Aca se crea un objeto Date y se le resta automaticamente 6 meses, esto funciona en caso de que pase el año, pasaria de ejemplo: 01-01-2023 al 01-06-2022 como fecha minima */
    let e = new Date();
    e.setMonth(e.getMonth()-12)
    this.minDate = new Date (e.getFullYear(), (e.getMonth()), e.getDate());
    this.maxDate = new Date (new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  }

  radioChange(event) {
    this.resetSearchType();
    if (event.value == 1) {
      this.radioButton = 1;
    }
    if (event.value == 2) {
      this.radioButton = 2;
    }
    if (event.value == 3) {
      this.radioButton = 3;
    }
  }

  resetSearchType() {
    this.searchByTicketNumber = false;
  }

  filterTickets() {
    this.resetSearchType();
    switch (this.radioButton) {
      case 1: {
        this.searchByTicketNumber = true;
        this.ticketNumber = this.searchTicketForm1.value.ticketNumber;
        this.ticketDate = this.searchTicketForm1.value.ticketDate._i.date +"-"+ (this.searchTicketForm1.value.ticketDate._i.month+1) +"-"+this.searchTicketForm1.value.ticketDate._i.year;
        break;
      }
      case 2: {
        this.swalertInfo(this.translateService.instant('statusTicket.Error.alertInactiveFlow.title'), this.translateService.instant('statusTicket.Error.alertInactiveFlow.message'));
        break;
      }
      case 3: {
        this.swalertInfo(this.translateService.instant('statusTicket.Error.alertInactiveFlow.title'), this.translateService.instant('statusTicket.Error.alertInactiveFlow.message'));
        break;
      }
      default: {
        //statements;
        break;
      }
    }
    this.buscarAcuerdoForm();
  }

  checkFileName(): string {
    this.fileName = '';
    let file = this.searchTicketForm2.value.file;
    if (file != null) {
      let arrayName = file.split('\\');
      this.fileName = arrayName[arrayName.length - 1];
    }
    return this.fileName;
  }

  dateFormat(type: string, event: MatDatepickerInputEvent<Date>) {
    //let dateInput = this.datepipe.transform(event.value, 'yyyy-MM-dd') + 'T00:00:00';
    if (type === 'from') {
      //this.searchTicketForm3.get('from').setValue(dateInput);
    }
    if (type === 'until') {
      //this.searchTicketForm3.get('until').setValue(dateInput);
    }
  }


  buscarAcuerdoForm() {
    this.searchTicketForm1 = this.fb.group({
      ticketNumber: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      ticketDate: new UntypedFormControl('', [Validators.required])
    });

    this.searchTicketForm2 = this.fb.group({
      file: new UntypedFormControl(null, [Validators.required, this.requiredFileType('csv')])
    });

    this.searchTicketForm3 = this.fb.group({
      from: new UntypedFormControl('', Validators.required),
      until: new UntypedFormControl('', Validators.required)
    });
  }

  requiredFileType(type: string) {
    return function (control: UntypedFormControl) {
      const file = control.value;
      if (file) {
        let arrayName = file.split('.');
        const extension = arrayName[arrayName.length - 1].toLowerCase();
        if (type.toLowerCase() !== extension.toLowerCase()) {
          control.setValue(null);
          return {
            requiredFileType: true
          };
        }

        return null;
      }

      return null;
    };
  }

  swalertInfo(title: string, message: string) {
    swal.fire({
      title: '<p>' + title + '</p>',
      html: '<p>' + message + '</p>',
      confirmButtonText: this.translateService.instant('button.confirm'),
      confirmButtonColor: '#d33',
      icon: 'info',
      allowOutsideClick: false
    });
  }
}

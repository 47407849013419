import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { SessionInfo } from '../../models/SessionInfo.model';
import { AgencyGroup } from '../../models/agency-group.model';
import { Profile } from '../../models/profile.model';
import { AgencyGroupService } from '../../services/agency-group.service';
import { BudgetService } from '../../services/budget.service';
import { ProfilingService } from '../../services/profiling.service';
import { SessionInfoService } from '../../services/session-info.service';

@Component({
  selector: 'app-list-waiver-agent-budget',
  templateUrl: './list-waiver-agent-budget.component.html',
  styleUrls: ['./list-waiver-agent-budget.component.css']
})
export class ListWaiverAgentBudgetComponent implements OnInit, OnDestroy{
  showAgencyGroupAmount = false;
  amountAvailableGroup: string = '';
  agencyGroupAmount: AgencyGroup = null;

  infoUser: SessionInfo = new SessionInfo();
  profiles = new Profile();
  timer: any;
  timerSubscription: any;

  constructor(private profilesService: ProfilingService,
    private sessionInfoService: SessionInfoService,
    private agencyGroupService: AgencyGroupService,
    private budgetService: BudgetService){

  }

  ngOnInit(): void {
    this.profiles = this.profilesService.getProfile();
    this.timer = timer(0, 30000);

    this.sessionInfoService.getSessionInfoAsPromise()
      .then(
        sessionInfo => {
          this.infoUser = sessionInfo;
          this.timerSubscription = this.timer.subscribe(
            () => {
              // Falta ppto de sucursal
              this.getAmountAvailableAgencyGroup(this.infoUser.matrix_id);
            }
          );
        }
      )
      .catch(
        error => {
          console.log('error', error);
        }
      );
  }

  /**
   * Metodo que permite que no se ejecute el timer
   */
  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  /**
   * Este metodo permite obtener el valor del saldo disponible del
   * grupo
   * @param agencyGroupId
   */
  private getAmountAvailableAgencyGroup(agencyGroupId: number) {
    this.agencyGroupService.getAgencyGroupById(agencyGroupId).subscribe({
      next: data => {
        this.agencyGroupAmount = data;
        if (this.agencyGroupAmount?.showBudget) {
          this.getAmountAvailable(this.agencyGroupAmount.id);
        } else {
          this.showAgencyGroupAmount = false;
        }
      },
      error: (error: HttpErrorResponse) => {
        this.showAgencyGroupAmount = false;
        console.log('error getGroupAgencyByUserInfo', error);
      }
  });
  }

  /**
   * Metodo que calcula el saldo del grupo al que pertenece la agencia
   * del usuario
   * @param agencyGroupId
   */
  private getAmountAvailable(agencyGroupId) {
    this.budgetService.getMonthlyAfterSalesBudgetByGroup(agencyGroupId).subscribe({
      next: data => {
        this.amountAvailableGroup = (data.assignedAmount - data.busyAmount).toString();
        this.showAgencyGroupAmount = true;
      },
      error: (error: HttpErrorResponse) => {
        this.amountAvailableGroup = '0';
        console.log('error getAmountAvailable', error);
      }
  });
  }

}

import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'tooltipBudgetTable',
  pure: false
})
export class TooltipBudgetTablePipe implements PipeTransform {
  transform(value: number): string {
    const language = localStorage.getItem('language');

    if (language === 'es') {
      return this.getSpanishTranslation(value);
    } else if (language === 'en') {
      return this.getEnglishTranslation(value);
    } else if (language === 'fr') {
      return this.getFrenchTranslation(value);
    } else if (language === 'it') {
      return this.getItalianTranslation(value);
    } else if (language === 'de') {
      return this.getGermanTranslation(value);
    } else if (language === 'pt') {
      return this.getPortugueseTranslation(value);
    } else {
      return this.getEnglishTranslation(value);
    }
  }

  getSpanishTranslation(value: number): string {
    switch (value) {
      case 0: return 'Monto transferido de meses anteriores';
      case 1: return 'Monto cargado para consumo de Waivers del POS';
      case 2: return 'Monto permitido para distribuir entre Grupos AGV del POS';
      case 3: return 'Monto utilizado en solicitudes de Waivers del POS';
      case 4: return 'Monto disponible del POS';
      default: return 'Información Adicional';
    }
  }

  getEnglishTranslation(value: number): string {
    switch (value) {
      case 0: return 'Transferred amount from previous months';
      case 1: return 'Amount carged for market waiver expenses';
      case 2: return 'Amount allowed to distribute between AGV Groups of market';
      case 3: return 'Amount used in Waivers requests from the market';
      case 4: return 'Amount available from the market';
      default: return 'Additional Information';
    }
  }

  getFrenchTranslation(value: number): string {
    switch (value) {
      case 0: return 'Montant transféré des mois précédents';
      case 1: return 'Montant facturé pour la consommation de Waivers du POS';
      case 2: return 'Montant autorisé à répartir entre les groupes AGV du POS';
      case 3: return 'Quantité utilisée dans Waivers du POS';
      case 4: return 'Montant disponible au POS';
      default: return 'Informations Complémentaires';
    }
  }

  getItalianTranslation(value: number): string {
    switch (value) {
      case 0: return 'Importo trasferito dai mesi precedenti';
      case 1: return 'Importo addebitato per il consumo di Waivers del POS';
      case 2: return 'Quantità consentita per la distribuzione tra i gruppi AGV POS';
      case 3: return 'Quantità utilizzata nelle richieste di Waivers dal POS';
      case 4: return 'Quantità di Waivers disponibile';
      default: return 'Informazioni Aggiuntive';
    }
  }

  getGermanTranslation(value: number): string {
    switch (value) {
      case 0: return 'Übertragener Betrag aus den vergangenen Monaten';
      case 1: return 'Betrag, der für den Verbrauch von Ausnahmen vom Markt berechnet wird';
      case 2: return 'Betrag, der auf die AGV-Gruppen des Marktes verteilt werden kann';
      case 3: return 'Betrag, der für Anträge auf Markt Verzichtserklärungen verwendet wird';
      case 4: return 'Am Markt verfügbare Menge';
      default: return 'Zusätzliche Informationen';
    }
  }

  getPortugueseTranslation(value: number): string {
    switch (value) {
      case 0: return 'Quantia transferida de meses anteriores';
      case 1: return 'Quantia cobrada pelo consumo de Waivers do POS';
      case 2: return 'Quantidade permitida para distribuir entre grupos AGV do POS';
      case 3: return 'Quantidade utilizada nos pedidos de Waivers do POS';
      case 4: return 'Quantidade disponível no mercado';
      default: return 'Informação Adicional';
    }
  }
}

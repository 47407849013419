import { HttpErrorResponse } from "@angular/common/http";
import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { SelectionModel } from "@angular/cdk/collections";
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { TranslateService } from "@ngx-translate/core";
import swal from "sweetalert2";

import {
  SystemException,
} from "../../models/Exception.model";
import { Booking } from "../../models/booking.model";
import { InfoAdvp } from "../../models/info-advp.model";
import { Motive } from "../../models/motive.model";
import { OsiInfo } from "../../models/osi-info.model";
import { Passenger } from "../../models/passenger.model";
import { Pnr } from "../../models/pnr.model";
import { Waiver } from "../../models/waiver.model";
import { WaiverType } from "../../models/waiver-type.model";
import { WaiverCategory } from "../../models/waiver-category.model";
import { PricingType } from "../../models/pricing-type.model";
import { Profile } from "../../models/profile.model";
import { Segment } from "../../models/segment.model";
import { SidebarMenuModel } from "../../models/SidebarMenu.model";
import { SolicitedValue } from "../../models/solicited-value.model";
import { WaiverPresaleType } from "../../models/waiver-presale-type.model";
import { WaiverResult } from "../../models/waiver-result.model";
import { WaiverValue } from "../../models/waiver-value.model";

import { BookingInfoComponent } from "../../components/booking-info/booking-info.component";
import { FareDiffComponent } from "./fare-diff/fare-diff.component";
import { PassengerInfoComponent } from "../../components/passenger-info/passenger-info.component";
import { RequestResultComponent } from "./request-result/request-result.component";
import { SegmentInfoComponent } from "../../components/segment-info/segment-info.component";

import { MessageService } from "./../../services/message.service";
import { ProfilingService } from "../../services/profiling.service";
import { SessionInfoService } from "../../services/session-info.service";
import { SidebarShareDataService } from "../../services/sidebar-share-data.service";
import { WaiverPresaleService } from "../../services/waiver-presale.service";
import { WaiverPostsaleService } from "../../services/waiver-postsale.service";
import { FileStorageService } from '../../services/file-storage.service';
import { FileUploadModel } from "../../models/file-upload.model";
import { MessageErrorService } from "../../services/message-error.service";


@Component({
  selector: "app-waiver-presale",
  templateUrl: "./waiver-presale.component.html",
  styleUrls: ["./waiver-presale.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class WaiverPresaleComponent implements OnInit {
  allowPrivateFare = false;
  fareDiff = false;
  isAdding = false;
  isAgree = false;
  isChangeMatch = false;
  isChangeWaiver = false;
  isFareValueValid = false;
  isLoading = false;
  isPrivate = false;
  isShowDetail = false;
  isValid = false;
  isValuated = false;
  isValuating = false;
  isValuatingCustom = false;
  labelSet = false;
  reload = false;
  segmentsValid = false;
  isLegalAllowed = false;
  inProgress = false;
  aditionalComment: string;
  files: Array<FileUploadModel> = [];


  valorizacionNueva: boolean = false;
  idWaiver: number = 0;
  descuentoAdvpFarebasis: number = 0;
  descuentoAdvp: number = 0;
  currency: string;
  formAdvp: UntypedFormGroup;
  newWaiverAdvp: any = null;
  isCouponUsed = false;
  isUsMarket = false;
  isBrMarket = false;
  isSelectAdvp = false;
  segmentsWithOneCouponInvalidQuantity = 0;
  dataReservation = new MatTableDataSource();
  dataPassengers = new MatTableDataSource();
  dataSegments = new MatTableDataSource();
  dataCoupons = new MatTableDataSource();
  dataTkt = new MatTableDataSource();
  dataVoucher = new MatTableDataSource();
  waiver: Waiver = new Waiver();
  invalidStatusSegmentsQuantity = 0;
  existPassengersWithoutTicket: boolean = true;
  selectedType: WaiverType = new WaiverType(
    18,
    "REA",
    "Reissue ADVP - ticket partially used",
    new WaiverCategory(18, "Reissue ADVP - ticket partially used", "REA")
  );
  validPrePNR: boolean = false;
  validPostPNR: boolean = false;
  validPostTKT: boolean = false;
  passengerTicketRequestsCompleted: number = 0;
  passengerTicketRequestsWithError: number = 0;
  pnrAdvp: any;
  bookingAdvp: any;
  loading: boolean = false;
  allPassengersInvalid: boolean = true;
  atLeastOneInfantPass = false;
  infantList: number[] = new Array();
  infantCoupon = false;
  selectionPassengers = new SelectionModel<Element>(true, []);
  selectionSegments = new SelectionModel<Element>(true, []);
  selectionCoupons = new SelectionModel<Element>(true, []);
  selectedReservationInformationRowIndex: number = -1;
  selectedPassengerInformationRowIndex: number = -1;
  selectedSegmentsInformationRowIndex: number = -1;
  selectedTktInformationRowIndex: number = -1;
  selectedCouponsInformationRowIndex: number = -1;
  validSegmentStatusList = ["HK", "KK"];
  displayedColumnsR = [
    "pnrNumber",
    "pnrType",
    "bookingCreation",
    "NumberOfPassengers",
    "pos",
    "iata",
    "pcc",
    "officeId",
  ];
  displayedColumnsPPOS = [
    "passengerName",
    "foid",
    "passengerType",
    "tkt",
    "select",
  ];
  displayedColumnsP = ["passengerName", "foid", "passengerType", "select"];
  displayedColumnsS = [
    "segmentNumber",
    "carrierMkt",
    "flightNumberMkt",
    "class",
    "origin",
    "destination",
    "dateHourDeparture",
    "status",
    "fareBasis",
    "select",
  ];
  displayedColumnsC = [
    "couponNumber",
    "carrierMkt",
    "flightNumberMkt",
    "class",
    "origin",
    "destination",
    "dateHourDeparture",
    "status",
    "fareBasis",
    "select",
  ];
  displayedColumnsTKT = [
    "tktNumber",
    "pnrNum",
    "emisionCreationDate",
    "posInfo",
    "iataInfo",
    "pccInfo",
    "officeIdInfo",
  ];
  displayedColumnsV = [
    "voucherNumber",
    "carrierTkt",
    "flightNumberTkt",
    "classTkt",
    "originTkt",
    "destinationTkt",
    "dateHourTkt",
    "farebasis",
    "voucherStatus",
    "select",
  ];
  invalidCouponStatusList = [
    "USED",
    "RFND",
    "EXCH",
    "VOID",
    "F",
    "R",
    "V",
    "E",
  ];
  allowedFileFormats = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "image/png",
    "image/jpeg",
  ];
  latamCarrierCodes = ["LA", "JJ", "4M", "XL"];
  formG: UntypedFormGroup;

  booking: Booking;
  infoAdvp: InfoAdvp;
  osiInfo: OsiInfo;
  pnr: Pnr;
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(
    SidebarMenuModel.MENU_WAIVERS,
    SidebarMenuModel.SUBMENU_PRESALE_WAIVER_REQUEST
  );
  solicitedValue: SolicitedValue;
  waiverResult: WaiverResult;
  waiverValue: WaiverValue;

  passengers: Passenger[];
  pricingTypes: PricingType[] = [];
  segments: Segment[];
  waiverTypes: WaiverPresaleType[] = [];
  motive: Motive = { id: 1 };
  waiverOneDealsFirstTrip: {commission: number} [] = [];
  waiverOneDealsSecondTrip: {commission: number} [] = [];
  accept = '.pdf, .doc, .docx, .png, .jpg, .jpeg';
  step = 0;
  totalOriginal: number;
  totalFinal: number;
  groupId: number;
  branchOfficeId: number;

  availablePercentage: number[];
  percentageSelected: number;
  maxPercentage: number;
  codeChangeWaiver: string;
  cxRivalry: string;
  labelRule: string[];
  minStayRule: string;
  pnrAux: string;
  pricingTypeCodeAux: string;
  displayedColumnsBookingInfo = [
    "pnrNumber",
    "type",
    "createDateTime",
    "pos",
    "iata",
    "ppc",
    "officeId",
  ];
  displayedColumnsPassengerInfo = ["name", "foId", "type", "select"];
  displayedColumnsSegmentInfo: string[] = [
    "segmentNumber",
    "carrierMarketing",
    "flightNumber",
    "class",
    "source",
    "destination",
    "departureDateTime",
    "fareBasisReq",
  ];
  profiles = new Profile();

  message = {
    icon: "warning",
    detail: "",
    status: 0,
    show: false,
  };

  pricingTypeSelected: PricingType = new PricingType(null, null, null);
  firstClickPnr = false;

  @ViewChild("pnrInput") pnrInput: ElementRef;
  @ViewChild("bookingInfoComponent") bookingInfoComponent: BookingInfoComponent;
  @ViewChild("fareDiffComponent") fareDiffComponent: FareDiffComponent;
  @ViewChild("passengerInfoComponent")
  passengerInfoComponent: PassengerInfoComponent;
  @ViewChild("RequestResultComponent")
  requestResultComponent: RequestResultComponent;
  @ViewChild("segmentInfoComponent") segmentInfoComponent: SegmentInfoComponent;
  @ViewChild("stepper") stepper: MatStepper;

  constructor(
    private formB: UntypedFormBuilder,
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private profilesService: ProfilingService,
    private sessionInfoService: SessionInfoService,
    private sidebarShareDataService: SidebarShareDataService,
    private fileStorageService: FileStorageService,
    private translateService: TranslateService,
    private waiverPresaleService: WaiverPresaleService,
    private waiverPostsaleService: WaiverPostsaleService
  ) {
    this.sessionInfoService
      .getSessionInfoAsPromise()
      .then((currentUser) => {
        this.groupId = currentUser.matrix_id;
        this.branchOfficeId = currentUser.branch_office_id;

      })
      .catch((error) => {

      });
  }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.buildForm();
    this.getWaiverTypes();
    this.getPricingTypes();
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.isUsMarket = this.sessionInfoService.getSessionInfo().marketId === 18;
    this.isBrMarket = this.sessionInfoService.getSessionInfo().marketId === 5;
  }

  getWaiverTypes() {
    this.waiverPresaleService.getWaiverTypes().subscribe({
      next: (res: {
        waiverTypes: WaiverPresaleType[];
        allowPrivateFare: boolean;
        isLegalAllowed: boolean;
      }) => {
        this.waiverTypes = res.waiverTypes.filter(
          (waiverType: WaiverPresaleType) => waiverType.id !== 16
        );
        /* En ngOnInit se pregunta si el marketId === 18 osea market US es true o false la variable isUsMarket
           Luego de eso se pregunta si isUsMarket es falso (No es mercado US), no muestra el tipo de waiver 18
           que seria el nuevo tipo de waiver MCPMLT-3750. En caso contrario si lo muestra.
        */
        if (!this.isUsMarket) {
          this.waiverTypes = this.waiverTypes.filter(
            (waiverType: WaiverPresaleType) => waiverType.id !== 18
          );
        }

        this.allowPrivateFare = res.allowPrivateFare;
        this.isLegalAllowed = res.isLegalAllowed;

        if (!this.isLegalAllowed) {
          this.isFareValueValid = true;
        }

        this.formG.get("waiverType").enable();
      },
      error: (error: HttpErrorResponse) => {
        console.log("error", error);
      }
  });
  }

  getPricingTypes() {
    this.waiverPresaleService.getPricingTypes().subscribe({
      next: (res: { pricingTypes: PricingType[] }) => {
        this.pricingTypes = res.pricingTypes;
      },
      error: (error: HttpErrorResponse) => {
        console.log("error", error);
      }
  });
  }

  highlightReservationInformationRow(event: Event, rowIndex: number) {
    this.selectedReservationInformationRowIndex =
      event.type === "mouseover" ? rowIndex : -1;
  }

  highlightPassengerInformationRow(event: Event, rowIndex: number) {
    this.selectedPassengerInformationRowIndex =
      event.type === "mouseover" ? rowIndex : -1;
  }

  highlightSegmentsInformationRow(event: Event, rowIndex: number) {
    this.selectedSegmentsInformationRowIndex =
      event.type === "mouseover" ? rowIndex : -1;
  }

  highlightTktInformationRow(event: Event, rowIndex: number) {
    this.selectedTktInformationRowIndex =
      event.type === "mouseover" ? rowIndex : -1;
  }

  highlightCouponsInformationRow(event: Event, rowIndex: number) {
    this.selectedCouponsInformationRowIndex =
      event.type === "mouseover" ? rowIndex : -1;
  }

  toggleSelectionSegmentPost(row) {
    if (
      this.validSegmentStatusList.includes(row.status) &&
      row.invalidCoupons === 0
    ) {
      this.selectionSegments.toggle(row);
    }
  }

  toggleSelectionPassengerPost(row) {
    let clearData = false;

    if (!row.invalidTkt && !row.allCouponsInvalid && !row.infantTkt) {
      if (!this.selectionPassengers.isSelected(row)) {
        this.processSegments(row);

        if (row.type !== "INF" && this.atLeastOneInfantPass) {
          // Logica para habilitar los campos cuando es un adulto para un inf
          this.setInfantTktPropertie(false);
        }
      } else {
        this.processSegmentsWithTkt(row);
        // logica de seleccion para el tipo de usuario INF
        clearData = this.selectedPassengerInf(row)
      }

      this.selectionPassengers.toggle(row);
      this.selectionSegments.selected.forEach((s: any) => {
        if (
          !this.validSegmentStatusList.includes(s.status) ||
          s.invalidCoupons > 0
        ) {
          this.selectionSegments.toggle(s);
        }
      });
      this.segmentsWithOneCouponInvalidQuantity = 0;

      this.countSegmentsWithOneCouponInvalidQuantity();

      if (clearData) {
        this.selectionPassengers.clear();
      }
    }
  }

  selectedPassengerInf(row: any): boolean {
    if (this.infantList.length > 0) {
          let selectionAdlt: boolean = false;

          if (this.selectionPassengers.selected.length - 1 > 0) {
            this.selectionPassengers.selected.forEach((passenger: any) => {
              if (
                passenger.type !== "INF" &&
                passenger.ticketNumber !== row.ticketNumber
              ) {
                selectionAdlt = true;
              }
            });

            if (selectionAdlt) {
              this.setInfantTktPropertie(false);
            } else {
              this.setInfantTktPropertie(true);
              return true;
            }
          } else {
            this.setInfantTktPropertie(true);
          }
        }
        return false;
  }

  countSegmentsWithOneCouponInvalidQuantity() {
    for (const element of this.dataSegments.data) {
      if (
        this.validSegmentStatusList.includes(
          element["status"]
        ) &&
        element["invalidCoupons"] > 0
      ) {
        this.segmentsWithOneCouponInvalidQuantity++;
      }
    }
  }

  processSegmentsWithTkt(row: any) {
    for (const element of this.dataSegments.data) {
      let coupon = this.findCouponBySourceDestination(
        row.coupons,
        element["source"],
        element["destination"]
      );

      if (!coupon) {
        continue;
      }

      element["selectedCoupons"].splice(
        element["selectedCoupons"].findIndex(
          (c) => c["tkt"] === coupon["tkt"]
        ),
        1
      );

      if (this.invalidCouponStatusList.includes(coupon["status"])) {
        element["invalidCoupons"]--;
      }
    }
  }

  processSegments(row) {
    for (const element of this.dataSegments.data) {
      let coupon = this.findCouponBySourceDestination(
        row.coupons,
        element["source"],
        element["destination"]
      );

      if (!coupon) {
        continue;
      }

      element["selectedCoupons"].push(coupon);

      if (this.invalidCouponStatusList.includes(coupon["status"])) {
        element["invalidCoupons"]++;
      }
    }
  }

  private setInfantTktPropertie(needDisabled: boolean) {
    if (this.infantList.length > 0 && this.atLeastOneInfantPass) {
      for (const element of this.infantList) {
        if (needDisabled) {
          this.dataPassengers.data[element]["infantTkt"] = true;
        } else {
          this.dataPassengers.data[element]["infantTkt"] = false;
        }
      }
    }
  }

  findCouponBySourceDestination(coupons: Array<any>, src: string, dst: string) {
    return coupons.find(
      (coupon) => coupon.source == src && coupon.destination == dst
    );
  }

  buildForm() {
    this.formG = this.formB.group({
      waiverType: [{ value: null, disabled: true }, Validators.required],
      PNR: [null, Validators.required],
      FAREBASIS: [null],
      checkPrivate: false,
      pricingType: [{ value: null, disabled: true }, Validators.required],
      pricingTypeCode: [null, Validators.required],
    });
  }

  sendMessage(){
    if (this.isBrMarket && this.formG.get("waiverType").value.code == "FRD" && !this.firstClickPnr){
      swal
            .fire({
              title: `<p style="font-family: LatamSansBold">${this.translateService.instant("info.warning")}.</p>`,
              icon: "warning",
              html: `<div>
          <p>${this.translateService.instant("info.taxValidate")}</p>
        </div>`,
              confirmButtonText:
                this.translateService.instant("button.confirm"),
              confirmButtonColor: "#d33",
              allowOutsideClick: false,
            })
      this.firstClickPnr = true;
    }
  }

  isAllSelectedPassengers() {
    const numSelectedPassengers = this.selectionPassengers.selected.length;
    const numRowsPassengers = this.dataPassengers.data.length;
    let numInvalidPassengers = 0;

    for (let i = 0; i < numRowsPassengers; i++) {
      if (
        this.dataPassengers.data[i]["invalidTkt"] ||
        this.dataPassengers.data[i]["allCouponsInvalid"]
      ) {
        numInvalidPassengers++;
      }
    }

    return numSelectedPassengers === numRowsPassengers - numInvalidPassengers;
  }

  calcularNewValorization() {
    let descuento = this.descuentoAdvpFarebasis - this.descuentoAdvp / 2;

    this.waiverPresaleService
      .saveDocumentFare(
        this.idWaiver,
        descuento,
        this.descuentoAdvpFarebasis,
        this.currency
      )
      .subscribe({
        next: (data) => {
          console.log("DATA 2:", data);
          swal
            .fire({
              title: `<p style="font-family: LatamSansBold">Valorization finished.</p>`,
              icon: "success",
              html: `<div>
          <p>Valorization finished.</p>
        </div>`,
              confirmButtonText:
                this.translateService.instant("button.confirm"),
              confirmButtonColor: "#d33",
              allowOutsideClick: false,
            })
            .then(() => {
              // Se limpia y reinicia el formulario de Solicitud de Waiver de Preventa
              //this.stepper.selected.completed = true;
              //this.stepper.next();
            });
        },
        error: (error: HttpErrorResponse) => {
          this.validPostPNR = false;
          this.showMessage(error);
          this.loading = false;
          console.log("error", error);
        }
  });
  }

  validatePnr() {
    this.disabledControls();
    this.isLoading = true;
    this.isValid = false;
    this.labelSet = false;

    if ((<WaiverPresaleType>this.formG.get("waiverType").value).id == 18) {

      this.validatePnrAdvp();
      this.getPnr();
      this.validatePresalePnrWithWaiverType();

    } else {
      this.validatePresalePnr();
    }
  }

  validatePresalePnr() {
    this.waiverPresaleService
        .validatePnr(
          this.formG.get("PNR").value,
          (<WaiverPresaleType>this.formG.get("waiverType").value).id
        )
        .subscribe({
          next: (res) => {
            this.infoAdvp = res;
            this.pnr = this.infoAdvp.infoPnr.pnr;
            this.booking = this.infoAdvp.infoPnr.booking;
            this.passengers = this.infoAdvp.infoPnr.passengers;
            this.segments = this.infoAdvp.infoPnr.segments;
            this.labelSet = this.infoAdvp.rules.length > 0;
            this.labelRule = this.infoAdvp.rules;
            this.waiverOneDealsFirstTrip =
              this.infoAdvp.infoPnr.waiverOneDealsFirstTrip;
            this.waiverOneDealsSecondTrip =
              this.infoAdvp.infoPnr.waiverOneDealsSecondTrip;
            this.solicitedValue = this.infoAdvp.solicitedValue
              ? this.infoAdvp.solicitedValue
              : new SolicitedValue(null, null, null);
            this.cxRivalry = this.infoAdvp.cxRivalry;
            this.infoAdvp.waiverType = <WaiverPresaleType>(
              this.formG.get("waiverType").value
            );
            this.displayedColumnsSegmentInfo =
              this.displayedColumnsSegmentInfo.filter(
                (item) => item !== "ticketDesignator"
              );

            if (
              this.formG.get("checkPrivate").value &&
              this.formG.get("pricingType").value
            ) {
              this.infoAdvp.privateWaiver = true;
              this.infoAdvp.pricingType = <PricingType>(
                this.formG.get("pricingType").value
              );

              if (this.formG.get("pricingTypeCode").value) {
                this.infoAdvp.pricingTypeCode =
                  this.formG.get("pricingTypeCode").value;
              }
            } else {
              this.infoAdvp.privateWaiver = false;
            }

            if (
              this.infoAdvp.pricingType &&
              this.infoAdvp.pricingType.code === "JCB"
            ) {
              this.displayedColumnsSegmentInfo.push("ticketDesignator");
            }

            if (this.infoAdvp.infoPnr?.osiInfo[0]) {

              this.showQuestionToUtilization(
                this.infoAdvp.infoPnr.pnr.pnrNumber,
                this.infoAdvp.infoPnr.booking.iata,
                this.infoAdvp.infoPnr.osiInfo[0]
              );
            } else {
              this.isValid = true;

            }

            this.isLoading = false;
            this.enabledControls();
          },
          error: (error: any) => {
            this.isValid = false;

            this.messageErrorService.sendError(error, "error.request");

            this.enabledControls();
            this.isLoading = false;
          }
    });
  }

  validatePresalePnrWithWaiverType() {
    this.waiverPresaleService
        .validatePnr(this.formG.get("PNR").value, 18)
        .subscribe({
          next: (res) => {
            this.infoAdvp = res;
            setTimeout(() => {
              this.calcularFarebasis();
            }, 500);
          },
          error: (error: any) => {
            this.isValid = false;

            this.messageErrorService.sendError(error, "error.request");

            this.enabledControls();
            this.isLoading = false;
          }
    });
  }

  getPnr() {
    this.waiverPostsaleService.getPnr(this.formG.get("PNR").value).subscribe({
        next: (data) => {
          console.table(data.segments);

          this.dataReservation.data = [
            {
              pnr: data["pnr"],
              booking: data["booking"],
            },
          ];
          data["passengers"].forEach((value, index) => {
            data["passengers"][index].id = index + 1;
          });
          this.dataPassengers.data = data["passengers"];
          this.dataSegments.data = data["segments"];

          for (const element of this.dataSegments.data) {
            element["selectedCoupons"] = [];
            element["invalidCoupons"] = 0;
            if (
              !this.validSegmentStatusList.includes(
                element["status"]
              )
            ) {
              this.invalidStatusSegmentsQuantity++;
            }
          }


          this.existPassengersWithoutTicket = true;
          this.findTktInfoForPassengersAndSegments();

          this.osiInfo = data["osiInfo"];
          this.pnrAdvp = data["pnr"];
          this.bookingAdvp = data["booking"];

          if (this.pnr && this.osiInfo && this.booking) {

            this.showQuestionMessage(
              this.pnr.pnrNumber,
              this.booking.iata,
              this.osiInfo
            );
          } else {
            this.validPostPNR = true;

          }

          this.validPostPNR = true;

        },
        error: (error: HttpErrorResponse) => {
          this.validPostPNR = false;
          this.showMessage(error);
          this.loading = false;
          console.log("error", error);
        }
    });
  }
  validatePnrAdvp() {
    this.waiverPresaleService
        .validatePnrAdvp(this.formG.get("PNR").value)
        .subscribe({
          next: (data) => {
            this.newWaiverAdvp = data[0];
            this.idWaiver = data[0].id;
            this.descuentoAdvp = data[0].reference_min_fare - data[0].base_fare;
            this.currency = data[0].currency;
          },
          error: (error: HttpErrorResponse) => {
            this.validPostPNR = false;
            this.showMessage(error);
            this.loading = false;
            console.log("error", error);
          }
    });

  }

  isLoadingContainer() {
    return (
      this.loading ||
      (this.validPostPNR &&
        this.passengerTicketRequestsCompleted !==
          this.dataPassengers.data.length)
    );
  }
  /**
   * shows an error message
   * @param e object of error / contains the error detail
   */
  public showMessage(e: HttpErrorResponse) {
    this.message.show = true;
    if (e.status === 404) {
      this.message.status = 2;
      swal.fire({
        title:
          "<p> " + this.translateService.instant("error.notDataFound") + "</p>",
        confirmButtonText: this.translateService.instant("button.confirm"),
        confirmButtonColor: "#d33",
        icon: "error",
        allowOutsideClick: false,
      });
    } else {
      if (e.message === SystemException) {
        this.message.status = 1;
        this.message.detail = "";
        swal.fire({
          title:
            "<p> " + this.translateService.instant("error.notFound") + "</p>",
          confirmButtonText: this.translateService.instant("button.confirm"),
          confirmButtonColor: "#d33",
          icon: "error",
          allowOutsideClick: false,
        });
      } else {
        this.message.status = 0;
        this.message.detail = "sin respuesta";
        swal.fire({
          title:
            "<p> " +
            this.translateService.instant("error.consultaTicket") +
            "</p>",
          confirmButtonText: this.translateService.instant("button.confirm"),
          confirmButtonColor: "#d33",
          icon: "error",
          allowOutsideClick: false,
        });
      }
    }
  }

  isAllSelectedSegments() {
    const numSelectedSegments = this.selectionSegments.selected.length;
    const numRowsSegments = this.dataSegments.data.length;
    let numInvalidSegments = 0;

    for (let i = 0; i < numRowsSegments; i++) {
      if (
        this.selectedType.waiverCategory.code === "POST" &&
        (!this.validSegmentStatusList.includes(
          this.dataSegments.data[i]["status"]
        ) ||
          this.dataSegments.data[i]["invalidCoupons"] > 0)
      ) {
        numInvalidSegments++;
      } else if (
        this.selectedType.waiverCategory.code === "PRE" &&
        !this.validSegmentStatusList.includes(
          this.dataSegments.data[i]["status"]
        )
      ) {
        numInvalidSegments++;
      }
    }

    return numSelectedSegments === numRowsSegments - numInvalidSegments;
  }

  masterToggleSegments() {
    if (this.isAllSelectedSegments()) {
      this.selectionSegments.clear();
    } else if (this.selectedType.waiverCategory.code === "PRE") {
      this.dataSegments.data.forEach((row: any) => {
        if (this.validSegmentStatusList.includes(row.status)) {
          this.selectionSegments.select(row);
        }
      });
    } else if (this.selectedType.waiverCategory.code === "POST") {
      this.dataSegments.data.forEach((row: any) => {
        if (
          this.validSegmentStatusList.includes(row.status) &&
          row.invalidCoupons === 0
        ) {
          this.selectionSegments.select(row);
        }
      });
    }
  }

  findTktInfoForPassengersAndSegments(i: number = 0): void {
    setTimeout(() => {
      for (let k = 0; k < 4; k++) {
        if (i < this.dataPassengers.data.length) {
          let l: number = i;
          this.dataPassengers.data[i]["allCouponsInvalid"] = true;
          if (
            !this.dataPassengers.data[i]["ticketNumber"] ||
            this.dataPassengers.data[i]["ticketNumber"] === "" ||
            !this.dataPassengers.data[i]["ticketNumber"].trim()
          ) {
            this.dataPassengers.data[i]["invalidTkt"] = true;
            this.passengerTicketRequestsCompleted++;
          } else if (
            this.dataPassengers.data[i]["ticketNumber"] !== "" &&
            this.dataPassengers.data[i]["type"] == "INF"
          ) {
            // Logica para los INF
            this.atLeastOneInfantPass = true;
            this.infantList.push(l);
            this.dataPassengers.data[l]["infantTkt"] = true;
            this.dataPassengers.data[l]["allCouponsInvalid"] = false;
            this.existPassengersWithoutTicket = false;
            this.passengerTicketRequestsCompleted++;
          } else {
            this.getPnrTkt(i ,l);
          }
        }

        i++;
      }
      this.findTktInfoForPassengersAndSegments(i);
      this.addInfoInfantPassengerCoupons();
      if (
        this.passengerTicketRequestsCompleted ===
          this.dataPassengers.data.length &&
        this.existPassengersWithoutTicket
      ) {
        this.existPassengersWithoutTicket = false;

        this.messageService.showWarningMessage(
          this.translateService.instant("button.confirm"),
          this.translateService.instant("warning.pnrWithoutTkt")
        );
      }
    }, 1000);
  }

  getPnrTkt(i: number, l: number) {
    this.existPassengersWithoutTicket = false;
            this.waiverPostsaleService
              .getPnrTkt(
                this.dataPassengers.data[i]["ticketNumber"].substring(0, 13)
              )
              .subscribe({
                next: (data) => {
                  if (data.coupons.length > 0) {
                    data.coupons.forEach((element) => {
                      if (
                        element.status === "USED" ||
                        element.status === "FLOWN" ||
                        element.status === "EXCH" ||
                        element.status === "CKIN"
                      ) {
                        this.isCouponUsed = true;
                      }
                    });
                  }

                  this.dataCoupons.data = data.coupons;
                  console.table(data.coupons);

                  let couponsForDeleteIndexes: number[] = [];
                  let carrierMarketingFoundOnSegmentList: boolean = false;

                  for (let j = 0; j < data["coupons"].length; j++) {
                    carrierMarketingFoundOnSegmentList = false;
                    for (const element of this.dataSegments.data) {
                      if (
                        data["coupons"][j]["carrierMarketing"] ===
                        element["carrierMarketing"]
                      ) {
                        carrierMarketingFoundOnSegmentList = true;
                        break;
                      }
                    }

                    if (!carrierMarketingFoundOnSegmentList) {
                      couponsForDeleteIndexes.push(j);
                    }
                  }

                  couponsForDeleteIndexes.forEach((n) => {
                    data["coupons"].splice(n, 1);
                  });

                  this.validateCouponInvalid(data, l);
                  this.passengerTicketRequestsCompleted++;
                },
                error: (error: HttpErrorResponse) => {
                  this.passengerTicketRequestsCompleted++;
                  this.passengerTicketRequestsWithError++;
                  this.dataPassengers.data[l]["validTktResponse"] = false;
                  this.showMessage(error);
                  console.log("error", error);
                }
          });
  }

  validateCouponInvalid(data: Waiver, l: number) {
    for (const element of data["coupons"]) {
      element["tkt"] =
        this.dataPassengers.data[l]["ticketNumber"];
      if (element["status"] === "OK") {
        this.dataPassengers.data[l]["allCouponsInvalid"] = false;
      }
    }
    this.dataPassengers.data[l]["coupons"] = data["coupons"];

    if (!this.dataPassengers.data[l]["allCouponsInvalid"]) {
      this.allPassengersInvalid = false;
    }
  }

  addInfoInfantPassengerCoupons() {
    if (this.infantList.length > 0 && this.infantCoupon === false) {
      let indexPassInfo: number = -1;

      for (let pass = 0; pass < this.dataPassengers.data.length; pass++) {
        if (
          this.dataPassengers.data[pass]["allCouponsInvalid"] === false &&
          this.dataPassengers.data[pass]["coupons"] != null
        ) {
          indexPassInfo = pass;
          break;
        }
      }

      if (indexPassInfo !== -1) {
        for (const element of this.infantList) {
          this.dataPassengers.data[element]["coupons"] =
            this.dataPassengers.data[indexPassInfo]["coupons"];
        }
        this.infantCoupon = true;
      }
    }
  }

  private showQuestionMessage(
    pnrNumber: string,
    iata: string,
    osiInfo: OsiInfo
  ) {
    swal
      .fire({
        title: this.translateService.instant("acceptUsePnr"),
        icon: "question",
        html: `<div class='popup-pnr'>${this.translateService.instant(
          "confirmUseIata",
          {
            osiInfo: osiInfo.agencyOsi,
            waiverType: this.selectedType.name,
            pnrNumber: pnrNumber,
            iata: iata,
          }
        )}</div>`,
        showCancelButton: true,
        confirmButtonText: this.translateService.instant("button.yes"),
        cancelButtonText: this.translateService.instant("button.no"),
      })
      .then((result) => {
        if (result.value) {
          this.validPostPNR = true;
        } else {
          this.validPostPNR = false;
        }
      });
  }

  private showQuestionToUtilization(
    pnrNumber: string,
    iata: string,
    osiInfo: OsiInfo
  ) {
    swal
      .fire({
        title: this.translateService.instant("acceptUsePnr"),
        icon: "question",
        html: `<div class='popup-pnr'>${this.translateService.instant(
          "confirmUseIata",
          {
            osiInfo: osiInfo.agencyOsi,
            waiverType: (<WaiverPresaleType>this.formG.get("waiverType").value)
              .name,
            pnrNumber: pnrNumber,
            iata: iata,
          }
        )}</div>`,
        showCancelButton: true,
        confirmButtonText: this.translateService.instant("button.yes"),
        cancelButtonText: this.translateService.instant("button.no"),
      })
      .then((result) => {
        if (result.value) {
          this.isValid = true;
        } else {
          this.isValid = false;
        }
      });
  }

  onClick(){
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    let sameFileCount: number;

    fileUpload.onchange = () => {
      let index = fileUpload.files.length - 1;
      const file = fileUpload.files[index];
      sameFileCount = 0;
      this.files.forEach((f) => {
        if (f.data.name === file.name) {
          sameFileCount++;
        }
      });

      if (!this.allowedFileFormats.includes(file.type)) {

        this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.invalidFileFormat')
        );
      } else if (file.name.length > 100) {

        this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.fileName')
        );
      } else if (file.size > 2097152) {

        this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.maxSize')
        );
      } else {
        if (sameFileCount > 0) {
          this.messageService.showWarningMessage(
            this.translateService.instant('button.confirm'),
            this.translateService.instant('warning.alreadyLoadedFile')
          );
        } else {
          this.files.push({
            data: file, state: 'in', inProgress: false, progress: 0, canRetry: false, canCancel: true
          });
          let indexZ = this.files.length - 1;
          this.uploadFile(this.files[indexZ]);
        }
      }
    };

    fileUpload.value = '';
    fileUpload.click();
  }

  private uploadFile(file: FileUploadModel) {
    file.inProgress = true;
    this.inProgress = true;

    console.log("Subiendo archivo archivo");
    this.fileStorageService.uploadFile(file.data).subscribe({
      next: (event: any) => {
        console.log("Evento: ", event);
        let indexZ = this.files.length - 1;
        this.files[indexZ].data['locationStorage'] = event.reference;
        this.files.forEach(console.log);
        this.inProgress = false;
      },
      error: (error: HttpErrorResponse) => {
        this.removeFileFromArray(file);
        console.log('error', error);
        this.inProgress = false;
      }});
  }

  private removeFileFromArray(file: FileUploadModel) {
    const index = this.files.indexOf(file);

    if (index > -1) {
      this.files.splice(index, 1);
    }
  }

  cancelFile(file: FileUploadModel) {
    file.deletingFile = true;

    this.fileStorageService.deleteFile(file.data['locationStorage']).subscribe({
      next: (event: any) => {
        this.removeFileFromArray(file);
        file.deletingFile = false;
      },
      error: (error: HttpErrorResponse) => {
        file.deletingFile = false;
        console.log('error', error);
      }});
  }

  getFormValid(event: boolean) {
    this.segmentsValid = event;
  }

  invalidP(): boolean {
    let resp = true;
    this.passengers.forEach((element) => {
      if (element.isSelected) {
        resp = false;
      }
    });
    return resp;
  }

  getFareValueValid(event: boolean) {
    this.isFareValueValid = event;
  }

  getSolicitedValue(event: SolicitedValue) {
    this.solicitedValue = event;
    this.infoAdvp.solicitedValue = this.solicitedValue;
  }

  getCxRivalryValue(event: string) {
    this.infoAdvp.cxRivalry = event;
  }

  setSolicitedValue() {
    this.solicitedValue = new SolicitedValue(0, 0, 0);
    this.infoAdvp.solicitedValue = this.solicitedValue;
    this.infoAdvp.cxRivalry = "";
  }

  calcularFarebasis() {
    this.waiverPresaleService
      .valorizationNewFareBasis(
        this.infoAdvp,
        this.formG.get("FAREBASIS").value
      )
      .subscribe({
        next: (res: number) => {
          this.descuentoAdvpFarebasis = res;
          this.valorizacionNueva = true;
          this.isLoading = false;
        },
        error: (error: any) => {
          this.isValuated = false;
          this.isValuating = false;
          this.enabledControls();

          this.messageErrorService.sendError(error, "error.request");
        }
  });
  }



  isDiplomatic(){
    const waiverTypeValue = <WaiverPresaleType>this.formG.get('waiverType').value
    if (waiverTypeValue === null || waiverTypeValue.code === null){
      return false;
    }
    return (<WaiverPresaleType>this.formG.get('waiverType').value).code === 'DPE';
  }

  getWaiverTypeSelected(){
    return <WaiverPresaleType>this.formG.get('waiverType').value;
  }

  calculateWaiver() {
    this.disabledControls();
    this.isValuating = true;
    this.fareDiff = false;

    if (!this.isLegalAllowed) {
      this.setSolicitedValue();
    }

    this.waiverPresaleService.valorizationFareBasis(this.infoAdvp).subscribe({
      next: (res: InfoAdvp) => {
        this.isValuated = true;
        this.waiverValue = res.value;

        if ((<WaiverPresaleType>this.formG.get('waiverType').value).code === 'FRD') {
          this.fareDiff = true;
          this.isChangeMatch = this.waiverValue.match;
          this.totalOriginal = this.waiverValue.originalFareByPaxWOQs + this.waiverValue.qsByPax;
          this.totalFinal = this.waiverValue.finalFareByPaxWOQs + this.waiverValue.qsByPax;
        }

        if (res.value.typeId) {
          this.infoAdvp.waiverType.id = res.value.typeId;
          this.isChangeWaiver = true;
          this.codeChangeWaiver = this.waiverTypes.find(waiverType => waiverType.id === res.value.typeId).name;
        }
        this.maxPercentage = res.value.percentage;
        this.availablePercentage = this.getAvailablePercentage();
        this.percentageSelected = res.value.percentage;

        this.isValuating = false;
        this.enabledControls();
        this.stepper.selected.completed = true;
        this.stepper.next();
      },
      error: (error: any) => {
        this.isValuated = false;
        this.isValuating = false;
        this.enabledControls();

          this.messageErrorService.sendError(error, "error.request");
        }
  });
  }

  private getAvailablePercentage(): number[]{
    const availablePercentage: number[] = [];
    for (let i: number = 1; i <= this.maxPercentage; i++) {
        availablePercentage.push(i);
    }
    return availablePercentage;
  }

  parcentageChange(): void {
    this.isValuating = true;
    this.isValuatingCustom = true;

    const infoCustomPercentage: InfoAdvp = {...this.infoAdvp, value: {...this.waiverValue, percentage: this.percentageSelected}};
    this.waiverPresaleService.valorizationCustom(infoCustomPercentage).subscribe({next: (res: InfoAdvp) => {
        this.isValuated = true;
        this.waiverValue = res.value;

        if ((<WaiverPresaleType>this.formG.get('waiverType').value).code === 'FRD') {
          this.isChangeMatch = this.waiverValue.match;
          this.totalOriginal = this.waiverValue.originalFareByPaxWOQs + this.waiverValue.qsByPax;
          this.totalFinal = this.waiverValue.finalFareByPaxWOQs + this.waiverValue.qsByPax;
        }

        this.availablePercentage = this.getAvailablePercentage();
        this.percentageSelected = res.value.percentage;

        this.isValuating = false;
        this.isValuatingCustom = false;
    }, error: (error: any) => {
         this.isValuated = false;
         this.isValuating = false;
         this.isValuatingCustom = false;

        this.messageErrorService.sendError(error, 'error.request');
      }
  });
  }



  // Metodos para Aceptar o Rechazar el waiver
  private showConfirm() {
    swal
      .fire({
        title: `<p style="font-family: LatamSansBold">${this.translateService.instant(
          "popup.wellDone"
        )}</p>`,
        icon: "success",
        html: `<div>
          <p>${this.translateService.instant("timeLimit")}</p>
        </div>`,
        confirmButtonText: this.translateService.instant("button.confirm"),
        confirmButtonColor: "#d33",
        allowOutsideClick: false,
      })
      .then(() => {
        // Se limpia y reinicia el formulario de Solicitud de Waiver de Preventa
        this.stepper.selected.completed = true;
        this.stepper.next();
      });
  }

  public validateFile() {
    return this.files?.length > 0;
  }

  agreeWaiverPresale() {
    this.isAdding = true;
    this.infoAdvp.motive = this.motive;
    this.infoAdvp.value = this.waiverValue;

    if (this.files != null){
      this.infoAdvp.aditionalComment = this.aditionalComment;
      let archivos = [];
      this.files.forEach(fil => {
        console.log("Guardando archivo en waiver: ", fil);
        archivos.push({
          name: fil.data.name.toString(),
          size: fil.data.size.toString(),
          type: fil.data.type.toString(),
          locationStorage: fil.data['locationStorage']
        });
      });
      this.infoAdvp.files = archivos;
    }

    this.waiverPresaleService.saveWaiverPresale(this.infoAdvp).subscribe({
      next: (res: WaiverResult) => {
        this.waiverResult = res;
        this.waiverResult.waiverValue = this.waiverValue.waiverValue;
        this.isAgree = true;
        this.showConfirm();
        this.isAdding = false;
        this.files = null;
      },
      error: (error: any) => {
        this.messageErrorService.sendError(error, "error.request");

        this.isAdding = false;
        this.reset();
      }
  });
  }

  reset() {
    this.isValuated = false;
    this.isAgree = false;
    this.infoAdvp = null;
    this.formG.get("waiverType").reset();
    this.waiverTypeChange();
    this.stepper.reset();
  }

  disagreeWaiverPresale() {
    this.reset();
    swal
            .fire({
              title: `<p style="font-family: LatamSansBold">${this.translateService.instant("popup.wellDone")}</p>`,
              icon: "success",
              html: `<div>
          <p>${this.translateService.instant("success.waiverCreated")}</p>
          <p>${this.translateService.instant("success.waiverCreated2")}</p>
        </div>`,
              confirmButtonText:
                this.translateService.instant("button.confirm"),
              confirmButtonColor: "#d33",
              allowOutsideClick: false,
            })
  }

  waiverTypeChange() {
    if (this.formG.get("waiverType").value) {
      this.motive =
        (<WaiverPresaleType>this.formG.get("waiverType").value).code === "121"
          ? { id: 15 }
          : { id: 1 };
    }

    if (this.formG.get("waiverType").value?.id === 18) {
      this.isSelectAdvp = true;
    } else {
      this.isSelectAdvp = false;
    }

    if (this.isValuated) {
      const waiverType = <WaiverPresaleType>this.formG.get("waiverType").value;
      this.stepper.reset();
      this.isValuated = false;
      this.isShowDetail = false;
      this.formG.get("waiverType").setValue(waiverType);
    }

    this.formG.get("PNR").reset();
    this.formG.get("checkPrivate").reset();
    this.checkPrivateEvent();
  }

  pnrChange() {
    if (
      this.pnrAux !== String(this.formG.get("PNR").value).toUpperCase() &&
      this.isValid
    ) {
      this.checkPrivateEvent();
    }

    if (this.formG.get("PNR").valid) {
      this.pnrAux = String(this.formG.get("PNR").value).toUpperCase();
    }
  }

  checkPrivateEvent() {
    this.isValid = false;
    this.isValuated = false;
    this.isPrivate = this.formG.get("checkPrivate").value;

    if (!this.formG.get("checkPrivate").value) {
      this.formG.get("pricingType").reset();
      this.formG.get("pricingTypeCode").reset();
      this.clearValidators();
    } else {
      if (this.formG.get("waiverType").value.code !== "FRD") {
        this.pricingTypeSelected = this.pricingTypes.find((item) => {
          return item.code === "JCB";
        });
      } else {
        this.pricingTypeSelected = this.pricingTypes.find((item) => {
          return item.code === "ACO";
        });
      }

      this.setValidators();
    }
  }

  pricingTypeChange() {
    this.isValid = false;
    this.isValuated = false;
    this.formG.get("pricingTypeCode").reset();

    if ((<PricingType>this.formG.get("pricingType").value).code === "JCB") {
      this.formG.get("pricingTypeCode").clearValidators();
      this.formG.get("pricingTypeCode").updateValueAndValidity();
    } else {
      this.setValidators();
    }
  }

  pricingTypeCodeChange() {
    if (
      this.pricingTypeCodeAux !== this.formG.get("pricingTypeCode").value &&
      (this.isValid || this.isValuated)
    ) {
      this.isValid = false;
      this.isValuated = false;
    }

    this.pricingTypeCodeAux = this.formG.get("pricingTypeCode").value;
  }

  clearValidators() {
    this.formG.get("pricingType").clearValidators();
    this.formG.get("pricingTypeCode").clearValidators();
    this.updateValidators();
  }

  setValidators() {
    if (this.pricingTypeSelected.code === "ACO") {
      this.formG.get("pricingType").setValidators([Validators.required]);
      this.formG.get("pricingTypeCode").setValidators([Validators.required]);
    } else {
      this.formG.get("pricingType").setValidators([Validators.required]);
      this.formG.get("pricingTypeCode").clearValidators();
    }
    this.updateValidators();
  }

  updateValidators() {
    this.formG.get("pricingType").updateValueAndValidity();
    this.formG.get("pricingTypeCode").updateValueAndValidity();
  }

  validatingForm() {
    if (this.formG.valid) {
      return false;
    } else {
      return true;
    }
  }

  showDetail() {
    this.isShowDetail = !this.isShowDetail;
  }

  onStepChange(event: StepperSelectionEvent) {
    this.reload = event.selectedIndex < this.step;
    this.step = event.selectedIndex;
  }

  enabledControls() {
    this.formG.get("waiverType").enable();
    this.formG.get("checkPrivate").enable();
    this.formG.get("PNR").enable();
    this.formG.get("FAREBASIS").enable();
    this.formG.get("pricingType").enable();
    this.formG.get("pricingTypeCode").enable();
    setTimeout(() => {
      this.pnrInput.nativeElement.focus();
    });
  }

  disabledControls() {
    this.formG.get("waiverType").disable();
    this.formG.get("checkPrivate").disable();
    this.formG.get("PNR").disable();
    this.formG.get("FAREBASIS").disable();
    this.formG.get("pricingType").disable();
    this.formG.get("pricingTypeCode").disable();
  }

  setValuated(event: boolean) {
    this.isValuated = event;
  }

  allowPrivateFareWaiver() {
    if (
      this.formG.get("waiverType").value &&
      this.allowPrivateFare &&
      (this.formG.get("waiverType").value.code === "FRD" ||
        this.formG.get("waiverType").value.code === "ADP" ||
        this.formG.get("waiverType").value.code === "121" ||
        this.formG.get("waiverType").value.code === "MAS" ||
        this.formG.get("waiverType").value.code === "MIS")
    ) {
      this.formG.get("pricingType").disable();
      return true;
    } else {
      return false;
    }
  }

  comparePricingType(pricingType1: PricingType, pricingType2: PricingType) {
    return pricingType1 && pricingType2
      ? pricingType1.code === pricingType2.code
      : pricingType1 === pricingType2;
  }
}

import { Observable } from 'rxjs';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { CustomValidators } from './../../../validators/custom-validators';

import { LoaderService } from './../../../services/loader.service';
import { LoginAgencyService } from './../../../services/login-agency.service';
import { MessageService } from './../../../services/message.service';


@Component({
  selector: 'app-reset-pass',
  templateUrl: './reset-pass.component.html',
  styleUrls: ['./reset-pass.component.css']
})
export class ResetPassComponent implements OnInit {
  hide = true;
  hidePass = true;
  isDisabled = false;

  formG: UntypedFormGroup;

  @Input() code: string;
  @Input() urlLogin: string;

  @Output() message =  new EventEmitter<string>();

  constructor(
    private router: Router,
    private formB: UntypedFormBuilder,
    private loaderService: LoaderService,
    private loginAgencyService: LoginAgencyService,
    private messageService: MessageService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.formG = this.formB.group({
      newP: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.patternValidator(/[a-zA-Z]/, { hasLetter: true }),
          CustomValidators.patternValidator(/\d/, { hasNumber: true }),
          Validators.minLength(6)
        ])
      ],
      confirmP: null
    }, { validator: this.checkP });
  }

  checkP(group: UntypedFormGroup) {
    let pass = group.get('newP').value;
    let confirmPass = group.get('confirmP');
    let controlValid = null;

    confirmPass.setErrors(null);

    if (pass !== confirmPass.value) {
      controlValid = { notSame: true };
      confirmPass.setErrors({ invalid: true });
    }

    return controlValid;
  }

  resetPass() {
    if (this.formG.valid) {
      if (this.code) {
        this.loaderService.show();
        this.isDisabled = true;

        this.loginAgencyService.verifyResetCode(this.code).subscribe({
            next: data => {
              this.loginAgencyService.changePassword(this.code, this.formG.get('newP').value).subscribe({
                next: val => {
                  this.messageService.showSuccessMessage(
                    this.translateService.instant('button.confirm'),
                    this.translateService.instant('popup.wellDone'),
                    this.translateService.instant('login.success.resetPass')
                  );

                  this.goLogin();
                  this.isDisabled = false;
                  this.loaderService.hide();
                },
                error: error => {
                  this.message.emit('login.error.resetPass');
                  this.isDisabled = false;
                  this.loaderService.hide();
                  console.log('error', error);
                }
              });
            },
            error: error => {
              this.message.emit('login.error.resetPass');
              this.isDisabled = false;
              this.loaderService.hide();
              console.log('error', error);
            }
          });
      } else {
        this.message.emit('login.error.resetPass');
      }
    } else {
      this.validateAllFormFields(this.formG);
    }
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);

      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  goLogin(): void {
    this.router.navigate(['/login/agency']);
  }
}

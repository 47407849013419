import { Component, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { filter, Observable ,  Subject,  takeUntil,  timer } from 'rxjs';
import swal from 'sweetalert2';

import { Profile } from '../../models/profile.model';
import { SessionInfo } from '../../models/SessionInfo.model';
import { SidebarMenuModel } from '../../models/SidebarMenu.model';

import { KeepAliveService } from '../../services/keep-alive.service';
import { LoginAgencyService } from './../../services/login-agency.service';
import { LoginLatamService } from '../../services/login-latam.service';
import { LoginService } from './../../services/login.service';
import { ProfilingService } from '../../services/profiling.service';
import { SessionInfoService } from '../../services/session-info.service';
import { SidebarShareDataService } from '../../services/sidebar-share-data.service';
import { WaiverPostsaleService } from '../../services/waiver-postsale.service';
import { WaiverPage } from '../../models/waiver-page.model';
import { StorageService } from '../../services/storage.service';
import { BudgetService } from '../../services/budget.service';
import { HttpErrorResponse } from '@angular/common/http';
import { WaiverType } from '../../models/waiver-type.model';
import { NavigationEnd, Router } from '@angular/router';
import { CourtesyPolicyMenu } from '../../interfaces/courtesy-policy-menu';
import { CourtesyPolicyMenuMap } from '../../interfaces/courtesy-policy-menu';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input() showMainLayout: boolean;

  isAuthAndAuthorized = false;
  menuOpened = true;
  urlparameters: string;
  innerHeight: number = null;
  lastOpened: number;
  isAllowedEmail: boolean = false;

  isBudgetAvailable: boolean = false;
  isNewWaiverModule: boolean = false;
  isWaiversLatamAvailable: boolean = false;

  infoUser$: Promise<SessionInfo> = this.sessionInfoService.getSessionInfoAsPromise();
  sessionInfo: SessionInfo;

  menuItemsHovered: boolean[] = [];
  profiles = new Profile();
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_WAIVERS, SidebarMenuModel.SUBMENU_WAIVER_REQUEST);

  subMenuItemsHovered: boolean[] = [];

  private timer: Observable<number>;
  private timerSubscription: any;

  private readonly waiversCourtesy: string = 'waivers/latam';
  private readonly waiversPolicy: string = 'waivers/policies';

  private readonly waiverCourtesyRequest: string = 'agency/waivers/latam/request';
  private readonly waiverCourtesyList: string = 'agency/waivers/latam/list';
  private readonly waiverCourtesyManual: string = 'agency/waivers/latam/manuals';
  private readonly waiverPolicyRequest: string = 'agency/waivers/policies/request';
  private readonly waiverPolicyList: string = 'agency/waivers/policies/list';
  private readonly waiverPolicyManual: string = 'agency/waivers/policies/manuals';

  public waiverRequestRoute: CourtesyPolicyMenu;

  private ngUnsubscribe = new Subject<void>();




  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(
    private titleService: Title,
    private keepAliveService: KeepAliveService,
    private loginAgencyService: LoginAgencyService,
    private loginLatamService: LoginLatamService,
    private loginService: LoginService,
    private profilesService: ProfilingService,
    private sessionInfoService: SessionInfoService,
    private sidebarShareDataService: SidebarShareDataService,
    private translateService: TranslateService,
    private waiverPostsaleService: WaiverPostsaleService,
    private budgetService: BudgetService,
    private router:Router) {
      this.urlparameters = StorageService.getDnsWaivers();
    }

  ngOnInit() {
    this.isNewWaiverModuleRoute();
    this.setSessionInfo();
    if (this.sessionInfo.isAgencySession()){
      this.agencyHasWaiverChanges();
    }
    this.innerHeight = window.innerHeight > 657 ? window.innerHeight - 47 : 742;
    this.subMenuItemsHovered.fill(false);
    this.menuItemsHovered.fill(false);
    this.loginService.loginState
      .subscribe(
        loginState => {
          this.isAuthAndAuthorized = loginState;
        }
      );

    this.isLogin();

    if (this.isAuthAndAuthorized) {
      this.infoUser$
        .then(
          sessionInfo => {
            this.profiles = this.profilesService.getProfile();
            console.log('this.profiles', this.profiles);

            this.countPendingWaivers(sessionInfo);

            this.sidebarShareDataService.getData().subscribe(
              sidebarMenu => {
                this.sidebarMenu = sidebarMenu;
              }
            );

            this.startKeepAlive();
            this.validateAllowedEmailFareRegulation();
          }
        )
        .catch(
          error => {
            console.log('error', error);
          }
        );
    }
  }
  validateAllowedEmailFareRegulation() {
    const idToken = localStorage.getItem('idToken')
    const idTokenJson: string = JSON.parse(atob(idToken.split('.')[1]));
    const email: string = idTokenJson["email"];
    const emailList = ["dirlene.martins@latam.com",
      "dirlene.martins@hotmail.com",
      "andrea.ismania@latam.com",
      "renato.morais@latam.com",
      "andreaismania@gmail.com",
      "juanortiz.everis@latam.com",
      "dantelagos.nttdata@latam.com",
      "dantelagos.everis@latam.com"];
    if (emailList.find( e => e === email.toLocaleLowerCase())){
      this.isAllowedEmail = true;
    }
  }

  isNewWaiverModuleRoute() {
    const routeMap: CourtesyPolicyMenuMap = {
      [this.waiversCourtesy]: {
          waiverRequest: this.waiverCourtesyRequest,
          waiverList: this.waiverCourtesyList,
          waiverManual: this.waiverCourtesyManual
        },
      [this.waiversPolicy]: {
        waiverRequest: this.waiverPolicyRequest,
        waiverList: this.waiverPolicyList,
        waiverManual: this.waiverPolicyManual
      },
    };

    this.router.events.subscribe(() => {
      const route = this.router.url;
      const matchedRoute = Object.keys(routeMap).find(key => route.includes(key));

      if (matchedRoute) {
        this.waiverRequestRoute = routeMap[matchedRoute];
        this.isNewWaiverModule = true;
      } else {
        this.isNewWaiverModule = false;
      }
    });
  }

  private countPendingWaivers(sessionInfo: SessionInfo): void {
    if (sessionInfo.is_agree_termsconditions) {
      if (sessionInfo.isLatamSession() && !sessionInfo.isAdminPiaUserLatam()) {
        this.timer = timer(0, 180000);
        this.timerSubscription = this.timer.subscribe(() => {
          this.waiverPostsaleService.countPendingWaivers(new WaiverPage()).subscribe({
            next: data => {
            this.titleService.setTitle(data ? '(' + data + ') LATAM Trade' : 'LATAM Trade');
          }, error: error => {
            console.log('error', error);
          }});
        });
      }
    }
  }

  setSessionInfo(){
    this.sessionInfo = this.sessionInfoService.getSessionInfo();
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  isLogin() {
    if (localStorage.getItem('idToken') && localStorage.getItem('sessionToken')) {
      this.loginService.setCurrentLoginState(true);
    } else {
      this.loginService.setCurrentLoginState(false);
    }
  }

  private startKeepAlive(): void {
    this.keepAliveService.getFutureActionForToken()
      .then(
        action => {
          if (action === 'logout') {
            this.logout();
          } else if (action === 'renew') {
            this.alertExpirationSession();
          }
        }
      );
  }

  closeMenu(): void {
    this.menuOpened = false;
    this.sidenav.close();
    this.setPanelOpened(SidebarMenuModel.MENU_NONE, SidebarMenuModel.MENU_NONE);
  }

  openMenu(): void {
    this.menuOpened = true;
    this.sidenav.open();
  }

  openMenuFromPanel(target: number): void {
    if (target === this.sidebarMenu.panelOpened && this.menuOpened) {
      this.setPanelOpened(SidebarMenuModel.MENU_NONE, SidebarMenuModel.MENU_NONE);
    } else if (!this.menuOpened) {
      this.menuOpened = true;
      this.sidenav.open();
      this.setPanelOpened(target, target);
    }
  }

  setPanelOpened(target: number, source: number): void {
    if (!((source !== this.sidebarMenu.panelOpened) && target === SidebarMenuModel.MENU_NONE)) {
      this.sidebarMenu.panelOpened = target;
    }

    if (this.sidebarMenu.panelOpened === SidebarMenuModel.MENU_NONE) {
      this.lastOpened = source;
    }
  }

  private renewToken(): void {
    console.log('renew Token');
    const refreshToken = localStorage.getItem('refreshToken');
    this.infoUser$
      .then(
        sessionInfo => {
          if (sessionInfo.isAgencySession()) {
            this.loginAgencyService.renewToken(refreshToken).subscribe(
              token => {
                localStorage.setItem('idToken', token);
                this.sessionInfoService.createSessionInfoToken();
                this.startKeepAlive();
              }
            );
          }
          else if (sessionInfo.isLatamExternalSession()){
            console.log()
            this.loginAgencyService.renewToken(refreshToken).subscribe(
              token => {
                localStorage.setItem('idToken', token);
                this.sessionInfoService.createOutsourcingSessionInfoToken();
                this.startKeepAlive();
              }
            )
          }
          else {
            this.loginLatamService.logout();
          }
        }
      )
      .catch(
        error => {
          console.log('error', error);
        }
      );
  }

  private logout(): void {
    this.infoUser$
      .then(
        sessionInfo => {
          if (sessionInfo.isAgencySession()) {
            this.loginAgencyService.logout(sessionInfo.isLatamExternalSession());
          } else {
            this.loginLatamService.logout();
          }
        }
      )
      .catch(
        error => {
          console.log('error', error);
        }
      );
  }

  subMenuItemHovered(event: Event, subMenuHovered: number): void {
    this.subMenuItemsHovered[subMenuHovered] = ['mouseover', 'click'].includes(event.type);
  }

  menuItemHovered(event: Event, menuHovered: number): void {
    this.menuItemsHovered[menuHovered] = (event.type === 'mouseover');
  }


  agencyHasWaiverChanges(){
    const marketId: any = this.sessionInfo.marketId;
    this.waiverPostsaleService.getTypesByMarket(marketId).subscribe({
      next: data => {
        data.forEach((waiverType: WaiverType) => {
          if (waiverType.name.includes('Waiver:')){
            this.isWaiversLatamAvailable = true;
          }
        });
      },
      error: (error: HttpErrorResponse) => {

      }
    });
  }

  get MENU_NONE(): number {
    return SidebarMenuModel.MENU_NONE;
  }

  get MENU_LATAM(): number {
    return SidebarMenuModel.MENU_LATAM;
  }

  get MENU_AGENCIES(): number {
    return SidebarMenuModel.MENU_AGENCIES;
  }

  get MENU_CORRECTNAME():number{
    return SidebarMenuModel.MENU_CORRECTNAME;
  }

  get MENU_WAIVERS(): number {
    return SidebarMenuModel.MENU_WAIVERS;
  }

  get MENU_FARE_REGULATION():number{
    return SidebarMenuModel.MENU_FARE_REGULATION;
  }

  get SUBMENU_LATAM_USER_CREATION(): number {
    return SidebarMenuModel.SUBMENU_LATAM_USER_CREATION;
  }

  get SUBMENU_LATAM_USERS_LIST(): number {
    return SidebarMenuModel.SUBMENU_LATAM_USERS_LIST;
  }

  get SUBMENU_LATAM_FEEDBACK(): number {
    return SidebarMenuModel.SUBMENU_LATAM_FEEDBACK;
  }

  get SUBMENU_LATAM_BUDGET_MANAGEMENT(): number {
    return SidebarMenuModel.SUBMENU_LATAM_BUDGET_MANAGEMENT;
  }

  get SUBMENU_AGENCY_SEARCH(): number {
    return SidebarMenuModel.SUBMENU_AGENCY_SEARCH;
  }

  get SUBMENU_AGENCY_PROFILE(): number {
    return SidebarMenuModel.SUBMENU_AGENCY_PROFILE;
  }

  get SUBMENU_MATRIX_PROFILE(): number {
    return SidebarMenuModel.SUBMENU_MATRIX_PROFILE;
  }

  get SUBMENU_AGENCY_GROUP(): number {
    return SidebarMenuModel.SUBMENU_AGENCY_GROUP;
  }

  get SUBMENU_AGENCY_REGISTER(): number {
    return SidebarMenuModel.SUBMENU_AGENCY_REGISTER;
  }

  get SUBMENU_WAIVER_REQUEST(): number {
    return SidebarMenuModel.SUBMENU_WAIVER_REQUEST;
  }

  get SUBMENU_PRESALE_WAIVER_REQUEST(): number {
    return SidebarMenuModel.SUBMENU_PRESALE_WAIVER_REQUEST;
  }

  get SUBMENU_WAIVER_MANAGEMENT(): number {
    return SidebarMenuModel.SUBMENU_WAIVER_MANAGEMENT;
  }

  get SUBMENU_WAIVER_ONE_DEAL_REQUEST(): number {
    return SidebarMenuModel.SUBMENU_WAIVER_ONE_DEAL_REQUEST;
  }

  get SUBMENU_PRESALE_WAIVER_MANAGEMENT(): number {
    return SidebarMenuModel.SUBMENU_PRESALE_WAIVER_MANAGEMENT;
  }

  get SUBMENU_LATAM_REPORT(): number {
    return SidebarMenuModel.SUBMENU_LATAM_REPORT;
  }

  get SUBMENU_WAIVER_AUTOMATION(): number {
    return SidebarMenuModel.SUBMENU_WAIVER_AUTOMATION;
  }

  get SUBMENU_AGENCY_REPORT(): number {
    return SidebarMenuModel.SUBMENU_AGENCY_REPORT;
  }

  get SUBMENU_WAIVER_TYPE_MANAGEMENT(): number {
    return SidebarMenuModel.SUBMENU_WAIVER_TYPE_MANAGEMENT;
  }
  get SUBMENU_USER_PROFILE(): number {
    return SidebarMenuModel.SUBMENU_USER_PROFILE;
  }

  get SUBMENU_WAIVER_FAREDIFF_MANAGEMENT(): number {
    return SidebarMenuModel.SUBMENU_WAIVER_FAREDIFF_MANAGEMENT;
  }

  get SUBMENU_PARAMETERS(): number {
    return SidebarMenuModel.SUBMENU_PARAMETERS;
  }

  get MENU_VENTA_PORTAL(): number {
    return SidebarMenuModel.MENU_VENTA_PORTAL;
  }

  get SUBMENU_VENTA_PORTAL(): number {
    return SidebarMenuModel.SUBMENU_VENTA_PORTAL;
  }

  get SUBMENU_BRANCH_OFFICE_PROFILE(): number {
    return SidebarMenuModel.SUBMENU_BRANCH_OFFICE_PROFILE;
  }

  get SUBMENU_BULK_LOAD(): number {
    return SidebarMenuModel.SUBMENU_BULK_LOAD;
  }

  get MENU_STATUSTICKET(): number {
    return SidebarMenuModel.MENU_STATUSTICKET;
  }

  get SUBMENU_WAIVER_COURTESY_REQUEST(): number {
    return SidebarMenuModel.SUBMENU_WAIVER_COURTESY_REQUEST;
  }

  get SUBMENU_WAIVER_POLICY_REQUEST(): number {
    return SidebarMenuModel.SUBMENU_WAIVER_POLICY_REQUEST;
  }

  get SUBMENU_WAIVER_MANUAL_MANAGEMENT(): number {
    return SidebarMenuModel.SUBMENU_WAIVER_MANUAL_MANAGEMENT;
  }

  private alertExpirationSession(): void {
    let timerInterval;
    let textButton = this.translateService.instant('button.renewSession');

    this.infoUser$
      .then(
        sessionInfo => {
          if (sessionInfo.isLatamSession()) {
            textButton = this.translateService.instant('button.signIn');
          }

          swal.fire({
            title: `<span class="icon-session-time icon-light-uid042"></span>
            <p class="session-modal-title">${ this.translateService.instant('areYouThere')}</p>`,
            html: `<p>${this.translateService.instant('expireSession')}<br><b id="sessionCountdown" class="session-countdown-number"></p>`,
            timer: 60000,
            onOpen: () => {
              timerInterval = setInterval(() => {
                swal.getContent().querySelector('#sessionCountdown').textContent = String(Math.ceil(swal.getTimerLeft() / 1000));
              }, 1000);
            },
            onClose: () => {
              clearInterval(timerInterval);
            },
            confirmButtonText: textButton,
            confirmButtonColor: '#d33',
            allowOutsideClick: false,
          }).then((result) => {
            if (result.value) {
              this.renewToken();
            } else if (result.dismiss) {
              this.logout();
            }
          });
        }
      )
      .catch(
        error => {
          console.log('error', error);
        }
      );
  }
}

<ul class="cb-slideshow">
  <li>
    <span>Image 01</span>
    <div>
      <h3>
        <img src="assets/images/error-page/room.png" alt="usa"/>
        <strong>{{ 'login.city.newYork' | translate }}</strong>, {{ 'login.country.usa' | translate }}
      </h3>
    </div>
  </li>
  <li>
    <span>Image 02</span>
    <div>
      <h3>
        <img src="assets/images/error-page/room.png" alt="chile"/>
        <strong>{{ 'login.city.santiago' | translate }}</strong>, {{ 'login.country.chile' | translate }}
      </h3>
    </div>
  </li>
  <li>
    <span>Image 03</span>
    <div>
      <h3>
        <img src="assets/images/error-page/room.png" alt="italy"/>
        <strong>{{ 'login.city.milan' | translate }}</strong>, {{ 'login.country.italy' | translate }}
      </h3>
    </div>
  </li>
  <li>
    <span>Image 04</span>
    <div>
      <h3>
        <img src="assets/images/error-page/room.png" alt="spain"/>
        <strong>{{ 'login.city.madrid' | translate }}</strong>, {{ 'login.country.spain' | translate }}
      </h3>
    </div>
  </li>
  <li>
    <span>Image 05</span>
    <div>
      <h3>
        <img src="assets/images/error-page/room.png" alt="brazil"/>
        <strong>{{ 'login.city.rioJaneiro' | translate }}</strong>, {{ 'login.country.brazil' | translate }}
      </h3>
    </div>
  </li>
  <li>
    <span>Image 06</span>
    <div>
      <h3>
        <img src="assets/images/error-page/room.png" alt="argentina"/>
        <strong>{{ 'login.city.buenosAires' | translate }}</strong>, {{ 'login.country.argentina' | translate }}
      </h3>
    </div>
  </li>
</ul>

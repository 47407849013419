
  <div class="info-container">
    <div  class="Center-image">
      <button mat-dialog-close mat-icon-button  >
        <img  src="assets/images/correct-name/Close.svg" />
      </button>      
    </div>    
    <div class="confirmChange">
      <img  src="assets/images/correct-name/confirmChangeName.svg" />
    </div>

    <span class="subtitle-name">
      {{ 'correctName.confirmChangeName' | translate }}
    </span>  

    <div class="container">          

            <div class="image-center">          
              <div class="check-confirm">               
                
                  <img  src="assets/images/correct-name/lineAndCross.svg" />
               
                <div class="center-text">                
                  <span id="name">
                    {{ 'correctName.withErrors' | translate }}
                    <br>
                  </span> 
                  <span id="fullName">
                    {{ oldFullName}}
                  </span> 
                  <span>  </span>
                </div>                
                                  
              </div>    
              <div class="check-confirm">
                <img  src="assets/images/correct-name/check.svg" />
                <div class="center-text"> 
                  <span id="name" > 
                    {{ 'correctName.correctNames' | translate }}
                    <br>
                  </span>
                  <span id="fullName">
                    {{ newFullName}}
                  </span> 
                </div>   
              </div>         
            </div>    
            
            <div class="button-container">
              <button mat-dialog-close class="my-custom-button"
                    style="color:#1B0088;   background-color:#ffffff !important; border-color: #1B0088;" mat-stroked-button mat-flat-button >
                      <span id="button-modal">
                        {{ 'correctName.returnEdition' | translate }}
                      </span>                
              </button>
              <button class="my-custom-button" (click)="confirmCorrection()" 
                    style="color:#ffffff !important; background-color:#e91e63;" mat-stroked-button mat-flat-button >
                      <span id="button-modal">
                          {{ 'correctName.confirmCorrection' | translate }}
                      </span>                
              </button>
            </div>
           
            
    </div>  
  </div>

export class ChangeNameFlightSegment {
  departureDateTime: string;
  flightNumber: string;   
  departureAirport: string;   
  arrivalAirport:string;
  marketingAirlineCode: string;
  operatingAirlineCode: string;
  segmentNumber: string;

  constructor(
    departureDateTime: string,
    flightNumber: string,
    departureAirport: string,
    arrivalAirport:string,
    marketingAirlineCode: string,
    operatingAirlineCode: string, 
    segmentNumber: string
  ){
      this.departureDateTime = departureDateTime;
      this.flightNumber = flightNumber;
      this.departureAirport = departureAirport;
      this.arrivalAirport = arrivalAirport;
      this.marketingAirlineCode = marketingAirlineCode;
      this.operatingAirlineCode = operatingAirlineCode;
      this.segmentNumber = segmentNumber;
  }

}
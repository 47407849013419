import { MarketService } from './../../../../services/market.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';

import { BusinessException, Exception, SystemException } from '../../../../models/Exception.model';
import { Language } from '../../../../models/language.model';
import { LatamRole } from '../../../../models/latam-role.model';
import { Market } from '../../../../models/market.model';
import { Profile } from '../../../../models/profile.model';
import { SidebarMenuModel } from '../../../../models/SidebarMenu.model';
import { UserLatam } from '../../../../models/user-latam.model';
import { UserLatamPosition } from '../../../../models/user-latam-position.model';

import { LatamService } from '../../../../services/latam.service';
import { MessageService } from './../../../../services/message.service';
import { ProfilingService } from '../../../../services/profiling.service';
import { SessionInfoService } from '../../../../services/session-info.service';
import { SidebarShareDataService } from '../../../../services/sidebar-share-data.service';
import { MessageErrorService } from '../../../../services/message-error.service';


@Component({
  selector: 'app-edit-latam-user',
  templateUrl: './edit-latam-user.component.html',
  styleUrls: ['./edit-latam-user.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EditLatamUserComponent implements OnInit {
  @ViewChild('createLatamUserForm') createLatamUserForm: NgForm;
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_LATAM, SidebarMenuModel.SUBMENU_LATAM_USER_CREATION);
  markets: Market[] = [];
  userMarketIds: number | number[];
  userLatamPositions: UserLatamPosition[];
  latamRoles: LatamRole[];
  languages: Language[];
  userLatam: UserLatam = new UserLatam();
  loading: boolean = false;
  userId: number;
  isAdmin: boolean = false;
  profiles = new Profile();
  postNotAllowed = ['PR', 'PA', 'CR'];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private latamService: LatamService,
    private marketService: MarketService,
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private profilesService: ProfilingService,
    private sessionInfoService: SessionInfoService,
    private sidebarShareDataService: SidebarShareDataService,
    private translateService: TranslateService) {
  }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.loading = true;
    this.checkIfIsAdmin();
    this.activatedRoute.queryParams.subscribe(params => {
      this.userId = params.id;
      this.latamService.getUserLatamById(this.userId).subscribe(
        data => {
          this.userLatam = data;
          this.loading = false;
        },
        (error: HttpErrorResponse) => {
          this.messageErrorService.sendError(error, 'error.loadingUser');

          this.returnToList();
          this.loading = false;
        });
    });

    this.latamService.getUserLatamPositions().subscribe((data: UserLatamPosition[]) => {
      this.userLatamPositions = data;
    });

    this.marketService.getMarkets().subscribe((data: Market[]) => {
      this.markets = this.getMarketsFilter(data);
    });

    this.sessionInfoService.getSessionInfoAsPromise()
      .then(
        session => {
          this.userMarketIds = session.marketId;
          this.loadMarkets();

          this.latamService.getRolesLatam().subscribe(
            data => {
              if (session.isAdminPiaUserLatam()) {
                this.latamRoles = data.filter(role => role.id > 3);
              } else {
                this.latamRoles = data.filter((element, index, array) => {
                  return session.isUserRoleIdGreaterThan(element.id);
                });
              }
            },
            (error: HttpErrorResponse) => {
              console.log('error', error);
            }
          );
        }
      )
      .catch(
        error => {
          console.log('error', error);
        }
      );

    this.latamService.getLanguages().subscribe((data: Language[]) => {
      this.languages = data;
    });
  }

  private getMarketsFilter(data: Market[]){
    return data.filter((market: Market) => !this.postNotAllowed.includes(market.name));
  }

  compareMarkets(market1: Market, market2: Market) {
    return market1 && market2 ? market1.id === market2.id : market1 === market2;
  }

  compareRoles(role1: LatamRole, role2: LatamRole) {
    return role1 && role2 ? role1.id === role2.id : role1 === role2;
  }

  comparePositions(position1: UserLatamPosition, position2: UserLatamPosition) {
    return position1 && position2 ? position1.id === position2.id : position1 === position2;
  }

  compareLanguages(language1: Language, language2: Language) {
    return language1 && language2 ? language1.id === language2.id : language1 === language2;
  }

  private loadMarkets() {
    this.marketService.getMarkets().subscribe(
      data => {
        this.markets = this.getMarketsFilter(data);
        if (!this.isAdmin){
          if (typeof this.userMarketIds === 'number') {
            const userMarketIdsAux: number = this.userMarketIds;
            this.markets = this.markets.filter(item => userMarketIdsAux === item.id);
          } else {
            const userMarketIdsAux: number[] = this.userMarketIds;
            this.markets = this.markets.filter(item => userMarketIdsAux.indexOf(item.id) >= 0);
          }
        }
      },
      (error: HttpErrorResponse) => {
        console.log('error', error);
      }
    );
  }

  updateUserLatam(): void {
    this.loading = true;
    this.latamService.updateUserLatam(this.userLatam.id, this.userLatam).subscribe(
      data => {
        this.loading = false;
        this.showSuccessMessage();
      },
      (error: HttpErrorResponse) => {
      this.messageErrorService.sendError(error, 'error.updateUser');

      this.loading = false;
    });
  }

  /**
   * shows a message of success
   */
  showSuccessMessage() {
    swal.fire({
      title: '<p>' + this.translateService.instant('popup.wellDone') + '</p>',
      html: '<p>' + this.translateService.instant('success.editUser') + '</p>',
      confirmButtonText: this.translateService.instant('button.confirm'),
      confirmButtonColor: '#d33',
      icon: 'success',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        this.router.navigate(['/latam/users/list']);
      }
    });
  }

  returnToList() {
    // Se incorpora this.activatedRoute , queryParams para que actualize al hacer el volver en register si hay cambios
    this.router.navigate(['../list'], { relativeTo: this.activatedRoute });
  }

  checkIfIsAdmin(){
    this.sessionInfoService.getSessionInfoAsPromise()
      .then(
        sessionInfo => {
          if (sessionInfo.role === 'ADMIN') {
              this.isAdmin = true;
          } else {
            this.isAdmin = false;
          }
    });

  }
}

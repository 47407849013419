
import {share} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { environment } from '../../environments/environment';

import { Market } from '../models/market.model';

import { RouteLocaleExtractorService } from './route-locale-extractor.service';
import { SessionInfoService } from './session-info.service';
import { StorageService } from './storage.service';


@Injectable()
export class MarketService {
  headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  urlBase = `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlAgency}`;

  constructor(
    private http: HttpClient,
    private routeLocaleExtractorService: RouteLocaleExtractorService,
    private sessionInfoService: SessionInfoService) { }

  /**
   * Obtiene un market por su nombre
   * @param name
   * @returns `Observable<Market>`
   */
  getMarketByName(name: string): Observable<Market> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<Market>(`${this.urlBase}${locale}/markets/name?marketName=${name}`);
  }

  /**
   * Obtiene el listado de mercados
   * @returns `Observable<Market[]>`
   */
  getMarkets(): Observable<Market[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<Market[]>(`${this.urlBase}${locale}/markets`);
  }

  /**
   * Obtiene el market de la sesión del usuario por su identificador
   * @returns `Observable<Market>`
   */
  getSessionMarketById(): Observable<Market> {
    const locale = this.routeLocaleExtractorService.getLocale();
    const sessionInfo = this.sessionInfoService.getSessionInfo();

    if (sessionInfo != null) {
      return this.http.get<Market>(`${this.urlBase}${locale}/markets/${sessionInfo.marketId}`);
    }
  }

  getSessionMarkets(): Observable<Market[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<Market[]>(`${this.urlBase}${locale}/session/markets`);
  }

  saveMarkets(market: Market[]): Observable<Market> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.put<Market>(`${this.urlBase}${locale}/markets/list`, market, { headers: this.headers }).pipe(share());
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';

import { Agency } from '../../models/agency.model';
import { Profile } from '../../models/profile.model';

import { AgencyBranchOfficeService } from './../../services/agency-branch-office.service';
import { AgencyService } from '../../services/agency.service';
import { ProfilingService } from '../../services/profiling.service';
import { MessageErrorService } from '../../services/message-error.service';


@Component({
  selector: 'app-associated-iatas',
  templateUrl: './associated-iatas-list.component.html',
  styleUrls: ['./associated-iatas-list.component.css']
})
export class AssociatedIatasListComponent implements OnInit {
  loading = false;
  agenciesDataFound = false;

  selectedRowIndex = -1;
  private maxLength = 10;

  displayedColumns = ['name', 'iata', 'tier', 'city', 'executive', 'actions'];

  dataSource = new MatTableDataSource();
  private profiles = new Profile();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() matrixId: number;
  @Input() branchOfficeId: number;

  loadingAgenciesDataError = (index: number, item: { errorLoadingData: boolean }) => item.errorLoadingData;
  noAgenciesDataFound = (index: number, item: { noData: boolean }) => item.noData;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private agencyBranchOfficeService: AgencyBranchOfficeService,
    private agencyService: AgencyService,
    private messageErrorService: MessageErrorService,
    private profilingService: ProfilingService) { }

  ngOnInit() {
    this.profiles = this.profilingService.getProfile();

    if (this.branchOfficeId) {
      this.getAgencyListByBranchOfficeId(this.branchOfficeId, 0, this.maxLength);
    } else {
      this.getAgencyListByAgencyGroupId(this.matrixId, 0, this.maxLength);
    }
  }

  private getAgencyListByAgencyGroupId(groupId: number, pageIndex: number, pageSize: number): void {
    this.loading = true;
    this.agenciesDataFound = false;

    this.agencyService.getAgencyListByAgencyGroupId(groupId, pageIndex, pageSize).subscribe({
      next: data => {
        if (data.agencySimplified.length === 0) {
          this.dataSource.data = [{ noData: true }];
        } else {
          this.agenciesDataFound = true;
          setTimeout(() => {
            this.dataSource.data = data.agencySimplified;
            this.dataSource.sort = this.sort;
            this.paginator.length = data.pagination.totalElements;
            this.paginator.pageIndex = data.pagination.pageIndex;
            this.paginator.pageSize = data.pagination.pageSize;
            this.dataSource.sortingDataAccessor = this.sortData();
          });
        }

        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.dataSource.data = [{ errorLoadingData: true }];
        this.messageErrorService.sendError(error, 'error.loadingPartnerAgencies');

        this.loading = false;
        console.log('error', error);
      }
  });
  }

  private sortData(): (dataAux: Agency, header: any) => number{
    return (dataAux: Agency, header) => {

      let transformedData: number;

      if (header === 'tier') {
        transformedData = dataAux.tierLevel;
      } else {
        if (!isNaN(dataAux[header]) || dataAux[header] == null) {
          transformedData = dataAux[header];
        } else {
          transformedData = dataAux[header].toLocaleLowerCase();
        }
      }
      return transformedData;
    };
  }

  private getAgencyListByBranchOfficeId(branchOfficeId: number, pageIndex: number, pageSize: number): void {
    this.loading = true;
    this.agenciesDataFound = false;

    this.agencyBranchOfficeService.getAgencyListByBranchOfficeId(branchOfficeId, pageIndex, pageSize).subscribe({
      next: data => {
        if (data.agencySimplified.length === 0) {
          this.dataSource.data = [{ noData: true }];
        } else {
          this.agenciesDataFound = true;
          setTimeout(() => {
            this.paginator.length = data.pagination.totalElements;
            this.paginator.pageIndex = data.pagination.pageIndex;
            this.paginator.pageSize = data.pagination.pageSize;
            this.dataSource.data = data.agencySimplified;
            this.dataSource.sort = this.sort;
            this.dataSource.sortingDataAccessor = this.sortData();
          });
        }

        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.dataSource.data = [{ errorLoadingData: true }];
        this.messageErrorService.sendError(error, 'error.loadingPartnerAgencies');

        this.loading = false;
        console.log('error', error);
      }
  });
  }

  reloadAgencies(): void {
    let pageIndex = 0;
    let pageSize = this.maxLength;

    if (this.paginator) {
      pageIndex = this.paginator.pageIndex;
      pageSize = this.paginator.pageSize;
    }

    if (this.branchOfficeId) {
      this.getAgencyListByBranchOfficeId(this.branchOfficeId, pageIndex, pageSize);
    } else {
      this.getAgencyListByAgencyGroupId(this.matrixId, pageIndex, pageSize);
    }
  }

  pageEvent(event: PageEvent): void {
    if (this.branchOfficeId) {
      this.getAgencyListByBranchOfficeId(this.branchOfficeId, event.pageIndex, event.pageSize);
    } else {
      this.getAgencyListByAgencyGroupId(this.matrixId, event.pageIndex, event.pageSize);
    }
  }

  /**
   * Function to open the component with the profile of the agency 'ProfileAgencyComponent'
   */
  profileAgency(agencyId: number): void {
    if (this.profiles.readAgencyProfile) {
      this.router.navigate(
        ['/agency/profile'],
        { relativeTo: this.activatedRoute, queryParams: { agencyId: agencyId } }
      );
    } else if (this.profiles.readAgencyProfileLT) {
      this.router.navigate(
        ['/latam/agencies/profile'],
        { relativeTo: this.activatedRoute, queryParams: { agencyId: agencyId, groupId: this.matrixId } }
      );
    }
   }

   highlightRow(event: Event, rowIndex: number): void {
    this.selectedRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

}

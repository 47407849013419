<div class="content">
    <mat-horizontal-stepper [linear]="true" #stepper>
            <mat-step>
                <div class="search-main-container">
                    <div class="test">
                        <span class="search-by">{{ 'waiverLatam.searchBy' | translate}}</span>
                        <div class="search-inputs">
                            <form [formGroup]="recordLocatorForm" (ngSubmit)="loadBudget()">
                                <mat-form-field appearance="outline" >
                                    <mat-select value="PNR" formControlName="selection">
                                        <mat-option value="PNR">PNR</mat-option>
                                        <mat-option value="TKT">TKT</mat-option>
                                    </mat-select>

                                    <mat-error *ngIf="recordLocatorForm.get('selection').hasError('required')">
                                        {{ 'field.error.required' | translate }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" *ngIf="recordLocatorForm.get('selection').value === 'PNR'">
                                    <mat-label>PNR</mat-label>
                                    <input
                                        matInput
                                        maxlength="6"
                                        formControlName="pnrValue"
                                        [disabled]="loadingReservationInfo"
                                    >
                                    <mat-error
                                        *ngIf="recordLocatorForm.get('pnrValue').hasError('required')">
                                            {{ 'field.error.required' | translate }}
                                    </mat-error>
                                    <mat-error
                                        *ngIf="recordLocatorForm.get('pnrValue').hasError('pattern')">
                                            {{ 'field.error.invalidPnr' | translate }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" *ngIf="recordLocatorForm.get('selection').value === 'TKT'">
                                    <mat-label>TKT</mat-label>
                                    <input
                                        matInput
                                        maxlength="13"
                                        formControlName="tktValue"
                                        [disabled]="loadingReservationInfo"
                                    >
                                    <mat-error
                                        *ngIf="recordLocatorForm.get('tktValue').hasError('required')">
                                            {{ 'field.error.required' | translate }}
                                    </mat-error>
                                    <mat-error  *ngIf="recordLocatorForm.get('tktValue').hasError('pattern')">
                                            {{ 'field.error.invalidTkt' | translate }}
                                    </mat-error>
                                </mat-form-field>

                                <button mat-flat-button [disabled]="loadingReservationInfo || isLoading() || isLoadingAgencyBudget">
                                        <span *ngIf="!loadingReservationInfo && !isLoading() && !isLoadingAgencyBudget">
                                            {{ 'button.search' | translate }}
                                        </span>
                                        <mat-progress-spinner
                                            *ngIf="loadingReservationInfo || isLoading() || isLoadingAgencyBudget"
                                            class="custom-spinner"
                                            mode="indeterminate"
                                            diameter="20">
                                        </mat-progress-spinner>
                                </button>
                            </form>
                        </div>
                    </div>

                    <div class="info">
                        <img  src="assets/images/waiver-latam/search.svg" />
                        <h2>
                            {{'waiverLatam.howToRequest' | translate}}
                        </h2>
                        <p>{{'waiverLatam.howToRequestDescription1' | translate}}<b>(PNR)</b>{{'waiverLatam.howToRequestDescription2' | translate}}<b>(TKT)</b>
                            {{'waiverLatam.howToRequestDescription3' | translate}}</p>
                        <p>{{'waiverLatam.howToRequestDescription4' | translate}}</p>
                        <p>{{'waiverLatam.howToRequestDescription5' | translate}}</p>
                        <p>{{'waiverLatam.howToRequestDescription6' | translate}}</p>
                    </div>
                </div>
            </mat-step>
            <mat-step>
                <div class="reservation-info-container">
                    <div *ngIf="isLoading()" class="loader loading">
                        <div class="loading-div">
                        <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                        </svg>
                        <span class="loading-span">{{ 'loading' | translate }}</span>
                        </div>
                    </div>
                    <a (click)="stepperBack(1)" *ngIf="!isLoading() && !loadingReservationInfo && isAgencyBudgetValid" class="pointer">
                        <span class="go-back centered"><mat-icon>arrow_back_ios</mat-icon> {{ 'button.back' | translate}}</span>
                    </a>
                    <span *ngIf="(validPostPNR && passengerTicketRequestsCompleted == dataPassengers.data.length && passengerTicketRequestsWithError == 0) && !loadingReservationInfo && isAgencyBudgetValid && !isLoading()"
                        class="subtitle-waiver">
                        {{ 'bookingInformation' | translate }}

                    </span>
                    <div *ngIf="(validPostPNR && passengerTicketRequestsCompleted == dataPassengers.data.length && passengerTicketRequestsWithError == 0) && !loadingReservationInfo && isAgencyBudgetValid && !isLoading()"  class="info-container">
                        <!-- Reservation INFO -->
                        <ng-container id="pnrInfoDiv">

                                <mat-table #table [dataSource]="dataReservation">
                                    <ng-container matColumnDef="pnrNumber">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.pnr' | translate }}</mat-header-cell>
                                        <mat-cell class="indigo-light latam-bold" *matCellDef="let data">{{ data.pnr.pnrNumber | dashWhenEmpty }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="pnrType">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.pnrType' | translate }}</mat-header-cell>
                                        <mat-cell class="indigo-light latam-bold" *matCellDef="let data">{{ data.pnr.type | dashWhenEmpty }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="bookingCreation">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.bookingCreation' | translate }}</mat-header-cell>
                                        <mat-cell class="indigo-dark latam-bold" *matCellDef="let data">
                                            {{ data.pnr.createDateTime | dateFormat:'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="NumberOfPassengers">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.numberPassengers' | translate }}</mat-header-cell>
                                        <mat-cell class="indigo-dark latam-bold" *matCellDef="let data">{{ dataPassengers?.data?.length }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="pos">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.pos' | translate }}</mat-header-cell>
                                        <mat-cell class="indigo-dark latam-bold" *matCellDef="let data">{{ data.booking.pos | dashWhenEmpty }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="iata">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.iata' | translate }}</mat-header-cell>
                                        <mat-cell class="indigo-dark latam-bold"  *matCellDef="let data">{{ data.booking.iata | dashWhenEmpty }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="pcc">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.pcc' | translate }}</mat-header-cell>
                                        <mat-cell class="indigo-dark latam-bold" *matCellDef="let data">{{ data.booking.ppc | dashWhenEmpty }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="officeId">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.officeId' | translate }}</mat-header-cell>
                                        <mat-cell class="indigo-dark latam-bold" *matCellDef="let data">{{ data.booking.officeId | dashWhenEmpty }}</mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="displayedColumnsR" class="waiver-post-row"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumnsR; let i = index"
                                    [ngClass]="{ 'highlight': selectedReservationInformationRowIndex === i }"
                                    (mouseout)="highlightReservationInformationRow($event, i)"
                                    (mouseover)="highlightReservationInformationRow($event, i)" class="waiver-post-row"></mat-row>
                                </mat-table>
                        </ng-container>

                    </div>

                    <span *ngIf="(validPostPNR && passengerTicketRequestsCompleted == dataPassengers.data.length && passengerTicketRequestsWithError == 0) && !loadingReservationInfo && !isLoading() && isAgencyBudgetValid"
                        class="subtitle-waiver">{{ 'passengerInfo' | translate }}
                    </span>
                    <div *ngIf="(validPostPNR && passengerTicketRequestsCompleted == dataPassengers.data.length && passengerTicketRequestsWithError == 0) && !loadingReservationInfo && !isLoading() && isAgencyBudgetValid"
                        class="info-container selection"
                        id="waiver-auto-info-passenger"
                    >
                        <!-- informacion de pasajeros pos -->
                        <ng-container id="postSalePNRPassengersDiv">
                            <mat-table id="passengersPostPNRTable" #table [dataSource]="dataPassengers">
                                    <ng-container matColumnDef="passengerName">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.passengerName' | translate }}</mat-header-cell>
                                        <mat-cell [ngClass]="{ 'mat-cell-invalid': data.invalidTkt||data.allCouponsInvalid || data.infantTkt }"
                                            id="passenger-name"
                                            *matCellDef="let data">
                                            {{ (data.firstName | partialDisplay: '#' : 1 | dashWhenEmpty) + ' ' + (data.lastName | partialDisplay: '#' : 1 | dashWhenEmpty) }}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="foid">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.foid' | translate }}</mat-header-cell>
                                        <mat-cell [ngClass]="{ 'mat-cell-invalid': data.invalidTkt||data.allCouponsInvalid || data.infantTkt }"
                                            id="foid"
                                            *matCellDef="let data">{{ data.foid | partialDisplay:'#' :-2  | dashWhenEmpty }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="passengerType">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.passengerType' | translate }}
                                        </mat-header-cell>
                                        <mat-cell [ngClass]="{ 'mat-cell-invalid': data.invalidTkt||data.allCouponsInvalid || data.infantTkt }"
                                            id="passenger-type"
                                            *matCellDef="let data">{{ data.type | dashWhenEmpty }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="tkt">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.tkt' | translate }}</mat-header-cell>
                                        <mat-cell [ngClass]="{ 'mat-cell-invalid': data.invalidTkt||data.allCouponsInvalid || data.infantTkt }"
                                             id="passenger-tkt"
                                            *matCellDef="let data">{{ data.ticketNumber | dashWhenEmpty }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="select" id="select-passenger">
                                        <mat-header-cell *matHeaderCellDef class="pt-2">
                                                <mat-checkbox color="accent" [checked]="selectionPassengers.hasValue() && isAllSelectedPassengers()"
                                                [disabled]="allPassengersInvalid"
                                                [indeterminate]="selectionPassengers.hasValue() && !isAllSelectedPassengers()"
                                                (change)="$event ? masterTogglePassengers() : null">
                                                </mat-checkbox>
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let row" class="pt-2">
                                            <div id="tooltipPassenger" *ngIf="!(row.infantTkt)" matTooltip="{{ 'tooltip.notHaveTkt' | translate }}"
                                            matTooltipPosition="before"
                                            [matTooltipDisabled]="!(row.invalidTkt || row.allCouponsInvalid || row.infantTkt)">
                                                <mat-checkbox (click)="$event.stopPropagation()" color="accent"
                                                    (change)="$event ? toggleSelectionPassengerPost(row) : null"
                                                    [checked]="selectionPassengers.isSelected(row)"
                                                    [disabled]="row.invalidTkt || row.allCouponsInvalid || row.infantTkt">
                                                </mat-checkbox>
                                            </div>

                                            <div id="tooltipPassengerINF" *ngIf="row.infantTkt"
                                            matTooltip="{{ 'tooltip.adultPassengerRequired' | translate }}" matTooltipPosition="before"
                                            [matTooltipDisabled]="!(row.infantTkt)">
                                                <mat-checkbox color="accent" [checked]="selectionPassengers.isSelected(row)"
                                                    [disabled]="row.invalidTkt || row.allCouponsInvalid || row.infantTkt"
                                                    (change)="$event ? toggleSelectionPassengerPost(row) : null" (click)="$event.stopPropagation()">
                                                </mat-checkbox>
                                            </div>
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="displayedColumnsPPOS" class="waiver-post-row"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumnsPPOS; let i = index"
                                    [ngClass]="{ 'highlight': selectedPassengerInformationRowIndex === i || selectionPassengers.isSelected(row) }"
                                    (click)="toggleSelectionPassengerPost(row)" (mouseout)="highlightPassengerInformationRow($event, i)"
                                    (mouseover)="highlightPassengerInformationRow($event, i)" class="waiver-post-row">
                                    </mat-row>
                                </mat-table>
                        </ng-container>
                    </div>
                    <span *ngIf="(validPostPNR && passengerTicketRequestsCompleted == dataPassengers.data.length && passengerTicketRequestsWithError == 0) && !loadingReservationInfo && !isLoading() && isAgencyBudgetValid" class="subtitle-waiver">
                        <img  src="assets/images/waiver-latam/airplane.svg" />
                        {{ 'segmentsInformation' | translate }}
                    </span>
                    <div *ngIf="(validPostPNR && passengerTicketRequestsCompleted == dataPassengers.data.length && passengerTicketRequestsWithError == 0) && !loadingReservationInfo && !isLoading() && isAgencyBudgetValid"
                        class="info-container selection"
                        id="waiver-auto-info-segments"
                    >
                        <!-- informacion segmento -->
                        <div id="pnr-segments-div">

                            <mat-table id="segmentsTable" #table [dataSource]="dataSegments">
                                <ng-container matColumnDef="segmentNumber">
                                <mat-header-cell *matHeaderCellDef>{{ 'field.segmentNumber' | translate }}</mat-header-cell>
                                <mat-cell
                                    [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                                    *matCellDef="let data">{{ data.segmentNumber | dashWhenEmpty }}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="carrierMkt" id="carrier-mkt">
                                    <mat-header-cell *matHeaderCellDef>{{ 'field.mktCarrier' | translate }}</mat-header-cell>
                                    <mat-cell
                                        [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0}"
                                        *matCellDef="let data">{{ data.carrierMarketing | dashWhenEmpty }}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="flightNumberMkt" id="flight-number-mkt">
                                    <mat-header-cell *matHeaderCellDef>{{ 'field.mktFlightNumber' | translate }}</mat-header-cell>
                                    <mat-cell
                                        [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                                        *matCellDef="let data">{{ data.flightNumber | dashWhenEmpty }}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="class" id="class">
                                    <mat-header-cell *matHeaderCellDef>{{ 'field.class' | translate }}</mat-header-cell>
                                    <mat-cell
                                        [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                                        *matCellDef="let data">{{ data.class | dashWhenEmpty }}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="origin" id="flight-origin">
                                    <mat-header-cell *matHeaderCellDef>{{ 'field.from' | translate }}</mat-header-cell>
                                    <mat-cell
                                        [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0}"
                                        *matCellDef="let data">{{ data.source | dashWhenEmpty }}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="destination" id="flight-destination">
                                    <mat-header-cell *matHeaderCellDef>{{ 'field.to' | translate }}</mat-header-cell>
                                    <mat-cell
                                        [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0}"
                                        *matCellDef="let data">{{ data.destination | dashWhenEmpty }}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="dateHourDeparture" id="flight-date-hour-departure">
                                    <mat-header-cell *matHeaderCellDef>{{ 'field.departureDate' | translate }}</mat-header-cell>
                                    <mat-cell
                                        [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                                        *matCellDef="let data">
                                        {{ data.departureDateTime | dateFormatWithoutLocale: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="status" id="flight-status">
                                    <mat-header-cell *matHeaderCellDef>{{ 'field.segmentStatus' | translate }}</mat-header-cell>
                                    <mat-cell
                                        [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                                        *matCellDef="let data">{{ data.status | dashWhenEmpty }}</mat-cell>
                                </ng-container>

                                <!-- Este campo no se debe mostrar, pero permite obtener informacion del farebasis NO eliminar -->
                                <ng-container matColumnDef="fareBasis" id="farebasis">
                                    <mat-header-cell *matHeaderCellDef [hidden]="true">{{ 'field.farebasis' | translate }}</mat-header-cell>
                                    <mat-cell *matCellDef="let data" [hidden]="true">{{ data.fareBasis | dashWhenEmpty }}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="select">
                                <mat-header-cell *matHeaderCellDef class="pt-2">
                                    <mat-checkbox color="accent" [checked]="selectionSegments.hasValue() && isAllSelectedSegments()"
                                    [disabled]="(segmentsWithOneCouponInvalidQuantity + invalidStatusSegmentsQuantity) === dataSegments.data.length"
                                    [indeterminate]="selectionSegments.hasValue() && !isAllSelectedSegments()"
                                    (change)="$event ? masterToggleSegments() : null;">
                                    </mat-checkbox>
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="pt-2">
                                    <div id="tooltipSegmentPOST"
                                        matTooltip="{{ 'tooltip.invalidSegmentCoupon' | translate }}" matTooltipPosition="before"
                                        [matTooltipDisabled]="!(!validSegmentStatusList.includes(row.status) || row.invalidCoupons > 0)">
                                        <mat-checkbox color="accent" [checked]="selectionSegments.isSelected(row)"
                                            [disabled]="!validSegmentStatusList.includes(row.status) || row.invalidCoupons > 0"
                                            (change)="$event ? toggleSelectionSegmentPost(row) : null;" (click)="$event.stopPropagation()">
                                        </mat-checkbox>
                                    </div>
                                </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumnsS" class="waiver-post-row"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumnsS; let i = index"
                                        [ngClass]="{ 'highlight': selectedSegmentsInformationRowIndex === i || selectionSegments.isSelected(row)}"
                                        (click)="toggleSelectionSegmentPost(row)" (mouseout)="highlightSegmentsInformationRow($event, i)"
                                        (mouseover)="highlightSegmentsInformationRow($event, i)" class="waiver-post-row">
                                </mat-row>
                            </mat-table>
                        </div>
                    </div>

                    <span class="subtitle-waiver"
                        *ngIf="validPostTKT && !loadingReservationInfo && !isLoading() && isAgencyBudgetValid"
                    >
                        {{ 'tktInformation' | translate }}
                    </span>
                    <div *ngIf="validPostTKT && !loadingReservationInfo && !isLoading() && isAgencyBudgetValid"
                        class="info-container"
                        id="waiver-auto-info-tkt"
                    >
                        <!-- informacion ticket -->
                        <ng-container id="tkt-info-container">
                                <mat-table #table [dataSource]="dataTkt">
                                    <ng-container matColumnDef="tktNumber" id="tkt-number">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.tkt' | translate }}</mat-header-cell>
                                        <mat-cell *matCellDef="let data">{{ data.tkt.tktNumber | dashWhenEmpty }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="pnrNum" id="pnr-value">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.pnr' | translate }}</mat-header-cell>
                                        <mat-cell *matCellDef="let data">{{ data.tkt.pnr | dashWhenEmpty }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="emisionCreationDate" id="emission-date">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.issueDate' | translate }}</mat-header-cell>
                                        <mat-cell *matCellDef="let data">
                                            {{ data.tkt.createDateTime | dateFormat: 'DD-MMM-YY' | uppercase | dashWhenEmpty }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="posInfo" class="posInfo" id="pos-info">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.pos' | translate }}</mat-header-cell>
                                        <mat-cell *matCellDef="let data">{{ data.issuer.pos | dashWhenEmpty }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="iataInfo" class="iataInfo" id="iata-info">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.iata' | translate }}</mat-header-cell>
                                        <mat-cell *matCellDef="let data">{{ data.booking.iata | dashWhenEmpty }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="pccInfo" class="pccInfo" id="pcc-info">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.pcc' | translate }}</mat-header-cell>
                                        <mat-cell *matCellDef="let data">{{ data.booking.ppc | dashWhenEmpty }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="officeIdInfo" class="officeIdInfo" id="office-id-info">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.officeId' | translate }}</mat-header-cell>
                                        <mat-cell *matCellDef="let data">{{ data.booking.officeId | dashWhenEmpty }}</mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="displayedColumnsTKT" class="waiver-post-row"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumnsTKT; let i = index"
                                        [ngClass]="{ 'highlight': selectedTktInformationRowIndex === i }"
                                        (mouseover)="highlightTktInformationRow($event, i)" (mouseout)="highlightTktInformationRow($event, i)"
                                        class="waiver-post-row">
                                    </mat-row>
                                </mat-table>
                        </ng-container>
                    </div>

                    <span *ngIf="validPostTKT && !loadingReservationInfo && !isLoading() && isAgencyBudgetValid" class="subtitle-waiver">
                        {{ 'couponInformation' | translate }}
                    </span>

                    <div *ngIf="validPostTKT && !loadingReservationInfo && !isLoading() && isAgencyBudgetValid"
                        class="info-container selection"
                        id="waiver-auto-info-coupon"
                    >
                        <!-- informacion cupon -->
                        <ng-container id="tkt-coupons-container">
                            <mat-table id="couponsTable" #table [dataSource]="dataVoucher">
                                <ng-container matColumnDef="voucherNumber">
                                <mat-header-cell *matHeaderCellDef>{{ 'field.couponNumber' | translate }}</mat-header-cell>
                                <mat-cell [ngClass]="{'mat-cell-invalid': invalidCouponStatusList.includes(data.status)}"
                                    *matCellDef="let data">{{ data.couponNumber | dashWhenEmpty }}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="carrierTkt">
                                <mat-header-cell *matHeaderCellDef>{{ 'field.carrier' | translate }}</mat-header-cell>
                                <mat-cell [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                                    *matCellDef="let data">{{ data.carrierMarketing | dashWhenEmpty }}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="flightNumberTkt">
                                <mat-header-cell *matHeaderCellDef>{{ 'field.flightNumber' | translate }}</mat-header-cell>
                                <mat-cell [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                                    *matCellDef="let data">{{ data.flightNumber | dashWhenEmpty }}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="classTkt">
                                <mat-header-cell *matHeaderCellDef>{{ 'field.class' | translate }}</mat-header-cell>
                                <mat-cell [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                                    *matCellDef="let data">{{ data.class | dashWhenEmpty }}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="originTkt">
                                <mat-header-cell *matHeaderCellDef>{{ 'field.from' | translate }}</mat-header-cell>
                                <mat-cell [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                                    *matCellDef="let data">{{ data.source | dashWhenEmpty }}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="destinationTkt">
                                <mat-header-cell *matHeaderCellDef>{{ 'field.to' | translate }}</mat-header-cell>
                                <mat-cell [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                                    *matCellDef="let data">{{ data.destination | dashWhenEmpty }}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="dateHourTkt">
                                <mat-header-cell *matHeaderCellDef>{{ 'field.departureDate' | translate }}</mat-header-cell>
                                <mat-cell [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                                    *matCellDef="let data">
                                    {{ data.departureDateTime | dateFormatWithoutLocale: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="farebasis">
                                <mat-header-cell *matHeaderCellDef>{{ 'field.farebasis' | translate }}</mat-header-cell>
                                <mat-cell [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                                    *matCellDef="let data">{{ data.fareBasis | dashWhenEmpty }}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="voucherStatus">
                                <mat-header-cell *matHeaderCellDef>{{ 'field.couponStatus' | translate }}</mat-header-cell>
                                <mat-cell [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                                    *matCellDef="let data">{{ data.status | dashWhenEmpty }}</mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="select">
                                <mat-header-cell *matHeaderCellDef class="pt-2">
                                    <mat-checkbox color="accent" name="generalCouponsCheckbox"
                                    [checked]="selectionCoupons.hasValue() && isAllSelectedCoupons()"
                                    [disabled]="dataVoucher.data.length==invalidCouponsQuantity"
                                    [indeterminate]="selectionCoupons.hasValue() && !isAllSelectedCoupons()"
                                    (change)="$event ? masterToggleCoupons() : null;">
                                    </mat-checkbox>
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="pt-2">
                                    <div id="tooltip" matTooltip="{{ 'tooltip.invalidCoupon' | translate }}" matTooltipPosition="before"
                                    [matTooltipDisabled]="!invalidCouponStatusList.includes(row.status)">
                                    <mat-checkbox name="couponsCheckbox" color="accent" [checked]="selectionCoupons.isSelected(row)"
                                        [disabled]="invalidCouponStatusList.includes(row.status)"
                                        (change)="$event ? selectionCoupons.toggle(row) : null;" (click)="$event.stopPropagation()">
                                    </mat-checkbox>
                                    </div>
                                </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumnsV" class="waiver-post-row"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumnsV; let i = index"
                                [ngClass]="{ 'highlight': selectedCouponsInformationRowIndex === i || selectionCoupons.isSelected(row) }"
                                (click)="toggleSelectionCouponsRow(row)" (mouseout)="highlightCouponsInformationRow($event, i)"
                                (mouseover)="highlightCouponsInformationRow($event, i)" class="waiver-post-row">
                                </mat-row>
                            </mat-table>
                        </ng-container>
                    </div>
                    <span *ngIf="!loadingReservationInfo && !isLoading() && showAgencyBudget" class="subtitle-waiver">
                        <img  src="assets/images/waiver-latam/fares.svg" />
                        {{ 'budgetInformation' | translate}}
                    </span>
                    <div *ngIf="!loadingReservationInfo && !isLoading() && showAgencyBudget" class="info-container">
                        <ng-container>
                                <mat-table #table [dataSource]="dataAgencyBudget">
                                    <ng-container matColumnDef="agencyGroupName">
                                        <mat-header-cell *matHeaderCellDef>{{ 'waiverLatam.agency.nameTitle' | translate }}</mat-header-cell>
                                        <mat-cell class="indigo-light latam-bold" *matCellDef="let data">{{ data.agencyGroupName | dashWhenEmpty }}</mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="budgetTotal">
                                        <mat-header-cell *matHeaderCellDef>{{ 'waiverLatam.agency.totalBudget' | translate }}</mat-header-cell>
                                        <mat-cell class="indigo-light latam-bold" *matCellDef="let data">{{ data.budgetTotal }}</mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="budgetBalance">
                                        <mat-header-cell *matHeaderCellDef>{{ 'waiverLatam.agency.currentBudget' | translate }}</mat-header-cell>
                                        <mat-cell class="indigo-light latam-bold" *matCellDef="let data">{{ data.budgetBalance }}</mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="budgetUsed">
                                        <mat-header-cell *matHeaderCellDef>{{ 'waiverLatam.agency.usedBudget' | translate }}</mat-header-cell>
                                        <mat-cell class="indigo-light latam-bold" *matCellDef="let data">{{ data.budgetUsed }}</mat-cell>
                                    </ng-container>
                                    <mat-header-row *matHeaderRowDef="displayedColumnsAgencyBudget" class="waiver-post-row"></mat-header-row>
                                    <mat-row class="indigo-light latam-bold" *matRowDef="let row; columns: displayedColumnsAgencyBudget;"></mat-row>

                                </mat-table>
                        </ng-container>
                    </div>
                    <div *ngIf="!loadingReservationInfo && !isLoading() && isAgencyBudgetValid" class="button-container">
                        <button
                            mat-flat-button
                            class="continue-button"
                            [disabled]="(!validPostPNR || !isSelectPassengersPost() || !isSelectSegmentsPost()) && (!validPostTKT || !isSelectCouponsPost())"
                            (click)="nextStep()"
                        >
                            <b>Continuar</b>
                        </button>
                    </div>
            </div>
            </mat-step>
            <mat-step>
                <div class="main-container">
                    <div class="section-container">
                        <div class="left-section">

                            <a (click)="stepperBack(2)" class="pointer"><span class="go-back"><mat-icon>arrow_back_ios</mat-icon> {{ 'button.back' | translate}}</span></a>

                            <h2 class="test-color">Waivers</h2>
                            <h4 class="test-color">{{ 'waiverLatam.selectWaiverType' | translate}}</h4>

                            <div class="form-card">

                                <ng-container *ngIf="(isWaiverAvailable('RNA') || isWaiverAvailable('RAW')) && (isWaiverAvailable('CNA') || isWaiverAvailable('CFP'))">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Tipo de waiver</mat-label>
                                        <mat-select [(ngModel)]="waiverSelected" required (ngModelChange)="selectWaiver()" [disabled]="isConvertingCurrency">
                                            <mat-option
                                                *ngFor="let waiver of waiverAvailables"
                                                [value]="waiver">
                                                {{ waiver | translate }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>

                                <ng-container *ngIf="waiverSelected === waiverAvailables[0] || !(isWaiverAvailable('RNA') && isWaiverAvailable('RAW'))">
                                    <mat-radio-group [(ngModel)]="selectedWaiverType" #waiverType="ngModel" (ngModelChange)="selectWaiverType()" [disabled]="isConvertingCurrency">
                                        <mat-radio-button matTooltip="{{ 'waiverLatam.toolTip.changeNotAllowed' | translate }}" value="CNA">{{ 'waiverLatam.radioButton.changeNotAllowed' | translate }}</mat-radio-button>
                                        <mat-radio-button matTooltip="{{ 'waiverLatam.toolTip.changeFlightPenalty' | translate }}" value="CFP">{{ 'waiverLatam.radioButton.changeFlightPenalty' | translate }}</mat-radio-button>
                                    </mat-radio-group>
                                </ng-container>
                                <ng-container *ngIf="waiverSelected === waiverAvailables[1] || !(isWaiverAvailable('CFP') && isWaiverAvailable('CNA'))">
                                    <mat-radio-group [(ngModel)]="selectedWaiverType" #waiverType="ngModel" (ngModelChange)="selectWaiverType()" [disabled]="isConvertingCurrency">
                                        <mat-radio-button matTooltip="{{ 'waiverLatam.toolTip.refundNotAllowed' | translate }}" value="RNA">{{ 'waiverLatam.radioButton.refundNotAllowed' | translate }}</mat-radio-button>
                                        <mat-radio-button matTooltip="{{ 'waiverLatam.toolTip.refundAllowedWithoutPenalty' | translate }}" value="RAW">{{ 'waiverLatam.radioButton.refundAllowedWithoutPenalty' | translate }}</mat-radio-button>
                                    </mat-radio-group>
                                </ng-container>


                                <mat-error style="width: 100%" *ngIf="showWaiverTypeErrors && selectedWaiverType === '' ">
                                    <p class="error">Debes seleccionar un tipo de waiver</p>
                                </mat-error>

                                <mat-form-field appearance="outline">
                                    <mat-label>{{ 'waiverLatam.coin' | translate}}</mat-label>
                                    <mat-select [(ngModel)]="selectedCurrency" (ngModelChange)="updateCostControlState()" required [disabled]="isConvertingCurrency">
                                        <mat-option
                                            *ngFor="let currency of currencyOptions"
                                            [value]="currency.code">
                                            {{currency.code}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <form [formGroup]="passengersForm">
                                  <ng-container formArrayName="passengers" *ngFor="let passenger of passengerArray.controls; let i = index">
                                    <ng-container [formGroupName]="i">
                                      <div style="display: flex; justify-content: space-between;">
                                                <mat-form-field style="width: 70%;" class="regular" appearance="outline">
                                                    <mat-label>{{ 'waiverLatam.passengerName' | translate}}</mat-label>
                                                    <input matInput
                                                        formControlName="name"
                                                        disabled>
                                                </mat-form-field>

                                                <div class="flex" style="width: 25%;">
                                                  <mat-form-field style="width: 100%;" class="regular" appearance="outline">
                                                      <mat-label>{{ 'waiverLatam.passengerCost' | translate}}</mat-label>
                                                      <input
                                                          matInput
                                                          type="text"
                                                          mask="separator.2"
                                                          thousandSeparator="."
                                                          decimalMarker=","
                                                          [dropSpecialCharacters]="false"
                                                          formControlName="cost"
                                                          (input)="updateWaiverValue()"
                                                          [min]="0"
                                                          [disabled]="!isCurrencySelected()"
                                                          required
                                                      >
                                                  </mat-form-field>
                                                  <mat-error
                                                        style="margin-top: -20px;"
                                                        *ngIf="passenger.controls.cost.errors && (passenger.controls.cost.dirty || passenger.controls.cost.touched) && passenger.controls.cost.errors.required">
                                                        {{ 'field.error.required' | translate }}</mat-error>
                                                  <mat-error
                                                    style="margin-top: -20px;"
                                                    *ngIf="passenger.controls.cost.errors && (passenger.controls.cost.dirty || passenger.controls.cost.touched) && passenger.controls.cost.errors.valueMin">
                                                    Min: {{minAmount}}</mat-error>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </form>

                                <mat-form-field class="example-full-width regular" appearance="outline">
                                    <mat-label>{{ 'waiverLatam.comment' | translate}}</mat-label>
                                    <textarea matInput [(ngModel)]="aditionalComment"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="checkbox-container regular p-justify">
                                <mat-checkbox [(ngModel)]="understandingAcceptance" #understanding required></mat-checkbox>
                                <label for="understandingAcceptance">{{ 'waiverLatam.understanding' | translate}}</label>
                            </div>

                            <div class="checkbox-container regular p-justify">
                                <mat-checkbox [(ngModel)]="termsAcceptance" #terms required></mat-checkbox>
                                <label for="termsAcceptance">{{ 'waiverLatam.termsAndConditions1' | translate}}<span class="terms-conditions" (click)="openTermsModal()"><b>{{ 'waiverLatam.termsAndConditions2' | translate}}</b></span>{{ 'waiverLatam.termsAndConditions3' | translate}}</label>
                            </div>

                            <mat-error style="width: 100%" *ngIf="showTermsError">
                                <p class="error">Debes aceptar los terminos y conidiciones para continuar.</p>
                            </mat-error>

                        </div>

                        <div class="right-section">
                            <h2>{{ 'waiverLatam.costDetail' | translate}}</h2>

                            <div class="passenger-card">
                                <span class="title">{{ 'waiverLatam.passengers' | translate}}</span>
                                <div *ngFor="let passenger of passengerArray.controls; let i = index" class="cost-item total">
                                    <span class="passenger-name">{{ selectionPassengers.selected[i]?.firstName?.replace('MR', '') + ' ' + selectionPassengers.selected[i]?.lastName}}</span>
                                    <span>{{ selectedCurrency }} {{ passenger.value.cost }}</span>
                                </div>
                            </div>

                            <div class="total-cost-card">
                                <span class="title">{{ 'waiverLatam.totalCost' | translate}}</span>
                                <div class="total">
                                    <div style="font-family: 'LatamSansLight';
                                        padding-bottom: 1rem;
                                        color: #4a148c;
                                        font-size: 1.5rem;
                                        width: auto;
                                        display: flex;
                                        flex-wrap: wrap;"
                                    >
                                        {{ selectedCurrency + ' ' + waiverTotalValue }}
                                        <span *ngIf="!isConvertingCurrency">
                                        (USD {{ waiverUsdTotalValue }})
                                        </span>
                                        <span *ngIf="isConvertingCurrency"
                                            style="display: flex;
                                                align-items: center;"
                                        >
                                            (USD
                                            <span style="padding-left: 1rem;">
                                                <mat-progress-spinner
                                                class="budget-spinner"
                                                mode="indeterminate"
                                                diameter="20"
                                            >
                                                </mat-progress-spinner>
                                            </span>
                                            )
                                        </span>
                                    </div>

                                </div>
                            </div>
                            <div class="spacer">

                            </div>
                            <div class="action-section">
                                <button
                                    *ngIf="!canSubmitWaiver"
                                    mat-flat-button
                                    [disabled]="false"
                                    (click)="letSubmit()"
                                    [ngClass]="{'button-transition': !canSubmitWaiver, 'button-hidden': canSubmitWaiver}"

                                >
                                    {{ 'button.confirm' | translate }}
                                </button>

                                <button
                                    *ngIf="areConditionsAccepted() && canSubmitWaiver"
                                    mat-flat-button
                                    [disabled]="loadingSaveWaiver"
                                    (click)="saveWaiverAutoApproved()"
                                    [ngClass]="{'button-transition': canSubmitWaiver && areConditionsAccepted(), 'button-hidden': !canSubmitWaiver && !areConditionsAccepted()}"

                                >
                                <span *ngIf="!loadingSaveWaiver">{{ 'button.sendRequest' | translate }}</span>
                                <mat-progress-spinner
                                            *ngIf="loadingSaveWaiver"
                                            class="custom-spinner"
                                            mode="indeterminate"
                                            diameter="20">
                                </mat-progress-spinner>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step>
                <div class="search-main-container">
                    <a (click)="newRequest()" class="pointer">
                        <span class="go-back centered"><mat-icon>arrow_back_ios</mat-icon> {{ 'button.back' | translate}}</span>
                    </a>
                    <div class="info">
                        <img  src="assets/images/waiver-latam/successflytickets.svg" />
                        <br>

                        <h3 *ngIf="!sessionInfo.isSelfManaged && waiverStatus?.id == 1">
                            {{ 'waiverLatam.pendingRequest' | translate}}
                        </h3>

                        <h2 *ngIf="!sessionInfo.isSelfManaged && waiverStatus?.id == 2">
                            {{ 'waiverLatam.approvedRequest' | translate}}
                        </h2>
                        <h2 *ngIf="sessionInfo.isSelfManaged">
                            {{ 'success.waiverCreated' | translate}}
                        </h2>
                    </div>

                    <span class="subtitle-waiver">
                        <img  src="assets/images/waiver-latam/fares.svg" />
                        {{ 'waiverLatam.waiverDetail' | translate }}
                    </span>
                    <div *ngIf="!loadingSaveWaiver" class="info-container">
                        <ng-container>
                                <mat-table #table [dataSource]="dataWaiverResult">
                                    <ng-container matColumnDef="id">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.idRequest' | translate }}</mat-header-cell>
                                        <mat-cell *matCellDef="let data">{{ data.id | dashWhenEmpty }}</mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="waiverPos">
                                        <mat-header-cell *matHeaderCellDef>POS</mat-header-cell>
                                        <mat-cell *matCellDef="let data">{{ data?.market?.name }}</mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="agencyName">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.agency' | translate }}</mat-header-cell>
                                        <mat-cell *matCellDef="let data">{{ data?.agencyGroup?.name }}</mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="iata">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.iata' | translate }}</mat-header-cell>
                                        <mat-cell *matCellDef="let data">{{ data?.documentIata }}</mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="userName">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.executiveSale' | translate }}</mat-header-cell>
                                        <mat-cell *matCellDef="let data">{{ data?.user?.name + ' ' +  data.user.lastname | dashWhenEmpty }}</mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="waiverType">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.type' | translate }}</mat-header-cell>
                                        <mat-cell *matCellDef="let data">{{ data?.type.name | dashWhenEmpty }}</mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="documentNumber">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.pnrTkt' | translate }}</mat-header-cell>
                                        <mat-cell *matCellDef="let data">{{ data?.documentNumber | dashWhenEmpty }}</mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="statusDate">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.approvalDateHour' | translate }}</mat-header-cell>
                                        <mat-cell *matCellDef="let data">{{ data?.statusDate | dateFormat: 'DD-MMM-YY' | uppercase | dashWhenEmpty}}</mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="flightDate">
                                        <mat-header-cell *matHeaderCellDef>{{ 'field.departureDate' | translate }}</mat-header-cell>
                                        <mat-cell *matCellDef="let data">{{ data?.segments[0]?.departureDateTime | dateFormat: 'DD-MMM-YY' | uppercase | dashWhenEmpty }}</mat-cell>
                                    </ng-container>
                                    <mat-header-row *matHeaderRowDef="displayedColumnsWaiverResult" class="waiver-post-row"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumnsWaiverResult;"></mat-row>

                                </mat-table>
                        </ng-container>
                    </div>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </div>

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PageEvent } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { WaiverPostsaleInterface } from '../../../interfaces/waiver-postsale.interface';
import { BusinessException, Exception } from '../../../models/Exception.model';
import { TypeMotiveFilter } from '../../../models/type-motive.model';
import { WaiverPage } from '../../../models/waiver-page.model';
import { MessageService } from '../../../services/message.service';
import { WaiverPostsaleOutsourcingService } from '../../../services/waiver-postsale-outsourcing.service';
import { WaiverPostsaleService } from '../../../services/waiver-postsale.service';
import { DetailListWaiverLatamComponent } from '../detail-list-waiver-latam/detail-list-waiver-latam.component';

@Component({
  selector: 'app-list-waiver-outsourcing',
  templateUrl: './list-waiver-outsourcing.component.html',
  styleUrls: ['./list-waiver-outsourcing.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ListWaiverOutsourcingComponent implements OnInit {
   loadingPendingData: boolean = false;
   loadingApprovedData: boolean = true;
   loadingRejectedData: boolean = true;

   errorLoadingPendingData: boolean = false;

   pendingData: WaiverPostsaleInterface[];
   approvedData: WaiverPostsaleInterface[];
   rejectedData: WaiverPostsaleInterface[];

   /* Paginacion */
   countPendingData: number;
   countApprovedData: number;
   countRejectedData: number;
   pendingCurrentPage: number = 0;
   approvedCurrentPage: number = 0;
   rejectedCurrentPage: number = 0;

   pendingPageSize: number = 10;
   approvedPageSize: number = 10;
   rejectedPageSize: number = 10;

   didFirstLoadPending: boolean = false;
   didFirstLoadApproved: boolean = false;
   didFirstLoadRejected: boolean = false;
   pendingTab: boolean = true;
   approvedTab: boolean = false;
   rejectedTab: boolean = false;

   formFilter: UntypedFormGroup;

  constructor(
      private adapter: DateAdapter<Moment>,
      private waiverPostsaleOutsourcingService: WaiverPostsaleOutsourcingService,
      private waiverPostsaleService: WaiverPostsaleService,
      private messageService: MessageService,
      private translateService: TranslateService,
      public dialog: MatDialog,) {
         this.formFilter = this.initFormFilters();
   }

   ngOnInit() {
      this.searchPendingWaiverList();
   }

  searchPendingWaiverList(){
      this.loadingPendingData = true;
      this.waiverPostsaleOutsourcingService.getPendingWaiverListOutsourcing(this.pendingCurrentPage,
         this.pendingPageSize, this.getWaiverSearchFilter())
         .subscribe({
            next: (resp) => {
               this.pendingData = resp.content;
               this.countPendingData = resp.totalElements;
               this.pendingCurrentPage = resp.page;
               this.loadingPendingData = false;
               this.errorLoadingPendingData = false;
               this.didFirstLoadPending = !this.didFirstLoadPending ? true : this.didFirstLoadPending;
            },
            error: (err) => {
               this.loadingPendingData = false;
               this.errorLoadingPendingData = true;
               this.messageService.showErrorMessage(
                  new Exception(
                     err.error.type,
                     err.error.type !== BusinessException ? this.translateService.instant('error.loadingPendingWaivers') : err.error.description,
                     err.status
                  ),
                  this.translateService.instant('button.confirm')
               );
            }
  });
   }

   searchApprovedWaiverList(){
      this.loadingApprovedData = true;
      this.waiverPostsaleOutsourcingService.getApprovedWaiverListOutsourcing(this.approvedCurrentPage,
         this.approvedPageSize, this.getWaiverSearchFilter())
         .subscribe({
            next: (resp) => {
               this.approvedData = resp.content;
               this.countApprovedData = resp.totalElements;
               this.approvedCurrentPage = resp.page;
               this.loadingApprovedData = false;
               this.didFirstLoadApproved = !this.didFirstLoadApproved ? true : this.didFirstLoadApproved;
            },
            error: (err) => {
               this.loadingApprovedData = false;
               this.messageService.showErrorMessage(
                  new Exception(
                     err.error.type,
                     err.error.type !== BusinessException ? this.translateService.instant('error.loadingAprovedWaivers') : err.error.description,
                     err.status
                  ),
                  this.translateService.instant('button.confirm')
               );
            }
   });
   }

   searchRejectedWaiverList(){
      this.loadingRejectedData = true;
      this.waiverPostsaleOutsourcingService.getRejectedWaiverListOutsourcing(this.rejectedCurrentPage,
         this.rejectedPageSize, this.getWaiverSearchFilter())
         .subscribe({
            next: (resp) => {
               this.rejectedData = resp.content;
               this.countRejectedData = resp.totalElements;
               this.rejectedCurrentPage = resp.page;
               this.loadingRejectedData = false;
               this.didFirstLoadRejected = !this.didFirstLoadRejected ? true : this.didFirstLoadRejected;
            },
            error: (err) => {
               this.loadingRejectedData = false;
               this.messageService.showErrorMessage(
                  new Exception(
                     err.error.type,
                     err.error.type !== BusinessException ? this.translateService.instant('error.loadingDeniedWaivers') : err.error.description,
                     err.status
                  ),
                  this.translateService.instant('button.confirm')
               );
            }
   });
   }

   openDialog(e): void {
      console.log(e);
      let dialogRef = this.dialog.open(
        DetailListWaiverLatamComponent,
        {
          width: '90vw',
          height: '65vh',
          disableClose: true
        }
      );

      dialogRef.componentInstance.tab = e.tab;
      dialogRef.componentInstance.objectWaiver = { id: e.object.id, document: e.object.document, documentNumber: e.object.documentNumber };

      dialogRef.afterClosed().subscribe(result => {
        if (['reload-approved', 'reload-rejected', 'reload-pending-agency', 'error'].includes(result)) {
         this.filterWaiverList();
        }
      });
    }

   filterWaiverList(){
      if (this.pendingTab){
         this.pendingCurrentPage = 0;
         this.pendingPageSize = 10;
         this.searchPendingWaiverList();
      }
      if (this.approvedTab){
         this.approvedCurrentPage = 0;
         this.approvedPageSize = 10;
         this.searchApprovedWaiverList();
      }
      if (this.rejectedTab){
         this.rejectedCurrentPage = 0;
         this.rejectedPageSize = 10;
         this.searchRejectedWaiverList;
      }
   }

   getWaiverTypeMotiveFilter(){
      let waivertypeMotive: TypeMotiveFilter[] = [];
      waivertypeMotive.push(new TypeMotiveFilter(2, [3, 7, 9, 12]));
      waivertypeMotive.push(new TypeMotiveFilter(3, [3, 7, 9, 12]));
      waivertypeMotive.push(new TypeMotiveFilter(8, [7, 9, 12]));
      waivertypeMotive.push(new TypeMotiveFilter(9, [7, 9, 12]));
      waivertypeMotive.push(new TypeMotiveFilter(12, []));

      return waivertypeMotive;
   }

   getWaiverSearchFilter(){
      let waiverSearchFilter = new  WaiverPage(
         this.formFilter.get('idRequest').value,
         this.formFilter.get('pos').value,
         this.formFilter.get('agency').value,
         this.formFilter.get('iata').value,
         this.formFilter.get('requestDate').value,
         this.formFilter.get('salesEjecutive').value,
         this.formFilter.get('type').value,
         this.formFilter.get('motive').value,
         this.formFilter.get('pnrTkt').value,
         this.formFilter.get('flightDate').value,
         this.getWaiverTypeMotiveFilter()
       );

      return waiverSearchFilter;
   }

   initFormFilters(): UntypedFormGroup {
      return this.waiverPostsaleService.initForm();
    }

   pageEventPending(e: PageEvent){
      this.pendingCurrentPage = e.pageIndex;
      this.pendingPageSize = e.pageSize;
      this.searchPendingWaiverList();
   }

   pageEventApproved(e: PageEvent){
      this.approvedCurrentPage = e.pageIndex;
      this.approvedPageSize = e.pageSize;
      this.searchApprovedWaiverList();
   }

   pageEventRejected(e: PageEvent){
      this.rejectedCurrentPage = e.pageIndex;
      this.rejectedPageSize = e.pageSize
      this.searchRejectedWaiverList();
   }

   tabChange(tab){
      this.formFilter = this.initFormFilters();
      if (tab == 0){
         if (!this.didFirstLoadPending){
            this.searchPendingWaiverList();
         }
         this.pendingTab = true;
         this.approvedTab = false;
         this.rejectedTab = false;
      }
      if (tab == 1){
         if (!this.didFirstLoadApproved){
            this.searchApprovedWaiverList();
         }

         this.pendingTab = false;
         this.approvedTab = true;
         this.rejectedTab = false;
      }
      if (tab == 2){
         if (!this.didFirstLoadRejected){
            this.searchRejectedWaiverList();
         }

         this.pendingTab = false;
         this.approvedTab = false;
         this.rejectedTab = true;
      }
   }

   reloadTable(tabNumber){
      if ( tabNumber == 1 ){
         this.searchPendingWaiverList();
      }
      if ( tabNumber == 2 ){
         this.searchApprovedWaiverList();
      }
      if (tabNumber == 3 ){
         this.searchRejectedWaiverList();
      }
   }

   setCalendarLanguage() {
      this.adapter.setLocale(localStorage.getItem('language'));
    }

}

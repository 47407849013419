
import { Market } from './market.model';

export class UserLatamCsvElement {
    public markets: String;
    public latam_user_id: String;
    public name: String;
    public lastname: String;
    public email: String;
    public role: String;
    public userLatamPosition: String;
    public id: number;
    public creation_date: String;
    public active: boolean;


    public constructor(
        markets: Market[],
        latam_user_id: String,
        name: String,
        lastname: String,
        email: String,
        role: String,
        userLatamPosition: String,
        id: number,
        creation_date: String,
        active: boolean) {
            this.markets = '';
            markets.forEach((market, key) => {
                this.markets = this.markets + market.name;
                if (markets.length - 1 > key) {
                    this.markets = this.markets + ',';
                }
            });
            this.latam_user_id = latam_user_id;
            this.name = name;
            this.lastname = lastname;
            this.email = email;
            this.role = role;
            this.userLatamPosition = userLatamPosition;
            this.id = id;
            this.creation_date = creation_date;
            this.active = active;
    }
}

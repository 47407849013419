import { AgencyGroup } from './agency-group.model';

export class BranchOffice {
  id: number;
  name: string;
  agencyGroup: AgencyGroup;
  consumeGroupBudget: boolean;
  showPostsaleBudget: boolean;
  showPresaleBudget: boolean;

  constructor(
    id?: number,
    name?: string,
    agencyGroup?: AgencyGroup,
    consumeGroupBudget?: boolean,
    showPostsaleBudget?: boolean,
    showPresaleBudget?: boolean) {
      this.id = id;
      this.name = name;
      this.agencyGroup = agencyGroup;
      this.consumeGroupBudget = consumeGroupBudget;
      this.showPostsaleBudget = showPostsaleBudget;
      this.showPresaleBudget = showPresaleBudget;
  }
}

<div *ngIf="isLoadingAgencyBudget" class="loader loading">
  <div class="loading-div">
  <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
  <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
  </svg>
  <span class="loading-span">{{ 'loading' | translate }}</span>
  </div>
</div>

<div class="reservation-info-container" *ngIf="!isLoadingAgencyBudget" >
    <span class="go-back centered">
      <a (click)="stepperBack()" *ngIf="isAgencyBudgetValid" class="pointer">
        <mat-icon>arrow_back_ios</mat-icon> {{ 'button.back' | translate}}
      </a>
    </span>

    <span *ngIf="isAgencyBudgetValid"
        class="subtitle-waiver">
        {{ 'bookingInformation' | translate }}

    </span>
    <div *ngIf="isAgencyBudgetValid"  class="info-container">
        <!-- Reservation INFO -->
        <ng-container id="pnrInfoDiv">

                <mat-table #table [dataSource]="reservationInfo.dataReservation">
                    <ng-container matColumnDef="pnrNumber">
                        <mat-header-cell *matHeaderCellDef>{{ 'field.pnr' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let data" class="indigo-light latam-bold">{{ data.pnr.pnrNumber | dashWhenEmpty }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="pnrType">
                        <mat-header-cell *matHeaderCellDef>{{ 'field.pnrType' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let data" class="indigo-light latam-bold">{{ data.pnr.type | dashWhenEmpty }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="bookingCreation">
                        <mat-header-cell *matHeaderCellDef>{{ 'field.bookingCreation' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let data" class="indigo-dark latam-bold" >
                            {{ data.pnr.createDateTime | dateFormat:'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="NumberOfPassengers">
                        <mat-header-cell *matHeaderCellDef>{{ 'field.numberPassengers' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let data" class="indigo-dark latam-bold" >{{ dataPassengers?.data?.length }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="pos">
                        <mat-header-cell *matHeaderCellDef>{{ 'field.pos' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let data" class="indigo-dark latam-bold">{{ data.booking.pos | dashWhenEmpty }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="iata">
                        <mat-header-cell *matHeaderCellDef>{{ 'field.iata' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let data" class="indigo-dark latam-bold">{{ data.booking.iata | dashWhenEmpty }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="pcc">
                        <mat-header-cell *matHeaderCellDef>{{ 'field.pcc' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let data" class="indigo-dark latam-bold">{{ data.booking.ppc | dashWhenEmpty }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="officeId">
                        <mat-header-cell *matHeaderCellDef>{{ 'field.officeId' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let data" class="indigo-dark latam-bold">{{ data.booking.officeId | dashWhenEmpty }}</mat-cell>
                    </ng-container>

                    <mat-header-row class="waiver-post-row" *matHeaderRowDef="displayedColumnsR"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsR; let i = index"
                    [ngClass]="{ 'highlight': selectedReservationInformationWaiverLatamRowIndex === i }"
                    (mouseout)="highlightReservationInformationRowWaiverLatam($event, i)"
                    (mouseover)="highlightReservationInformationRowWaiverLatam($event, i)" class="waiver-post-row"></mat-row>
                </mat-table>
        </ng-container>

    </div>

    <span *ngIf="isAgencyBudgetValid"
        class="subtitle-waiver">{{ 'passengerInfo' | translate }}
    </span>
    <div *ngIf="isAgencyBudgetValid"
        class="info-container selection"
        id="waiver-auto-info-passenger"
    >
        <!-- informacion de pasajeros pos -->
        <ng-container id="waiverLatamPNRPassengersDiv">
            <mat-table id="waiverLatamPassengersPostPNRTable" #table [dataSource]="reservationInfo.dataPassengers">
                    <ng-container matColumnDef="passengerName">
                        <mat-header-cell *matHeaderCellDef>{{ 'field.passengerName' | translate }}</mat-header-cell>
                        <mat-cell id="waiver-latam-passenger-name"
                          [ngClass]="{ 'mat-cell-invalid': data.invalidTkt||data.allCouponsInvalid || data.infantTkt }"
                            *matCellDef="let data">
                            {{ (data.firstName | partialDisplay: '#' : 1 | dashWhenEmpty) + ' ' + (data.lastName | partialDisplay: '#' : 1 | dashWhenEmpty) }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="foid">
                        <mat-header-cell *matHeaderCellDef>{{ 'field.foid' | translate }}</mat-header-cell>
                        <mat-cell id="waiver-latam-foid"
                            [ngClass]="{ 'mat-cell-invalid': data.invalidTkt||data.allCouponsInvalid || data.infantTkt }"
                            *matCellDef="let data">{{ data.foid | partialDisplay:'#' :-2  | dashWhenEmpty }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="passengerType">
                        <mat-header-cell *matHeaderCellDef>{{ 'field.passengerType' | translate }}
                        </mat-header-cell>
                        <mat-cell id="waiver-latam-passenger-type"
                            [ngClass]="{ 'mat-cell-invalid': data.invalidTkt||data.allCouponsInvalid || data.infantTkt }"
                            *matCellDef="let data">{{ data.type | dashWhenEmpty }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="tkt">
                        <mat-header-cell *matHeaderCellDef>{{ 'field.tkt' | translate }}</mat-header-cell>
                        <mat-cell id="waiver-latam-passenger-tkt"
                            [ngClass]="{ 'mat-cell-invalid': data.invalidTkt||data.allCouponsInvalid || data.infantTkt }"
                            *matCellDef="let data">{{ data.ticketNumber | dashWhenEmpty }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="select" id="waiver-latam-select-passenger" *ngIf="enableSelection">
                        <mat-header-cell *matHeaderCellDef class="pt-2">
                                <mat-checkbox color="accent" 
                                [indeterminate]="selectionPassengers.hasValue() && !isAllSelectedPassengers()"
                                [checked]="selectionPassengers.hasValue() && isAllSelectedPassengers()"
                                [disabled]="allPassengersInvalid"
                                (change)="$event ? masterTogglePassengers() : null">
                                </mat-checkbox>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" class="pt-2">
                            <div id="waiverLatamTooltipPassenger" *ngIf="!(row.infantTkt)" matTooltip="{{ 'tooltip.notHaveTkt' | translate }}"
                            matTooltipPosition="before"
                            [matTooltipDisabled]="!(row.invalidTkt || row.allCouponsInvalid || row.infantTkt)">
                                <mat-checkbox (click)="$event.stopPropagation()" color="accent"
                                    [checked]="selectionPassengers.isSelected(row)"
                                    (change)="$event ? toggleSelectionPassengerWaiverLatam(row) : null"
                                    [disabled]="row.invalidTkt || row.allCouponsInvalid || row.infantTkt">
                                </mat-checkbox>
                            </div>

                            <div id="waiverLatamTooltipPassengerINF" *ngIf="row.infantTkt"
                            matTooltip="{{ 'tooltip.adultPassengerRequired' | translate }}" matTooltipPosition="before"
                            [matTooltipDisabled]="!(row.infantTkt)">
                                <mat-checkbox color="accent" [checked]="selectionPassengers.isSelected(row)"
                                    [disabled]="row.invalidTkt || row.allCouponsInvalid || row.infantTkt"
                                    (click)="$event.stopPropagation()"
                                    (change)="$event ? toggleSelectionPassengerWaiverLatam(row) : null" 
                                    >
                                </mat-checkbox>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumnsPPOS" class="waiver-post-row"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsPPOS; let i = index"
                      (click)="enableSelection ? toggleSelectionPassengerWaiverLatam(row) : null" 
                      (mouseout)="enableSelection ? highlightPassengerInformationRowWaiverLatam($event, i) : null"
                      [ngClass]="{ 'highlight': selectedPassengerInformationWaiverLatamRowIndex === i || selectionPassengers.isSelected(row) }"
                      (mouseover)="enableSelection ? highlightPassengerInformationRowWaiverLatam($event, i): null" class="waiver-post-row">
                    </mat-row>
                </mat-table>
        </ng-container>
    </div>
    <span *ngIf="isAgencyBudgetValid" class="subtitle-waiver">
        <img  src="assets/images/waiver-latam/airplane.svg" alt="airplane" />
        {{ 'segmentsInformation' | translate }}
    </span>
    <div *ngIf="isAgencyBudgetValid"
        class="info-container selection"
        id="waiver-auto-info-segments"
    >
        <!-- informacion segmento -->
        <div id="waiver-latam-pnr-segments-div">

            <mat-table id="waiverLatamSegmentsTable" [dataSource]="reservationInfo.dataSegments" #table>
                <ng-container matColumnDef="segmentNumber">
                <mat-header-cell *matHeaderCellDef>{{ 'field.segmentNumber' | translate }}</mat-header-cell>
                <mat-cell
                    *matCellDef="let data"
                    [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                    >{{ data.segmentNumber | dashWhenEmpty }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="carrierMkt" id="carrier-mkt">
                    <mat-header-cell *matHeaderCellDef>{{ 'field.mktCarrier' | translate }}</mat-header-cell>
                    <mat-cell
                        *matCellDef="let data"
                        [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0}"
                        >{{ data.carrierMarketing | dashWhenEmpty }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="flightNumberMkt" id="flight-number-mkt">
                    <mat-header-cell *matHeaderCellDef>{{ 'field.mktFlightNumber' | translate }}</mat-header-cell>
                    <mat-cell
                        *matCellDef="let data"
                        [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                        >{{ data.flightNumber | dashWhenEmpty }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="class" id="class">
                    <mat-header-cell *matHeaderCellDef>{{ 'field.class' | translate }}</mat-header-cell>
                    <mat-cell
                        *matCellDef="let data"
                        [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                        >{{ data.class | dashWhenEmpty }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="origin" id="flight-origin">
                    <mat-header-cell *matHeaderCellDef>{{ 'field.from' | translate }}</mat-header-cell>
                    <mat-cell
                        *matCellDef="let data"  
                        [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0}"
                        >{{ data.source | dashWhenEmpty }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="destination" id="flight-destination">
                    <mat-header-cell *matHeaderCellDef>{{ 'field.to' | translate }}</mat-header-cell>
                    <mat-cell
                        *matCellDef="let data"
                        [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0}"
                        >{{ data.destination | dashWhenEmpty }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="dateHourDeparture" id="flight-date-hour-departure">
                    <mat-header-cell *matHeaderCellDef>{{ 'field.departureDate' | translate }}</mat-header-cell>
                    <mat-cell
                        *matCellDef="let data"
                        [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                        >
                        {{ data.departureDateTime | dateFormatWithoutLocale: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="status" id="flight-status">
                    <mat-header-cell *matHeaderCellDef>{{ 'field.segmentStatus' | translate }}</mat-header-cell>
                    <mat-cell
                        *matCellDef="let data"
                        [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                        >{{ data.status | dashWhenEmpty }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="fareBasis" id="waiver-latam-farebasis">

                    <mat-header-cell  [hidden]="true" *matHeaderCellDef>{{ 'field.farebasis' | translate }}</mat-header-cell>
                    <mat-cell [hidden]="true" *matCellDef="let data">{{ data.fareBasis | dashWhenEmpty }}</mat-cell>
                    
                </ng-container>

                <ng-container matColumnDef="select" *ngIf="enableSelection">
                  <mat-header-cell *matHeaderCellDef class="pt-2">
                      <mat-checkbox color="accent" [checked]="selectionSegments.hasValue() && isAllSelectedSegments()"
                      [disabled]="(segmentsWithOneCouponInvalidQuantity + invalidStatusSegmentsQuantity) === dataSegments.data.length"
                      [indeterminate]="selectionSegments.hasValue() && !isAllSelectedSegments()"
                      (change)="$event ? masterToggleSegments() : null;">
                      </mat-checkbox>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="pt-2">
                      <div id="waiveRLatamTooltipSegmentPOST"
                          matTooltipPosition="before"
                          matTooltip="{{ 'tooltip.invalidSegmentCoupon' | translate }}" 
                          [matTooltipDisabled]="!(!validSegmentStatusList.includes(row.status) || row.invalidCoupons > 0)"
                          >
                          <mat-checkbox color="accent" [checked]="selectionSegments.isSelected(row)"
                              [disabled]="!validSegmentStatusList.includes(row.status) || row.invalidCoupons > 0"
                              (click)="$event.stopPropagation()"
                              (change)="$event ? toggleSelectionSegmentWaiverLatam(row) : null;" 
                            >
                          </mat-checkbox>
                      </div>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumnsS" class="waiver-post-row"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsS; let i = index"
                        [ngClass]="{ 'highlight': selectedSegmentsInformationWaiverLatamRowIndex === i || selectionSegments.isSelected(row)}"
                        (click)="toggleSelectionSegmentWaiverLatam(row)" (mouseout)="highlightSegmentsInformationRowWaiverLatam($event, i)"
                        (mouseover)="highlightSegmentsInformationRowWaiverLatam($event, i)" class="waiver-post-row">
                </mat-row>
            </mat-table>
        </div>
    </div>

    <span class="subtitle-waiver"
        *ngIf="isAgencyBudgetValid && searchType === 'TKT' "
    >
        {{ 'tktInformation' | translate }}
    </span>
    <div *ngIf="isAgencyBudgetValid && searchType === 'TKT' "
        class="info-container"
        id="waiver-auto-info-tkt"
    >
        <!-- Informacion Ticket Waivers LATAM -->
        <ng-container id="waiver-latam-tkt-info-container">
            <mat-table #table [dataSource]="reservationInfo.dataTkt">
                <ng-container matColumnDef="tktNumber" id="waiver-latam-tkt-number">
                    <mat-header-cell *matHeaderCellDef>{{ 'field.tkt' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let data">{{ data.tkt.tktNumber | dashWhenEmpty }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="pnrNum" id="waiver-latam-pnr-value">
                    <mat-header-cell *matHeaderCellDef>{{ 'field.pnr' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let data">{{ data.tkt.pnr | dashWhenEmpty }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="emisionCreationDate" id="waiver-latam-emission-date">
                    <mat-header-cell *matHeaderCellDef>{{ 'field.issueDate' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        {{ data.tkt.createDateTime | dateFormat: 'DD-MMM-YY' | uppercase | dashWhenEmpty }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="posInfo" class="posInfo" id="waiver-latam-pos-info">
                    <mat-header-cell *matHeaderCellDef>{{ 'field.pos' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let data">{{ data.issuer.pos | dashWhenEmpty }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="iataInfo" class="iataInfo" id="waiver-latam-iata-info">
                    <mat-header-cell *matHeaderCellDef>{{ 'field.iata' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let data">{{ data.booking.iata | dashWhenEmpty }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="pccInfo" class="pccInfo" id="waiver-latam-pcc-info">
                    <mat-header-cell *matHeaderCellDef>{{ 'field.pcc' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let data">{{ data.booking.ppc | dashWhenEmpty }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="officeIdInfo" class="officeIdInfo" id="waiver-latam-office-id-info">
                    <mat-header-cell *matHeaderCellDef>{{ 'field.officeId' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let data">{{ data.booking.officeId | dashWhenEmpty }}</mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumnsTKT" class="waiver-post-row"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsTKT; let i = index"
                    [ngClass]="{ 'highlight': selectedTktInformationWaiverLatamRowIndex === i }"
                    (mouseover)="highlightTktInformationRowWaiverLatam($event, i)" (mouseout)="highlightTktInformationRowWaiverLatam($event, i)"
                    class="waiver-post-row">
                </mat-row>
            </mat-table>
        </ng-container>
    </div>

    <span *ngIf="isAgencyBudgetValid && searchType === 'TKT' " class="subtitle-waiver">
        {{ 'couponInformation' | translate }}
    </span>

    <div *ngIf="isAgencyBudgetValid && searchType === 'TKT' "
        class="info-container selection"
        id="waiver-auto-info-coupon"
    >
        <!-- informacion cupon waivers LATAM -->
        <ng-container id="waiver-latamtkt-coupons-container">
            <mat-table id="couponsTable" #table [dataSource]="dataVoucher">
              <ng-container matColumnDef="voucherNumber">
              <mat-header-cell *matHeaderCellDef>{{ 'field.couponNumber' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data"
                  [ngClass]="{'mat-cell-invalid': invalidCouponStatusList.includes(data.status)}"
                  >{{ data.couponNumber | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="carrierTkt">
              <mat-header-cell *matHeaderCellDef>{{ 'field.carrier' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data" 
                  [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                  >{{ data.carrierMarketing | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="flightNumberTkt">
              <mat-header-cell *matHeaderCellDef>{{ 'field.flightNumber' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data" 
                  [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                  >{{ data.flightNumber | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="classTkt">
              <mat-header-cell *matHeaderCellDef>{{ 'field.class' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data" 
                  [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                  >{{ data.class | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="originTkt">
              <mat-header-cell *matHeaderCellDef>{{ 'field.from' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data" 
                  [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                  >{{ data.source | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="destinationTkt">
              <mat-header-cell *matHeaderCellDef>{{ 'field.to' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data" 
                  [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                  >{{ data.destination | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="dateHourTkt">
              <mat-header-cell *matHeaderCellDef>{{ 'field.departureDate' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data" 
                  [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                  >
                  {{ data.departureDateTime | dateFormatWithoutLocale: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="farebasis">
              <mat-header-cell *matHeaderCellDef>{{ 'field.farebasis' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data" 
                  [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                  >{{ data.fareBasis | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="voucherStatus">
              <mat-header-cell *matHeaderCellDef>{{ 'field.couponStatus' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data" 
                  [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                  >{{ data.status | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="select" *ngIf="enableSelection">
                <mat-header-cell *matHeaderCellDef class="pt-2">
                    <mat-checkbox color="accent" name="generalCouponsCheckbox"
                    (change)="$event ? masterToggleCoupons() : null;"
                    [checked]="selectionCoupons.hasValue() && isAllSelectedCoupons()"
                    [disabled]="dataVoucher.data.length==invalidCouponsQuantity"
                    [indeterminate]="selectionCoupons.hasValue() && !isAllSelectedCoupons()"
                    >
                    </mat-checkbox>
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="pt-2">
                    <div id="tooltip" [matTooltipDisabled]="!invalidCouponStatusList.includes(row.status)" 
                      matTooltip="{{ 'tooltip.invalidCoupon' | translate }}" 
                      matTooltipPosition="before"
                    >
                    <mat-checkbox name="couponsCheckbox" color="accent" [checked]="selectionCoupons.isSelected(row)"
                        [disabled]="invalidCouponStatusList.includes(row.status)"
                        (change)="$event ? selectionCoupons.toggle(row) : null;" (click)="$event.stopPropagation()">
                    </mat-checkbox>
                    </div>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumnsV" class="waiver-post-row"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsV; let i = index"
              [ngClass]="{ 'highlight': selectedCouponsInformationRowIndex === i || selectionCoupons.isSelected(row) }"
              (click)="toggleSelectionCouponsRow(row)" (mouseout)="highlightCouponsInformationRowWaiverLatam($event, i)"
              (mouseover)="highlightCouponsInformationRowWaiverLatam($event, i)" class="waiver-post-row">
              </mat-row>
            </mat-table>
        </ng-container>
    </div>
    <span *ngIf="showAgencyBudget && !isLoadingAgencyBudget && waiverModule === 'COR'" class="subtitle-waiver">
        <img  src="assets/images/waiver-latam/fares.svg" />
        {{ 'budgetInformation' | translate}}
    </span>
    <div *ngIf="showAgencyBudget && !isLoadingAgencyBudget && waiverModule === 'COR'" class="info-container">
        <ng-container>
                <mat-table #table [dataSource]="dataAgencyBudget">
                    <ng-container matColumnDef="agencyGroupName">
                        <mat-header-cell *matHeaderCellDef>{{ 'waiverLatam.agency.nameTitle' | translate }}</mat-header-cell>
                        <mat-cell class="indigo-light latam-bold" *matCellDef="let data">{{ data.agencyGroupName | dashWhenEmpty }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="budgetTotal">
                        <mat-header-cell *matHeaderCellDef>{{ 'waiverLatam.agency.totalBudget' | translate }}</mat-header-cell>
                        <mat-cell class="indigo-light latam-bold" *matCellDef="let data">{{ data.budgetTotal }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="budgetBalance">
                        <mat-header-cell *matHeaderCellDef>{{ 'waiverLatam.agency.currentBudget' | translate }}</mat-header-cell>
                        <mat-cell class="indigo-light latam-bold" *matCellDef="let data">{{ data.budgetBalance }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="budgetUsed">
                        <mat-header-cell *matHeaderCellDef>{{ 'waiverLatam.agency.usedBudget' | translate }}</mat-header-cell>
                        <mat-cell class="indigo-light latam-bold" *matCellDef="let data">{{ data.budgetUsed }}</mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumnsAgencyBudget" class="waiver-post-row"></mat-header-row>
                    <mat-row class="indigo-light latam-bold" *matRowDef="let row; columns: displayedColumnsAgencyBudget;"></mat-row>

                </mat-table>
        </ng-container>
    </div>
    <div *ngIf="isAgencyBudgetValid && !isLoadingAgencyBudget" class="button-container">
        <button
            mat-flat-button
            class="continue-button"
            (click)="nextStep()"
            [disabled]="(!isSelectPassengersPost() || !isSelectSegmentsPost()) && (!isSelectCouponsPost()) && waiverModule === 'COR'"
        >
            <b>Continuar</b>
        </button>
    </div>
</div>
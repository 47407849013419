import { Component } from '@angular/core';

@Component({
  selector: 'app-home-courtesy',
  templateUrl: './home-courtesy.component.html',
  styleUrls: ['./home-courtesy.component.css']
})
export class HomeCourtesyWaiverComponent {


}

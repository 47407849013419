import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AgencyGroup } from '../../../models/agency-group.model';
import { BranchOffice } from '../../../models/branch-office.model';
import { BusinessException, Exception, SystemException } from '../../../models/Exception.model';
import { Profile } from '../../../models/profile.model';
import { SessionInfo } from '../../../models/SessionInfo.model';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';
import { User } from './../../../models/user.model';

import { CreateEditUserComponent } from '../../user-module/create-edit-user/create-edit-user.component';

import { AgencyBranchOfficeService } from './../../../services/agency-branch-office.service';
import { MessageService } from './../../../services/message.service';
import { ProfilingService } from '../../../services/profiling.service';
import { SessionInfoService } from '../../../services/session-info.service';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';
import { MessageErrorService } from '../../../services/message-error.service';


@Component({
  selector: 'app-profile-branch-office',
  templateUrl: './profile-branch-office.component.html',
  styleUrls: ['./profile-branch-office.component.css'],
})
export class ProfileBranchOfficeComponent implements OnInit {
  loading = false;
  reloadUsers = false;
  matrixUserInfo: number;
  matrixId: number;

  branchOffice = new BranchOffice();
  agencyGroup = new AgencyGroup();
  infoUser$: Promise<SessionInfo> = this.sessionInfoService.getSessionInfoAsPromise();
  profiles = new Profile();
  private sidebarMenu = new SidebarMenuModel(SidebarMenuModel.MENU_AGENCIES, SidebarMenuModel.SUBMENU_BRANCH_OFFICE_PROFILE);

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private agencyBranchOfficeService: AgencyBranchOfficeService,
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private profilesService: ProfilingService,
    private sessionInfoService: SessionInfoService,
    private sidebarShareDataService: SidebarShareDataService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);

    this.activatedRoute.queryParams.subscribe(params => {
      this.matrixId = params.agencyGroupId;
      this.branchOffice.id = params.branchOfficeId;

      this.infoUser$
        .then(
          sessionInfo => {
            if (!this.matrixId) {
              this.matrixId = sessionInfo.matrix_id;
            }

            if (!this.branchOffice.id) {
              this.branchOffice.id = sessionInfo.branch_office_id;
            }

            this.getBranchOfficeById(this.branchOffice.id);
          }
        )
        .catch(
          error => {
            console.log('error', error);
          }
        );
    });
  }

  private getBranchOfficeById(branchOfficeId: number): void {
    this.loading = true;

    this.agencyBranchOfficeService.getBranchOfficeById(branchOfficeId).subscribe(
      data => {
        this.branchOffice.name = data.name;
        this.agencyGroup.id =  data.matrixId;
        this.loading = false;
      },
      (error: HttpErrorResponse) => {
        if (error.error?.status === 403) {
            this.infoUser$.then( sessionInfo => {
            if (sessionInfo.isLatamSession()) {
            this.router.navigateByUrl('/latam/home');
            } else {
            this.router.navigateByUrl('/agency/home');
            }
            });
        }
        this.messageErrorService.sendError(error, 'error.loadingBranchOffice');

        this.loading = false;
        console.log('error', error);
      }
    );
  }

  private openCreateUserDialog(): void {
    this.reloadUsers = false;
    const dialogRef = this.dialog.open(CreateEditUserComponent);
    dialogRef.componentInstance.user = new User();
    dialogRef.componentInstance.user.agencyGroup = this.agencyGroup;
    dialogRef.componentInstance.user.agencyBranchOffice = this.branchOffice;
    dialogRef.componentInstance.isCreate = true;

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.reloadUsers = true;
      }
    });
  }

  private goBack() {
    this.infoUser$
      .then(
        sessionInfo => {
          if (sessionInfo.isLatamSession()) {
            this.router.navigate(
              ['/latam/agencies/group/profile'],
              { relativeTo: this.activatedRoute, queryParams: { id: this.matrixId } }
            );
          } else {
            this.router.navigate(['/agency/group/profile'], { relativeTo: this.activatedRoute });
          }
        }
      )
      .catch(
        error => {
          console.log('error', error);
        }
      );
  }
}

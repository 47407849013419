<div class = "screen">
  <app-country-images></app-country-images>
</div>

<div class="container" *ngIf="errorCode=='401'">
  <div class="row d-flex align-items-center">
    <div class="col-md-6 col-sm-12">
      <div class="content-message">
        <img src="assets/images/error-page/logo-latamtrade.png" class="img-fluid" width="300" alt="error page"/>
        <h1 class="principal-text">{{ 'authRequired' | translate }}</h1>
        <span>{{ 'authMessage' | translate }}</span>
        <span class="second-message">
          {{ ('pleaseKeepExploring' | translate) + ' ' }}<strong>latamtrade.com</strong>
        </span>
        <a href="javascript:void(0);" (click)="toRoot()">{{ 'button.back' | translate }}</a>
      </div>
    </div>
    <div class="col-md-6 col-sm-12">
      <div class="error-type">
        <h2>
          {{ ('error.error' | translate) + ' ' }}<span>401</span>
        </h2>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="errorCode=='403'">
  <div class="row d-flex align-items-center">
    <div class="col-md-6 col-sm-12">
      <div class="content-message">
        <img src="assets/images/error-page/logo-latamtrade.png" class="img-fluid" width="300" alt="error page"/>
        <h1 class="principal-text">{{ 'forbidden.forbiddenAccess' | translate }}</h1>
        <span>{{ 'allowedAccess' | translate }}</span>
        <span class="second-message">
          {{ ('pleaseKeepExploring' | translate) + ' ' }}<strong>latamtrade.com</strong>
        </span>
        <a href="javascript:void(0);" (click)="toRoot()">{{ 'button.login' | translate }}</a>
      </div>
    </div>
    <div class="col-md-6 col-sm-12">
      <div class="error-type">
        <h2>
          {{ ('error.error' | translate) + ' ' }}<span>403</span>
        </h2>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="errorCode=='404'">
  <div class="row d-flex align-items-center">
    <div class="col-md-6 col-sm-12">
      <div class="content-message">
        <img src="assets/images/error-page/logo-latamtrade.png" class="img-fluid" width="300" alt="error page"/>
        <h1 class="principal-text">{{ 'notFound.pageNotFound' | translate }}</h1>
        <span>{{ 'notFound.pageDoesNotExist' | translate }}</span>
        <span class="second-message">
          {{ ('pleaseKeepExploring' | translate) + ' ' }}<strong>latamtrade.com</strong>
        </span>
        <a href="javascript:void(0);" (click)="toRoot()">{{ 'button.back' | translate }}</a>
      </div>
    </div>
    <div class="col-md-6 col-sm-12">
      <div class="error-type">
        <h2>
          {{ ('error.error' | translate) + ' ' }}<span>404</span>
        </h2>
      </div>
    </div>
  </div>
</div>

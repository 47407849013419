import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { BulkLoadType } from '../models/bulk-load-types.model';

import { RouteLocaleExtractorService } from './route-locale-extractor.service';
import { StorageService } from './storage.service';


@Injectable()
export class BulkLoadService {
  urlBase = `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlFeedback}`;

  constructor(
    private httpClient: HttpClient,
    private routeLocaleExtractorService: RouteLocaleExtractorService) { }

  /**
   * returns a list with all types of bulk load available
   */
  getTypes(): Observable<BulkLoadType[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.httpClient.get<BulkLoadType[]>(`${this.urlBase}${locale}/bulkload/types`)
      .pipe(
        map(data => {
          return data['bulkLoadTypes'];
        })
      )

  }

  /**
   * This method save ADMC agencies
   */
  importAdmcAgency(encodedFile: string): Observable<any> {
    return this.httpClient.post(`${this.urlBase}/importer/admc-agency`, encodedFile);
  }

  /**
   * This method save PIA agencies
   */
  importPiaAgency(file) {
    const fd = new FormData();
    fd.append('file', file);

    return this.httpClient.post(`${this.urlBase}/importer/pia_agency`, fd);
  }

  /**
   * This method save PIA users
   */
  importPiaUser(file) {
    const fd = new FormData();
    fd.append('file', file);

    return this.httpClient.post(`${this.urlBase}/importer/pia_user`, fd);
  }
}

<div class="container">
    <div class="content-container">
        <img  src="assets/images/waiver-latam/wallet.svg" alt="wallet-img"/>

        <h1>
            {{ 'waiverLatam.welcomeMessage1' | translate}}
            <br>{{ 'waiverLatam.welcomeMessage2' | translate}}
            <b> {{ 'waiverLatam.welcomeMessage3' | translate}}</b>
        </h1>

        <h2>{{'waiverLatam.aboutNewModule' | translate}}</h2>
        <p>{{ 'waiverLatam.homeInfo' | translate }}<p>
            
        <div class="info">

            <div class="p-container">
                <img  src="assets/images/waiver-latam/check.svg" alt="check-img-1"/>
                <p><b>{{ 'waiverLatam.homeType1' | translate }}</b>{{ 'waiverLatam.homeType1Description' | translate }}</p>
            </div>
            <div class="p-container">
                <img  src="assets/images/waiver-latam/check.svg" alt="check-img-2"/>
                <p><b>{{ 'waiverLatam.homeType2' | translate }}</b>{{ 'waiverLatam.homeType2Description' | translate }}</p>
            </div>
            <div class="p-container">
                <p><b>{{ 'waiverLatam.homeSeriesGroupsImportant' | translate }}</b>{{ 'waiverLatam.homeSeriesGroupsMessage' | translate }}</p>
            </div>            
        </div>
    </div>
</div>
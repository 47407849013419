import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

import { WaiverInfoInterface } from '../../../interfaces/waiver-info.interface';
import { WaiverRequestInfoInterface } from '../../../interfaces/waiver-request-info.interface';

import { Booking } from '../../../models/booking.model';
import { Passenger } from '../../../models/passenger.model';
import { Pnr } from '../../../models/pnr.model';
import { Segment } from '../../../models/segment.model';

import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { slideInOutAnimation } from '../../../constants/constants';
import { FileDTO } from '../../../models/file-dto.model';
import { Profile } from '../../../models/profile.model';
import { WaiverRejectionMotive } from '../../../models/waiver-rejection-motive.model';
import { MessageErrorService } from '../../../services/message-error.service';
import { ProfilingService } from '../../../services/profiling.service';
import { WaiverPostsaleService } from '../../../services/waiver-postsale.service';
import { WaiverPresaleService } from '../../../services/waiver-presale.service';
import { MessageService } from './../../../services/message.service';


@Component({
  selector: 'app-waiver-list-detail',
  templateUrl: './waiver-list-detail.component.html',
  styleUrls: ['./waiver-list-detail.component.css'],
  animations: [slideInOutAnimation],
  encapsulation: ViewEncapsulation.None
})
export class WaiverListDetailComponent implements OnInit {
  isValuated = false;
  loading = false;
  approveReject = true;
  confirmApproval = false;
  confirmRejection = false;
  approvalComment: string;

  idWaiver = 0;
  numberPassengers = 0;
  step = 1;

  displayedColumnsBookingInfo = [
    'pnrNumber',
    'type',
    'createDateTime',
    'numberPassengers',
    'pos',
    'iata',
    'ppc',
    'accountCode',
    'officeId'
  ];
  displayedColumnsIssuedRequestInfo = ['userName', 'typeName', 'pnrAgency', 'aditionalComment', 'creationDate'];
  displayedColumnsPassengerInfo = ['name', 'foId', 'type'];
  displayedColumnsRejectionInfo = [
    'statusDate',
    'rejectionMotive',
    'statusComment',
    'paxOriginalBaseFare',
    'paxBaseFare',
    'discountRate',
    'valorization'
  ];
  displayedColumnsPendingInfo = [
    'paxOriginalBaseFare',
    'paxBaseFare',
    'discountRate',
    'valorization'
  ];
  displayedColumnsRejectionRequestInfo = ['userName', 'typeName', 'motive'];
  displayedColumnsRequestInfo = ['userName', 'typeName', 'pnrAgency', 'aditionalComment'];
  displayedColumnsSegmentInfo = [
    'segmentNumber',
    'carrierMarketing',
    'flightNumber',
    'fareBasis',
    'source',
    'destination',
    'departureDateTime',
    'status'
  ];
  displayedColumnsWaiverInfo = ['creationDate', 'statusComment', 'paxOriginalBaseFare', 'paxBaseFare', 'discountRate', 'valorization'];
  displayedColumnsIssuedWaiverInfo = [
    'statusDate',
    'statusComment',
    'folio',
    'paxOriginalBaseFare',
    'paxBaseFare',
    'discountRate',
    'valorization'
  ];
  tab = '';
  motives: {};
  displayedColumnsCo = ['files', 'coment'];
  rejectionComment: string;
  profiles = new Profile();

  booking: Booking;
  passengers: Passenger[];
  pnr: Pnr;
  segments: Segment[];
  waiverInfo: WaiverInfoInterface;
  waiverRequestInfo: WaiverRequestInfoInterface;
  files: FileDTO[];
  dataSource = new MatTableDataSource();
  selectedMotive: WaiverRejectionMotive = new WaiverRejectionMotive(-1, '');
  animationState = 'out';

  constructor(
    private dialogRef: MatDialogRef<WaiverListDetailComponent>,
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private waiverPostsaleService: WaiverPostsaleService,
    private translateService: TranslateService,
    private profilesService: ProfilingService,
    private waiverPresaleService: WaiverPresaleService) { }

  ngOnInit() {
    this.loading = true;
    this.profiles = this.profilesService.getProfile();
    this.getRejectionMotives();

    if (this.tab === 'issued') {
      this.displayedColumnsPassengerInfo.push('ticketNumber');
    }

    this.waiverPresaleService.getWaiver(this.idWaiver).subscribe({
      next: data => {
        this.waiverInfo = {
          creationDate: data.creationDate,
          statusDate: data.statusDate,
          statusComment: data.statusComment,
          folio: data.folio,
          paxOriginalBaseFare: data.documentFare ? data.documentFare.originalPaxBaseFare : null,
          paxBaseFare: data.documentFare ? data.documentFare.paxBaseFare : null,
          discountRate: data.documentFare ? data.documentFare.discountRate : null,
          valorization: data.valorization,
          rejectionMotive: data.rejectionMotive
        };
        this.dataSource.data = [ data ];

        this.waiverRequestInfo = {
          userName: data.userAgencyFullName,
          typeName: data.type,
          pnrAgency: data.documentNumber,
          aditionalComment: data.aditionalComment,
          creationDate: data.creationDate,
          motive: data.motive
        };

        this.pnr = new Pnr(data.documentNumber, data.documentCreationDate, data.documentPnrType);

        this.booking = new Booking(
          data.documentIata,
          data.documentPcc,
          data.documentOfficeId,
          data.documentPos,
          data.documentFare ? data.documentFare.pricingTypeCode : null
        );

        this.passengers = data.passengers;

        this.numberPassengers = data.passengers.length;

        this.segments = data.segments;

        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.loadingWaiver');

        this.closeDialog();
        this.loading = false;
        console.log('error', error);
      }
  });
  }

  private getRejectionMotives(): void {

    this.waiverPostsaleService.getRejectionMotives().subscribe({
      next: data => {
        this.motives = data;
      },
      error: (error: HttpErrorResponse) => {
        console.log('error', error);
      }
  });
  }

  toggleShowDiv(divName: string) {
    if (divName === 'divFooter') {
      this.animationState = this.animationState === 'out' ? 'in' : 'out';
    }
  }

  toggleShowTab(divName: string) {
    if (divName === 'divFooter' && this.approveReject) {
      this.animationState = this.animationState === 'out' ? 'in' : 'out';
      this.approveReject = false;
    }
  }

  approveWaiver() {
    this.loading = true;

    this.waiverPresaleService.putApproveWaiver(
      this.idWaiver, this.approvalComment).subscribe({
        next: data => {
          this.dialogRef.close('reload-approved');

          this.messageService.showSuccessMessage(
            this.translateService.instant('button.back'),
            this.translateService.instant('popup.wellDone'),
            this.translateService.instant('success.aprovedWaiver', { waiverId: this.idWaiver.toString() })
          );

          this.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.dialogRef.close('error');

          this.messageErrorService.sendError(error, 'error.aprovedWaiver')

          this.loading = false;
          console.log('error', error);
        }
  });
  }

  rejectWaiver() {
    this.loading = true;

    this.waiverPresaleService.putRejectWaiver(this.idWaiver, this.selectedMotive.id, this.rejectionComment).subscribe({
      next: data => {
        this.dialogRef.close('reload-rejected');

        this.messageService.showSuccessMessage(
          this.translateService.instant('button.back'),
          this.translateService.instant('popup.wellDone'),
          this.translateService.instant('success.deniedWaiver', { waiverId: this.idWaiver.toString() })
        );

        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.dialogRef.close('error');
        this.messageErrorService.sendError(error, 'error.deniedWaiver');

        this.loading = false;
        console.log('error', error);
      }});
  }

  /**
   * metodo para cerrar el modal
   */
  closeDialog(): void {
    this.dialogRef.close();
  }
}

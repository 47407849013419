import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { FileUploadModel } from '../../../models/file-upload.model';
import { WaiverUserManualFile } from '../../../models/waiver-user-manual-file-model';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';
import { FileStorageService } from '../../../services/file-storage.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import { MessageErrorService } from '../../../services/message-error.service';


@Component({
  selector: 'app-waiver-manual-new',
  templateUrl: './waiver-manual-new.component.html',
  styleUrls: ['./waiver-manual-new.component.css']
})
export class WaiverManualNewComponent {
  @ViewChild(MatPaginator) manualPaginator: MatPaginator;
  @ViewChild(MatSort) manualSort: MatSort;

  manualDataSource = new MatTableDataSource<any>();
  public manualDisplayedColumns: string[] = ['fileName', 'languageCategory', 'waiverCategoryNew', 'waiverTypeNew' ,'actions'];
  dataNotfound = (index, item) => item.noData;
  selectedRowIndex: number = -1;
  downloading = false;
  downloadingSuccessfully = false;

  sidebar: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_LATAM, SidebarMenuModel.SUBMENU_WAIVER_MANUAL_MANAGEMENT);


  noFilesFoundBucket = false;
  textFile: File;
  isLoading: boolean = true;
  fileIdType: number;
  formato: string = "";

  public files: Array<FileUploadModel> = [];

  operationfinishOK: boolean = false;

  // fin adjuntar archivos
  constructor(
    private messagesErrorService: MessageErrorService,
    private translateService: TranslateService,
    private fileStorageService: FileStorageService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loadFiles();
  }

  /**
   * Obtener los archvios del Bucket de error
   */
  public loadFiles() {
    this.fileStorageService.listWaiverUserManual().subscribe({
        next: data => this.handleFileData(data),
        error: error => this.handleError(error)
    });
  }

  handleFileData(data: any) {
      if (data === undefined || data.length === 0) {
          this.setNoFilesFound();
      } else {
          const dataFiltered: any = data.filter(w => w?.language === this.translateService.currentLang);
          this.setDataSource(dataFiltered);
      }
  }

  handleError(error: HttpErrorResponse) {
      if (error.status === 404) {
          this.setNoFilesFound();
      } else {
          this.showLoadError();
      }
      console.log('error', error);
  }

  setNoFilesFound() {
      this.noFilesFoundBucket = true;
      this.manualDataSource.data = [{ noData: true }];
      this.manualDataSource.paginator = null;
  }

  setDataSource(data: any) {
      this.manualDataSource.data = data;
      this.manualDataSource.paginator = this.manualPaginator;
      this.manualDataSource.sort = this.manualSort;
      this.isLoading = false;
  }

  showLoadError() {
      Swal.fire({
          html: '<p>' + this.translateService.instant('login.error.loadError') + '</p>',
          confirmButtonText: this.translateService.instant('button.confirm'),
          confirmButtonColor: '#d33',
          icon: 'error',
          allowOutsideClick: false
      }).then(result => {
          if (result.value) {
              this.refreshView();
          }
      });
  }

  bytesToSize(bytes: number): string {
    const units = ['KB', 'MB', 'GB', 'TB'];
    let size = bytes / 1024;
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
    }

    return `${size.toFixed(1)} ${units[unitIndex]}`;
}

  /**
  * Metodo que sirve para refrescar la pagina
  */
  refreshView() {
    this.isLoading = true;
    this.files = [];
    this.ngOnInit();
    
  }

  valueSelect(event: { value: { name: string, id: number } }) {
    const { name, id } = event.value;
    this.formato = name;
    this.fileIdType = id;
}

  highlightRow(event: Event, rowIndex: number) {
    this.selectedRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }


  downloadFile(fileBdaStorage: WaiverUserManualFile) {
    console.log('downloadFile');
    this.setDownloadState(true, false, fileBdaStorage, true);

    this.fileStorageService.downloadWaiverUserManual(fileBdaStorage.locationStorage, "es").subscribe({
        next: response => this.handleFileDownloadSuccess(response, fileBdaStorage),
        error: error => this.handleFileDownloadError(error, fileBdaStorage)
    });
  }

  setDownloadState(downloading: boolean, downloadingSuccessfully: boolean, fileBdaStorage: WaiverUserManualFile, inProgress: boolean) {
      this.downloading = downloading;
      this.downloadingSuccessfully = downloadingSuccessfully;
      fileBdaStorage.inProgress = inProgress;
  }

  handleFileDownloadSuccess(response: Blob, fileBdaStorage: WaiverUserManualFile) {
      const file = new File([response], fileBdaStorage.name, { type: 'text/csv' });
      this.downloadFileFromBlob(file);
      this.setDownloadState(false, true, fileBdaStorage, false);
  }

  handleFileDownloadError(error: HttpErrorResponse, fileBdaStorage: WaiverUserManualFile) {
      console.error('Error al descargar el archivo:', error);
      this.messagesErrorService.sendError(error, 'bda.error.downloadFile');
      this.setDownloadState(false, false, fileBdaStorage, false);
      console.log('error', error);
  }

  downloadFileFromBlob(file: File) {
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(file);
      downloadLink.download = file.name;
      downloadLink.click();
  }
}

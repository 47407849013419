
import {map} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { LoginService } from './login.service';
import { SessionInfoService } from './session-info.service';
import { UserService } from './user.service';
import { FirebaseLoginResponse } from '../models/firebase-login-response.model';
import { StorageService } from './storage.service';


@Injectable()
export class LoginAgencyService {

  urlBase = StorageService.getDnsLatamtrade() + environment.endpoints.urlUserAdmin;
  pathBase = this.urlBase + "/api/firebase/";
  pathLogin = this.pathBase + "login";
  refreshToken = this.pathBase + "refresh/token";
  sendResetEmail = this.pathBase + "send/password/reset/email";
  verifyCode = this.pathBase + "verify/reset/code";
  headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

  constructor(
    private router: Router,
    private loginService: LoginService,
    private sessionInfoService: SessionInfoService,
    private http: HttpClient,
    private userService: UserService) {
  }

  changePassword(token: string, newpass: string): Observable<void> {
    return this.http.post<void>(this.verifyCode, {oobCode: token, newPassword: newpass}, { headers: this.headers });
  }

  isAuthorized(isOutsourcing: boolean) {
    if (isOutsourcing){
      this.userService.getOutsourcingUserSessionToken();
    }
    return this.userService.getUserSessionToken();
  }

  login(email: string, cred: string): Observable<FirebaseLoginResponse> {
    return this.http.post<FirebaseLoginResponse>(this.pathLogin, {email, password: cred, returnSecureToken: true}, { headers: this.headers });
  }

  logout(isOutsourcing: boolean) {
    this.sessionInfoService.cleanSession();
    this.loginService.setCurrentLoginState(false);
    const path = isOutsourcing ? ['/login/outsourcing'] : ['/login/agency'];
    this.router.navigate(path);
  }

  recoveryPassword(email: string): Observable<void> {
    const request = {
      requestType: "PASSWORD_RESET",
      email,
      clientType: "CLIENT_TYPE_WEB",
      continueUrl: StorageService.getDnsLogin(),
      canHandleCodeInApp: false
    }

    return this.http.post<void>(this.sendResetEmail, request, { headers: this.headers });
  }

  renewToken(refreshToken: string): Observable<string> {
    return this.http.post<FirebaseLoginResponse>(this.pathLogin, {refreshToken, grantType: 'refresh_token'}, { headers: this.headers }).pipe(map(resp => resp.idToken));
  }

  verifyResetCode(token: string): Observable<void> {
    return this.http.post<void>(this.verifyCode, {oobCode: token}, { headers: this.headers });
  }
}

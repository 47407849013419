import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';

import { BranchOffice } from '../../../models/branch-office.model';
import { PiaCode } from '../../../models/piaCode.model';
import { Profile } from '../../../models/profile.model';
import { Role } from '../../../models/role.model';
import { System } from '../../../models/system.model';
import { User } from '../../../models/user.model';

import { AgencyBranchOfficeService } from './../../../services/agency-branch-office.service';
import { AgencyPiaService } from './../../../services/agency-pia.service';
import { ProfilingService } from '../../../services/profiling.service';
import { MessageService } from '../../../services/message.service';
import { SessionInfoService } from '../../../services/session-info.service';
import { UserService } from '../../../services/user.service';
import { DateAdapter } from '@angular/material/core';
import { MessageErrorService } from '../../../services/message-error.service';


@Component({
  selector: 'app-create-edit-user',
  templateUrl: './create-edit-user.component.html',
  styleUrls: ['./create-edit-user.component.css']
})
export class CreateEditUserComponent implements OnInit {
  formG: UntypedFormGroup;

  isCreate: boolean;
  isEdit: boolean;
  loading = false;
  loadingBranchOffices = false;
  loadingIataPiaCodes = false;
  viewBranchOffice = false;
  viewPiaCode = false;

  branchOffices: BranchOffice[] = [];
  maxDate = new Date();
  piaCodes: PiaCode[] = [];
  profiles = new Profile();
  systemsAux: System[] = [];
  user: User;

  private sessionInfo = this.sessionInfoService.getSessionInfo();
  private systems: System[] = [];

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private adapter: DateAdapter<any>,
    private dialogRef: MatDialogRef<CreateEditUserComponent>,
    private formB: UntypedFormBuilder,
    private agencyBranchOfficeService: AgencyBranchOfficeService,
    private agencyPiaService: AgencyPiaService,
    private profilesService: ProfilingService,
    private messageErrorService: MessageErrorService,
    private messageService: MessageService,
    private sessionInfoService: SessionInfoService,
    private translateService: TranslateService,
    private userService: UserService) {
      this.adapter.setLocale(locale);
  }

  ngOnInit() {
    this.buildForm();
    this.getRolesBySystem();
    this.profiles = this.profilesService.getProfile();

    if (this.user.agencyBranchOffice) {
      if (this.user.agencyBranchOffice.id) {
        this.addFormControl('branchOffice', { value: this.user.agencyBranchOffice, disabled: true });
        this.getBranchOfficeList();
        this.viewBranchOffice = true;
      }
    }
  }

  buildForm() {
    this.formG = this.formB.group({
      name: [this.user ? this.user.name : null, Validators.required],
      lastname: [this.user ? this.user.lastname : null, Validators.required],
      email: [{ value: this.user ? this.user.email : null, disabled: this.isEdit }, Validators.required],
      position: this.user ? this.user.position : null,
      birthdate: [{ value: this.user ? this.user.birthdate : null, disabled: true }]
    });
  }

  public getRolesBySystem() {
    this.loading = true;

    this.userService.getRolesByStystems().subscribe({
      next: res => {
        this.systems = res;
        let control: UntypedFormControl;

        if (this.sessionInfo.isAgencySession()) {
          for (const system of this.systems) {
            if (system.name.toLowerCase().trim().replace(' ', '') !== 'pia') {
              this.systemsAux.push(system);
            }
          }
        } else {
          this.systemsAux = this.systems;
        }

        for (const system of this.systemsAux) {
          if (this.user.roles) {
            control = new UntypedFormControl(this.user.roles.find(f => f.systemOrigin.id === system.id));
          } else {
            control = new UntypedFormControl(null);
          }

          this.formG.addControl(system.name.toLowerCase().trim().replace(' ', '') + 'Roles', control);
        }

        if (this.sessionInfo.isLatamSession() && this.user.agency?.id) {
            this.addFormControl('piaCode', { value: this.user.agency, disabled: true });
            this.getPiaCodes(this.user.agencyGroup.id, this.user.agencyBranchOffice ? this.user.agencyBranchOffice.id : null);
            this.viewPiaCode = true;
        }

        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.loadingRoles');

        this.loading = false;
        console.log('error', error);
      }
  });
  }

  private getBranchOfficeList() {
    this.loadingBranchOffices = true;

    this.agencyBranchOfficeService.getBranchOfficeList(
      this.user.agencyGroup.id,
      this.user.agencyBranchOffice ? this.user.agencyBranchOffice.id : null).subscribe({
      next: data => {
        if (data.length > 0) {
          this.branchOffices = data;
        } else {
          this.branchOffices = [];
        }

        this.formG.get('branchOffice').enable();
        this.loadingBranchOffices = false;
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.loadingBranchOffice');

        this.loadingBranchOffices = false;
        console.log('error', error);
      }
  });
  }

  getPiaCodes(agencyGroupId: number, agencyBranchOfficeId: number) {
    this.loadingIataPiaCodes = true;

    this.agencyPiaService.getPiaCodes(agencyGroupId, agencyBranchOfficeId).subscribe({
        next: data => {
          if (data.length > 0) {
            this.piaCodes = data;

            if (this.formG.get('piaCode')) {
              this.formG.get('piaCode').enable();
            }
          } else {
            this.piaCodes = [];
            this.viewPiaCode = false;

            if (this.formG.get('piaCode')) {
              this.formG.removeControl('piaCode');
            }

            if (this.formG.get('piaRoles')) {
              this.formG.get('piaRoles').reset();
            }
          }

          this.loadingIataPiaCodes = false;
        },
        error: (error: HttpErrorResponse) => {
          this.messageErrorService.sendError(error, 'error.loadingPiaCodes');

          this.loadingIataPiaCodes = false;
          console.log('error', error);
        }
  });
  }

  save(): void {
    if (this.isCreate) {
      this.createUser();
    } else if (this.isEdit) {
      this.editUser();
    }
  }

  private createUser(): void {
    const user = this.setUser();

    if (user) {
      this.loading = true;

      this.userService.createUser(user).subscribe({
        next: data => {
          this.loading = false;
          this.closeDialog('ok');
          this.messageService.showSuccessMessage(
            this.translateService.instant('button.confirm'),
            this.translateService.instant('popup.wellDone'),
            this.translateService.instant('success.createUser')
          );
        },
        error: (error: HttpErrorResponse) => {
          this.messageErrorService.sendError(error, 'error.createUser');

          this.loading = false;
          this.closeDialog('error');
        }
    });
    } else {
      return;
    }
  }

  private editUser(): void {
    const user = this.setUser();

    if (user) {
      this.loading = true;

      this.userService.editUser(user).subscribe({
        next: data => {
          this.loading = false;
          this.closeDialog('ok');
          this.messageService.showSuccessMessage(
            this.translateService.instant('button.confirm'),
            this.translateService.instant('popup.wellDone'),
            this.translateService.instant('success.editUser')
          );
        },
        error: (error: HttpErrorResponse) => {
          this.messageErrorService.sendError(error, 'error.editUser');

          this.loading = false;
          this.closeDialog('error');
        }
    });
    } else {
      return;
    }
  }

  private setUser(): User {
    const user: User = new User();
    user.roles = [];

    for (const system of this.systems) {
      const systemName = system.name.toLowerCase().trim().replace(' ', '');
      // recupera los valores de los roles ingresados en el popup
      const rolesControl = this.formG.get(systemName + 'Roles');
      if (rolesControl?.value) {
        user.roles.push(rolesControl.value);
      }

      // si el usuario tiene roles pia los agrega, ya que estan ocultos para usuarios agencia en el popup
      if (this.sessionInfo.isAgencySession() && this.user.agency?.id && systemName === 'pia') {
        user.roles.push(...system.roles);
      }
    }

    if (user.roles.length === 0) {
      this.formG.setErrors({ 'noRoleSelected': true });
      return null;
    } else {
      this.formG.setErrors({ 'noRoleSelected': false });
    }

    user.id = this.user.id;
    user.name = this.formG.get('name').value;
    user.lastname = this.formG.get('lastname').value;
    user.birthdate = this.formG.get('birthdate').value;
    user.email = this.formG.get('email').value;
    user.position = this.formG.get('position').value;
    user.association = this.user.association;
    user.agencyGroup = this.user.agencyGroup;

    if (this.formG.get('branchOffice')) {
      user.agencyBranchOffice = this.formG.get('branchOffice').value;
    } else {
      user.agencyBranchOffice = null;
    }

    if (this.formG.get('piaCode')) {
      user.agency = this.formG.get('piaCode').value;
    } else if (this.sessionInfo.isAgencySession() && this.user.agency) {
      user.agency = this.user.agency;
    } else {
      user.agency = null;
    }

    return user;
  }

  onSelectRole(event: MatSelectChange) {

    if (event.value.type.code === 'SUC' && !this.sessionInfo.isAdminBranchOfficeUser()) {
      this.handleSuc();
    } else if (event.value.type.code === 'MTZ') {
      this.handleMtz();
    } else if (event.value.code === 'PIA' && event.value.type.code === 'EXT') {
      this.handlePiaExt();
    }
  }
  handlePiaExt() {
    this.getPiaCodes(
      this.user.agencyGroup.id,
      this.formG.get('branchOffice')?.value ? this.formG.get('branchOffice').value.id : null
    );

    if (!this.formG.get('piaCode')) {
      this.addFormControl('piaCode', { value: null, disabled: this.piaCodes.length === 0 });
    }

    this.viewPiaCode = true;
  }

  handleMtz() {
    if (this.formG.get('branchOffice')) {
        this.formG.removeControl('branchOffice');
      }

      this.viewBranchOffice = false;

      if (this.sessionInfo.isLatamSession() && this.formG.get('piaCode')) {
        this.formG.get('piaCode').reset();
        this.viewPiaCode = true;
        this.getPiaCodes(this.user.agencyGroup.id, null);
      }
  }

  handleSuc() {
    if (!this.formG.get('branchOffice')) {
      this.addFormControl('branchOffice', { value: null, disabled: this.branchOffices.length === 0 });
    } else {
      this.formG.get('branchOffice').reset();
    }

    if (this.branchOffices.length === 0) {
      this.getBranchOfficeList();
    }

    this.viewBranchOffice = true;
  }

  onSelectBranchOffice(event: MatSelectChange) {
    if (this.formG.get('piaCode')) {
      this.formG.get('piaCode').reset();
      this.viewPiaCode = true;
      this.getPiaCodes(this.user.agencyGroup.id, event.value.id);
    }
  }

  private addFormControl(controlName: string, formState: any): void {
    if (!this.formG.contains(controlName)) {
      this.formG.addControl(
        controlName,
        new UntypedFormControl(formState, Validators.required)
      );
    }
  }

  compareRole(role1: Role, role2: Role): boolean {
    return role1 && role2 ? role1.id === role2.id : role1 === role2;
  }

  compareBranchOffice(branchOffice1: BranchOffice, branchOffice2: BranchOffice): boolean {
    return branchOffice1 && branchOffice2 ? branchOffice1.id === branchOffice2.id : branchOffice1 === branchOffice2;
  }

  comparePiaCode(piaCode1: PiaCode, piaCode2: PiaCode): boolean {
    return piaCode1 && piaCode2 ? piaCode1.id === piaCode2.id : piaCode1 === piaCode2;
  }

  closeDialog(event: string): void {
    this.dialogRef.close(event);
  }

  setCalendarLanguage() {
    this.adapter.setLocale(localStorage.getItem('language'));
  }
}

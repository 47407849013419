export class SidebarMenuModel {

  public static readonly MENU_NONE = 0;
  public static readonly MENU_LATAM = 1;
  public static readonly MENU_AGENCIES = 2;
  public static readonly MENU_WAIVERS = 3;
  public static readonly MENU_DBA_PORTAL = 4;
  public static readonly MENU_VENTA_PORTAL = 5;
  public static readonly MENU_STATUSTICKET = 6;
  public static readonly MENU_CORRECTNAME = 7;
  public static readonly MENU_FARE_REGULATION = 8;

  public static readonly SUBMENU_LATAM_USER_CREATION = 1;
  public static readonly SUBMENU_LATAM_USERS_LIST = 2;
  public static readonly SUBMENU_AGENCY_SEARCH = 3;
  public static readonly SUBMENU_AGENCY_PROFILE = 4;
  public static readonly SUBMENU_AGENCY_REGISTER = 5;
  public static readonly SUBMENU_AGENCY_GROUP = 6;
  public static readonly SUBMENU_WAIVER_REQUEST = 7;
  public static readonly SUBMENU_WAIVER_MANAGEMENT = 8;
  public static readonly SUBMENU_LATAM_FEEDBACK = 9;
  public static readonly SUBMENU_LATAM_BUDGET_MANAGEMENT = 10;
  public static readonly SUBMENU_LATAM_BUDGET_BULK_LOAD = 11;  
  public static readonly SUBMENU_AGENCY_REPORT = 11;
  public static readonly SUBMENU_LATAM_REPORT = 11;
  public static readonly SUBMENU_WAIVER_AUTOMATION = 12;
  public static readonly SUBMENU_WAIVER_TYPE_MANAGEMENT = 13;
  public static readonly SUBMENU_WAIVER_FAREDIFF_MANAGEMENT = 14;
  public static readonly SUBMENU_BULK_LOAD = 15;
  public static readonly SUBMENU_PRESALE_WAIVER_REQUEST = 16;
  public static readonly SUBMENU_PRESALE_WAIVER_MANAGEMENT = 17;
  public static readonly SUBMENU_MATRIX_PROFILE = 18;
  public static readonly SUBMENU_PARAMETERS = 19;
  public static readonly SUBMENU_BRANCH_OFFICE_PROFILE = 20;
  public static readonly SUBMENU_USER_PROFILE = 21;
  public static readonly SUBMENU_VENTA_PORTAL = 22;
  public static readonly SUBMENU_WAIVER_ONE_DEAL_REQUEST = 23;
  public static readonly SUBMENU_WAIVER_MANUAL_MANAGEMENT = 24;
  public static readonly SUBMENU_WAIVER_COURTESY_REQUEST = 25;
  public static readonly SUBMENU_WAIVER_POLICY_REQUEST = 26;


  public panelOpened: number;
  public subPanelOpened: number;

  public constructor(panelOpened?: number, subPanelOpened?: number) {
    this.panelOpened = panelOpened;
    this.subPanelOpened = subPanelOpened;
  }
}


<div *ngIf="loading" class="loading">
  <div class="loading-div">
    <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
    <span class="loading-span">{{ 'loading' | translate }}</span></div>
</div>
<div [ngClass]="{ 'd-none': loading }" class="table-waivers">
  <app-filter class="mt-3 ml-0 mr-0" [item]="filter" [tab]="tab" (sendFilters)="sendFilterData($event)" (cleanFilters)="cleanFilters()"></app-filter>

  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.idRequest' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.id | dashWhenEmpty }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.type' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.type | dashWhenEmpty }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="documentNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.pnr' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.documentNumber | dashWhenEmpty }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="matrixBranchOffice">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.agency' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ (element.branchOffice ? element.branchOffice : element.matrix) | dashWhenEmpty }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="salesExecutive">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.executiveSale' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.salesExecutive | dashWhenEmpty }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="agent">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.requestedBy' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.agent | dashWhenEmpty}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="creationDate">
      <ng-container *ngIf="tab === 'approved'">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.approvalDateHour' | translate }}</mat-header-cell>
      </ng-container>
      <ng-container *ngIf="tab === 'pending'">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.requestDateHour' | translate }}</mat-header-cell>
      </ng-container>
      <mat-cell *matCellDef="let element">
        {{ element.creationDate | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="statusDate">
      <ng-container *ngIf="tab === 'issued'">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.issuedDateHour' | translate }}</mat-header-cell>
      </ng-container>
      <ng-container *ngIf="tab === 'expired'">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.expiredDateHour' | translate }}</mat-header-cell>
      </ng-container>
      <ng-container *ngIf="tab === 'rejected'">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.denialDateHour' | translate }}</mat-header-cell>
      </ng-container>
      <mat-cell *matCellDef="let element">
        {{ element.statusDate | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="documentCloserDepartureDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.flightDate' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.documentCloserDepartureDate | dateFormat: 'DD-MMM-YY' | uppercase | dashWhenEmpty }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="valorization">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.waiverValueUsd' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.valorization | number: '1.2-2': 'en-US' | dashWhenEmpty }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions" *ngIf="profiles.readWaiverPreSaleManagmenet || profiles.readPreSaleRequest">
      <mat-header-cell *matHeaderCellDef><span class="ml-2">{{ 'button.actions' | translate }}</span></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button (click)="openDialog(tab, element.id)" class="action">{{ 'button.view' | translate }}</button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="no-data-found">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <ng-container *ngIf="tab === 'approved'">
        <mat-cell class="no-data-found-row" *matCellDef="let item">{{ 'error.notApprovedWaivers' | translate }}
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="tab === 'issued'">
        <mat-cell class="no-data-found-row" *matCellDef="let item">{{ 'error.notIssuedWaivers' | translate }}
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="tab === 'expired'">
        <mat-cell class="no-data-found-row" *matCellDef="let item">{{ 'error.notExpiredWaivers' | translate }}
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="tab === 'rejected'">
        <mat-cell class="no-data-found-row" *matCellDef="let item">{{ 'error.notDeniedWaivers' | translate }}
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="tab === 'pending'">
        <mat-cell class="no-data-found-row" *matCellDef="let item">{{ 'error.loadingPendingWaivers' | translate }}
        </mat-cell>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="loading-data-error">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell class="loading-data-error-row" *matCellDef="let item">
        <span class="cdk-visually-hidden" #translatedTooltipMessage>{{ 'button.refresh' | translate }}</span>
        <button mat-icon-button
          (click)="tab === 'approved' ? getApprovedWaiver() : (tab === issued ? getWaiverIssued() : (tab === 'expired' ? getWaiverExpired() : (tab === 'rejected' ? getRejectedWaiver() : null)))"
          class="refresh-spinner">
          <mat-icon [matTooltip]="translatedTooltipMessage.innerHTML" [matTooltipPosition]="'right'">refresh
          </mat-icon>
        </button>
        <br>
        <ng-container *ngIf="tab === 'approved'">
          <span>{{ 'error.loadingAprovedWaivers' | translate }}</span>
        </ng-container>
        <ng-container *ngIf="tab === 'issued'">
          <span>{{ 'error.loadingIssuedWaivers' | translate }}</span>
        </ng-container>
        <ng-container *ngIf="tab === 'expired'">
          <span>{{ 'error.loadingExpiredWaivers' | translate }}</span>
        </ng-container>
        <ng-container *ngIf="tab === 'rejected'">
          <span>{{ 'error.loadingDeniedWaivers' | translate }}</span>
        </ng-container>
        <ng-container *ngIf="tab === 'pending'">
          <span>{{ 'error.loadingPendingWaivers' | translate }}</span>
        </ng-container>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns" class="waivers-row"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"
      [ngClass]="{'highlight': selectedRowIndex === i}" (mouseover)="highlightRow($event, i)"
      (mouseout)="highlightRow($event, i)" class="waivers-row"></mat-row>

    <mat-row *matRowDef="let row; columns: ['no-data-found']; when: noWaiversFound"></mat-row>

    <mat-row *matRowDef="let row; columns: ['loading-data-error']; when: errorLoadingWaivers"></mat-row>
  </mat-table>

  <mat-paginator
    *ngIf="!noWaivers"
    #paginatorApproved
    [pageIndex]="pageNumber"
    [pageSize]="pageSize"
    [pageSizeOptions]="[10, 30, 50]"
    [length]="totalElements"
    (page)="updateList($event)"
    [showFirstLastButtons]="true">
  </mat-paginator>
</div>


import {map} from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { BranchOfficeData } from '../interfaces/branch-office-data.interface';

import { AgencySimplified } from './../models/agency-simplified.model';
import { BranchOffice } from '../models/branch-office.model';
import { Pagination } from '../models/pagination.model';

import { RouteLocaleExtractorService } from './route-locale-extractor.service';
import { StorageService } from './storage.service';


@Injectable()
export class AgencyBranchOfficeService {
  headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  urlBase = `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlAgency}`;

  constructor(
    private http: HttpClient,
    private routeLocaleExtractorService: RouteLocaleExtractorService) { }

  /**
   * Crea una Sucursal
   * @param branchOffice
   * @returns `Observable<BranchOffice>`
   */
  createBranchOffice(branchOffice: BranchOffice): Observable<BranchOffice> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.post<BranchOffice>(`${this.urlBase}${locale}/agency-branch-office`, branchOffice, { headers: this.headers });
  }

  getAgencyListByBranchOfficeId(branchOfficeId: number, pageNumber: number, pageSize: number):
    Observable<{ agencySimplified: AgencySimplified[], pagination: Pagination }> {
    const locale = this.routeLocaleExtractorService.getLocale();
    let agencySimplified: AgencySimplified[] = [];
    let pagination = new Pagination();

    return this.http.get<{ agencySimplified: AgencySimplified[], pagination: Pagination }>(
      `${this.urlBase}${locale}/agency-branch-office/iatas/${branchOfficeId}`,
      {
        params: new HttpParams()
          .set('page', pageNumber.toString())
          .set('size', pageSize.toString())
          .set('sort', 'id')
      }
    ).pipe(map(
      resp => {
        agencySimplified = resp['content'];
        pagination.totalElements = resp['totalElements'];
        pagination.pageIndex = resp['number'];
        pagination.pageSize = resp['size'];

        return { agencySimplified, pagination };
      }
    ));
  }

  getBranchOfficeById(branchOfficeId: number): Observable<BranchOfficeData> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<BranchOfficeData>(`${this.urlBase}${locale}/agency-branch-office/info/${branchOfficeId}`);
  }

  getBranchOfficeList(agencyGroupId: number, branchOfficeId: number): Observable<BranchOffice[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    if (branchOfficeId != null) {
      return this.http.get<BranchOffice[]>(`${this.urlBase}${locale}/agency-branch-office/group?groupId=${agencyGroupId}&branchOfficeId=${branchOfficeId}`);
    }

    return this.http.get<BranchOffice[]>(`${this.urlBase}${locale}/agency-branch-office/group?groupId=${agencyGroupId}`);
  }

  getBranchOfficeListByGroudId(matrixId: number, pageNumber: number, pageSize: number, sortOrder: string, budgetTypeName: string):
    Observable<{ branchOfficeData: BranchOfficeData[], pagination: Pagination }> {
    const locale = this.routeLocaleExtractorService.getLocale();
    let branchOfficeData: BranchOfficeData[] = [];
    let pagination = new Pagination();

    return this.http.get<{ branchOfficeData: BranchOfficeData[], pagination: Pagination }>(
      `${this.urlBase}${locale}/agency-branch-office/groupId`, {
      params: new HttpParams()
        .set('page', pageNumber.toString())
        .set('size', pageSize.toString())
        .set('groupId', matrixId.toString())
        .set('sort', sortOrder)
        .set('budgetTypeName', budgetTypeName)
    }).pipe(map(
      resp => {
        pagination.totalElements = resp['totalElements'];
        pagination.pageIndex = resp['number'];
        pagination.pageSize = resp['size'];
        resp['content'].map(
          data => {
            branchOfficeData.push({
              id: data.id,
              name: data.name,
              assignedValue: data.assignedValue,
              availableValue: data.availableValue,
              spentValue: data.spentValue,
              agencySimplifiedDTO: data.agencySimplifiedDTO,
              matrixId: data.matrixId
            });
          }
        );

        return { branchOfficeData, pagination };
      }
    ));
  }

  updateBranchOffice(budgetTypeId: number, brancOffice: BranchOffice): Observable<BranchOffice> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.put<BranchOffice>(
      `${this.urlBase}${locale}/agency-branch-office/configure-show-budget?budgetTypeId=${budgetTypeId}`,
      brancOffice,
      { headers: this.headers }
    );
  }
}

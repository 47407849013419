import { RoleAction } from './role-action.model';

export class LatamRole {
  id: number;
  name: string;
  code: string;
  actions: RoleAction[];

  constructor(
    id: number,
    name: string) {
      this.id = id;
      this.name = name;
  }
}

<ng-container *ngIf="!noDataFound && !errorLoadingData">
  <div class="row mr-3 mb-3 ml-3 justify-content-end">
    <div class="col-3">
      <div class="row">
        <div class="col-12 text-center">
          <span class="text">{{ 'budgetToDistribute' | translate }}</span>
        </div>
        <div class="col-12 text-center">
          <span class="text">{{ (month | monthName) + ' ' + year + ' - ' }}</span>
          <span class="text bold">{{ 'USD ' + (totalCurrentAmount | number: '1.0-0':'en-US' | formatNumber) }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<mat-table #table [dataSource]="dataSource" matSort multiTemplateDataRows class="table-budget mat-elevation-z8">
  <ng-container matColumnDef="agencyGroupName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.agencyGroup' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.name | dashWhenEmpty }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="branchOfficeName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.agency' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.name | dashWhenEmpty }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="consumeGroupBudget">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.consumeBudget' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <ng-container *ngIf="element.consumeGroupBudget">
        <mat-checkbox [checked]="element.consumeGroupBudget" [disabled]="true"></mat-checkbox>
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="assignedValue">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.assigned' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <ng-container *ngIf="!element.consumeGroupBudget">
        <app-editable (update)="updateField(element.id, ('assignedValue' + element.id))">
          <ng-template appViewMode>{{ element.assignedValue | number: '1.0-0':'en-US' | formatNumber | dashWhenEmpty }}
          </ng-template>
          <ng-template appEditMode>
            <mat-form-field>
              <input matInput [formControl]="getControl('assignedValue' + element.id)" appOnlyPositiveNumber
                pattern="([0-9])+">
              <mat-error *ngIf="getControl('assignedValue' + element.id).hasError('pattern')">
                {{ 'field.error.invalidAmount' | translate }}</mat-error>
            </mat-form-field>
          </ng-template>
        </app-editable>
      </ng-container>
      <ng-container *ngIf="element.consumeGroupBudget">
        {{ element.assignedValue | dashWhenEmpty }}
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="currentAmount">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.available' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <ng-container *ngIf="!element.consumeGroupBudget">
        {{ element.currentAmount | number: '1.0-0':'en-US' | formatNumber | dashWhenEmpty }}
      </ng-container>
      <ng-container *ngIf="element.consumeGroupBudget">
        {{ element.currentAmount | dashWhenEmpty }}
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="spentValue">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.spent' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <ng-container *ngIf="!element.consumeGroupBudget">
        {{ element.spentValue | number: '1.0-0':'en-US' | formatNumber | dashWhenEmpty }}
      </ng-container>
      <ng-container *ngIf="element.consumeGroupBudget">
        {{ element.spentValue | dashWhenEmpty }}
      </ng-container>
    </mat-cell>
  </ng-container>

  <!-- <ng-container matColumnDef="differenceValue">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Diferencia</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.differenceValue | number: '1.0-0':'en-US' | formatNumber | dashWhenEmpty }}</mat-cell>
  </ng-container> -->

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef>{{ 'button.actions' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <ng-container *ngIf="element.hasBranchOffice">
        <button class="action" (click)="goNext(element)">{{ 'button.view' | translate }}</button>
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="no-data-found">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell class="no-data-found-row" *matCellDef="let item">{{ 'notFound.noBudgetFound' | translate }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="loading-data-error">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell class="loading-data-error-row" *matCellDef="let item">
      <ng-container *ngIf="refreshingBudget">
        <span class="cdk-visually-hidden" #translatedTooltipMessage>{{ 'button.refresh' | translate }}</span>
        <button mat-icon-button (click)="refreshBudget()" class="refresh-spinner">
          <mat-icon [matTooltip]="translatedTooltipMessage.innerHTML" [matTooltipPosition]="'right'">refresh</mat-icon>
        </button>
        <br>
        <span>{{ 'error.loadingBudget' | translate }}</span>
      </ng-container>
      <div class="mt-3 mb-3" *ngIf="!refreshingBudget">
        <span class="loading-span">{{ 'loading' | translate }}</span>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns" class="budget-row"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" (mouseover)="highlightRow($event, row)"
    (mouseout)="highlightRow($event, row)"
    [ngClass]="{ 'highlight': selectedRowIndex === row.id, 'gray-row': row.consumeGroupBudget }">
  </mat-row>
  <mat-row *matRowDef="let row; columns: ['no-data-found']; when: noBudgetDataFound"></mat-row>
  <mat-row *matRowDef="let row; columns: ['loading-data-error']; when: loadingBudgetDataError"></mat-row>
</mat-table>

<ng-container *ngIf="!noDataFound && !errorLoadingData">
  <div class="table-footer text-right">
    <span class="text">{{ 'budgetToDistributed' | translate }}</span><span
      [ngClass]="{'text bold': newTotalCurrentAmountAux >= 0, 'amount-red': newTotalCurrentAmountAux < 0 }">{{ 'USD ' + (newTotalCurrentAmountAux | number: '1.0-0':'en-US' | formatNumber) }}</span>
  </div>

  <!-- <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 30, 50]" [showFirstLastButtons]="false" class="table-paginator"></mat-paginator> -->
</ng-container>
export class AgencyPage {
  public id: number;
  public name: string;
  public iata: string;
  public city: string;
  public agencyTierName: string;
  public executive: string;
  public status: string;
  public marketName: string;
  constructor(
    id?: number,
    name?: string,
    iata?: string,
    city?: string,
    agencyTierName?: string,
    executive?: string,
    status?: string,
     marketName?: string) {
    this.id = id;
    this.name = name;
    this.iata = iata;
    this.city = city;
    this.agencyTierName = agencyTierName;
    this.executive = executive;
    this.status = status;
    this.marketName = marketName;
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FareRegulationComponent } from './fare-regulation/fare-regulation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { ShareModule } from '../share-module/share.module';
import { TermsConditionsModule } from '../terms-conditions-module/terms-conditions.module';



@NgModule({
    declarations: [
        FareRegulationComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        CommonModule,
        CommonModule,
        FormsModule,
        MatCardModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatExpansionModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatChipsModule,
        MatTooltipModule,
        ReactiveFormsModule,
        ShareModule,
        TermsConditionsModule,
        MatFormFieldModule,
        NgxMaskDirective,
        NgxMaskPipe,
        MatProgressSpinnerModule,
        BrowserAnimationsModule
    ]
})
export class FareRegulationModule { }

import { NgModule } from '@angular/core';

import { ArrayToStringPipe } from './array-to-string.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { CurrencyAmountPipe } from './currency-amount.pipe';
import { DashWhenEmptyPipe } from './dash-when-empty.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { DateFormatWithoutLocalePipe } from './date-format-without-locale.pipe';
import { FormatNumberPipe } from './format-number.pipe';
import { MonthNamePipe } from './month-name.pipe';
import { PartialDisplayPipe } from './partial-display.pipe';
import { PendingWaiverCountPipe } from './pending-waiver-count.pipe';
import { TooltipBudgetTablePipe } from './tooltip-budget-table.pipe';
import { TranslateBudgetRowNamePipe } from './translate-budget-row-name.pipe';
import { TranslateBudgetTypeNamePipe } from './translate-budget-type-name.pipe';
import { FirstWordPipe } from './firstWord.pipe';


@NgModule({
  declarations: [
    ArrayToStringPipe,
    CapitalizePipe,
    FirstWordPipe,
    CurrencyAmountPipe,
    DashWhenEmptyPipe,
    DateFormatPipe,
    DateFormatWithoutLocalePipe,
    FormatNumberPipe,
    MonthNamePipe,
    PartialDisplayPipe,
    PendingWaiverCountPipe,
    TooltipBudgetTablePipe,
    TranslateBudgetRowNamePipe,
    TranslateBudgetTypeNamePipe
  ],
  exports: [
    ArrayToStringPipe,
    CapitalizePipe,
    FirstWordPipe,
    CurrencyAmountPipe,
    DashWhenEmptyPipe,
    DateFormatPipe,
    DateFormatWithoutLocalePipe,
    FormatNumberPipe,
    MonthNamePipe,
    PartialDisplayPipe,
    PendingWaiverCountPipe,
    TooltipBudgetTablePipe,
    TranslateBudgetRowNamePipe,
    TranslateBudgetTypeNamePipe
  ]
})
export class PipesModule { }

<ng-container *ngIf="infoUser$ | async as infoUser">
  <div class="container">
    <div class="row">
      <div class="col">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="latam-sans breadcrumb-item">
              <a>{{ 'menu.latamManagement' | translate }}</a>
            </li>
            <li class="breadcrumb-item latam-sans active" aria-current="page">
              <a>{{ 'menu.typeWaiverActivation' | translate }}</a>
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-8 col-xl-10">
        <span id="waiverManagementTitle" class="title">{{ 'menu.typeWaiverActivation' | translate }}</span>
      </div>
      <div class="col-2 col-xl-1 text-right">
        <span class="txt-pos">{{ 'field.country' | translate }}</span>
      </div>
      <div class="col-2 col-xl-1">
        <mat-select id="posSelect" name="pos" #pos="ngModel" [(ngModel)]="selectedPos"
          (selectionChange)="getMarketBySelectedPos(selectedPos)" class="select-pos">
          <mat-option *ngFor="let pos of infoUser.market" [value]="pos">
            {{ pos }}
          </mat-option>
        </mat-select>
      </div>
    </div>

    <hr>

    <div *ngIf="loading" class="loading">
      <div class="loading-div">
        <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
        <span class="loading-span">{{ 'loading' | translate }}</span>
      </div>
    </div>

    <div *ngIf="!loading && isMarketSelected" class="mt-4 mb-4 table-waiver-type">
      <mat-table #table [dataSource]="dataSource">
        <ng-container matColumnDef="waiver">
          <mat-header-cell *matHeaderCellDef>{{ 'field.waiver' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element" class="pos-item">{{ element.waivertype.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>{{ 'field.status' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-slide-toggle disableRipple [(ngModel)]="element.status"></mat-slide-toggle>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" class="waiver-type-row"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"
          [ngClass]="{ 'highlight': selectedPosRowIndex === i }" (click)="toggleSelectionRow($event, row)"
          (mouseout)="highlightRow($event, i)" (mouseover)="highlightRow($event, i)" class="waiver-type-row"></mat-row>
      </mat-table>
    </div>

    <ng-container *ngIf="!loading && isMarketSelected && profiles.readWaiverAutomation">
      <div class="row mb-3 justify-content-end">
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
          <button mat-raised-button (click)="save()"
            class="background-red w-100">{{ 'button.save' | translate }}</button>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
import { UserLatam } from './user-latam.model';

export class UserLatamTableElement extends UserLatam {
  public roleName: string;
  public userLatamPositionName: string;

  public constructor(userLatam: UserLatam) {
    super();
    this.id = userLatam.id;
    this.latamUserId = userLatam.latamUserId;
    this.name = userLatam.name;
    this.lastname = userLatam.lastname;
    this.email = userLatam.email;
    this.role = userLatam.role;
    this.markets = userLatam.markets;
    this.userLatamPosition = userLatam.userLatamPosition;
    this.language = userLatam.language;
    this.roleName = userLatam.role.name;
    this.userLatamPositionName = userLatam.userLatamPosition.name;
  }
}

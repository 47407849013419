export class ControlBda{
   bdaLogId: number;
   userEmail: string;
   executionDate: string;
   bdaIata: string;
   filename: string;
   fileFormat: string;
   totalRows: number;
   correctRows: number;
   bdaScore: number;
   agencyGroup: string;
}

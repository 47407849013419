
import { Observable, from as observableFrom } from 'rxjs';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, mergeMap } from 'rxjs/operators';



import { environment } from '../../environments/environment';

import { Carrier } from '../models/carrier.model';
import { InfoAdvp } from '../models/info-advp.model';
import { PricingType } from '../models/pricing-type.model';
import { WaiverDocumentFare } from '../models/waiver-document-fare.model';
import { WaiverPresaleType } from '../models/waiver-presale-type.model';
import { WaiverResult } from '../models/waiver-result.model';

import { SessionInfo } from '../models/SessionInfo.model';
import { PaginatorWaiverPresale } from '../models/paginator-waiver-presale';
import { WaiverAdvp } from '../models/waiver-advp';
import { WaiverFarediffStatus } from '../models/waiver-farediff-status.model';
import { RouteLocaleExtractorService } from './route-locale-extractor.service';
import { SessionInfoService } from './session-info.service';
import { StorageService } from './storage.service';


@Injectable()
export class WaiverPresaleService {
  headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  // urlBase = 'http://localhost:9090/http://localhost:8060/waiver';
  urlBase = `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlWaiver}`;

  constructor(
    private http: HttpClient,
    private sessionInfoService: SessionInfoService,
    private routeLocaleExtractorService: RouteLocaleExtractorService) { }

  getCarriers(): Observable<Carrier[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<Carrier[]>(`${this.urlBase}${locale}/waivers-presales/carrier`);
  }

  getPricingTypes(): Observable<{ pricingTypes: PricingType[] }> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<{ pricingTypes: PricingType[] }>(`${this.urlBase}${locale}/waivers/pre-sale/pricing-types`);
  }

  getWaiver(waiverId: number): Observable<WaiverDocumentFare> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<WaiverDocumentFare>(`${this.urlBase}${locale}/waivers/${waiverId}/presale`);
  }

  getWaiverFarediffStatus(): Observable<WaiverFarediffStatus[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<WaiverFarediffStatus[]>(`${this.urlBase}${locale}/farediff/status`);
  }

  getWaiverTypes(): Observable<{ waiverTypes: WaiverPresaleType[], allowPrivateFare: boolean, isLegalAllowed: boolean }> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<{ waiverTypes: WaiverPresaleType[], allowPrivateFare: boolean, isLegalAllowed: boolean }>(`${this.urlBase}${locale}/waivers/category/pre-sale/types`);
  }

  getWaiversPresale(statusId: number, statusName: string, pageSize: number, numPage: number, params: HttpParams): Observable<PaginatorWaiverPresale> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return observableFrom(this.sessionInfoService.getSessionInfoAsPromise()).pipe(mergeMap(
      (session: SessionInfo) => {
        const branchOfficeId = session.branch_office_id;
        const marketId = session.marketId;
        const matrixId = session.matrix_id;
        const role = session.role;
        const userId = session.userId;

        let url = '';

        if (role === 'AGENT_MTZ') {
          url = `${this.urlBase}${locale}/waivers/user/status/${statusId}?userId=${userId}&sizePage=${pageSize}&numPage=${numPage}`;
        } else if (role === 'MANUAL_MTZ' && matrixId && statusId == 9) {// Status pendiente pre venta
          url = `${this.urlBase}${locale}/waivers/manual/status/${statusId}?matrixId=${matrixId}&sizePage=${pageSize}&numPage=${numPage}`;
        } else if ((role === 'ADMIN_MTZ' || role === 'MANUAL_MTZ') && matrixId) {
          url = `${this.urlBase}${locale}/waivers/status/${statusId}?matrixId=${matrixId}&sizePage=${pageSize}&numPage=${numPage}`;
        } else if (role === 'ADMIN_SUC' && branchOfficeId) {
          url = `${this.urlBase}${locale}/waivers/status/${statusId}?branchOfficeId=${branchOfficeId}&sizePage=${pageSize}&numPage=${numPage}`;
        } else if (role === 'AGENT_SUC' && branchOfficeId) {
          url = `${this.urlBase}${locale}/waivers/branchoffice/user/status/${statusId}?branchOfficeId=${branchOfficeId}&userId=${userId}&sizePage=${pageSize}&numPage=${numPage}`;
        } else if (role === 'ADMIN_SALES') {
          url = `${this.urlBase}${locale}/waivers-presales/user/sales/status/${statusId}?adminSalesId=${userId}&sizePage=${pageSize}&numPage=${numPage}`;
        } else if (role === 'USER_SALES') {
          url = `${this.urlBase}${locale}/waivers-presales/user/sales/status/${statusId}?userSalesId=${userId}&sizePage=${pageSize}&numPage=${numPage}`;
        } else {
          url = `${this.urlBase}${locale}/waivers-presales/market/${statusName}?markets=${marketId}&sizePage=${pageSize}&numPage=${numPage}`;
        }

        return this.http.get<PaginatorWaiverPresale>(url, {params});
      }
    ));
  }

  validatePnr(pnr: string, waiverTypeId: number): Observable<InfoAdvp> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<InfoAdvp>(`${this.urlBase}${locale}/waiver-presales/pnr/${pnr}?waiverType=${waiverTypeId}`);
  }

  validatePnrAdvp(pnr: string): Observable<WaiverAdvp> {
    const locale = this.routeLocaleExtractorService.getLocale();
    return this.http.get<WaiverAdvp>(`${this.urlBase}${locale}/waiver/presales/advp/pnr/${pnr}`);
  }

  validatePnrOneDeal(pnr: string,pos: string, waiverTypeId: number): Observable<InfoAdvp> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<InfoAdvp>(`${this.urlBase}${locale}/waiver-one-deal/pnr/${pnr}?waiverType=${waiverTypeId}&pos=${pos}`);
  }

  saveWaiverFarediffStatus(request: any): Observable<WaiverFarediffStatus[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.put<WaiverFarediffStatus[]>(`${this.urlBase}${locale}/farediff/status`, request, { headers: this.headers });
  }

  saveWaiverPresale(request: InfoAdvp): Observable<WaiverResult> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return observableFrom(this.sessionInfoService.getSessionInfoAsPromise()).pipe(mergeMap(
      (session: SessionInfo) => {
        const isSelfManaged = session.isSelfManaged;
        if (isSelfManaged && request.waiverType.code != 'DPE') {
          return this.http.post<WaiverResult>(`${this.urlBase}${locale}/manual/waivers-presales`, request, { headers: this.headers });
        }

        return this.http.post<WaiverResult>(`${this.urlBase}${locale}/waivers-presales`, request, { headers: this.headers });
      }
    ));

  }

  valorizationFareBasis(request: InfoAdvp): Observable<InfoAdvp> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.post<InfoAdvp>(`${this.urlBase}${locale}/waivers-presales/valorization`, request, { headers: this.headers });
  }

  valorizationNewFareBasis(request: InfoAdvp, farebasis: string){
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.post(`${this.urlBase}${locale}/waivers-presales/valorization/${farebasis}`, request, { headers: this.headers });
  }

  saveDocumentFare(id:number,valor1: number, valor2: number, currency: string){

    return this.http.post(`${this.urlBase}/presales/document/fare/${id}/${valor1}/${valor2}/${currency}`, { headers: this.headers });
  }
  valorizationCustom(request: InfoAdvp): Observable<InfoAdvp> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.post<InfoAdvp>(`${this.urlBase}${locale}/waivers-presales/valorization/custom`, request, {headers: this.headers});
  }

   putApproveWaiver(id: number, comment: string) {
    const locale = this.routeLocaleExtractorService.getLocale();
    return observableFrom(this.sessionInfoService.getSessionInfoAsPromise()).pipe(mergeMap(
      (session: SessionInfo) => {
        const role = session.role;
        if (role === 'MANUAL_MTZ'){
          return this.http.put(
            `${this.urlBase}${locale}/waivers-presales/manual/${id}/approve`,
            { additionalComment: comment },
            { headers: this.headers }
          ).pipe(
            map(
              data => {
                return data;
              }
            )
          )
        }
        return this.http.put(
          `${this.urlBase}${locale}/waivers-presales/${id}/approve`,
          { additionalComment: comment },
          { headers: this.headers }
        ).pipe(
          map(
            data => {
              return data;
            }
          )
        )

      }
    ));


  }

  putRejectWaiver(id: number, motiveId: number, comment: string) {
    const locale = this.routeLocaleExtractorService.getLocale();
    return observableFrom(this.sessionInfoService.getSessionInfoAsPromise()).pipe(mergeMap(
      (session: SessionInfo) => {
        const role = session.role;
        if (role === 'MANUAL_MTZ'){
          return this.http.put(
            `${this.urlBase}${locale}/waivers-presales/${id}/manual/reject`,
            { rejectionMotiveId: motiveId, rejectionComment: comment },
            { headers: this.headers }
          )
          .pipe(
            map(
              data => {
                return data;
              }
            )
          )
        }
        return this.http.put(
          `${this.urlBase}${locale}/waivers-presales/${id}/reject`,
          { rejectionMotiveId: motiveId, rejectionComment: comment },
          { headers: this.headers }
        )
        .pipe(
          map(
            data => {
              return data;
            }
          )
        )

      }
    ))
  }
}

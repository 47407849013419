<div class="mt-4 mb-3">
  <div class="row">
    <div class="col">
      <span class="subtitle">{{ 'bookingInformation' | translate }}</span>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-table [dataSource]="dataSource" class="table-booking-info mat-elevation-z1">
        <ng-container matColumnDef="pnrNumber">
          <mat-header-cell *matHeaderCellDef>{{ 'field.pnr' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.pnrNumber | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef>{{ 'field.pnrType' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.type | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="createDateTime">
          <mat-header-cell *matHeaderCellDef>{{ 'field.bookingCreation' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.createDateTime | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="numberPassengers">
          <mat-header-cell *matHeaderCellDef>{{ 'field.numberPassengers' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.numberPassengers | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="pos">
          <mat-header-cell *matHeaderCellDef>{{ 'field.pos' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.pos | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="iata">
          <mat-header-cell *matHeaderCellDef>{{ 'field.iata' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.iata | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ppc">
          <mat-header-cell *matHeaderCellDef>{{ 'field.pcc' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.ppc | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="accountCode">
          <mat-header-cell *matHeaderCellDef>{{ 'field.accountCode' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.accountCode | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="officeId">
          <mat-header-cell *matHeaderCellDef>{{ 'field.officeId' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.officeId | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" class="booking-info-row"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="booking-info-row"></mat-row>
      </mat-table>
    </div>
  </div>
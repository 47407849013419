import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { Language } from '../models/language.model';
import { LatamRole } from '../models/latam-role.model';
import { UserLatam } from '../models/user-latam.model';
import { UserLatamCsvElement } from '../models/UserLatamCsvElement.model';
import { UserLatamPosition } from '../models/user-latam-position.model';

import { RouteLocaleExtractorService } from './route-locale-extractor.service';
import { UserLatamConfigurations } from '../models/user-latam-configurations.model';
import { StorageService } from './storage.service';


@Injectable()
export class LatamService {
  headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  urlBase = `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlUserAdmin}`;

  constructor(
    private http: HttpClient,
    private routeLocaleExtractorService: RouteLocaleExtractorService) { }

  createUserLatam(userLatam: UserLatam): Observable<UserLatam> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.post<UserLatam>(
      `${this.urlBase}${locale}/latam/users`,
      userLatam,
      { headers: this.headers }
    );
  }

  getActiveUsers(): Observable<UserLatam[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    let usersLatam: UserLatam[] = [];

    return this.http.get(`${this.urlBase}${locale}/latam/users`)
      .pipe(
        map(
          (data: UserLatam[]) => {
            data.map(
              (value: UserLatam) => {
                if (value.name !== 'LATAM TRADE') {
                  usersLatam.push(
                    new UserLatam(
                      value.id,
                      value.latamUserId,
                      value.name,
                      value.lastname,
                      value.email,
                      value.role,
                      value.userLatamPosition,
                      value.markets,
                      value.language
                    )
                  );
                }
              }
            );

            return usersLatam;
          }
        )
      );
  }

  getActiveUsersCSV(): Observable<UserLatamCsvElement[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    let usersLatam: UserLatamCsvElement[] = [];

    return this.http.get(`${this.urlBase}${locale}/latam/users`)
      .pipe(
        map((data: UserLatamCsvElement[]) => {
          data.map(
            (value: any) => {
              if (value.name !== 'LATAM TRADE') {
                usersLatam.push(
                  new UserLatamCsvElement(
                    value.markets,
                    value.latamUserId,
                    value.name,
                    value.lastname,
                    value.email,
                    value.role.name,
                    value.userLatamPosition.name,
                    value.id,
                    value.creationDate,
                    value.active
                  )
                );
              }
            }
          );

          return usersLatam;
        }
      )
      )
  }

  getLanguages(): Observable<Language[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    let languages: Language[] = [];

    return this.http.get(`${this.urlBase}${locale}/latam/users/create/languages`)
    .pipe(
      map(
        (data: Language[]) => {
          data.map((value: Language) => {
            languages.push(new Language(value.id, value.name));
          });
          return languages;
        }
      )
    );
  }

  getRolesLatam(): Observable<LatamRole[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    let latamRoles: LatamRole[] = [];

    return this.http.get(`${this.urlBase}${locale}/latam/roles`)
    .pipe(
      map(
        (data: LatamRole[]) => {
          data.map((value: LatamRole) => {
            latamRoles.push(new LatamRole(value.id, value.name));
          });
          return latamRoles;
        }
      )
    );
  }

  getUserLatamById(id: number): Observable<UserLatam> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<UserLatam>(`${this.urlBase}${locale}/latam/users/${id}`);
  }

  getUserLatamConfigurations(): Observable<UserLatamConfigurations> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<UserLatamConfigurations>(
      `${this.urlBase}${locale}/latam/configurations/`, { headers: this.headers });
  }

  getUserLatamPositions(): Observable<UserLatamPosition[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    let userLatamPositions: UserLatamPosition[] = [];

    return this.http.get(`${this.urlBase}${locale}/latam/positions`)
    .pipe(
      map(
        (data: UserLatamPosition[]) => {
          data.map((value: UserLatamPosition) => {
            userLatamPositions.push(new UserLatamPosition(value.id, value.name));
          });

          return userLatamPositions;
        }
      )
    );
  }

  updateUserLatam(id: number, userLatam: UserLatam): Observable<UserLatam> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.put<UserLatam>(
      `${this.urlBase}${locale}/latam/users/${id}`,
      userLatam,
      { headers: this.headers }
    );
  }

  updateUserLatamNotifications(configurations: UserLatamConfigurations): Observable<UserLatamConfigurations> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.post<UserLatamConfigurations>(
      `${this.urlBase}${locale}/latam/configurations/notifications/`, configurations, { headers: this.headers }
    );
  }

  updateUserLatamNotificationsByMarket(market: String, status: boolean): Observable<null> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<null>(
      `${this.urlBase}${locale}/latam/configurations/notifications/update?market=${market}&status=${status}`, { headers: this.headers }
    );
  }

  /**
   * Elimina usuario LATAM
   * @param `number` id
   */
  deleteUserLatam(id: number) {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.delete(`${this.urlBase}${locale}/latam/users/${id}`, { headers: this.headers });
  }
}

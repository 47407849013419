import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation, LOCALE_ID } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';

import { FeedbackCSV } from '../../../models/feedbackCSV.model';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';

import { FeedbackService } from '../../../services/feedback.service';
import { MessageService } from './../../../services/message.service';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MessageErrorService } from '../../../services/message-error.service';


@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FeedbackComponent implements OnInit {
  loading: boolean = false;
  locale: string;
  reportFilename: string;

  currentDate = new Date();
  endDate: Moment;
  feedbacks: FeedbackCSV[] = [];
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_LATAM, SidebarMenuModel.SUBMENU_LATAM_FEEDBACK);
  startDate: Moment;

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private adapter: DateAdapter<Moment>,
    private feedbackService: FeedbackService,
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private sidebarShareDataService: SidebarShareDataService,
    private translateService: TranslateService) {
      this.locale = locale;
      this.adapter.setLocale(locale);
    }

  ngOnInit() {
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
  }

  startDateChanged(startDate: MatDatepickerInputEvent<Moment>) {
    if (this.endDate) {
      if (startDate.value > this.endDate) {
        this.endDate = null;
        return;
      }

      this.getFeedbacksBetweenDates(startDate.value, this.endDate);
    }
  }

  endDateChanged(endDate: MatDatepickerInputEvent<Moment>) {
    if (this.startDate) {
      this.getFeedbacksBetweenDates(this.startDate, endDate.value);
    }
  }

  getFeedbacksBetweenDates(startDate: Moment, endDate: Moment) {
    this.loading = true;

    this.feedbackService.getFeedbacksBetweenDates(startDate.toDate(), endDate.toDate()).subscribe({
      next: data => {
        this.feedbacks = data;

        if (this.feedbacks.length === 0) {
          this.messageService.showWarningMessage(
            this.translateService.instant('button.confirm'),
            this.translateService.instant('warning.feedbackNotFound')
          );
        } else {
          this.reportFilename = 'feedbacks_report_' +
            this.feedbackService.parseDateToDateString(startDate.toDate()) + '_' +
            this.feedbackService.parseDateToDateString(endDate.toDate()) + '.csv';
        }

        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.generateReport');

        this.loading = false;
        console.log('error', error);
      }
  });
  }

  setCalendarLanguage() {
    this.adapter.setLocale(this.locale);
  }
}

import { AgencyGroup } from './agency-group.model';
import { BranchOffice } from './branch-office.model';
import { PiaCode } from './piaCode.model';
import { Role } from './role.model';

export class User {
    id: number;
    name: string;
    lastname: string;
    birthdate: string;
    email: string;
    roles: Role[];
    position: string;
    association: string;
    agencyGroup: AgencyGroup;
    agencyBranchOffice: BranchOffice;
    agency: PiaCode;

    constructor(
        name?: string,
        lastname?: string,
        birthdate?: string,
        email?: string,
        roles?: Role[],
        position?: string,
        association?: string,
        agencyGroup?: AgencyGroup,
        agencyBranchOffice?: BranchOffice,
        agency?: PiaCode) {
            this.name = name;
            this.lastname = lastname;
            this.birthdate = birthdate;
            this.email = email;
            this.roles = roles;
            this.position = position;
            this.association = association;
            this.agencyGroup = agencyGroup;
            this.agencyBranchOffice = agencyBranchOffice;
            this.agency = agency;
    }
}

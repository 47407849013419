import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dashWhenEmpty' })
export class DashWhenEmptyPipe implements PipeTransform {
  transform(value: string): string {
    let dash = '—';

    if (!value || value === '') {
      return dash;
    }

    let type = typeof value;

    if (type === 'string' && !value.trim()) {
      return dash;
    }

    return value;
  }
}

import { animate, state, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { merge ,  Observable ,  of } from 'rxjs';
import { map } from 'rxjs/operators';

import { BranchOfficeData } from '../../../interfaces/branch-office-data.interface';

import { BudgetType } from '../../../models/budget-type.model';
import { Profile } from '../../../models/profile.model';
import { SessionInfo } from '../../../models/SessionInfo.model';

import { AgencyBranchOfficeService } from './../../../services/agency-branch-office.service';
import { MessageService } from '../../../services/message.service';
import { ProfilingService } from '../../../services/profiling.service';
import { SessionInfoService } from '../../../services/session-info.service';
import { MessageErrorService } from '../../../services/message-error.service';


export class TableDataSource extends DataSource<BranchOfficeData> {
  private data: BranchOfficeData[];

  constructor(
    private paginator: MatPaginator,
    private _data: any[]) {
    super();
    const rows = [];
    _data.forEach(element => rows.push(element, { detailRow: true, element }));
    this.data = rows;
  }

  connect(): Observable<BranchOfficeData[]> {
    const dataMutations = [
      of(this.data),
      this.paginator.page
    ] as any;

    return merge(...dataMutations).pipe(
      map(() => {
        return this.data;
      })
    );
  }

  disconnect() {
    console.log("Disconnect");
   }
}


@Component({
  selector: 'app-branch-office-matrix',
  templateUrl: './branch-office-matrix.component.html',
  styleUrls: ['./branch-office-matrix.component.css'],
  animations: [
    trigger(
      'detailExpand',
      [
        state('collapsed', style({ height: '0px', minHeight: '0px', visibility: 'hidden' })),
        state('expanded', style({ height: '*', visibility: 'visible' })),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
      ]
    )
  ]
})
export class BranchOfficeMatrixComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private agencyBranchOfficeService: AgencyBranchOfficeService,
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private profilingService: ProfilingService,
    private sessionInfoService: SessionInfoService,
    private translateService: TranslateService) { }
  @ViewChild('paginator') paginator: MatPaginator;
  @Input() matrixId: number;
  @Input() budgetTypes: BudgetType[];

  budgetTypeSelected: BudgetType = new BudgetType(2, 'Presale');
  actualDate: string;
  loading = false;
  branchOfficeDataFound = false;
  refreshingBranchOffices = true;

  private maxLength = 10;

  displayedColumns = ['name'];
  dataSource: TableDataSource;
  infoUser$: Promise<SessionInfo> = this.sessionInfoService.getSessionInfoAsPromise();
  private profiles = new Profile();
  year: string;

  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  loadingUsersDataError = (index: number, item: { errorLoadingData: boolean }) => item.errorLoadingData;
  noBranchOfficeDataFound = (index: number, item: { noData: boolean }) => item.noData;

  ngOnInit() {
    this.profiles = this.profilingService.getProfile();
    this.setMonthAndYear();
    this.setDisplayedColumns();
    this.loadBranchOfficeByMatrixId(this.matrixId, 0, this.maxLength, this.budgetTypeSelected);
  }

  private loadBranchOfficeByMatrixId(matrixId: number, pageIndex: number, pageSize: number, budgetType: BudgetType) {
    this.loading = true;
    this.branchOfficeDataFound = false;
    let budgetTypeName = '';
    if (budgetType.id === 1) {
      budgetTypeName = 'POSTSALE';
    } else if (budgetType.id === 2) {
      budgetTypeName = 'PRESALE';
    } else {
      return;
    }

    this.agencyBranchOfficeService.getBranchOfficeListByGroudId(matrixId, pageIndex, pageSize, 'name', budgetTypeName).subscribe({
      next: data => {
        if (data.branchOfficeData.length === 0) {
          setTimeout(() => {
            this.paginator.length = data.pagination.totalElements;
            this.paginator.pageIndex = data.pagination.pageIndex;
            this.paginator.pageSize = data.pagination.pageSize;
            this.dataSource = new TableDataSource(this.paginator, [{ noData: true }]);
          });
        } else {
          this.branchOfficeDataFound = true;

          setTimeout(() => {
            this.paginator.length = data.pagination.totalElements;
            this.paginator.pageIndex = data.pagination.pageIndex;
            this.paginator.pageSize = data.pagination.pageSize;
            this.dataSource = new TableDataSource(this.paginator, data.branchOfficeData);
          });
        }

        this.loading = false;
        this.refreshingBranchOffices = false;
      },
      error: (error: HttpErrorResponse) => {
        this.refreshingBranchOffices = false;
        setTimeout(() => {
          this.paginator.length = 0;
          this.paginator.pageIndex = 0;
          this.paginator.pageSize = 10;
          this.dataSource = new TableDataSource(this.paginator, [{ errorLoadingData: true }]);
        });

        this.messageErrorService.sendError(error, 'error.loadingAgenciesGroup');

        this.loading = false;
        console.log('error', error);
      }
  });
  }

  refreshBranchOffices(): void {
    this.refreshingBranchOffices = true;
    this.loadBranchOfficeByMatrixId(this.matrixId, 0, this.maxLength, this.budgetTypeSelected);
  }

  pageEvent(event: PageEvent): void {
    this.loadBranchOfficeByMatrixId(this.matrixId, event.pageIndex, event.pageSize, this.budgetTypeSelected);
  }

  /**
   * Function to open the component with the profile of the agency 'ProfileAgencyComponent'
   */
  profileBranchOffice(branchOfficeId: number): void {
    if (this.profiles.readAgencyProfile) {
      this.router.navigate(
        ['/agency/branch-office/profile'],
        { relativeTo: this.activatedRoute, queryParams: { agencyGroupId: this.matrixId, branchOfficeId: branchOfficeId } }
      );
    } else if (this.profiles.readAgencyProfileLT) {
      this.router.navigate(
        ['/latam/branch-office/profile'],
        { relativeTo: this.activatedRoute, queryParams: { agencyGroupId: this.matrixId, branchOfficeId: branchOfficeId } }
      );
    }
  }

  /**
   * Function to open the component with the profile of the agency 'ProfileAgencyComponent'
   */
  profileAgency(agencyId: number): void {
    this.infoUser$
      .then(
        sessionInfo => {
          if (sessionInfo.isLatamSession()) {
            this.router.navigate(
              ['/latam/agencies/profile'],
              { relativeTo: this.activatedRoute, queryParams: { agencyId: agencyId, groupId: this.matrixId } }
            );
          } else {
            this.router.navigate(
              ['/agency/profile'],
              { relativeTo: this.activatedRoute, queryParams: { agencyId: agencyId } }
            );
          }
        }
      )
      .catch(
        error => {
          console.log('error', error);
        }
      );
  }

  public selectionBudgetTypeChange(event: MatOptionSelectionChange): void {
    this.budgetTypeSelected = <BudgetType>event.source.value;
    if (this.budgetTypeSelected) {
      this.loadBranchOfficeByMatrixId(this.matrixId, 0, this.maxLength, this.budgetTypeSelected);
    }
  }

  setDisplayedColumns(): void {
    this.infoUser$
      .then(
        sessionInfo => {
          if (sessionInfo.isLatamSession()) {
            this.displayedColumns = ['name', 'assignedValue', 'availableValue', 'spentValue', 'view'];
          } else {
            this.displayedColumns = ['name'];
          }
        }
      )
      .catch(
        error => {
          console.log('error', error);
        }
      );
  }

  compareBudgetType(budgetType1: BudgetType, budgetType2: BudgetType) {
    return budgetType1 && budgetType2 ? budgetType1.id === budgetType2.id : budgetType1 === budgetType2;
  }

  setMonthAndYear() {
    const today = new Date();
    const monthToday = today.getMonth();
    const yearToday = today.getFullYear();
    this.actualDate = `${ monthToday }`;
    this.year = `${ yearToday }`;
  }
}

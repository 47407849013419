<div class="mb-3">
  <div *ngIf="loading" class="loader loading">
    <div class="loading-div">
      <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
      <span class="loading-span">{{ 'loading' | translate }}</span>
    </div>
  </div>

  <ng-container *ngIf="!loading">
    <mat-table #table [dataSource]="dataSource" matSort class="table-agency">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.agency' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.name | capitalize | dashWhenEmpty }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="iata">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.iata' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.iata | dashWhenEmpty }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="tier">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.tier' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.agencyTierName | capitalize | dashWhenEmpty }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="city">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.city' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.city | capitalize | dashWhenEmpty }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="executive">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.accountManager' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.executive | capitalize | dashWhenEmpty }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>{{ 'button.actions' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button class="action" id="verButton">
            <span (click)="profileAgency(element.id)">{{ 'button.view' | translate }}</span>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="no-data-found">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell class="no-data-found-row" *matCellDef="let item">{{ 'notFound.partnerAgencies' | translate }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="loading-data-error">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell class="loading-data-error-row" *matCellDef="let item">
            <span class="cdk-visually-hidden" #translatedTooltipMessage>{{ 'button.refresh' | translate }}</span>
            <button mat-icon-button (click)="reloadAgencies()" class="refresh-spinner">
              <mat-icon [matTooltip]="translatedTooltipMessage.innerHTML" [matTooltipPosition]="'right'">refresh
              </mat-icon>
            </button>
            <br>
            <span>{{ 'error.loadingPartnerAgencies' | translate }}</span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns" class="agency-row"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns; let i = index" [ngClass]="{ 'highlight': selectedRowIndex === i }"
        (mouseover)="highlightRow($event, i)" (mouseout)="highlightRow($event, i)" class="agency-row"></mat-row>
      <mat-row *matRowDef="let row; columns: ['no-data-found']; when: noAgenciesDataFound"></mat-row>
      <mat-row *matRowDef="let row; columns: ['loading-data-error']; when: loadingAgenciesDataError"></mat-row>
    </mat-table>

    <ng-container *ngIf="agenciesDataFound">
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 30, 50]" [showFirstLastButtons]="false"
        (page)="pageEvent($event)" class="table-paginator">
      </mat-paginator>
    </ng-container>
  </ng-container>
</div>

import {from as observableFrom,  Observable, ObservableInput } from 'rxjs';

import {mergeMap, map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';



import { environment } from '../../environments/environment';

import { DownloadAgencyReport } from '../models/download-agency-report.model';
import { DownloadAgencyReportCSV } from '../models/download-agency-report-CSV.model';

import { RouteLocaleExtractorService } from './route-locale-extractor.service';
import { SessionInfoService } from './session-info.service';
import { SessionInfo } from '../models/SessionInfo.model';
import { StorageService } from './storage.service';

@Injectable()
export class AgencyReportService {

  constructor(
    private http: HttpClient,
    private routeLocaleExtractorService: RouteLocaleExtractorService,
    private sessionInfoService: SessionInfoService) { }

  getWaiversBetweenDates(startDate: Date, endDate: Date): Observable<DownloadAgencyReportCSV[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    let waivers: DownloadAgencyReportCSV[] = [];

    return observableFrom(this.sessionInfoService.getSessionInfoAsPromise()).pipe<DownloadAgencyReport[]>(mergeMap<SessionInfo, ObservableInput<DownloadAgencyReport[]>>(
      session => {
        let matrixId = session.matrix_id;

        return this.http.get(
          `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlWaiver}${locale}/waivers/matrix/${matrixId}/report?startDate=${this.parseDateToUTCDateString(startDate)}&endDate=${this.parseEndDateToUTCDateString(endDate)}`
        ).
        pipe(
          map(
            (data: DownloadAgencyReport[]) => {
              data.map(
                (value: DownloadAgencyReport) => {
                  waivers.push(
                    new DownloadAgencyReportCSV(
                      value.id,
                      value.status,
                      value.creationDate,
                      value.statusDate,
                      value.documentNumber,
                      value.user,
                      value.departureDate,
                      value.type,
                      value.motive,
                      value.aditionalComment,
                      value.statusComment,
                      value.pnrAgency,
                      value.rejectionMotive,
                      value.folio,
                      value.valorization
                    )
                  );
                }
              );

              return waivers;
            }
          )
        )
      }
    ));
  }

  parseDateToUTCDateString(date: Date): string {
    return date.toUTCString();
  }

  parseEndDateToUTCDateString(date: Date): string {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date.toUTCString();
  }

  parseDateToDateString(date: Date): string {
    return date.toLocaleString('es-CL').split(' ')[0];
  }
}

<div class="container">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="latam-sans breadcrumb-item">
            <a>{{ 'statusTicket.name' | translate }}</a>
          </li>
          <li class="breadcrumb-item latam-sans active" aria-current="page">
            <a>{{ 'statusTicket.search' | translate }}</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <span class="title">{{ 'statusTicket.optionDescription' | translate }}</span>
    </div>
  </div>

  <hr>



  <div style="margin-top: 50px;">
    <div>
      <mat-radio-group (change)="radioChange($event)">
        <mat-radio-button id="radio1" class="mr-5" value="1" checked>{{'statusTicket.searchOption1.name' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <ng-container *ngIf="radioButton == 1">
    <form [formGroup]="searchTicketForm1" (ngSubmit)="filterTickets()" class="animate__animated animate__fadeIn">
      <ng-container id="containerForm1">
        <div class="row" style="align-items: baseline">

          <div class="mt-2 col-md-4 col-lg-3 col-sm-12">
            <mat-form-field class="example-full-width">
              <mat-label>{{'statusTicket.searchOption1.input1' | translate}}</mat-label>
              <input type="text"  matInput formControlName="ticketNumber" name="ticketNumber" />
              <mat-hint>0XXXXXXXXX</mat-hint>
              <mat-error *ngIf="searchTicketForm1.get('ticketNumber').hasError('pattern')">
                {{ 'statusTicket.Error.TicketNumber' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="mt-2 col-md-4 col-lg-3 col-sm-12">
            <mat-form-field>
              <mat-label>{{ 'statusTicket.inputFecha' | translate }}</mat-label>
              <input [min]="minDate" [max]="maxDate" matInput formControlName="ticketDate" name="ticketDate" [matDatepicker]="picker">
              <mat-hint>{{ 'statusTicket.infoFecha' | translate }} ({{ minDate | date:'dd-MM-yyyy' }})</mat-hint>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>



          <div class="mt-2 col-md-4 col-sm-12 my-4 col-lg-3 mr-1vw">
            <button class="background-blue w-100" id="btnSearchTicket1" mat-raised-button type="submit"
              [disabled]="!searchTicketForm1.valid">
              <span *ngIf="!isLoadingButton">{{'statusTicket.search' | translate}}</span>
              <span *ngIf="isLoadingButton">{{'statusTicket.search' | translate}}...</span>
              <div class="spinner-border spinner-border-sm ml-2" *ngIf="isLoadingButton" role="status"></div>
            </button>
          </div>

        </div>
      </ng-container>
    </form>
  </ng-container>

  <ng-container *ngIf="radioButton == 2">
    <form [formGroup]="searchTicketForm2" (ngSubmit)="filterTickets()" class="animate__animated animate__fadeIn">
      <ng-container id="containerForm2">

        <div class="row" style="align-items: baseline">
          <div class="col-2">
            <span class="btn btn-file background-blue w-100">
              {{'statusTicket.searchOption2.input1' | translate}}<input formControlName="file" type="file">
            </span>
          </div>
          <div class="col-10">
            <div class="card" style="width: 50%;">
              <div *ngIf="checkFileName()" class="card-header">
                {{fileName}}
              </div>
              <div *ngIf="!checkFileName()" class="card-header">
                {{'statusTicket.searchOption2.message' | translate}}
              </div>
            </div>
          </div>
        </div>

        <div class="row" style="align-items: baseline">
          <div class="col-4"></div>
          <div class="col-8">
            <div class="row btnsContainer">
              <div class="mt-2 col-md-4 col-sm-12 my-4 col-lg-3 mr-1vw">
                <button class="background-blue w-100" id="btnSearchTicket2" mat-raised-button type="submit"
                  [disabled]="!searchTicketForm2.valid">
                  <span *ngIf="!isLoadingButton">{{'statusTicket.search' | translate}}</span>
                  <span *ngIf="isLoadingButton">{{'statusTicket.search' | translate}}...</span>
                  <div class="spinner-border spinner-border-sm ml-2" *ngIf="isLoadingButton" role="status">
                  </div>
                </button>

              </div>
            </div>
          </div>
        </div>

      </ng-container>
    </form>
  </ng-container>

  <ng-container *ngIf="radioButton == 3">
    <form [formGroup]="searchTicketForm3" (ngSubmit)="filterTickets()" class="animate__animated animate__fadeIn">
      <ng-container id="containerForm3">

        <div class="row" style="align-items: baseline">
          <div class="mt-2 col-md-4 col-lg-3 col-sm-12">
            <mat-form-field class="matFormWrapper">
              <mat-label>{{'statusTicket.searchOption3.input1' | translate}}</mat-label>
              <input matInput [matDatepicker]="datepicker1" formControlName="from" class="activeInput"
                (dateChange)="dateFormat('from',$event)" />
              <mat-datepicker-toggle matSuffix [for]="datepicker1"></mat-datepicker-toggle>
              <mat-datepicker #datepicker1>
              </mat-datepicker>
            </mat-form-field>
          </div>

          <div class="mt-2 col-md-4 col-lg-3 col-sm-12">
            <mat-form-field class="matFormWrapper">
              <mat-label>{{'statusTicket.searchOption3.input2' | translate}}</mat-label>
              <input matInput [matDatepicker]="datepicker2" formControlName="until" class="activeInput"
                (dateChange)="dateFormat('until',$event)" />
              <mat-datepicker-toggle matSuffix [for]="datepicker2"></mat-datepicker-toggle>
              <mat-datepicker #datepicker2>
              </mat-datepicker>
            </mat-form-field>
          </div>

          <div class="mt-2 col-md-4 col-sm-12 my-4 col-lg-3 mr-1vw">
            <button class="background-blue w-100" id="btnSearchTicket3" mat-raised-button type="submit"
              [disabled]="!searchTicketForm3.valid">
              <span *ngIf="!isLoadingButton">{{'statusTicket.search' | translate}}</span>
              <span *ngIf="isLoadingButton">{{'statusTicket.search' | translate}}...</span>
              <div class="spinner-border spinner-border-sm ml-2" *ngIf="isLoadingButton" role="status"></div>
            </button>
          </div>

        </div>
      </ng-container>
    </form>
  </ng-container>

  <app-by-ticket *ngIf="searchByTicketNumber" [stNumber]='ticketNumber' [dateTicket]='ticketDate'></app-by-ticket>
</div>

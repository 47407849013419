import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SessionAuthAgencyGuard } from './guards/session-auth-agency.guard';
import { SessionAuthLatamGuard } from './guards/session-auth-latam.guard';

import { ActivateAgencyComponent } from './modules/agency-module/activate-agency/activate-agency.component';
import { AgencyReportComponent } from './modules/waiver-postsale-module/agency-report/agency-report.component';
import { BranchOfficeBudgetComponent } from './modules/latam-module/budget/branch-office-budget/branch-office-budget.component';
import { BulkLoadLatamComponent } from './modules/latam-module/bulk-load/bulk-load-latam.component';
import { CreateLatamUserComponent } from './modules/latam-module/latam-user/create-latam-user/create-latam-user.component';
import { CreateWaiverComponent } from './modules/waiver-postsale-module/create-waiver/create-waiver.component';
import { EditAgencyGroupComponent } from './modules/agency-group-module/edit-agency-group/edit-agency-group.component';
import { EditLatamUserComponent } from './modules/latam-module/latam-user/edit-latam-user/edit-latam-user.component';
import { ErrorPageComponent } from './modules/error-module/error-page/error-page.component';
import { FeedbackComponent } from './modules/latam-module/feedback/feedback.component';
import { GlobalBudgetManagementComponent } from './modules/latam-module/budget/global-budget-management/global-budget-management.component';
import { BudgetBulkLoadLatamComponent } from './modules/latam-module/budget/budget-bulk-load/budget-bulk-load-latam.component';
import { HomeComponent } from './components/home/home.component';
import { LatamReportComponent } from './modules/waiver-postsale-module/latam-report/latam-report.component';
import { ListAgencyGroupComponent } from './modules/agency-group-module/list-agency-group/list-agency-group.component';
import { ListLatamUsersComponent } from './modules/latam-module/latam-user/list-latam-users/list-latam-users.component';
import { ListWaiverAgencyComponent } from './modules/waiver-postsale-module/list-waiver-agency/list-waiver-agency.component';
import { ListWaiverAgentComponent } from './modules/waiver-postsale-module/list-waiver-agent/list-waiver-agent.component';
import { ListWaiverLatamComponent } from './modules/waiver-postsale-module/list-waiver-latam/list-waiver-latam.component';
import { ProfileAgencyComponent } from './modules/agency-module/profile-agency/profile-agency.component';
// tslint:disable-next-line: max-line-length
import { ProfileAgencyGroupComponent } from './modules/agency-group-module/profile-agency-group/profile-agency-group.component';
import { ProfileBranchOfficeComponent } from './modules/branch-office-module/profile-branch-office/profile-branch-office.component';
import { UserProfileLatamComponent } from './modules/latam-module/latam-user/user-profile-latam/user-profile-latam.component';
import { RegisterAgencyComponent } from './modules/agency-module/register-agency/register-agency.component';
import { SearchAgencyComponent } from './modules/agency-module/search-agency/search-agency.component';
import { TermsConditionsComponent } from './modules/terms-conditions-module/terms-conditions/terms-conditions.component';
import { WaiverAutomationComponent } from './modules/latam-module/waiver-automation/waiver-automation.component';
import { WaiverListComponent } from './modules/waiver-presale-module/waiver-list/waiver-list.component';
import { WaiverPresaleComponent } from './modules/waiver-presale-module/waiver-presale.component';
import { WaiverTypeManagementComponent } from './modules/latam-module/waiver-type-management/waiver-type-management.component';
import { UserProfileComponent } from './modules/latam-module/user-profile/user-profile.component';
import { WaiverFarediffManagementComponent } from './modules/latam-module/waiver-farediff-management/waiver-farediff-management.component';
import { LoginComponent } from './modules/login-module/login.component';
import { LoadSalesReportComponent } from './components/BDA-portal/load-sales-report/load-sales-report.component';
import { DownloadSalesReportComponent } from './modules/latam-module/bda-portal/download-sales-report/download-sales-report.component';
import { StatusTicketSearchComponent } from './components/status-ticket/status-ticket-search/status-ticket-search.component';
import { FormCasesComponent } from './components/form-cases/form-cases.component';
import { SessionAuthOutsourcingGuard } from './guards/session-auth-outsourcing.guard';
import { ListWaiverOutsourcingComponent } from './modules/waiver-postsale-module/list-waiver-outsourcing/list-waiver-outsourcing.component';
import { WaiverManualManagementComponent } from './modules/latam-module/waiver-manual/waiver-manual-management/waiver-manual-management.component';
import { CreateCourtesyWaiverComponent } from './modules/waiver-postsale-auto-module/create-courtesy-waiver/create-courtesy-waiver.component';
import { WaiverHomeComponent } from './modules/waiver-postsale-auto-module/waiver-home/waiver-home.component';
import { ListWaiverAgencyNewComponent } from './modules/waiver-postsale-auto-module/list-waiver-agency-new/list-waiver-agency-new.component';
import { WaiverManualNewComponent } from './modules/waiver-postsale-auto-module/waiver-manual-new/waiver-manual-new.component';
import { NameSeekerComponent } from './modules/correct-name/name-seeker/name-seeker.component';
import { ExecutiveApprovalComponent } from './modules/waiver-postsale-auto-module/executive-approval/executive-approval.component';
import { LoginLatamMobileComponent } from './modules/login-module/login-latam-mobile/login-latam-mobile.component';
import { ReportComponent } from './modules/correct-name/report/report.component';
import { CreatePolicyWaiverComponent } from './modules/waiver-postsale-auto-module/create-policy-waiver/create-policy-waiver.component';
import { FareRegulationComponent } from './modules/fare-regulation-module/fare-regulation/fare-regulation.component';

const routes: Routes = [
  // Pagina principal
  {
    path: 'login/agency',
    component: LoginComponent
  },
  {
    path: 'login/outsourcing',
    component: LoginComponent
  },
  {
    path: 'login/latam',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login/latam/executive',
    component: LoginLatamMobileComponent,
    data: {
      code: 'latam-mobile-executive'
    }
  },
  // Rutas pertencientes a los usuarios de latam
  {
    path: 'latam',
    canActivate: [SessionAuthLatamGuard],
    data: {
      routePermissions: [
        // Gestión LATAM
        {
          path: '/latam/status-ticket/search',
          role: ['ADMIN', 'ADMIN_POS', 'ADMIN_SALES']
        },
        {
          path: '/latam/users/list',
          role: ['ADMIN', 'ADMIN_POS', 'ADMIN_SALES']
        },
        {
          path: '/latam/users/create',
          role: ['ADMIN', 'ADMIN_POS', 'ADMIN_SALES']
        },
        {
          path: '/latam/users/edit',
          permission: [],
          role: ['ADMIN', 'ADMIN_POS', 'ADMIN_SALES']
        },
        {
          path: '/latam/users/profile',
          permission: [],
          role: ['ADMIN', 'ADMIN_POS', 'ADMIN_SALES'],
        },
        {
          path: '/latam/feedback',
          permission: [],
          role: ['ADMIN', 'ADMIN_POS'],
        },
        {
          path: '/latam/budget/management',
          permission: [],
          role: ['ADMIN', 'ADMIN_POS', 'ADMIN_SALES', 'USER_SALES']
        },
        {
          path: '/latam/budget/bulk-load',
          permission: [],
          role: ['ADMIN', 'ADMIN_POS', 'ADMIN_SALES', 'USER_SALES']
        },
        {
          path: '/latam/budget/branch-office',
          permission: [],
          role: ['ADMIN', 'ADMIN_POS', 'ADMIN_SALES', 'USER_SALES']
        },
        {
          path: '/latam/waiver-automation',
          permission: [],
          role: ['ADMIN', 'ADMIN_POS']
        },
        {
          path: '/latam/waiver-type-management',
          permission: [],
          role: ['ADMIN', 'ADMIN_POS']
        },
        {
          path: '/latam/waiver-farediff-management',
          permission: [],
          role: ['ADMIN', 'ADMIN_POS']
        },
        {
          path: '/latam/bulk-load',
          permission: [],
          role: ['ADMIN', 'ADMIN_PIA', 'ADMIN_POS']
        },
        // Gestión De Agencias
        {
          path: '/latam/agencies/search',
          permission: [],
          role: ['ADMIN', 'ADMIN_POS', 'USER_POS']
        },
        {
          path: '/latam/agencies/activation/:iataCode',
          permission: [],
          role: ['ADMIN', 'ADMIN_POS']
        },
        {
          path: '/latam/agencies/register',
          permission: [],
          role: ['ADMIN', 'ADMIN_POS', 'USER_POS', 'ADMIN_SALES', 'USER_SALES']
        },
        {
          path: '/latam/agencies/profile',
          permission: [],
          role: ['ADMIN', 'ADMIN_POS', 'USER_POS', 'ADMIN_SALES', 'USER_SALES']
        },
        {
          path: '/latam/agencies/group/list',
          permission: [],
          role: ['ADMIN', 'ADMIN_POS', 'ADMIN_SALES', 'USER_SALES']
        },
        {
          path: '/latam/agencies/group/profile',
          permission: [],
          role: ['ADMIN', 'ADMIN_POS']
        },
        {
          path: '/latam/agencies/group/edit',
          permission: [],
          role: ['ADMIN', 'ADMIN_POS']
        },
        {
          path: '/latam/branch-office/profile',
          permission: [],
          role: ['ADMIN', 'ADMIN_POS', 'USER_POS', 'ADMIN_SALES', 'USER_SALES']
        },
        // WAIVERS
        {
          path: '/latam/waivers/list',
          permission: [],
          role: ['ADMIN', 'ADMIN_POS', 'USER_POS', 'ADMIN_SALES', 'USER_SALES']
        },
        {
          path: '/latam/waivers/pre-sale-list',
          permission: [],
          role: ['ADMIN', 'ADMIN_POS', 'USER_POS', 'ADMIN_SALES', 'USER_SALES']
        },
        {
          path: '/latam/waivers/presale',
          permission: [],
          role: ['ADMIN', 'ADMIN_POS']
        },
        {
          path: '/latam/waivers/manuals',
          permission: [],
          role: ['ADMIN', 'ADMIN_POS']
        },
        {
          path: '/latam/waivers/report',
          permission: [],
          role: ['ADMIN', 'ADMIN_POS', 'USER_POS', 'ADMIN_SALES', 'USER_SALES']
        },
        //BDA
        {
          path: '/latam/bda-portal/download-sales-report',
          permission: [],
          role: ['USER_BDA']
        }
      ]
    },
    children: [
      {
        path: 'users',
        children: [
          {
            path: 'create',
            component: CreateLatamUserComponent
          },
          {
            path: 'list',
            component: ListLatamUsersComponent
          },
          {
            path: 'edit',
            component: EditLatamUserComponent
          },
          {
            path: 'profile',
            component: UserProfileLatamComponent
          }
        ]
      },
      {
        path: 'budget',
        children: [
          {
            path: 'management',
            component: GlobalBudgetManagementComponent
          },
          {
            path: 'bulk-load',
            component: BudgetBulkLoadLatamComponent
          },
          {
            path: 'branch-office',
            component: BranchOfficeBudgetComponent
          }
        ]
      },
      {
        path: 'agencies',
        children: [
          {
            path: 'search',
            component: SearchAgencyComponent
          },
          {
            path: 'register',
            component: RegisterAgencyComponent
          },
          {
            path: 'activation/:iataCode',
            component: ActivateAgencyComponent
          },
          {
            path: 'profile',
            component: ProfileAgencyComponent
          },
          {
            path: 'group',
            children: [
              {
                path: 'profile',
                component: ProfileAgencyGroupComponent
              },
              {
                path: 'list',
                component: ListAgencyGroupComponent
              },
              {
                path: 'edit',
                component: EditAgencyGroupComponent
              }
            ]
          }
        ]
      },
      {
        path: 'branch-office',
        children: [
          {
            path: 'profile',
            component: ProfileBranchOfficeComponent
          }
        ]
      },
      {
        path: 'waivers',
        children: [
          {
            path: 'list',
            component: ListWaiverLatamComponent
          },
          {
            path: 'report',
            component: LatamReportComponent
          },
          {
            path: 'pre-sale-list',
            component: WaiverListComponent,
          },
          {
            path: 'manuals',
            component: WaiverManualManagementComponent,
          }
        ]
      },
      {
        path: 'feedback',
        component: FeedbackComponent
      },
      {
        path: 'waiver-automation',
        component: WaiverAutomationComponent
      },
      {
        path: 'waiver-type-management',
        component: WaiverTypeManagementComponent
      },
      {
        path: 'waiver-farediff-management',
        component: WaiverFarediffManagementComponent
      },
      {
        path: 'bulk-load',
        component: BulkLoadLatamComponent
      },
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'user-profile',
        component: UserProfileComponent
      },
      {
        path: 'status-ticket',
        children: [
          {
            path: 'search',
            component: StatusTicketSearchComponent
          }
        ]
      },
      {
        path: 'bda-portal',
        children: [
          {
            path: 'download-sales-report',
            component: DownloadSalesReportComponent
          }
        ]
      }
    ]
  },
  // Rutas pertenecientes a los usuarios de agencias
  {
    path: 'agency',
    canActivate: [SessionAuthAgencyGuard],
    data: {
      routePermissions: [
        {
          path: '/agency/status-ticket/search',
          role: ['ADMIN_MTZ', 'MANUAL_MTZ', 'AGENT_MTZ', 'ADMIN_SUC', 'AGENT_SUC']
        },
        {path: '/agency/waivers/request',
          permission: [],
          role: ['ADMIN_MTZ', 'ADMIN_SUC'],},
        {path: '/agency/waivers/latam/request',
          permission: [],
          role: ['ADMIN_MTZ', 'MANUAL_MTZ', 'AGENT_MTZ'],},
        {path: '/agency/waivers/latam/list',
          permission: [],
          role: ['ADMIN_MTZ', 'MANUAL_MTZ', 'AGENT_MTZ'],},
        {path: '/agency/waivers/latam/manuals',
          permission: [],
          role: ['ADMIN_MTZ', 'MANUAL_MTZ', 'AGENT_MTZ'],},
        {path: '/agency/waivers/list',
          permission: [],
          role: ['ADMIN_MTZ', 'MANUAL_MTZ',  'ADMIN_SUC'],},
        {path: '/agency/waivers/pre-sale-list',
          permission: [],
          role: ['ADMIN_MTZ', 'MANUAL_MTZ', 'ADMIN_SUC'],},
        {path: '/agency/waivers/report',
          permission: [],
          role: ['ADMIN_MTZ', 'ADMIN_SUC'],},
        {path: '/agency/waivers/presale',
          permission: [],
          role: ['ADMIN_MTZ', 'MANUAL_MTZ', 'ADMIN_SUC'],},
        {path: '/agency/profile',
          permission: [],
          role: ['ADMIN_MTZ', 'MANUAL_MTZ'],},
        {
          path: '/agency/group/profile',
          permission: [],
          role: ['ADMIN_MTZ', 'MANUAL_MTZ', 'ADMIN_SUC'],
        },
        {
          path: '/agency/agent/waivers/request',
          permission: [],
          role: ['AGENT_MTZ', 'AGENT_SUC'],
        },

        {
          path: '/agency/agent/waivers/list',
          permission: [],
          role: ['AGENT_MTZ', 'AGENT_SUC'],
        },
        {
          path: '/agency/agent/waivers/presale',
          permission: [],
          role: ['AGENT_MTZ', 'AGENT_SUC'],
        },
        {
          path: '/agency/branch-office/profile',
          permission: [],
          role: ['ADMIN_MTZ', 'MANUAL_MTZ', 'ADMIN_SUC'],
        },
        {
          path: '/agency/correct-name',
          permission: [],
          role: ['ADMIN_MTZ', 'MANUAL_MTZ', 'AGENT_MTZ', 'ADMIN_SUC', 'AGENT_SUC']  // son los 4 perfiles agencia

        },
        {
          path: '/agency/correct-name/report',
          permission: [],
          role: ['ADMIN_MTZ', 'MANUAL_MTZ', 'AGENT_MTZ', 'ADMIN_SUC', 'AGENT_SUC']  // son los 4 perfiles agencia

        },
        {
          path: '/agency/fare-regulation',
          permission: [],
          role: ['ADMIN_MTZ', 'MANUAL_MTZ', 'AGENT_MTZ', 'ADMIN_SUC', 'AGENT_SUC']  // son los 4 perfiles agencia

        },

        //portal DBA
        {
          path: '/agency/bda-portal/load-sales-report',
          permission: [],
          role: ['ADMIN_MTZ', 'MANUAL_MTZ', 'AGENT_MTZ', 'ADMIN_SUC', 'AGENT_SUC']  // son los 4 perfiles agencia

        },
        {
          path: '/agency/form-cases',
          permission: [],
          role: ['ADMIN_MTZ', 'MANUAL_MTZ', 'AGENT_MTZ', 'ADMIN_SUC', 'AGENT_SUC']
        },
        {
          path: '/agency/correct-name',
          permission: [],
          role: ['ADMIN_MTZ', 'MANUAL_MTZ', 'AGENT_MTZ', 'ADMIN_SUC', 'AGENT_SUC']
        },
        {
          path: '/agency/correct-name/report',
          permission: [],
          role: ['ADMIN_MTZ', 'MANUAL_MTZ', 'AGENT_MTZ', 'ADMIN_SUC', 'AGENT_SUC']
        },

      ]
    },
    children: [
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'correct-name',
        component: NameSeekerComponent,
      },
      {
        path: 'correct-name/report',
        component: ReportComponent,
      },

      {
        path: 'waivers/request',
        component: CreateWaiverComponent,
      },
      {
        path: 'waivers/latam',
        component: WaiverHomeComponent,
        data: {
          module: 'waiverCourtesy'
        }
      },
      {
        path: 'waivers/policies',
        component: WaiverHomeComponent,
        data: {
          module: 'waiverPolicy'
        }
      },
      {
        path: 'waivers/latam/request',
        component: CreateCourtesyWaiverComponent,
      },
      {
        path: 'waivers/policies/request',
        component: CreatePolicyWaiverComponent,
      },
      {
        path: 'waivers/policies/list',
        component: ListWaiverAgencyNewComponent,
      },
      {
        path: 'waivers/policies/manuals',
        component: WaiverManualNewComponent,
      },
      {
        path: 'waivers/latam/manuals',
        component: WaiverManualNewComponent,
      },

      {
        path: 'waivers/latam/list',
        component: ListWaiverAgencyNewComponent,
      },
      {
        path: 'waivers/presale',
        component: WaiverPresaleComponent,
      },
      {
        path: 'waivers/list',
        component: ListWaiverAgencyComponent,
      },
      {
        path: 'waivers/pre-sale-list',
        component: WaiverListComponent,
      },
      {
        path: 'profile',
        component: ProfileAgencyComponent,
      },
      {
        path: 'group/profile',
        component: ProfileAgencyGroupComponent
      },
      {
        path: 'branch-office/profile',
        component: ProfileBranchOfficeComponent
      },
      {
        path: 'bda-portal/load-sales-report',
        component: LoadSalesReportComponent
      },
      {
        path: 'waivers/report',
        component: AgencyReportComponent,
      },
      {
        path: 'agent',
        children: [
          {
            path: 'waivers/request',
            component: CreateWaiverComponent,
          },
          {
            path: 'waivers/presale',
            component: WaiverPresaleComponent,
          },
          {
            path: 'waivers/list',
            component: ListWaiverAgentComponent,
          },
          {
            path: 'waivers/pre-sale-list',
            component: WaiverListComponent,
          },
        ]
      },
      {
        path: 'status-ticket',
        children: [
          {
            path: 'search',
            component: StatusTicketSearchComponent
          }
        ]
      },
      {
        path: 'form-cases',
        component: FormCasesComponent,
      },
      {
        path: 'fare-regulation',
        component: FareRegulationComponent,
      }
    ]
  },
  {
    path: 'outsourcing',
    canActivate: [SessionAuthOutsourcingGuard],
    data: {
      routePermissions: [
        // OUTSOURCING
        {
          path: '/outsourcing/home',
          role: ['USER_EXT']
        },
        {
          path: '/outsourcing/waivers/list',
          role: ['USER_EXT']
        },
        {
          path: '/outsourcing/waivers/report',
          role: ['USER_EXT']
        },
        {
          path: '/outsourcing/status-ticket/search',
          role: ['USER_EXT']
        }
      ]
    },
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'waivers',
        children: [
          {
            path: 'list',
            component: ListWaiverOutsourcingComponent
          },
          {
            path: 'report',
            component: LatamReportComponent
          }
        ]
      },
      {
        path: 'status-ticket',
        children: [
          {
            path: 'search',
            component: StatusTicketSearchComponent
          }
        ]
      },
    ]
  },
  {
    path: 'latam/executive/management/:actionKey',
    component: ExecutiveApprovalComponent,
    canActivate: [SessionAuthLatamGuard],
    data: {
      code: 'latam-mobile-executive',
      routePermissions: [
        {
          path: 'latam/executive/management/:actionKey',
          role: ['ADMIN', 'ADMIN_POS', 'USER_POS', 'ADMIN_SALES', 'USER_SALES'],
          permission: []
        }
      ]
    }
  },
  {
    path: '401',
    component: ErrorPageComponent,
    data: {
      code: '401'
    }
  },
  {
    path: '403',
    component: ErrorPageComponent,
    data: {
      code: '403'
    }
  },
  {
    path: '404',
    component: ErrorPageComponent,
    data: {
      code: '404'
    }
  },
  {
    path: 'termsconditions',
    component: TermsConditionsComponent
  },
  {
    path: '**',
    component: ErrorPageComponent,
    data: {
      code: '404'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { Feedback } from '../models/feedback.model';
import { FeedbackCSV } from '../models/feedbackCSV.model';

import { SessionInfoService } from './session-info.service';
import { StorageService } from './storage.service';


@Injectable()
export class FeedbackService {
  urlBase = `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlFeedback}`;

  constructor(private http: HttpClient, private sessionInfoService: SessionInfoService) { }

  getFeedbacksBetweenDates(startDate: Date, endDate: Date): Observable<FeedbackCSV[]> {
    const sessionInfo = this.sessionInfoService.getSessionInfo();
    let feedbacks: FeedbackCSV[] = [];

    return this.http
      .get(`${this.urlBase}/feedbacks?startDate=${this.parseDateToUTCDateString(startDate)}&endDate=${this.parseEndDateToUTCDateString(endDate)}`)
      .pipe(
        map(
          (data: Feedback[]) => {
            data.map(
              (value: Feedback) => {
                feedbacks.push(new FeedbackCSV(value.home, this.parseDateToString(value.creationDate), value.evaluation, value.comment));
              }
            );

            if (sessionInfo.isAdminUserLatam()) {
              return this.filtroPorMercado(sessionInfo.market, feedbacks);
            } else {
              return feedbacks;
            }
          }
        )
      )
  }

  // Filtro para el tipo de usuario Admin POS que permite mostrar los feedback segun los mercados asignados en sesion
  filtroPorMercado(market: string | string[], feedbacks: FeedbackCSV[]) {
    let feedbacksforMarket: FeedbackCSV[] = [];
    let feedbacksConsolidado: FeedbackCSV[] = [];

    for (let i of market) {
      feedbacksforMarket = feedbacks.filter(feedbacksAux => feedbacksAux.home.includes('_' + i.toLocaleLowerCase()));

      if (feedbacksforMarket.length > 0) {
        for (let j of feedbacksforMarket) {
          feedbacksConsolidado.push(j);
        }
      }
    }

    return feedbacksConsolidado;
  }

  parseDateToDateString(date: Date): string {
    return date.toLocaleString('es-CL').split(' ')[0];
  }

  parseDateToString(date: Date): string {
    date = new Date(date);
    return date.toLocaleString('es-CL').split('.')[0].replace('T', ' ');
  }

  parseDateToUTCDateString(date: Date): string {
    return date.toUTCString();
  }

  parseEndDateToUTCDateString(date: Date): string {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date.toUTCString();
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { Title } from '@angular/platform-browser';

import { WaiverLatamPostsaleInterface } from '../../../interfaces/waiver-latam-postsale.interface';
import { WaiverPage } from '../../../models/waiver-page.model';

import { Profile } from '../../../models/profile.model';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';
import { WaiverRejectionMotive } from '../../../models/waiver-rejection-motive.model';
import { DetailListWaiverLatamComponent } from '../detail-list-waiver-latam/detail-list-waiver-latam.component';

import { DateAdapter } from '@angular/material/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { Moment } from 'moment';
import { MessageErrorService } from '../../../services/message-error.service';
import { ProfilingService } from '../../../services/profiling.service';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';
import { WaiverPostsaleService } from '../../../services/waiver-postsale.service';
import { RejectListWaiversLatamComponent } from '../reject-list-waivers-latam/reject-list-waivers-latam.component';


@Component({
  selector: 'app-list-waiver-latam',
  templateUrl: './list-waiver-latam.component.html',
  styleUrls: ['./list-waiver-latam.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ListWaiverLatamComponent implements OnInit, OnDestroy {

  animationState = 'out';
  approveReject = true;
  statusListWaiverPending = true;
  statusListWaiverApproved = false;
  statusListWaiverRejected  = false;
  pendents: WaiverLatamPostsaleInterface[];
  approved: any[];
  rejected: any[];
  offset: number;
  limit: number;
  public  formFilter: UntypedFormGroup;
  approvedWaiversLoading = true;
  approvedWaiversCountLoading = true;
  noApprovedWaivers = false;
  noPendingWaivers = false;
  noRejectedWaivers = false;
  confirmRejectedWaivers = false;
  checkUncheckedAll = false;
  pendingWaiversCountLoading = true;
  rejectedWaiversCountLoading = true;
  loading = false;
  pendingWaiversLoading = true;
  rejectedWaiversLoading = true;
  confirmRejection = false;
  selectedPendingWaiversRowIndex = -1;
  selectedApprovedWaiversRowIndex = -1;
  selectedRejectedWaiversRowIndex = -1;
  pendingWaiversCount: number;
  approvedWaiversCount: number;
  rejectedWaiversCount: number;
  tabIndex = 0;
  countReject = 0;
  indexSelected = 0;
  rejectionComment: string;
  locale: string;

  agencyFilter = new UntypedFormControl('');
  agencyIataFilter = new UntypedFormControl();
  creationDateFilter = new UntypedFormControl();
  dataWaiverApproved = new MatTableDataSource();
  dataWaiverPending = new MatTableDataSource();
  dataWaiverRejected = new MatTableDataSource();
  displayedColumnsApproved = ['id', 'marketName', 'agency', 'agencyIata', 'executive', 'type',
    'motive', 'documentNumber', 'statusDate', 'documentCloserDepartureDate', 'actions'];
  displayedColumnsPending = ['check','id', 'marketName', 'agency', 'agencyIata', 'executive', 'type',
    'motive', 'documentNumber', 'creationDate', 'documentCloserDepartureDate', 'actions'];
  displayedColumnsRejected = ['id', 'marketName', 'agency', 'agencyIata', 'executive', 'type',
    'motive', 'documentNumber', 'statusDate', 'documentCloserDepartureDate', 'actions'];
  documentCloserDepartureDateFilter = new UntypedFormControl();
  documentNumberFilter = new UntypedFormControl();
  executiveFilter = new UntypedFormControl();
  filteredValues = {
    id: '', marketName: '', agency: '',
    agencyIata: '', executive: '', type: '',
    motive: '', documentNumber: '', creationDate: '',
    documentCloserDepartureDate: '', topFilter: false
  };
  objectWaiver = {
    id: 0,
    document: '',
    documentNumber: ''
  };
  objectWaivers= [];
  idFilter = new UntypedFormControl();
  marketNameFilter = new UntypedFormControl();
  motiveFilter = new UntypedFormControl();
  profiles = new Profile();
  selectedMotive: WaiverRejectionMotive = new WaiverRejectionMotive(-1, '');
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_WAIVERS, SidebarMenuModel.SUBMENU_WAIVER_MANAGEMENT);
  timer: any;
  timerSubscription: any;
  typeFilter = new UntypedFormControl();

  @ViewChild('paginatorApproved') paginatorApproved: MatPaginator;
  @ViewChild('paginatorPending') paginatorPending: MatPaginator;
  @ViewChild ('checkOne') checkOne: MatCheckboxChange;
  @ViewChild('paginatorRejected') paginatorRejected: MatPaginator;
  @ViewChild('sortApproved') sortApproved: MatSort;
  @ViewChild('sortPending') sortPending: MatSort;
  @ViewChild('sortRejected') sortRejected: MatSort;


  noApprovedWaiversFound = (index: number, item: { noData: boolean }) => item.noData;
  noPendingWaiversFound = (index: number, item: { noData: boolean }) => item.noData;
  noRejectedWaiversFound = (index: number, item: { noData: boolean }) => item.noData;

  errorLoadingApprovedWaivers = (index: number, item: { errorLoadingData: boolean }) => item.errorLoadingData;
  errorLoadingPendingWaivers = (index: number, item: { errorLoadingData: boolean }) => item.errorLoadingData;
  errorLoadingRejectedWaivers = (index: number, item: { errorLoadingData: boolean }) => item.errorLoadingData;

  pendingIndicator = 'P';
  approvedIndicator = 'A';
  rejectedIndicator = 'R';

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private adapter: DateAdapter<Moment>,
    public dialog: MatDialog,
    public formB: UntypedFormBuilder,
    private titleService: Title,
    private messageErrorService: MessageErrorService,
    private profilesService: ProfilingService,
    private sidebarShareDataService: SidebarShareDataService,
    private waiverPostsaleService: WaiverPostsaleService) {
      this.locale = locale;
      this.formFilter = this.initFormFilters();
  }

  ngOnInit() {

    this.offset=0;
    this.limit=10;
    this.profiles = this.profilesService.getProfile();
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.getPendingWaiver(this.offset, this.limit);

  }
  private initFormFilters(): UntypedFormGroup {

    return this.waiverPostsaleService.initForm();
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  /**
   * groups the list of waivers in pages according to how many records you want to see per page
   * @param indexNumber number of records that you want to see per page
   */
  tabChange(indexNumber: number) {
    this.initFormFilters();
    this.tabIndex = indexNumber;
    this.offset=0;
    this.limit=10;

    setTimeout(() => {
      switch (indexNumber) {
        case 0:

          this.statusListWaiverPending    = true;
          this.getPendingWaiver(this.offset, this.limit)
          this.statusListWaiverApproved   = false;
          this.statusListWaiverRejected   = false;

          break;
        case 1:

          this.statusListWaiverPending    = false;
          this.getApprovedWaiver(this.offset, this.limit)
          this.statusListWaiverApproved   = true;
          this.statusListWaiverRejected   = false;
          break;
        case 2:

          this.statusListWaiverPending   = false;
          this.getRejectedWaiver(this.offset, this.limit)
          this.statusListWaiverApproved  = false;
          this.statusListWaiverRejected  = true;
          break;
        default:

      }
    });
  }
  filtrar() {

    if (!this.formFilter.invalid){

      this.offset=0;
      this.limit=10;
      if (this.statusListWaiverPending){ this.getPendingWaiver( (this.offset), this.limit); }
      if (this.statusListWaiverApproved){ this.getApprovedWaiver( (this.offset), this.limit); }
      if (this.statusListWaiverRejected){ this.getRejectedWaiver( (this.offset),this.limit);  }
    }

  }

  pageChanged(event){


    let pageIndex = event.pageIndex;
    let pageSize = event.pageSize;

    this.offset=pageIndex;
    this.limit=pageSize;

    if (this.statusListWaiverPending){ this.getPendingWaiver( (pageIndex), pageSize); }
    if (this.statusListWaiverApproved){ this.getApprovedWaiver( (pageIndex), pageSize); }
    if (this.statusListWaiverRejected){ this.getRejectedWaiver( (pageIndex), pageSize);  }

  }

  setCalendarLanguage() {
    this.adapter.setLocale(localStorage.getItem('language'));
  }

  getPendingWaiver(pageIndex: number, pageSize: number) {
    this.noPendingWaivers = false;
    this.pendingWaiversLoading = true;
    this.pendingWaiversCountLoading = true;

    console.log(this.formFilter.get('idRequest').value);
    let waiver = new  WaiverPage(
      this.formFilter.get('idRequest').value,
      this.formFilter.get('pos').value,
      this.formFilter.get('agency').value,
      this.formFilter.get('iata').value,
      this.formFilter.get('requestDate').value,
      this.formFilter.get('salesEjecutive').value,
      this.formFilter.get('type').value,
      this.formFilter.get('motive').value,
      this.formFilter.get('pnrTkt').value,
      this.formFilter.get('flightDate').value
    );

    console.log(waiver);

    this.waiverPostsaleService.getPendingWaiverList(pageSize,pageIndex, waiver).subscribe({
      next: data => {
        if (data.length === 0) {



          this.pendingWaiversCountLoading = false;
           this.pendingWaiversLoading = false;
          this.noPendingWaivers = true;
          this.dataWaiverPending.data = [{ noData: true }];
          this.dataWaiverPending.paginator = null;

        } else {
           this.waiverPostsaleService.countPendingWaivers(waiver).subscribe({next: dataAux => {
            this.temporarySetFields(dataAux, pageIndex, pageSize, data, this.pendingIndicator);
            },
            error: (error: HttpErrorResponse) => {
              console.log('error', error);
            }
        });
        }
      },
      error: (error: HttpErrorResponse) => {
        this.noPendingWaivers = true;
        this.dataWaiverPending.data = [{ errorLoadingData: true }];
        this.dataWaiverPending.paginator = null;
        this.pendingWaiversCountLoading = false;
        this.pendingWaiversLoading = false;

        this.messageErrorService.sendError(error, 'error.loadingPendingWaivers');

        console.log('error', error);
      }});
  }

  getApprovedWaiver(pageIndex: number, pageSize: number): void {
    this.noApprovedWaivers = false;
    this.approvedWaiversLoading = true;
    this.approvedWaiversCountLoading = true;

    let waiver = new  WaiverPage(
      this.formFilter.get('idRequest').value,
      this.formFilter.get('pos').value,
      this.formFilter.get('agency').value,
      this.formFilter.get('iata').value,
      this.formFilter.get('requestDate').value,
      this.formFilter.get('salesEjecutive').value,
      this.formFilter.get('type').value,
      this.formFilter.get('motive').value,
      this.formFilter.get('pnrTkt').value,
      this.formFilter.get('flightDate').value
    );

    this.waiverPostsaleService.getApprovedWaiverList(pageSize, pageIndex, waiver).subscribe({
      next: data => {

        if (data.length === 0) {
          this.approvedWaiversCountLoading = false;
          this.approvedWaiversLoading = false;
          this.noApprovedWaivers = true;
          this.dataWaiverApproved.data = [{ noData: true }];
          this.dataWaiverApproved.paginator = null;
        } else {
          this.waiverPostsaleService.countApprovedWaivers(waiver).subscribe({next: dataAux => {
            this.temporarySetFields(dataAux, pageIndex, pageSize, data, this.approvedIndicator);
          },
            error: (error: HttpErrorResponse) => {
              console.log('error', error);
            }
        });
        }
      },
      error: (error: HttpErrorResponse) => {
        this.noApprovedWaivers = true;
        this.dataWaiverApproved.data = [{ errorLoadingData: true }];
        this.dataWaiverApproved.paginator = null;
        this.approvedWaiversCountLoading = false;
        this.approvedWaiversLoading = false;

        this.messageErrorService.sendError(error, 'error.loadingAprovedWaivers');

        console.log('error', error);
      }});
  }

  getRejectedWaiver(pageIndex: number, pageSize: number): void {

    this.noRejectedWaivers = false;
    this.rejectedWaiversLoading = true;
    this.rejectedWaiversCountLoading = true;

    let waiver = new  WaiverPage(
      this.formFilter.get('idRequest').value,
      this.formFilter.get('pos').value,
      this.formFilter.get('agency').value,
      this.formFilter.get('iata').value,
      this.formFilter.get('requestDate').value,
      this.formFilter.get('salesEjecutive').value,
      this.formFilter.get('type').value,
      this.formFilter.get('motive').value,
      this.formFilter.get('pnrTkt').value,
      this.formFilter.get('flightDate').value
    );


    this.waiverPostsaleService.getRejectedWaiverList(pageSize, pageIndex, waiver).subscribe({
      next: data => {
        if (data.length === 0) {
          this.rejectedWaiversCountLoading = false;
          this.rejectedWaiversLoading = false;
          this.noRejectedWaivers = true;
          this.dataWaiverRejected.data = [{ noData: true }];
          this.dataWaiverRejected.paginator = null;
        } else {
          this.waiverPostsaleService.countRejectedWaivers(waiver).subscribe({next: dataAux => {
            this.temporarySetFields(dataAux, pageIndex, pageSize, data, this.rejectedIndicator);
          },
            error: (error: HttpErrorResponse) => {
              console.log('error', error);
            }
        });
        }
      },
      error: (error: HttpErrorResponse) => {
        this.noRejectedWaivers = true;
        this.dataWaiverRejected.data = [{ errorLoadingData: true }];
        this.dataWaiverRejected.paginator = null;
        this.rejectedWaiversCountLoading = false;
        this.rejectedWaiversLoading = false;

        this.messageErrorService.sendError(error, 'error.loadingDeniedWaivers');

        console.log('error', error);
      }});
  }

  /**
   * call the waiver detail component
   * @param tab contains the information of the tab it is in (pendientes, aprobados, rechazados)
   * @param object `WaiverLatamPostsaleInterface`
   */
  openDialog(tab: string, object: WaiverLatamPostsaleInterface): void {
    let dialogRef = this.dialog.open(
      DetailListWaiverLatamComponent,
      {
        width: '90vw',
        height: '65vh',
        disableClose: true
      }
    );

    dialogRef.componentInstance.tab = tab;
    dialogRef.componentInstance.objectWaiver = { id: object.id, document: object.document, documentNumber: object.documentNumber };

    dialogRef.afterClosed().subscribe(result => {
      if (['reload-approved', 'reload-rejected', 'reload-pending-agency', 'error'].includes(result)) {
        if (this.statusListWaiverPending){ this.getPendingWaiver( (this.offset), this.limit); }
        if (this.statusListWaiverApproved){ this.getApprovedWaiver( (this.offset), this.limit); }
        if (this.statusListWaiverRejected){ this.getRejectedWaiver( (this.offset),this.limit);  }
      }
    });
  }

  openRejectDialog(tab: string, objectWaivers: WaiverLatamPostsaleInterface[]): void {
    let dialogRef = this.dialog.open(
      RejectListWaiversLatamComponent,
      {
        width: '90vw',
        height: '65vh',
        disableClose: true
      }
    );

    dialogRef.componentInstance.tab = tab;
    dialogRef.componentInstance.objectWaivers = this.objectWaivers;
    dialogRef.componentInstance.countReject = this.countReject;

    dialogRef.afterClosed().subscribe(result => {
      if (['reload-approved', 'reload-rejected', 'error'].includes(result)) {
        console.log("Se recarga la pestaña");
        if (this.statusListWaiverPending){ this.getPendingWaiver( (this.offset), this.limit); }
        if (this.statusListWaiverApproved){ this.getApprovedWaiver( (this.offset), this.limit); }
        if (this.statusListWaiverRejected){ this.getRejectedWaiver( (this.offset),this.limit);  }
        this.objectWaivers = [];
      }
    });

  }

  checkAllWaivers(event: MatCheckboxChange): void{


   if(event.checked){
    this.dataWaiverPending.filteredData.forEach((item: any)=>{
      if(!this.objectWaivers.includes(item)){
      item.check = true;
      this.objectWaivers.push(item);
      this.countReject++;
      }
    })
    this.checkUncheckedAll = true;
   }

   if(!event.checked){
    this.dataWaiverPending.filteredData.forEach((item: any)=>{
      item.check = false;
    })
    this.checkUncheckedAll = false;
    this.objectWaivers = [];
    this.countReject = 0;
   }
  }

  checkRejectWaiver(element: WaiverLatamPostsaleInterface, event: MatCheckboxChange): void{

   if(event.checked){
    this.confirmRejectedWaivers = true;
    this.objectWaivers.push(element);
    this.countReject++;
   }
   if(!event.checked){
    this.confirmRejectedWaivers = false;
    this.objectWaivers.forEach((item: any) =>{
      if(item.id == element.id){
        this.indexSelected = this.objectWaivers.indexOf(element);
        this.objectWaivers.splice(this.indexSelected, 1);

      }
    })
    this.countReject--;
   }
  }

  highlightPendingWaiversRow(event: Event, rowIndex: number) {
    this.selectedPendingWaiversRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  highlightApprovedWaiversRow(event: Event, rowIndex: number) {
    this.selectedApprovedWaiversRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  highlightRejectedWaiversRow(event: Event, rowIndex: number) {
    this.selectedRejectedWaiversRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }
  private temporarySetFields(countWaivers: number, pageIndex: number, pageSize: number, data: WaiverLatamPostsaleInterface[], indicator: string): void {
    this.titleService.setTitle((countWaivers > 0) ? '(' + countWaivers + ') LATAM Trade' : 'LATAM Trade');

    if (indicator === this.pendingIndicator) {
      this.handlePendingIndicator(countWaivers, pageIndex, pageSize, data);
    } else if (indicator === this.approvedIndicator) {
      this.handleAprovvedIndicator(countWaivers, pageIndex, pageSize, data);
    } else if (indicator === this.rejectedIndicator) {
      this.handleRejectedIndicator(countWaivers, pageIndex, pageSize, data);
    }
  }

  handleRejectedIndicator(countWaivers: number, pageIndex: number, pageSize: number, data: WaiverLatamPostsaleInterface[]) {
    this.rejectedWaiversCount = countWaivers;
      this.rejectedWaiversCountLoading = false;
      setTimeout(() => {
        this.paginatorRejected.length = countWaivers;
        this.paginatorRejected.pageIndex = pageIndex;
        this.paginatorRejected.pageSize = pageSize;
        this.rejected = data;
        this.dataWaiverRejected.data = this.rejected;
        this.dataWaiverRejected.sort = this.sortRejected;
        this.rejectedWaiversLoading = false;
        this.dataWaiverPending.sortingDataAccessor = (dataAux: any, header) => {
          if (header == "tier"){
            return dataAux.tierLevel;
          } else {
            return (!isNaN(dataAux[header]) || dataAux[header] == null) ? dataAux[header] : dataAux[header].toLocaleLowerCase();
          }
        };
      });
  }

  handleAprovvedIndicator(countWaivers: number, pageIndex: number, pageSize: number, data: WaiverLatamPostsaleInterface[]) {
    this.approvedWaiversCount = countWaivers;
      this.approvedWaiversCountLoading = false;
      setTimeout(() => {
        this.paginatorApproved.length = countWaivers;
        this.paginatorApproved.pageIndex = pageIndex;
        this.paginatorApproved.pageSize = pageSize;
        this.approved = data;
        this.dataWaiverApproved.data = this.approved;
        this.dataWaiverApproved.sort = this.sortApproved;
        this.approvedWaiversLoading = false;
        this.dataWaiverApproved.sortingDataAccessor = (dataAux: any, header) => {
          if (header == "tier"){
            return dataAux.tierLevel
          } else {
            return (!isNaN(dataAux[header]) || dataAux[header] == null) ? dataAux[header] : dataAux[header].toLocaleLowerCase()
          }
        };
      });
  }

  handlePendingIndicator(countWaivers: number, pageIndex: number, pageSize: number, data: WaiverLatamPostsaleInterface[]) {
    this.pendingWaiversCount = countWaivers;
      this.pendingWaiversCountLoading = false;
      setTimeout(() => {
        this.paginatorPending.length = countWaivers;
        this.paginatorPending.pageIndex = pageIndex;
        this.paginatorPending.pageSize = pageSize;
        this.pendents = data;
        this.dataWaiverPending.data = this.pendents.map((pendet :WaiverLatamPostsaleInterface) => {return {
          ...pendet,
          check: false
        }});
        this.dataWaiverPending.sort = this.sortPending;
        this.pendingWaiversLoading = false;
        this.dataWaiverPending.sortingDataAccessor = (dataAux: any, header) => {
          if (header == "tier"){
            return dataAux.tierLevel;
          } else {
            return (!isNaN(dataAux[header]) || dataAux[header] == null) ? dataAux[header] : dataAux[header].toLocaleLowerCase();
          }
        };
      });
  }

}

import { Component, OnInit, Input } from '@angular/core';

import { WaiverResult } from '../../../models/waiver-result.model';


@Component({
  selector: 'app-request-result',
  templateUrl: './request-result.component.html',
  styleUrls: ['./request-result.component.css']
})
export class RequestResultComponent {

  @Input() waiverResult: WaiverResult = new WaiverResult(null, null, null, null, null, null, null);
  @Input() isFarediff: boolean;
  @Input() isBrMarket: boolean;
  @Input() isSelfManaged: boolean;



}

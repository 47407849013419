import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-by-csv',
  templateUrl: './by-csv.component.html',
  styleUrls: ['./by-csv.component.css']
})
export class ByCsvComponent {





}

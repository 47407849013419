import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {

  transform(val: number): string {
    return val ? val.toString().replace(',', '').replace('.', ',') : null;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'parseWaiverCount' })
export class PendingWaiverCountPipe implements PipeTransform {
  transform(value: number): string {
    if (!value) {
      return '';
    }

    return value <= 200 ? value.toString() : '200+';
  }
}

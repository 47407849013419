<header >
    <ng-container *ngIf="infoUser$ | async as infoUser">
        <div class="header-waivers-latam">
            <div class="header-left">
                <a class="logo-latam-header" href="/agency/home">
                    <img class="latam-logo" src="/assets/images/latam-airlines.svg" alt="Latam airlines logo">
                </a>
            </div>
            <div class="header-center">
            </div>
            <div class="header-right">                
                <button  class="language-button" mat-button [matMenuTriggerFor]="menu">
                    <img style="width: 20px" src="assets/images/waiver-latam/{{languageItem.img}}" />
                    {{ languageItem.translateName | translate }}
                    <img src="assets/images/waiver-latam/caretdown.svg" />
                </button>
                <mat-menu #menu="matMenu">
                    <ng-container *ngFor="let item of languages">
                        <button *ngIf="item.shortName !== language" mat-menu-item (click)="changedLanguage(item.shortName)">
                            <img style="width: 22px" src="assets/images/waiver-latam/{{item.img}}" />
                            {{ item.translateName | translate}}
                        </button>
                    </ng-container>
                </mat-menu>

                <div class="budget-header" *ngIf="showAgencyBudget">
                    <span style="margin-right: 5px;">
                        Saldo:
                    </span>
                    <span *ngIf="isLoadingAgencyBudget"
                    >
                        <mat-progress-spinner 
                            class="budget-spinner"
                            mode="indeterminate" 
                            diameter="20" 
                        >
                        </mat-progress-spinner>
                    </span>
                    <span *ngIf="!isLoadingAgencyBudget" style="width: max-content;"
                    > 
                        USD {{agencyGroupBudget}}
                    </span>
                </div>

               
                    <button  id="initialsButton" class="logout-button radial-btn" mat-button [matMenuTriggerFor]="menus" >                
                        <div class="Navbar-informacion">                            
                            <div class="iniciales-nombre">
                                {{ (infoUser.name.split(' ')[0][0] | capitalize) + ' ' + (infoUser.name?.split(' ')[1][0] ?? ' ' | capitalize) }}      
                            </div>
                            <div>
                                {{ infoUser.name | firstWord | capitalize }}   
                            </div>
                            <div>
                                <img src="assets/images/waiver-latam/caretdown.svg">
                            </div>                            
                        </div>
                   </button>

                    <mat-menu class="close-session" #menus="matMenu" >
                        <button mat-menu-item >                                                                                                  
                            <div class="center-items-infoUser" >   
                                <div class="center-image" >
                                    <img class="Destinationjpg"src="assets/images/correct-name/category.svg" />     
                                </div>                          
                                <div class="info-user">
                                    <div class="col-12 sidenav-title" >
                                        <span id="sidenavUserNameSpan">{{ infoUser.name | capitalize }}</span>
                                    </div>
                                    <div class="subTitle">
                                        <div class="col-12 sidenav-subtitle" >
                                            <span id="sidenavUserPositionSpan">{{ infoUser.roleName }}</span>
                                        </div>
                                        <div *ngIf="infoUser.secondaryRoleName" class="col-12 sidenav-subtitle">
                                            <span id="sidenavUserSecondaryPositionSpan">{{ infoUser.secondaryRoleName }}</span>
                                        </div>
                                        <div *ngIf="infoUser.isAgencySession()" class="col-12 sidenav-subtitle" >
                                            <span id="sidenavUserAgencySpan">{{ infoUser.agency }}</span>
                                        </div>
                                        <div *ngIf="infoUser.isLatamSession() || infoUser.isLatamExternalSession()" class="col-12 sidenav-subtitle" >
                                            <span id="sidenavUserMarketSpan">{{ 'markets' | translate }}</span>
                                        </div>                    
                                        <div *ngIf="infoUser.isLatamSession() || infoUser.isLatamExternalSession()" class="col-12 sidenav-subtitle" >
                                            <span>{{ infoUser.market.join(', ') | uppercase }}</span>
                                        </div>
                                    </div>                                    
                                </div>                                                           
                            </div>       
                                     
                        </button>
                        <button mat-menu-item (click)="logout()" >
                            <img class="Destinationjpg"src="assets/images/correct-name/Destinations.svg" />
                            <span class="CerrarSesión" style="padding-left: 15px;">{{ 'button.logout' | translate}}</span>    
                        </button>                
                    </mat-menu>
                          

                

            </div>
        </div>
    </ng-container>
</header>

import { Component } from "@angular/core";

@Component({
  selector: "app-form-cases",
  templateUrl: "./form-cases.component.html",
  styleUrls: ["./form-cases.component.css"],
})
export class FormCasesComponent{

  name = 'Dynamic Form Action Demo';
  url = 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8';
  tipoCaso = '';
  lenguajeSeleccionado = '';


}

<div class="modal-content small-modal">
  <div class="modal-header">
    <h5 class="modal-title">{{ 'userData' | translate }}</h5>
    <button class="close" (click)="closeDialog('close')">
      <mat-icon class="close-icon">clear</mat-icon>
    </button>
  </div>

  <div class="modal-body">
    <form [formGroup]="formG">
      <div *ngIf="loading" class="loader loading">
        <div class="loading-div">
          <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
          </svg>
          <span class="loading-span">{{ 'loading' | translate }}</span>
        </div>
      </div>

      <div *ngIf="!loading">
        <div class="row">
          <div class="col-6">
            <mat-form-field>
              <mat-label>{{ 'field.name' | translate }}</mat-label>
              <input matInput id="userNameInput" formControlName="name"
                pattern="[A-zÀ-ú:\u3000\u3400-\u4DBF\u4E00-\u9FFF\0-9]+" maxlength="100" required>
              <mat-error
                *ngIf="formG.controls.name.invalid && (formG.controls.name.dirty || formG.controls.name.touched) && formG.controls.name.errors.required">
                {{ 'field.error.required' | translate }}</mat-error>
              <mat-error
                *ngIf="formG.controls.name.invalid && (formG.controls.name.dirty || formG.controls.name.touched) && formG.controls.name.errors.pattern">
                {{ 'field.error.invalidData' | translate }}</mat-error>
            </mat-form-field>
          </div>

          <div class="col-6">
            <mat-form-field>
              <mat-label>{{ 'field.surname' | translate }}</mat-label>
              <input matInput id="userLastnameInput" formControlName="lastname" pattern="[A-zÀ-ú:\u3000\u3400-\u4DBF\u4E00-\u9FFF\0-9]+"
                maxlength="100" required>
              <mat-error
                *ngIf="formG.controls.lastname.errors && (formG.controls.lastname.dirty || formG.controls.lastname.touched) && formG.controls.lastname.errors.required">
                {{ 'field.error.required' | translate }}</mat-error>
              <mat-error
                *ngIf="formG.controls.lastname.errors && (formG.controls.lastname.dirty || formG.controls.lastname.touched) && formG.controls.lastname.errors.pattern">
                {{ 'field.error.invalidData' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <mat-form-field>
              <mat-label>{{ 'field.email' | translate }}</mat-label>
              <input matInput id="userEmailInput" formControlName="email"
                maxlength="100" required
                pattern='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'>
              <mat-error
                *ngIf="formG.controls.email.errors && (formG.controls.email.dirty || formG.controls.email.touched) && formG.controls.email.errors.required">
                {{ 'field.error.required' | translate }}</mat-error>
              <mat-error
                *ngIf="formG.controls.email.errors && (formG.controls.email.dirty || formG.controls.email.touched) && formG.controls.email.errors.pattern">
                {{ 'field.error.invalidData' | translate }}</mat-error>
            </mat-form-field>
          </div>

          <div class="col-6">
            <mat-form-field>
              <mat-label>{{ 'field.position' | translate }}</mat-label>
              <input matInput id="userPositionInput" formControlName="position" pattern="\b[a-zA-ZáéíóúÁÉÍÓÚÑñ\s]+" maxlength="50">
              <mat-error
                *ngIf="formG.controls.position.errors && (formG.controls.position.dirty || formG.controls.position.touched) && formG.controls.position.errors.pattern">
                {{ 'field.error.invalidData' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <mat-form-field>
              <mat-label>{{ 'field.birthDay' | translate }}</mat-label>
              <input matInput id="userBirthdayInput" formControlName="birthdate" [min]="minDate" [max]="maxDate"
                [matDatepicker]="picker">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker disabled="false" (opened)="setCalendarLanguage()"></mat-datepicker>
            </mat-form-field>
          </div>

          <ng-container *ngFor="let system of systemsAux">
            <div class="col-6">
              <mat-form-field>
                <mat-label>{{ ('field.role' | translate) + ' ' + system.name }}</mat-label>
                <mat-select id="system-{{ system.id }}"
                  formControlName="{{ system.name.toLowerCase().trim().replace(' ', '') + 'Roles' }}" [compareWith]="compareRole"
                  (selectionChange)="onSelectRole($event)">
                  <mat-option *ngFor="let role of system.roles" [value]="role">
                    {{ role.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container *ngIf="viewBranchOffice">
            <div class="col-6">
              <mat-form-field>
                <mat-label>{{ 'field.branchOffice' | translate }}</mat-label>
                <mat-select id="branchOfficeSelect" formControlName="branchOffice" [compareWith]="compareBranchOffice"
                  (selectionChange)="onSelectBranchOffice($event)" required>
                  <mat-option *ngFor="let branchOffice of branchOffices" [value]="branchOffice">
                    {{ branchOffice.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="formG.controls.branchOffice.errors && (formG.controls.branchOffice.dirty || formG.controls.branchOffice.touched) && formG.controls.branchOffice.errors.required">
                  {{ 'field.error.required' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container *ngIf="viewPiaCode">
            <div class="col-6">
              <mat-form-field>
                <mat-label>{{ 'field.piaCode' | translate }}</mat-label>
                <mat-select id="piaCodeSelect" formControlName="piaCode"
                  [compareWith]="comparePiaCode" required>
                  <mat-option *ngFor="let piaCode of piaCodes" [value]="piaCode">
                    {{ piaCode.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="formG.controls.piaCode.errors && (formG.controls.piaCode.dirty || formG.controls.piaCode.touched) && formG.controls.piaCode.errors.required">
                  {{ 'field.error.required' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </ng-container>
        </div>

        <div class="row">
          <div class="col">
            <mat-error *ngIf="formG.getError('noRoleSelected')" class="error">{{ 'field.error.selectOneRole' | translate }}
            </mat-error>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer"
    *ngIf="profiles.createAgencyGroupUserManagementLT || profiles.createAgencyGroupUserManagement || profiles.createBranchOfficeUser">
    <button mat-raised-button id="closeButton" (click)="closeDialog('cancel')"
      class="btn-volver btn-w">{{ 'button.cancel' | translate }}</button>
    <button mat-raised-button id="createUserButton" data-dismiss="modal"
      [disabled]="!formG.valid || !formG.dirty || loadingBranchOffices || loadingIataPiaCodes" (click)="save()"
      class="background-red btn-w float-right">{{ 'button.save' | translate }}</button>
  </div>
</div>

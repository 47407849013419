import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessageErrorService } from '../../../services/message-error.service';
import { ModalInformationTypepnrComponent } from '../modal-information-typepnr/modal-information-typepnr.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-validate-correct-name',
  templateUrl: './modal-validate-correct-name.component.html',
  styleUrls: ['./modal-validate-correct-name.component.css']
})
export class ModalValidateCorrectNameComponent {
  newFullName: string;
  oldFullName: string;
  agentBooking: string;
  oac: string;
  loadingInfo: boolean = false; 

  constructor(
    private dialogRef: MatDialogRef<ModalValidateCorrectNameComponent>,
    private messageErrorService: MessageErrorService,
    private translateService: TranslateService,
      public dialog: MatDialog
  ) {}

  confirmCorrection() {
    this.loadingInfo = true; 
    this.dialogRef.close(true);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

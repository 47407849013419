<div class="modal-content small-modal">
  <div class="modal-header">
    <h5 class="modal-title">{{ 'button.userManual' | translate }}</h5>
    <button class="close" (click)="closeDialog()">
      <mat-icon class="close-icon">clear</mat-icon>
    </button>
  </div>


  <div class="modal-body">
    <div class="row justify-content-center">
      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <mat-form-field>
          <mat-label>{{ 'field.type' | translate }}</mat-label>
          <mat-select #waiverCategorySelect="ngModel"id="typeSelect" name="typeSelect" [(ngModel)]="waiverType" required >
            <mat-option *ngFor="let type of types" [value]="type.code">
              {{ type.name }}
            </mat-option>
          </mat-select>

        </mat-form-field>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <button mat-button class="mat-raised-button background-red" (click)="downloadWaiverManual()"
          [disabled]="waiverType==''" >{{ 'button.download' | translate }}</button>
      </div>
    </div>
  </div>
</div>

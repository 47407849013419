<div class="wrapper-login">
  <app-country-images></app-country-images>

  <div class="container">
    <div class="row">
      <div class="col mt-5">
        <div class="row mt-5 elevar">
          <div id="left-login" class="col-12 col-md-5 col-lg-5 col-xl-5 left-content">
            <div class="content-message box-1">
              <div class="mt-50">
                <span class="txt-1">LATAM Trade</span>
                <span class="txt-2">{{ 'login.welcomeMsg' | translate}}</span>
              </div>
              <span class="visit-lt">{{ 'login.visit' | translate }}
                <a href="https://www.latamtrade.com">latamtrade.com</a>
              </span>
            </div>
          </div>

          <div id="right-login" class="col-12 col-md-7 col-lg-7 col-xl-7 right-content">
            <div class="content-message box-2">
              <div class="row mt-5">
                <div class="col">
                  <img src="assets/images/logo-latamtrade.svg" alt="Logo Latamtrade">
                </div>
              </div>

              <ng-container *ngIf="!showLoginLatam && !showRecoveryPass && !showResetPass">
                <app-login-agency (message)="setMessage($event)">
                </app-login-agency>
              </ng-container>

              <ng-container *ngIf="showLoginLatam && !showRecoveryPass && !showResetPass">
                <app-login-latam (message)="setMessage($event)">
                </app-login-latam>
              </ng-container>


              <ng-container *ngIf="!showLoginLatam && !showRecoveryPass && !showResetPass">
                <div class="row mt-3">
                  <div class="col text-center">
                    <span class="link-login">
                      <a (click)="setShowRecoveryPass(true)">{{ 'login.forgotPassword' | translate }}</a>
                    </span>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="!showLoginLatam && showRecoveryPass && !showResetPass">
                <app-recovery-pass (showRecoveryPass)="setShowRecoveryPass($event)" (message)="setMessage($event)">
                </app-recovery-pass>
              </ng-container>

              <ng-container *ngIf="!showLoginLatam && !showRecoveryPass && showResetPass">
                <app-reset-pass [code]="code" [urlLogin]="urlLogin" (message)="setMessage($event)"></app-reset-pass>
              </ng-container>

              <div class="row mt-3 error-message" *ngIf="message?.length > 0">
                <div class="col text-center">
                  {{ message | translate }}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-loader></app-loader>
</div>

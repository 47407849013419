<ng-container>
  <ng-container>
   
    <!-- Comienzo de menu  -->
    <mat-sidenav-content class="sidenav-content" style="margin-left: -15px;">
    
      <!--Inicio de buscar pnr --> 
      <div class="content">
        <mat-horizontal-stepper [linear]="true" #stepper >
            <mat-step>
                  <div class="row initStyle">
                    <div class="col style" style="top: 12px;">
                      <span class="title correct-name"  >{{ 'correctName.correctName' | translate }}</span>
                    </div>
                  </div>
                  <div id="menuColor" class="header-latam-menu " >
                    <div class="latam-menu-left">
                        <button class="menu-item" style="padding-right: 50px;"  routerLinkActive="active-menu-item">
                            <span>{{ 'correctName.correctName' | translate }}</span>
                        </button>
          
                        <button 
                          class="menu-item" 
                          style="padding-left: -1px; padding-right: 50px;" 
                          (click)="navigateToReport()" 
                          routerLinkActive="active-menu-item"
                          >
                          <span>{{ 'correctName.report' | translate }}</span>
                        </button>
                    </div>
                  </div>                
                  <div class="search-main-container">
                    <div class="test">
                        <div class="search-inputs">
                            <form [formGroup]="recordLocatorForm" (ngSubmit)="getPnrInfo()">                            
    
                                  <mat-form-field id="buttonPnr" appearance="outline" *ngIf="recordLocatorForm.get('selection').value === 'PNR'">
                                    <mat-label>{{ 'correctName.pnrCode' | translate }}</mat-label>
                                    <input  
                                        matInput
                                        maxlength="6"
                                        formControlName="pnrValue"
                                        [disabled]="loadingReservationInfo"
                                    >
                                    <mat-error
                                        *ngIf="recordLocatorForm.get('pnrValue').hasError('required')">
                                            {{ 'field.error.required' | translate }}
                                    </mat-error>
                                    <mat-error
                                        *ngIf="recordLocatorForm.get('pnrValue').hasError('pattern')">
                                            {{ 'field.error.invalidPnr' | translate }}
                                    </mat-error>
                                  </mat-form-field>
    
                                <button mat-flat-button 
                                  [disabled]="loadingReservationInfo || isLoading()">
                                        <span *ngIf="!loadingReservationInfo && !isLoading()">
                                            {{ 'button.search' | translate }}
                                        </span>
                                        <mat-progress-spinner 
                                            *ngIf="loadingReservationInfo"
                                            class="custom-spinner"
                                            mode="indeterminate" 
                                            diameter="20">
                                        </mat-progress-spinner>
                                </button>
                            </form>
                        </div>
                    </div>
    
                    <div *ngIf="!validPostPNR" class="info">
                        <img  src="assets/images/waiver-latam/search.svg" />
                        <h2>
                          <span class="searchsinfo" [innerHTML]="'correctName.searchInfoPart1' | translate"></span>
                          <span class="searchsinfo" [innerHTML]="'correctName.searchInfoPart2' | translate"></span>
                        </h2>                     
                    </div>    
                    
                      
                    <app-form-correctname 
                      [passengers]="passenger" 
                      [segments]="segments" 
                      [recordLocator]="pnrValue" 
                      [remarks]="remarks"    
                      [agentBooking]="agentBooking"  
                      [oac]="oac"  
                      (correctionConfirmed)="finalStep($event)"                       
                      *ngIf="!loadingReservationInfo && validPostPNR" 
                      class="info-2">
                    </app-form-correctname> 
                  </div>                  
            </mat-step>
            <mat-step>
              <app-successful-name-change 
                [oldFullName]="correctionInfo?.oldName" 
                [newFullName]="correctionInfo?.newName" 
                [motive]="correctionInfo?.motive"
                [date]="correctionInfo?.date"
                (backClicked)="resetStepper()"
                *ngIf="confirmCorrection"
                class="info-2">
              >

              </app-successful-name-change>
            </mat-step>
        </mat-horizontal-stepper>  
      </div> 
    </mat-sidenav-content>    
  </ng-container>
</ng-container>



<div class="mt-4 mb-3">
  <div class="row">
    <div class="col">
      <span class="subtitle">{{ 'segmentsInformation' | translate }}</span>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <form #segmentInfoForm="ngForm">
        <mat-table [dataSource]="dataSource" class="table-segment-info mat-elevation-z1">
          <mat-header-row *matHeaderRowDef="displayedColumns" class="segment-info-row"></mat-header-row>

          <mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"
            [ngClass]="{'highlight': selectedRow === i}" (mouseover)="highlightRow($event, i)"
            (mouseout)="highlightRow($event, i)" class="segment-info-row"></mat-row>

          <ng-container matColumnDef="segmentNumber" id="segment-info-segmentNumber">
            <mat-header-cell *matHeaderCellDef>{{ 'field.segmentNumber' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.segmentNumber | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="carrierMarketing" id="segment-info-mktCarrier">
            <mat-header-cell *matHeaderCellDef>{{ 'field.mktCarrier' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.carrierMarketing | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="flightNumber" id="segment-info-mktFlightNumber">
            <mat-header-cell *matHeaderCellDef>{{ 'field.mktFlightNumber' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.flightNumber | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="fareBasis" id="segment-info-fareBasis">
            <mat-header-cell *matHeaderCellDef>{{ 'field.farebasis' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.fareBasis | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="class" id="segment-info-class">
            <mat-header-cell *matHeaderCellDef>{{ 'field.class' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.class | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="source" id="segment-info-source">
            <mat-header-cell *matHeaderCellDef>{{ 'field.from' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.source | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="destination" id="segment-info-destination">
            <mat-header-cell *matHeaderCellDef>{{ 'field.to' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.destination | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="departureDateTime" id="segment-info-departureDateTime">
            <mat-header-cell *matHeaderCellDef>{{ 'field.departureDate' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.departureDateTime | dateFormatWithoutLocale: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status" id="segment-info-status">
            <mat-header-cell *matHeaderCellDef>{{ 'field.segmentStatus' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.status | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="fareBasisReq">
            <mat-header-cell *matHeaderCellDef>{{ 'field.confirmFarebasis' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-form-field class="edit-input">
                <input id="fareBasis-{{ element.segmentNumber }}" name="fareBasis-{{ element.segmentNumber }}" matInput
                  [(ngModel)]="element.fareBasis" #fareBasis="ngModel" class="edit-input" (input)="changeSegments(element)"
                  [disabled]="isValuated && step !== 0" minlength="7" [maxlength]="setMaxLength()"
                  pattern="([a-zA-Z0-9]+)|([a-zA-Z0-9]+/[a-zA-Z0-9]+)" matTooltip="{{ 'tooltip.requiredFarebasis' | translate }}" required>
                <mat-error *ngIf="fareBasis.errors?.required && (fareBasis.dirty || fareBasis.touched)">
                  {{ 'field.error.required' | translate }}</mat-error>
                <mat-error
                  *ngIf="(fareBasis.errors?.minlength || fareBasis.errors?.pattern) && (fareBasis.dirty || fareBasis.touched)">
                  {{ 'field.error.invalidData' | translate }}</mat-error>
              </mat-form-field>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ticketDesignator">
            <mat-header-cell *matHeaderCellDef>Ticket Designator</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-form-field>
                <mat-select id="ticketDesignator-{{ element.segmentNumber }}" name="ticketDesignator-{{ element.segmentNumber }}"
                  [disabled]="isValuated && step !== 0" [(ngModel)]="element.ticketDesignator" #ticketDesignator="ngModel"
                  (selectionChange)="changeSegments(element)" required>
                  <mat-option *ngFor="let ticketDesignator of ticketDesignators" [value]="ticketDesignator">
                    <div id="{{ ticketDesignator }}">{{ ticketDesignator }}</div>
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="ticketDesignator.errors?.required && (ticketDesignator.dirty || ticketDesignator.touched)">
                  {{ 'field.error.required' | translate }}</mat-error>
              </mat-form-field>
            </mat-cell>
          </ng-container>
        </mat-table>
      </form>
    </div>
  </div>
</div>

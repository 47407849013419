export class Exception {
  type: string;
  description: string;
  status: number;

  constructor(
    type: string,
    description: string,
    status?: number) {
      this.type = type;
      this.description = description;
      this.status = status;
  }
}

export const SystemException = 'SystemException';
export const BusinessException = 'BusinessException';

import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { Injectable } from "@angular/core";

export class ContextRoute {
  public toCacheRoute: string;
  public futureRoutes: string[];

  public constructor(toCacheRoute: string, futureRoutes: string[]) {
    this.toCacheRoute = toCacheRoute;
    this.futureRoutes = futureRoutes;
  }
}

@Injectable()
export class LatamComponentsReuseStrategy implements RouteReuseStrategy {
  private contextRoutes: Array<ContextRoute> = new Array<ContextRoute>();
  private storedRouteHandles = new Map<string, DetachedRouteHandle>();

  public constructor() {
    this.contextRoutes.push(new ContextRoute('/latam/users/list', ['/latam/users/profile'/*,'edit','create'*/]));
  }

  // Decides if the route should be stored
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    let path = this.buildPath(route);
    return !!this.contextRoutes.find(e => e.toCacheRoute === path);
  }

  // Store the information for the route we're destructing
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.storedRouteHandles.set(this.buildPath(route), handle);
  }

  // Return true if we have a stored route object for the next route
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return this.storedRouteHandles.has(this.buildPath(route));
  }

  // If we returned true in shouldAttach(), now return the actual route data for restoration
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    return this.storedRouteHandles.get(this.buildPath(route));
  }

  // Reuse the route if we're going to and from the same route
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    let furutePath = this.buildPath(future);
    let currPath = this.buildPath(curr);

    if (furutePath !== currPath
      && !this.contextRoutes.find(e => (e.toCacheRoute === furutePath && e.futureRoutes.includes(currPath))
      || (e.toCacheRoute === currPath && e.futureRoutes.includes(furutePath)))) {
      this.storedRouteHandles = new Map<string, DetachedRouteHandle>();
    }
    return future.routeConfig === curr.routeConfig;
  }

  buildPath(route: ActivatedRouteSnapshot): string {
    if (!route.parent) {
      return route.url.toString();
    }
    return this.buildPath(route.parent) + '/' + route.url.toString();
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation, LOCALE_ID, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter, MatOption, MatOptionSelectionChange } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { AgencyGroup } from '../../models/agency-group.model';
import { DownloadLatamReportCSV } from '../../models/download-latam-report-CSV.model';

import { Profile } from '../../models/profile.model';
import { SessionInfo } from '../../models/SessionInfo.model';
import { SidebarMenuModel } from '../../models/SidebarMenu.model';
import { WaiverPresaleType } from '../../models/waiver-presale-type.model';

import { AgencyGroupService } from './../../services/agency-group.service';
import { LatamReportService } from '../../services/latam-report.service';
import { MessageService } from '../../services/message.service';
import { ProfilingService } from '../../services/profiling.service';
import { SessionInfoService } from '../../services/session-info.service';
import { SidebarShareDataService } from '../../services/sidebar-share-data.service';
import { WaiverPostsaleService } from './../../services/waiver-postsale.service';
import { MessageErrorService } from './../../services/message-error.service';
import { WaiverStatus } from '../../models/waiver-status.model';
import { MatSelect } from '@angular/material/select';
import { environment } from '../../../environments/environment';



@Component({
  selector: 'app-latam-report-base', 
  templateUrl: './latam-report-base.component.html',
  styleUrls: ['./latam-report-base.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LatamReportBaseComponent implements OnInit  {
  @Input() isOutsourcing: boolean;
  marketIds: number[] = [];
  allowedTypes: number[] = [2, 3, 8, 9, 12];
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_WAIVERS, SidebarMenuModel.SUBMENU_LATAM_REPORT);
  waiverStatus:any = [{statusIds: [2, 4], name : 'approved'}, {statusIds: [3, 7] , name : 'denied'}, {statusIds: [5] , name : 'issued'}, {statusIds: [9] , name : 'pending'}, {statusIds: [8] , name : 'pendingAgency'}]
  waiverStatusFilter: number[] = [];
  currentDate: Date = new Date();
  diffDays: number;
  report: DownloadLatamReportCSV[] = [];
  reportFilename: string;
  loading: boolean = false;
  marketOptions: string[];

  progress: number = 0;
  page: number = 0;
  size: number = environment.report.pageSize;
  totalPages: number = 0;

  reportFullyLoaded: boolean = false;
  allMarketSelected = false;
  @ViewChild('marketSelect') marketSelect: MatSelect;


  infoUser$: Promise<SessionInfo> = this.sessionInfoService.getSessionInfoAsPromise();
  reportHeaders: any = [
    { label: 'Id', key: 'id' },
    { label: 'Waiver Category', key: 'waiverCategory' },
    { label: 'Type', key: 'type' },
    { label: 'Status', key: 'status' },
    { label: 'Request Day/Hour', key: 'creationDate' },
    { label: 'BP', key: 'latamUserId' },
    { label: 'Approved/Denied by', key: 'statusUser' },
    { label: 'Status Day/Hour', key: 'statusDate' },
    { label: 'PNR/TKT', key: 'documentNumber' },
    { label: 'POS', key: 'pos' },
    { label: 'Agency Group', key: 'agencyGroup' },
    { label: 'Requested By', key: 'user' },
    { label: 'Flight Date', key: 'departureDate' },
    { label: 'Reason', key: 'motive' },
    { label: 'Remarks', key: 'aditionalComment' },
    { label: 'Status Remarks', key: 'statusComment' },
    { label: 'Agency PNR', key: 'pnrAgency' },
    { label: 'Denial Reason', key: 'rejectionMotive' },
    { label: 'Waiver Record ', key: 'folio' },
    { label: 'Valorization', key: 'valorization' },
    { label: 'TKT', key: 'tkt'},
    { label: 'IATA PNR', key: 'iataPnr' },
    { label: 'IATA OSI', key: 'iataOsi' },
    { label: 'Account Code', key: 'accountCode' },
    { label: 'Branch Office', key: 'branchOffice' },
    { label: 'Tier', key: 'tier' },
    { label: 'Channel', key: 'channel' },
    { label: 'Admin Sales', key: 'adminSales' },
    { label: 'User Sales', key: 'userSales' },
    { label: 'Original Fare', key: 'originalFare' },
    { label: 'Approved Fare', key: 'approvedFare' },
    { label: 'Total Q', key: 'totalQ' },
    { label: 'Discount', key: 'discount' },
    { label: 'OriDes', key: 'oriDes' },
    { label: 'Cabin', key: 'cabin' },
    { label: 'Codeshare', key: 'codeShare' },
    { label: 'Pax', key: 'pax' },
    { label: 'Carrier', key: 'carrier' },
    { label: 'Desired Fare', key: 'desiredFare' },
    { label: 'Request Q', key: 'requestQ' }
  ];
  profiles = new Profile();

  formFilter: UntypedFormGroup;

  waiverTypes: WaiverPresaleType[] = [];
  waiverTypeIds: number[] = [];
  waiverTypesSelected: WaiverPresaleType;

  waiverTypeStatus: WaiverStatus[] = [];
  waiverStatuss: number[] = [];

  agencyGroup: AgencyGroup;
  groupId: number = null;
  filteredAgencyGroups: Observable<AgencyGroup[]>;
  agencyGroupSelected: AgencyGroup;
  selectedMarketAgencyGroups: AgencyGroup[] = [];

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private adapter: DateAdapter<Moment>,
    private formB: UntypedFormBuilder,
    private agencyGroupService: AgencyGroupService,
    private latamReportService: LatamReportService,
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private profilesService: ProfilingService,
    private sessionInfoService: SessionInfoService,
    private sidebarShareDataService: SidebarShareDataService,
    private translateService: TranslateService,
    private waiverPostsaleService: WaiverPostsaleService) {
      this.adapter.setLocale(locale);
  }
 
  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.getWaiverTypes();
    this.formFilter = this.initFormFilters();
    this.formFilter.reset();
    this.getMarkets();
  }

  private initFormFilters(): UntypedFormGroup {
    return this.formB.group({
      markets: [{ value: null }, Validators.required],
      startDate: [{ value: null }, Validators.required],
      endDate: [{ value: null }, Validators.required],
      waiverTypes: [{ value: null, disabled: true }],
      waiverStatus: [{ value: null}],
      groupId: [{ value: null, disabled: true }],
    });
  }

  getMarkets(){
    this.marketIds = []; // Inicializar marketIds vacío
    this.infoUser$
      .then(
        info => {
          this.marketOptions = [...info.market];          
        }
    );
  }
  
  marketSelectionChange(event) {
    if (!event) {
      this.infoUser$
          .then(sessionInfo => {
              const selectedMarkets = this.formFilter.get('markets').value.filter(market => market != 'ALL');
              this.marketIds = selectedMarkets.map((element: any) => sessionInfo.marketId[sessionInfo.market.indexOf(element)]);
              this.getAgencyGroups();
          })
          .catch(error => {
              console.log('error', error);
          });
    }
}

  startDateChanged(startDate: Moment) {
    if (this.formFilter.get('endDate').value && this.marketIds != null && this.marketIds.length > 0) {
      if (startDate) {
        this.diffDays = (this.formFilter.get('endDate').value.toDate().getTime() - startDate.toDate().getTime()) / (24 * 60 * 60 * 1000);
        if (this.diffDays > 365) {
          this.formFilter.get('endDate').setValue(this.adapter.addCalendarYears(startDate, 1));
        }
      }
      this.getReport();
    }
  }

  endDateChanged(endDate: Moment) {
    if (this.formFilter.get('startDate').value && this.marketIds != null && this.marketIds.length > 0) {
      this.getReport();
    }
  }

  /**
   * Métodos para seleccionar tipos de waivers
   */
  getWaiverTypes() {
    this.waiverPostsaleService.getTypes().subscribe({
      next: res => {
        let filteredData = this.filterTypes(res);
        this.waiverTypes = filteredData;
        this.formFilter.get('waiverTypes').enable();
      },
      error: (error: HttpErrorResponse) => {
        console.log('error', error);
      }
  });
  }

  waiverTypeChange() {
    if (this.formFilter.get('waiverTypes').value) {
      this.waiverTypeIds = [];
      this.formFilter.get('waiverTypes').value.forEach((waiver: WaiverPresaleType) => {
        this.waiverTypeIds.push(waiver.id);
      });
      this.getReport();
    }
  }

  waiverStatusType() {
    if (this.formFilter.get('waiverStatus').value) {
      this.waiverStatusFilter = [];
      this.formFilter.get('waiverStatus').value.forEach((waiverStatus: any) => {
        waiverStatus.statusIds.forEach(id => {
          this.waiverStatusFilter.push(id);
        });
      });
      this.getReport();
    }
  }
  

  /**
   * Métodos para cargar los AgencyGroups
   */

  getAgencyGroups() {
    this.formFilter.get('groupId').reset();
    if (this.marketIds && this.marketIds.length != 0) {
      this.selectedMarketAgencyGroups = [];
      this.marketIds.forEach((market: number) => {
        this.loadAgencyGroups(market);
      });
    }
    this.getReport();
  }

  loadAgencyGroups(marketId: number) {
    this.formFilter.get('groupId').disable();
    this.agencyGroupService.getAgencyGroupListByMarket(marketId).subscribe({
      next: data => {
        data.forEach((agencyGroup: AgencyGroup) => {
          this.selectedMarketAgencyGroups.push(agencyGroup);
        });
        this.filteredAgencyGroups = this.formFilter.get('groupId').valueChanges.pipe(
          startWith<string | AgencyGroup>(''),
          map(value => {
            if (value) {
              return typeof value === 'string' ? value : value.name;
            }
          }),
          map(name => name ? this._filter(name) : this.selectedMarketAgencyGroups.slice())
        );
        this.formFilter.get('groupId').enable();
      },
      error: (error: HttpErrorResponse) => {
        console.log('error', error);
      }
  });

    // return loadGroups;
  }

  displayFn(agencyGroup?: AgencyGroup): string | undefined {
    return agencyGroup ? agencyGroup.name : undefined;
  }

  private _filter(name: string): AgencyGroup[] {
    const filterValue = name.toLowerCase();
    return this.selectedMarketAgencyGroups.filter(option => option.name.toLowerCase().startsWith(filterValue));
  }

  onSelectionAgencyGroupChange(event: MatOptionSelectionChange, agencyGroup: AgencyGroup): void {
    if (event.source.selected) {
      this.groupId = agencyGroup.id;
      this.getReport();
    }
  }

  /**
   * Solicitud del reporte
   */
  getReport() {    
    if (this.formFilter.get('startDate').value && this.formFilter.get('endDate').value &&
      this.marketIds != null && this.marketIds.length > 0) {
      const starDate = this.latamReportService.parseDateToUTCDateString(this.formFilter.get('startDate').value.toDate());
      const endDate = this.latamReportService.parseDateToUTCDateString(this.formFilter.get('endDate').value.toDate());
      this.loading = true;


      let reportData: DownloadLatamReportCSV[] = [];
      const fetchPage = () => {
        this.latamReportService.getWaiversByMarketsAndBetweenDates(
          starDate, endDate,this.waiverStatusFilter,
          this.marketIds, this.waiverTypeIds, this.groupId, this.isOutsourcing, this.page, this.size).subscribe({
            next: data => {
              reportData = reportData.concat(data);
              //this.progress = Math.min(100, Math.round(((this.page + 1) / this.totalPages) * 100));        
              if (data.length == 0 && this.page == 0) {
                this.messageService.showWarningMessage(                
                  this.translateService.instant('button.confirm'),
                  this.translateService.instant('notFound.waivers')
                );
                this.reportFullyLoaded = false;
                this.loading = false;
              } else if (data.length > 0){
                  this.page++;
                  fetchPage();
              } else {
                this.report = reportData;
                this.reportFilename = 'waiver_report_' + starDate + '_' + endDate + '.csv';
                this.loading = false;
                this.reportFullyLoaded = true;
                this.progress = 0;
                this.page = 0;
              }
            },
            error: (error: HttpErrorResponse) => {
              this.messageErrorService.sendError(error, 'error.generateReport');
              this.loading = false;
              this.reportFullyLoaded = false;
              console.log('error', error);
            }
        });
      }

      fetchPage();
    }
  }

  /**
   * Ajustes para los Calendarios
   */
  maxStartDate() {
    const endDate = this.formFilter.get('endDate') ? this.formFilter.get('endDate').value : null;
    return (endDate && (endDate.toDate() < this.currentDate)) ? endDate : this.currentDate;
  }

  minEndDate() {
    const startDate = this.formFilter.get('startDate') ? this.formFilter.get('startDate').value : null;
    return startDate;
  }

  maxEndDate() {
    const startDate = this.formFilter.get('startDate') ? this.formFilter.get('startDate').value : null;
    if (startDate && (this.adapter.addCalendarYears(startDate, 1).toDate() < this.currentDate)) {
      return this.adapter.addCalendarYears(startDate, 1);
    } else {
      return this.currentDate;
    }
  }

  setButtonGenerator() {
    const buttonStatus: boolean = (
      (this.formFilter.get('markets') && this.formFilter.get('markets').value && this.formFilter.get('markets').value.length < 1) ||
      (this.formFilter.get('startDate') && !this.formFilter.get('startDate').value) ||
      (this.formFilter.get('endDate') && !this.formFilter.get('endDate').value) ||
      this.report.length === 0 ||
      this.loading
    );

    return buttonStatus;
  }

  setCalendarLanguage() {
    this.adapter.setLocale(localStorage.getItem('language'));
  }

  toggleAllSelection() {
    this.allMarketSelected = !this.allMarketSelected;
    
    if (this.allMarketSelected) {
      this.marketSelect.options.forEach( (item : MatOption) => item.select());
    } else {
      this.marketSelect.options.forEach( (item : MatOption) => item.deselect());
    }
  }

  handleOptionSelection(){
    const allSelect : MatOption = this.marketSelect.options.find((item : MatOption) => item.value === 'ALL');

    this.marketSelect.options.forEach((item : MatOption) => {
      if (item.value !== 'ALL' && !item.selected){
        allSelect.deselect();
        this.allMarketSelected = false;
      }
    });
  }

  private filterTypes(dataToFilter: WaiverPresaleType[]): WaiverPresaleType[] {
    if (this.isOutsourcing){
      return this.filterTypesForOutsourcing(dataToFilter);
    }
    return (this.sessionInfoService.getSessionInfo().role === 'USER_EXT') ? this.temporaryFilter(dataToFilter) : dataToFilter;
  }

  private filterTypesForOutsourcing(dataToFilter: WaiverPresaleType[]): WaiverPresaleType[] {
    return dataToFilter.filter((waiverType) => this.allowedTypes.includes(waiverType.id));
  }

  private temporaryFilter(dataToFilter: WaiverPresaleType[]): WaiverPresaleType[] {
    return dataToFilter.filter((waiverType) => {
      return ('MAX Stay' !== waiverType.name);
    });
  }

}

<div class="modal-content small-modal">
  <div class="modal-header">
    <h5 class="modal-title">{{ 'agencyGroupData' | translate }}</h5>
    <button class="close" (click)="closeDialog()">
      <mat-icon class="close-icon">clear</mat-icon>
    </button>
  </div>

  <div class="modal-body">
    <form #createGroupForm="ngForm">
      <div *ngIf="loading"class="loader loading">
        <div class="loading-div">
        <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
        <span class="loading-span">{{ 'loading' | translate }}</span>
        </div>
      </div>

      <div *ngIf="!loading">
        <div class="row">
          <div class="col-6">
            <mat-form-field>
              <mat-label>{{ 'field.agencyGroupName' | translate }}</mat-label>
              <input matInput id="groupNameInput" name="name" #name="ngModel" [(ngModel)]="group.name" pattern="[A-zÀ-ú:\u3000\u3400-\u4DBF\u4E00-\u9FFF\0-9]+"
                maxlength="25" required>
              <mat-error *ngIf="name.errors && (name.dirty || name.touched) && name.errors.required">
                {{ 'field.error.required' | translate }}</mat-error>
              <mat-error *ngIf="name.errors && (name.dirty || name.touched) && name.errors.pattern">
                {{ 'field.error.invalidData' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-6">
            <mat-form-field>
              <mat-label>{{ 'field.tier' | translate }}</mat-label>
              <mat-select id="agencyTier" name="agencyTier" #agencyTier="ngModel" [(ngModel)]="group.agencyTier" [disabled]="tierList.length === 0" required>
                <mat-option *ngFor="let tier of tierList" [value]="tier">
                  {{ tier.name }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="agencyTier.errors && (agencyTier.dirty || agencyTier.touched) && agencyTier.errors.required">
                {{ 'field.error.required' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <mat-form-field>
              <mat-label>{{ 'field.channel2' | translate }}</mat-label>
              <mat-select id="channelSelect" name="channelSelect" #channelSelect="ngModel"
                [(ngModel)]="group.agencyChannel"
                [disabled]="channelList.length === 0" required>
                <mat-option *ngFor="let channel of channelList" [value]="channel">
                  {{ channel.name }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="channelSelect.errors && (channelSelect.dirty || channelSelect.touched) && channelSelect.errors.required">
                {{ 'field.error.required' | translate }}</mat-error>
            </mat-form-field>
          </div>

          <div class="col-6">
            <mat-form-field>
              <mat-label>{{ 'field.market' | translate }}</mat-label>
              <mat-select id="marketsSelect" name="marketsSelect" #marketsSelect="ngModel" [(ngModel)]="group.market" [disabled]="markets.length === 0" required>
                <mat-option *ngFor="let market of markets" [value]="market">
                  {{ market.name }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="marketsSelect.errors && (marketsSelect.dirty || marketsSelect.touched) && marketsSelect.errors.required">
                {{ 'field.error.required' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <mat-form-field>
              <mat-label>{{ 'field.adminSalesLatam' | translate }}</mat-label>
              <mat-select id="adminSalesSelect" name="adminSalesSelect" [(ngModel)]="group.adminSales" [disabled]="adminSalesList.length === 0">
                <mat-option *ngFor="let adminSales of adminSalesList" [value]="adminSales">
                  {{ adminSales.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-6">
            <mat-form-field>
              <mat-label>{{ 'field.userSalesLatam' | translate }}</mat-label>
              <mat-select id="salesExecutiveSelect" name="salesExecutiveSelect" [(ngModel)]="group.salesExecutive" [disabled]="salesExecutiveList.length === 0">
                <mat-option *ngFor="let salesExecutive of salesExecutiveList" [value]="salesExecutive">
                  {{ salesExecutive.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-6 col-xl-6">
            <mat-form-field>
              <input matInput id="mainIataId" name="mainIataId" #mainIataId="ngModel"
                [(ngModel)]="group.mainIata" placeholder="{{ 'field.iata' | translate }}"
                maxlength="9" pattern="([0-9]{6,7})-([0-9]{1})"  matTooltip="{{ 'field.iata' | translate }}">
                <mat-error *ngIf="mainIataId.errors && (mainIataId.dirty || mainIataId.touched) && mainIataId.errors.pattern">
                  {{ 'field.error.invalidIata' | translate }}
                </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 col-lg-6 col-xl-6">
            <mat-checkbox id="allowPreSaleModuleCheckbox" name="allowPreSaleModuleCheckbox"
              [(ngModel)]="group.allowPreSaleModule">{{ 'field.allowPresaleModule' | translate }}</mat-checkbox>
          </div>

          <div class="col-12 col-md-6 col-lg-6 col-xl-6">
            <mat-checkbox id="allowPostSaleModuleCheckbox" name="allowPostSaleModuleCheckbox"
              [(ngModel)]="group.allowPostSaleModule">{{ 'field.allowPostsaleModule' | translate }}</mat-checkbox>
          </div>

          <div class="col-12 col-md-6 col-lg-6 col-xl-6">
            <mat-checkbox id="allowPrivateFareCheckbox" name="allowPrivateFareCheckbox"
              [(ngModel)]="group.allowPrivateFare">{{ 'field.enablePrivateFare' | translate }}</mat-checkbox>
          </div>
          <div class="col-12 col-md-6 col-lg-6 col-xl-6">
            <mat-checkbox id="selManagedCheckbox" name="selManagedCheckbox"
                [(ngModel)]="group.selfManaged">{{ 'field.selfManaged' | translate }}</mat-checkbox>
          </div>

        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer" *ngIf="profiles.createAgencyGroupManagment && !loading">
    <button mat-raised-button id="closeButton" type="button" class="btn-volver btn-w"
      (click)="closeDialog()">{{ 'button.cancel' | translate }}</button>
    <button mat-raised-button id="createUserButton" [disabled]="createGroupForm.invalid" data-dismiss="modal"
      (click)="saveGroup()" class="background-red btn-w float-right">{{ 'button.create' | translate }}</button>
  </div>
</div>

export class DownloadAgencyReportBase {
  public id: number;
  public status: string;
  public creationDate: Date;
  public statusDate: Date;
  public documentNumber: string;
  public user: string;
  public departureDate: Date;
  public type: string;
  public motive: string;
  public aditionalComment: string;
  public statusComment: string;
  public pnrAgency: string;
  public rejectionMotive: string;
  public folio: string;
  public valorization: number;

  public constructor(
    id: number,
    status: string,
    creationDate: Date,
    statusDate: Date,
    documentNumber: string,
    user: string,
    departureDate: Date,
    type: string,
    motive: string,
    aditionalComment: string,
    statusComment: string,
    pnrAgency: string,
    rejectionMotive: string,
    folio: string,
    valorization: number
  ) {
    this.id = id;
    this.status = status;
    this.creationDate = creationDate;
    this.statusDate = statusDate;
    this.documentNumber = documentNumber;
    this.user = user;
    this.departureDate = departureDate;
    this.type = type;
    this.motive = motive;
    this.aditionalComment = aditionalComment;
    this.statusComment = statusComment;
    this.pnrAgency = pnrAgency;
    this.rejectionMotive = rejectionMotive;
    this.folio = folio;
    this.valorization = valorization;
  }
}

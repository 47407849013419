import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'translateBudgetRowName',
  pure: false
})
export class TranslateBudgetRowNamePipe implements PipeTransform {
  transform(value: string): string {
    const language = localStorage.getItem('language');

    const languageTranslations = {
      'es': this.getSpanishTranslation,
      'en': this.getEnglishTranslation,
      'fr': this.getFrenchTranslation,
      'it': this.getItalianTranslation,
      'de': this.getGermanTranslation,
      'pt': this.getPortugueseTranslation
    };

    const translationFunction = languageTranslations[language] || this.getEnglishTranslation;

    return translationFunction(value);
  }

  getSpanishTranslation(value: string): string {
    return value;
  }

  getEnglishTranslation(value: string): string {
    switch (value) {
      case 'Transferido': return 'Transferred';
      case 'Cargado': return 'Loaded';
      case 'Por distribuir': return 'To be distributed';
      case 'Gastado': return 'Spent';
      case 'Disponible': return 'Available';
    }
  }

  getFrenchTranslation(value: string): string {
    switch (value) {
      case 'Transferido': return 'transféré';
      case 'Cargado': return 'Chargé';
      case 'Por distribuir': return 'À distribuer';
      case 'Gastado': return 'Passé';
      case 'Disponible': return 'Disponible';
    }
  }

  getItalianTranslation(value: string): string {
    switch (value) {
      case 'Transferido': return 'Trasferito';
      case 'Cargado': return 'Caricato';
      case 'Por distribuir': return 'Da distribuire';
      case 'Gastado': return 'Esaurito';
      case 'Disponible': return 'Disponibile';
    }
  }

  getGermanTranslation(value: string): string {
    switch (value) {
      case 'Transferido': return 'Übertragen';
      case 'Cargado': return 'Geladen';
      case 'Por distribuir': return 'Zu verteilen';
      case 'Gastado': return 'Ausgegeben';
      case 'Disponible': return 'Verfügbar';
    }
  }

  getPortugueseTranslation(value: string): string {
    switch (value) {
      case 'Transferido': return 'Transferido';
      case 'Cargado': return 'Carregado';
      case 'Por distribuir': return 'Para ser distribuído';
      case 'Gastado': return 'Gasto';
      case 'Disponible': return 'Disponível';
    }
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';

import { AgencyCreate } from '../../../models/agency-create.model';
import { AgencyDetail } from '../../../models/agency-detail.model';
import { AgencyGroup } from '../../../models/agency-group.model';
import { BusinessException, Exception, SystemException } from '../../../models/Exception.model';
import { Market } from '../../../models/market.model';
import { PiaAgencyType } from '../../../models/pia-agency-type.model';
import { Profile } from '../../../models/profile.model';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';

import { AgencyGroupService } from './../../../services/agency-group.service';
import { AgencyPiaService } from './../../../services/agency-pia.service';
import { AgencyService } from '../../../services/agency.service';
import { MarketService } from './../../../services/market.service';
import { MessageService } from '../../../services/message.service';
import { ProfilingService } from '../../../services/profiling.service';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';
import { AgencyCdp } from '../../../interfaces/agency.interface';
import { MessageErrorService } from '../../../services/message-error.service';


@Component({
  selector: 'app-activate-agency',
  templateUrl: './activate-agency.component.html',
  styleUrls: ['./activate-agency.component.css']
})
export class ActivateAgencyComponent implements OnInit {
  enableActiveButton = false;
  generatePiaCode = false;
  loading = false;
  saving = false;

  officeID: string;
  pcc: string;

  agency: AgencyCdp;
  iataCode: string;
  agencyDetail: AgencyDetail = new AgencyDetail();
  agencyGroupList: AgencyGroup[] = [];
  piaAgencyTypeList: PiaAgencyType[] = [];
  markets: Market[];
  profiles = new Profile();
  selectedPiaAgencyType: PiaAgencyType;
  selectedGroup: AgencyGroup;
  selectedMarket: Market;
  private sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_AGENCIES, SidebarMenuModel.SUBMENU_AGENCY_SEARCH);

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private agencyGroupService: AgencyGroupService,
    private messageErrorService: MessageErrorService,
    private agencyPiaService: AgencyPiaService,
    private agencyService: AgencyService,
    private marketService: MarketService,
    private profilesService: ProfilingService,
    private sidebarShareDataService: SidebarShareDataService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);

    this.iataCode = this.activatedRoute.snapshot.paramMap.get('iataCode');

    if(this.agencyService.agency !== undefined){
      this.agency = this.agencyService.agency;
      this.loading = false;
      this.enableActiveButton = true;
    } else{
      this.getAgencyByIata();
    }

    this.getMarkets();
    this.getAgencyPiaTypesList();
  }

  async getAgencyByIata(){
    const getAgencyByIataPromise = this.agencyService.getAgencyByIata(this.iataCode).toPromise();

    await getAgencyByIataPromise
    .then(agency =>{
        this.agency = agency
        this.loading = false;
        this.enableActiveButton = true;
    }).catch(
      (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.loadingAgency');

        this.loading = false;
        console.log('error', error);
      }
    )
  }



  private getMarkets(): void {
    this.marketService.getMarkets().subscribe({
      next: data => {
        this.markets = data;
      },
      error: (error: HttpErrorResponse) => {
        console.log('error', error);
      }
  });
  }

  private getAgencyPiaTypesList(): void {
    this.agencyPiaService.getAgencyPiaTypesList().subscribe({
      next: data => {
        if (data) {
          this.piaAgencyTypeList = data;
        } else {
          this.piaAgencyTypeList = [];
        }
      },
      error: (error: HttpErrorResponse) => {
        console.log('error', error);
      }
  });
  }

  getAgencyGroupList(marketId: number): void {
    this.selectedGroup = null;
    this.agencyGroupList = [];

    this.agencyGroupService.getAgencyGroupListByMarket(marketId).subscribe({
      next: data => {
        this.agencyGroupList = data;
      },
      error: (error: HttpErrorResponse) => {
        console.log('error', error);
      }
  });
  }

  returnToSearch(): void {
    this.router.navigate(['./../../search'], { relativeTo: this.activatedRoute });
  }

  private createAgency(): void {
    const marketAgency = this.markets.find(market => market.name === this.agency.address.country);
    this.saving = true;
    let agencyCreate: AgencyCreate = new AgencyCreate();
    agencyCreate.name = this.agency.agencyData.tradeName ? this.agency.agencyData.tradeName : this.agency.agencyData.legalName;
    agencyCreate.market = marketAgency ? marketAgency : this.selectedMarket;
    agencyCreate.country = this.agency.address.country;
    agencyCreate.iata = this.iataCode;
    agencyCreate.city = this.agency.address.city ? this.agency.address.city : this.agency.address.address;
    agencyCreate.executive = this.agency.agencyData.legalName;
    agencyCreate.agencyBranch = Number(this.agency.agencyData.taxId);
    agencyCreate.agencyCode = null;
    agencyCreate.officeId = this.officeID;
    agencyCreate.pcc = this.pcc;
    agencyCreate.agencyGroup = this.selectedGroup;
    agencyCreate.piaAgencyType = this.selectedPiaAgencyType ? this.selectedPiaAgencyType.code : null;
    agencyCreate.agencyId = this.agency.agencyId.agencyId;


    this.agencyService.createAgency(agencyCreate)
      .subscribe({
        next: data => {
          this.saving = false;
          this.showSuccessMessage();
        },
        error: (error: HttpErrorResponse) => {
          this.saving = false;
          this.messageErrorService.sendError(error, 'error.activateAgency');

          console.log('error', error);
        }});
  }

  /**
   * shows a message of success
   */
  private showSuccessMessage(): void {
    swal.fire({
      title: `<p>${ this.translateService.instant('popup.wellDone') }</p>`,
      html: `<p>${ this.translateService.instant('success.activateAgency') }</p>`,
      confirmButtonText: this.translateService.instant('button.confirm'),
      confirmButtonColor: '#d33',
      icon: 'success',
      allowOutsideClick: false
    }).then((result) => {
      this.returnToSearch();
    });
  }

  checkedPia(event: MatCheckboxChange): void {
    if (!event.checked) {
      this.selectedPiaAgencyType = null;
    }
  }

  activateAgency(): void {
    if (this.generatePiaCode) {
      this.showQuestionMessage();
    } else {
      this.createAgency();
    }
  }

  private showQuestionMessage(): void {
    swal.fire({
      html: `<p>${ this.translateService.instant('warning.generatePiaCode') }</p>`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('button.yes'),
      cancelButtonText: this.translateService.instant('button.cancel'),
      reverseButtons: true,
      customClass: {
        confirmButton: 'confirm-button',
        cancelButton: 'cancel-button'
      }
    }).then((result) => {
      if (result.value) {
        this.createAgency();
      }
    });
  }
}

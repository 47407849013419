import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {mergeMap} from 'rxjs/operators';
import { Observable, from } from 'rxjs';
import { environment } from '../../environments/environment';
import { StatusTicket } from '../models/status-ticket.model';
import { SessionInfoService } from './session-info.service';
import { StorageService } from './storage.service';

@Injectable()
export class StatusTicketService {
  urlBase = `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlStatusticket}`;

  constructor(
    private http: HttpClient,
    private sessionInfoService: SessionInfoService) { }

  getStatusTicket(stNumber: number, dateTicket: String): Observable<StatusTicket[]> {
    return from(this.sessionInfoService.getSessionInfoAsPromise()).pipe(mergeMap(
      session => {
        let url = `${this.urlBase}/${stNumber}/${dateTicket}`;
        if (session.matrix_id !== undefined && session.marketId !== undefined) {
          url = `${this.urlBase}/${stNumber}/${dateTicket}?groupId=${session.matrix_id}&marketId=${session.marketId}`;
        }
        return this.http.get<StatusTicket[]>(url);
      }
    ));

  }

  getStatusTicketByPnr(pnrCode: string): Observable<StatusTicket[]> {
    return this.http.get<StatusTicket[]>(`${this.urlBase}/pnr/${pnrCode}`);
  }
}

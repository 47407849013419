<div class="search-main-container">
  <a (click)="newRequest()" class="pointer">
      <span class="go-back centered"><mat-icon>arrow_back_ios</mat-icon> {{ 'button.back' | translate}}</span>
  </a>
  <div class="info">
      <img  src="assets/images/waiver-latam/successflytickets.svg" alt="success-img"/>
      <br>

      <h3 *ngIf="!sessionInfo.isSelfManaged && dataWaiverResult.data[0]?.status?.id == 1">
        {{ 'waiverLatam.pendingPolicy' | translate}}
      </h3>

      <h2 *ngIf="!sessionInfo.isSelfManaged && dataWaiverResult.data[0]?.status?.id == 2">
          {{ 'waiverLatam.approvedRequest' | translate}}
      </h2>
      <h2 *ngIf="sessionInfo.isSelfManaged">
          {{ 'success.waiverCreated' | translate}}
      </h2>
  </div>

  <span class="subtitle-waiver">
      <img src="assets/images/waiver-latam/fares.svg" alt="fares-img"/>
      {{ 'waiverLatam.waiverDetail' | translate }}
  </span>
  <div class="info-container">
      <ng-container>
              <mat-table [dataSource]="dataWaiverResult" #table >
                  <ng-container matColumnDef="id" data-test="id-column">
                      <mat-header-cell *matHeaderCellDef>{{ 'field.idRequest' | translate }}</mat-header-cell>
                      <mat-cell *matCellDef="let data; let i = index">{{ data.id | dashWhenEmpty }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="waiverPos" data-test="pos-column">
                      <mat-header-cell *matHeaderCellDef>POS</mat-header-cell>
                      <mat-cell *matCellDef="let data; let i = index">{{ data?.market?.name }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="agencyName" data-test="agency-column">
                      <mat-header-cell *matHeaderCellDef>{{ 'field.agency' | translate }}</mat-header-cell>
                      <mat-cell *matCellDef="let data; let i = index">{{ data?.agencyGroup?.name }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="iata" data-test="iata-column">
                      <mat-header-cell *matHeaderCellDef>{{ 'field.iata' | translate }}</mat-header-cell>
                      <mat-cell *matCellDef="let data; let i = index">{{ data?.documentIata }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="userName" data-test="user-column">
                      <mat-header-cell *matHeaderCellDef>{{ 'field.executiveSale' | translate }}</mat-header-cell>
                      <mat-cell *matCellDef="let data; let i = index">{{ data?.user?.name + ' ' +  data.user.lastname | dashWhenEmpty }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="waiverType" data-test="type-column">
                      <mat-header-cell *matHeaderCellDef>{{ 'field.type' | translate }}</mat-header-cell>
                      <mat-cell *matCellDef="let data; let i = index">{{ data?.type.name | dashWhenEmpty }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="documentNumber" data-test="document-column">
                      <mat-header-cell *matHeaderCellDef>{{ 'field.pnrTkt' | translate }}</mat-header-cell>
                      <mat-cell *matCellDef="let data; let i = index">{{ data?.documentNumber | dashWhenEmpty }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="statusDate" data-test="status-date-column">
                      <mat-header-cell *matHeaderCellDef>{{ 'field.approvalDateHour' | translate }}</mat-header-cell>
                      <mat-cell *matCellDef="let data; let i = index">{{ data?.statusDate | dateFormat: 'DD-MMM-YY' | uppercase | dashWhenEmpty}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="flightDate" data-test="flight-date-column">
                      <mat-header-cell *matHeaderCellDef>{{ 'field.departureDate' | translate }}</mat-header-cell>
                      <mat-cell *matCellDef="let data; let i = index">{{ data?.segments[0]?.departureDateTime | dateFormat: 'DD-MMM-YY' | uppercase | dashWhenEmpty }}</mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="displayedColumnsWaiverResult" class="waiver-post-row"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumnsWaiverResult;"></mat-row>

              </mat-table>
      </ng-container>
  </div>
</div>

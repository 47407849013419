import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { AgencyGroupInterface } from '../interfaces/agency-group.interface';
import { AgencyTier } from './../models/agency-tier.model';

import { AgencyGroup } from '../models/agency-group.model';
import { UserSales } from '../models/user-sales.mode';

import { RouteLocaleExtractorService } from './route-locale-extractor.service';
import { SessionInfoService } from './session-info.service';
import { StorageService } from './storage.service';


@Injectable()
export class AgencyGroupService {
  headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  //urlBase = `http://localhost:9090/http://localhost:8065/agency`;
  urlBase = `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlAgency}`;

  constructor(
    private http: HttpClient,
    private routeLocaleExtractorService: RouteLocaleExtractorService,
    private sessionInfoService: SessionInfoService) { }

  getAdminSalesList(): Observable<UserSales[]> {
    const locale = this.routeLocaleExtractorService.getLocale();
    const roleDescription = 'Admin Sales';

    return this.http.get<UserSales[]>(`${this.urlBase}${locale}/latam/sale/users?roleDescription=${roleDescription}`);
  }

  getUserSalesList(): Observable<UserSales[]> {
    const locale = this.routeLocaleExtractorService.getLocale();
    const roleDescription = 'User Sales';

    return this.http.get<UserSales[]>(`${this.urlBase}${locale}/latam/sale/users?roleDescription=${roleDescription}`);
  }


  /**
   * Crea un grupo Agencia
   * @param agencyGroup
   * @returns `Observable<AgencyGroup>`
   */
  createAgencyGroup(agencyGroup: AgencyGroup): Observable<AgencyGroup> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.post<AgencyGroup>(`${this.urlBase}${locale}/agencies-groups`, agencyGroup, { headers: this.headers });
  }

  /**
   * Retorna la cantidad de grupos agencia existentes
   * @returns `Observable<number>`
   */
  countAgencyGroup(): Observable<number> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<number>(`${this.urlBase}${locale}/agencies-groups/count`);
  }

  /**
   * Obtiene la informacion de una agencia
   * @param id identificador de la agencia
   * @returns `Observable<AgencyGroup>`
   */
  getAgencyGroupById(id: number): Observable<AgencyGroup> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<AgencyGroup>(`${this.urlBase}${locale}/agencies-groups/${id}`);
  }

  /**
   * Obtiene los grupos agencia
   * @param pageNumber numero de pagina
   * @param pageSize cantidad de registros por pagina
   * @param sortColumn columna por la que se ordenaran los datos
   * @param sortOrder orden de los datos (asc o desc)
   * @returns `Observable<AgencyGroupInterface[]>`
   */
  getAgencyGroupList(
    pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string): Observable<AgencyGroupInterface[]> {
    const locale = this.routeLocaleExtractorService.getLocale();
    const sessionInfo = this.sessionInfoService.getSessionInfo();

    let url = '';

    if (sessionInfo.role === 'ADMIN_SALES' || sessionInfo.role === 'USER_SALES') {
      url = `${this.urlBase}${locale}/agencies-groups/listByUserSales`;
    } else {
      url = `${this.urlBase}${locale}/agencies-groups`;
    }

    return this.http.get<AgencyGroupInterface[]>(url, {
      params: new HttpParams()
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
        .set('sortColumn', sortColumn)
        .set('sortOrder', sortOrder)
    });
  }

  /**
   * Obtiene los grupos agencia
   * @param `number` marketId
   * @returns `Observable<AgencyGroup[]>`
   */
  getAgencyGroupListByMarket(marketId: number): Observable<AgencyGroup[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<AgencyGroup[]>(`${this.urlBase}${locale}/agencies-groups/listByMarket?marketId=${marketId}`);
  }

  /**
   * Obtiene el listado de tiers
   * @returns `Observable<AgencyTier[]>`
   */
  getTierList(): Observable<AgencyTier[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<AgencyTier[]>(`${this.urlBase}${locale}/agencies-groups/tiers`)
      .pipe(
        map(
          data => {
            return data['tiers'];
          }
        )
      )
  }

  updateAgencyGroup(id: number, agencyGroup: AgencyGroup): Observable<AgencyGroup> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.put<AgencyGroup>(`${this.urlBase}${locale}/agencies-groups/${id}`, agencyGroup, { headers: this.headers });
  }

  /**
   * Actualiza el flag que muestra/oculta el presupuesto a una agencia
   * @param agencyGroup
   * @param budgetTypeId
   * @returns `Observable<AgencyGroup>`
   */
  updateShowBudgetAgencyGroup(agencyGroup: AgencyGroup, budgetTypeId: number): Observable<AgencyGroup> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.put<AgencyGroup>(
      `${this.urlBase}${locale}/agencies-groups/show-budget?budgetTypeId=${budgetTypeId}`,
      agencyGroup,
      { headers: this.headers }
    );
  }

  /**
   * Guarda cambios de notificaciones de waivers por agencia de los usuarios latam
   * @param agencyId
   * @param status
   */
  updateWaiverNotifications(agencyGroup: AgencyGroupInterface): Observable<AgencyGroupInterface> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<AgencyGroupInterface>(`${this.urlBase}${locale}/agencies-groups/notifications?agencyId=${agencyGroup.id}&status=${!agencyGroup.notification}`);
  }
}

<ng-container *ngIf="infoUser$ | async as infoUser">
  <div class="container">
    <div class="row">
      <div class="col">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="latam-sans breadcrumb-item">
              <a>{{ 'menu.waivers' | translate }}</a>
            </li>
            <li class="breadcrumb-item latam-sans active">
              <a>{{ 'waiverReport' | translate }}</a>
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <span class="title" id="reportTitle">{{ 'waiverReport' | translate }}</span>
      </div>
    </div>

    <hr>

    <div class="row mt-3">
      <div class="col">
        <span class="subtitle text-break">{{ 'selectPOS' | translate }}</span>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <form [formGroup]="formFilter">
          <div class="row">
            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-form-field>
                <mat-label>{{ 'field.pos' | translate }}</mat-label>
                <mat-select #marketSelect formControlName="markets"
                  (openedChange)="marketSelectionChange($event)" required multiple>
                  <mat-option [value]="'ALL'" (click)="toggleAllSelection()">{{ 'all' | translate }}</mat-option>
                  <mat-option *ngFor="let market of marketOptions" [value]="market" (click)="handleOptionSelection()" class="select-pos">{{ market }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-form-field>
                <mat-label>{{ 'field.rangeFrom' | translate }}</mat-label>
                <input id="startDateInput" matInput formControlName="startDate" [max]="maxStartDate()"
                  [matDatepicker]="startPicker" name="startDate"
                  required readonly (dateChange)="startDateChanged($event.value)">
                <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                <mat-datepicker #startPicker (opened)="setCalendarLanguage()"></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-form-field>
                <mat-label>{{ 'field.rangeTo' | translate }}</mat-label>
                <input id="endDateInput" matInput formControlName="endDate" [min]="minEndDate()" [max]="maxEndDate()"
                  [matDatepicker]="endPicker" name="endDate" required
                  readonly (dateChange)="endDateChanged($event.value)">
                <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                <mat-datepicker #endPicker (opened)="setCalendarLanguage()"></mat-datepicker>
              </mat-form-field>
            </div>          

            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-form-field>
                <mat-label>{{ 'field.waiverType' | translate }}</mat-label>
                <mat-select formControlName="waiverTypes"
                  (openedChange)="waiverTypeChange()" multiple>
                  <mat-option *ngFor="let waiverType of waiverTypes" [value]="waiverType">
                    <div id="{{ waiverType.code }}">{{ waiverType.name }}</div>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-form-field>
                <mat-label>{{ 'field.agencyGroup' | translate }}</mat-label>
                <input matInput formControlName="groupId" type="text" [matAutocomplete]="agencyGroupAutoComplete">
                <mat-autocomplete #agencyGroupAutoComplete="matAutocomplete" [displayWith]="displayFn">
                  <mat-option (onSelectionChange)="onSelectionAgencyGroupChange($event, agencyGroup)"
                    *ngFor="let agencyGroup of filteredAgencyGroups | async" [value]="agencyGroup">
                    {{ agencyGroup.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-form-field>
                <mat-label>{{ 'field.status' | translate }}</mat-label>
                <mat-select formControlName="waiverStatus"
                  (openedChange)="waiverStatusType()" multiple>
                  <mat-option *ngFor="let waiverStatus of waiverStatus" [value]="waiverStatus">
                    {{ waiverStatus.name | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div *ngIf="profiles.readReports" class="row mt-4 justify-content-center">
            <div *ngIf="loading" class="col-11 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <div class="generate-report">
                <span>
                  <p>{{'info.generatingReport' | translate}}</p>
                </span>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
              </div>
            </div>

            <div *ngIf="reportFullyLoaded && !loading" class="col-11 col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <a mat-button csvLink 
                [headers]="reportHeaders" 
                [delimiter]="';'" 
                [filename]="reportFilename"
                [data]="report" [disabled]="setButtonGenerator()"
                class="mat-raised-button background-red w-100"
              >
                {{ 'button.download' | translate }}
              </a>
            </div>

          </div>
        </form>
      </div>
    </div>
  </div>

</ng-container>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'arrayToString'})
export class ArrayToStringPipe implements PipeTransform {
  transform(value: string[]): any {
    if (!value) {
      return value;
    }

    if (Array.isArray(value)) {
      return value.join(', ');
    }

    return value;
  }
}

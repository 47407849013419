<ng-container *ngIf="infoUser$ | async as infoUser">
  <div class="container">
    <div class="row">
      <div class="col">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="latam-sans breadcrumb-item">
              <a>{{ 'menu.agenciesManagement' | translate }}</a>
            </li>
            <li class="breadcrumb-item latam-sans active" aria-current="page">
              <a>{{ 'menu.agencyList' | translate }}</a>
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <span id="registerAgencyTitle" class="title">{{ 'menu.agencyList' | translate }}</span>
      </div>
    </div>

    <hr>

    <div class="row justify-content-start">
      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <button mat-raised-button *ngIf="profiles.readActivateAgency" id="activateAgency" (click)="searchAgency()"
          class="background-red w-100">{{ 'menu.activateAgency' | translate }}</button>
      </div>

      <div class="w-100 new-line"></div>

      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 offset-lg-3 offset-xl-3">
        <button mat-raised-button *ngIf="profiles.downloadAgencyListLT" id="downloadAgenciesList"
          [disabled]="downloadAgenciesList" (click)="downloadAgenciesListCSV()" class="background-red w-100">
          {{ 'button.downloadAgenciesList' | translate }}
          <span *ngIf="downloadAgenciesList" class="spinner-border spinner-border-sm"></span>
          <ng-container *ngIf="!downloadAgenciesList && downloadAgenciesListSuccessfully">
            <mat-icon class="icon-green">check</mat-icon>
          </ng-container>
        </button>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <button mat-raised-button
          *ngIf="infoUser.isSuperAdmin() || infoUser.isAdminUserLatam() || infoUser.isAdminPiaUserLatam()"
          id="createUserLatamBtn" [disabled]="downloadAgenciesUserList" (click)="downloadAgenciesUserListCSV()"
          class="background-red w-100">
          {{ 'button.downloadAgenciesUserList' | translate }}
          <span *ngIf="downloadAgenciesUserList" class="spinner-border spinner-border-sm"></span>
          <ng-container *ngIf="!downloadAgenciesUserList && downloadAgenciesUserListSuccessfully">
            <mat-icon class="icon-green">check</mat-icon>
          </ng-container>
        </button>
      </div>
    </div>

    <div *ngIf="loading" class="loading">
      <div class="loading-div">
        <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
        <span class="loading-span">{{ 'loading' | translate }}</span>
      </div>
    </div>

    <div *ngIf="!loading" class="table-agencies mt-3 mb-3">
      <mat-table matSort #table [dataSource]="dataSource" class="scrolling">
        <ng-container matColumnDef="name">
          <mat-header-cell id="agencyNameColumnHeader" *matHeaderCellDef class="title">
            <div>
              {{ 'field.agencyName' | translate }}
              <span mat-sort-header></span>
            </div>
            <div class="div-filter" *ngIf="!noAgencies">
              <mat-form-field class="filter">
                <input matInput [formControl]="nameFilter">
              </mat-form-field>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.name | capitalize | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="iata">
          <mat-header-cell id="iataColumnHeader" *matHeaderCellDef class="title">
            <div>
              {{ 'field.iata' | translate }}
              <span mat-sort-header></span>
            </div>
            <div class="div-filter" *ngIf="!noAgencies">
              <mat-form-field class="filter">
                <input matInput [formControl]="iataFilter">
              </mat-form-field>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.iata | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="tier">
          <mat-header-cell id="tierColumnHeader" *matHeaderCellDef class="title">
            <div>
              {{ 'field.tier' | translate }}
              <span mat-sort-header></span>
            </div>
            <div class="div-filter" *ngIf="!noAgencies">
              <mat-form-field class="filter">
                <input matInput [formControl]="tierFilter">
              </mat-form-field>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.agencieTierName | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="city">
          <mat-header-cell id="cityColumnHeader" *matHeaderCellDef class="title">
            <div>
              {{ 'field.city' | translate }}
              <span mat-sort-header></span>
            </div>
            <div class="div-filter" *ngIf="!noAgencies">
              <mat-form-field class="filter">
                <input matInput [formControl]="cityFilter">
              </mat-form-field>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.city | capitalize | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="executive">
          <mat-header-cell id="executiveColumnHeader" *matHeaderCellDef class="title">
            <div>
              {{ 'field.accountManager' | translate }}
              <span mat-sort-header></span>
            </div>
            <div class="div-filter" *ngIf="!noAgencies">
              <mat-form-field class="filter">
                <input matInput [formControl]="executiveFilter">
              </mat-form-field>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.executive | capitalize | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="marketName">
          <mat-header-cell id="posHeader" *matHeaderCellDef class="title">
            <div>
              {{ 'field.pos' | translate }}
              <span mat-sort-header></span>
            </div>
            <div class="div-filter" *ngIf="!noAgencies">
              <mat-form-field class="filter">
                <input matInput [formControl]="marketNameFilter">
              </mat-form-field>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.marketName | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell id="activeColumnHeader" *matHeaderCellDef class="title">
            <div>
              {{ 'field.status' | translate }}
              <span mat-sort-header></span>
            </div>
            <div class="div-filter" *ngIf="!noAgencies">
              <mat-form-field class="filter">
                <mat-select [formControl]="statusSelected">
                  <mat-option value='0'>{{ 'field.inactive' | translate }}</mat-option>
                  <mat-option value='1'>{{ 'field.active' | translate }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{ element.status, select, 1 { {{ 'field.active' | translate }} } 0 {
            {{ 'field.inactive' | translate }} } }</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell class="title" *matHeaderCellDef>{{ 'button.actions' | translate }}
            <div class="div-filter" *ngIf="!noAgencies">
              <button mat-icon-button matTooltip="{{ 'button.search' | translate }}" (click)='searchAgencies()'>
                <mat-icon>search</mat-icon>
              </button>
              <button mat-icon-button matTooltip="{{ 'button.clear' | translate }}" (click)='refreshAgencies()'>
                <mat-icon>refresh</mat-icon>
              </button>
            </div>
          </mat-header-cell>

          <mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="false" class="action-icon">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item *ngIf="profiles.readAgencyRegister && element.status" class="btn-mat-menu">
                <span class="item-mat-menu"
                  (click)="profileAgency(element.id, element.status)">{{ 'button.view' | translate }}</span>
              </button>
              <button mat-menu-item *ngIf="profiles.updateAgencyRegister && !element.status" class="btn-mat-menu">
                <span class="item-mat-menu"
                  (click)="reactivateAgency(element.id)">{{ 'button.reactivate' | translate }}</span>
              </button>
              <button mat-menu-item *ngIf="profiles.deleteAgencyRegister && element.status" class="btn-mat-menu">
                <span class="item-mat-menu"
                  (click)="deactivateAgency(element.id)">{{ 'button.deactivate' | translate }}</span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="no-data-found">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell class="no-data-found-row" *matCellDef="let item">{{ 'error.notAgencyFound' | translate }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="loading-data-error">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell class="loading-data-error-row" *matCellDef="let item">
            <span class="cdk-visually-hidden" #translatedTooltipMessage>{{ 'button.refresh' | translate }}</span>
            <button mat-icon-button (click)="refreshAgencies()" class="refresh-spinner">
              <mat-icon [matTooltip]="translatedTooltipMessage.innerHTML" [matTooltipPosition]="'right'">refresh
              </mat-icon>
            </button>
            <br>
            <span>{{ 'error.loadingUsers' | translate }}</span>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
          [ngClass]="{ 'highlight': selectedRowIndex === i}" (mouseout)="highlightRow($event, i)"
          (mouseover)="highlightRow($event, i)" class="register-agency-row"></mat-row>
        <mat-row *matRowDef="let row; columns: ['no-data-found']; when: noAgenciesDataFound"></mat-row>
        <mat-row *matRowDef="let row; columns: ['loading-data-error']; when: loadingAgenciesDataError"></mat-row>
      </mat-table>

      <ng-container *ngIf="agenciesDataFound && !loadAgenciesNoDataError">
        <mat-paginator [pageSizeOptions]="[10, 30, 50]" [showFirstLastButtons]="false" (page)="pageEvent($event)">
        </mat-paginator>
      </ng-container>
    </div>
  </div>
</ng-container>
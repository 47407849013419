import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatBotUtilService {

  chatProperties: any = environment.chatProperties;

  generateChatScriptV2(language: string, market: string){
    return `var initESW = function(gslbBaseURL) {
       embedded_svc.settings.displayHelpButton = true; //Or false
       embedded_svc.settings.language = '${language}'; //For example, enter 'en' or 'en-US'
       
       // Settings for Chat (images)
       embedded_svc.settings.waitingStateBackgroundImgURL = "assets/images/img-waiting.gif"
       embedded_svc.settings.avatarImgURL = "assets/images/logo-chatbot-40px.png"
       embedded_svc.settings.chatbotAvatarImgURL = "assets/images/logo-chatbot-40px.png"
       embedded_svc.settings.smallCompanyLogoImgURL = "assets/images/logo-chatbot-40px.png"
   
       let languageName;
       let firstName;
       let lastName;
       let email;
 
       if ('${language}' == 'pt-br') {
         embedded_svc.settings.defaultMinimizedText = 'Fale com a gente';
         embedded_svc.settings.loadingText = 'Conectando...';
         embedded_svc.settings.prechatBackgroundImgURL = "assets/images/chat-bot/PT.png";
         languageName = 'Portugues';
         firstName = 'Primeiro Nome';
         lastName = 'Sobrenome';
         email = 'Email';
       } else if ('${language}' == 'en-US') {
         embedded_svc.settings.defaultMinimizedText = 'Chat with us';
         embedded_svc.settings.loadingText = 'Connecting...';
         embedded_svc.settings.prechatBackgroundImgURL = "assets/images/chat-bot/EN.png";
         languageName = 'Ingles';
         firstName = 'First Name';
         lastName = 'Last Name';
         email = 'Email';
       } else if ('${language}' == 'de') {
         embedded_svc.settings.defaultMinimizedText = 'Chatten Sie mit uns';
         embedded_svc.settings.loadingText = 'Verbinden...';
         embedded_svc.settings.prechatBackgroundImgURL = "/assets/images/chat-bot/DE.png";
         languageName = 'Aleman';
         firstName = 'Vorname';
         lastName = 'Nachname';
         email = 'E-Mail';
       } else if ('${language}' == 'fr') {
         embedded_svc.settings.defaultMinimizedText = 'Chat avec nouz';
         embedded_svc.settings.loadingText = 'Connectant...';
         embedded_svc.settings.prechatBackgroundImgURL = "assets/images/chat-bot/FR.png";
         languageName = 'Frances';
         firstName = 'Prénom';
         lastName = 'Nom';
         email = 'Adresse e-mail';
       } else if ('${language}' == 'it') {
         embedded_svc.settings.defaultMinimizedText = 'Chatta con noi';
         embedded_svc.settings.loadingText = 'Collegamento...';
         embedded_svc.settings.prechatBackgroundImgURL = "assets/images/chat-bot/IT.png";
         languageName = 'Italiano';
         firstName = 'Nome';
         lastName = 'Cognome';
         email = 'Email';
       } else {
         embedded_svc.settings.defaultMinimizedText = 'Habla con nosotros';
         embedded_svc.settings.loadingText = 'Conectando...';
         embedded_svc.settings.prechatBackgroundImgURL = "assets/images/chat-bot/ES.png";
         languageName = 'Espanol';
         firstName = 'Nombre';
         lastName = 'Apellidos';
         email = 'Correo electrónico';
       }
     
       embedded_svc.settings.prepopulatedPrechatFields = {
               AMT_NumeroIata__c: "0",
               AMT_POS__c: '${market}',//Se debe reemplazar el valor entre comillas por uno de los siguientes valores en funci?n del sitio:
               AMT_Language__c: languageName
           }; //Sets the auto-population of pre-chat form fields
   
       embedded_svc.settings.enabledFeatures = ['LiveAgent'];
       embedded_svc.settings.entryFeature = 'LiveAgent';
           
           embedded_svc.addEventHandler("afterMaximize", function(data) {
               document.querySelector('.AMT_POS__c').parentNode.style.display = 'none';
               document.querySelector('.AMT_Language__c').parentNode.style.display = 'none';
           });
           
           embedded_svc.settings.extraPrechatFormDetails = 
           
       [
         {
           "label": firstName,
           "transcriptFields": ["AMT_ContactoPrimerNombre__c"]
         },
         {
           "label": lastName,
           "transcriptFields": ["AMT_ContactoApellido__c"]        
         },
         {
           "label": email,
           "transcriptFields": ["AMT_ContactoCorreo__c"]        
         },
         {
           "label": "IATA",
           "transcriptFields": ["AMT_ContactoIata__c"]        
         },
         {
           "label": "POS",
           "transcriptFields": ["AMT_POS__c"]      
         },
         {
           "label": "Language",
           "transcriptFields": ["AMT_ContactoIdioma__c"]      
         }
       ];
   
       embedded_svc.init(
         '${this.chatProperties.baseUrl}',
         '${this.chatProperties.liveAgentUrl}',
         gslbBaseURL,
         '${this.chatProperties.chatId}',
         'AMT_LatamAirlinesNewBot',
         {
           baseLiveAgentContentURL: '${this.chatProperties.baseLiveAgentContentUrl}',
           deploymentId: '${this.chatProperties.deploymentId}',
           buttonId: '${this.chatProperties.buttonId}',
           baseLiveAgentURL: '${this.chatProperties.baseLiveAgentUrl}',
           eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04IDU000000CakJ2AS_186b78285e2',
           isOfflineSupportEnabled: false
         }
       );
     };
   
     if (!window.embedded_svc) {
       var s = document.createElement('script');
       s.setAttribute('src', 'https://latamneworg.my.salesforce.com/embeddedservice/5.0/esw.min.js');
       s.onload = function() {
         initESW(null);
       };
       document.body.appendChild(s);
     } else {
       initESW('https://service.force.com');
     }`;
   }

  loadCss(): string {
    return `
    .embeddedServiceHelpButton .helpButton .uiButton {
        background-color: #1b0088;
        /*font-family: "Arial", sans-serif;*/
        font-family: "Latam Sans", sans-serif;
        width: 70px !important;
        min-width: 70px !important;
        height: 70px !important;
        background-color: transparent !important;
        background-image: url(assets/images/logo-chatbot.png) !important;
        background-size: contain !important;
        display: inline-block;
        box-shadow: 0 4px 4px 0 transparent !important;
        border-radius: 40px 40px 40px 40px !important;
    }

    .embeddedServiceHelpButton .helpButton .helpButtonEnabled:hover::before,
    .embeddedServiceHelpButton .helpButton .helpButtonEnabled:focus::before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /*border-radius: 23px;*/
        background-color: transparent !important;
        pointer-events: none !important;
    }

    .embeddedServiceHelpButton .helpButton .uiButton:hover::before,
    .embeddedServiceHelpButton .helpButton .uiButton:focus::before {
        border-radius: 8px 8px 0 0
    }

    .embeddedServiceHelpButton .embeddedServiceIcon {
        display: none !important;
        position: absolute;
        z-index: 1;
        background-color: #1B0088;
        padding: 2px;
        border-top-left-radius: 8px;
        display: inline-block;
        margin-right: 10px;
        line-height: 1;
        vertical-align: top;
        right: 167px;
    }

    .embeddedServiceHelpButton .uiButton .helpButtonLabel {
        position: fixed;
        background-color: #1B0088;
        padding: 6px;
        right: 85px;
        bottom: 8%;
        font-size: 20px;
        z-index: 1;
        border-radius: 9px 9px 9px 9px !important;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -webkit-align-self: stretch;
        -ms-flex-item-align: stretch;
        align-self: stretch;
        max-width: 100%;
        line-height: normal;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .embeddedServiceHelpButton .uiButton .helpButtonLabel .message {
        font-weight: 600;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .embeddedServiceHelpButton .helpButton .uiButton:focus {
        /*outline: 1px solid #005290;*/
        outline: 1px solid #1b0088;
    }

    .embeddedServiceLiveAgentStateChatItem.chatMessage {
        height: auto !important;
    }

    .embeddedServiceHelpButton .helpButton {
        position: fixed;
        bottom: 9.4%;
    }

    .modalContainer .sidebarMaximized .layout-docked .embeddedServiceSidebar .dockableContainer .showDockableContainer {
        margin-bottom: 4%;
    }
    `;
  }
}

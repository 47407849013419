<ng-container *ngIf="infoUser$ | async as infoUser">
  <div class="container">
    <div class="row">
      <div class="col">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="latam-sans breadcrumb-item">
              <a>{{ 'menu.latamManagement' | translate }}</a>
            </li>
            <li class="breadcrumb-item latam-sans active" aria-current="page">
              <a>{{ 'menu.budgetManagement' | translate }}</a>
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-xl-5">
        <span id="budgetmanagementTitle" class="title">{{ 'menu.latamBudgetManagement' | translate }}</span>
      </div>
      <div class="col-5 col-sm-2 col-md-1 col-lg-1 col-xl-1 text-align">
        <span class="txt-pos">{{ 'field.country' | translate }}</span>
      </div>

      <div class="col-7 col-sm-2 col-md-2 col-lg-2 col-xl-1">
        <mat-select id="posSelect" name="pos" [(ngModel)]="selectedPos" (selectionChange)="getMarketBySelectedPos(selectedPos)"
          class="select-pos">
          <mat-option *ngFor="let pos of infoUser.market" [value]="pos">
            {{ pos }}
          </mat-option>
        </mat-select>
      </div>
      <div class="col-5 col-sm-5 col-md-4 col-lg-4 col-xl-3 offset-md-1 offset-lg-2 offset-xl-0 text-align">
        <span class="txt-pos">{{ 'field.budgetType' | translate }}</span>
      </div>
      <div class="col-7 col-sm-3 col-md-4 col-lg-3 col-xl-2">
        <mat-select id="budgetType" placeholder="{{ 'field.chooseOption' | translate }}" [(ngModel)]="budgetType"
          [compareWith]="compareBudgetType" (selectionChange)="selectionBudgetTypeChange($event)" class="select-pos">
          <mat-option *ngFor="let budgetType of budgetTypes" [value]="budgetType">
            {{ budgetType.name | translateBudgetTypeName }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="budgetTypeRequired" class="budget-management">{{ 'field.error.required' | translate }}
        </mat-error>
      </div>
    </div>

    <hr>

    <div *ngIf="loading" class="loading">
      <div class="loading-div">
        <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
        <span class="loading-span">{{ 'loading' | translate }}</span>
      </div>
    </div>

    <ng-container *ngIf="!loading && isBudgetTypeSelected && profiles.readBudgetManagement">
      <ng-container *ngIf="!(infoUser.isAdminSalesLatam() || infoUser.isUserSalesLatam())">
        <mat-accordion multi="true">
          <mat-expansion-panel class="budget-management" [expanded]="true" hideToggle="false">
            <mat-expansion-panel-header>
              <mat-panel-title id="divTitle" class="subtitle budget-management">{{ 'addGlobalBudget' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- Informacion Agencia para LATAM-->
            <div class="table-budget">
              <mat-table #table [dataSource]="firstSemesterGlobalBudget">
                <ng-container matColumnDef="position">
                  <mat-header-cell *matHeaderCellDef>{{ 'field.currentYear' | translate }}</mat-header-cell>
                  <mat-cell class="item-table-budget-management" *matCellDef="let element">
                    <div id="tooltipHeader" matTooltip="{{ element.inputTooltip | tooltipBudgetTable }}"
                      [matTooltipPosition]="position">{{ element.rowName | translateBudgetRowName }}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ene">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.ene' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element" class="budget-span">
                    {{ element.amount[0] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="feb">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.feb' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element" class="budget-span">
                    {{ element.amount[1] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="mar">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.mar' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element" class="budget-span">
                    {{ element.amount[2] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="abr">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.abr' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element" class="budget-span">
                    {{ element.amount[3] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="may">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.may' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element" class="budget-span">
                    {{ element.amount[4] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="jun">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.jun' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element" class="budget-span">
                    {{ element.amount[5] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="firstSemesterColumns" class="budget-management"></mat-header-row>
                <mat-row *matRowDef="let row; columns: firstSemesterColumns;" class="budget-management"></mat-row>
              </mat-table>
            </div>

            <div class="table-budget">
              <mat-table #table [dataSource]="secondSemesterGlobalBudget">
                <ng-container matColumnDef="position">
                  <mat-header-cell *matHeaderCellDef>{{ 'field.currentYear' | translate }}</mat-header-cell>
                  <mat-cell class="item-table-budget-management" *matCellDef="let element">
                    <div id="tooltipHeader" matTooltip="{{ element.inputTooltip | tooltipBudgetTable }}"
                      [matTooltipPosition]="position">
                      {{ element.rowName | translateBudgetRowName }}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="jul">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.jul' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element" class="budget-span">
                    {{ element.amount[6] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ago">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.ago' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element" class="budget-span">
                    {{ element.amount[7] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="sep">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.sep' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element" class="budget-span">
                    {{ element.amount[8] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="oct">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.oct' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element" class="budget-span">
                    {{ element.amount[9] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="nov">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.nov' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element" class="budget-span">
                    {{ element.amount[10] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="dic">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.dic' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element" class="budget-span">
                    {{ element.amount[11] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="total">
                  <mat-header-cell class="total-item" *matHeaderCellDef>{{ 'field.anualTotal' | translate }}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="item-table-budget-management">
                    {{ element.total | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="secondSemesterColumns" class="budget-management"></mat-header-row>
                <mat-row *matRowDef="let row; columns: secondSemesterColumns;" class="budget-management"></mat-row>
              </mat-table>
            </div>
          </mat-expansion-panel>

          <!--second panel-->
          <mat-expansion-panel class="budget-management mb-3" [expanded]="true" hideToggle="false">
            <mat-expansion-panel-header>
              <mat-panel-title id="budgetInfoTitle" class="subtitle budget-management">
                {{ 'addAgencyGroupBudget' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row justify-content-center mt-3 ml-3 mr-3">
              <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                <form>
                  <mat-form-field>
                    <input type="text" placeholder="{{ 'field.selectAgencyGroup' | translate }}" matInput
                      [formControl]="agencyGroup" [matAutocomplete]="agencyGroupAutoComplete">
                    <mat-autocomplete #agencyGroupAutoComplete="matAutocomplete" [displayWith]="displayFn">
                      <mat-option (onSelectionChange)="onSelectionAgencyGroupChange($event, agencyGroup)"
                        *ngFor="let agencyGroup of filteredAgencyGroups | async" [value]="agencyGroup">
                        {{ agencyGroup.name }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </form>
              </div>
            </div>

            <div *ngIf="loadingAgencyGroupBudget" class="loader loading">
              <div class="loading-div">
                <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                  <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                  </circle>
                </svg>
                <span class="loading-span">{{ 'loading' | translate }}</span>
              </div>
            </div>

            <!-- Informacion Agencia para LATAM-->
            <ng-container *ngIf="agencyGroupBudgetLoaded && !loadingAgencyGroupBudget">
              <mat-table #table [dataSource]="firstSemesterBudgetGroup" class="table-budget">
                <ng-container matColumnDef="position">
                  <mat-header-cell *matHeaderCellDef>{{ 'field.currentYear' | translate }}</mat-header-cell>
                  <mat-cell class="item-table-budget-management" *matCellDef="let element">
                    {{ element.rowName | translateBudgetRowName }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ene">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.ene' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="input-content" *ngIf="element.input">
                      <input [(ngModel)]="element.amount[0]" type="number" min="0" placeholder="$USD"
                        class="form-control input-control"
                        [disabled]="disabledMonthlyAmountInput(0) || !profiles.createBudgetManagement" />
                    </div>
                    <span
                      *ngIf="!element.input">{{ element.amount[0] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="feb">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.feb' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="input-content" *ngIf="element.input">
                      <input [(ngModel)]="element.amount[1]" type="number" min="0" placeholder="$USD"
                        class="form-control input-control"
                        [disabled]="disabledMonthlyAmountInput(1) || !profiles.createBudgetManagement" />
                    </div>
                    <span
                      *ngIf="!element.input">{{ element.amount[1] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="mar">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.mar' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="input-content" *ngIf="element.input">
                      <input [(ngModel)]="element.amount[2]" type="number" min="0" placeholder="$USD"
                        class="form-control input-control"
                        [disabled]="disabledMonthlyAmountInput(2) || !profiles.createBudgetManagement" />
                    </div>
                    <span
                      *ngIf="!element.input">{{ element.amount[2] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="abr">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.abr' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="input-content" *ngIf="element.input">
                      <input [(ngModel)]="element.amount[3]" type="number" min="0" placeholder="$USD"
                        class="form-control input-control"
                        [disabled]="disabledMonthlyAmountInput(3) || !profiles.createBudgetManagement" />
                    </div>
                    <span
                      *ngIf="!element.input">{{ element.amount[3] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="may">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.may' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="input-content" *ngIf="element.input">
                      <input [(ngModel)]="element.amount[4]" type="number" min="0" placeholder="$USD"
                        class="form-control input-control"
                        [disabled]="disabledMonthlyAmountInput(4) || !profiles.createBudgetManagement" />
                    </div>
                    <span
                      *ngIf="!element.input">{{ element.amount[4] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="jun">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.jun' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="input-content" *ngIf="element.input">
                      <input [(ngModel)]="element.amount[5]" type="number" min="0" placeholder="$USD"
                        class="form-control input-control"
                        [disabled]="disabledMonthlyAmountInput(5) || !profiles.createBudgetManagement" />
                    </div>
                    <span
                      *ngIf="!element.input">{{ element.amount[5] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="firstSemesterColumns" class="budget-management"></mat-header-row>
                <mat-row *matRowDef="let row; columns: firstSemesterColumns;" class="budget-management"></mat-row>
              </mat-table>

              <mat-table #table [dataSource]="secondSemesterBudgetGroup" class="table-budget">
                <ng-container matColumnDef="position">
                  <mat-header-cell *matHeaderCellDef>{{ 'field.currentYear' | translate }}</mat-header-cell>
                  <mat-cell class="item-table-budget-management" *matCellDef="let element">
                    {{ element.rowName | translateBudgetRowName }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="jul">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.jul' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="input-content" *ngIf="element.input">
                      <input [(ngModel)]="element.amount[6]" type="number" min="0" placeholder="$USD"
                        class="form-control input-control"
                        [disabled]="disabledMonthlyAmountInput(6) || !profiles.createBudgetManagement" />
                    </div>
                    <span
                      *ngIf="!element.input">{{ element.amount[6] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ago">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.ago' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="input-content" *ngIf="element.input">
                      <input [(ngModel)]="element.amount[7]" type="number" min="0" placeholder="$USD"
                        class="form-control input-control"
                        [disabled]="disabledMonthlyAmountInput(7) || !profiles.createBudgetManagement" />
                    </div>
                    <span
                      *ngIf="!element.input">{{ element.amount[7] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="sep">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.sep' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="input-content" *ngIf="element.input">
                      <input [(ngModel)]="element.amount[8]" type="number" min="0" placeholder="$USD"
                        class="form-control input-control"
                        [disabled]="disabledMonthlyAmountInput(8) || !profiles.createBudgetManagement" />
                    </div>
                    <span
                      *ngIf="!element.input">{{ element.amount[8] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="oct">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.oct' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="input-content" *ngIf="element.input">
                      <input [(ngModel)]="element.amount[9]" type="number" min="0" placeholder="$USD"
                        class="form-control input-control"
                        [disabled]="disabledMonthlyAmountInput(9) || !profiles.createBudgetManagement" />
                    </div>
                    <span
                      *ngIf="!element.input">{{ element.amount[9] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="nov">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.nov' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="input-content" *ngIf="element.input">
                      <input [(ngModel)]="element.amount[10]" type="number" min="0" placeholder="$USD"
                        class="form-control input-control"
                        [disabled]="disabledMonthlyAmountInput(10) || !profiles.createBudgetManagement" />
                    </div>
                    <span
                      *ngIf="!element.input">{{ element.amount[10] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="dic">
                  <mat-header-cell *matHeaderCellDef>{{ 'months.dic' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="input-content" *ngIf="element.input">
                      <input [(ngModel)]="element.amount[11]" type="number" min="0" placeholder="$USD"
                        class="form-control input-control"
                        [disabled]="disabledMonthlyAmountInput(11) || !profiles.createBudgetManagement" />
                    </div>
                    <span
                      *ngIf="!element.input">{{ element.amount[11] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="total">
                  <mat-header-cell class="total-item" *matHeaderCellDef>{{ 'field.anualTotal' | translate }}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="item-table-budget-management">
                    {{ element.total | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="secondSemesterColumns" class="budget-management"></mat-header-row>
                <mat-row *matRowDef="let row; columns: secondSemesterColumns;" class="budget-management"></mat-row>
              </mat-table>
              <!--
                MCPMLT-5049 https://projectmanagement.appslatam.com/browse/MCPMLT-5049

                En una investigacion previa con dante, los group agency tienen un atributo en la bd para poder mostrar o no el budget,
                y esta agencia esta desactivado para mostrar el budget, se lo comente a piero pero el no puede activarla, siendo que
                tiene un perfil con todos los privilegios.

                El problema estaria aca, ya que este es el texto que se ocupa en la casilla para activar la visualizacion del
                budget.

              -->
              <div class="row mb-3 ml-3 mr-3">
                <div class="col-sm-6 col-md-8 col-lg-9 col-xl-9 text-left">
                  <ng-container *ngIf="budgetType.id === 1">
                    <mat-checkbox [(ngModel)]="monthlyBudget.showBudget">{{ 'field.showAgencyBudget' | translate }}
                    </mat-checkbox>
                  </ng-container>
                  <ng-container *ngIf="budgetType.id === 2">
                    <mat-checkbox [(ngModel)]="monthlyBudget.showPresaleBudget">
                      {{ 'field.showAgencyBudget' | translate }}
                    </mat-checkbox>
                  </ng-container>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <button mat-raised-button id="crearButton"
                    [disabled]="!profiles.createBudgetManagement ||
                  JSON.stringify(initialGroupBudgetFirstSemester) == JSON.stringify(this.firstSemesterBudgetGroup.data[0]['amount']) &&
                  JSON.stringify(initialGroupBudgetSecondSemester) == JSON.stringify(this.secondSemesterBudgetGroup.data[0]['amount']) &&
                  initialShowBudget == (budgetType.id === 1 ? monthlyBudget.showBudget : monthlyBudget.showPresaleBudget)" (click)="save()"
                    class="background-red w-100">{{ 'button.save' | translate }}</button>
                </div>
              </div>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>

        <ng-container *ngIf="selectedAgencyGroup">
          <app-global-budget-branch-office #globalBudgetBranchOfficeComponent [market]="marketInfo"
            [budgetType]="budgetType" [selectedAgencyGroup]="selectedAgencyGroup"
            (budgetBranchSaved)="reloadBudget($event)">
          </app-global-budget-branch-office>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="infoUser.isAdminSalesLatam() || infoUser.isUserSalesLatam()">
        <app-agency-group-budget [market]="marketInfo" [budgetTypeId]="budgetType.id"></app-agency-group-budget>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

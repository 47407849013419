import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';


@Pipe({
  name: 'dateFormatWithoutLocale',
  pure: false
})
export class DateFormatWithoutLocalePipe implements PipeTransform {

  /**
   * Formatea una fecha UTC de tipo yyyy-MM-dd'T'HH:mm:ss.SSSZ al formato especificado
   * @param value Fecha en formato UTC: yyyy-MM-dd'T'HH:mm:ss.SSSZ
   * @param format Formato a aplicar
   */
  transform(value: string, format: string): string {
    if (value) {
      return moment(moment.utc(value)).format(format);
    }
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';

import { SidebarMenuModel } from '../models/SidebarMenu.model';

@Injectable()
export class SidebarShareDataService {

  private sidebarMenu = new BehaviorSubject(new SidebarMenuModel());

  getData(): Observable<SidebarMenuModel> {
    return this.sidebarMenu.asObservable();
  }



  changePanelOpened(sidebarMenu: SidebarMenuModel) {
    this.sidebarMenu.next(sidebarMenu);
  }
}

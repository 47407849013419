
import {from as observableFrom,  Observable, pipe } from 'rxjs';

import {mergeMap, map} from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';



import { environment } from '../../environments/environment';

import { DownloadLatamReport } from '../models/download-latam-report.model';
import { DownloadLatamReportCSV } from '../models/download-latam-report-CSV.model';

import { RouteLocaleExtractorService } from './route-locale-extractor.service';
import { SessionInfoService } from './session-info.service';
import { StorageService } from './storage.service';
import { PageLatamReport } from '../models/page-latam-report.model';


@Injectable()
export class LatamReportService {

  constructor(
    private http: HttpClient,
    private routeLocaleExtractorService: RouteLocaleExtractorService,
    private sessionInfoService: SessionInfoService) { }

  getWaiversByMarketsAndBetweenDates(
    startDate: string, endDate: string, status: number[], marketIds: number[], waiverTypeIds: number[], groupId: number, isOutsourcing: boolean, page: number, size: number): Observable<DownloadLatamReportCSV[]> {
    const locale = this.routeLocaleExtractorService.getLocale();
    let url = '';
    if (isOutsourcing){
      url = `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlWaiver}${locale}/waivers/outsourcing/market/report`
    } else {
      url = `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlWaiver}${locale}/waivers/market/report`
      //url = `http://localhost:9090/http://localhost:8060/${environment.endpoints.urlWaiver}${locale}/waivers/market/report`

    }
    const params = new HttpParams()
      .set('markets', marketIds.toString())
      .set('status', status.toString())
      .set('waiverTypeIds', waiverTypeIds.toString())
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('groupId', groupId != null ? groupId.toString() : null)
      .set('page', page)
      .set('size', size);

    let waivers: DownloadLatamReportCSV[] = [];

    return observableFrom(this.sessionInfoService.getSessionInfoAsPromise()).pipe(mergeMap(
      session => {
        return this.http.get(url, {params})
        .pipe(
          map(
            (data: DownloadLatamReport[]) => {
              data.forEach(
                (value: DownloadLatamReport) => {
                  waivers.push(
                    new DownloadLatamReportCSV(
                      value.id,
                      value.waiverCategory,
                      value.type,
                      value.status,
                      value.creationDate,
                      value.latamUserId,
                      value.statusUser,
                      value.statusDate,
                      value.documentNumber,
                      value.pos,
                      value.agencyGroup,
                      value.user,
                      value.departureDate,
                      value.motive,
                      value.aditionalComment,
                      value.statusComment,
                      value.pnrAgency,
                      value.rejectionMotive,
                      value.folio,
                      value.valorization,
                      value.tkt,
                      value.iataPnr,
                      value.iataOsi,
                      value.accountCode,
                      value.branchOffice,
                      value.tier,
                      value.channel,
                      value.adminSales,
                      value.userSales,
                      value.originalFare,
                      value.approvedFare,
                      value.totalQ,
                      value.discount ? `${value.discount}%` : '',
                      value.oriDes,
                      value.cabin,
                      value.codeShare,
                      value.pax,
                      value.carrier,
                      value.desiredFare,
                      value.requestQ,
                    )
                  );
                }
              );
              return waivers;
            }
          )
        )
      }
    ));
  }

  parseDateToUTCDateString(date: Date): string {
    return date.toUTCString();
  }

  parseEndDateToUTCDateString(date: Date): string {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date.toUTCString();
  }

  parseDateToDateString(date: Date): string {
    return date.toLocaleString('es-CL').split(' ')[0];
  }
}

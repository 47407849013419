<ng-container *ngIf="showComponent">
  <mat-accordion multi="true">
    <mat-expansion-panel class="budget-branch-office" [expanded]="true" hideToggle="false">
      <mat-expansion-panel-header>
        <mat-panel-title id="budgetInfoTitle" class="budget-branch-office">{{ 'branchOfficeBudget' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <form>
        <div class="row justify-content-center mt-3 ml-3 mb-2 mr-3">
          <div class="col-12 col-md-5 col-lg-5 col-xl-4">
            <mat-form-field>
              <input type="text" placeholder="{{ 'field.selectBranchOffice' | translate }}" matInput [formControl]="branch"
                [matAutocomplete]="branchAutoComplete">
              <mat-autocomplete #branchAutoComplete="matAutocomplete" [displayWith]="displayFn">
                <mat-option (onSelectionChange)="onSelectionBranchChange($event, branch)"
                  *ngFor="let branch of filteredBranches | async" [value]="branch">
                  {{ branch.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <ng-container *ngIf="showConsumeGroupBudget">
            <div class="col-12 col-md-5 col-lg-5 col-xl-4">
              <mat-checkbox [formControl]="consumeGroupBudget" (change)="consumeGroupBudgetChanged($event)"
                class="check-budget">
                {{ 'field.expendAgencyGroupBudget' | translate }}</mat-checkbox>
            </div>
          </ng-container>
        </div>
      </form>

      <div class="loading-div mb-3" *ngIf="loadingBranchBudget || isSaving">
        <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
        <span class="loading-span">{{ 'loading' | translate }}</span>
      </div>

      <!-- Informacion Agencia para LATAM-->
      <div class="table-budget-branch-office" *ngIf="branchBudgetLoaded && !loadingBranchBudget && !isSaving">
        <mat-table #table [dataSource]="firstSemesterBudget">

          <ng-container matColumnDef="position" id="global-budget-branch-position">
            <mat-header-cell *matHeaderCellDef>{{ 'field.currentYear' | translate }}</mat-header-cell>
            <mat-cell class="item-table-budget-branch-office" *matCellDef="let element">
              {{ element.rowName | translateBudgetRowName }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ene" id="global-budget-branch-ene">
            <mat-header-cell *matHeaderCellDef id="global-budget-branch-ene-cell">{{ 'months.ene' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element" id="global-budget-branch-ene-input">
              <div class="input-content" *ngIf="element.input">
                <input [(ngModel)]="element.amount[0]" type="number" min="0" placeholder="$USD"
                  class="form-control input-control"
                  [disabled]="disabledMonthlyAmountInput(0) || !profiles.createBudgetManagement" />
              </div>
              <span
                *ngIf="!element.input">{{ element.amount[0] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="feb" id="global-budget-branch-feb">
            <mat-header-cell *matHeaderCellDef id="global-budget-branch-feb-cell">{{ 'months.feb' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element" id="global-budget-branch-feb-input">
              <div class="input-content" *ngIf="element.input">
                <input [(ngModel)]="element.amount[1]" type="number" min="0" placeholder="$USD"
                  class="form-control input-control"
                  [disabled]="disabledMonthlyAmountInput(1) || !profiles.createBudgetManagement" />
              </div>
              <span
                *ngIf="!element.input">{{ element.amount[1] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="mar" id="global-budget-branch-mar">
            <mat-header-cell *matHeaderCellDef id="global-budget-branch-mar-cell">{{ 'months.mar' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element" id="global-budget-branch-mar-input">
              <div class="input-content" *ngIf="element.input">
                <input [(ngModel)]="element.amount[2]" type="number" min="0" placeholder="$USD"
                  class="form-control input-control"
                  [disabled]="disabledMonthlyAmountInput(2) || !profiles.createBudgetManagement" />
              </div>
              <span
                *ngIf="!element.input">{{ element.amount[2] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="abr" id="global-budget-branch-abr">
            <mat-header-cell *matHeaderCellDef id="global-budget-branch-abr-cell">{{ 'months.abr' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element" id="global-budget-branch-abr-input">
              <div class="input-content" *ngIf="element.input">
                <input [(ngModel)]="element.amount[3]" type="number" min="0" placeholder="$USD"
                  class="form-control input-control"
                  [disabled]="disabledMonthlyAmountInput(3) || !profiles.createBudgetManagement" />
              </div>
              <span
                *ngIf="!element.input">{{ element.amount[3] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="may" id="global-budget-branch-may">
            <mat-header-cell *matHeaderCellDef id="global-budget-branch-may-cell">{{ 'months.may' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element" id="global-budget-branch-may-input">
              <div class="input-content" *ngIf="element.input">
                <input [(ngModel)]="element.amount[4]" type="number" min="0" placeholder="$USD"
                  class="form-control input-control"
                  [disabled]="disabledMonthlyAmountInput(4) || !profiles.createBudgetManagement" />
              </div>
              <span
                *ngIf="!element.input">{{ element.amount[4] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="jun" id="global-budget-branch-jun">
            <mat-header-cell *matHeaderCellDef id="global-budget-branch-jun-cell">{{ 'months.jun' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element" id="global-budget-branch-jun-input">
              <div class="input-content" *ngIf="element.input">
                <input [(ngModel)]="element.amount[5]" type="number" min="0" placeholder="$USD"
                  class="form-control input-control"
                  [disabled]="disabledMonthlyAmountInput(5) || !profiles.createBudgetManagement" />
              </div>
              <span
                *ngIf="!element.input">{{ element.amount[5] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="firstSemesterColumns" class="budget-branch-office"></mat-header-row>
          <mat-row *matRowDef="let row; columns: firstSemesterColumns;" class="budget-branch-office"></mat-row>
        </mat-table>
      </div>

      <div class="table-budget-branch-office" *ngIf="branchBudgetLoaded && !loadingBranchBudget && !isSaving" id="global-budget-branch-table-budget">
        <mat-table #table [dataSource]="secondSemesterBudget">

          <ng-container matColumnDef="position" id="global-budget-branch-position">
            <mat-header-cell *matHeaderCellDef>{{ 'field.currentYear' | translate }}</mat-header-cell>
            <mat-cell class="item-table-budget-branch-office" *matCellDef="let element">
              {{ element.rowName | translateBudgetRowName }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="jul" id="global-budget-branch-jul">
            <mat-header-cell *matHeaderCellDef id="global-budget-branch-jul-cell">{{ 'months.jul' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element" id="global-budget-branch-jul-input">
              <div class="input-content" *ngIf="element.input">
                <input [(ngModel)]="element.amount[6]" type="number" min="0" placeholder="$USD"
                  class="form-control input-control"
                  [disabled]="disabledMonthlyAmountInput(6) || !profiles.createBudgetManagement" />
              </div>
              <span
                *ngIf="!element.input">{{ element.amount[6] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ago" id="global-budget-branch-ago">
            <mat-header-cell *matHeaderCellDef id="global-budget-branch-ago-cell">{{ 'months.ago' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element" id="global-budget-branch-ago-input">
              <div class="input-content" *ngIf="element.input">
                <input [(ngModel)]="element.amount[7]" type="number" min="0" placeholder="$USD"
                  class="form-control input-control"
                  [disabled]="disabledMonthlyAmountInput(7) || !profiles.createBudgetManagement" />
              </div>
              <span
                *ngIf="!element.input">{{ element.amount[7] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="sep" id="global-budget-branch-sep">
            <mat-header-cell *matHeaderCellDef id="global-budget-branch-sep-cell">{{ 'months.sep' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element" id="global-budget-branch-sep-input">
              <div class="input-content" *ngIf="element.input">
                <input [(ngModel)]="element.amount[8]" type="number" min="0" placeholder="$USD"
                  class="form-control input-control"
                  [disabled]="disabledMonthlyAmountInput(8) || !profiles.createBudgetManagement" />
              </div>
              <span
                *ngIf="!element.input">{{ element.amount[8] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="oct" id="global-budget-branch-oct">
            <mat-header-cell *matHeaderCellDef id="global-budget-branch-oct-cell">{{ 'months.oct' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element" id="global-budget-branch-oct-input">
              <div class="input-content" *ngIf="element.input">
                <input [(ngModel)]="element.amount[9]" type="number" min="0" placeholder="$USD"
                  class="form-control input-control"
                  [disabled]="disabledMonthlyAmountInput(9) || !profiles.createBudgetManagement" />
              </div>
              <span
                *ngIf="!element.input">{{ element.amount[9] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="nov" id="global-budget-branch-nov">
            <mat-header-cell *matHeaderCellDef id="global-budget-branch-nov-cell">{{ 'months.nov' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element" id="global-budget-branch-nov-input">
              <div class="input-content" *ngIf="element.input">
                <input [(ngModel)]="element.amount[10]" type="number" min="0" placeholder="$USD"
                  class="form-control input-control"
                  [disabled]="disabledMonthlyAmountInput(10) || !profiles.createBudgetManagement" />
              </div>
              <span
                *ngIf="!element.input">{{ element.amount[10] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="dic" id="global-budget-branch-dic">
            <mat-header-cell *matHeaderCellDef id="global-budget-branch-dic-cell">{{ 'months.dic' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element" id="global-budget-branch-dic-input">
              <div class="input-content" *ngIf="element.input">
                <input [(ngModel)]="element.amount[11]" type="number" min="0" placeholder="$USD"
                  class="form-control input-control"
                  [disabled]="disabledMonthlyAmountInput(11) || !profiles.createBudgetManagement" />
              </div>
              <span
                *ngIf="!element.input">{{ element.amount[11] | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="total" id="global-budget-branch-total">
            <mat-header-cell class="total-item" *matHeaderCellDef>{{ 'field.anualTotal' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="item-table-budget-branch-office">
              {{ element.total | number: '1.2-2':'en-US' | formatNumber | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="secondSemesterColumns" class="budget-branch-office"></mat-header-row>
          <mat-row *matRowDef="let row; columns: secondSemesterColumns;" class="budget-branch-office"></mat-row>
        </mat-table>
      </div>

      <div class="row ml-3 mb-3 mr-3" *ngIf="branchBudgetLoaded">
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
          <mat-checkbox [formControl]="showBranchBudget" (change)="showBranchBudgetChanged($event)"
            class="check-budget">{{ 'field.showBOBudget' | translate }}</mat-checkbox>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
          <button mat-raised-button id="crearButton" [disabled]="(JSON.stringify(initialBranchBudgetFirstSemester) == JSON.stringify(this.firstSemesterBudget.data[0]['amount']) &&
              JSON.stringify(initialBranchBudgetSecondSemester) == JSON.stringify(this.secondSemesterBudget.data[0]['amount']) &&
              enableSaveByConsumeCheck && enableSaveShowBranchBudget) || isSaving || !profiles.createBudgetManagement"
            (click)="save()" class="background-red w-100">{{ 'button.save' | translate }}</button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>

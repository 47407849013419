<ng-container *ngIf="infoUser$ | async as infoUser">
  <div class="container">
    <div class="row">
      <div class="col">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="latam-sans breadcrumb-item">
              <a>{{ 'menu.latamManagement' | translate }}</a>
            </li>
            <li class="breadcrumb-item latam-sans active" aria-current="page">
              <a>{{ 'menu.userManagement' | translate }}</a>
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <span id="registerAgencyTitle" class="title">{{ 'menu.userRegister' | translate }}</span>
      </div>
    </div>

    <hr>

    <div class="row justify-content-start">
      <div *ngIf="profiles.createUserManagement" class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <button mat-raised-button id="createUserLatamBtn" (click)="openCreateLatamUser()"
          class="background-red w-100">{{ 'menu.createUser' | translate }}</button>
      </div>

      <div *ngIf="profiles.readDownloadUserListLT" class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <button mat-raised-button id="createUserLatamBtn" [disabled]="downloading" (click)="downloadLatamUsersCSV()"
          class="background-red w-100">
          {{ 'button.downloadUserList' | translate }}
          <span *ngIf="downloading" class="spinner-border spinner-border-sm"></span>
          <ng-container *ngIf="!downloading && downloadingSuccessfully">
            <mat-icon class="icon-green">check</mat-icon>
          </ng-container>
        </button>
      </div>
    </div>

    <div *ngIf="loading" class="loading">
      <div class="loading-div">
        <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
        <span class="loading-span">{{ 'loading' | translate }}</span>
      </div>
    </div>

    <div class="table-user mt-3 mb-3" [ngClass]="{ 'd-none': loading }">
      <!--  Filtros de la tabla -->
      <br>
      <ng-container *ngIf="!noLatamUsers">
        <div class="row ml-1 mr-1">
          <div class="col-4 col-md-3 col-lg-2 col-xl-2">
            <mat-form-field>
              <mat-label>{{ 'field.username' | translate }}</mat-label>
              <input matInput [formControl]="latamUserIdFilter">
            </mat-form-field>
          </div>

          <div class="col-4 col-md-3 col-lg-2 col-xl-2">
            <mat-form-field>
              <mat-label>{{ 'field.pos' | translate }}</mat-label>
              <input matInput [formControl]="marketsFilter">
            </mat-form-field>
          </div>

          <div class="col-4 col-md-3 col-lg-2 col-xl-2">
            <mat-form-field>
              <mat-label>{{ 'field.name' | translate }}</mat-label>
              <input matInput [formControl]="nameFilter">
            </mat-form-field>
          </div>

          <div class="col-4 col-md-3 col-lg-2 col-xl-2">
            <mat-form-field>
              <mat-label>{{ 'field.surname' | translate }}</mat-label>
              <input matInput [formControl]="lastnameFilter">
            </mat-form-field>
          </div>

          <div class="col-4 col-md-3 col-lg-2 col-xl-2">
            <mat-form-field>
              <mat-label>{{ 'field.role' | translate }}</mat-label>
              <input matInput [formControl]="roleNameFilter">
            </mat-form-field>
          </div>

          <div class="col-4 col-md-3 col-lg-2 col-xl-2">
            <mat-form-field>
              <mat-label>{{ 'field.position' | translate }}</mat-label>
              <input matInput [formControl]="userLatamPositionNameFilter">
            </mat-form-field>
          </div>
        </div>
      </ng-container>

      <mat-table id="usersTable" #table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="latamUserId">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.username' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.latamUserId | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="markets">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.pos' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.markets.length > 1 ? 'Multiples' : (element.markets[0] ? element.markets[0].name : '—') }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.name' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.name | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastname">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.surname' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.lastname | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="roleName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.role' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.roleName | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="userLatamPositionName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.position' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.userLatamPositionName | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>{{ 'button.actions' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="!profiles.readUserManagement">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item *ngIf="profiles.readUserManagement" class="btn-mat-menu">
                <span (click)="openUserProfile(element.id)" class="item-mat-menu">{{ 'button.view' | translate }}</span>
              </button>
              <button mat-menu-item
                *ngIf="profiles.updateUserManagment && infoUser.isUserRoleGreaterThan(element.role.code)"
                class="btn-mat-menu">
                <span (click)="openEditProfile(element.id)" class="item-mat-menu">{{ 'button.edit' | translate }}</span>
              </button>
              <button mat-menu-item
                *ngIf="profiles.deleteUserManagement && infoUser.isUserRoleGreaterThan(element.role.code)"
                class="btn-mat-menu">
                <span (click)="showWarningMessage(element.id, element.roleName)"
                  class="item-mat-menu">{{ 'button.delete' | translate }}</span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="no-data-found">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell class="no-data-found-row" *matCellDef="let item"> No se encontraron usuarios registrados</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" class="latam-user-row"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"
          [ngClass]="{ 'highlight': selectedRowIndex === i }" (mouseover)="highlightRow($event, i)"
          (mouseout)="highlightRow($event, i)" class="latam-user-row"></mat-row>
        <mat-row *matRowDef="let row; columns: ['no-data-found']; when: noLatamUsersFound"></mat-row>
      </mat-table>

      <div *ngIf="!noLatamUsers">
        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 30, 50]" [showFirstLastButtons]="false">
        </mat-paginator>
      </div>
    </div>
  </div>
</ng-container>

import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';
import { FileUploadModel } from '../../../models/file-upload.model';

import { Waiver } from '../../../models/waiver.model';

import { MessageService } from '../../../services/message.service';
import { WaiverPostsaleService } from '../../../services/waiver-postsale.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Profile } from '../../../models/profile.model';
import { FileStorageService } from '../../../services/file-storage.service';
import { WaiverAgencyCorrection } from '../../../interfaces/waiver-agency-correction.interface';
import { MessageErrorService } from '../../../services/message-error.service';
import { ProfilingService } from '../../../services/profiling.service';
import { WaiverRejectionMotive } from '../../../models/waiver-rejection-motive.model';
import { slideInOutAnimation } from '../../../constants/constants';
import { SessionInfoService } from '../../../services/session-info.service';
import { SessionInfo } from '../../../models/SessionInfo.model';
@Component({
  selector: 'app-detail-list-waiver-agency',
  templateUrl: './detail-list-waiver-agency.component.html',
  styleUrls: ['./detail-list-waiver-agency.component.css'],
  animations: [
    trigger(
      'fadeInOut',
      [
        state('in', style({ opacity: 100 })),
        transition('* => void', [animate(300, style({ opacity: 0 }))])
      ]
    ),
    slideInOutAnimation
  ],
  encapsulation: ViewEncapsulation.None
})
export class DetailListWaiverAgencyComponent implements OnInit {
  displayedColumns = ['solicitante', 'tipo', 'motivo', 'date_hour'];
  displayedColumnsCo = ['pnrAgencia', 'files', 'coment'];
  displayedColumnsP = ['pnrNumber', 'pnrType', 'reservationCreationDate', 'numberOfPassengers', 'pos', 'iata', 'pcc', 'officeId'];
  displayedColumnsI = ['passengerName', 'foid', 'passengerType', 'passengerTKT'];
  displayedColumnsS = ['segmentNumber', 'carrierMarketing', 'flightNumber', 'class', 'source',
    'destination', 'departureDateTime', 'status'];
  displayedColumnsT = ['passengerTKT', 'pnrNumber', 'emisionCreationDate', 'pos', 'iata', 'pcc', 'officeId']; // , 'tktUsd', 'taxUsd'];
  displayedColumnsC = ['voucherNumber', 'carrierMarketing', 'flightNumber', 'class', 'source',
    'destination', 'departureDateTime', 'farebasis', 'voucherStatus'];
  displayedColumnsR = ['dateRech', 'motivoRech', 'commentRech'];
  displayedColumnsPA = ['datePA', 'commentPA'];
  displayedColumnsA = ['dateAprob', 'commentAprob', 'folioAprob', 'waiverValueAprob'];


  @Input() accept = '.pdf, .doc, .docx, .png, .jpg, .jpeg';
  @Output() complete = new EventEmitter<string>();
  private files: Array<FileUploadModel> = [];
  allowedFileFormats = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/png',
    'image/jpeg'
  ];

  statusComment: string;
  animationState = 'out';
  selector = ['pendientes', 'aprobados', 'rechazados', 'pendientes-agencia'];
  idWaiver = 0;
  tab: string = '';
  approveReject = true;
  waiverValue: string;
  approvalComment: string;
  rejectionComment: string;
  confirmApproval = false;
  selectedMotive: WaiverRejectionMotive = new WaiverRejectionMotive(-1, '');
  motives: {};
  loadingData = false;
  confirmRejection = false;
  waiver = new Waiver();
  dataSource = new MatTableDataSource();
  dataWaiver = new MatTableDataSource();
  dataPassengers = new MatTableDataSource();
  dataSegments = new MatTableDataSource();
  dataCoupons = new MatTableDataSource();
  tkt: boolean = false;
  document: string;
  profiles = new Profile();
  loading: boolean = false;
  currencyCode: string;
  taxCurrencyCode: string;
  sessionInfo: SessionInfo;

  constructor(
    public dialogRef: MatDialogRef<DetailListWaiverAgencyComponent>,
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private translateService: TranslateService,
    private profilesService: ProfilingService,
    private waiverPostsaleService: WaiverPostsaleService,
    private fileStorageService: FileStorageService,
    private sessionInfoService: SessionInfoService) { }

  ngOnInit() {
    this.loading = true;
    this.getRejectionMotives();
    this.profiles = this.profilesService.getProfile();

    this.waiverPostsaleService.getWaiverById(this.idWaiver).subscribe({
      next: data => {
        this.waiver = data;
        console.log(this.waiver);
        this.dataSource.data = [{ waiver: data }];
        this.document = data.document;
        this.dataPassengers.data = data.passengers;
        this.dataSegments.data = data.segments;
        this.dataCoupons.data = data.coupons;
        this.currencyCode = data['documentTktCurrency'];
        this.taxCurrencyCode = data['documentTktTaxCurrency'];
        this.initFiles(data);
        this.loading = false;
        if(this.sessionInfo.isSelfManaged){
          this.waiverValue = this.waiver.valorization;
        }
        
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.loadingWaiver');

        this.closeDialog();
        this.loading = false;
        console.log('error', error);
      }});

      this.setSessionInfo();
  }

  private getRejectionMotives(): void {

    this.waiverPostsaleService.getRejectionMotives().subscribe({
      next: data => {
        this.motives = data;
      },
      error: (error: HttpErrorResponse) => {
        this.loadingData = false;
        console.log('error', error);
      }
  });
  }

  /**
   * metodo para cerrar la modal
  */
  closeDialog() {
    this.dialogRef.close();
  }

  initFiles(waiver: any){
    if (waiver.files.length > 0){
      waiver.files.forEach(file => {
        let temporalFile = new FileUploadModel();
        temporalFile.data = file;
        temporalFile.canCancel = true;
        this.files.push(temporalFile);
      });
    }
  }

  cancelFile(file: FileUploadModel) {
    console.log('canceling-File');
    file.deletingFile = true;
    this.removeFileFromArray(file);
    file.deletingFile = false;
    console.log(file);

    this.fileStorageService.deleteFile(file.data['locationStorage']).subscribe({
      next: (event: any) => {
        this.removeFileFromArray(file);
        file.deletingFile = false;
      },
      error: (error: HttpErrorResponse) => {
        file.deletingFile = false;
        console.log('error', error);
    }});
  }

  approveWaiver() {
    this.loading = true;
    let waiverValueString = this.sessionInfo.isSelfManaged?  this.waiver.valorization :this.waiverValue;
    this.waiverPostsaleService.putApproveWaiver(
      this.idWaiver, this.approvalComment, null, waiverValueString).subscribe({
        next: data => {
          this.dialogRef.close('reload-approved');

          this.messageService.showSuccessMessage(
            this.translateService.instant('button.back'),
            this.translateService.instant('popup.wellDone'),
            this.translateService.instant('success.aprovedWaiver', { waiverId: this.idWaiver.toString() })
          );

          this.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.dialogRef.close('error');

          this.messageErrorService.sendError(error, 'error.aprovedWaiver');

          this.loading = false;
          console.log('error', error);
        }
  });
  }

  rejectWaiver() {
    this.loading = true;

    this.waiverPostsaleService.putRejectWaiver(this.idWaiver, this.selectedMotive.id, this.rejectionComment).subscribe({
      next: data => {
        this.dialogRef.close('reload-rejected');

        this.messageService.showSuccessMessage(
          this.translateService.instant('button.back'),
          this.translateService.instant('popup.wellDone'),
          this.translateService.instant('success.deniedWaiver', { waiverId: this.idWaiver.toString() })
        );

        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.dialogRef.close('error');
        this.messageErrorService.sendError(error, 'error.deniedWaiver');

        this.loading = false;
        console.log('error', error);
      }});
  }

  onClick() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    let sameFileCount: number;
    let allowUpload = true;

    fileUpload.onchange = () => {
      let index = fileUpload.files.length - 1;
      const file = fileUpload.files[index];
      sameFileCount = 0;
      this.files.forEach((f) => {
        if (f.data.name === file.name) {
          sameFileCount++;
        }
      });

      if (!this.allowedFileFormats.includes(file.type)) {
        allowUpload = false;

        this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.invalidFileFormat')
        );
      } else if (file.name.length > 100) {
        allowUpload = false;

        this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.fileName')
        );
      } else if (file.size > 2097152) {
        allowUpload = false;

        this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.maxSize')
        );
      } else {
        if (sameFileCount > 0) {
          this.messageService.showWarningMessage(
            this.translateService.instant('button.confirm'),
            this.translateService.instant('warning.alreadyLoadedFile')
          );
        } else {
          this.files.push({
            data: file, state: 'in', inProgress: false, progress: 0, canRetry: false, canCancel: true
          });
          let indexZ = this.files.length - 1;
          this.uploadFile(this.files[indexZ]);
        }
      }
    };

    fileUpload.value = '';
    fileUpload.click();
  }

  private removeFileFromArray(file: FileUploadModel) {
    const index = this.files.indexOf(file);

    if (index > -1) {
      this.files.splice(index, 1);
    }

    console.log(this.files);
  }

  private uploadFile(file: FileUploadModel) {
    file.inProgress = true;

    this.fileStorageService.uploadFile(file.data).subscribe(
      (event: any) => {
        let indexZ = this.files.length - 1;
        this.files[indexZ].data['locationStorage'] = event.reference;
      },
      (error: HttpErrorResponse) => {
        this.removeFileFromArray(file);
      });
  }

  updateWaiverWithAgencyCorrections(){
    this.loading = true;
    let waiverFiles = [];

    this.files.forEach(file => {
      waiverFiles.push(
        {
          id: file.data['id'] !=null && file.data['id'] != undefined ? file.data['id'] : null,
          name: file.data.name.toString(),
          size: file.data.size.toString(),
          type: file.data.type.toString(),
          locationStorage: file.data['locationStorage']
        }
      );
    });

    const waiverAgencyCorrection: WaiverAgencyCorrection = {
      statusComment: this.statusComment,
      waiverFiles: waiverFiles
    };

    this.waiverPostsaleService.putWaiverPendingCorrection(this.waiver.id, waiverAgencyCorrection)
      .subscribe({
        next: (resp) => {
          this.dialogRef.close('reload-update');
          this.messageService.showSuccessMessage(
            this.translateService.instant('button.back'),
            this.translateService.instant('popup.wellDone'),
            this.translateService.instant('Se ha corregido la información para la solicitud: ', { waiverId: this.waiver.id })
          );
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
          console.log(err);
        }
  });
  }

  getStyleClass(){
    let styleClass: string;
    if (this.tab === 'pendientes-agencia' || this.tab === 'pendientes'){
      styleClass = 'alert-text-warning';
    }else{
      styleClass = 'alert-text-error';
    }

    return styleClass;

  }

  toggleShowTab(divName: string) {
    if (divName === 'divFooter' && this.approveReject) {
      this.animationState = this.animationState === 'out' ? 'in' : 'out';
      this.approveReject = false;
    }
  }

  toggleShowDiv(divName: string) {
    if (divName === 'divFooter') {
      this.animationState = this.animationState === 'out' ? 'in' : 'out';
    }
  }
  setSessionInfo(){
    this.sessionInfo = this.sessionInfoService.getSessionInfo();
  }

}

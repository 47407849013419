import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-information-typepnr',
  templateUrl: './modal-information-typepnr.component.html',
  styleUrls: ['./modal-information-typepnr.component.css']
})
export class ModalInformationTypepnrComponent {


  messageInformation:string;
  
    constructor(
      private dialogRef: MatDialogRef<ModalInformationTypepnrComponent>
  
    ){ }
}

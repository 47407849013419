import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BudgetService } from '../../../services/budget.service';
import { SessionInfoService } from '../../../services/session-info.service';
import { WaiverPostsaleService } from '../../../services/waiver-postsale.service';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { SelectionModel } from '@angular/cdk/collections';
import { WaiverReservationDetailTableInterface } from '../../../interfaces/waiver-reservation-detail-table.interface';

@Component({
  selector: 'app-reservation-detail',
  templateUrl: './reservation-detail.component.html',
  styleUrls: ['./reservation-detail.component.css']
})
export class ReservationDetailComponent implements OnInit {

  private readonly BUDGET_NOT_FOUND: string = "No budget found";

  @Input() reservationInfo: WaiverReservationDetailTableInterface;
  @Input() enableSelection: boolean;
  @Input() searchType: string;
  @Input() waiverModule: string;
  @Input() infantList: number[] = new Array();
  @Input() atLeastOneInfantPass = false;
  @Output() continueProcess = new EventEmitter<any>();
  @Output() stepperBackEvent = new EventEmitter<any>();

  displayedColumnsR = ['pnrNumber', 'pnrType', 'bookingCreation', 'NumberOfPassengers', 'pos', 'iata', 'pcc', 'officeId'];
  displayedColumnsPPOS = ['passengerName', 'foid', 'passengerType', 'tkt', 'select'];
  displayedColumnsP = ['passengerName', 'foid', 'passengerType', 'select'];
  displayedColumnsS = ['segmentNumber', 'carrierMkt', 'flightNumberMkt', 'class', 'origin', 'destination', 'dateHourDeparture',
      'status', 'fareBasis', 'select'];
  displayedColumnsTKT = ['tktNumber', 'pnrNum', 'emisionCreationDate', 'posInfo', 'iataInfo', 'pccInfo', 'officeIdInfo'];
  displayedColumnsV = ['voucherNumber', 'carrierTkt', 'flightNumberTkt', 'classTkt', 'originTkt', 'destinationTkt', 'dateHourTkt',
      'farebasis', 'voucherStatus', 'select'];
  displayedColumnsAgencyBudget = ['agencyGroupName', 'budgetTotal', 'budgetBalance', 'budgetUsed'];
  displayedColumnsWaiverResult = ['id', 'waiverPos', 'agencyName', 'iata', 'userName', 'waiverType', 'documentNumber', 'statusDate', 'flightDate'];

  dataAgencyBudget = new MatTableDataSource();
  dataReservation = new MatTableDataSource();
  dataPassengers = new MatTableDataSource();
  dataSegments = new MatTableDataSource();
  dataTkt = new MatTableDataSource();
  dataVoucher = new MatTableDataSource();
  agencyGroupName: string = '';
  agencyGroupBudget: number = 0;

  selectedReservationInformationWaiverLatamRowIndex: number = -1;
  selectedPassengerInformationWaiverLatamRowIndex: number = -1;
  selectedSegmentsInformationWaiverLatamRowIndex: number = -1;
  selectedTktInformationWaiverLatamRowIndex: number = -1;
  selectedCouponsInformationRowIndex: number = -1;
  segmentsWithOneCouponInvalidQuantity = 0;

  selectionPassengers = new SelectionModel<Element>(true, []);
  selectionSegments = new SelectionModel<Element>(true, []);
  selectionCoupons = new SelectionModel<Element>(true, []);

  showAgencyBudget: boolean = false;
  isAgencyBudgetValid: boolean = true;
  isLoadingAgencyBudget: boolean = false;
  validSegmentStatusList = ['HK', 'KK'];
  invalidCouponStatusList = ['RFND', 'EXCH', 'VOID', 'USED', 'SUSP', 'LFTD', 'CHKD', 'CTCR'];

  passengerTicketRequestsCompleted = 0;


  constructor(private readonly waiverPostsaleService: WaiverPostsaleService,
      private readonly translateService: TranslateService,
      private readonly sessionInfoService: SessionInfoService,
      private readonly budgetService: BudgetService,
      public dialog: MatDialog,
      private readonly cdr: ChangeDetectorRef
  ){ }

  ngOnInit(): void {
    this.checkSelectionStatus();
    this.sessionInfoService.getSessionInfoAsPromise()
      .then(
        sessionInfo => {
          this.agencyGroupName = sessionInfo.agency;
          this.getAmountAvailable(sessionInfo.matrix_id);
        }
      )
  }

  private checkSelectionStatus(){
    if (!this.enableSelection){
      this.displayedColumnsPPOS = this.displayedColumnsPPOS.filter(col => col !== 'select');
      this.displayedColumnsP = this.displayedColumnsP.filter(col => col !== 'select');
      this.displayedColumnsS = this.displayedColumnsS.filter(col => col !== 'select');
      this.displayedColumnsV = this.displayedColumnsV.filter(col => col !== 'select');

      this.reservationInfo.dataPassengers.data.forEach(passenger => this.selectionPassengers.select(passenger as Element));
      this.reservationInfo.dataSegments.data.forEach(segments => this.selectionSegments.select(segments as Element));
    }
  }

  private getAmountAvailable(agencyGroupId) {
    this.isLoadingAgencyBudget = true;

    if (this.waiverModule === 'COR'){
      this.budgetService.getMonthlyAfterSalesBudgetByGroup(agencyGroupId).subscribe({
        next: (budgetData) => {
            this.showAgencyBudget = budgetData?.agencyGroup?.showBudget;

            const formattedBudgetTotal = budgetData.assignedAmount.toFixed(2);
            const formattedBudgetUsed = budgetData.busyAmount.toFixed(2);
            const availableBudget = budgetData.assignedAmount - budgetData.busyAmount;
  
            this.dataAgencyBudget.data = [{
                agencyGroupName: this.agencyGroupName,
                budgetTotal: formattedBudgetTotal,
                budgetBalance: availableBudget.toFixed(2),
                budgetUsed: formattedBudgetUsed,
            }];
  
            this.agencyGroupBudget = (budgetData.assignedAmount - budgetData.busyAmount)
  
            this.isAgencyBudgetValid = true;
            this.isLoadingAgencyBudget = false;
        },
        error: (errorResponse: HttpErrorResponse) => {
          const isBudgetNotFoundError = errorResponse?.error?.description === this.BUDGET_NOT_FOUND;

          if (isBudgetNotFoundError) {
            this.dataAgencyBudget.data = [{
              agencyGroupName: this.agencyGroupName,
              budgetTotal: 0,
              budgetBalance: 0,
              budgetUsed: 0,
            }];
            this.isAgencyBudgetValid = true;
          } else {
            const errorMessage  = this.translateService.instant('waiverLatam.budgetException');
            this.swalAlert(errorMessage);
            this.isAgencyBudgetValid = false;
          }
          this.isLoadingAgencyBudget = false;
        }
      });
    } else {
      this.isAgencyBudgetValid = true;
      this.isLoadingAgencyBudget = false;
    }
  }

  nextStep(){
    if (this.waiverModule.includes('POL')){
      this.continueProcess.emit(this.selectionPassengers);
    }
  }

  swalAlert(msg: string){
    Swal.fire({
      icon: "error",
      text: msg,
    });
  }

  highlightReservationInformationRowWaiverLatam(event: Event, rowIndex: number) {
    this.selectedReservationInformationWaiverLatamRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  highlightPassengerInformationRowWaiverLatam(event: Event, rowIndex: number) {
    this.selectedPassengerInformationWaiverLatamRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  highlightSegmentsInformationRowWaiverLatam(event: Event, rowIndex: number) {
    this.selectedSegmentsInformationWaiverLatamRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  highlightTktInformationRowWaiverLatam(event: Event, rowIndex: number) {
    this.selectedTktInformationWaiverLatamRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  highlightCouponsInformationRowWaiverLatam(event: Event, rowIndex: number) {
    this.selectedCouponsInformationRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  toggleSelectionSegmentWaiverLatam(row) {
    if (this.validSegmentStatusList.includes(row.status) && row.invalidCoupons === 0) {
      this.selectionSegments.toggle(row);
    }
  }

  toggleSelectionPassengerWaiverLatam(row) {
    let clearData = false;

    if (!row.invalidTkt && !row.allCouponsInvalid && !row.infantTkt) {
      if (!this.selectionPassengers.isSelected(row)) {
        this.addCouponsToSelectedSegmentsWaiverLatam(row);
        if (row.type !== 'INF' && this.atLeastOneInfantPass) {
          this.setInfantTktPropertie(false);
        }
      } else {
        this.removeCouponsFromSelectedSegments(row);
        if (this.infantList.length > 0) {
          clearData = this.updateInfantSelectionState(row);
        }
      }

      this.selectionPassengers.toggle(row);
      this.updateSegmentSelectionState();
      this.updateSegmentsWithInvalidCoupons();

      if (clearData) {
        this.selectionPassengers.clear();
      }
    }
  }

  addCouponsToSelectedSegmentsWaiverLatam(row) {
    for (const element of this.dataSegments.data) {
      let coupon = this.findCouponBySourceDestination(
        row.coupons, element['source'], element['destination']
      );

      if (!coupon) {
        continue;
      }

      element['selectedCoupons'].push(coupon);

      if (this.invalidCouponStatusList.includes(coupon['status'])) {
        element['invalidCoupons']++;
      }
    }
  }

  updateSegmentSelectionState() {
    this.selectionSegments.selected.forEach((s: any) => {
      if (!this.validSegmentStatusList.includes(s.status) || s.invalidCoupons > 0) {
        this.selectionSegments.toggle(s);
      }
    });
  }

  updateSegmentsWithInvalidCoupons() {
    this.segmentsWithOneCouponInvalidQuantity = 0;

    for (const element of this.dataSegments.data) {
      if (this.validSegmentStatusList.includes(element['status']) && element['invalidCoupons'] > 0) {
        this.segmentsWithOneCouponInvalidQuantity++;
      }
    }
  }

  findCouponBySourceDestination(coupons: Array<any>, src: string, dst: string) {
    return coupons.find(coupon => coupon.source == src && coupon.destination == dst);
  }
  
  removeCouponsFromSelectedSegments(row) {
    for (const element of this.dataSegments.data) {
      let coupon = this.findCouponBySourceDestination(
        row.coupons, element['source'], element['destination']
      );

      if (!coupon) {
        continue;
      }

      element['selectedCoupons'].splice(
        element['selectedCoupons'].findIndex(c => c['tkt'] === coupon['tkt']), 1
      );

      if (this.invalidCouponStatusList.includes(coupon['status'])) {
        element['invalidCoupons']--;
      }
    }
  }

  updateInfantSelectionState(row) {
    let selectionAdlt = false;

    if (this.selectionPassengers.selected.length - 1 > 0) {
      this.selectionPassengers.selected.forEach((passenger: any) => {
        if (passenger.type !== 'INF' && passenger.ticketNumber !== row.ticketNumber) {
          selectionAdlt = true;
        }
      });

      if (selectionAdlt) {
        this.setInfantTktPropertie(false);
      } else {
        this.setInfantTktPropertie(true);
        return true;  // clearData = true
      }
    } else {
      this.setInfantTktPropertie(true);
    }

    return false;  // clearData = false
  }

  private setInfantTktPropertie(needDisabled: boolean) {
    if (this.infantList.length > 0 && this.atLeastOneInfantPass) {
      for (const element of this.infantList) {
        if (needDisabled) {
          this.dataPassengers.data[element]['infantTkt'] = true;
        } else {
          this.dataPassengers.data[element]['infantTkt'] = false;
        }
      }
    }
  }
  


  isSelectPassengersPost(): boolean{
    if (this.selectionPassengers.selected.length === 0){
      return false;
    }
    return true;
  }
  
  isSelectSegmentsPost(): boolean{
    if (this.selectionSegments.selected.length === 0){
      return false;
    }
    return true;
  }
  
  isSelectCouponsPost(): boolean{
    if (this.selectionCoupons.selected.length === 0){
      return false;
    }

    return true;
  }

  stepperBack(){
    this.stepperBackEvent.emit('reservation-details');
  }

}

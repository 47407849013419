<div class="container">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="latam-sans breadcrumb-item">
            <a>{{ 'menu.latamManagement' | translate }}</a>
          </li>
          <li class="breadcrumb-item latam-sans active" aria-current="page">
            <a>{{ 'menu.waiversAutomation' | translate }}</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <span id="waiverAutomationTitle" class="title">{{ 'menu.waiversAutomation' | translate }}</span>
    </div>
  </div>

  <hr>

  <div *ngIf="loading" class="loading">
    <div class="loading-div">
      <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
      <span class="loading-span">{{ 'loading' | translate }}</span>
    </div>
  </div>

  <div class="mt-4 mb-4 table-waiver" *ngIf="!loading">
    <mat-table #table [dataSource]="dataSource">
      <ng-container matColumnDef="pos">
        <mat-header-cell *matHeaderCellDef>{{ 'field.pos' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="waiver-item">{{ element.name }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="automate">
        <mat-header-cell *matHeaderCellDef>{{ 'field.enableAutoApproval' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="checkbox-automate">
          <mat-slide-toggle [(ngModel)]="element.waiverEnableAutoapprove" disableRipple></mat-slide-toggle>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns" class="waiver-auto-row"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"
        [ngClass]="{ 'highlight': selectedPosRowIndex === i }" (click)="toggleSelectionRow($event, row)"
        (mouseout)="highlightRow($event, i)" (mouseover)="highlightRow($event, i)" class="waiver-auto-row"></mat-row>
    </mat-table>
  </div>

  <ng-container *ngIf="!loading && profiles.readWaiverTypeActivation">
    <div class="row mb-3 justify-content-end">
      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <button mat-raised-button (click)="save()" class="background-red w-100">{{ 'button.save' | translate }}</button>
      </div>
    </div>
  </ng-container>
</div>
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

import { AgencyGroup } from '../../models/agency-group.model';
import { Budget } from '../../models/budget.model';
import { BudgetType } from '../../models/budget-type.model';
import { MonthlyBudget } from '../../models/monthly-budget.model';

import { BudgetService } from '../../services/budget.service';
import { MessageErrorService } from './../../services/message-error.service';


@Component({
  selector: 'app-budget-agency-group',
  templateUrl: './budget-agency-group.component.html',
  styleUrls: ['./budget-agency-group.component.css']
})
export class BudgetAgencyGroupComponent implements OnInit, OnChanges {
  loadingAgencyGroupBudget = false;
  agencyGroupBudgetLoaded = false;
  firstSemesterColumns = ['position', 'ene', 'feb', 'mar', 'abr', 'may', 'jun'];
  secondSemesterColumns = ['position', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic', 'total'];
  selectedAgencyGroup: AgencyGroup;

  firstSemesterBudgetGroup = new MatTableDataSource();
  secondSemesterBudgetGroup = new MatTableDataSource();

  initialGroupBudget = {};
  initialGroupBudgetFirstSemester = [];
  initialGroupBudgetSecondSemester = [];
  initialShowBudget: boolean;
  position = 'below';

  /* autocomplete*/
  agencyGroup = new UntypedFormControl();

  monthlyBudget: MonthlyBudget = new MonthlyBudget();

  @Input() group: AgencyGroup;
  @Input() budgetType: BudgetType;

  @ViewChild('profileGroupForm') profileGroupForm: NgForm;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private noAgenciesDataFound = (index: number, item: { noData: boolean }) => item.noData;

  constructor(
    private budgetService: BudgetService,
    private messageErrorService: MessageErrorService) { }

  ngOnInit() {
    this.initializeTableGroupBudgetDataSources();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.budgetType.firstChange) {
      this.budgetType = changes.budgetType.currentValue;
      this.getBudgetByAgencyGroup(this.group);
    }
  }

  /**
   * Se inicializa la tabla del PPTO de Waiver
   */
  private initializeTableGroupBudgetDataSources(): void {
    this.firstSemesterBudgetGroup = new MatTableDataSource();
    this.firstSemesterBudgetGroup.data[0] = {};
    this.firstSemesterBudgetGroup.data[1] = {};
    this.firstSemesterBudgetGroup.data[2] = {};
    this.firstSemesterBudgetGroup.data[0]['input'] = false;
    this.firstSemesterBudgetGroup.data[1]['input'] = false;
    this.firstSemesterBudgetGroup.data[2]['input'] = false;
    this.firstSemesterBudgetGroup.data[0]['rowName'] = 'Cargado';
    this.firstSemesterBudgetGroup.data[1]['rowName'] = 'Gastado';
    this.firstSemesterBudgetGroup.data[2]['rowName'] = 'Disponible';
    this.firstSemesterBudgetGroup.data[0]['amount'] = [];
    this.firstSemesterBudgetGroup.data[1]['amount'] = [];
    this.firstSemesterBudgetGroup.data[2]['amount'] = [];

    this.secondSemesterBudgetGroup = new MatTableDataSource();
    this.secondSemesterBudgetGroup.data[0] = {};
    this.secondSemesterBudgetGroup.data[1] = {};
    this.secondSemesterBudgetGroup.data[2] = {};
    this.secondSemesterBudgetGroup.data[0]['input'] = false;
    this.secondSemesterBudgetGroup.data[1]['input'] = false;
    this.secondSemesterBudgetGroup.data[2]['input'] = false;
    this.secondSemesterBudgetGroup.data[0]['rowName'] = 'Cargado';
    this.secondSemesterBudgetGroup.data[1]['rowName'] = 'Gastado';
    this.secondSemesterBudgetGroup.data[2]['rowName'] = 'Disponible';
    this.secondSemesterBudgetGroup.data[0]['amount'] = [];
    this.secondSemesterBudgetGroup.data[1]['amount'] = [];
    this.secondSemesterBudgetGroup.data[2]['amount'] = [];
  }

  public getBudgetByAgencyGroup(selectedAgencyGroup: AgencyGroup): void {
    this.agencyGroupBudgetLoaded = false;
    this.loadingAgencyGroupBudget = true;

    if (this.budgetType.id === 1) {
      this.getBudgetPostSaleByAgencyGroup(selectedAgencyGroup);
    } else if (this.budgetType.id === 2) {
      this.getBudgetPresaleByAgencyGroup(selectedAgencyGroup);
    }
  }

  private getBudgetPostSaleByAgencyGroup(selectedAgencyGroup: AgencyGroup): void {
    this.budgetService.getBudgetPostSaleByAgencyGroup(selectedAgencyGroup.id).subscribe({
      next: (data: Budget[]) => {
        this.setBudgetAgencyGroupData(data, selectedAgencyGroup);
        this.agencyGroupBudgetLoaded = true;
        this.loadingAgencyGroupBudget = false;
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.loadingBudget');

        this.loadingAgencyGroupBudget = false;
        console.log('error', error);
  }});
  }

  private getBudgetPresaleByAgencyGroup(selectedAgencyGroup: AgencyGroup): void {
    this.budgetService.getBudgetPresaleByAgencyGroup(selectedAgencyGroup.id).subscribe({
      next: (data: Budget[]) => {
        this.setBudgetAgencyGroupData(data, selectedAgencyGroup);
        this.agencyGroupBudgetLoaded = true;
        this.loadingAgencyGroupBudget = false;
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.loadingBudget');

        this.loadingAgencyGroupBudget = false;
        console.log('error', error);
  }});
  }

  private setBudgetAgencyGroupData(data: Budget[], selectedAgencyGroup: AgencyGroup): void {
    this.monthlyBudget.groupId = selectedAgencyGroup.id;
    this.monthlyBudget.showBudget = selectedAgencyGroup.showBudget;
    this.monthlyBudget.showPresaleBudget = selectedAgencyGroup.showPresaleBudget;
    this.initialShowBudget = this.budgetType.id === 1 ? selectedAgencyGroup.showBudget : selectedAgencyGroup.showPresaleBudget;
    data.slice(0, 6).forEach(b => {
      this.firstSemesterBudgetGroup.data[0]['amount'][b.month] = parseFloat(b.assignedValue.toString()).toFixed(2);
      this.firstSemesterBudgetGroup.data[1]['amount'][b.month] = b.spentValue;
      this.firstSemesterBudgetGroup.data[2]['amount'][b.month] = b.currentAmount;

    });
    data.slice(6, 12).forEach(b => {
      this.secondSemesterBudgetGroup.data[0]['amount'][b.month] = parseFloat(b.assignedValue.toString()).toFixed(2);
      this.secondSemesterBudgetGroup.data[1]['amount'][b.month] = b.spentValue;
      this.secondSemesterBudgetGroup.data[2]['amount'][b.month] = b.currentAmount;
    });
    this.initialGroupBudget = data;
    this.initialGroupBudgetFirstSemester = this.firstSemesterBudgetGroup.data[0]['amount'].slice();
    this.initialGroupBudgetSecondSemester = this.secondSemesterBudgetGroup.data[0]['amount'].slice();
    this.secondSemesterBudgetGroup.data[0]['total'] =
      this.firstSemesterBudgetGroup.data[0]['amount'].concat(
        this.secondSemesterBudgetGroup.data[0]['amount']).reduce((a, b) => parseFloat(a) + parseFloat(b)
        );
    this.secondSemesterBudgetGroup.data[1]['total'] =
      this.firstSemesterBudgetGroup.data[1]['amount'].concat(this.secondSemesterBudgetGroup.data[1]['amount']).reduce((a, b) => a + b);
    this.secondSemesterBudgetGroup.data[2]['total'] =
      this.firstSemesterBudgetGroup.data[2]['amount'].concat(this.secondSemesterBudgetGroup.data[2]['amount']).reduce((a, b) => a + b);
  }
}

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MessageErrorService } from '../../../services/message-error.service';

@Component({
  selector: 'app-modal-validate-correct-name',
  templateUrl: './modal-validate-correct-name.component.html',
  styleUrls: ['./modal-validate-correct-name.component.css']
})
export class ModalValidateCorrectNameComponent {
  newFullName: string;
  oldFullName: string;
  loadingInfo: boolean = false; // Estado de carga

  constructor(
    private dialogRef: MatDialogRef<ModalValidateCorrectNameComponent>,
    private messageErrorService: MessageErrorService
  ) {}

  confirmCorrection() {
    this.loadingInfo = true; // Activa el spinner
    this.dialogRef.close(true); // Cierra el modal y ejecuta la lógica en el componente padre

    // Aquí iría la llamada al servicio que procesa la corrección
    // Suponiendo que hay una función en el componente padre que maneja la lógica.
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {

  public static getClientId(): string {
    return sessionStorage.getItem('client_id');
  }

  public static setClientId(clientId: string): void {
    sessionStorage.setItem('client_id', clientId);
  }

  public static getDnsLatamtrade(): string {
    return sessionStorage.getItem('dns_latamtrade');
  }

  public static setDnsLatamtrade(dnsLatamtrade: string): void {
    sessionStorage.setItem('dns_latamtrade', dnsLatamtrade);
  }

  public static getDnsWaivers(): string {
    return sessionStorage.getItem('dns_waivers');
  }

  public static setDnsWaivers(dnsWaivers: string): void {
    sessionStorage.setItem('dns_waivers', dnsWaivers);
  }

  public static getDnsLogin(): string {
    return sessionStorage.getItem('dns_login');
  }

  public static setDnsLogin(dnsLogin: string): void {
    sessionStorage.setItem('dns_login', dnsLogin);
  }

  public static getBucketName(): string {
    return sessionStorage.getItem('bucket_name');
  }

  public static setBucketName(bucketName: string): void {
    sessionStorage.setItem('bucket_name', bucketName);
  }

  public static getAuthDomain(): string {
    return sessionStorage.getItem('fire_domain');
  }

  public static setAuthDomain(bucketName: string): void {
    sessionStorage.setItem('fire_domain', bucketName);
  }
}

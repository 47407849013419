import { Injectable } from '@angular/core';

@Injectable()
export class CommonsService {



  getLanguages(): string[] {
    const languages = ['en', 'es', 'pt'];

    return languages;
  }

  getLenguagesV2(): any[]{
    const languages = [
      {shortName: 'en', translateName: 'field.waiverManualEnglish', img: 'CircleUK.svg'},
      {shortName: 'es', translateName: 'field.waiverManualSpanish', img: 'CircleES.svg'},
      {shortName: 'pt', translateName: 'field.waiverManualPortuguese', img: 'CircleBR.svg'}
    ];

    return languages;
  }
}

import { WaiverType } from './waiver-type.model';
import { Market } from './market.model';

export class WaiverTypeStatus {
  public id: number;
  public waivertype: WaiverType;
  public market: Market;
  public status: Boolean;

  public constructor(
    id: number,
    waivertype: WaiverType,
    market: Market,
    status: Boolean) {
      this.id = id;
      this.waivertype = waivertype;
      this.market = market;
      this.status = status;
  }
}

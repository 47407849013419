import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ErrorPageComponent } from './error-page/error-page.component';

import { ShareModule } from '../share-module/share.module';

import { UrlService } from './../../services/url.service';


@NgModule({
  declarations: [
    ErrorPageComponent
  ],
  imports: [
    CommonModule,
    ShareModule
  ],
  exports: [
    ErrorPageComponent
  ],
  providers: [
    UrlService
  ]
})
export class ErrorModule { }

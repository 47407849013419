<div *ngIf="loading" class="loading-div">
  <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
    <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
  </svg>
  <span class="loading-span">{{ 'loading' | translate }}</span>
</div>

<ng-container *ngIf="!loading">
  <div class="row mt-4 mr-3 mb-3 ml-3">
    <div class="col">
      <span class="subtitle">{{ 'associatedAgencyGroups' | translate }}</span>
    </div>
  </div>

  <app-monthly-budget [errorLoadingData]="errorLoadingData" [displayedColumns]="displayedColumns" [budget]="budget"
    [marketId]="market.id" [marketName]="market.name" [budgetTypeId]="budgetTypeId" (budgetAux)="setBudget($event)"
    (newTotalCurrentAmount)="setNewTotalCurrentAmount($event)" (refreshingBudget)="refreshBudget($event)" (validatesave) ="setValidateSave($event)">
  </app-monthly-budget>

  <ng-container *ngIf="budget.length > 0">
    <div class="row justify-content-end mt-3 mr-3 ml-3 mb-4">
      <div class="col-2">
        <button mat-raised-button id="save" [disabled]="newTotalCurrentAmount < 0 || !validateSave" (click)="saveBudget()"
          class="background-red w-100">{{ 'button.save' | translate }}</button>
      </div>
    </div>
  </ng-container>
</ng-container>

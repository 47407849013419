import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { Observable } from 'rxjs';
import { SessionInfoService } from '../services/session-info.service';

@Injectable()
export class SessionAuthOutsourcingGuard implements CanActivate  {

  constructor(
    private router: Router,
    private sessionInfoService: SessionInfoService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    this.sessionInfoService.getSessionInfoAsPromise()
      .then(
        sessionInfo => {
          if (sessionInfo && (sessionInfo.isLatamSession() || sessionInfo.isAgencySession())) {
            this.router.navigateByUrl('/401', { skipLocationChange: true });
            return false;
          }
          this.sessionInfoService.validateRolePath(route, state, sessionInfo.role);
    });

    if (localStorage.getItem('idToken') && localStorage.getItem('sessionToken')) {
      return true;
    }

    this.router.navigate(['']);
    return false;
  }
  
}

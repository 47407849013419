import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';

import { Profile } from '../../../models/profile.model';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';

import { ProfilingService } from '../../../services/profiling.service';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';
import { SessionInfoService } from '../../../services/session-info.service';
import { SessionInfo } from '../../../models/SessionInfo.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-waiver-agency-new',
  templateUrl: './list-waiver-agency-new.component.html',
  styleUrls: ['./list-waiver-agency-new.component.css']
})
export class ListWaiverAgencyNewComponent implements OnInit {

  localeLanguage: string;
  displayedColumnsPendingStatus = ['id', 'type', 'motive', 'documentNumber', 'agent', 'creationDate', 'documentCloserDepartureDate', 'actions'];
  displayedColumnsPendingAgencyStatus = ['id', 'type', 'motive', 'documentNumber', 'agent', 'statusDate', 'documentCloserDepartureDate', 'actions'];
  displayedColumnsApprovedStatus = ['id', 'type', 'motive', 'documentNumber', 'agent', 'statusDate', 'documentCloserDepartureDate', 'actions'];
  displayedColumnsRejectedStatus = ['id', 'type', 'motive', 'documentNumber', 'agent', 'statusDate', 'documentCloserDepartureDate', 'actions'];
  private readonly waiversBaseEndpoind = {
    courtesy: "/agency/waivers/latam",
    policies: "/agency/waivers/policies"
  }

  userProfiles = new Profile();
  tab:string;
  sessionInfo: SessionInfo;
  constructor(
    @Inject(LOCALE_ID) locale: string,
    private profilesService: ProfilingService,
    private sidebarShareDataService: SidebarShareDataService,
    private router:Router,
    private sessionInfoService: SessionInfoService) {
      this.localeLanguage = locale;
  }

  ngOnInit() {
    const route = this.router.url;
    const matchedRoute = Object.values(this.waiversBaseEndpoind).find(endpoind => route.startsWith(endpoind));
    if (matchedRoute){
      let sidebarMenuAuto;
      if (matchedRoute === this.waiversBaseEndpoind.courtesy){
        sidebarMenuAuto = new SidebarMenuModel(SidebarMenuModel.MENU_WAIVERS, SidebarMenuModel.SUBMENU_WAIVER_COURTESY_REQUEST);
      } else {
        sidebarMenuAuto = new SidebarMenuModel(SidebarMenuModel.MENU_WAIVERS, SidebarMenuModel.SUBMENU_WAIVER_POLICY_REQUEST);
      }
      this.userProfiles = this.profilesService.getProfile();
      this.sidebarShareDataService.changePanelOpened(sidebarMenuAuto);
      this.sessionInfo = this.sessionInfoService.getSessionInfo();
    }
  }

}

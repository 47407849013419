import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateCourtesyWaiverComponent } from './create-courtesy-waiver/create-courtesy-waiver.component';
import { WaiverHomeComponent } from './waiver-home/waiver-home.component';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ShareModule } from '../share-module/share.module';
import { TermsConditionsModule } from '../terms-conditions-module/terms-conditions.module';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ListWaiverAgencyNewComponent } from './list-waiver-agency-new/list-waiver-agency-new.component';
import { WaiverManualNewComponent } from './waiver-manual-new/waiver-manual-new.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ExecutiveApprovalComponent } from './executive-approval/executive-approval.component';
import { LoginLatamService } from '../../services/login-latam.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CreatePolicyWaiverComponent } from './create-policy-waiver/create-policy-waiver.component';
import { SearchReservationComponent } from './search-reservation/search-reservation.component';
import { ReservationDetailComponent } from './reservation-detail/reservation-detail.component';
import { RequestSummaryComponent } from './request-summary/request-summary.component';
import { WaiverResultComponent } from './waiver-result/waiver-result.component';




@NgModule({
  declarations: [
    CreateCourtesyWaiverComponent,
    WaiverHomeComponent,
    ListWaiverAgencyNewComponent,
    WaiverManualNewComponent,
    ExecutiveApprovalComponent,
    CreatePolicyWaiverComponent,
    SearchReservationComponent,
    ReservationDetailComponent,
    RequestSummaryComponent,
    WaiverResultComponent,
  ],
  imports: [
    MatButtonModule,
    CommonModule,
    FormsModule,
    MatCardModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatChipsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    ShareModule,
    TermsConditionsModule,
    MatFormFieldModule,
    NgxMaskDirective, 
    NgxMaskPipe,
    MatProgressSpinnerModule,
    BrowserAnimationsModule, 
    
  ], 
  providers: [provideNgxMask(),
    LoginLatamService,
  ]

})
export class WaiverPostsaleAutoModuleModule { }

export class Market {
  id: number;
  name: string;
  locale: string;
  termsConditions: string;
  waiverEnableAutoapprove: boolean;
  wisHierarchyCode: number;

  public constructor(
    id?: number,
    name?: string,
    locale?: string,
    termsConditions?: string,
    waiverEnableAutoapprove?: boolean) {
      this.id = id;
      this.name = name;
      this.locale = locale;
      this.termsConditions = termsConditions;
      this.waiverEnableAutoapprove = waiverEnableAutoapprove;
  }
}

import { LatamRole } from './latam-role.model';
import { UserLatamPosition } from './user-latam-position.model';
import { Market } from './market.model';
import { Language } from './language.model';

export class UserLatam {
  id: number;
  latamUserId: number;
  name: string;
  lastname: string;
  email: string;
  role: LatamRole;
  userLatamPosition: UserLatamPosition;
  markets: Market[];
  language: Language;
  active: boolean;
  birthdate: string;
  creationDate: string;

  constructor(
    id?: number,
    latamUserId?: number,
    name?: string,
    lastname?: string,
    email?: string,
    role?: LatamRole,
    userLatamPosition?: UserLatamPosition,
    markets?: Market[],
    language?: Language) {
      this.id = id;
      this.latamUserId = latamUserId;
      this.name = name;
      this.lastname = lastname;
      this.email = email;
      this.role = role;
      this.userLatamPosition = userLatamPosition;
      this.markets = markets;
      this.language = language;
  }
}

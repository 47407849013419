<div class="approval-container">
    <div *ngIf="!isLoadingManagementInfo && !loadingAgencyGroupBudget && !loadingGlobalBudget && !invalidStatus && !approvedWaiver && !rejectedWaiver && executiveManagementInfo != null">
        
        
        <h2 class="title-default">{{ 'waiverLatam.waiverManagement' | translate }}</h2>
        <h4 class="section-title">{{ 'requestDetail' | translate }} - {{executiveManagementInfo.waiver.id}}</h4>
        <div class="budget-detail">
            <table class="mat-table column-type">
                <tr>
                    <th class="title-row">POS</th>
                    <td class="content-row">{{ executiveManagementInfo.waiver.market.name }}</td>
                </tr>
                <tr>
                    <th class="title-row">{{'field.agency' | translate }}</th>
                    <td class="content-row">{{ executiveManagementInfo.waiver.agencyGroup.name }}</td>
                </tr>
                <tr>
                    <th class="title-row">PNR/TKT</th>
                    <td class="content-row">{{ executiveManagementInfo.waiver.documentNumber }}</td>
                </tr>
                <tr>
                    <th class="title-row">IATA</th>
                    <td class="content-row">{{ executiveManagementInfo.waiver.documentIata | dashWhenEmpty  }}</td>
                </tr>
                <tr>
                    <th class="title-row">{{ 'field.waiverType' | translate }}</th>
                    <td class="content-row">{{ executiveManagementInfo.waiver.type.name }}</td>
                </tr>
            </table>
        </div>
        <br>
        <h4 class="section-title">{{ 'waiverLatam.costDetail' | translate}}</h4>
        <div class="budget-detail">
            <div class="section-element">
                <span class="section-element-title"><b>{{ 'budgetAvailable' | translate }}</b></span>
                <span class="section-element-content">USD {{ globalBudget.toBeDistributedValue }}</span>
            </div>
            <div class="section-element">
            <span class="section-element-title"><b>{{ 'budgetAvailableAgency' | translate }}</b></span>
            <span class="section-element-content">USD {{ agencyGroupBudget}}</span>
            </div>
            <div class="section-element">
                <span class="section-element-title"><b>{{ 'requestedAmount' | translate }}</b></span>
                <span class="section-element-content">USD {{ waiver.valorization }}</span>
            </div>
        </div>

        <div class="action-container">
            <button mat-flat-button [disabled]="rejectingWaiver || approvingWaiver" (click)="confirmRejection()">
                <span *ngIf="!rejectingWaiver">{{ 'button.deny' | translate }}</span>                
                <mat-progress-spinner 
                    *ngIf="rejectingWaiver"
                    class="custom-spinner"
                    mode="indeterminate" 
                    diameter="20">
                </mat-progress-spinner>
            </button>
            <button mat-flat-button [disabled]="rejectingWaiver || approvingWaiver" (click)="saveWaiverApprovedByExecutive()">
                <span *ngIf="!approvingWaiver">{{ 'button.approve' | translate }}</span>
                <mat-progress-spinner 
                    *ngIf="approvingWaiver"
                    class="custom-spinner"
                    mode="indeterminate" 
                    diameter="20">
                </mat-progress-spinner>
            </button>
        </div>
    </div>

    <div class="spinner-container" *ngIf="isLoadingManagementInfo || loadingAgencyGroupBudget || loadingGlobalBudget">
            <mat-progress-spinner
                class="budget-spinner"
                mode="indeterminate" 
                diameter="50">
            </mat-progress-spinner>
            <span class="spinner-text">{{ 'loadingRequest' | translate }}</span>
    </div>


    <div *ngIf="rejectedWaiver || approvedWaiver" [@slideInOut]>

        <div *ngIf="approvedWaiver">
            <div class="info">
                <img  src="assets/images/waiver-latam/successflytickets.svg" />
                <br>
                <span>
                    {{ 'success.aprovedWaiver' | translate:{ waiverId: waiver.id } }}
                </span>
            </div>
        </div>
    
        <div *ngIf="rejectedWaiver">
            <div class="info">
                <img  src="assets/images/waiver-latam/successflytickets.svg" />
                <br>
                <span>
                    {{ 'success.deniedWaiver' | translate:{ waiverId : waiver.id } }}
                </span>
            </div>
        </div>

        <div class="result-container">
            <table class="mat-table column-type">
                <tr>
                    <th class="title-row">{{ 'field.idRequest' | translate }}</th>
                    <td class="content-row">{{ waiver?.id }}</td>
                </tr>
                <tr>
                    <th class="title-row">Folio</th>
                    <td class="content-row">{{ waiver?.folio }}</td>
                </tr>
                <tr>
                    <th class="title-row">POS</th>
                    <td class="content-row">{{ waiver?.market?.name }}</td>
                </tr>
                <tr>
                    <th class="title-row">{{'field.agency' | translate }}</th>
                    <td class="content-row">{{ waiver?.agencyGroup?.name }}</td>
                </tr>
                <tr>
                    <th class="title-row">IATA</th>
                    <td class="content-row">{{ waiver?.documentIata | dashWhenEmpty  }}</td>
                </tr>
                <tr>
                    <th class="title-row">PNR/TKT</th>
                    <td class="content-row">{{ waiver?.documentNumber }}</td>
                </tr>
                <tr>
                    <th class="title-row">Fecha solicitud</th>
                    <td class="content-row">{{ waiver?.statusDate | dateFormat: 'DD-MMM-YY' | uppercase | dashWhenEmpty}}</td>
                </tr>
                <tr>
                    <th class="title-row">{{ 'field.waiverType' | translate }}</th>
                    <td class="content-row">{{ executiveManagementInfo.waiver.type.name }}</td>
                </tr>
                <tr>
                    <th class="title-row">Monto waiver</th>
                    <td class="content-row">USD {{ waiver.valorization}}</td>
                </tr>
            </table>
        </div>
    </div>

    <div *ngIf="invalidStatus" [@slideInOut]>

        <div class="info">
            <img  src="assets/images/waiver-latam/error.svg" />
            <br>
            <span>
                {{ statusMessage }}
            </span>
        </div>

    </div>
</div>

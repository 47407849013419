
import {timer as observableTimer,  Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';


import { environment } from '../../environments/environment';

import { TokenExpires } from '../models/token-expires.model';

import { RouteLocaleExtractorService } from './route-locale-extractor.service';
import { StorageService } from './storage.service';


@Injectable()
export class KeepAliveService {
  urlBase = `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlUserAdmin}`;

  constructor(
    private http: HttpClient,
    private routeLocaleExtractorService: RouteLocaleExtractorService) { }

  getFutureActionForToken(): Promise<string> {
    return new Promise(
      (resolve, reject) => {
        this.getTokenExpiresIn().subscribe(
          tokenExpires => {
            if (tokenExpires.expiresIn <= 0) {
              resolve('logout');
            }

            observableTimer(tokenExpires.expiresIn * 1000)
              .subscribe(
                () => {
                  resolve('renew');
                },
                (error) => {
                  console.log('error', error);
                }
              );
          },
          (error: HttpErrorResponse) => {
            resolve('logout');
            console.log('we have some error requesting expiration time, so we try to renewToken', error);
          }
        );
      }
    );
  }

  private getTokenExpiresIn(): Observable<TokenExpires> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<TokenExpires>(`${this.urlBase}${locale}/token/expires`);
  }
}

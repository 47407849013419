import { Injectable } from '@angular/core';
import { Router, ActivationStart } from '@angular/router';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class UrlService {
  private errorCodeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private router: Router) { }

  getErrorCode(): Observable<string> {
    return this.errorCodeSubject.asObservable();
  }

  getUrlErrorCode(): Observable<ActivationStart> {
    return this.router.events.pipe(
      filter<ActivationStart>(event => event instanceof ActivationStart)
    );
  }

  setErrorCode(value: string): void {
    this.errorCodeSubject.next(value);
  }
}

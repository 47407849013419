
import {from as observableFrom,  Observable ,  of } from 'rxjs';

import {share, mergeMap,  catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import swal from 'sweetalert2';


import { environment } from '../../environments/environment';

import { SessionInfo } from '../models/SessionInfo.model';

import { RouteLocaleExtractorService } from '../services/route-locale-extractor.service';
import { SessionInfoService } from './session-info.service';
import { WaiverUserManualFile } from '../models/waiver-user-manual-file-model';
import { Language } from '../models/language.model';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './storage.service';


@Injectable()
export class FileStorageService {
  urlBase = `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlWaiver}`;

  constructor(
    private http: HttpClient,
    private routeLocaleExtractorService: RouteLocaleExtractorService,
    private translateService: TranslateService,
    private sessionInfoService: SessionInfoService) { }

  /**
   * returns a file
   * @param fileName file name to download
   */
  public async downloadFile(fileReference: string): Promise<Blob> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return await this.http.get<Blob>(
      `${this.urlBase}${locale}/files?fileName=${fileReference}`,
      { responseType: 'blob' as 'json' }
    ).toPromise();
  }

  /**
   * returns a file guidance
   * @param fileName file name to download
   */
  public downloadFileGuidance(): Observable<Blob> {
    const fileName = 'guidance.pdf';
    const locale = this.routeLocaleExtractorService.getLocale();
    const session: SessionInfo = this.sessionInfoService.getSessionInfo();

    return this.http.get<Blob>(`${this.urlBase}${locale}/files/market/${session.marketId}?fileName=${fileName}`, { responseType: 'blob' as 'json' });
  }

  /**
   * Method that calls the rest service to add files to the file-storage
   * @param file
   */
  uploadFile(file) {
    const locale = this.routeLocaleExtractorService.getLocale();

    let fd = new FormData();

    fd.append('file', file);

    return observableFrom(
      this.sessionInfoService.getSessionInfoAsPromise()).pipe(mergeMap(
        session => {
          let groupId = session.matrix_id;
          return this.http.post(`${this.urlBase}${locale}/files/agency-group/${groupId}`, fd).pipe(share());
        })).pipe(
          catchError(error => of(console.log('error', error)))
        );
  }

  /**
   * Method that calls the rest service to delete file a waiver to the file-storage
   * @param file
   */
  deleteFile(fileReference) {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.delete(`${this.urlBase}${locale}/files?fileName=${fileReference}`).pipe(share());
  }

  downloadWaiverUserManualSuccess(){
    return (data) => {
      const file = new Blob([data], { type: 'application/pdf' });

      const link = URL.createObjectURL(file);
      window.open(link, '_blank');

    };
  }

  downloadWaiverUserManualError(){
    return (error: HttpErrorResponse) => {
      console.log('error', error);
      swal.fire({
        html: '<p>' + this.translateService.instant('login.error.loadError') + '</p>',
        confirmButtonText: this.translateService.instant('button.confirm'),
        confirmButtonColor: '#d33',
        icon: 'error',
        allowOutsideClick: false
      }).then((result) => {
        if (result.value) {
          //Success
        }
      });
    }
};

listWaiverUserManualError(dialogRef){
  return (error: HttpErrorResponse) => {
    if (error.status == 404) {
      if (dialogRef != null){
        dialogRef.close()
      }
      swal.fire({
        html: '<p>' + this.translateService.instant('error.notDataFound') + '</p>',
        confirmButtonText: this.translateService.instant('button.confirm'),
        confirmButtonColor: '#d33',
        icon: 'warning',
        allowOutsideClick: false
      }).then((result) => {
        if (result.value) {
          //Success
        }
      });


    } else {
      swal.fire({
        html: '<p>' + this.translateService.instant('login.error.loadError') + '</p>',
        confirmButtonText: this.translateService.instant('button.confirm'),
        confirmButtonColor: '#d33',
        icon: 'error',
        allowOutsideClick: false
      }).then((result) => {
        if (result.value) {
          //Success
        }
      });
   }
  }
}


  public downloadWaiverUserManual(fileReference: string,language: string): Observable<Blob> {
    return this.http.get<Blob>(`${this.urlBase}/${language}/files?fileName=${fileReference}`, { responseType: 'blob' as 'json' });
  }

  public listWaiverUserManual(): Observable<WaiverUserManualFile[]> {
    const waivermanualsfolder = 'waivermanuals';
    return this.http.get<WaiverUserManualFile[]>(`${this.urlBase}/files/user/manual/${waivermanualsfolder}`);
  }

  public uploadWaiverUserManual(language, waiverCategory, waiverType, file) {

    let fd = new FormData();

    fd.append('file', file);
    return this.http.post(`${this.urlBase}/files/user/manual?language=${language}&waiverCategory=${waiverCategory}&waiverType=${waiverType}`, fd);

  }
}

import { Component, OnInit, Input } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

import { WaiverRequestInfoInterface } from '../../interfaces/waiver-request-info.interface';


@Component({
  selector: 'app-waiver-request-info',
  templateUrl: './waiver-request-info.component.html',
  styleUrls: ['./waiver-request-info.component.css']
})
export class WaiverRequestInfoComponent implements OnInit {
  dataSource = new MatTableDataSource();

  @Input() waiverRequestInfo: WaiverRequestInfoInterface;
  @Input() displayedColumns: string[];



  ngOnInit() {
    this.dataSource.data = [this.waiverRequestInfo];
  }

}

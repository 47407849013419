import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'parseAmount' })
export class CurrencyAmountPipe implements PipeTransform {
  transform(value: string): string {
    let splitted: string[] = value.split('.');

    if (splitted.length > 1 && !isNaN(splitted[0] as any) && !isNaN(splitted[1] as any)) {
      return splitted[0] + '.' + splitted[1].substr(0, 2);
    } else {
      return value;
    }
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { MessageErrorService } from './message-error.service';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class ManualUtilService {
  encodedFile: string;
  errores: string = "";
  allowedFileFormats = ['application/pdf', 'text/pdf', 'application/vnd.ms-excel'];

  constructor(
    private translateService: TranslateService,
    private messageErrorService: MessageErrorService,
    private messageService: MessageService
  ) { }

  showError(errores: string) {
    Swal.fire({
      title: '<p>' + this.translateService.instant('info.fileValidate') + '</p>',
      html: '<p>' + errores + '</p>',
      confirmButtonText: this.translateService.instant('button.confirm'),
      confirmButtonColor: '#d33',
      icon: 'warning',
      allowOutsideClick: false
    });
  }

  deleteFile(
    locationStorage: string,
    deleteFileMethod: (location: string) => Observable<any>,
    loadFilesMethod: () => void,
    oAuthToken?: string
  ) {
    console.log('deleteFile');
    const success = (resp) => {
      this.messageService.showSuccessMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('bda.popup.wellDone'),
          this.translateService.instant('bda.success.deleteFile')
      );
      if (resp != null) {
        loadFilesMethod();
      }
    };

    const failure = (error: HttpErrorResponse) => {
      this.messageErrorService.sendError(error, 'bda.error.deleteFile');
    };

    Swal.fire(this.messageErrorService.prepareDeleteMessage('bda.popup.deleteFile')).then((result) => {
      if (result.value) {
        deleteFileMethod(locationStorage).subscribe({ next: success, error: failure });
        loadFilesMethod();
      }
    });
  }

  downloadFile(
    file: any,
    downloadMethod: (name: string, token?: string) => Observable<Blob>,
    oAuthToken?: string
  ) {
    console.log('downloadFile');
    file.inProgress = true;
    const success = (response: Blob) => {
      // Convertir el Blob en un objeto File
      const downloadedFile = new File([response], file.name, { type: 'text/csv' });

      // Descargar el archivo
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(downloadedFile);
      downloadLink.download = downloadedFile.name;
      downloadLink.click();

      file.inProgress = false;
    };

    const failure = (error: HttpErrorResponse) => {
      console.error('Error al descargar el archivo:', error);
      this.messageErrorService.sendError(error, 'bda.error.downloadFile');

      file.inProgress = false;
    };

    downloadMethod(file.name, oAuthToken).subscribe({ next: success, error: failure });
  }
  handleFileUpload(file: File, allowedFileFormats: string[], messageService: any, translateService: TranslateService, onValidFile: (file: File) => void) {
    if (!allowedFileFormats.includes(file.type) || file.size > 200097152) {
      messageService.showWarningMessage(
        translateService.instant('button.confirm'),
        translateService.instant('warning.invalidFileFormat')
      );
    } else {
      onValidFile(file);
    }
  }

  
  validateFormat(fileUpload: HTMLInputElement, files: Array<any>) {
    const reader = new FileReader();

    reader.onload = () => {
      let myRegex = /^([a-zA-Z0-9 ._-]+)$/;
      const fileName = files[0].data.name;
      let nameCaract = myRegex.test(fileName);
      let nameSize = fileName.length;
      let error = false;
      this.errores = "";

      const binaryString = reader.result as string;
      this.encodedFile = btoa(binaryString);
      let result1 = reader.result as string;
      let result2 = result1.split("\r\n");
      let result3 = result2[0].split(";");

      if (result3[result3.length - 1] == "") {
        result3.pop();
      }

      error = this.validateFile(nameSize, error, nameCaract, fileName, files);

      if (error) {
        this.showError(this.errores);
      }
    }

    fileUpload.onchange = () => {
      let index = fileUpload.files.length - 1;
      const file = fileUpload.files[index];

      if (!this.allowedFileFormats.includes(file.type) || file.size > 200097152) {
        this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.invalidFileFormat')
        );
      } else {
        files.push({
          data: file, state: 'in', inProgress: false, progress: 0, canRetry: false, canCancel: true
        });
        reader.readAsBinaryString(files[0].data);
      }
    };
    fileUpload.value = '';
    fileUpload.click();
  }

  
  private validateFile(nameSize: number, error: boolean, nameCaract: boolean, fileName: string, files: Array<any>) {
    if (nameSize >= 50) {
      this.errores = this.errores.concat(this.translateService.instant('info.fileNameSize') + "<br>");
      this.cancelFile(files[0], files);
      error = true;
    }

    if (!nameCaract || fileName.indexOf('\.') != fileName.lastIndexOf('\.')) {
      this.errores = this.errores.concat(this.translateService.instant('info.fileNameCaract') + "<br>");
      this.cancelFile(files[0], files);
      error = true;
    }
    return error;
  }

  cancelFile(file: any, files: Array<any>) {
    const index = files.indexOf(file);
    if (index > -1) {
      files.splice(index, 1);
    }
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';

import { BulkLoadType } from '../../../models/bulk-load-types.model';
import { FileUploadModel } from '../../../models/file-upload.model';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';
import { BdaService } from '../../../services/bda.service';
import { MessageService } from './../../../services/message.service';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';
import { ControlBda } from '../../../interfaces/control-bda.interface'
import { ProfilingService } from './../../../services/profiling.service';
import { DatePipe } from '@angular/common';
import { UserService } from '../../../services/user.service';
import { MessageErrorService } from '../../../services/message-error.service';

@Component({
  selector: 'app-load-sales-report',
  templateUrl: './load-sales-report.component.html',
  styleUrls: ['./load-sales-report.component.css'],
  animations: [
    trigger(
      'fadeInOut',
      [
        state('in', style({ opacity: 100 })),
        transition('* => void', [animate(300, style({ opacity: 0 }))])
      ]
    )
  ],
  encapsulation: ViewEncapsulation.None
})

export class LoadSalesReportComponent implements OnInit {
  @ViewChild('loadSalesForm') loadSalesForm: NgForm;
  encodedFile: string;

  loadSalesTypeList: BulkLoadType[];
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_VENTA_PORTAL, SidebarMenuModel.SUBMENU_VENTA_PORTAL);


  textFile: File;
  aux: string;
  loading: boolean = true;
  validCsvFormat: boolean = false;
  formatoCsv: string[] = ["DATA_EMISSAO", "DATA_EMBARQUE", "HORA_EMBARQUE", "CIA_BILHETE", "TRECHO",
    "ORIGEM", "DESTINO", "CUPOM", "BILHETE", "TIPO", "CABINE", "CIA_VOO", "VALOR_BRL",
    "EMPRESA", "CNPJ", "IATA_AGENCIA", "BASE_VENDA", "QTD_PAX", "NUM_VOO", "CONSOLIDADA",
    "DATA_EXTRACAO", "HORA_EMISSAO", "DATA_RESERVA", "HORA_RESERVA", "HORA_POUSO",
    "BASE_TARIFARIA", "TKT_DESIG", "FAMILIA_TARIFARIA", "CLASSE_TARIFA", "CLASSE_SERVICO",
    "OND_DIRECIONAL", "TOUR_CODE", "RT_OW", "VALOR_USD", "TARIFA_PUBLICA_BRL", "TARIFA_PUBLICA_USD",
    "PNR_AGENCIA", "PNR_CIA_AEREA", "SELFBOOKING_OFFLINE", "NOME_PAX", "TIPO_PAX", "CPF_PAX",
    "E_MAIL_PAX", "CELULAR_PAX", "TIER_FIDELIDADE_PAX", "TIPO_PAGAMENTO", "DIGITOS_CC", "GRUPO_EMPRESA", "GRUPO_CONSOLIDADA"]


  sizeExcelFile: number[] = [20, 49, 15, 15, 50];
  large: number;
  result3: string[];
  auxSizeExcelFile: string[] = ["Cliente", "Cod.Forn."];
  typeFileId: number;
  format: string = "";
  errores: string = "";
  bdaManual: string = 'bda_manual';


  // ini adjuntar archivos
  /** File extension that accepted, same as 'accept' of <input type='file' />. By the default, it's set to 'image/*'. */
  @Input() accept = '.csv';
  /** Allow you to add handler after its completion. Bubble up response text from remote. */
  @Output() complete = new EventEmitter<string>();
  public files: Array<FileUploadModel> = [];
  files2: FileUploadModel;

  allowedFileFormats = ['application/csv', 'text/csv', 'application/vnd.ms-excel'];
  csvText: File;
  loadSalesSuccessfully: boolean = false;
  loadSalesProcess: boolean = false;
  operationfinishOK: boolean = false;

  // fin adjuntar archivos
  constructor(
    private bdaService: BdaService,
    private messageService: MessageService,
    private sidebarShareDataService: SidebarShareDataService,
    private messageErrorService: MessageErrorService,
    private translateService: TranslateService,
    private profilingService: ProfilingService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.loadSalesTypeList = [{ id: 0, name: "1.0" },
    { id: 1, name: "2.0" }
    ]
  }

  /**
   * Metodo para subir el archivo
   */
  validateFormat() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    const reader = new FileReader();

    reader.onload = () => {
      let myRegex = /^([a-zA-Z0-9 ._-]+)$/;
      const fileName = this.files[0].data.name;
      let nameCaract = myRegex.test(fileName);
      let nameSize = fileName.length;
      let error = false;
      this.errores = "";

      const binaryString = reader.result;
      reader.readAsBinaryString
      this.encodedFile = btoa(binaryString.toString());
      let result1 = reader.result as string;
      let result2 = result1.split("\r\n");

      this.result3 = result2[0].split(";");

      if (this.result3[this.result3.length - 1] == "") {
        this.result3.pop();
      }

      error = this.validate(nameSize, error, nameCaract, fileName);

      if(error){
      swal.fire({
        title: '<p>' + this.translateService.instant('info.fileValidate') + '</p>',
        html: '<p>' + this.errores + '</p>',
        confirmButtonText: this.translateService.instant('button.confirm'),
        confirmButtonColor: '#d33',
        icon: 'warning',
        allowOutsideClick: false
      }).then((result) => {
        if (result.value) {
          this.refreshComponent();
        }
      });
    }
    }


    fileUpload.onchange = () => {

      let index = fileUpload.files.length - 1;
      const file = fileUpload.files[index];


      if (!this.allowedFileFormats.includes(file.type) || file.size > 200097152 ) {
        this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.invalidFileFormat')
        );
      } else {
        this.files.push({
          data: file, state: 'in', inProgress: false, progress: 0, canRetry: false, canCancel: true
        });
        reader.readAsBinaryString(this.files[0].data);
      }
    };
    fileUpload.value = '';
    fileUpload.click();
  }


  private validate(nameSize: number, error: boolean, nameCaract: boolean, fileName: string) {
    if (nameSize >= 50) {
      this.errores = this.errores.concat(this.translateService.instant('info.fileNameSize') + "<br>");
      this.cancelFile(this.files[0]);
      error = true;
    }

    if (!nameCaract || fileName.indexOf('\.') != fileName.lastIndexOf('\.')) {
      this.errores = this.errores.concat(this.translateService.instant('info.fileNameCaract') + "<br>");
      this.cancelFile(this.files[0]);
      error = true;
    }

    if ((this.typeFileId == 0 && this.result3.length != this.sizeExcelFile[0]) || (this.typeFileId == 1 && this.result3.length != this.sizeExcelFile[1])) {
      this.errores = this.errores.concat(this.translateService.instant('info.selectTipe') + "<br>");
      this.cancelFile(this.files[0]);
      error = true;
    }
    return error;
  }

  /**
   * @param file
   */
  cancelFile(file: FileUploadModel) {
    const index = this.files.indexOf(file);
    if (index > -1) {
      this.files.splice(index, 1);
    }
    this.validCsvFormat = false;
  }

  /**
   * Metodo que inicia el proceso de carga masiva
   */
  public initBulkLoad() {
    const file = this.files[0].data;
    const exDate = new Date(Date.now());
    const datepipe: DatePipe = new DatePipe('en-US')
    let formattedDate = datepipe.transform(exDate, 'yyyy-MM-ddTHH:mm:ss.sss')
    let controlDataBDA = new ControlBda();
    controlDataBDA.filename = file.name.split(' ').join('_');
    controlDataBDA.userEmail = localStorage.getItem('userMail');
    controlDataBDA.fileFormat = this.format.split(' ').join('_');

    controlDataBDA.executionDate = formattedDate.toString() + "Z"
    controlDataBDA.agencyGroup = this.profilingService.decodedTokenTemp.association_name

    this.showLoading();

    this.userService.getAgencyUserToken().subscribe({
      next: this.successUserToken(file, controlDataBDA),
      error: this.sendErrorMessage()
  });
    this.cancelFile(this.files[0]);
  }

  private showLoading() {
    swal.fire({
      title: this.translateService.instant('bda.wait'),
      html: this.translateService.instant('bda.process'),
      allowOutsideClick: false,
      allowEscapeKey: false,
      heightAuto: false,
      onBeforeOpen: () => {
        swal.showLoading();
      },
    });
  }

  successUserToken(file, controlDataBDA){
    return (result): void => {
      let oAuthToken = result.sessionToken;

      this.bdaService.uploadFile(file, oAuthToken, controlDataBDA).subscribe({

        next: data => {

          swal.fire({
            html: '<p>' + this.translateService.instant('success.loadSuccess') + '</p>',
            confirmButtonText: this.translateService.instant('button.confirm'),
            confirmButtonColor: '#d33',
            icon: 'success',
            allowOutsideClick: false
          }).then((result) => {
            if (result.value) {
              //Success
              this.refreshComponent();
            }
          });
        },
        error: this.sendErrorMessage()
    });
    }
  }

  private sendErrorMessage (){
    return (error: HttpErrorResponse) => {
      console.log('error', error);
      if (this.messageErrorService.sendErrorSecondVersion(error)){
        this.refreshComponent();
      }
    };
  }

  /**
  * Metodo que sirve para refrescar la pagina
  */
  refreshComponent() {
    if (this.operationfinishOK) {
      this.loadSalesSuccessfully = false;
      this.loadSalesProcess = false;
      this.operationfinishOK = false;
      this.loading = true;
      this.loadSalesTypeList = [];
      this.files = [];
      this.ngOnInit();
    }
  }

  valueSelect(event: any) {
    this.format = event.value.name;
    this.typeFileId = event.value.id;
    this.large = this.sizeExcelFile[event.value.id];
    this.cancelFile(this.files[0]);
  }

  arrayObjToCsv(ar, name) {
    //comprobamos compatibilidad
    if (window.Blob && (window.URL || window.webkitURL)) {
        let save;
        let clicEvent;
      const content = this.createFileContent(name, ar);

      let blob = new Blob(["\ufeff", content], { type: 'text/csv' });

      let reader2 = new FileReader();
      reader2.onload = function (event) {
        save = document.createElement('a');
        save.href = event.target.result;
        save.target = '_blank';
        save.download = "Modelo BDA " + name + ".csv";
        try {
          clicEvent = new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': true
          });
        } catch (e) {
          //si llega aquí es que probablemente implemente la forma antigua de crear un enlace
          clicEvent = document.createEvent("MouseEvent");
          clicEvent.initEvent('click', true, true);
        }
        save.dispatchEvent(clicEvent);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
      reader2.readAsDataURL(blob);
    } else {
      alert("Su navegador no permite esta acción");
    }
  };

  ExampleArchivo(event) {
    this.arrayObjToCsv(this.formatoCsv, event);
  }

  private createFileContent(name: any, ar: any) {
    let content = "";
    if (name == 2.0) {
      for (let i = 0; i < ar.length; i++) {
        //construimos cabecera del csv
        content += ar[i];
        if (i != ar.length - 1) {
          content += ";";
        }
      }
    } else {
      for (let i = 0; i < 20; i++) {
        content += ar[i];
        if (i != ar.length - 1) {
          content += ";";
        }
      }
    }
    return content;
  }

  downloadBdaManual(){
    this.userService.getAgencyUserToken().subscribe({
      next: (resp) => {
        this.bdaService.downloadManualFile(resp.sessionToken)
          .subscribe(
            (resp) => {
              const file = new File([resp], this.bdaManual, { type: 'application/pdf' });
              const downloadLink = document.createElement('a');
              downloadLink.href =  URL.createObjectURL(file);
              downloadLink.download = file.name;
              downloadLink.click();
            }
          );
      },
      error: (err) => {
          this.messageErrorService.sendError(err, 'bda.error.downloadFile');
      }
    });
  }
  

}



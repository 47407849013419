<div class="rejection-container">
  <form [formGroup]="rejectionForm">  
    <mat-form-field appearance="fill">
      <mat-label>Comentario</mat-label>
      <textarea matInput formControlName="statusComment" name="statusComment" placeholder="{{ 'field.enterComment' | translate }}"></textarea>
      <mat-error *ngIf="rejectionForm.get('statusComment').hasError('required') && rejectionForm.get('statusComment').touched">
        {{ 'field.error.required' | translate}}
      </mat-error>    </mat-form-field>

    <div class="action-buttons">
      <button mat-flat-button type="button" class="reject" (click)="cancel()">Cancelar</button>
      <button mat-flat-button type="button" class="confirm" (click)="confirmRejection(rejectionForm)">Confirmar</button>
    </div>
    
  </form>
</div>
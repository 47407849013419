<div class="container">
  <div class="row mt-4">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="latam-sans breadcrumb-item">
            <a>{{ 'menu.agenciesManagement' | translate }}</a>
          </li>
          <li class="latam-sans breadcrumb-item">
            <a>{{ 'menu.agencyList' | translate }}</a>
          </li>
          <li class="breadcrumb-item latam-sans active" aria-current="page">
            <a>{{ 'agencyProfile' | translate }}</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col">
      <span class="title">{{ 'agencyProfile' | translate }}</span>
      <span *ngIf="!loading" class="title-red">{{ agencyProfile.tradeName }}</span>
    </div>
  </div>

  <!-- line -->
  <hr>

  <!-- acordion -->
  <div *ngIf="loading" class="loading">
    <div class="loading-div">
      <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
      <span class="loading-span">{{ 'loading' | translate }}</span>
    </div>
  </div>

  <ng-container *ngIf="!loading">
    <mat-accordion>
      <mat-expansion-panel class="profile-agency" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title id="agencyInfoTitle" class="subtitle profile-agency-title">{{ 'agencyInformation' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <!-- Informacion Agencia para LATAM-->
        <ng-container *ngIf="profiles.readAgencyProfileLT">
          <div class="row ml-3 mr-3">
            <div class="col table-responsive">
              <table class="table table-bordered">
                <tbody>
                  <tr class="table-tuple">
                    <th id="tradeNameTh" class="text-nowrap" scope="row">{{ 'field.tradeName' | translate }}</th>
                    <td class="text-content">{{ agencyProfile.tradeName | dashWhenEmpty }}</td>
                    <th id="typeOfTargetTh" class="text-nowrap" scope="row">{{ 'field.typeOfTarget' | translate }}</th>
                    <td class="text-content">{{ agencyProfile.typeTargetDescription | dashWhenEmpty }}</td>
                  </tr>

                  <tr class="table-tuple">
                    <th id="agencyGroupTh" class="text-nowrap" scope="row">{{ 'field.agencyGroup' | translate }}</th>
                    <td class="text-content">{{ agencyProfile.groupDescription | dashWhenEmpty }}</td>
                    <th id="channel1Th" class="text-nowrap" scope="row">{{ 'field.channel1' | translate }}</th>
                    <td class="text-content">{{ agencyProfile.salesChannelDescription | dashWhenEmpty }}</td>
                  </tr>
                  <tr class="table-tuple">
                    <th id="admcIdTh" class="text-nowrap" scope="row">{{ 'field.admcId' | translate }}</th>
                    <td class="text-content">{{ agencyProfile.agencyCode | dashWhenEmpty }}</td>
                    <th id="channel2Th" class="text-nowrap" scope="row">{{ 'field.channel2' | translate }}</th>
                    <td class="text-content">{{ agencyProfile.typeAgencyDescription | dashWhenEmpty }}</td>
                  </tr>
                  <tr class="table-tuple">
                    <th id="admcBranchIdTh" class="text-nowrap" scope="row">{{ 'field.admcBranchId' | translate }}</th>
                    <td class="text-content">{{ agencyProfile.agencyBranch | dashWhenEmpty }}</td>
                    <th id="channel3Th" class="text-nowrap" scope="row">{{ 'field.channel3' | translate }}</th>
                    <td class="text-content">{{ agencyProfile.issuingChannelDescription | dashWhenEmpty }}</td>
                  </tr>
                  <tr class="table-tuple">
                    <th id="iataTh" class="text-nowrap" scope="row">{{ 'field.iatas' | translate }}</th>
                    <td class="text-content">{{ agencyProfile.iatas | dashWhenEmpty | arrayToString }}</td>
                    <th id="channel4Th" class="text-nowrap" scope="row">{{ 'field.channel4' | translate }}</th>
                    <td class="text-content">{{ agencyProfile.consortiumDescription | dashWhenEmpty }}</td>
                  </tr>
                  <tr class="table-tuple">
                    <th id="taxIdTh" class="text-nowrap" scope="row">{{ 'field.taxId' | translate }}</th>
                    <td class="text-content">{{ agencyProfile.taxIdentifier | dashWhenEmpty }}</td>
                    <th id="accountManagerTh" class="text-nowrap" scope="row">{{ 'field.accountManager' | translate }}</th>
                    <td class="text-content">{{ ((agencyProfile.salesForceFirstName ? agencyProfile.salesForceFirstName : '') + ' ' + (agencyProfile.salesForceLastName ? agencyProfile.salesForceLastName : '')) | dashWhenEmpty }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ng-container>

        <!-- Informacion Admin Usuario Agencia -->
        <ng-container *ngIf="profiles.readAgencyProfile">
          <div class="row ml-3 mr-3">
            <div class="col table-responsive">
              <table class="table table-bordered">
                <tbody>
                  <tr class="table-tuple">
                    <th id="tradeNameTh" class="text-nowrap" scope="row">{{ 'field.tradeName' | translate }}</th>
                    <td class="text-content">{{ agencyProfile.tradeName | dashWhenEmpty }}</td>
                    <th id="iataTh" class="text-nowrap" scope="row">{{ 'field.iatas' | translate }}</th>
                    <td class="text-content">{{ agencyProfile.iatas | dashWhenEmpty | arrayToString }}</td>
                  </tr>

                  <tr class="table-tuple">
                    <th id="taxIdTh" class="text-nowrap" scope="row">{{ 'field.taxId' | translate }}</th>
                    <td class="text-content">{{ agencyProfile.taxIdentifier | dashWhenEmpty }}</td>
                    <th id="accountManagerTh" class="text-nowrap" scope="row">{{ 'field.accountManager' | translate }}
                    </th>
                    <td class="text-content">
                      {{ ((agencyProfile.salesForceFirstName ? agencyProfile.salesForceFirstName : '') + ' ' + (agencyProfile.salesForceLastName ? agencyProfile.salesForceLastName : '')) | dashWhenEmpty }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ng-container>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="profiles.readAgencyProfile || profiles.readAgencyProfileLT" class="profile-agency">
        <mat-expansion-panel-header>
          <mat-panel-title id="contactInformationDivTitle" class="subtitle profile-agency-title">
            {{ 'contactInformation' | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row ml-3 mr-3">
          <div class="col table-responsive">
            <table class="table table-bordered">
              <tbody>
                <tr class="table-tuple">
                  <th id="addressTh" class="text-nowrap" scope="row">{{ 'field.address' | translate }}</th>
                  <td class="text-content">{{ agencyProfile.addressStreet | dashWhenEmpty }}</td>
                  <th id="countryTh" class="text-nowrap" scope="row">{{ 'field.country' | translate }}</th>
                  <td class="text-content">{{ agencyProfile.countryDescription | dashWhenEmpty }}</td>
                </tr>

                <tr class="table-tuple">
                  <th id="cityTh" class="text-nowrap" scope="row">{{ 'field.city' | translate }}</th>
                  <td class="text-content">{{ agencyProfile.cityDescription | dashWhenEmpty }}</td>
                  <th id="phoneNumberTh" class="text-nowrap" scope="row">{{ 'field.phoneNumber' | translate }}</th>
                  <td class="text-content">
                    {{ ((agencyProfile.phoneExtNumber ? agencyProfile.phoneExtNumber : '') + ' ' + (agencyProfile.phoneNumber ? agencyProfile.phoneNumber : '')) | dashWhenEmpty }}
                  </td>
                </tr>

                <tr class="table-tuple">
                  <th id="stateTh" class="text-nowrap" scope="row">{{ 'field.state' | translate }}</th>
                  <td class="text-content">{{ agencyProfile.stateDescription | dashWhenEmpty }}</td>
                  <th id="legalRepresentativeTh" class="text-nowrap" scope="row">
                    {{ 'field.legalRepresentative' | translate }}</th>
                  <td class="text-content">{{ agencyProfile.nameLegalRepresentative | dashWhenEmpty }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel id="editAgencyDiv" *ngIf="profiles.readAgencyProfileLT" class="profile-agency">
        <mat-expansion-panel-header>
          <mat-panel-title id="extraInformationDivTitle" class="subtitle profile-agency-title">
            {{ 'additionalInformation' | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <form #profileAgencyForm="ngForm">
          <div class="row ml-3 mr-3">
            <div class="col table-responsive">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td class="td-custom-width">
                      <mat-form-field class="profile-agency" floatLabel="always">
                        <mat-label>{{ 'field.market' | translate }}</mat-label>
                        <input matInput #marketName="ngModel" name="marketName" id="marketName" [(ngModel)]="agencyProfile.marketName"
                          [disabled]="true" />
                      </mat-form-field>
                    </td>

                    <td></td>
                  </tr>

                  <tr>
                    <td class="td-custom-width">
                      <mat-form-field class="profile-agency" floatLabel="always">
                        <mat-label>{{ 'field.agencyGroup' | translate }}</mat-label>
                        <mat-select id="agencyGroupId"
                          name="agencyGroupId" [ngModel]="agencyProfile.agencyGroup.id" #agencyGroupId="ngModel"
                          [disabled]="!controlDisabled || groupList.length === 0" required
                          (selectionChange)="setAgencyGroup($event)">
                          <mat-option *ngFor="let group of groupList" [value]="group.id">
                            {{ group.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="agencyGroupId.invalid && (agencyGroupId.dirty || agencyGroupId.touched) && agencyGroupId.errors.required">
                          {{ 'field.error.required' | translate }}</mat-error>
                      </mat-form-field>
                    </td>

                    <td>
                      <mat-form-field class="profile-agency" floatLabel="always">
                        <mat-label>{{ 'field.branchOffice' | translate }}</mat-label>
                        <mat-select id="branchOfficeId"
                          [ngModel]="agencyProfile.branchOffice.id" name="branchOfficeId" #branchOfficeId="ngModel"
                          [disabled]="!controlDisabled || branchOfficeList.length === 0"
                          (selectionChange)="setBranchOffice($event)">
                          <mat-option [value]="0"></mat-option>
                          <mat-option *ngFor="let branchOffice of branchOfficeList" [value]="branchOffice.id">
                            {{ branchOffice.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <mat-form-field class="profile-agency" floatLabel="always">
                        <mat-label>{{ 'field.officeId' | translate }}</mat-label>
                        <input matInput name="officeId" id="officeId"
                          [(ngModel)]="agencyProfile.officeId" #officeId="ngModel" pattern="([a-zA-Z0-9])+"
                          [maxlength]="10" [disabled]="!controlDisabled" />
                        <mat-error
                          *ngIf="officeId.invalid && (officeId.dirty || officeId.touched) && officeId.errors.pattern">
                          {{ 'field.error.invalidOfficeId' | translate }}</mat-error>
                      </mat-form-field>
                    </td>

                    <td>
                      <mat-form-field class="profile-agency" floatLabel="always">
                        <mat-label>{{ 'field.pcc' | translate }}</mat-label>
                        <input matInput name="pcc" id="pcc"
                          [(ngModel)]="agencyProfile.pcc" #pcc="ngModel" [disabled]="!controlDisabled"
                          pattern="([a-zA-Z0-9])+" [maxlength]="10" />
                        <mat-error *ngIf="pcc.invalid && (pcc.dirty || pcc.touched) && pcc.errors.pattern">
                          {{ 'field.error.invalidPCC' | translate }}</mat-error>
                      </mat-form-field>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <ng-container *ngIf="!agencyProfile.piaAgencyCode">
                        <mat-checkbox id="generatePiaCode" name="generatePiaCode" [(ngModel)]="generatePiaCode"
                          [disabled]="!controlDisabled" (change)="checkedPia($event)" class="pia-check">
                          {{ 'field.generatePiaCode' | translate }}</mat-checkbox>
                      </ng-container>

                      <ng-container *ngIf="agencyProfile.piaAgencyCode">
                        <mat-form-field class="profile-agency" floatLabel="always">
                          <mat-label>{{ 'field.piaCode' | translate }}</mat-label>
                          <input matInput name="piaAgencyCode" id="piaAgencyCode" [(ngModel)]="agencyProfile.piaAgencyCode"
                            #piaAgencyCode="ngModel" [disabled]="true" />
                        </mat-form-field>
                      </ng-container>
                    </td>

                    <td>
                      <mat-form-field class="profile-agency pia-cell" floatLabel="always">
                        <mat-label>{{ 'field.piaAgencyType' | translate }}</mat-label>
                        <mat-select id="piaAgencyType" name="piaAgencyType" #piaAgencyType="ngModel" [(ngModel)]="selectedPiaAgencyType"
                          required
                          [disabled]="!controlDisabled || piaAgencyTypeList.length === 0 || !generatePiaCode || (agencyProfile.piaAgencyCode != null && agencyProfile.piaAgencyCode !== '')"
                          (selectionChange)="changePiaAgencyType($event)">
                          <mat-option *ngFor="let piaAgencyType of piaAgencyTypeList" [value]="piaAgencyType">
                            {{ piaAgencyType.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="piaAgencyType.invalid && (piaAgencyType.dirty || piaAgencyType.touched) && piaAgencyType.errors.required">
                          {{ 'field.error.required' | translate }}</mat-error>
                      </mat-form-field>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </form>

        <div class="row ml-3 mb-3 mr-3">
          <div class="col-sm-6 col-md-8 col-lg-9 col-xl-9 text-right">
            <mat-icon *ngIf="!updating" class="icon-green" [ngClass]="{ 'icon-green-hidden': !updatedSuccessfully }">
              check</mat-icon>
            <div class="updating-loader" *ngIf="updating">
              <svg class="spinner" width="24px" height="24px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                </circle>
              </svg>
            </div>
          </div>

          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 text-left">
            <button mat-raised-button *ngIf="controlDisabled" id="updateButton"
              [disabled]="(updating || profileAgencyForm.invalid || !profileAgencyForm.dirty) && agencyIsActive"
              (click)="updateAgencyData()" class="background-red w-100">
              <span>{{ 'button.save' | translate }}</span>
            </button>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="row mt-4 mb-4">
      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <button mat-raised-button id="backButton" (click)="goBack()"
          class="btn-volver w-100">{{ 'button.back' | translate }}</button>
      </div>
    </div>
  </ng-container>
</div>

import { Injectable } from '@angular/core';


@Injectable()
export class RouteLocaleExtractorService {
  locale: string;

  public getLocale(): string {
    this.locale = localStorage.getItem('language');

    if (this.locale != null) {
      return '/' + this.locale;
    } else {
      return '/' + 'en';
    }
  }
}

<div class="container">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="latam-sans breadcrumb-item">
            <a>{{ 'menu.waivers' | translate }}</a>
          </li>
          <li class="breadcrumb-item latam-sans active" aria-current="page">
            <a>{{ 'menu.afterSaleManagement' | translate }}</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <span class="title">{{ 'menu.afterSaleManagement' | translate }}</span>
    </div>

  </div>
  <hr>
  <div class="row mt-3">
    <div class="col">
      <form [formGroup]="formFilter">
        <div class="row">
          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2" id="list-waiver-outsourcing-idRequest">
            <mat-form-field>
              <mat-label>{{ 'field.idRequest' | translate }}</mat-label>
              <input matInput id="idRequest" type="number"  formControlName="idRequest"
                maxlength="100">

            </mat-form-field>

          </div>
          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2" id="list-waiver-outsourcing-pos">
            <mat-form-field>
              <mat-label>{{ 'field.pos' | translate }}</mat-label>
              <input matInput id="pos"  formControlName="pos"
                maxlength="100">

            </mat-form-field>

          </div>
          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2" id="list-waiver-outsourcing-agency">
            <mat-form-field>
              <mat-label>{{ 'field.agency' | translate }}</mat-label>
              <input matInput id="agency"  formControlName="agency"
                maxlength="100">

            </mat-form-field>

          </div>
          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2" id="list-waiver-outsourcing-iata">
            <mat-form-field>
              <mat-label>{{ 'field.iata' | translate }}</mat-label>
              <input matInput id="iata" formControlName="iata"
                maxlength="100">

            </mat-form-field>

          </div>

          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2" id="list-waiver-outsourcing-requestDate">
            <mat-form-field>
              <mat-label>{{ 'field.requestDateHour' | translate }}</mat-label>
              <input id="requestDate" matInput formControlName="requestDate"
                [matDatepicker]="startPicker" name="requestDate">
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker (opened)="setCalendarLanguage()"></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2">

          </div>

          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2" id="list-waiver-outsourcing-salesEjecutive">
            <mat-label>{{ 'field.executiveSale' | translate }}</mat-label>
            <mat-form-field>
              <input matInput id="salesEjecutive" formControlName="salesEjecutive"
                maxlength="100">

            </mat-form-field>

          </div>
          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2" id="list-waiver-outsourcing-type">
            <mat-label>{{ 'field.type' | translate }}</mat-label>
            <mat-form-field>
              <input matInput id="type" formControlName="type"
                maxlength="100">

            </mat-form-field>

          </div>
          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2" id="list-waiver-outsourcing-motive">
            <mat-form-field>
              <mat-label>{{ 'field.reason' | translate }}</mat-label>
              <input matInput id="motive"  formControlName="motive"
                maxlength="100">

            </mat-form-field>

          </div>
          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2" id="list-waiver-outsourcing-pnrTkt">
            <mat-form-field>
              <mat-label>{{ 'field.pnrTkt' | translate }}</mat-label>
              <input matInput id="pnrTkt"  formControlName="pnrTkt"
                maxlength="100">

            </mat-form-field>

          </div>

          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2" id="list-waiver-outsourcing-flightDate">
            <mat-form-field>
              <mat-label>{{ 'field.flightDate' | translate }}</mat-label>
              <input id="fDate" matInput  formControlName="flightDate"
                [matDatepicker]="endPicker" name="flightDate">
              <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
              <mat-datepicker #endPicker (opened)="setCalendarLanguage()"></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2">
            <button (click)="filterWaiverList()" mat-button [disabled]="formFilter.invalid"   class="mat-raised-button background-blue w-100">{{ 'button.search' | translate }}</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <mat-chip-list [ngClass]="{ 'd-none': loadingPendingData || countPendingData < 0 || errorLoadingPendingData}">
    <mat-chip id="countBadge">{{ countPendingData | parseWaiverCount }}</mat-chip>
  </mat-chip-list>

  <mat-card>
    <mat-card-content>
      <mat-tab-group #tabGroup (selectedIndexChange)="tabChange($event)">
        <mat-tab>
          <ng-template mat-tab-label>
            <span>{{ 'pendings' | translate }}</span>
          </ng-template>

          <ng-container *ngIf = "!loadingPendingData">
            <app-waiver-list-table #pendingWaiverTable
              [waiverData] = "pendingData"
              [totalRows] = "countPendingData"
              [currentPage] = "pendingCurrentPage"
              [tabNumber] = "1"
              [pageSize] = "pendingPageSize"
              (pageEvent) = "pageEventPending($event)"
              (reloadTable) = "reloadTable($event)"
              (watchDetailsEvent) = "openDialog($event)"
            >
            </app-waiver-list-table>
          </ng-container>

          <div *ngIf="loadingPendingData" class="loading">
            <div class="loading-div">
              <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                </circle>
              </svg>
              <span class="loading-span">{{ 'loading' | translate }}</span>
            </div>
          </div>

        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <span>{{ 'approveds' | translate }}</span>
          </ng-template>
          <ng-container *ngIf = "!loadingApprovedData">
              <app-waiver-list-table #approvedWaiverTable
                [waiverData] = "approvedData"
                [totalRows] = "countApprovedData"
                [currentPage] = "approvedCurrentPage"
                [tabNumber] = "2"
                [pageSize] = "approvedPageSize"
                (pageEvent) = "pageEventApproved($event)"
                (reloadTable) = "reloadTable($event)"
                (watchDetailsEvent) = "openDialog($event)"
              >
            </app-waiver-list-table>
          </ng-container>

          <div *ngIf="loadingApprovedData" class="loading">
            <div class="loading-div">
              <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                </circle>
              </svg>
              <span class="loading-span">{{ 'loading' | translate }}</span>
            </div>
          </div>

        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <span>{{ 'denieds' | translate }}</span>
          </ng-template>
          <ng-container *ngIf = "!loadingRejectedData">
              <app-waiver-list-table #rejectedWaiverTable
                [waiverData] = "rejectedData"
                [totalRows] = "countRejectedData"
                [currentPage] = "rejectedCurrentPage"
                [tabNumber] = "3"
                [pageSize] = "rejectedPageSize"
                (pageEvent) = "pageEventRejected($event)"
                (reloadTable) = "reloadTable($event)"
                (watchDetailsEvent) = "openDialog($event)"
              >
            </app-waiver-list-table>
          </ng-container>

          <div *ngIf="loadingRejectedData" class="loading">
            <div class="loading-div">
              <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                </circle>
              </svg>
              <span class="loading-span">{{ 'loading' | translate }}</span>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>

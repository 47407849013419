<form [formGroup]="formG" class="recovery-form">
  <div class="row justify-content-center">
    <div class="col-sm-8 col-md-8 col-lg-6 col-xl-6">
      <mat-form-field>
        <mat-label>{{ 'login.field.email' | translate }}</mat-label>
        <input matInput email id="email" type="email" formControlName="email" required>
        <mat-error
          *ngIf="validateRequiredEmail()">
          {{ 'field.error.required' | translate }}</mat-error>
        <mat-error
          *ngIf="validateEmail()">
          {{ 'field.error.invalidEmail' | translate }}</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row justify-content-center mt-4">
    <div class="col-sm-8 col-md-8 col-lg-6 col-xl-6">
      <button [disabled]="!formG.valid || isDisabled" (click)="recoveryPass()"
        class="btn btn-Latam w-100">{{ 'button.resetPass' | translate }}</button>
    </div>
  </div>

  <div class="row justify-content-center mt-3">
    <div class="col-sm-8 col-md-8 col-lg-6 col-xl-6">
      <button class="btn btn-back w-100" (click)="returnLogin()">{{ 'button.back' | translate }}</button>
    </div>
  </div>
</form>

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { LoaderService } from './../../../services/loader.service';
import { LoginAgencyService } from './../../../services/login-agency.service';
import { MessageService } from './../../../services/message.service';


@Component({
  selector: 'app-recovery-pass',
  templateUrl: './recovery-pass.component.html',
  styleUrls: ['./recovery-pass.component.css']
})
export class RecoveryPassComponent implements OnInit {
  isDisabled = false;
  formG: UntypedFormGroup;

  @Output() message =  new EventEmitter<string>();
  @Output() showRecoveryPass: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private formB: UntypedFormBuilder,
    private loaderService: LoaderService,
    private loginAgencyService: LoginAgencyService,
    private messageService: MessageService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.formG = this.formB.group({
      email: [null, Validators.required]
    });
  }

  recoveryPass() {
    if (this.formG.valid) {
      this.loaderService.show();
      this.isDisabled = true;

      this.loginAgencyService.recoveryPassword(this.formG.get('email').value)
        .then(
          value => {
            this.messageService.showSuccessMessage(
              this.translateService.instant('button.confirm'),
              this.translateService.instant('popup.wellDone'),
              this.translateService.instant('login.resetPass')
            );

            this.returnLogin();
            this.isDisabled = false;
            this.loaderService.hide();
          }
        )
        .catch(
          error => {
            this.message.emit('login.error.invalidEmail');
            this.isDisabled = false;
            this.loaderService.hide();
            console.log('error', error);
          }
        );
    } else {
      this.validateAllFormFields(this.formG);
    }
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);

      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  returnLogin(): void {
    this.showRecoveryPass.emit(false);
  }

  validateRequiredEmail(){
    return this.formG.controls.email.invalid && (this.formG.controls.email.dirty || this.formG.controls.email.touched) && this.formG.controls.email.errors.required;
  }

  validateEmail(){
    return this.validateRequiredEmail() && this.formG.controls.email.errors.email;
  }
}

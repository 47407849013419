<div class="container">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="latam-sans breadcrumb-item">
            <a>{{ 'menu.agenciesManagement' | translate }}</a>
          </li>
          <li class="breadcrumb-item latam-sans active" aria-current="page">
            <a>{{ 'menu.activateAgency' | translate }}</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <span id="searchAgencyTitle" class="title">{{ 'menu.activateAgency' | translate }}</span>
    </div>
  </div>

  <hr>

  <div class="row mt-3">
    <div class="col">
      <span class="subtitle text-break">{{ 'searchAgencies' | translate }}</span>
    </div>
  </div>

  <!-- buscador -->
  <div class="row mt-3">
    <div class="col-10 col-sm-4 col-md-4 col-lg-4 col-xl-4">
      <mat-form-field>
        <mat-label>{{ 'field.iata' | translate }}</mat-label>
        <input matInput id="searchAgencyIataInput" name="iata" #iata="ngModel" [(ngModel)]="agencyIata"
          [disabled]="disableIata" (change)="iataFieldChanged($event.target.value)" maxlength="9"
          pattern="([0-9]{7})-([0-9]{1})">
        <mat-hint align="end">{{ 'tooltip.rutExample' | translate }}</mat-hint>
        <mat-error *ngIf="iata.errors && (iata.dirty || iata.touched) && iata.errors.pattern">
          {{ 'field.error.invalidIata' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-2">
      <button mat-icon-button id="searchAgencyIataButton"
        [disabled]="iata.errors || iata.value === undefined || iata.value === '' || disableIata || loading"
        (click)="getAgencyByIata()" class="icon-btn background-red">
        <mat-icon aria-label="">search</mat-icon>
      </button>
    </div>
  </div>

  <div class="row"
    [ngClass]="{ 'justify-content-start': message.show && message.from == 'name', 'justify-content-end': message.show && message.from == 'iata' }">
    <div *ngIf="message.show && (message.from == 'name' || message.from == 'iata')" id="agencySearchErrorMessage"
      class="col-6">
      <div class="alert-search-agency alert alert-warning pt-0 pr-0 pb-0 pl-0" role="alert">
        <div class="alert-icon-div bg-warning-alert-dark">
          <i class="material-icons">error_outline</i>
        </div>
        <div class="alert-text-div bg-warning-alert">
          <p *ngIf="message.status === 2" id="agencyNotExist" class="mb-0">
            {{ 'warning.agencyNotExist' | translate }}</p>
          <p *ngIf="message.status === 1" id="notAvailable" class="mb-0">
            {{ 'error.notAvailable' | translate }}</p>
        </div>
      </div>
    </div>
  </div>

  <!-- loading -->
  <div *ngIf="loading" class="loading">
    <div class="loading-div">
      <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
      <span class="loading-span">{{ 'loading' | translate }}</span>
    </div>
  </div>

  <ng-container *ngIf="validSearch && !loading">
    <hr>

    <div class="row mt-3">
      <div class="col">
        <span class="subtitle">{{ 'searchResults' | translate }}</span>
      </div>
    </div>

    <div class="row mt-3 mb-3">
      <div class="col">
        <div class="res-busqueda">
          <mat-table [dataSource]="dataAgency.data" #sort="matSort" matSort>
            <ng-container matColumnDef="tradeName">
              <mat-header-cell id="agencyNameColumnHeader" *matHeaderCellDef mat-sort-header>
                {{ 'field.agencyName' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let object">{{ object.tradeName | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="iataCode">
              <mat-header-cell id="iataCodeColumnHeader" *matHeaderCellDef mat-sort-header>
                Iata</mat-header-cell>
              <mat-cell *matCellDef="let object">{{ object.iataCode | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="zipCode">
              <mat-header-cell id="branchOfficeIdColumnHeader" *matHeaderCellDef mat-sort-header>
                {{ 'field.zipCode' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let object">{{ object.zipCode | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <!-- esta acción lleva a validar agencia -->
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef id="actionsColumnHeader">
                {{ 'button.actions' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <button *ngIf="profiles.readActivateAgency" class="action">
                  <span
                    (click)="searchActivate()">{{ 'button.activate' | translate }}</span>
                </button>
                <!-- <i class="action-icon material-icons">more_vert</i>  -->
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns" class="search-agency-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"
              [ngClass]="{ 'highlight': selectedRowIndex === i }" (mouseover)="highlightRow($event, i)"
              (mouseout)="highlightRow($event, i)" class="search-agency-row"></mat-row>
          </mat-table>

          <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 30, 50]" [showFirstLastButtons]="false">
          </mat-paginator>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="main-container">
  <div class="section-container">
      <div class="left-section">

          <a (click)="stepperBack()" class="pointer"><span class="go-back"><mat-icon>arrow_back_ios</mat-icon> {{ 'button.back' | translate}}</span></a>

          <h2 class="test-color">Waivers</h2>
          <h4 class="test-color">{{ 'waiverLatam.selectWaiverType' | translate}}</h4>

          <div class="form-card">

            <ng-container *ngIf="waiverModule === 'POL'">
              <mat-form-field appearance="outline">
                  <mat-label>{{ 'field.motive' | translate }}</mat-label>
                  <mat-select [(ngModel)]="selectedWaiverMotive" required>
                      <mat-option
                          *ngFor="let motive of waiverMotives"
                          [value]="motive">
                          {{ motive.name | translate }}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
          </ng-container>
          <mat-error style="width: 100%" *ngIf="showWaiverTypeErrors && selectedWaiverMotive?.name == ''">
              <p class="error">Debes seleccionar un motivo</p>
          </mat-error>

          <ng-container *ngIf="(isWaiverAvailable('RNA') || isWaiverAvailable('RAW')) && 
                              (isWaiverAvailable('CNA') || isWaiverAvailable('CFP'))" 
                      data-section="waiver-type-selector">
              <mat-form-field appearance="outline" class="waiver-type-field">
                  <mat-label data-i18n="waiver-type">{{'field.waiverType' | translate }}</mat-label>
                  <mat-select [(ngModel)]="waiverSelected" 
                            required 
                            [disabled]="isConvertingCurrency"
                            aria-labelledby="waiver-type-label">
                      <mat-option *ngFor="let waiver of waiverAvailables; let i = index"
                                [value]="waiver"
                                [attr.data-index]="i">
                          {{ waiver | translate }}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
          </ng-container>

          <ng-container *ngIf="waiverSelected === waiverAvailables[0] || 
                              !(isWaiverAvailable('RNA') && isWaiverAvailable('RAW'))"
                      data-section="change-options">
              <mat-radio-group [(ngModel)]="selectedWaiverType" 
                              #waiverType="ngModel" 
                              (ngModelChange)="selectWaiverType()" 
                              [disabled]="isConvertingCurrency"
                              aria-labelledby="change-options-label">
                  <mat-radio-button matTooltip="{{ 'waiverLatam.toolTip.changeNotAllowed' | translate }}" 
                                  value="CNA"
                                  class="waiver-radio-option">
                      {{ 'waiverLatam.radioButton.changeNotAllowed' | translate }}
                  </mat-radio-button>
                  <mat-radio-button matTooltip="{{ 'waiverLatam.toolTip.changeFlightPenalty' | translate }}" 
                                  value="CFP"
                                  class="waiver-radio-option">
                      {{ 'waiverLatam.radioButton.changeFlightPenalty' | translate }}
                  </mat-radio-button>
              </mat-radio-group>
          </ng-container>

          <ng-container *ngIf="waiverSelected === waiverAvailables[1] || 
                              !(isWaiverAvailable('CFP') && isWaiverAvailable('CNA'))"
                      data-section="refund-options">
              <mat-radio-group [(ngModel)]="selectedWaiverType" 
                              #waiverType="ngModel" 
                              (ngModelChange)="selectWaiverType()" 
                              [disabled]="isConvertingCurrency"
                              role="radiogroup">
                  <mat-radio-button matTooltip="{{ 'waiverLatam.toolTip.refundNotAllowed' | translate }}" 
                                  value="RNA"
                                  class="refund-option">
                      {{ 'waiverLatam.radioButton.refundNotAllowed' | translate }}
                  </mat-radio-button>
                  <mat-radio-button matTooltip="{{ 'waiverLatam.toolTip.refundAllowedWithoutPenalty' | translate }}" 
                                  value="RAW"
                                  class="refund-option">
                      {{ 'waiverLatam.radioButton.refundAllowedWithoutPenalty' | translate }}
                  </mat-radio-button>
              </mat-radio-group>
          </ng-container>

          <mat-error style="width: 100%" 
                    *ngIf="showWaiverTypeErrors && selectedWaiverType === ''"
                    role="alert" 
                    aria-live="assertive">
              <p class="error waiver-selection-error">Debes seleccionar un tipo de waiver</p>
          </mat-error>

              <mat-form-field appearance="outline" *ngIf="waiverModule === 'COR'">
                  <mat-label>{{ 'waiverLatam.coin' | translate}}</mat-label>
                  <mat-select [(ngModel)]="selectedCurrency" (ngModelChange)="updateCostControlState()" required [disabled]="isConvertingCurrency">
                      <mat-option
                          *ngFor="let currency of currencyOptions"
                          [value]="currency.code">
                          {{currency.code}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>

              <form [formGroup]="passengersForm" data-form-id="passengers-form">
                <ng-container formArrayName="passengers" 
                              *ngFor="let passenger of passengerArray.controls; let i = index; let isFirst = first; let isLast = last">
                  <ng-container id="form-group-passengers" [formGroupName]="i">
                    <div style="display: flex; justify-content: space-between;"
                        class="passenger-row"
                        [attr.data-is-first]="isFirst"
                        [attr.data-is-last]="isLast">
                      <mat-form-field
                        class="regular passenger-name-field"
                        appearance="outline"
                        [ngStyle]="{ 'width': waiverModule === 'COR' ? '70%' : '100%'}"
                        [attr.aria-label]="'waiverLatam.passengerName' | translate">
                          <mat-label title="Passenger Name">{{ 'waiverLatam.passengerName' | translate}}</mat-label>
                          <input matInput
                                formControlName="name"
                                disabled
                                data-test-id="passenger-name-input">
                      </mat-form-field>

                      <div class="flex passenger-cost-container" 
                          style="width: 25%;" 
                          *ngIf="waiverModule === 'COR'"
                          data-module="cor">
                        <mat-form-field style="width: 100%;" 
                                      class="regular cost-field" 
                                      appearance="outline">
                            <mat-label title="Passenger Cost">{{ 'waiverLatam.passengerCost' | translate}}</mat-label>
                            <input
                                matInput
                                type="text"
                                mask="separator.2"
                                thousandSeparator="."
                                decimalMarker=","
                                [dropSpecialCharacters]="false"
                                formControlName="cost"
                                (input)="updateWaiverValue()"
                                [min]="0"
                                [disabled]="!isCurrencySelected()"
                                required
                                data-test-id="cost-input">
                        </mat-form-field>

                        <mat-error
                              style="margin-top: -20px;"
                              *ngIf="passenger.controls.cost.errors && 
                                    (passenger.controls.cost.dirty || passenger.controls.cost.touched) && 
                                    passenger.controls.cost.errors.required"
                              class="cost-required-error"
                              role="alert">
                              {{ 'field.error.required' | translate }}</mat-error>

                        <mat-error
                              style="margin-top: -20px;"
                              *ngIf="passenger.controls.cost.errors && 
                                    (passenger.controls.cost.dirty || passenger.controls.cost.touched) && 
                                    passenger.controls.cost.errors.valueMin"
                              class="cost-min-error"
                              role="alert"
                              [attr.data-min-amount]="minAmount">
                              Min: {{minAmount}}</mat-error>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </form>

              <mat-form-field 
                  class="example-full-width regular comments-field" 
                  appearance="outline"
                  data-field-type="comments">
                  <mat-label title="Additional Comments">{{ 'waiverLatam.comment' | translate}}</mat-label>
                  <textarea 
                      matInput 
                      [(ngModel)]="aditionalComment"
                      aria-label="Comments"
                      data-test-id="additional-comment"
                      placeholder=""
                      rows="3"></textarea>
              </mat-form-field>

              <div *ngIf="documentationNeeded" class="adjunt-container">
                <div class="adjunt-button-container">
                  <button mat-flat-button id="adj-btn" matTooltip="{{ 'tooltip.allowedExtensions' | translate }}"
                    matTooltipPosition="after" [disabled]="files.length == 2" (click)="onClick(i)"
                    class="adjunt-button w-100">{{ 'button.attachFile' | translate }}</button>
                  <mat-hint class="opcional mt-1">{{ 'tooltip.maxSize' | translate }}</mat-hint>
                  <mat-hint *ngIf="pnrList?.length > 1" class="opcional mt-1">{{ 'tooltip.optional' | translate }}</mat-hint>
                </div>

                <div class="adjunt-container-items">
                  <ul class="filename-list">
                    <li class="filename-list-item no-files" *ngIf="files.length == 0">
                      <span class="filename-span">{{ 'warning.fileRequired' | translate }}</span>
                    </li>
                    <li id="create-waiver-post-summ-item" class="filename-list-item" *ngFor="let file of files" matTooltipPosition="after"
                      [matTooltip]="file.data.name">
                      <span class="filename-span" id="file-label">
                        {{ file.data.name }}
                      </span>
                      <div id="create-waiver-post-summ-del-load" *ngIf="file.data.locationStorage === undefined" class="deleting-loader">
                        <svg class="spinner" width="24px" height="24px" viewBox="0 0 66 66"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                            </circle>
                          </svg>
                      </div>
                      <a id="create-waiver-post-summ-cancel" title="Cancel" (click)="cancelFile(file, i)" *ngIf="file.data.locationStorage">
                        <mat-icon *ngIf="!file.deletingFile" class="cancelAdjunto">close</mat-icon>
                        <div class="deleting-loader" *ngIf="file.deletingFile">
                          <svg class="spinner" width="24px" height="24px" viewBox="0 0 66 66"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                            </circle>
                          </svg>
                        </div>
                      </a>
                    </li>
                  </ul>
                  <input type="file" id="fileUpload" name="fileUpload" multiple="multiple" accept="{{accept}}"
                    style="display:none;" />
                </div>
              </div>

              <mat-error style="width: 100%" *ngIf="showWaiverTypeErrors && files.length == 0 && true ">
                  <p class="error">Debes adjuntar un documento</p>
              </mat-error>

          </div>

          <div class="checkbox-container regular p-justify understanding-section" data-section="understanding">
            <mat-checkbox 
                [(ngModel)]="understandingAcceptance" 
                #understanding 
                required
                id="understandingCheckbox"
                aria-labelledby="understanding-label"></mat-checkbox>
            <label for="understandingAcceptance" id="understanding-label">
                {{ 'waiverLatam.understanding' | translate}}
            </label>
          </div>

          <div class="checkbox-container regular p-justify terms-section" data-section="terms">
            <mat-checkbox 
                [(ngModel)]="termsAcceptance" 
                #terms 
                required
                id="termsCheckbox"
                aria-labelledby="terms-label"></mat-checkbox>
            <label for="termsAcceptance" id="terms-label">
                {{ 'waiverLatam.termsAndConditions1' | translate}}
                <span 
                    class="terms-conditions" 
                    (click)="openTermsModal()"
                    role="button"
                    tabindex="0">
                    <b>{{ 'waiverLatam.termsAndConditions2' | translate}}</b>
                </span>
                {{ 'waiverLatam.termsAndConditions3' | translate}}
            </label>
          </div>

          <mat-error 
            style="width: 100%" 
            *ngIf="showTermsError"
            role="alert"
            aria-live="assertive"
            data-error-type="terms">
            <p class="error terms-error-message">Debes aceptar los terminos y conidiciones para continuar.</p>
          </mat-error>

        </div>

        <div class="right-section detail-panel">
          <h2>{{ waiverModule === 'COR' ? 'waiverLatam.costDetail' : 'requestDetail' | translate}}</h2>

          <div class="passenger-card">
              <span class="title">{{ 'waiverLatam.passengers' | translate}}</span>
              <div *ngFor="let passenger of passengerArray.controls; let i = index" class="cost-item total">
                  <span class="passenger-name">{{ selectionPassengers.selected[i]?.firstName?.replace('MR', '') + ' ' + selectionPassengers.selected[i]?.lastName}}</span>
                  <span *ngIf="waiverModule === 'COR'">{{ selectedCurrency }} {{ passenger.value.cost }}</span>
              </div>
          </div>

          <div class="total-cost-card">
              <span class="title">{{ waiverModule === 'COLOR' ? 'waiverLatam.totalCost' : 'waiverLatam.exemptionType' | translate}}</span>
              <div class="total">
                  <div style="font-family: 'LatamSansLight';
                      padding-bottom: 1rem;
                      color: #4a148c;
                      font-size: 1.5rem;
                      width: auto;
                      display: flex;
                      flex-wrap: wrap;"
                      *ngIf="waiverModule === 'COR'"
                  >
                      {{ selectedCurrency + ' ' + waiverTotalValue }}
                      <span *ngIf="!isConvertingCurrency">
                      (USD {{ waiverUsdTotalValue }})
                      </span>
                      <span *ngIf="isConvertingCurrency"
                          style="display: flex;
                              align-items: center;"
                      >
                          (USD
                          <span style="padding-left: 1rem;">
                              <mat-progress-spinner
                              class="budget-spinner"
                              mode="indeterminate"
                              diameter="20"
                          >
                              </mat-progress-spinner>
                          </span>
                          )
                      </span>
                  </div>

                  <div style="font-family: 'LatamSansLight';
                      padding-bottom: 1rem;
                      color: #4a148c;
                      font-size: 1.3rem;
                      width: auto;
                      display: flex;
                      flex-wrap: wrap;"
                      *ngIf="waiverModule === 'POL'"
                  >
                    {{ selectedWaiverMotive.exceptionTypeName }}
                  </div>
              </div>
          </div>
          <div class="spacer layout-element" role="presentation" aria-hidden="true">
          </div>

          <div class="action-section button-container" data-section="action-buttons">

            <button
                *ngIf="!canSubmitWaiver"
                mat-flat-button
                [disabled]="false"
                (click)="letSubmit()"
                [ngClass]="{'button-transition': !canSubmitWaiver, 'button-hidden': canSubmitWaiver}"
                type="button"
                data-button-type="confirm"
                aria-label="{{ 'button.confirm' | translate }}"
            >
                {{ 'button.confirm' | translate }}
            </button>

            <button
                *ngIf="areConditionsAccepted() && canSubmitWaiver"
                mat-flat-button
                [disabled]="loadingSaveWaiver"
                (click)="saveWaiverAutoApproved()"
                [ngClass]="{'button-transition': canSubmitWaiver && areConditionsAccepted(), 
                          'button-hidden': !canSubmitWaiver && !areConditionsAccepted()}"
                type="submit"
                data-button-type="submit"
                aria-label="{{ 'button.sendRequest' | translate }}"
            >
                <span 
                    *ngIf="!loadingSaveWaiver"
                    class="button-text"
                >{{ 'button.sendRequest' | translate }}</span>
                
                <mat-progress-spinner
                    *ngIf="loadingSaveWaiver"
                    class="custom-spinner loading-indicator"
                    mode="indeterminate"
                    diameter="20"
                    aria-label="Saving waiver..."
                >
                </mat-progress-spinner>
            </button>
          </div>
      </div>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'partialDisplay' })
export class PartialDisplayPipe implements PipeTransform {
  transform(value: string, charForHide: string, visibleChars: number): string {
    if (!value) {
      return value;
    }

    if (value.length > 0) {
      let positiveVisibleChars = Math.abs(visibleChars);
      let hiddenChars: number = value.length - positiveVisibleChars;

      if (visibleChars < 0) {
        return charForHide.repeat(hiddenChars) + value.substr(hiddenChars, positiveVisibleChars);
      } else {
        return value.substr(0, positiveVisibleChars) + charForHide.repeat(hiddenChars);
      }
    } else {
      return value;
    }
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { LoaderStateInterface } from '../interfaces/loader-state.interface';

@Injectable()
export class LoaderService {
  private loaderSubject = new Subject<LoaderStateInterface>();
  loaderState = this.loaderSubject.asObservable();



  show() {
    this.loaderSubject.next(<LoaderStateInterface>{show: true});
  }

  hide() {
    this.loaderSubject.next(<LoaderStateInterface>{show: false});
  }
}

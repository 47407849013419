import { AgencyGroup } from './agency-group.model';
import { Agency } from './agency.model';
import { AgencyTier } from './agency-tier.model';
import { BranchOffice } from './branch-office.model';
import { AgencyBase } from './agency-base';

export class AgencyProfile extends AgencyBase {
  agencyTier: AgencyTier;
  officeId: string;
  pcc: string;
  id: number;
  marketId: number;
  marketName: string;
  agencyGroup: AgencyGroup;
  branchOffice: BranchOffice;
  piaAgencyCode: string;
  piaAgencyType: string;

  relatedAgencies: Agency[];
  iatas: string[];
}

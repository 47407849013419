import { HttpErrorResponse } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';
import { FileUploadModel } from '../../../../models/file-upload.model';
import { WaiverUserManualFile } from '../../../../models/waiver-user-manual-file-model';
import { SidebarMenuModel } from '../../../../models/SidebarMenu.model';
import { MessageService } from '../../../../services/message.service';
import { SidebarShareDataService } from '../../../../services/sidebar-share-data.service';
import { FileStorageService } from '../../../../services/file-storage.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import { ModalWaiverManualComponent } from '../modal-waiver-manual/modal-waiver-manual.component';
import { MessageErrorService } from '../../../../services/message-error.service';
import { ManualUtilService } from '../../../../services/manual-util.service';
@Component({
  selector: 'app-waiver-manual-management',
  templateUrl: './waiver-manual-management.component.html',
  styleUrls: ['./waiver-manual-management.component.css'],
  animations: [
    trigger(
      'fadeInOut',
      [
        state('in', style({ opacity: 100 })),
        transition('* => void', [animate(300, style({ opacity: 0 }))])
      ]
    )
  ],
  encapsulation: ViewEncapsulation.None
})

export class WaiverManualManagementComponent implements OnInit {
  @ViewChild('loadSalesForm') loadSalesForm: NgForm;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  encodedFile: string;

  dataSource = new MatTableDataSource<any>();
  public displayedColumns: string[] = ['name', 'language', 'waiverCategory', 'waiverType', 'updated','size','actions'];
  dataNotfound = (index, item) => item.noData;
  selectedRowIndex: number = -1;
  downloading = false;
  downloadingSuccessfully = false;

  fileList: WaiverUserManualFile[];
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_LATAM, SidebarMenuModel.SUBMENU_WAIVER_MANUAL_MANAGEMENT);


  noFilesFound = false;
  textFile: File;
  loading: boolean = true;
  validCsvFormat: boolean = false;
  result3: string[];
  typeFileId: number;
  format: string = "";
  errores: string = "";

  /** File extension that accepted, same as 'accept' of <input type='file' />. By the default, it's set to 'image/*'. */
  @Input() accept = '.pdf';
  /** Allow you to add handler after its completion. Bubble up response text from remote. */
  @Output() complete = new EventEmitter<string>();
  public files: Array<FileUploadModel> = [];

  allowedFileFormats = ['application/pdf', 'text/pdf', 'application/vnd.ms-excel'];
  loadSalesSuccessfully: boolean = false;
  loadSalesProcess: boolean = false;
  operationfinishOK: boolean = false;

  // fin adjuntar archivos
  constructor(
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private sidebarShareDataService: SidebarShareDataService,
    private translateService: TranslateService,
    private fileStorageService: FileStorageService,
    private manualUtilService: ManualUtilService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.loadFiles();
  }

/**
   * Metodo para subir el archivo
   */
validateFormat(fileUpload: HTMLInputElement) {
  this.manualUtilService.validateFormat(fileUpload, this.files);
  this.refreshComponent();
}


  /**
   * @param file
   */
  cancelFile(file: FileUploadModel) {
    this.manualUtilService.cancelFile(file, this.files);
    this.validCsvFormat = false;
  }


  upload() {
    const dialogRef = this.dialog.open(ModalWaiverManualComponent);
    dialogRef.afterClosed().subscribe(() => {
      this.loadFiles();
    });
  }


  /**
   * Obtener los archvios del Bucket de error
   */
  public loadFiles() {

    this.fileStorageService.listWaiverUserManual().subscribe({

      next: data => {
        if (data === undefined || data.length === 0) {
          this.noFilesFound = true;
          this.dataSource.data = [{ noData: true }];
          this.dataSource.paginator = null;
        } else {

            this.dataSource.data = data
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.loading = false;
          }
      },

      error: (error: HttpErrorResponse) => {
        if (error.status == 404) {
          this.noFilesFound = true;
          this.dataSource.data = [{ noData: true }];
          this.dataSource.paginator = null;

        } else {
          swal.fire({
            html: '<p>' + this.translateService.instant('login.error.loadError') + '</p>',
            confirmButtonText: this.translateService.instant('button.confirm'),
            confirmButtonColor: '#d33',
            icon: 'error',
            allowOutsideClick: false
          }).then((result) => {
            if (result.value) {
              //Success
              this.refreshComponent();
            }
          });
        }
        console.log('error', error);
      }
  });

  }

  /**
  * Metodo que sirve para refrescar la pagina
  */
  refreshComponent() {
    if (this.operationfinishOK) {
      this.loadSalesSuccessfully = false;
      this.loadSalesProcess = false;
      this.operationfinishOK = false;
      this.loading = true;
      this.fileList = [];
      this.files = [];
      this.ngOnInit();
    }
  }

  valueSelect(event: any) {
    this.format = event.value.name;
    this.typeFileId = event.value.id;
  }

  highlightRow(event: Event, rowIndex: number) {
    this.selectedRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }


  bytesToSize(bytes: number): string {
    const kilobytes = bytes / 1024;
    if (kilobytes < 1024) {
      return kilobytes.toFixed(1) + ' KB';
    } else {
      const megabytes = kilobytes / 1024;
      return megabytes.toFixed(1) + ' MB';
    }
  }

  downloadFile(fileBdaStorage: WaiverUserManualFile) {
    this.manualUtilService.downloadFile(
      fileBdaStorage,
      (location) => this.fileStorageService.downloadWaiverUserManual(fileBdaStorage.locationStorage, "es")
    );
  }

  deleteFile(waiverUserManualFile: WaiverUserManualFile) {
    this.manualUtilService.deleteFile(
      waiverUserManualFile.locationStorage,
      this.fileStorageService.deleteFile.bind(this.fileStorageService),
      this.loadFiles.bind(this)
    );
  }
}

<div class="modal-header">
  <div class="row ml-0 mr-0">
    <div class="col-11 col-md-6 col-lg-6 col-xl-7 order-3 order-md-1 order-lg-1 order-xl-1 title">
      <p>{{ ('requestDetailId' | translate) + ' ' + idWaiver }}
      </p>
    </div>

    <div
      class="col-11 col-md-5 col-lg-5 col-xl-4 order-1 order-md-2 order-lg-2 order-xl-2 pt-0 pl-0 pb-0 pr-0 alert-waiver-list alert"
      [ngClass]="{ 'alert-success': tab === 'approved' || tab === 'issued', 'alert-warning': tab === 'expired' || tab === 'pending', 'alert-error': tab === 'rejected' }">
      <div class="alert-icon"
        [ngClass]="{ 'alert-icon-success': tab === 'approved' || tab === 'issued', 'alert-icon-warning': tab === 'expired' || tab === 'pending', 'alert-icon-error': tab === 'rejected' }">
        <ng-container *ngIf="tab === 'approved' || tab === 'issued'">
          <i class="material-icons">done</i>
        </ng-container>
        <ng-container *ngIf="tab === 'expired'">
          <i class="material-icons">query_builder</i>
        </ng-container>
        <ng-container *ngIf="tab === 'rejected'">
          <i class="material-icons">clear</i>
        </ng-container>
        <ng-container *ngIf="tab === 'pending'">
          <i class="material-icons">query_builder</i>
        </ng-container>
      </div>
      <div class="alert-text w-100"
        [ngClass]="{ 'alert-text-warning': tab === 'expired' || tab === 'pending', 'alert-text-error': tab === 'rejected' }">
        <ng-container *ngIf="tab === 'approved'">
          <p class="mb-0">{{ 'approved' | translate }}</p>
        </ng-container>
        <ng-container *ngIf="tab === 'issued'">
          <p class="mb-0">{{ 'issued' | translate }}</p>
        </ng-container>
        <ng-container *ngIf="tab === 'expired'">
          <p class="mb-0">{{ 'expired' | translate }}</p>
        </ng-container>
        <ng-container *ngIf="tab === 'rejected'">
          <p class="mb-0">{{ 'denied' | translate }}</p>
        </ng-container>
        <ng-container *ngIf="tab === 'pending'">
          <p class="mb-0">{{ 'pending' | translate }}</p>
        </ng-container>
      </div>
    </div>

    <div class="col-1 order-2 order-md-3 order-lg-3 order-xl-3 pr-0">
      <div class="close-modal">
        <button class="btn-close-modal" (click)="closeDialog()">
          <mat-icon class="close-icon">clear</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal-content detail-modal">
  <ng-container *ngIf="!loading">
    <div class="modal-content-body">
      <!--  info aprobado  -->
      <ng-container *ngIf="tab === 'approved'">
        <app-waiver-info [waiverInfo]="waiverInfo" [displayedColumns]="displayedColumnsWaiverInfo" [tab]="tab">
        </app-waiver-info>
      </ng-container>

      <!--  info emitidos  -->
      <ng-container *ngIf="tab === 'issued'">
        <app-waiver-info [waiverInfo]="waiverInfo" [displayedColumns]="displayedColumnsIssuedWaiverInfo" [tab]="tab">
        </app-waiver-info>
      </ng-container>

      <!--  info expirado  -->
      <ng-container *ngIf="tab === 'expired'">
        <app-waiver-info [waiverInfo]="waiverInfo" [displayedColumns]="displayedColumnsIssuedWaiverInfo" [tab]="tab">
        </app-waiver-info>
      </ng-container>

      <!--  info rechazado  -->
      <ng-container *ngIf="tab === 'rejected'">
        <app-waiver-info [waiverInfo]="waiverInfo" [displayedColumns]="displayedColumnsRejectionInfo" [tab]="tab">
        </app-waiver-info>
      </ng-container>

      <!--  info pendiente  -->
      <ng-container *ngIf="tab === 'pending'">
        <app-waiver-info [waiverInfo]="waiverInfo" [displayedColumns]="displayedColumnsPendingInfo" [tab]="tab">
        </app-waiver-info>
      </ng-container>

      <!-- Adjunto -->
      <div class="table-waiver-agency mat-elevation-z1 mt-3">
        <mat-table #table [dataSource]="dataSource">

          <ng-container matColumnDef="files">
            <mat-header-cell *matHeaderCellDef>{{ 'field.attachedFiles' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object"><span *ngIf="object.files.length==0">—</span>
              <app-download-list *ngIf="object.files.length!=0" [files]="object.files"></app-download-list>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="coment">
            <mat-header-cell *matHeaderCellDef>{{ 'field.remarks' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">{{object.aditionalComment | dashWhenEmpty}}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsCo" class="waiver-gency-row"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsCo;" class="waiver-gency-row"></mat-row>
        </mat-table>
      </div>

      <!--  info solicitud  -->
      <ng-container *ngIf="tab === 'approved'">
        <app-waiver-request-info [waiverRequestInfo]="waiverRequestInfo" [displayedColumns]="displayedColumnsRequestInfo">
        </app-waiver-request-info>
      </ng-container>

      <ng-container *ngIf="tab === 'issued'">
        <app-waiver-request-info [waiverRequestInfo]="waiverRequestInfo"
          [displayedColumns]="displayedColumnsIssuedRequestInfo"></app-waiver-request-info>
      </ng-container>

      <ng-container *ngIf="tab === 'expired'">
        <app-waiver-request-info [waiverRequestInfo]="waiverRequestInfo"
          [displayedColumns]="displayedColumnsIssuedRequestInfo"></app-waiver-request-info>
      </ng-container>

      <ng-container *ngIf="tab === 'rejected'">
        <app-waiver-request-info [waiverRequestInfo]="waiverRequestInfo"
          [displayedColumns]="displayedColumnsIssuedRequestInfo"></app-waiver-request-info>
      </ng-container>

      <!--  title  -->
      <div class="row ml-3 mr-3">
        <div class="col text-center">
          <span class="title">{{ 'summary' | translate }}&nbsp;</span><span
            class="title-bold">{{ 'field.pnr' | translate }}&nbsp;{{ pnr ? pnr.pnrNumber : '' }}</span>
        </div>
      </div>

      <!--  info reserva  -->
      <div class="row ml-3 mr-3">
        <div class="col">
          <app-booking-info [booking]="booking" [pnr]="pnr" [numberPassengers]="numberPassengers"
            [displayedColumns]="displayedColumnsBookingInfo"></app-booking-info>
        </div>
      </div>

      <!--  info pasajero  -->
      <div class="row ml-3 mr-3">
        <div class="col">
          <app-passenger-info [passengers]="passengers" [displayedColumns]="displayedColumnsPassengerInfo" [isValuated]="true">
          </app-passenger-info>
        </div>
      </div>

      <!--  info segmento  -->
      <div class="row ml-3 mr-3">
        <div class="col">
          <app-segment-info [isValuated]="isValuated" [segments]="segments" [step]="step"
            [displayedColumns]="displayedColumnsSegmentInfo"></app-segment-info>
        </div>
      </div>
    </div>

    <!-- forms aprobar - rechazar-->
  <ng-container *ngIf="tab == 'pending' && (profiles.updateWaiverPreSaleManagement || profiles.waiverApproveRejectManual) && !loading">
    <div class="btn-div">
      <div [@slideInOut]="animationState" class="divFooter">
        <mat-card class="edit-waiver">
          <mat-card-content>
            <mat-tab-group #tabGroup class="tab-group-form"
              [ngClass]="{'tab-min': approveReject, 'tab-max': !approveReject }"
              (selectChange)="toggleShowTab('divFooter')">
              <mat-tab>
                <ng-template mat-tab-label>
                  <div (click)="toggleShowTab('divFooter')">{{ 'button.approve' | translate }}</div>
                  <div class="bottom-border-approve"> </div>
                </ng-template>

                <form>
                  <div class="row mt-4 ml-3 mr-3 ml-0 mr-0">

                    <div class="col-6 col-md-3 col-lg-3 col-xl-3">
                      <textarea matInput name="approvalComment" #messageAprobar maxlength="500" [(ngModel)]="approvalComment"
                        placeholder="{{ 'field.enterComment' | translate }}" class="textarea-approve"></textarea>
                      <mat-hint class="comment-hint">{{ messageAprobar.value.length }} / 500</mat-hint>
                    </div>

                    <div class="col-6 col-md-4 col-lg-4 col-xl-4 offset-6 offset-md-0 offset-lg-0 offset-xl-0">
                      <div class="row ml-0 mr-0 justify-content-center">
                        <div class="col-12 col-lg-10 col-xl-10 pl-0 pr-0 text-center">
                          <mat-checkbox ngDefaultControl id="approveCheck" name="confirmApproval" type="checkbox"
                            [(ngModel)]="confirmApproval">{{ 'field.confirm' | translate }}
                          </mat-checkbox>
                        </div>
                      </div>

                      <div class="row ml-0 mr-0 justify-content-center">
                        <div class="col-12 col-lg-10 col-xl-10 pl-0 pr-0"
                          *ngIf="(profiles.updateWaiverPreSaleManagement || profiles.waiverApproveRejectManual)">
                          <button mat-raised-button id="approveWaiverButton"
                            [disabled]="!confirmApproval"
                            (click)="approveWaiver()"
                            class="btn-approval w-100">{{ 'button.approveRequest' | translate }}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label>
                  <div (click)="toggleShowTab('divFooter')">{{ 'button.deny' | translate }}</div>
                </ng-template>

                <form #rechazoWaiverForm="ngForm">
                  <div class="row mt-4 ml-3 mr-3 ml-0 mr-0">
                    <div class="col-6 col-md-4 col-lg-4 col-xl-4">
                      <mat-form-field>
                        <mat-label>{{ 'field.reason' | translate }}</mat-label>
                        <mat-select ngDefaultControl name="motivo" #motive="ngModel"
                          required [(ngModel)]="selectedMotive">
                          <mat-option *ngFor="let motive of motives" [value]="motive">{{ motive.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="motive.errors && (motive.dirty || motive.touched) && motive.errors.required">
                          {{ 'field.error.selectReasonRejection' | translate }}</mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-6 col-md-4 col-lg-4 col-xl-4">
                      <ng-container *ngIf="selectedMotive.id != -1">
                        <textarea matInput name="rejectionComment" #messageRechazar maxlength="500" [required]="selectedMotive.id == 4"
                          placeholder="{{ 'field.enterComment' | translate }}" [(ngModel)]="rejectionComment" class="textarea-approve"></textarea>
                        <mat-hint class="comment-hint">{{ messageRechazar.value.length }} / 500</mat-hint>
                      </ng-container>
                    </div>

                    <div class="col-6 col-md-4 col-lg-4 col-xl-4 offset-6 offset-md-0 offset-lg-0 offset-xl-0">
                      <div class="row ml-0 mr-0 justify-content-center">
                        <div class="col-12 col-lg-10 col-xl-10 pl-0 pr-0 text-center">
                          <mat-checkbox ngDefaultControl id="rejectCheck" name="confirmRejection" type="checkbox"
                            [(ngModel)]="confirmRejection">
                            {{ 'field.confirm' | translate }}</mat-checkbox>
                        </div>
                      </div>

                      <div class="row ml-0 mr-0 justify-content-center">
                        <div class="col-12 col-lg-10 col-xl-10 pl-0 pr-0 "
                          *ngIf="(profiles.updateWaiverPreSaleManagement || profiles.waiverApproveRejectManual)">
                          <button mat-raised-button id="rejectWaiverButton"
                            [disabled]="!confirmRejection || selectedMotive.id == -1 || rechazoWaiverForm.invalid"
                            (click)="rejectWaiver()"
                            class="background-red w-100">{{ 'button.denyRequest' | translate }}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </mat-tab>

            </mat-tab-group>
          </mat-card-content>
        </mat-card>
      </div>

      <button mat-raised-button *ngIf="tab == 'pending'"
        (click)="approveReject = !approveReject; toggleShowDiv('divFooter')" class="hide-button">
        <mat-icon matSuffix>{{ approveReject ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
      </button>
    </div>
  </ng-container>
  </ng-container>

  <div *ngIf="loading" class="loading">
    <div class="loading-div">
      <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
      <span class="loading-span">{{ 'loading' | translate }}</span>
    </div>
  </div>
</div>

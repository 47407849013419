import { Component, OnInit, Input } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

import { WaiverInfoInterface } from '../../interfaces/waiver-info.interface';


@Component({
  selector: 'app-waiver-info',
  templateUrl: './waiver-info.component.html',
  styleUrls: ['./waiver-info.component.css']
})
export class WaiverInfoComponent implements OnInit {
  dataSource = new MatTableDataSource();

  @Input() waiverInfo: WaiverInfoInterface;
  @Input() displayedColumns: string[];
  @Input() tab: string;



  ngOnInit() {
    this.dataSource.data = [this.waiverInfo];
  }

}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import swal from 'sweetalert2';

import { BusinessException, Exception, SystemException } from '../../../../models/Exception.model';
import { MonthlyBudgetGeneric } from '../../../../models/monthly-budget-generic.model';
import { Profile } from '../../../../models/profile.model';

import { BudgetService } from '../../../../services/budget.service';
import { ConstantsService } from '../../../../services/constants.service';
import { MessageService } from '../../../../services/message.service';
import { ProfilingService } from '../../../../services/profiling.service';
import { MessageErrorService } from '../../../../services/message-error.service';


@Component({
  selector: 'app-branch-office-budget',
  templateUrl: './branch-office-budget.component.html',
  styleUrls: ['./branch-office-budget.component.css']
})
export class BranchOfficeBudgetComponent implements OnInit {
  loading = false;
  errorLoadingData = false;

  agencyGroupCurrentAmount: number;
  agencyGroupId: number;
  budgetTypeId: number;
  newTotalCurrentAmount = -1; // se inicializa en -1 para deshabilitar el boton guardar
  marketId: number;
  validateSave = false;

  marketName: string;

  budget: MonthlyBudgetGeneric[] = [];
  displayedColumns: string[] = ['branchOfficeName', 'consumeGroupBudget', 'assignedValue', 'currentAmount', 'spentValue'];
  private profiles = new Profile();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private budgetService: BudgetService,
    private constantsService: ConstantsService,
    private messageErrorService: MessageErrorService,
    private messageService: MessageService,
    private profilesService: ProfilingService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();

    this.activatedRoute.queryParams.subscribe(params => {
      this.marketId = +params.marketId;
      this.marketName = params.marketName;
      this.budgetTypeId = +params.budgetTypeId;
      this.agencyGroupId = +params.agencyGroupId;

      if (this.constantsService.postsale.id === this.budgetTypeId) {
        this.getAfterSalesData();
      } else if (this.constantsService.presale.id === this.budgetTypeId) {
        this.getPresaleData();
      }
    });
  }

  private getPresaleData() {
    this.loading = true;
    this.errorLoadingData = false;

    forkJoin([
      this.budgetService.getMonthlyPresaleBudgetsByGroup(this.agencyGroupId),
      this.budgetService.getBranchOfficeBudget(this.budgetTypeId, this.agencyGroupId)
    ]).subscribe({
      next: data => {
        this.agencyGroupCurrentAmount = data[0].assignedAmount - data[0].busyAmount;

        if (data[1].length > 0) {
          this.budget = data[1];
        }

        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.errorLoadingData = true;
        this.messageErrorService.sendError(error, 'error.loadingBudget');

        this.loading = false;
        console.log('error', error);
      }
  });
  }

  private getAfterSalesData() {
    this.loading = true;
    this.errorLoadingData = false;

    forkJoin([
      this.budgetService.getMonthlyAfterSalesBudgetByGroup(this.agencyGroupId),
      this.budgetService.getBranchOfficeBudget(this.budgetTypeId, this.agencyGroupId)
    ]).subscribe({
      next: data => {
        this.agencyGroupCurrentAmount = data[0].assignedAmount - data[0].busyAmount;

        if (data[1].length > 0) {
          this.budget = data[1];
        }

        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.errorLoadingData = true;
        this.messageErrorService.sendError(error, 'error.loadingBudget');

        this.loading = false;
        console.log('error', error);
      }
  });
  }

  saveBudget(): void {
    if (this.profiles.createBudgetManagement) {
      if (this.newTotalCurrentAmount > 0) {
        this.showWarningMessage();
      } else if (this.newTotalCurrentAmount === 0) {
        this.saveBranchOfficeBudget(this.budgetTypeId, this.agencyGroupId, this.budget);
      }
    }
  }

  private saveBranchOfficeBudget(budgetTypeId: number, agencyGroupId: number, budget: MonthlyBudgetGeneric[]): void {
    this.loading = true;

    this.budgetService.saveBranchOfficeBudget(budgetTypeId, agencyGroupId, budget).subscribe({
      next: data => {
        this.loading = false;
        this.messageService.showSuccessMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('popup.wellDone'),
          this.translateService.instant('success.updateBudget')
        );

        if (this.constantsService.postsale.id === this.budgetTypeId) {
          this.getAfterSalesData();
        } else if (this.constantsService.presale.id === this.budgetTypeId) {
          this.getPresaleData();
        }
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.saveBudget');

        this.loading = false;
        console.log('error', error);
      }
  });
  }

  private showWarningMessage(): void {
    swal.fire({
      html: `<p>${ this.translateService.instant('warning.boBudgetGreaterThan') }</p>`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('button.yes'),
      cancelButtonText: this.translateService.instant('button.cancel'),
      reverseButtons: true,
      customClass: {
        confirmButton: 'confirm-button',
        cancelButton: 'cancel-button'
      }
    }).then((result) => {
      if (result.value) {
        this.saveBranchOfficeBudget(this.budgetTypeId, this.agencyGroupId, this.budget);
      }
    });
  }

  refreshBudget(event: boolean): void {
    if (event) {
      if (this.constantsService.postsale.id === this.budgetTypeId) {
        this.getAfterSalesData();
      } else if (this.constantsService.presale.id === this.budgetTypeId) {
        this.getPresaleData();
      }
    }
  }

  setBudget(event: MonthlyBudgetGeneric[]) {
    this.budget = event;
  }

  setNewTotalCurrentAmount(event: number) {
    this.newTotalCurrentAmount = event;
  }
  setValidateSave(event: boolean){
    this.validateSave = event;
    console.log(this.validateSave);
  }

  goBack(): void {
    this.router.navigate(
      ['/latam/budget/management'],
      {
        relativeTo: this.activatedRoute,
        queryParams: { marketId: this.marketId, marketName: this.marketName, budgetTypeId: this.budgetTypeId }
      }
    );
  }

}

export const environment = {
  sessionRefreshTime: 900000,
  production: true,
  endpoints: {
    urlUserAdmin: 'useradmin',
    urlWaiver: 'waiver',
    urlAgency: 'agency',
    urlFeedback: 'feedback',
    urlBudget: 'budget',
    urlStatusticket: 'statusticket'
  },
  chatProperties: {
    baseUrl: 'https://latamneworg.my.salesforce.com',
    liveAgentUrl: 'https://latamneworg.my.salesforce-sites.com/liveAgentSetupFlow',
    embeddedServiceUrl: 'https://latamneworg.my.salesforce.com/embeddedservice/5.0/esw.min.js',
    baseLiveAgentContentUrl: 'https://c.la4-c1-ia5.salesforceliveagent./content',
    baseLiveAgentUrl: 'https://d.la4-c1-ia5.salesforceliveagent.com/chat',
    chatId: '00D5f000005Xvyi',
    deploymentId: '5725f0000009hCs',
    buttonId: '5735f000000MJne'
  },
  report: {
    pageSize: 1000
  }
};

<script type="text/javascript" src="
https://service.force.com/embeddedservice/5.0/esw.min.js"
integrity="sha384-0k0uavqnGt4MOoSqUasa+fsU1uoNbplck31R9RVAE8+83vfovwTkIK4vksMVoaCv(base)" crossorigin="anonymous"></script>
<nav class="navbar blue-bar-header">
    <img class="navbar-logo" src="..\assets\images\logo.png" alt="LATAM airlines logo" (click)="redirect()">
  <div class="h-100">
    <ul class="navbar-nav h-100">
      <li class= "nav-red">
        <button class= "btn-logout" (click)="logout()">{{ 'button.logout' | translate }}</button>
      </li>
    </ul>
  </div>
</nav>
<div class="nav-strip">
  <span>{{ 'latamTrade' | translate }}</span>
  <div class="nav-language">
    <ng-container *ngFor="let languageAux of languages">
      <button (click)="changedLanguage(languageAux)" class="btn-language"
        [ngClass]="{ 'btn-language-selected': languageAux === language }">{{ languageAux.toUpperCase() }}</button>
    </ng-container>
  </div>
</div>

import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { WaiverCategory } from '../../../models/waiver-category.model';
import { WaiverUserManualFile } from '../../../models/waiver-user-manual-file-model';
import { FileStorageService } from '../../../services/file-storage.service';


@Component({
  selector: 'app-dialog-manual-type',
  templateUrl: './dialog-manual-type.component.html',
  styleUrls: ['./dialog-manual-type.component.css']
})
export class DialogManualTypeComponent {
  types: WaiverCategory[];
  waiverUserManualFiles: WaiverUserManualFile[] = [];
  dataSource = new MatTableDataSource();
  waiverType: string = '';
  constructor(
    private fileStorageService: FileStorageService,
    private dialogRef: MatDialogRef<DialogManualTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { waiverCategory: string, types: WaiverCategory[]}) {
      this.types = data.types;
  }



  public async downloadWaiverManual() {
    const language = localStorage.getItem('language');
    let locationStorage: string;

    this.fileStorageService.listWaiverUserManual().subscribe({
      next: (files: WaiverUserManualFile[]) => {
         this.waiverUserManualFiles = files.filter(
          (file: WaiverUserManualFile) => file.language === language && file.waiverCategory === this.data.waiverCategory &&
          file.waiverType === this.waiverType
        );
        if (this.waiverUserManualFiles.length > 0) {
          locationStorage = this.waiverUserManualFiles[0].locationStorage;
        }
        this.fileStorageService.downloadWaiverUserManual(locationStorage, language).subscribe({
          next: (data) => window.open(URL.createObjectURL(new Blob([data], { type: 'application/pdf' })), '_blank'),
          error: this.fileStorageService.downloadWaiverUserManualError
      });


      },
      error: this.fileStorageService.listWaiverUserManualError(this.dialogRef)
  });

  }
  closeDialog(): void {
    this.dialogRef.close();
  }

}

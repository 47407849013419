
import {map} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { BranchOffice } from '../models/branch-office.model';
import { Pagination } from '../models/pagination.model';
import { PiaCode } from '../models/piaCode.model';
import { System } from '../models/system.model';
import { TokenBda } from '../models/token-bda';
import { User } from '../models/user.model';
import { UserAgencysCsvElement } from './../models/UserAgencysCsvElement.model';

import { RouteLocaleExtractorService } from './route-locale-extractor.service';
import { StorageService } from './storage.service';


@Injectable()
export class UserService {
  headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  urlBase = `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlUserAdmin}`;

  constructor(
    private http: HttpClient,
    private routeLocaleExtractorService: RouteLocaleExtractorService) { }

  createUser(user: User): Observable<User> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.post<User>(`${this.urlBase}${locale}/agencies/users`, user, { headers: this.headers });
  }

  getActiveAgenciesUsers(): Observable<UserAgencysCsvElement[]> {
    const locale = this.routeLocaleExtractorService.getLocale();
    const useragencyCSV = [];

    return this.http.get<any>(`${this.urlBase}${locale}/agencies/users`).pipe(
      map(
        data => {
          data.map(
            (value: any) => {
              useragencyCSV.push(
                new UserAgencysCsvElement(value)
              );
            }
          );

          return useragencyCSV;
        }
      ));
  }

  getAgencyGroupUsers(matrixId: number, page: number, size: number): Observable<{ users: User[], pagination: Pagination }> {
    const locale = this.routeLocaleExtractorService.getLocale();

    let users: User[] = [];
    let pagination = new Pagination();

    return this.http.get<{ users: User[], pagination: Pagination }>(
      `${this.urlBase}${locale}/matrix/${matrixId}/users?page=${page}&size=${size}`
    ).pipe(
      map(
        this.prepareResponse(pagination, users)
      ));
  }

  prepareResponse(pagination: Pagination, users: User[]){
    return resp => {
      pagination.totalElements = resp['totalElements'];
      pagination.pageIndex = resp['number'];
      pagination.pageSize = resp['size'];
      resp['content'].map(
        data => {
          users.push({
            id: data.id,
            name: data.name,
            lastname: data.lastname,
            birthdate: data.birthdate,
            email: data.email,
            position: data.position,
            roles: data.roles,
            association: data.association,
            agencyGroup: null,
            agencyBranchOffice: data.branchOfficeId ? new BranchOffice(data.branchOfficeId) : null,
            agency: data.piaAgencyId ? new PiaCode(data.piaAgencyId) : null
          });
        }
      );

      return { users, pagination };
    }
  }

  getBranchOfficeUsers(branchOfficeId: number, page: number, size: number, sort: string) {
    const locale = this.routeLocaleExtractorService.getLocale();

    let pagination = new Pagination();
    let users: User[] = [];

    return this.http.get(
      `${this.urlBase}${locale}/branch-office/${branchOfficeId}/users?page=${page}&size=${size}&sort=${sort}`).pipe(
      map(this.prepareResponse(pagination, users)));
  }

  getRolesByStystems(): Observable<System[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<System[]>(`${this.urlBase}${locale}/latam/list/system/roles`);
  }

  getUserSessionToken(): Observable<any> {
    const locale = this.routeLocaleExtractorService.getLocale();
    const headers = new HttpHeaders().set('Timezone-Offset', new Date().getTimezoneOffset().toString());
    return this.http.get(`${this.urlBase}${locale}/users/info`, { headers: headers });
  }

  getOutsourcingUserSessionToken(): Observable<any> {
    const locale = this.routeLocaleExtractorService.getLocale();
    const headers = new HttpHeaders().set('Timezone-Offset', new Date().getTimezoneOffset().toString());
    return this.http.get(`${this.urlBase}${locale}/users/outsourcing/info`, { headers: headers });
  }

  getAgencyUserToken(): Observable<TokenBda>{
    console.log("getAgencyUserToken Service");
    return this.http.get<TokenBda>(`${this.urlBase}/agencies/oauth2/token`);
  }

  /**
   * save a user to the database
   * @param user user that you want to edit
   */
  editUser(user: User): Observable<User> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.put<User>(`${this.urlBase}${locale}/agencies/users`, user, { headers: this.headers });
  }

  /**
   * delete a user from the database logically and physically from the B2C
   * @param user user that you want to delete
   */
  deleteUser(user: User): Observable<any> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.delete(`${this.urlBase}${locale}/agencies/users/${user.id}`, { headers: this.headers });
  }
}

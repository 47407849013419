import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { OsiInfo } from '../../../models/osi-info.model';
import { WaiverCategory } from '../../../models/waiver-category.model';
import { WaiverType } from '../../../models/waiver-type.model';
import { BudgetService } from '../../../services/budget.service';
import { MessageService } from '../../../services/message.service';
import { WaiverPostsaleService } from '../../../services/waiver-postsale.service';
import Swal from 'sweetalert2';
import { map, catchError, of, forkJoin, Observable } from 'rxjs';
import { Waiver } from '../../../models/waiver.model';
import { WaiverReservationDetailTableInterface } from '../../../interfaces/waiver-reservation-detail-table.interface';

@Component({
    selector: 'app-search-reservation',
    templateUrl: './search-reservation.component.html',
    styleUrls: ['./search-reservation.component.css']
})

export class SearchReservationComponent implements OnInit, AfterViewInit {

  recordLocatorForm: FormGroup;
  loadingReservationInfo = false;
  
  dataReservation = new MatTableDataSource();
  dataPassengers = new MatTableDataSource();
  dataSegments = new MatTableDataSource();
  dataTkt = new MatTableDataSource();
  dataVoucher = new MatTableDataSource();
  dataAgencyBudget = new MatTableDataSource();
  dataWaiverResult = new MatTableDataSource();

  validSegmentStatusList = ['HK', 'KK'];
  invalidStatusSegmentsQuantity = 0;
  passengerTicketRequestsCompleted: number = 0;
  passengerTicketRequestsWithError: number = 0;

  validPostPNR: boolean = false;
  validPostTKT: boolean = false;
  atLeastOneInfantPass: boolean = false;
  infantCoupon: boolean = false;
  existPassengersWithoutTicket: boolean = true;
  allPassengersInvalid: boolean = true;

  osiInfo: OsiInfo;
  pnr: any;
  booking: any;

  origin: string = "";
  destination: string = "";

  pnrValue: string = '';
  tktValue: string = '';
  fareBasis: string = '';

  selectedType: WaiverType = new WaiverType(0, '', '', new WaiverCategory(2, 'Post-Sale', 'POST'));
  infantList: number[] = new Array();

  @Output() reservationInfoEvent = new EventEmitter<WaiverReservationDetailTableInterface>();
  @Input() enableAllSearchTypes: boolean;
  @Input() waiverModule: string;
  waiverData: Waiver;


  constructor(
    private readonly waiverPostSaleService: WaiverPostsaleService,
    private readonly budgetService: BudgetService,
    private readonly fb: FormBuilder,
    private readonly translateService: TranslateService,
    private readonly messageService: MessageService,
    public dialog: MatDialog,
  ){ }

  ngOnInit(): void {
    this.budgetService.triggerReload();
    this.recordLocatorForm = this.fb.group({
      selection: [{value: 'PNR', disabled: !this.enableAllSearchTypes}, Validators.required],
      pnrValue: [{ value: '', disabled: false }, [Validators.required, Validators.pattern(/^[A-Z]{6}$/)]],
      tktValue: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(/^\d{13}$/)]]
    });

    this.recordLocatorForm.get('selection').valueChanges.subscribe(value => {
      if (value === 'PNR') {
        this.recordLocatorForm.get('pnrValue').enable();
        this.recordLocatorForm.get('tktValue').disable();
      } else if (value === 'TKT') {
        this.recordLocatorForm.get('pnrValue').disable();
        this.recordLocatorForm.get('tktValue').enable();
      } 
    });
  }

  ngAfterViewInit(){
    this.checkIfReservationInfoisReady();
  }


  checkIfReservationInfoisReady(){
    const interval = setInterval(() => {
      if ((this.validPostPNR && this.passengerTicketRequestsCompleted == this.dataPassengers.data.length) && !this.loadingReservationInfo){
        const reservationInfo: WaiverReservationDetailTableInterface = {
          dataReservation: this.dataReservation,
          dataPassengers: this.dataPassengers,
          dataSegments: this.dataSegments,
          dataTkt: this.dataTkt
        };
        
        this.reservationInfoEvent.emit(reservationInfo);
        clearInterval(interval);
      }
    }, 500);
  }

  searchPnrOrTkt(){
    if (this.recordLocatorForm.valid){
      if (this.isSearchingPnr()){
        this.getPnrInfo();
      }
    }
  }

  getPnrInfo(): void {
    this.cleanData();
    this.loadingReservationInfo = true;

    this.waiverPostSaleService.getPnr(this.recordLocatorForm.get('pnrValue').value).subscribe({
      next: data => {
        this.dataReservation.data = [{ pnr: data['pnr'], booking: data['booking'] }];
        this.dataPassengers.data = data['passengers'].map((value, index) => ({ ...value, id: index + 1 }));
        this.dataSegments.data = data['segments'];

        this.dataSegments.data.forEach(segment => {
            segment['selectedCoupons'] = [];
            segment['invalidCoupons'] = 0;
            if (!this.validSegmentStatusList.includes(segment['status'])) {
              this.invalidStatusSegmentsQuantity++;
            }
        });

        this.osiInfo = data['osiInfo'];
        this.pnr = data['pnr'];
        this.booking = data['booking'];
        this.origin = data['origin'];
        this.destination = data['destination'];

        if (this.pnr && this.osiInfo && this.booking) {
          this.showQuestionMessage(this.pnr.pnrNumber, this.booking.iata, this.osiInfo);
        } else {
          this.validPostPNR = true;
        }

        this.processTicketsForPassengers();
      },
      error: (error: HttpErrorResponse) => {
        this.validPostPNR = false;
        this.loadingReservationInfo = false;

        const msg = this.translateService.instant('waiverLatam.pnrNotFound', {
            pnrValue: this.pnrValue
        });
        this.pnrOrTktNotFoundAlert(msg);
        console.error('error', error);
      }
    });
  }

  processTicketsForPassengers(): void {
    const ticketObservables: Observable<any>[] = [];
    this.passengerTicketRequestsCompleted = 0; 

    this.dataPassengers.data.forEach((passenger, index) => {
      passenger['allCouponsInvalid'] = true;

      if (!this.isValidTicketNumber(passenger['ticketNumber'])) {
        passenger['invalidTkt'] = true;
        this.passengerTicketRequestsCompleted++;
      } else if (passenger['type'] === 'INF') {
        this.atLeastOneInfantPass = true;
        this.infantList.push(index);
        passenger['infantTkt'] = true;
        passenger['allCouponsInvalid'] = false;
        this.existPassengersWithoutTicket = false;
        this.passengerTicketRequestsCompleted++;
      } else {
        this.existPassengersWithoutTicket = false;

        const tktObservable = this.waiverPostSaleService.getPnrTkt(passenger['ticketNumber'].substring(0, 13)).pipe(
          map(data => {
            this.handleTktDataResponse(index, data);
            return data;
          }),
          catchError((error: HttpErrorResponse) => {
            this.handleTktDataError(index, error);
            return of(null);
          })
        );
        ticketObservables.push(tktObservable);
      }
    });

    if (ticketObservables.length > 0) {
      forkJoin(ticketObservables).subscribe({
        next: results => {
          this.addInfoInfantPassengerCoupons();

          if (this.passengerTicketRequestsCompleted === this.dataPassengers.data.length && this.existPassengersWithoutTicket) {
              this.handlePassengersWithoutTicket();
          }
          this.loadingReservationInfo = false;
        },
        error: (error) => {
          this.loadingReservationInfo = false;
        }
      });
    } else {
      this.addInfoInfantPassengerCoupons();
      this.loadingReservationInfo = false;
    }
  }

  processPassenger(i: number): void {
    let passenger = this.dataPassengers.data[i];
    passenger['allCouponsInvalid'] = true;

    if (!this.isValidTicketNumber(passenger['ticketNumber'])) {
      passenger['invalidTkt'] = true;
      this.passengerTicketRequestsCompleted++;
    } else if (passenger['type'] === 'INF') {
      this.processInfantPassenger(i);
    } else {
      this.processRegularPassenger(i, passenger);
    }
  }

  processInfantPassenger(i: number): void {
    this.atLeastOneInfantPass = true;
    this.infantList.push(i);
    let passenger = this.dataPassengers.data[i];
    passenger['infantTkt'] = true;
    passenger['allCouponsInvalid'] = false;
    this.existPassengersWithoutTicket = false;
    this.passengerTicketRequestsCompleted++;
  }

  processRegularPassenger(i: number, passenger: any): void {
    this.existPassengersWithoutTicket = false;
    this.waiverPostSaleService.getPnrTkt(passenger['ticketNumber'].substring(0, 13)).subscribe({
      next: data => this.handleTktDataResponse(i, data),
      error: (error: HttpErrorResponse) => this.handleTktDataError(i, error)
    });
  }

  addInfoInfantPassengerCoupons() {
    if (this.infantList.length > 0 && this.infantCoupon === false) {
      let indexPassInfo: number = -1;

      for (let pass = 0; pass < this.dataPassengers.data.length; pass++) {
        if (this.dataPassengers.data[pass]['allCouponsInvalid'] === false
          && this.dataPassengers.data[pass]['coupons'] != null) {
          indexPassInfo = pass;
          break;
        }
      }

      if (indexPassInfo !== -1) {
        for (const element of this.infantList) {
          this.dataPassengers.data[element]['coupons'] = this.dataPassengers.data[indexPassInfo]['coupons'];
        }
        this.infantCoupon = true;
      }
    }
  }

  private showQuestionMessage(pnrNumber: string, iata: string, osiInfo: OsiInfo) {
    Swal.fire({
      title: this.translateService.instant('acceptUsePnr'),
      icon: 'question',
      html: `<div class='popup-pnr'>${
        this.translateService.instant(
          'confirmUseIata',
          {
            osiInfo: osiInfo.agencyOsi,
            waiverType: this.selectedType.name,
            pnrNumber: pnrNumber,
            iata: iata
          }
        )
      }</div>`,
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('button.yes'),
      cancelButtonText: this.translateService.instant('button.no'),
    }).then((result) => {
      if (result.value) {
        this.validPostPNR = true;
      } else {
        this.validPostPNR = false;
      }
    });
  }

  handlePassengersWithoutTicket(): void {
    this.existPassengersWithoutTicket = false;
    this.messageService.showWarningMessage(
      this.translateService.instant('button.confirm'),
      this.translateService.instant('warning.pnrWithoutTkt')
    );
  }

  handleTktDataResponse(i: number, data: any): void {
    this.fareBasis = data['coupons'][0]['fareBasis'];
    let passenger = this.dataPassengers.data[i];
    let couponsForDeleteIndexes: number[] = this.filterCoupons(data['coupons']);

    this.removeCoupons(data['coupons'], couponsForDeleteIndexes);
    this.updatePassengerCoupons(i, passenger, data['coupons']);
    this.passengerTicketRequestsCompleted++;
  }

  handleTktDataError(i: number, error: HttpErrorResponse): void {
    let passenger = this.dataPassengers.data[i];
    this.passengerTicketRequestsCompleted++;
    this.passengerTicketRequestsWithError++;
    passenger['validTktResponse'] = false;
  }

  filterCoupons(coupons: any[]): number[] {
    let couponsForDeleteIndexes: number[] = [];
    for (let j = 0; j < coupons.length; j++) {
        if (!this.isCarrierMarketingFoundOnSegmentList(coupons[j]['carrierMarketing'])) {
          couponsForDeleteIndexes.push(j);
        }
    }
    return couponsForDeleteIndexes;
  }

  updatePassengerCoupons(i: number, passenger: any, coupons: any[]): void {
    for (const element of coupons) {
      element['tkt'] = passenger['ticketNumber'];
      if (element['status'] === 'OK') {
          passenger['allCouponsInvalid'] = false;
      }
    }

    passenger['coupons'] = coupons;

    if (!passenger['allCouponsInvalid']) {
      this.allPassengersInvalid = false;
    }
  }

  removeCoupons(coupons: any[], couponsForDeleteIndexes: number[]): void {
    couponsForDeleteIndexes.forEach(n => {
      coupons.splice(n, 1);
    });
  }

  isCarrierMarketingFoundOnSegmentList(carrierMarketing: string): boolean {
      return this.dataSegments.data.some(element => element['carrierMarketing'] === carrierMarketing);
  }
  
  pnrOrTktNotFoundAlert(msg: string){
    Swal.fire({
      icon: "error",
      text: msg,
    });
  }

  isLoading() {
    return this.loadingReservationInfo || (this.validPostPNR && this.passengerTicketRequestsCompleted !== this.dataPassengers.data.length);
  }
  

  isValidTicketNumber(ticketNumber: string): boolean {
    return ticketNumber && ticketNumber.trim() !== '';
  }

  isSearchingPnr(){
    return  this.recordLocatorForm.get('selection').value === 'PNR';
  }

  isSearchingTkt(){
    return  this.recordLocatorForm.get('selection').value === 'TKT';
  }

  cleanRecordLocator(){
    this.recordLocatorForm.get('pnrValue').reset();
    this.recordLocatorForm.get('tktValue').reset();
  }

  cleanData() {
    this.dataReservation.data = [];
    this.dataPassengers.data = [];
    this.dataSegments.data = [];
    this.dataVoucher.data = [];
    this.validPostPNR = false;
    this.validPostTKT = false;
    this.passengerTicketRequestsCompleted = 0;
    this.passengerTicketRequestsWithError = 0;
    this.invalidStatusSegmentsQuantity = 0;
    this.infantList = [];
    this.infantCoupon = false;
    this.allPassengersInvalid = true;
    this.atLeastOneInfantPass = false;
    this.loadingReservationInfo = false;
  }
    
}

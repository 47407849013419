import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ControlBda } from '../interfaces/control-bda.interface';
import { RouteLocaleExtractorService } from './route-locale-extractor.service';
import { UserService } from './user.service';
import { FileBdaStorage } from '../models/file-bda-storage.model';
import { BulkLoadType } from '../models/bulk-load-types.model';
import { map } from 'rxjs/operators';
import { StorageService } from './storage.service';
@Injectable()
export class BdaService{
  bucketName = `${StorageService.getBucketName()}`;
  urlBase = 'https://storage.googleapis.com';
  bucketFolder = 'portalbdainput';
  bucketFolderError = 'portalbdaerror';
  bucketFolderManual = 'portalbdamanuals';
  manualFileName = 'bda_manual.pdf';

  constructor(
    private httpClient: HttpClient,
    private routeLocaleExtractorService: RouteLocaleExtractorService,
    private userService: UserService ) { }

   uploadFile(file, oAuthToken, controlDataBDA: ControlBda) {

    const data = new FormData();
    const controlDataToString :string  = JSON.stringify(controlDataBDA);
    const fileName = controlDataBDA.filename;
    data.append('controlData', controlDataToString);
    data.append('file', file);

    const headers = new HttpHeaders().set('Content-Type', 'application/octet-stream').set('Authorization', 'Bearer ' + oAuthToken);

    return this.httpClient.post(`${this.urlBase}/upload/storage/v1/b/${this.bucketName}/o?name=${this.bucketFolder}/${fileName}&uploadType=media`, data,{ headers: headers });
  }


  getFiles(oAuthToken):Observable<FileBdaStorage[]>  {
    console.log('getFiles Service');
    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'Bearer ' + oAuthToken);
       return this.httpClient.get<FileBdaStorage[]>(`${this.urlBase}/storage/v1/b/${this.bucketName}/o?prefix=${this.bucketFolderError}/`, { headers: headers })
    .pipe(
      map(data => {
        return data['items'];
      })
    )
  }

  downloadFile(fileName: string, oAuthToken:string): Observable<Blob>  {
    const headers = new HttpHeaders().set('Content-Type', 'application/octet-stream').set('Authorization', 'Bearer ' + oAuthToken);
    return this.httpClient.get(`${this.urlBase}/storage/v1/b/${this.bucketName}/o/${this.bucketFolderError}%2F${fileName}?alt=media`, {headers: headers, responseType: 'blob' });
  }

  downloadManualFile(oAuthToken:string): Observable<Blob>  {
    const headers = new HttpHeaders().set('Content-Type', 'application/octet-stream').set('Authorization', 'Bearer ' + oAuthToken);
    return this.httpClient.get(`${this.urlBase}/storage/v1/b/${this.bucketName}/o/${this.bucketFolderManual}%2F${this.manualFileName}?alt=media`, {headers: headers, responseType: 'blob' });
  }

  deleteFile(fileName: string, oAuthToken:string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/octet-stream').set('Authorization', 'Bearer ' + oAuthToken);
    return this.httpClient.delete(`${this.urlBase}/storage/v1/b/${this.bucketName}/o/${this.bucketFolderError}%2F${fileName}`, {headers: headers});
  }

}

import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WaiverType } from '../../../models/waiver-type.model';
import { FormGroup, FormArray, FormBuilder, Validators, AbstractControl, ValidatorFn, FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { CurrencyConverter } from '../../../interfaces/currency-converter.interface';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BudgetService } from '../../../services/budget.service';
import { MessageService } from '../../../services/message.service';
import { SessionInfoService } from '../../../services/session-info.service';
import { WaiverPostsaleService } from '../../../services/waiver-postsale.service';
import { WaiverLatamTermsComponent } from '../../../components/waiver-latam-terms/waiver-latam-terms.component';
import { SelectionModel } from '@angular/cdk/collections';
import { WaiverCategory } from '../../../models/waiver-category.model';
import { MatTableDataSource } from '@angular/material/table';
import { Exception } from '../../../models/Exception.model';
import { WaiverStatus } from '../../../models/waiver-status.model';
import { WaiverMotive } from '../../../models/waiver-motive.model';
import { Waiver } from '../../../models/waiver.model';
import { WaiverReservationDetailTableInterface } from '../../../interfaces/waiver-reservation-detail-table.interface';
import { FileUploadModel } from '../../../models/file-upload.model';
import { FileStorageService } from '../../../services/file-storage.service';
import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';

@Component({
  selector: 'app-request-summary',
  templateUrl: './request-summary.component.html',
  styleUrls: ['./request-summary.component.css']
})
export class RequestSummaryComponent implements OnInit{

  @Output() stepperBackEvent = new EventEmitter<any>();
  @Output() continueProcess = new EventEmitter<any>();
  @Input() waiverTypes: WaiverType[];
  @Input() waiverMotives: WaiverMotive[];
  @Input() selectionPassengers = new SelectionModel<Element>(true, []);
  @Input() waiverModule: string;
  @Input() documentationNeeded: boolean;
  @Input() reservationInfo: WaiverReservationDetailTableInterface;
  selectedFiles: File[] = [];

  selectedType: WaiverType = new WaiverType(0, '', '', new WaiverCategory(2, 'Post-Sale', 'POST'));

  waiverSelected: string;
  waiverStatus: WaiverStatus;
  loadingUpload: boolean = false;
  loadingSaveWaiver: boolean;
  waiverAvailables: string[] = ['waiverLatam.select.waiverChanges', 'waiverLatam.select.waiverRefund'];
  selectedWaiverType: string = '';
  selectedWaiverMotive: any = {name: '', exceptionTypeName: ''};
  selectedCurrency: string = 'USD';
  passengersForm: FormGroup;
  amountUsd: string = "";
  files: Array<FileUploadModel> = [];
  accept = '.pdf, .doc, .docx, .png, .jpg, .jpeg';
  allowedFileFormats = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/png',
    'image/jpeg'
  ];

  waiverTotalValue: number = 0.00;
  waiverUsdTotalValue: number = 0.00;
  currencyChange: number = 0.0;

  understandingAcceptance: boolean = false;
  termsAcceptance: boolean = false;
  showWaiverTypeErrors: boolean = false;
  isConvertingCurrency: boolean = false;
  showTermsError: boolean = false;
  dataWaiverResult = new MatTableDataSource();


   /* FormGroup para manejar los costos por cada pasajero */
   additionalInfo: FormGroup;
   canSubmitWaiver: boolean = false;

   waiver: Waiver = new Waiver();


  currencyOptions: any[] = [
    {code: 'USD', suffix: '$'},
    {code: 'ARS', suffix: "AR$"},
    {code: 'AUD', suffix: 'AU$'},
    {code: 'BRL', suffix: "R$"},
    {code: 'CAD', suffix: 'CA$'},
    {code: 'CHF', suffix: 'CHF'},
    {code: 'COP', suffix: 'CO$'},
    {code: 'CLP', suffix: "CL$"},
    {code: 'EUR', suffix: '€'},
    {code: 'GBP', suffix: '£'},
    {code: 'MXN', suffix: 'MX$'},
    {code: 'PEN', suffix: "S/."},
    {code: 'PYG', suffix: "₲"}
  ];

  aditionalComment: string = '';


  constructor(private readonly waiverPostsaleService: WaiverPostsaleService,
    private readonly translateService: TranslateService,
    private readonly messageService: MessageService,
    private fileStorageService: FileStorageService,
    private readonly sessionInfoService: SessionInfoService,
    private readonly budgetService: BudgetService,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    public dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef
  ){ }
  
  ngOnInit(): void {
    this.passengersForm = this.fb.group({
      passengers: this.fb.array([])
    });

    this.additionalInfo = this.fb.group({
      aditionalComment: new FormControl(''),
      understandingAcceptance: new FormControl(false),
      termsAcceptance: new FormControl(false)
    });
    
    this.loadPassengers();
  }

  stepperBack(){
    this.stepperBackEvent.emit('request-summary');
  }

  isWaiverAvailable(code: string){
    return this.waiverTypes ? this.waiverTypes.some(type => type.code === code) : false;
  }

  updateCostControlState() {
    if (this.isCurrencySelected()) {
      this.convertCurrency();
    }
  }

  isCurrencySelected(){
    return this.selectedCurrency !== '';
  }

  convertCurrency(){
    let finalValue = this.calculateFinalValue();
    this.waiverTotalValue = finalValue;
    if (this.selectedCurrency === 'USD'){
      finalValue = this.changeValueByPassengerAndTotalAmount(1, finalValue);
      this.waiverTotalValue = finalValue;
      this.waiverUsdTotalValue = this.calculateWithCurrencyChange(finalValue, 1);
    } else {
      this.isConvertingCurrency = true;

      let currencyConverter: CurrencyConverter = {
        sourceCurrencyIsoCode: 'USD',
        destinationCurrencyIsoCode: this.selectedCurrency,
        amount: finalValue
      };

      this.disableAllCostControls();
      this.waiverPostsaleService.changeCurrencyWaiverAmount(currencyConverter)
      .subscribe({
        next: (data: any) => {
          this.currencyChange = data.amount;
          finalValue = this.changeValueByPassengerAndTotalAmount(this.currencyChange, finalValue);
          this.waiverTotalValue = finalValue;
          this.waiverUsdTotalValue = this.calculateWithCurrencyChange(finalValue, this.currencyChange);
          this.isConvertingCurrency = false;
          this.enableAllCostControls();
        },
        error: (err) => {
          this.selectedCurrency = 'USD';
          this.waiverUsdTotalValue = this.calculateWithCurrencyChange(finalValue, 1);
          this.isConvertingCurrency = false;
          this.enableAllCostControls();
        }
      });
    }
  }

  calculateFinalValue(){
    let finalValue: number = 0.00;
    this.passengerArray.controls.forEach(control => {
      const cost = (control as FormGroup).controls['cost'];
      let costValue =  cost.value.replace(/\./g, '').replace(',', '.');
      finalValue += isNaN(parseFloat(costValue)) ? 0.00 : parseFloat(costValue);
    });

    return isNaN(finalValue) ? 0.00 : finalValue;
  }

  get passengerArray() {
    return this.passengersForm.get('passengers') as FormArray;
  }

  changeValueByPassengerAndTotalAmount(currencyChange: number, finalValue: number){
    if (this.selectedWaiverType === 'CFP' || this.selectedWaiverType === 'CNA'){
      const prepareValueUsd = this.amountUsd.replace(/\./g,"").replace(",",".");
      const valueUsd = isNaN(parseFloat(prepareValueUsd)) ? 0.00 : parseFloat(prepareValueUsd);
      const newValueByPassengerNumber = this.calculateWithCurrencyChangeUSD(valueUsd, currencyChange)
      const newValueByPassenger = newValueByPassengerNumber.toString();
      this.passengerArray.controls.forEach(control => {
        (control as FormGroup).controls['cost'].setValue(newValueByPassenger.replace(".", ","));
        (control as FormGroup).controls['cost'].setValidators([Validators.required, this.valueMinValidator(newValueByPassengerNumber)]);
        (control as FormGroup).controls['cost'].updateValueAndValidity();
      });
      return this.calculateFinalValue();
    } else {
      return finalValue;
    }
  }

  calculateWithCurrencyChange(originalAmount: number, currencyChange){
    const result: number = originalAmount / currencyChange;
    return Math.round(result * 100.0) / 100.0;
  }

  disableAllCostControls(){
    this.passengerArray.controls.forEach(control => {
      const cost = (control as FormGroup).controls['cost'];
      cost.disable();
    });
  }

  enableAllCostControls(){
    this.passengerArray.controls.forEach(control => {
      const cost = (control as FormGroup).controls['cost'];
      cost.enable();
    })
  }

  calculateWithCurrencyChangeUSD(originalAmount: number, currencyChange){
    const result: number = originalAmount * currencyChange;
    return Math.round(result * 100.0) / 100.0;
  }

  valueMinValidator(minValue: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let valor = control.value;
      if (valor != null){
        valor = valor.replace(/\./g,"").replace(",",".").replace(".", ",");
      }
      if (valor !== null && valor < minValue) {
        return { valueMin: true };
      }
      return null;
    };
  }

  openTermsModal(){
    const dialogRef = this.dialog.open(WaiverLatamTermsComponent, {
      height: '480px',
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.termsAcceptance = true;
      }
    })
  }

  areConditionsAccepted(): boolean{
    if (!this.understandingAcceptance || !this.termsAcceptance){
      this.canSubmitWaiver = false;
    }

    return this.understandingAcceptance && this.termsAcceptance;
  }

  loadPassengers() {
    this.selectionPassengers.selected.forEach((passenger: any ) => {
      const exists = this.passengerArray.controls.some((passengerGroup: FormGroup) =>
        passengerGroup.controls['name'].value === (passenger.firstName.replace('MR', '') + ' ' + passenger.lastName) &&
        passengerGroup.controls['tktNumber']?.value === passenger.ticketNumber
    );

      if (!exists){
        const passengerGroup = this.fb.group({
          name: [{value: passenger.firstName.replace('MR', '')  + ' ' +  passenger.lastName, disabled: true}],
          cost: [{value: '', disabled: false}, this.valueMinValidator(0)],
          tktNumber: passenger.ticketNumber
        });

        this.passengerArray.push(passengerGroup);
      }
    });
  }

  selectWaiverType() {
    this.selectedType = this.waiverTypes.find(type => type.code === this.selectedWaiverType);
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.selectedFiles = Array.from(input.files);
    }
  }

  letSubmit(): void {
    if (this.areConditionsAccepted()){
      this.canSubmitWaiver = true;
      this.showTermsError = false;
    } else {
      this.canSubmitWaiver = false;
      this.showTermsError = true;
    }
  }

  saveWaiverAutoApproved(): void{
    if (this.validatePresaveConditions()){
      this.showWaiverTypeErrors = false;
      this.loadingSaveWaiver = true;
      this.waiver = this.summaryInfoWaiver();
      console.log(this.waiver);

      const isSelfManaged = this.sessionInfoService.getSessionInfo().isSelfManaged;
      const saveWaiverMethod = isSelfManaged
        ? this.waiverPostsaleService.saveWaiver(this.waiver)
        : this.waiverPostsaleService.saveWaiverAutoApproved(this.waiver);

      saveWaiverMethod.subscribe({
        next: (data: Waiver) => this.handleSuccess(data),
        error: (error: HttpErrorResponse) => this.handleError(error)
      });
    }
  }

  private handleSuccess(data: Waiver): void {
    this.dataWaiverResult.data = [data];
    this.nextStep();
    this.loadingSaveWaiver = false;
    this.budgetService.triggerReload();

    if (!this.sessionInfoService.getSessionInfo().isSelfManaged) {
      this.showStatusAlert(data);
    }
  }

  nextStep(){
    if (this.waiverModule.includes('POL')){
      this.continueProcess.emit(this.dataWaiverResult);
    }
  }

  showStatusAlert(waiver: Waiver){
    let title: string = waiver.status.id == 1 ? `Waiver ${this.translateService.instant('pending')}` : `Waiver ${this.translateService.instant('approved')}`;
    let text: string = waiver.status.id == 1 ? '' : `Waiver (folio) ${waiver.folio}`;
    let icon: any = waiver.status.id == 1 ? "info" : "success";
    Swal.fire({
      title: title,
      text: text,
      icon: icon
    });
  }

  private handleError(error: HttpErrorResponse): void {
    const ex: Exception = new Exception('', this.getErrorMessage());
    this.messageService.showErrorMessage(ex, 'Aceptar');
    this.loadingSaveWaiver = false;
    this.router.navigateByUrl('/agency/home');
  }

  getErrorMessage(): string{
    return 'Ha ocurrido un error en la solicitud de waiver.';
  }

  validatePresaveConditions(){
    this.showWaiverTypeErrors = this.selectedWaiverType === '' || this.selectedWaiverMotive?.name == '' || (this.documentationNeeded && this.files.length === 0);

    return !this.showWaiverTypeErrors;
  }

  summaryInfoWaiver(): Waiver {
    if (this.reservationInfo.dataReservation.data.length !== 0) {
        this.populateWaiverFromReservation();
    }

    this.setCommonWaiverFields();
    this.waiver.files = this.initializeFiles();

    return this.waiver;
  }

  populateWaiverFromReservation() {
      this.waiver.pnr = this.reservationInfo.dataReservation.data[0]['pnr'];
      this.waiver.booking = this.reservationInfo.dataReservation.data[0]['booking'];
      this.waiver.passengers = this.selectionPassengers.selected;
      this.waiver.segments = this.sortSegments(this.reservationInfo.dataSegments.data);
      this.waiver.document = 'pnr';
      this.waiver.documentNumber = this.reservationInfo.dataReservation.data[0]['pnr']['pnrNumber'];
      this.waiver.documentCreationDate = this.reservationInfo.dataReservation.data[0]['pnr']['createDateTime'];
      this.waiver.documentPos = this.reservationInfo.dataReservation.data[0]['booking']['pos'];
      this.waiver.numberOfPassenger = this.reservationInfo.dataPassengers.data.length;
      this.waiver.valorization = this.waiverUsdTotalValue.toString();
      this.waiver.documentationNeeded = this.documentationNeeded;
  }

  sortSegments(segments) {
    return segments.sort(
        (a, b) => (a.segmentNumber > b.segmentNumber) ? 1 : ((b.segmentNumber > a.segmentNumber) ? -1 : 0)
    );
  }

  setCommonWaiverFields() {
    this.waiver.aditionalComment = this.aditionalComment;
    this.waiver.type = this.selectedType;
    this.waiver.motive = this.selectedWaiverMotive;
    this.waiver.category = this.selectedType.waiverCategory;
  }

  // FILES LOGIC
  initializeFiles(): any[] {
    let archivos = [];
    this.files.forEach(fil => {
      archivos.push({
        name: fil.data.name.toString(),
        size: fil.data.size.toString(),
        type: fil.data.type.toString(),
        locationStorage: fil.data['locationStorage']
      });
    });
    return archivos;
  }

  onClick() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    let sameFileCount: number;
    fileUpload.onchange = () => {
      let index = fileUpload.files.length - 1;
      const file = fileUpload.files[index];
      sameFileCount = 0;
      this.files.forEach((f) => {
        if (f.data.name === file.name) {
          sameFileCount++;
        }
      });

      if (!this.allowedFileFormats.includes(file.type)) {

        this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.invalidFileFormat')
        );
      } else if (file.name.length > 100) {

        this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.fileName')
        );
      } else if (file.size > 2097152) {

        this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.maxSize')
        );
      } else {
        if (sameFileCount > 0) {
          this.messageService.showWarningMessage(
            this.translateService.instant('button.confirm'),
            this.translateService.instant('warning.alreadyLoadedFile')
          );
        } else {
          this.validateAndUploadFile(file);

        }
      }
    };

    fileUpload.value = '';
    fileUpload.click();
  }

  validateAndUploadFile(file: File) {
    this.files.push({
      data: file, state: 'in', inProgress: false, progress: 0, canRetry: false, canCancel: true
    });
    let indexZ = this.files.length - 1;
    this.uploadFile(this.files[indexZ]);

  }

  cancelFile(file: FileUploadModel) {
    file.deletingFile = true;

    this.fileStorageService.deleteFile(file.data['locationStorage']).subscribe({
      next: (event: any) => {
        this.removeFileFromArray(file);
        file.deletingFile = false;
      },
      error: (error: HttpErrorResponse) => {
        file.deletingFile = false;
        console.log('error', error);
      }});
  }

  retryFile(file: FileUploadModel) {
    this.uploadFile(file);
    file.canRetry = false;
  }

  private uploadFile(file: FileUploadModel) {
    file.inProgress = true;
    this.loadingUpload = true;

    this.fileStorageService.uploadFile(file.data).subscribe({
      next: (event: any) => {
        let indexZ = this.files.length - 1;
        this.files[indexZ].data['locationStorage'] = event.reference;
        this.loadingUpload = false;
      },
      error: (error: HttpErrorResponse) => {
        this.removeFileFromArray(file);
        console.log('error', error);
        this.loadingUpload = false;
      }});
  }

  private removeFileFromArray(file: FileUploadModel) {
    const index = this.files.indexOf(file);

    if (index > -1) {
      this.files.splice(index, 1);
    }
  }

  public validateFile() {
    if (this.selectedWaiverMotive) {
      if (this.selectedWaiverMotive.attachmentFile === true) {
        if (this.files.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  }

}

import { Inject, Pipe, PipeTransform, LOCALE_ID } from '@angular/core';
import * as moment from 'moment';


@Pipe({
  name: 'dateFormat',
  pure: false
})
export class DateFormatPipe implements PipeTransform {
  locale: string;

  constructor(@Inject(LOCALE_ID) locale: string) {
    this.locale = locale;
  }

  /**
   * Formatea una fecha UTC de tipo yyyy-MM-dd'T'HH:mm:ss.SSSZ al formato especificado
   * @param value Fecha en formato UTC: yyyy-MM-dd'T'HH:mm:ss.SSSZ
   * @param format Formato a aplicar
   */
  transform(value: string, format: string): string {
    if (value) {
      return moment(moment.utc(value).local().locale(this.locale)).format(format);
    }
  }
}

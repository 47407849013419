<ng-container>
  <ng-container>
   
    <!-- Comienzo de menu  -->
    <mat-sidenav-content class="sidenav-content" style="margin-left: -15px;">
    
     
      <div class="content">     
                    <div class="row initStyle">
                     <div class="col style" style="top: 12px;">
                        <span class="title correct-name"  >{{ 'correctName.correctName' | translate }}</span>
                      </div>
                    </div>
                    <div id="menuColor" class="header-latam-menu " >
                      <div class="latam-menu-left">
                          <button class="menu-item" style="padding-right: 50px;"  routerLinkActive="active-menu-item" 
                          (click)="navigateToMain()" >
                              <span>{{ 'correctName.correctName' | translate }}</span>
                          </button>
            
                          <button 
                            class="menu-item" 
                            style="padding-left: -1px; padding-right: 50px;" 
                            routerLinkActive="active-menu-item"
                            >
                            <span>{{ 'correctName.report' | translate }}</span>
                          </button>
                      </div>
                    </div>   
                  
                    
                    <!--Inicio de reporte-->
                    <div class="test">
                      <div class="row mt-4 justify-content-center" style="margin-top: 0.3rem !important;">
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <mat-form-field class="button-container" appearance="outline">
                            <mat-label>{{ 'field.rangeFrom' | translate }}</mat-label>
                            <input matInput id="startDateInput" name="startDate" #startDateInput="ngModel" readonly [matDatepicker]="startPicker" [max]="currentDate"
                              [(ngModel)]="startDate" (dateChange)="startDateChanged($event)">
                            <mat-datepicker-toggle matSuffix [for]="startPicker" class="custom-calendar-icon"></mat-datepicker-toggle>
                            <mat-datepicker #startPicker (opened)="setCalendarLanguage()"></mat-datepicker>
                          </mat-form-field>
                        </div>
                    
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <mat-form-field class="button-container" appearance="outline">
                            <mat-label>{{ 'field.rangeTo' | translate }}</mat-label>
                            <input id="endDateInput" matInput [min]="startDate" [max]="currentDate" [matDatepicker]="endPicker" name="endDate" [(ngModel)]="endDate" #endDateInput="ngModel"
                              readonly (dateChange)="endDateChanged($event)">
                            <mat-datepicker-toggle matSuffix [for]="endPicker" class="custom-calendar-icon"></mat-datepicker-toggle>
                            <mat-datepicker #endPicker (opened)="setCalendarLanguage()"></mat-datepicker>
                          </mat-form-field>
                        </div>
                    
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3" style="padding-top: 16.3px;">
                          <button class="my-custom-button" 
                                  [disabled]="!startDate || !endDate || loading" 
                                  (click)="generateReport()" 
                                  [ngStyle]="{
                                      'color': (!startDate || !endDate || loading) ? '#858585' : 'aliceblue',
                                      'background-color': (!startDate || !endDate || loading) ? '#E1E1E1' : '#e91e63',
                                      'border-color': 'transparent' 
                                  }"
                                  mat-stroked-button
                                  mat-flat-button>
                              {{ 'button.generateReport' | translate }}
                              <span class="spinner-border spinner-border-sm" *ngIf="loading"></span>
                          </button>
                      </div>
                      </div>
                    </div>
                    
                        
                    
                    <!--Fin del reporte-->  
                    <div class="info">
                      <img src="assets/images/waiver-latam/search.svg" />
                      <h2>{{ 'correctName.selectionReport' | translate }}</h2>
                  </div>
      </div> 
    </mat-sidenav-content>    
  </ng-container>
</ng-container>



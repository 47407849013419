import { HttpErrorResponse } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';

import { BulkLoadType } from '../../../models/bulk-load-types.model';
import { BusinessException, Exception, SystemException } from './../../../models/Exception.model';
import { FileUploadModel } from '../../../models/file-upload.model';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';

import { BulkLoadService } from '../../../services/bulkload.service';
import { MessageService } from './../../../services/message.service';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';
import { MessageErrorService } from '../../../services/message-error.service';


@Component({
  selector: 'app-bulk-load-latam',
  templateUrl: './bulk-load-latam.component.html',
  styleUrls: ['./bulk-load-latam.component.css'],
  animations: [
    trigger(
      'fadeInOut',
      [
        state('in', style({ opacity: 100 })),
        transition('* => void', [animate(300, style({ opacity: 0 }))])
      ]
    )
  ],
  encapsulation: ViewEncapsulation.None
})
export class BulkLoadLatamComponent implements OnInit {
  @ViewChild('bulkLoadForm') bulkLoadForm: NgForm;

  encodedFile: string;

  bulkLoadTypeList: BulkLoadType[];
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_LATAM, SidebarMenuModel.SUBMENU_BULK_LOAD);
  selectedBulkLoadType: BulkLoadType;

  loading: boolean = true;

  // ini adjuntar archivos
  /** File extension that accepted, same as 'accept' of <input type='file' />. By the default, it's set to 'image/*'. */
  @Input() accept = '.csv';
  /** Allow you to add handler after its completion. Bubble up response text from remote. */
  @Output() complete = new EventEmitter<string>();
  private files: Array<FileUploadModel> = [];

  allowedFileFormats = ['application/csv', 'text/csv', 'application/vnd.ms-excel'];

  bulkLoadSuccessfully: boolean = false;
  bulkLoadProcess: boolean = false;
  operationfinishOK: boolean = false;
  // fin adjuntar archivos

  constructor(
    private bulkLoadService: BulkLoadService,
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private sidebarShareDataService: SidebarShareDataService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.loadBulkLoadTypes();
  }

  /**
   * Metodo para subir el archivo
   */
  onClick() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    const reader = new FileReader();
    reader.onload = () => {
      const binaryString = reader.result;
      this.encodedFile = btoa(binaryString.toString());
    };
    fileUpload.onchange = () => {

      let index = fileUpload.files.length - 1;
      const file = fileUpload.files[index];

      if (!this.allowedFileFormats.includes(file.type)) {
        this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.invalidFileFormat')
        );
      } else if (file.name.length > 100) {
        this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.fileName')
        );
      } else {
        this.files.push({
          data: file, state: 'in', inProgress: false, progress: 0, canRetry: false, canCancel: true
        });
        reader.readAsBinaryString(this.files[0].data);
      }
    };
    fileUpload.value = '';
    fileUpload.click();
  }

  /**
   * Funcion que carga los tipos de carga masiva de la BD
   */
  private loadBulkLoadTypes() {
    this.bulkLoadService.getTypes().subscribe({
      next: data => {
        this.bulkLoadTypeList = data;
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        console.log('Error loading loadBulkLoadTypes', error);
      }
  });
  }

  /**
   * @param file
   */
  cancelFile(file: FileUploadModel) {
    const index = this.files.indexOf(file);
    if (index > -1) {
      this.files.splice(index, 1);
    }
  }

  /**
   * Metodo que inicia el proceso de carga masiva
   */
  public initBulkLoad() {
    if (this.selectedBulkLoadType != null && this.files.length > 0 && this.bulkLoadProcess === false) {
      this.bulkLoadProcess = true;
      switch (this.selectedBulkLoadType.id) {
        case 1:
          this.bulkLoadService.importPiaAgency(this.files[0].data).subscribe({
            next: data => {
              this.showSuccessMessage(this.selectedBulkLoadType.id);
              this.bulkLoadProcess = false;
              this.bulkLoadSuccessfully = true;
              this.operationfinishOK = true;
            },
            error: (error: HttpErrorResponse) => {
              this.bulkLoadProcess = false;
              this.operationfinishOK = false;
              this.messageErrorService.sendError(error, 'error.bulkLoad');

              console.log('error', error);
            }
      });
          break;
        case 2:
          this.bulkLoadService.importPiaUser(this.files[0].data).subscribe({
            next: data => {
              this.showSuccessMessage(this.selectedBulkLoadType.id);
              this.bulkLoadProcess = false;
              this.bulkLoadSuccessfully = true;
              this.operationfinishOK = true;
            },
            error: (error: HttpErrorResponse) => {
              this.bulkLoadProcess = false;
              this.operationfinishOK = false;
              this.messageErrorService.sendError(error, 'error.bulkLoad');

              console.log('error', error);
            }
      });
          break;
        case 3:
          this.bulkLoadService.importAdmcAgency(this.encodedFile).subscribe({
            next: data => {
              this.showSuccessMessage(this.selectedBulkLoadType.id);
              this.bulkLoadProcess = false;
              this.bulkLoadSuccessfully = true;
              this.operationfinishOK = true;
            },
            error: (error: HttpErrorResponse) => {
              this.bulkLoadProcess = false;
              this.operationfinishOK = false;
              this.messageErrorService.sendError(error, 'error.bulkLoad');

              console.log('error', error);
            }
      });
          break;
      }
    } else {
      console.log('Error, la condicion no se cumple');
    }
  }

  /**
   * shows a message of success
   */
  private showSuccessMessage(idBulkLoad: number) {
    let messageTextUserSuccess = '';

    if (idBulkLoad === 1) {
      messageTextUserSuccess = this.translateService.instant('success.bulkLoadPiaAgencies');
    } else if (idBulkLoad === 2) {
      messageTextUserSuccess = this.translateService.instant('success.bulkLoadPiaUsers');
    } else {
      messageTextUserSuccess = this.translateService.instant('success.bulkLoadAdmcAgencies');
    }

    swal.fire({
      title: '<p>' + this.translateService.instant('popup.wellDone') + '</p>',
      html: '<p>' + messageTextUserSuccess + '</p>',
      confirmButtonText: this.translateService.instant('button.confirm'),
      confirmButtonColor: '#d33',
      icon: 'success',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        this.refreshComponent();
      }
    });
  }

  /**
   * Metodo que sirve para refrescar la pagina
   */
  refreshComponent() {
    if (this.operationfinishOK) {
      this.bulkLoadSuccessfully = false;
      this.bulkLoadProcess = false;
      this.operationfinishOK = false;
      this.loading = true;
      this.bulkLoadTypeList = [];
      this.selectedBulkLoadType = null;
      this.files = [];
      this.ngOnInit();
    }
  }
}

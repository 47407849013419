<div class="content">
  <mat-horizontal-stepper [linear]="true" #stepper>   
    <mat-step>
      <div class="reservation-info-container">         
        <div class="info-container">
          <!-- Personal INFO -->
          <span class="subtitle-name">
            {{ 'correctName.personalInfo' | translate }}
          </span>  
          <div class="search-main-container">
            <div class="test">
              <!--Primera fila-->
              <div class="search-inputs">
                <form [formGroup]="passengerForm">  
                  <!--select-->
                  <mat-form-field class="button-container " appearance="outline">
                    <mat-label>{{ 'correctName.passenger' | translate }}</mat-label>
                    
                    
                    <mat-select id="selectMotivo" (selectionChange)="actualizarCampos($event)" name="passengers" formControlName="paxNumber">
                      <mat-option *ngFor="let passenger of passengers" [value]="passenger.paxNumber">
                        {{passenger.firstName + " " + passenger.lastName}}
                      </mat-option>                                                      
                    </mat-select>
                    
                
                  </mat-form-field>              
                  <!--Select-->                    
                  <mat-form-field class="button-container" appearance="outline">
                    <mat-label>{{ 'correctName.changeName' | translate }}</mat-label>
                    <mat-select id="selectMotivo" name="motif" formControlName="motif">
                      <mat-option *ngFor="let motif of motiveTypes" [value]="motif.id">
                        {{ motif.name | translate}}
                      </mat-option>                                                      
                    </mat-select>
                  </mat-form-field>              
                </form>                             
              </div>
              <!--segunda fila-->
              <div class="search-inputs">
                <form [formGroup]="passengerForm">                
                  <!--Boton-->
                  <mat-form-field class="button-container" appearance="outline">
                    <mat-label>{{ 'correctName.name' | translate }}</mat-label>
                    <input  
                      matInput
                      formControlName="firstName"
                      [ngClass]="{'is-invalid': passengerForm.get('firstName').hasError('noMayusculas') && (passengerForm.get('firstName').touched || passengerForm.get('firstName').dirty)}"
                    >
                    <mat-error *ngIf="passengerForm.get('firstName').hasError('noMayusculas') && (passengerForm.get('firstName').touched || passengerForm.get('firstName').dirty)">
                      {{ 'correctName.capitalLetters' | translate }}
                    </mat-error>
                  </mat-form-field>                    
                  <mat-form-field class="button-container" appearance="outline">
                    <mat-label>{{ 'correctName.lastName' | translate }}</mat-label>
                    <input  
                      matInput
                      formControlName="lastName"
                      [ngClass]="{'is-invalid': passengerForm.get('lastName').hasError('noMayusculas') && (passengerForm.get('lastName').touched || passengerForm.get('lastName').dirty)}"
                    >
                    <mat-error *ngIf="passengerForm.get('lastName').hasError('noMayusculas') && (passengerForm.get('lastName').touched || passengerForm.get('lastName').dirty)">
                      {{ 'correctName.capitalLetters' | translate }}
                    </mat-error>
                  </mat-form-field>                            
                </form>                             
              </div>
              <!--Tercera fila-->
              <div class="search-inputs" style="display: flex; justify-content: space-around;">
                <form [formGroup]="passengerForm">                
                  <!--Boton-->
                  <mat-form-field class="button-container" appearance="outline">
                    <mat-label>{{ 'correctName.typeOfPassengers' | translate }}</mat-label>
                    <input  
                      matInput
                      maxlength="6"
                      formControlName="type"
                    >
                  </mat-form-field>              
                  <!--mat-form-field class="button-container" appearance="outline">
                    <mat-label>{{ 'correctName.typeSale' | translate }}</mat-label>
                    <input  
                      matInput
                      maxlength="6"
                      formControlName="SaleValue"
                    >
                  </mat-form-field-->        
                </form>                             
              </div>
              <div class="contenedor-search">
                <button class="my-custom-button" [disabled]="loadingInfo"
                        (click)="validationRequestType()" 
                        [disabled]="!passengerForm.valid"
                        [ngStyle]="{
                            'color': passengerForm.valid ? 'aliceblue' : '#858585',
                            'background-color': passengerForm.valid ? '#e91e63' : '#E1E1E1',
                            'border-color': passengerForm.valid ? 'transparent' : 'transparent'
                        }" 
                        mat-stroked-button 
                        mat-flat-button>
                    <span *ngIf="!loadingInfo">
                        {{ 'correctName.validateCorrection' | translate }}
                    </span>
                    <mat-progress-spinner 
                        *ngIf="loadingInfo"
                        class="custom-spinner"
                        mode="indeterminate" 
                        diameter="20">
                    </mat-progress-spinner>
                </button>
              </div> 
            
            </div> 
          </div>
        </div>  
        <!--Inicio de tarjeta-->           
        <span class="subtitle-name">
          {{ 'correctName.informationFlight' | translate }}
        </span>  

        <!--Tarjeta informativa vuelo de ida-->
        <div class="info-container-flight">
          <form [formGroup]="passengerForm"> 
            <div class="container-card">
              <div class="Information-flight">
                  <span style="font-family: 'LatamSansBold' !important;">
                    {{ 'correctName.outboundFlight' | translate }} · 
                  </span>
                  <span>
                    {{ departureDate }}
                  </span>
              </div>
              <div class="Information-flight-shipment">
                <span style="font-size: 30px;">                  
                   {{ formattedDepartureTimeGo }}
                  <span style="margin-left: 7px;">
                    {{ passengerForm.get('source').value }}
                  </span>
                </span>
                <div class="flight-arrival">
                  
                    <span style="margin-left: 7px;">
                    {{ passengerForm.get('destination').value }}
                    </span>
                </div>
                <div class="space-line">
                  <div class="linea-vertical"></div>
                </div>
                <div class="flight" >
                  <span id="flight">
                    {{ 'correctName.flight' | translate }}
                  </span>
                  <span id="hour-flight">
                    {{ passengerForm.get('flightGo').value }}
                  </span>            
                </div>
                <div class="space-line-one">
                  <div class="linea-vertical"></div>
                </div>
                <div class="flight" >
                  <span id="flight">
                    {{ 'correctName.class' | translate }}
                  </span>
                  <span id="hour-flight">
                    {{ passengerForm.get('classGo').value }}
                  </span>            
                </div>
                <div class="space-line-one">
                  <div class="linea-vertical"></div>
                </div>
                <div class="flight" >
                  <span id="flight">
                    {{ 'correctName.state' | translate }}
                  </span>
                  <span id="hour-flight">
                    {{ passengerForm.get('statusGO').value }}                    
                  </span>            
                </div>      
                  
              
              </div>
            </div>   
          </form>    
        </div>   
         <br>
        <!--Tarjeta informativa vuelo de vuelta-->
        <div class="info-container-flight"  *ngIf="contarSegmentNumbers() > 1">
          <form [formGroup]="passengerForm"> 
              <div class="container-card">
                  <div class="Information-flight">
                      <span style="font-family: 'LatamSansBold' !important;">
                          {{ 'correctName.returnFlight' | translate }} · 
                      </span>
                      <span>
                          {{ returnDate }}
                      </span>
                  </div>
                  <div class="Information-flight-shipment"> <!-- Condición para mostrar el contenido -->
                      <span style="font-size: 30px;">                  	
                          {{ formattedDepartureTimeReturn }}
                          <span style="margin-left: 7px;">
                              {{ passengerForm.get('sourceReturn').value }}
                          </span>
                      </span>
                      <div class="flight-arrival">                  
                          <span style="margin-left: 11px;">
                              {{ passengerForm.get('source').value }}
                          </span>                 
                      </div>
      
                      <div class="space-line">
                          <div class="linea-vertical"></div>
                      </div>
                      <div class="flight">
                          <span id="flight">
                              {{ 'correctName.flight' | translate }}
                          </span>
                          <span id="hour-flight">
                              {{ passengerForm.get('flightReturn').value }}
                          </span>
                      </div>
                      <div class="space-line-one">
                          <div class="linea-vertical"></div>
                      </div>
                      <div class="flight">
                          <span id="flight">
                              {{ 'correctName.class' | translate }}
                          </span>
                          <span id="hour-flight">
                              {{ passengerForm.get('classReturn').value }}
                          </span>
                      </div>
                      <div class="space-line-one">
                          <div class="linea-vertical"></div>
                      </div>
                      <div class="flight">
                          <span id="flight">
                              {{ 'correctName.state' | translate }}
                          </span>
                          <span id="hour-flight">
                              {{ passengerForm.get('statusReturn').value }}                    
                          </span>
                      </div>                       
                  </div>
              </div>   
          </form>    
      </div>
        <br>
        <br>
        <br>
        <br>
      </div>  
    </mat-step>
  </mat-horizontal-stepper>
  
</div>
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MessageErrorService } from '../../../services/message-error.service';

@Component({
  selector: 'app-modal-error-correct-name',
  templateUrl: './modal-error-correct-name.component.html',
  styleUrls: ['./modal-error-correct-name.component.css']
})
export class ModalErrorCorrectNameComponent {

  messageError:string;

  constructor(
    private dialogRef: MatDialogRef<ModalErrorCorrectNameComponent>,
    private messageErrorService: MessageErrorService

  ){ }

}

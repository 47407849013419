import { Component, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';


@Component({
  selector: 'app-dialog-budget',
  templateUrl: './dialog-budget.component.html',
  styleUrls: ['./dialog-budget.component.css']
})
export class DialogBudgetComponent {
  selectedRow: number = -1;

  dataSource = new MatTableDataSource();
  displayedColumns = ['name', 'currentAmount'];





  highlightRow(event: Event, rowIndex: number) {
    if (rowIndex > 0) {
      this.selectedRow = event.type === 'mouseover' ? rowIndex : -1;
    }
  }

}

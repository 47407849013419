import { Component, Inject, LOCALE_ID, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


import { Profile } from '../../../models/profile.model';
import { SessionInfo } from '../../../models/SessionInfo.model';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';

import { ProfilingService } from '../../../services/profiling.service';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';
import { SessionInfoService } from '../../../services/session-info.service';




@Component({
  selector: 'app-list-waiver-agency',
  templateUrl: './list-waiver-agency.component.html',
  styleUrls: ['./list-waiver-agency.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ListWaiverAgencyComponent implements OnInit {

  selectedTab: number;
  sessionInfo: SessionInfo;
  locale: string;


  displayedColumnsPending = ['id', 'type', 'motive', 'documentNumber', 'agent', 'creationDate', 'documentCloserDepartureDate', 'actions'];
  displayedColumnsPendingAgency = ['id', 'type', 'motive', 'documentNumber', 'agent', 'statusDate', 'documentCloserDepartureDate', 'actions'];
  displayedColumnsApproved = ['id', 'type', 'motive', 'documentNumber', 'agent', 'statusDate', 'documentCloserDepartureDate', 'actions'];
  displayedColumnsRejected = ['id', 'type', 'motive', 'documentNumber', 'agent', 'statusDate', 'documentCloserDepartureDate', 'actions'];
  infoUser: SessionInfo = new SessionInfo();
  profiles = new Profile();
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_WAIVERS, SidebarMenuModel.SUBMENU_WAIVER_MANAGEMENT);
  tab:string;

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sessionInfoService: SessionInfoService,
    private profilesService: ProfilingService,
    private sidebarShareDataService: SidebarShareDataService) {
      this.locale = locale;
  }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.sessionInfo = this.sessionInfoService.getSessionInfo();
  }

  /*
   * Metodo que redirecciona a la creación de nueva solicitud de waiver
   */
  nuevaSolicitud() {
    this.router.navigate(['../request'], { relativeTo: this.activatedRoute });
  }

}


export class WaiverMotive {
    public id: number;
    public code: string;
    public name: string;
    public attachmentFile: boolean;
    public calculateWis: boolean;
    public saveWis: boolean;

    public constructor(id?: number, code?: string, name?: string, attachmentFile?: boolean) {
        this.id = id;
        this.code = code;
        this.name = name;
        this.attachmentFile = attachmentFile;

    }
}

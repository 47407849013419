import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

import { TermsConditions } from '../models/terms-conditions.model';

import { RouteLocaleExtractorService } from './route-locale-extractor.service';
import { StorageService } from './storage.service';


@Injectable()
export class TermsConditionsService {

  constructor(
    private http: HttpClient,
    private routeLocaleExtractorService: RouteLocaleExtractorService) { }

  /**
   * Method that calls the rest service to get the url for termsConditions download
   */
  getTermsConditionsUrl() {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<TermsConditions>(`${StorageService.getDnsLatamtrade()}${environment.endpoints.urlUserAdmin}${locale}/terms-conditions`);
  }

  /**
   * Method that calls the rest service to get the url for waiver termsConditions download
   */
  getWaiverTermsConditionsUrl() {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<TermsConditions>(`${StorageService.getDnsLatamtrade()}${environment.endpoints.urlWaiver}${locale}/waivers/terms-conditions/pre-sale`);
  }

  /**
   * This method save the date of agree Terms conditions
   */
  setAgreeTermsConditions() {
    const locale = this.routeLocaleExtractorService.getLocale();
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    return this.http.put(`${StorageService.getDnsLatamtrade()}${environment.endpoints.urlUserAdmin}${locale}/terms-conditions/agree`, { headers: headers });
  }

}

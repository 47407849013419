export class AgencyBase {
  status: string;
  agencyCode: number;
  agencyBranch: number;
  iataCode: string;
  taxIdentifier: string;
  tradeName: string;
  nameLegalRepresentative: string;
  groupCode: string;
  groupDescription: string;
  typeTargetCode: string;
  typeTargetDescription: string;
  typeAgencyCode: string;
  typeAgencyDescription: string;
  issuingChannelCode: string;
  issuingChannelDescription: string;
  salesChannelCode: string;
  salesChannelDescription: string;
  addressStreet: string;
  countryCode: string;
  countryDescription: string;
  cityCode: string;
  cityDescription: string;
  stateCode: string;
  stateDescription: string;
  phonePrefixNumber: string;
  phoneNumber: string;
  phoneExtNumber: string;
  salesForceCode: string;
  salesForceFirstName: string;
  salesForceLastName: string;
  salesForceEmail: string;
  consortiumCode: string;
  consortiumDescription: string;

  constructor(
    status?: string,
    agencyCode?: number,
    agencyBranch?: number,
    iataCode?: string,
    taxIdentifier?: string,
    tradeName?: string,
    nameLegalRepresentative?: string,
    groupCode?: string,
    groupDescription?: string,
    typeTargetCode?: string,
    typeTargetDescription?: string,
    typeAgencyCode?: string,
    typeAgencyDescription?: string,
    issuingChannelCode?: string,
    issuingChannelDescription?: string,
    salesChannelCode?: string,
    salesChannelDescription?: string,
    addressStreet?: string,
    countryCode?: string,
    countryDescription?: string,
    cityCode?: string,
    cityDescription?: string,
    stateCode?: string,
    stateDescription?: string,
    phonePrefixNumber?: string,
    phoneNumber?: string,
    phoneExtNumber?: string,
    salesForceCode?: string,
    salesForceFirstName?: string,
    salesForceLastName?: string,
    salesForceEmail?: string,
    consortiumCode?: string,
    consortiumDescription?: string
  ) {
    this.status = status;
    this.agencyCode = agencyCode;
    this.iataCode = iataCode;
    this.agencyBranch = agencyBranch;
    this.taxIdentifier = taxIdentifier;
    this.tradeName = tradeName;
    this.nameLegalRepresentative = nameLegalRepresentative;
    this.groupCode = groupCode;
    this.groupDescription = groupDescription;
    this.typeTargetCode = typeTargetCode;
    this.typeTargetDescription = typeTargetDescription;
    this.typeAgencyCode = typeAgencyCode;
    this.typeAgencyDescription = typeAgencyDescription;
    this.issuingChannelCode = issuingChannelCode;
    this.issuingChannelDescription = issuingChannelDescription;
    this.salesChannelCode = salesChannelCode;
    this.salesChannelDescription = salesChannelDescription;
    this.addressStreet = addressStreet;
    this.countryCode = countryCode;
    this.countryDescription = countryDescription;
    this.cityCode = cityCode;
    this.cityDescription = cityDescription;
    this.stateCode = stateCode;
    this.stateDescription = stateDescription;
    this.phonePrefixNumber = phonePrefixNumber;
    this.phoneNumber = phoneNumber;
    this.phoneExtNumber = phoneExtNumber;
    this.salesForceCode = salesForceCode;
    this.salesForceFirstName = salesForceFirstName;
    this.salesForceLastName = salesForceLastName;
    this.salesForceEmail = salesForceEmail;
    this.consortiumCode = consortiumCode;
    this.consortiumDescription = consortiumDescription;
  }
}

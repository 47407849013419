<div class="container">
    <div class="row">
        <div class="col">
            <span id="searchAgencyTitle" class="title">{{ 'menu.dudasCasos' | translate }}</span>
        </div>
    </div>

    <hr>
    <div class="row">
        <form ngNoForm [action]="url" method="POST" target="_blank">
            <input type=hidden name="orgid" value="00D5f000005Xvyi">
            <input type=hidden name="retURL" value="https://www.latamairlines.com">
            <input type=hidden name="status" value="Nuevo">
            <input type=hidden name="priority" value="Media">
            <div class="container">
                <div class="row ">
                    <div class="col-md-12" style="margin-bottom: 5px;">
                        <mat-label> {{ 'formcases.recordType' | translate }} </mat-label><br>
                        <select matNativeControl required name="recordType" id="recordType">
                            <option value="">{{ 'formcases.none' | translate }}</option>
                            <option value="0125f000000Reqy">{{ 'formcases.recordName.dudas' | translate }}</option>
                            <option value="0125f000000Reqz">{{ 'formcases.recordName.latam' | translate }}</option>
                            <option value="0125f000000Rer0">{{ 'formcases.recordName.grupos' | translate }}</option>
                            <option value="0125f000000Rer1">{{ 'formcases.recordName.help' | translate }}</option>

                            <option value="0125f000000Rer2">{{ 'formcases.recordName.support' | translate }}</option>
                        </select>
                    </div>

                    <div class="col-md-12" style="margin-bottom: 5px;">
                        <mat-label> {{ 'formcases.preferenceLenguaje' | translate }} </mat-label><br>
                        <select matNativeControl required name="00N5f00000gBkvN" id="00N5f00000gBkvN">
                            <option value="">{{ 'formcases.none' | translate }}</option>
                            <option value="Espanol">Español</option>
                            <option value="Ingles">Inglés</option>
                            <option value="Portugues">Portugués</option>
                            <option value="Frances">Francés</option>
                            <option value="Aleman">Alemán</option>
                            <option value="Italiano">Italiano</option>
                        </select>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field>
                            <mat-label> {{ 'formcases.accountName' | translate }} </mat-label>
                            <input matInput id="company" maxlength="80" name="company" size="20" type="text" required />
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field>
                            <mat-label> {{ 'formcases.contactName' | translate }} </mat-label>
                            <input matInput id="name" maxlength="80" name="name" size="20" type="text" required />
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field>
                            <mat-label> {{ 'formcases.caseSubject' | translate }} </mat-label>
                            <input matInput id="subject" maxlength="80" name="subject" size="20" type="text" required />
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field>
                            <mat-label> {{ 'formcases.caseDescription' | translate }} </mat-label>
                            <input matInput name="description" required />
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field>
                            <mat-label> {{ 'formcases.email' | translate }} </mat-label>
                            <input matInput id="email" maxlength="80" name="email" size="20" required type="email" />
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <button class="mainButton" mat-raised-button type="submit">
                            {{ 'formcases.enviar' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

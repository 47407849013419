import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { WaiverTermsConditionsComponent } from './waiver-terms-conditions/waiver-terms-conditions.component';
import { DialogManualTypeComponent } from './dialog-manual-type/dialog-manual-type.component';

import { ShareModule } from './../share-module/share.module';

import { FileStorageService } from './../../services/file-storage.service';
import { LoginAgencyService } from './../../services/login-agency.service';
import { TermsConditionsService } from '../../services/terms-conditions.service';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSelectModule } from '@angular/material/select'; 
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    TermsConditionsComponent,
    WaiverTermsConditionsComponent,
    DialogManualTypeComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    PdfViewerModule,
    ShareModule,
    MatTableModule,
    MatSelectModule,
    MatIconModule,
    FormsModule
  ],
  exports: [
    WaiverTermsConditionsComponent
  ],
  providers: [
    FileStorageService,
    LoginAgencyService,
    TermsConditionsService
  ]
})
export class TermsConditionsModule { }

import { Injectable } from '@angular/core';
import { AgencyService } from './agency.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AgencyGroupService } from './agency-group.service';

@Injectable({
  providedIn: 'root'
})
export class AgencyGroupUtilService {

  constructor(private agencyService: AgencyService,
    private agencyGroupService: AgencyGroupService,
  ) { }

  loadChannels(component: any): void {
    this.agencyService.getAgencyChannelList().subscribe({
      next: data => {
        component.channelList = data['channels'];
      },
      error: (error: HttpErrorResponse) => {
        console.log('Error loading channels', error);
      }
    });
  }

  loadTiers(component: any): void{
    this.agencyGroupService.getTierList().subscribe({
      next: data => {
        component.tierList = data;
      },
      error: (error: HttpErrorResponse) => {
        console.log('Error loading Tiers', error);
      }
    });
  }

  loadAdminSales(component: any): void {
    this.agencyGroupService.getAdminSalesList().subscribe({
      next: data => {
        component.adminSalesList = data;
      },
      error: (error: HttpErrorResponse) => {
        console.log('Error loading adminSales', error);
    }});
  }

  loadSalesExecutives(component: any): void {
    this.agencyGroupService.getUserSalesList().subscribe({
      next: data => {
        component.salesExecutiveList = data;
      },
      error: (error: HttpErrorResponse) => {
        console.log('Error loading salesExecutive', error);
    }});
  }
}

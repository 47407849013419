import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'capitalize'})
export class CapitalizePipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      let splitted: string[] = value.split(' ');
      let capitalizedString: string = '';

      for (let i = 0; i < splitted.length; i++) {
        if (splitted[i]) {
          splitted[i] = splitted[i][0].toUpperCase() + splitted[i].slice(1).toLowerCase();

          if (i === (splitted.length - 1)) {
            capitalizedString += splitted[i];
          } else {
            capitalizedString += splitted[i] + ' ';
          }
        }
      }

      return capitalizedString;
    }
  }
}

<div class="container">
  <app-list-agency-header></app-list-agency-header>
  <div class="row mt-3">
    <div class="col">
      <form [formGroup]="formFilter"  (ngSubmit)="filtrar()">
        <div class="row">
          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2" id="list-waiver-latam-idRequest">
            <mat-form-field>
              <mat-label>{{ 'field.idRequest' | translate }}</mat-label>
              <input matInput id="idRequest" type="number"  formControlName="idRequest"
                maxlength="100">

            </mat-form-field>

          </div>
          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2" id="list-waiver-latam-pos">
            <mat-form-field>
              <mat-label>{{ 'field.pos' | translate }}</mat-label>
              <input matInput id="pos"  formControlName="pos"
                maxlength="100">

            </mat-form-field>

          </div>
          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2" id="list-waiver-latam-agency">
            <mat-form-field>
              <mat-label>{{ 'field.agency' | translate }}</mat-label>
              <input matInput id="agency"  formControlName="agency"
                maxlength="100">

            </mat-form-field>

          </div>
          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2" id="list-waiver-latam-iata">
            <mat-form-field>
              <mat-label>{{ 'field.iata' | translate }}</mat-label>
              <input matInput id="iata" formControlName="iata"
                maxlength="100">

            </mat-form-field>

          </div>

          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2" id="list-waiver-latam-requestDate">
            <mat-form-field>
              <mat-label>{{ 'field.requestDateHour' | translate }}</mat-label>
              <input id="requestDate" matInput formControlName="requestDate"
                [matDatepicker]="startPicker" name="requestDate">
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker (opened)="setCalendarLanguage()"></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2">

          </div>

          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2" id="list-waiver-latam-salesEjecutive">
            <mat-form-field>
              <mat-label>{{ 'field.executiveSale' | translate }}</mat-label>
              <input matInput id="salesEjecutive" formControlName="salesEjecutive"
                maxlength="100">

            </mat-form-field>

          </div>
          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2" id="list-waiver-latam-type">
            <mat-form-field>
              <mat-label>{{ 'field.type' | translate }}</mat-label>
              <input matInput id="type" formControlName="type"
                maxlength="100">

            </mat-form-field>

          </div>
          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2" id="list-waiver-latam-motive">
            <mat-form-field>
              <mat-label>{{ 'field.reason' | translate }}</mat-label>
              <input matInput id="motive"  formControlName="motive"
                maxlength="100">

            </mat-form-field>

          </div>
          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2" id="list-waiver-latam-pnrTkt">
            <mat-form-field>
              <mat-label>{{ 'field.pnrTkt' | translate }}</mat-label>
              <input matInput id="pnrTkt"  formControlName="pnrTkt"
                maxlength="100">

            </mat-form-field>

          </div>

          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2" id="list-waiver-latam-flightDate">
            <mat-form-field>
              <mat-label>{{ 'field.flightDate' | translate }}</mat-label>
              <input id="fDate" matInput  formControlName="flightDate"
                [matDatepicker]="endPicker" name="flightDate">
              <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
              <mat-datepicker #endPicker (opened)="setCalendarLanguage()"></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2">
            <button type="submit" mat-button [disabled]="formFilter.invalid"   class="mat-raised-button background-blue w-100">{{ 'button.search' | translate }}</button>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2">
            <button type="button" *ngIf="countReject>1" (click)="openRejectDialog('pendientes', objectWaivers)" mat-button [disabled]="formFilter.invalid"   class="mat-raised-button background-blue w-100">{{ 'button.deny' | translate }}</button>
          </div>
        </div>
      </form>
    </div>
  </div>




  <mat-chip-list [ngClass]="{ 'd-none': pendingWaiversLoading || noPendingWaivers || pendingWaiversCountLoading }">
    <mat-chip id="countBadge">{{ pendingWaiversCount | parseWaiverCount }}</mat-chip>
  </mat-chip-list>

  <mat-card>
    <mat-card-content>

      <mat-tab-group #tabGroup (selectedIndexChange)="tabChange($event)">
        <mat-tab>
          <ng-template mat-tab-label>
            <span>{{ 'pendings' | translate }}</span>
          </ng-template>


          <div *ngIf="pendingWaiversLoading" class="loading">
            <div class="loading-div">
              <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                </circle>
              </svg>
              <span class="loading-span">{{ 'loading' | translate }}</span>
            </div>
          </div>

          <div class="table-waivers-latam" [ngClass]="{ 'd-none': pendingWaiversLoading }">
            <mat-table [dataSource]="dataWaiverPending" #table #sortPending="matSort" matSort>
              <ng-container class="row ml-0 mr-0 justify-content-center" matColumnDef="check">
                <mat-header-cell id="checkAll"*matHeaderCellDef mat-sort-header>
                  <mat-checkbox [(ngModel)]= "checkUncheckedAll" [checked]="true"
                  (change)="checkAllWaivers($event)" class="waiver-checkAll">
                  </mat-checkbox></mat-header-cell>
                <mat-cell *matCellDef="let object">
                <mat-checkbox #checkOne [id]="object.id" [name]="object.id" [(ngModel)]="object.check"
                  (change)="checkRejectWaiver(object, checkOne)" class="waiver-check">
                  </mat-checkbox></mat-cell>
              </ng-container>
              <ng-container matColumnDef="id" id="list-waiver-latam-pending-idRequest">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.idRequest' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">{{ object.id | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="marketName" id="list-waiver-latam-pending-pos">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.pos' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let object">{{ object.marketName | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="agency" id="list-waiver-latam-pending-agency">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.agency' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">
                  {{ (object.branchOffice ? object.branchOffice : object.agencyGroup) | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="agencyIata" id="list-waiver-latam-pending-iata">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.iata' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">{{ object.agencyIata | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="executive" id="list-waiver-latam-pending-executive">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.executiveSale' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">{{ object.executive | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="type" id="list-waiver-latam-pending-type">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.type' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">{{ object.type | dashWhenEmpty}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="motive" id="list-waiver-latam-pending-motive">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.reason' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">{{ object.motive | dashWhenEmpty}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="documentNumber" id="list-waiver-latam-pending-documentNumber">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.pnrTkt' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">
                  {{ object.documentNumber | dashWhenEmpty}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="creationDate" id="list-waiver-latam-pending-creationDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="title">
                  <span>{{ 'field.requestDateHour' | translate }}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">
                  {{ object.creationDate | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="documentCloserDepartureDate" id="list-waiver-latam-pending-documentCloserDepartureDate">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.flightDate' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">
                  {{ object.documentCloserDepartureDate | dateFormat: 'DD-MMM-YY' | uppercase | dashWhenEmpty }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="actions" id="list-waiver-latam-pending-actions">
                <mat-header-cell *matHeaderCellDef class="title"><span class="ml-2">{{ 'button.actions' | translate }}</span></mat-header-cell>
                <mat-cell *matCellDef="let object">
                  <button class="action" *ngIf="profiles.waiverManagment">
                    <span (click)="openDialog('pendientes', object)">{{ 'button.view' | translate }}</span>
                  </button>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="no-data-found" id="list-waiver-latam-pending-no-data-found">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell class="no-data-found-row" *matCellDef="let item">{{ 'error.notPendingWaivers' | translate }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="loading-data-error" id="list-waiver-latam-pending-loading-data-error">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell class="loading-data-error-row" *matCellDef="let item">
                  <span class="cdk-visually-hidden" #translatedTooltipMessage>{{ 'button.refresh' | translate }}</span>
                  <button mat-icon-button (click)="getPendingWaiver()" class="refresh-spinner">
                    <mat-icon [matTooltip]="translatedTooltipMessage.innerHTML" [matTooltipPosition]="'right'">refresh
                    </mat-icon>
                  </button>
                  <br>
                  <span>{{ 'error.loadingPendingWaivers' | translate }}</span>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumnsPending" class="waivers-latam-row"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsPending; let i = index"
                [ngClass]="{ 'highlight': selectedPendingWaiversRowIndex === i }"
                (mouseover)="highlightPendingWaiversRow($event, i)" (mouseout)="highlightPendingWaiversRow($event, i)"
                class="waivers-latam-row"></mat-row>
              <mat-row *matRowDef="let row; columns: ['no-data-found']; when: noPendingWaiversFound">
              </mat-row>
              <mat-row *matRowDef="let row; columns: ['loading-data-error']; when: errorLoadingPendingWaivers">
              </mat-row>
            </mat-table>

            <mat-paginator
              *ngIf="!noPendingWaivers"
              #paginatorPending
              (page)="pageChanged($event)"
              [pageSize]="10"
              [pageSizeOptions]="[10, 30, 50]"
              [showFirstLastButtons]="true">
            </mat-paginator>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <span>{{ 'approveds' | translate }}</span>
          </ng-template>

          <div *ngIf="approvedWaiversLoading" class="loading">
            <div class="loading-div">
              <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                </circle>
              </svg>
              <span class="loading-span">{{ 'loading' | translate }}</span>
            </div>
          </div>

          <div class="table-waivers-latam" [ngClass]="{ 'd-none': approvedWaiversLoading }">
            <mat-table [dataSource]="dataWaiverApproved" #sortApproved="matSort" matSort>
              <ng-container matColumnDef="id" id="list-waiver-latam-approved-idRequest">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.idRequest' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                 </mat-header-cell>
                <mat-cell *matCellDef="let object">{{ object.id | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="marketName" id="list-waiver-latam-approved-marketName">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.pos' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">{{ object.marketName | dashWhenEmpty }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="agency" id="list-waiver-latam-approved-agency">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.agency' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">
                  {{ (object.branchOffice ? object.branchOffice : object.agencyGroup) | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="agencyIata" id="list-waiver-latam-approved-iata">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.iata' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">{{ object.agencyIata | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="executive" id="list-waiver-latam-approved-executive">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.executiveSale' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">{{ object.executive | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="type" id="list-waiver-latam-approved-type">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.type' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">{{ object.type | dashWhenEmpty }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="motive" id="list-waiver-latam-approved-motive">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.reason' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                  </mat-header-cell>
                <mat-cell *matCellDef="let object">{{ object.motive | dashWhenEmpty}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="documentNumber" id="list-waiver-latam-approved-documentNumber">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.pnrTkt' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">{{ object.documentNumber | dashWhenEmpty}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="statusDate" id="list-waiver-latam-approved-statusDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="title">
                  <span>{{ 'field.approvalDateHour' | translate }}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">
                  {{ object.statusDate | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="documentCloserDepartureDate" id="list-waiver-latam-approved-documentCloserDepartureDate">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.flightDate' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">
                  {{ object.documentCloserDepartureDate | dateFormat: 'DD-MMM-YY' | uppercase | dashWhenEmpty }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="actions" id="list-waiver-latam-approved-actions">
                <mat-header-cell *matHeaderCellDef class="title"><span class="ml-2">{{ 'button.actions' | translate }}</span></mat-header-cell>
                <mat-cell *matCellDef="let object">
                  <button *ngIf="profiles.waiverManagment" class="action">
                    <span (click)="openDialog('aprobados', object)">{{ 'button.view' | translate }}</span>
                  </button>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="no-data-found" id="list-waiver-latam-approved-no-data-found">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell class="no-data-found-row" *matCellDef="let item">
                  {{ 'error.notApprovedWaivers' | translate }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="loading-data-error" id="list-waiver-latam-approved-loading-data-error">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell class="loading-data-error-row" *matCellDef="let item">
                  <span class="cdk-visually-hidden" #translatedTooltipMessage>{{ 'button.refresh' | translate }}</span>
                  <button mat-icon-button (click)="getApprovedWaiver()" class="refresh-spinner">
                    <mat-icon [matTooltip]="translatedTooltipMessage.innerHTML" [matTooltipPosition]="'right'">refresh
                    </mat-icon>
                  </button>
                  <br>
                  <span>{{ 'error.loadingAprovedWaivers' | translate }}</span>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumnsApproved" class="waivers-latam-row"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsApproved;  let i = index"
                [ngClass]="{ 'highlight': selectedApprovedWaiversRowIndex === i }"
                (mouseover)="highlightApprovedWaiversRow($event, i)" (mouseout)="highlightApprovedWaiversRow($event, i)"
                class="waivers-latam-row"></mat-row>
              <mat-row *matRowDef="let row; columns: ['no-data-found']; when: noApprovedWaiversFound">
              </mat-row>
              <mat-row *matRowDef="let row; columns: ['loading-data-error']; when: errorLoadingApprovedWaivers">
              </mat-row>
            </mat-table>

            <mat-paginator
              *ngIf="!noApprovedWaivers"
              (page)="pageChanged($event)"
              #paginatorApproved
              [pageSize]="10"
              [pageSizeOptions]="[10, 30, 50]"
              [showFirstLastButtons]="true">
            </mat-paginator>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <span>{{ 'denieds' | translate }}</span>
          </ng-template>

          <div *ngIf="rejectedWaiversLoading" class="loading">
            <div class="loading-div">
              <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                </circle>
              </svg>
              <span class="loading-span">{{ 'loading' | translate }}</span>
            </div>
          </div>

          <div class="table-waivers-latam" [ngClass]="{ 'd-none': rejectedWaiversLoading }">
            <mat-table [dataSource]="dataWaiverRejected" #sortRejected="matSort" matSort>
              <ng-container matColumnDef="id" id="list-waiver-latam-reject-idRequest">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.idRequest' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">{{ object.id | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="marketName" id="list-waiver-latam-reject-marketName">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.pos' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">{{ object.marketName | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="agency" id="list-waiver-latam-reject-agency">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.agency' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">
                  {{ (object.branchOffice ? object.branchOffice : object.agencyGroup) | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="agencyIata" id="list-waiver-latam-reject-agencyIata">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.iata' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">{{ object.agencyIata | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="executive" id="list-waiver-latam-reject-executive">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.executiveSale' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">{{ object.executive| dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="type" id="list-waiver-latam-reject-type">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.type' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">{{ object.type | dashWhenEmpty }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="motive" id="list-waiver-latam-reject-motive">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.reason' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                 </mat-header-cell>
                <mat-cell *matCellDef="let object">{{ object.motive | dashWhenEmpty }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="documentNumber" id="list-waiver-latam-reject-documentNumber">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.pnrTkt' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                 </mat-header-cell>
                <mat-cell *matCellDef="let object">{{ object.documentNumber | dashWhenEmpty }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="statusDate" id="list-waiver-latam-reject-statusDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="title">
                  <span>{{ 'field.denialDateHour' | translate }}</span>
                  </mat-header-cell>
                <mat-cell *matCellDef="let object">
                  {{ object.statusDate | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="documentCloserDepartureDate" id="list-waiver-latam-reject-documentCloserDepDate">
                <mat-header-cell *matHeaderCellDef class="title">
                  <div>
                    <span>{{ 'field.flightDate' | translate }}</span>
                    <span mat-sort-header></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let object">
                  {{ object.documentCloserDepartureDate | dateFormat: 'DD-MMM-YY' | uppercase | dashWhenEmpty }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="actions" id="list-waiver-latam-reject-actions">
                <mat-header-cell *matHeaderCellDef class="title"><span class="ml-2">{{ 'button.actions' | translate }}</span></mat-header-cell>
                <mat-cell *matCellDef="let object">
                  <button *ngIf="profiles.waiverManagment" class="action">
                    <span (click)="openDialog('rechazados', object)">{{ 'button.view' | translate }}</span>
                  </button>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="no-data-found" id="list-waiver-latam-reject-no-data-found">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell class="no-data-found-row" *matCellDef="let item">{{ 'error.notDeniedWaivers' | translate }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="loading-data-error" id="list-waiver-latam-reject-loading-data-error">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell class="loading-data-error-row" *matCellDef="let item">
                  <span class="cdk-visually-hidden" #translatedTooltipMessage>{{ 'button.refresh' | translate }}</span>
                  <button mat-icon-button (click)="getRejectedWaiver()" class="refresh-spinner">
                    <mat-icon [matTooltip]="translatedTooltipMessage.innerHTML" [matTooltipPosition]="'right'">refresh
                    </mat-icon>
                  </button>
                  <br>
                  <span>{{ 'error.loadingDeniedWaivers' | translate }}</span>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumnsRejected" class="waivers-latam-row"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsRejected; let i = index"
                [ngClass]="{ 'highlight': selectedRejectedWaiversRowIndex === i }"
                (mouseover)="highlightRejectedWaiversRow($event, i)" (mouseout)="highlightRejectedWaiversRow($event, i)"
                class="waivers-latam-row"></mat-row>
              <mat-row *matRowDef="let row; columns: ['no-data-found']; when: noRejectedWaiversFound">
              </mat-row>
              <mat-row *matRowDef="let row; columns: ['loading-data-error']; when: errorLoadingRejectedWaivers">
              </mat-row>
            </mat-table>

            <mat-paginator
              *ngIf="!noRejectedWaivers"
              (page)="pageChanged($event)"
              #paginatorRejected [pageSize]="10"
              [pageSizeOptions]="[10, 30, 50]"
              [showFirstLastButtons]="true">
            </mat-paginator>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>

<div class="container" id="new-manual-container">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="latam-sans breadcrumb-item"><a>{{ 'menu.userManual' | translate }}</a></li>
          <li class="breadcrumb-item latam-sans active" aria-current="page">
            <a>{{ 'field.userManual' | translate }}</a></li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col">

      <span class="title"> {{'field.userManual' | translate}}</span>
    </div>

  </div>
<hr>

<div class="row" id="new-manual-table-row">
  <div class="col" id="new-manual-table-col">

    <mat-table id="fileListTable" #table [dataSource]="manualDataSource"  matSort>
      <ng-container matColumnDef="fileName" >
        <mat-header-cell *matHeaderCellDef mat-sort-header class="file-name-cell-width">{{ 'field.name' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="file-name-cell file-name-cell-width">
          <mat-icon class="icon-color">description</mat-icon> {{ element.name | uppercase }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="languageCategory">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.language' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
          <ng-container [ngSwitch]="element.language">
            <ng-container *ngSwitchCase="'es'">
              {{ 'field.waiverManualSpanish' | translate | uppercase}}
            </ng-container>
            <ng-container *ngSwitchCase="'en'">
              {{ 'field.waiverManualEnglish' | translate  | uppercase}}
            </ng-container>
            <ng-container *ngSwitchCase="'pt'">
              {{ 'field.waiverManualPortuguese' | translate  | uppercase}}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ element.language }}
            </ng-container>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="waiverCategoryNew">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.waiverCategory' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.waiverCategory | uppercase | dashWhenEmpty }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="waiverTypeNew">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.waiverType' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.waiverType | uppercase | dashWhenEmpty }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="no-data-found" id="waiver-manual-management-no-data-found">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell class="no-data-found-row" *matCellDef="let item">{{ 'bda.dataNotFound' | translate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions" id="waiver-manual-management-actions">
        <mat-header-cell *matHeaderCellDef>{{ 'button.actions' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-button type="button" [disabled]="element.inProgress"   matTooltip="{{ 'bda.tooltip.downloadFile' | translate }}" (click)="downloadFile(element)"
            class="btn button-icon" >
            <mat-icon *ngIf="!element.inProgress" class="icon-green">download</mat-icon>
            <span *ngIf="element.inProgress" class="spinner-border spinner-border-sm"></span>
          </button>
        </mat-cell>
      </ng-container>



      <mat-header-row *matHeaderRowDef="manualDisplayedColumns" class="latam-user-row" id="waiver-manual-management-displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: manualDisplayedColumns; let i = index;"
      [ngClass]="{ 'highlight': selectedRowIndex === i }" (mouseover)="highlightRow($event, i)"
      (mouseout)="highlightRow($event, i)" class="latam-user-row" id="waiver-manual-management-latam-user-row"></mat-row>
      <mat-row *matRowDef="let row; columns: ['no-data-found']; when: dataNotfound" id="waiver-manual-management-no-data-found-row"></mat-row>
    </mat-table>

    <div class="row">
      <div  class="col-10" *ngIf="!noFilesFoundBucket">
        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 30, 50]" [showFirstLastButtons]="false">
        </mat-paginator>
      </div>
    </div>
  </div>

</div>



</div>

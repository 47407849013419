import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { WaiverLatamPostsaleInterface } from '../interfaces/waiver-latam-postsale.interface';
import { WaiverPage } from '../models/waiver-page.model';
import { RouteLocaleExtractorService } from './route-locale-extractor.service';
import { SessionInfoService } from './session-info.service';
import { StorageService } from './storage.service';

@Injectable()
export class WaiverPostsaleOutsourcingService {
   readonly urlBase: string = StorageService.getDnsLatamtrade() + environment.endpoints.urlWaiver;
   readonly waiversOutsourcing: string = '/waivers/outsourcing/market';


   constructor(private http: HttpClient,
      private routeLocaleExtractorService: RouteLocaleExtractorService,
      private translateService: TranslateService,
      private sessionInfoService: SessionInfoService) { }


   getPendingWaiverListOutsourcing(page: number, size: number, waiverListFilter: WaiverPage): Observable<any>{
      const locale = this.routeLocaleExtractorService.getLocale();
      const url = `${this.urlBase}${locale}${this.waiversOutsourcing}/pending`;
      const marketId: number | number[] = this.sessionInfoService.getSessionInfo().marketId;

      const params = new HttpParams()
         .set('markets', marketId.toString())
         .set('page', page.toString())
         .set('size', size.toString());

      waiverListFilter = this.formatWaiverSearch(waiverListFilter);
      return this.http.post<WaiverLatamPostsaleInterface>(url, waiverListFilter, {params});
   }

   getRejectedWaiverListOutsourcing(page: number, size: number, waiverListFilter: WaiverPage): Observable<any>{
      const locale = this.routeLocaleExtractorService.getLocale();
      const url = `${this.urlBase}${locale}${this.waiversOutsourcing}/rejected`;
      const marketId: number | number[] = this.sessionInfoService.getSessionInfo().marketId;

      const params = new HttpParams()
         .set('markets', marketId.toString())
         .set('page', page.toString())
         .set('size', size.toString());

      waiverListFilter = this.formatWaiverSearch(waiverListFilter);
      return this.http.post<WaiverLatamPostsaleInterface>(url, waiverListFilter, {params});
   }

   getApprovedWaiverListOutsourcing(page: number, size: number, waiverListFilter: WaiverPage): Observable<any>{
      const locale = this.routeLocaleExtractorService.getLocale();
      const url = `${this.urlBase}${locale}${this.waiversOutsourcing}/approved`;
      const marketId: number | number[] = this.sessionInfoService.getSessionInfo().marketId;

      const params = new HttpParams()
      .set('markets', marketId.toString())
      .set('page', page.toString())
      .set('size', size.toString());

      waiverListFilter = this.formatWaiverSearch(waiverListFilter);
      return this.http.post<WaiverLatamPostsaleInterface>(url, waiverListFilter, {params});
   }

   countPendingWaiversOutsourcing(){
      const locale = this.routeLocaleExtractorService.getLocale();
      return null;
   }

   formatWaiverSearch(waiverPage: WaiverPage){

      if (waiverPage.id == null){
         waiverPage.id = 0;
      }
      if (waiverPage.flightDate == null || waiverPage.flightDate === ''){
         waiverPage.flightDate = 0;
      } else{
         waiverPage.flightDate = new Date(waiverPage.flightDate).getTime();
      }
      if (waiverPage.requestDate == null || waiverPage.requestDate === ''){
         waiverPage.requestDate = 0;
      } else{
         waiverPage.requestDate = new Date(waiverPage.requestDate).getTime()
      }

      return waiverPage;
   }
}

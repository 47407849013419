import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource, MatTableDataSourcePaginator } from '@angular/material/table';
import { Router } from '@angular/router';
import { Waiver } from '../../../models/waiver.model';
import { SessionInfoService } from '../../../services/session-info.service';
import { SessionInfo } from '../../../models/SessionInfo.model';
@Component({
  selector: 'app-waiver-result',
  templateUrl: './waiver-result.component.html',
  styleUrls: ['./waiver-result.component.css']
})
export class WaiverResultComponent implements OnInit {

  @Input() dataWaiverResult: MatTableDataSource<Waiver, MatTableDataSourcePaginator>;
  @Output() newRequestOutput = new EventEmitter<any>();
  sessionInfo: SessionInfo;

  displayedColumnsWaiverResult = ['id', 'waiverPos', 'agencyName', 'iata', 'userName', 'waiverType', 'documentNumber', 'statusDate', 'flightDate'];

  constructor(private router: Router,private sessionInfoService: SessionInfoService){}

  ngOnInit(): void {
    this.sessionInfo = this.sessionInfoService.getSessionInfo();
  }

  newRequest(){
    this.newRequestOutput.emit();
  }
}

export class WaiverToken {
  waiverToken: string;
  additionalComment: string;
  pnrAgency: string;

  public constructor(waiverToken: string, additionalComment?: string, pnrAgency?: string){
    this.waiverToken = waiverToken;
    this.additionalComment = additionalComment;
    this.pnrAgency = pnrAgency;
  }

}

export class AgencyCSV {
  public id: number;
  public iata: string;
  public name: string;
  public marketId: number;
  public marketName: string;
  public agencyCode: number;
  public agencyBranch: number;
  public officeId: string;
  public pcc: string;
  public agencyGroupid: number;
  public agencyGroupName: string;
  public city: string;
  public country: string;
  public executive: string;
  public active: boolean;
  public piaAgencyCode: string;
  public piaAgencyType: string;

  constructor(
    $id: number,
    $iata: string,
    $name: string,
    $agencyCode: number,
    $agencyBranch: number,
    $officeId: string,
    $pcc: string,
    $agencyGroupid: number,
    $agencyGroupName: string,
    $marketId: number,
    $marketName: string,
    $city: string,
    $country: string,
    $executive: string,
    $active: boolean,
    $piaAgencyCode: string,
    $piaAgencyType: string) {
      this.id = $id;
      this.iata = $iata;
      this.name = $name;
      this.agencyCode = $agencyCode;
      this.agencyBranch = $agencyBranch;
      this.officeId = $officeId;
      this.pcc = $pcc;
      this.agencyGroupid = $agencyGroupid;
      this.agencyGroupName = $agencyGroupName;
      this.marketId = $marketId;
      this.marketName = $marketName;
      this.city = $city;
      this.country = $country;
      this.executive = $executive;
      this.active = $active;
      this.piaAgencyCode = $piaAgencyCode;
      this.piaAgencyType = $piaAgencyType;
  }
}

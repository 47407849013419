import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { LoginAgencyService } from './../../../services/login-agency.service';
import { SessionInfoService } from '../../../services/session-info.service';
import { TermsConditionsService } from '../../../services/terms-conditions.service';
import { SessionInfo } from '../../../models/SessionInfo.model';


@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TermsConditionsComponent {

  scrollHeight = 1;
  scrollTop = 0;
  clientHeight = 0;
  pdfSrc: string = '';
  pdfLoading: boolean = true;
  infoUser$: Promise<SessionInfo> = this.sessionInfoService.getSessionInfoAsPromise();
  sessionInfo: SessionInfo;

  constructor(
    public dialogRef: MatDialogRef<TermsConditionsComponent>,
    private loginAgencyService: LoginAgencyService,
    private termConditionService: TermsConditionsService,
    private sessionInfoService: SessionInfoService) { }

  onScroll() {
    this.scrollHeight = Math.trunc(document.getElementById('modalContent').scrollHeight);
    this.scrollTop = Math.trunc(document.getElementById('modalContent').scrollTop);
    this.clientHeight = Math.trunc(document.getElementById('modalContent').clientHeight);
  }

  agreeTermsConditions() {
    this.termConditionService.setAgreeTermsConditions().subscribe({
      next: data => {
        localStorage.removeItem('sessionToken');
        this.sessionInfoService.createSessionInfoToken();
        this.dialogRef.close();
      },
      error: (error: HttpErrorResponse) => {
        console.log('error', error);
      }});
  }

  pdfLoaded = () => {
    this.pdfLoading = false;
  }

  logout() {
    this.infoUser$
      .then(
        sessionInfo => {
            this.loginAgencyService.logout(sessionInfo.isLatamExternalSession());
            this.dialogRef.close();
          }

      )
      .catch(
        error => {
          console.log('error', error);
        }
      );
  }

}

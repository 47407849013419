
<div class="dialog-content-farebasis">
  <h2 mat-dialog-title>{{data.tipo}}</h2>
  <div class="dialog-info-container">
    <mat-form-field appearance="fill">
      <mat-label>Clases</mat-label>
      <input type="text" matInput [value]="data.col1" readonly />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Season</mat-label>
      <input type="text" matInput [value]="data.col2" readonly />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Day Application</mat-label>
      <input type="text" matInput [value]="data.col3" readonly />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Family</mat-label>
      <input type="text" matInput [value]="data.col4" readonly />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Minimum Stay</mat-label>
      <input type="text" matInput [value]="data.col6" readonly />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>ADVP</mat-label>
      <input type="text" matInput [value]="data.col7" readonly />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Final</mat-label>
      <input type="text" matInput [value]="data.col8" readonly />
    </mat-form-field> 
  </div>
  <div class="close-button-container">
    <button mat-raised-button mat-dialog-close class="btn-volver btn-w">Cerrar</button>
  </div>
</div>
export class WaiverUserManualFile {
  public name: string;
  public language: string;
  public waiverType: string;
  public waiverCategory: string;
  public updated: number;
  public locationStorage: string;
  public size: number;
  public inProgress: boolean;

  constructor(
      name?: string,
      language?: string,
      waiverType?: string,
      waiverCategory?: string,
      updated?: number,
      locationStorage?: string,
      size?: number,
      inProgress?: boolean
  ) {
      this.name = name;
      this.language = language;
      this.waiverType = waiverType;
      this.waiverCategory = waiverCategory;
      this.updated = updated;
      this.locationStorage = locationStorage;
      this.size = size;
      this.inProgress = inProgress;
  }
}

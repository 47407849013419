import { TypeMotiveFilter } from "./type-motive.model";

export class WaiverPage {
  public id: number;
  public pos: string;
  public agency: string;
  public iata: string;
  public requestDate: string | number;
  public salesEjecutive: string;
  public type: string;
  public motive: string;
  public pnrTkt: string;
  public flightDate: string | number;
  public typeMotiveFilterList: TypeMotiveFilter[];
  constructor(
    id?: number,
    pos?: string,
    agency?: string,
    iata?: string,
    requestDate?: string,
    salesEjecutive?: string,
    type?: string,
    motive?: string,
    pnrTkt?: string,
    flightDate?: string,
    typeMotiveFilterList?: TypeMotiveFilter[]) {
    this.id = id;
    this.pos = pos;
    this.agency = agency;
    this.iata = iata;
    this.requestDate = requestDate;
    this.salesEjecutive = salesEjecutive;
    this.type = type;
    this.motive = motive;
    this.pnrTkt = pnrTkt;
    this.flightDate = flightDate;
    this.typeMotiveFilterList = typeMotiveFilterList;
  }
}

<ng-container *ngIf="infoUser$ | async as infoUser">
  <div class="mb-3">
    <div *ngIf="infoUser.isLatamSession()" class="row mr-3 ml-3">
      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <mat-form-field>
          <mat-label>{{ ('field.budgetType' | translate) + ' - ' + (actualDate  | monthName) + ' ' + year }}</mat-label>
          <mat-select id="budgetType"
            (selectionChange)="selectionBudgetTypeChange($event)" [compareWith]="compareBudgetType"
            [(ngModel)]="budgetTypeSelected">
            <mat-option *ngFor="let budgetType of budgetTypes" [value]="budgetType">
              {{ budgetType.name | translateBudgetTypeName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="loading" class="loader loading">
      <div class="loading-div">
        <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
        <span class="loading-span">{{ 'loading' | translate }}</span>
      </div>
    </div>

    <ng-container *ngIf="!loading && budgetTypeSelected">
      <mat-table #table [dataSource]="dataSource" class="table-branch-office">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>{{ 'field.branchOffices' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element" class="header">{{ element.name }}
            <div *ngIf="!infoUser.isLatamSession()" class="view-button">
              <button class="action" id="verButton">
                <span (click)="profileBranchOffice(element.id)">{{ 'button.view' | translate }}</span>
              </button>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="assignedValue" *ngIf="infoUser.isLatamSession()">
          <mat-header-cell *matHeaderCellDef class="cell">{{ 'field.assigned' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="cell cell-green"><span>{{ element.assignedValue }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="availableValue" *ngIf="infoUser.isLatamSession()">
          <mat-header-cell *matHeaderCellDef class="cell">{{ 'field.available' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="cell cell-green"><span>{{ element.availableValue }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="spentValue" *ngIf="infoUser.isLatamSession()">
          <mat-header-cell *matHeaderCellDef class="cell">{{ 'field.spent' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="cell cell-gray"><span>{{ element.spentValue}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="view" *ngIf="infoUser.isLatamSession()">
          <mat-header-cell *matHeaderCellDef>{{ 'button.view' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div>
              <button class="action" id="verButton">
                <span (click)="profileBranchOffice(element.id)">{{ 'button.view' | translate }}</span>
              </button>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column -->
        <ng-container matColumnDef="expandedDetail">
          <mat-cell *matCellDef="let detail" class="no-border">
            <div class="table-responsive-sm">
              <table class="table table-hover table-bordered detail-table">
                <thead>
                  <tr>
                    <th>{{ 'field.agency' | translate }}</th>
                    <th>{{ 'field.iata' | translate }}</th>
                    <th>{{ 'field.tier' | translate }}</th>
                    <th>{{ 'field.city' | translate }}</th>
                    <th>{{ 'field.accountManager' | translate }}</th>
                    <th>{{ 'button.actions' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let data of detail.element.agencySimplifiedDTO">
                    <tr>
                      <td>{{ data.name }}</td>
                      <td>{{ data.iata }}</td>
                      <td>{{ data.agencyTierName }}</td>
                      <td>{{ data.city }}</td>
                      <td>{{ data.executive }}</td>
                      <td>
                        <button class="action" id="verButton">
                          <span (click)="profileAgency(data.id)">{{ 'button.view' | translate }}</span>
                        </button>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="detail.element.agencySimplifiedDTO?.length === 0">
                    <tr>
                      <td colspan="6">{{ 'error.notAgencyFound' | translate }}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="no-data-found">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell class="no-data-found-row" *matCellDef="let item">{{ 'notFound.noBranchOffices' | translate }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="loading-data-error">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell class="loading-data-error-row" *matCellDef="let item">
            <ng-container *ngIf="!refreshingBranchOffices">
              <div>
                <span style="display: none" #tooltipMessage>{{ 'button.refresh' | translate }}</span>
                <button mat-icon-button (click)="refreshBranchOffices()">
                  <mat-icon [matTooltip]="tooltipMessage.innerHTML" [matTooltipPosition]="'right'">refresh</mat-icon>
                </button>
              </div>
              <ng-container>{{ 'error.loadingBranchOffice' | translate }}</ng-container>
            </ng-container>
            <div class="mt-3 mb-3" *ngIf="refreshingBranchOffices">
              <span class="loading-span">{{ 'loading' | translate }}</span>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" class="branch-office-row"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row"
          [class.expanded]="expandedElement == row" (click)="expandedElement = expandedElement === row ? null : row">
        </mat-row>

        <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
          [@detailExpand]="row.element == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden">
        </mat-row>

        <mat-row *matRowDef="let row; columns: ['no-data-found']; when: noBranchOfficeDataFound"></mat-row>
        <mat-row *matRowDef="let row; columns: ['loading-data-error']; when: loadingUsersDataError"></mat-row>
      </mat-table>

      <div [ngClass]="{ 'd-none': !branchOfficeDataFound }">
        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 30, 50]" [showFirstLastButtons]="false"
          (page)="pageEvent($event)" class="table-paginator"></mat-paginator>
      </div>
    </ng-container>
  </div>
</ng-container>


import {share} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


import { environment } from '../../environments/environment';

import { Budget } from '../models/budget.model';
import { BudgetResume } from '../models/budget-resume.model';
import { BudgetType } from '../models/budget-type.model';
import { GlobalBudget } from '../models/global-budget.model';
import { MonthlyBudget } from '../models/monthly-budget.model';
import { MonthlyBudgetAgencyGroup } from '../models/monthly-budget-agency-group.model';
import { MonthlyBudgetBranchOffice } from '../models/monthly-budget-branch-office.model';
import { MonthlyBudgetGeneric } from '../models/monthly-budget-generic.model';

import { ConstantsService } from './constants.service';
import { RouteLocaleExtractorService } from './route-locale-extractor.service';
import { SessionInfoService } from './session-info.service';
import { StorageService } from './storage.service';


@Injectable()
export class BudgetService {
  headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  reloadBudget = new Subject<void>();
  // urlBase = `http://localhost:9090/http://localhost:8070/budget`;
  urlBase = `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlBudget}`;

  constructor(
    private http: HttpClient,
    private constantsService: ConstantsService,
    private routeLocaleExtractorService: RouteLocaleExtractorService,
    private sessionInfoService: SessionInfoService) { }

  /**
   * Obtiene el presupuesto de post venta para un grupo agencia
   * @param agencyGroupId
   */
  getBudgetPostSaleByAgencyGroup(agencyGroupId: number): Observable<Budget[]> {
    return this.http.get<Budget[]>(`${this.urlBase}/budgets/${agencyGroupId}/group`);
  }

  /**
   * Obtiene el presupuesto global por mercado y tipo de presupuesto
   * @param market
   * @param budgetTypeId
   */
  getMarketGlobalBudgetForCurrentYear(market: string, budgetTypeId: number): Observable<GlobalBudget[]> {
    //const urlBase = 'http://localhost:9090/http://localhost:8070/budget'
    return this.http.get<GlobalBudget[]>(`${this.urlBase}/budgets/${market}?budgetTypeId=${budgetTypeId}`);
  }

  /**
   * Garda el presupuesto de post sale para un market
   * @param monthlyBudget
   */
  saveBudgetPostSale(monthlyBudget: MonthlyBudget): Observable<MonthlyBudget> {
    return this.http.post<MonthlyBudget>(`${this.urlBase}/budgets`, monthlyBudget, { headers: this.headers }).pipe(share());
  }


  saveBudgetsPostSale(budgetTypeId: number, encodeBudgets: string): Observable<any> {
    const url = `${this.urlBase}/bulk-load?budgetTypeId=${budgetTypeId}`;
    return this.http.post(url, encodeBudgets, { headers: this.headers  }).pipe(share());
  }

  listBudgetBulkLoad(page: number,size: number): Observable<any> {
    const url = `${this.urlBase}/bulk-load/list?page=${page}&size=${size}`;
    return this.http.get(url, { headers: this.headers  });
  }

  /**
   * Obtiene el presupuesto de preventa para un grupo agencia
   * @param agencyGroupId
   */
  getBudgetPresaleByAgencyGroup(agencyGroupId: number): Observable<Budget[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<Budget[]>(`${this.urlBase}${locale}/presale-budget/group/${agencyGroupId}`);
  }

  /**
   * Obtiene el presupuesto de preventa para una sucursal
   * @param agencyGroupId
   */
  getBudgetPresaleByBranchOffice(branchOfficeId: number, isShowBudgetPopUp: boolean): Observable<MonthlyBudgetBranchOffice> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<MonthlyBudgetBranchOffice>(`${this.urlBase}${locale}/presale-budget/branchOffice?branchOfficeId=${branchOfficeId}&isShowBudgetPopUp=${isShowBudgetPopUp}`);
  }

  /**
   * Obtiene el saldo del presupuesto preventa del mes en curso
   * @param agencyGroupId
   */
  getMonthlyPresaleBudgetsByGroup(agencyGroupId: number): Observable<MonthlyBudgetAgencyGroup> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<MonthlyBudgetAgencyGroup>(`${this.urlBase}${locale}/presale-budget/agencyGroup?agencyGroupId=${agencyGroupId}`);
  }

  /**
   * Garda el presupuesto de presale para un market
   * @param monthlyBudget
   */
  saveBudgetPresale(monthlyBudget: MonthlyBudget): Observable<MonthlyBudget> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.post<MonthlyBudget>(`${this.urlBase}${locale}/presale-budget`, monthlyBudget, { headers: this.headers }).pipe(share());
  }


  /**
   * Obtiene el presupuesto de post venta para una sucursal
   * @param agencyGroupId
   */
  getBudgetByBranch(branchId: number, budgetTypeId: number): Observable<Budget[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<Budget[]>(`${this.urlBase}${locale}/branch-office-budget/${branchId}?budgetTypeId=${budgetTypeId}`);
  }

  getBudgetResume(budgetTypeId: number, agencyGroupBudgetId: number): Observable<[BudgetResume]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<[BudgetResume]>(`${this.urlBase}${locale}/branch-office-budget/current-amount/${budgetTypeId}?agencyGroupBudgetId=${agencyGroupBudgetId}`);
  }

  /**
   * Garda el presupuesto para una sucursal
   * @param monthlyBudget
   */
  saveBudgetBranch(monthlyBudget: MonthlyBudget, budgetTypeId: number): Observable<null> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.post<null>(
      `${this.urlBase}${locale}/branch-office-budget?budgetTypeId=${budgetTypeId}`,
      monthlyBudget,
      { headers: this.headers }
    ).pipe(share());
  }


  getAgencyGroupBudget(marketId: number, budgetTypeId: number): Observable<MonthlyBudgetGeneric[]> {
    const locale = this.routeLocaleExtractorService.getLocale();
    const sessionInfo = this.sessionInfoService.getSessionInfo();
    let role = sessionInfo.role;
    let userId = sessionInfo.userId;
    let url = '';

    if (role === 'ADMIN_SALES') {
      url = `${this.urlBase}${locale}/budget/sales/agencyGroups?budgetTypeId=${budgetTypeId}&adminSalesId=${userId}&marketId=${marketId}`;
    } else if (role === 'USER_SALES') {
      url = `${this.urlBase}${locale}/budget/sales/agencyGroups?budgetTypeId=${budgetTypeId}&userSalesId=${userId}&marketId=${marketId}`;
    }

    return this.http.get<MonthlyBudgetGeneric[]>(url);
  }

  getBranchOfficeBudget(budgetTypeId: number, agencyGroupId: number): Observable<MonthlyBudgetGeneric[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<MonthlyBudgetGeneric[]>(`${this.urlBase}${locale}/budget/sales/branchOffice?budgetTypeId=${budgetTypeId}&groupId=${agencyGroupId}`);
  }

  /**
   * Obtiene el saldo del presupuesto post-venta del mes en curso
   * @param agencyGroupId
   */
  getMonthlyAfterSalesBudgetByGroup(agencyGroupId: number): Observable<MonthlyBudgetAgencyGroup> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<MonthlyBudgetAgencyGroup>(`${this.urlBase}${locale}/budget/agencyGroup?agencyGroupId=${agencyGroupId}`);
  }

  saveAgencyGroupBudget(marketId: number, budgetTypeId: number, budget: MonthlyBudgetGeneric[]): Observable<any> {
    const locale = this.routeLocaleExtractorService.getLocale();
    const sessionInfo = this.sessionInfoService.getSessionInfo();
    let role = sessionInfo.role;
    let userId = sessionInfo.userId;
    let url = '';

    if (role === 'ADMIN_SALES') {
      url = `${this.urlBase}${locale}/budget/sales/agencyGroups?budgetTypeId=${budgetTypeId}&adminSalesId=${userId}&marketId=${marketId}`;
    } else if (role === 'USER_SALES') {
      url = `${this.urlBase}${locale}/budget/sales/agencyGroups?budgetTypeId=${budgetTypeId}&userSalesId=${userId}&marketId=${marketId}`;
    }

    return this.http.post<MonthlyBudgetGeneric[]>(url, budget, { headers: this.headers }).pipe(share());
  }

  saveBranchOfficeBudget(budgetTypeId: number, agencyGroupId: number, budget: MonthlyBudgetGeneric[]): Observable<any> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.post<MonthlyBudgetGeneric[]>(`${this.urlBase}${locale}/budget/sales/branchOffice?budgetTypeId=${budgetTypeId}&groupId=${agencyGroupId}`, budget, { headers: this.headers }).pipe(share());
  }


  /**
   * Obtiene los tipos de presupuesto
   */
  getBudgetTypes(): BudgetType[] {
    return [
      this.constantsService.postsaleEs,
      this.constantsService.presaleEs,
    ];
  }

  get reload(): Observable<void> {
    return this.reloadBudget.asObservable();
  }

  triggerReload() {
    this.reloadBudget.next();
  }

}

<div class="container">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="latam-sans breadcrumb-item"><a>{{ 'menu.waivers' | translate }}</a></li>
          <li class="breadcrumb-item latam-sans active" aria-current="page">
            <a>{{ 'waiverPresaleRequest' | translate }}</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-7 col-md-8 col-lg-9 col-xl-9">
      <span class="title">{{ 'waiverPresaleRequest' | translate }}</span>
    </div>
    <div>
      <app-waiver-terms-conditions [waiverCategory]="'pre'"></app-waiver-terms-conditions>
    </div>
  </div>

  <hr>

  <mat-horizontal-stepper #stepper [linear]="true" (selectionChange)="onStepChange($event)">
    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>

    <mat-step [completed]="isValuated">
      <ng-template matStepLabel>
        <span>{{ 'selectWaiver' | translate }}</span>
      </ng-template>

      <hr>

      <div class="row mt-4 align-items-end">
        <div class="col-sm-6 col-md-8 col-lg-9 col-xl-9">
          <span class="subtitle">{{ 'selectWaiver' | translate }}</span>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
          <app-budget-popup [groupId]="groupId" [branchOfficeId]="branchOfficeId" [reload]="reload"></app-budget-popup>
        </div>
      </div>

      <div class="row mt-4" *ngIf="profiles.createPreSaleRequest">
        <div class="col">
          <form [formGroup]="formG">
            <div class="row">
              <!-- Tipo de waiver -->
              <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-form-field>
                  <mat-label>{{ 'field.waiverType' | translate }}</mat-label>
                  <mat-select formControlName="waiverType" required
                    (selectionChange)="waiverTypeChange()">
                    <mat-option *ngFor="let waiverType of waiverTypes" [value]="waiverType">
                      <div id="{{ waiverType.code }}">{{ waiverType.name }}</div>
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="!formG.get('waiverType').valid">{{ 'field.error.required' | translate }}</mat-error>
                </mat-form-field>
              </div>

              <!-- check privado -->
              <ng-container *ngIf="allowPrivateFareWaiver()">
                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 check-col text-center">
                  <mat-checkbox id="check-private" formControlName="checkPrivate" (change)="checkPrivateEvent()">
                    {{ 'field.private' | translate }}</mat-checkbox>
                </div>
              </ng-container>

              <!-- Pricing Type -->
              <ng-container *ngIf="formG.get('checkPrivate').value && allowPrivateFareWaiver()">
                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-form-field>
                    <mat-label>{{ 'field.rateType' | translate }}</mat-label>
                    <mat-select formControlName="pricingType" required
                      (selectionChange)="pricingTypeChange()" [compareWith]="comparePricingType"
                      [(ngModel)]="pricingTypeSelected">
                      <mat-option *ngFor="let pricingType of pricingTypes" [value]="pricingType">
                        <div id="{{ pricingType.code }}">{{ pricingType.name }}</div>
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="!formG.get('pricingType').valid">{{ 'field.error.required' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </ng-container>

              <!-- Pricing Type Code -->
              <ng-container
                *ngIf="formG.get('checkPrivate').value && formG.get('pricingType').value && formG.get('pricingType').value.code !== 'JCB'">
                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-form-field>
                    <mat-label>{{ formG.get('pricingType').value.name }}</mat-label>
                    <input matInput id="account-code" formControlName="pricingTypeCode" (blur)="pricingTypeCodeChange()"
                      maxlength="15" required class="upper-input">
                    <mat-error *ngIf="!formG.get('pricingTypeCode').valid">{{ 'field.error.required' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </ng-container>

              <ng-container *ngIf="allowPrivateFareWaiver()">
                <div class="w-100"></div>
              </ng-container>

              <!-- PNR -->
              <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-form-field>
                  <mat-label>{{ 'field.pnr' | translate }}</mat-label>
                  <input matInput #pnrInput formControlName="PNR"
                    maxlength="6" minlength="6" required (blur)="pnrChange()" (click)="sendMessage()"
                    matTooltip="{{ 'tooltip.pnrLatam' | translate }}" class="upper-input">
                  <mat-error *ngIf="!formG.get('PNR').valid">{{ 'field.error.required' | translate }}</mat-error>
                </mat-form-field>
              </div>
              <ng-container *ngIf="this.isSelectAdvp">
                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-form-field>
                    <mat-label>{{ 'field.farebasis' | translate }}</mat-label>
                    <input matInput #pnrFarebasis formControlName="FAREBASIS" required class="upper-input">
                    <mat-error *ngIf="!formG.get('FAREBASIS').valid">{{ 'field.error.required' | translate
                      }}</mat-error>
                  </mat-form-field>
                </div>
              </ng-container>

              <ng-container *ngIf="allowPrivateFareWaiver()">
                <div class="w-100 mt-2"></div>
              </ng-container>

              <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="profiles.createPreSaleRequest">
                <button mat-raised-button [disabled]="!formG.valid || isLoading || isValuating" (click)="validatePnr()"
                  class="background-blue w-100">{{ 'button.search' | translate }}</button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="alert alert-warning alert-dismissible fade show mt-3" role="alert">
                  {{ 'info.brandValidate' | translate }}
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </div>

            <ng-container *ngIf="isValid">
              <div class="row mt-2">
                <div class="col">
                  <ul *ngIf="labelSet" class="label-display">
                    <li *ngFor="let rule of labelRule">
                      <span>{{ rule.type + ' ' + ('permitted' | translate) + ' ' + rule.value }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-container>
          </form>
        </div>
      </div>

      <!-- Spinner de carga de datos -->
      <ng-container *ngIf="isLoading">
        <div class="loading">
          <div class="loading-div">
            <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
            </svg>
            <span class="loading-span">{{ 'loading' | translate }}</span>
          </div>
        </div>
      </ng-container>

      <!--ng-container
        *ngIf="validPrePNR || (validPostPNR && passengerTicketRequestsCompleted == dataPassengers.data.length && passengerTicketRequestsWithError == 0)">
        <hr>
      </ng-container-->

      <!-- informacion de reserva -->
      <ng-container id="pnrInfoDiv"
        *ngIf="(validPrePNR || (validPostPNR && passengerTicketRequestsCompleted == dataPassengers.data.length && passengerTicketRequestsWithError == 0)) && !isLoadingContainer()">
        <div class="row mt-4">
          <div class="col">
            <span class="subtitle">{{ 'bookingInformation' | translate }}</span>
          </div>
        </div>

        <div class="mat-elevation-z1 table-post mt-3">
          <mat-table #table [dataSource]="dataReservation">
            <ng-container matColumnDef="pnrNumber">
              <mat-header-cell *matHeaderCellDef>{{ 'field.pnr' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.pnr.pnrNumber | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="pnrType">
              <mat-header-cell *matHeaderCellDef>{{ 'field.pnrType' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.pnr.type | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="bookingCreation">
              <mat-header-cell *matHeaderCellDef>{{ 'field.bookingCreation' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">
                {{ data.pnr.createDateTime | dateFormat:'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="NumberOfPassengers">
              <mat-header-cell *matHeaderCellDef>{{ 'field.numberPassengers' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ dataPassengers?.data?.length }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="pos">
              <mat-header-cell *matHeaderCellDef>{{ 'field.pos' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.booking.pos | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="iata">
              <mat-header-cell *matHeaderCellDef>{{ 'field.iata' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.booking.iata | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="pcc">
              <mat-header-cell *matHeaderCellDef>{{ 'field.pcc' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.booking.ppc | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="officeId">
              <mat-header-cell *matHeaderCellDef>{{ 'field.officeId' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.booking.officeId | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsR" class="waiver-post-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsR; let i = index"
              [ngClass]="{ 'highlight': selectedReservationInformationRowIndex === i }"
              (mouseout)="highlightReservationInformationRow($event, i)"
              (mouseover)="highlightReservationInformationRow($event, i)" class="waiver-post-row"></mat-row>
          </mat-table>
        </div>
      </ng-container>

      <!-- informacion de pasajeros pre -->
      <ng-container id="preSalePNRPassengersDiv" *ngIf="validPrePNR && !isLoadingContainer()">
        <div class="row mt-4">
          <div class="col">
            <span class="subtitle">{{ 'passengerInfo' | translate }}</span>
          </div>
        </div>

        <div class="mat-elevation-z1 table-post mt-3">
          <mat-table id="passengersPrePNRTable" #table [dataSource]="dataPassengers">
            <ng-container matColumnDef="passengerName">
              <mat-header-cell *matHeaderCellDef>{{ 'field.passengerName' | translate }}
              </mat-header-cell>
              <mat-cell [ngClass]="{ 'mat-cell-invalid': data.infantTkt }" *matCellDef="let data">
                {{ (data.firstName | partialDisplay: '#' : 1 | dashWhenEmpty) + ' ' + (data.lastName | partialDisplay:
                '#' : 1 | dashWhenEmpty) }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="foid">
              <mat-header-cell *matHeaderCellDef>{{ 'field.foid' | translate }}</mat-header-cell>
              <mat-cell [ngClass]="{ 'mat-cell-invalid': data.infantTkt }" *matCellDef="let data">
                {{ data.foid | partialDisplay:'#' :-2 | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="passengerType">
              <mat-header-cell *matHeaderCellDef>{{ 'field.passengerType' | translate }}
              </mat-header-cell>
              <mat-cell [ngClass]="{ 'mat-cell-invalid': data.infantTkt }" *matCellDef="let data">
                {{ data.type | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef class="pt-2 ">
                <mat-checkbox color="accent" [checked]="selectionPassengers.hasValue() && isAllSelectedPassengers()"
                  [disabled]="allPassengersInvalid"
                  [indeterminate]="selectionPassengers.hasValue() && !isAllSelectedPassengers()"
                  (change)="$event ? masterTogglePassengers() : null">
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="pt-2">
                <mat-checkbox color="accent" [checked]="selectionPassengers.isSelected(row)" [disabled]="row.infantTkt"
                  (change)="$event ? toggleSelectionPassengerPre(row) : null" (click)="$event.stopPropagation()">
                </mat-checkbox>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsP" class="waiver-post-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsP; let i = index"
              [ngClass]="{ 'highlight': selectedPassengerInformationRowIndex === i || selectionPassengers.isSelected(row) }"
              (click)="toggleSelectionPassengerPre(row)" (mouseout)="highlightPassengerInformationRow($event, i)"
              (mouseover)="highlightPassengerInformationRow($event, i)" class="waiver-post-row">
            </mat-row>
          </mat-table>
        </div>
      </ng-container>

      <!-- informacion de pasajeros pos -->
      <ng-container id="postSalePNRPassengersDiv"
        *ngIf="(validPostPNR && passengerTicketRequestsCompleted == dataPassengers.data.length && passengerTicketRequestsWithError == 0) && !isLoadingContainer()">
        <div class="row mt-4">
          <div class="col">
            <span class="subtitle">{{ 'passengerInfo' | translate }}</span>
          </div>
        </div>

        <div class="mat-elevation-z1 table-post mt-3">
          <mat-table id="passengersPostPNRTable" #table [dataSource]="dataPassengers">
            <ng-container matColumnDef="passengerName">
              <mat-header-cell *matHeaderCellDef>{{ 'field.passengerName' | translate }}</mat-header-cell>
              <mat-cell [ngClass]="{ 'mat-cell-invalid': data.invalidTkt||data.allCouponsInvalid || data.infantTkt }"
                *matCellDef="let data">
                {{ (data.firstName | partialDisplay: '#' : 1 | dashWhenEmpty) + ' ' + (data.lastName | partialDisplay:
                '#' : 1 | dashWhenEmpty) }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="foid">
              <mat-header-cell *matHeaderCellDef>{{ 'field.foid' | translate }}</mat-header-cell>
              <mat-cell [ngClass]="{ 'mat-cell-invalid': data.invalidTkt||data.allCouponsInvalid || data.infantTkt }"
                *matCellDef="let data">{{ data.foid | partialDisplay:'#' :-2 | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="passengerType">
              <mat-header-cell *matHeaderCellDef>{{ 'field.passengerType' | translate }}
              </mat-header-cell>
              <mat-cell [ngClass]="{ 'mat-cell-invalid': data.invalidTkt||data.allCouponsInvalid || data.infantTkt }"
                *matCellDef="let data">{{ data.type | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="tkt">
              <mat-header-cell *matHeaderCellDef>{{ 'field.tkt' | translate }}</mat-header-cell>
              <mat-cell [ngClass]="{ 'mat-cell-invalid': data.invalidTkt||data.allCouponsInvalid || data.infantTkt }"
                *matCellDef="let data">{{ data.ticketNumber | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef class="pt-2">
                <mat-checkbox color="accent" [checked]="selectionPassengers.hasValue() && isAllSelectedPassengers()"
                  [disabled]="allPassengersInvalid"
                  [indeterminate]="selectionPassengers.hasValue() && !isAllSelectedPassengers()"
                  (change)="$event ? masterTogglePassengers() : null">
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="pt-2">
                <div id="tooltipPassenger" *ngIf="!(row.infantTkt)" matTooltip="{{ 'tooltip.notHaveTkt' | translate }}"
                  matTooltipPosition="before"
                  [matTooltipDisabled]="!(row.invalidTkt || row.allCouponsInvalid || row.infantTkt)">
                  <mat-checkbox (click)="$event.stopPropagation()" color="accent"
                    (change)="$event ? toggleSelectionPassengerPost(row) : null"
                    [checked]="selectionPassengers.isSelected(row)"
                    [disabled]="row.invalidTkt || row.allCouponsInvalid || row.infantTkt">
                  </mat-checkbox>
                </div>

                <div id="tooltipPassengerINF" *ngIf="row.infantTkt"
                  matTooltip="{{ 'tooltip.adultPassengerRequired' | translate }}" matTooltipPosition="before"
                  [matTooltipDisabled]="!(row.infantTkt)">
                  <mat-checkbox color="accent" [checked]="selectionPassengers.isSelected(row)"
                    [disabled]="row.invalidTkt || row.allCouponsInvalid || row.infantTkt"
                    (change)="$event ? toggleSelectionPassengerPost(row) : null" (click)="$event.stopPropagation()">
                  </mat-checkbox>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsPPOS" class="waiver-post-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsPPOS; let i = index"
              [ngClass]="{ 'highlight': selectedPassengerInformationRowIndex === i || selectionPassengers.isSelected(row) }"
              (click)="toggleSelectionPassengerPost(row)" (mouseout)="highlightPassengerInformationRow($event, i)"
              (mouseover)="highlightPassengerInformationRow($event, i)" class="waiver-post-row">
            </mat-row>
          </mat-table>
        </div>
      </ng-container>

      <div id="pnrSegmentsDiv"
        *ngIf="(validPrePNR || (validPostPNR && passengerTicketRequestsCompleted == dataPassengers.data.length && passengerTicketRequestsWithError == 0)) && !isLoadingContainer()">
        <div class="row mt-4">
          <div class="col">
            <span class="subtitle">{{ 'segmentsInformation' | translate }}</span>
          </div>
        </div>
        <div class="mat-elevation-z1 table-post mt-3">
          <mat-table id="CouponsTable" #table [dataSource]="dataCoupons">
            <ng-container matColumnDef="segmentNumber">
              <mat-header-cell *matHeaderCellDef>{{ 'field.segmentNumber' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                *matCellDef="let data">{{ data.couponNumber | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="carrierMkt">
              <mat-header-cell *matHeaderCellDef>{{ 'field.mktCarrier' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0}"
                *matCellDef="let data">{{ data.carrierMarketing | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="flightNumberMkt">
              <mat-header-cell *matHeaderCellDef>{{ 'field.mktFlightNumber' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                *matCellDef="let data">{{ data.flightNumber | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="class">
              <mat-header-cell *matHeaderCellDef>{{ 'field.class' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                *matCellDef="let data">{{ data.class | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="origin">
              <mat-header-cell *matHeaderCellDef>{{ 'field.from' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0}"
                *matCellDef="let data">{{ data.source | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="destination">
              <mat-header-cell *matHeaderCellDef>{{ 'field.to' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0}"
                *matCellDef="let data">{{ data.destination | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="dateHourDeparture">
              <mat-header-cell *matHeaderCellDef>{{ 'field.departureDate' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                *matCellDef="let data">
                {{ data.departureDateTime | dateFormatWithoutLocale: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef>{{ 'field.segmentStatus' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                *matCellDef="let data">{{ data.status | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <!-- Este campo no se debe mostrar, pero permite obtener informacion del farebasis NO eliminar -->
            <ng-container matColumnDef="fareBasis">
              <mat-header-cell *matHeaderCellDef [hidden]="true">{{ 'field.farebasis' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data" [hidden]="true">{{ data.fareBasis | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef class="pt-2">
                <mat-checkbox color="accent" [checked]="selectionSegments.hasValue() && isAllSelectedSegments()"
                  [disabled]="(segmentsWithOneCouponInvalidQuantity + invalidStatusSegmentsQuantity) === dataSegments.data.length"
                  [indeterminate]="selectionSegments.hasValue() && !isAllSelectedSegments()"
                  (change)="$event ? masterToggleSegments() : null;">
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="pt-2">
                <div id="tooltipSegmentPOST" *ngIf="selectedType.waiverCategory.code === 'POST'"
                  matTooltip="{{ 'tooltip.invalidSegmentCoupon' | translate }}" matTooltipPosition="before"
                  [matTooltipDisabled]="!(!validSegmentStatusList.includes(row.status) || row.invalidCoupons > 0)">
                  <mat-checkbox color="accent" [checked]="selectionSegments.isSelected(row)"
                    [disabled]="!validSegmentStatusList.includes(row.status) || row.invalidCoupons > 0"
                    (change)="$event ? toggleSelectionSegmentPost(row) : null;" (click)="$event.stopPropagation()">
                  </mat-checkbox>
                </div>
                <div id="tooltipSegmentPRE" *ngIf="selectedType.waiverCategory.code === 'PRE'"
                  matTooltip="{{ 'tooltip.invalidSegment' | translate }}" matTooltipPosition="before"
                  [matTooltipDisabled]="validSegmentStatusList.includes(row.status)">
                  <mat-checkbox color="accent" [checked]="selectionSegments.isSelected(row)"
                    [disabled]="!validSegmentStatusList.includes(row.status)"
                    (change)="$event ? toggleSelectionSegmentPost(row) : null;" (click)="$event.stopPropagation()">
                  </mat-checkbox>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsS" class="waiver-post-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsS; let i = index"
              [ngClass]="{ 'highlight': selectedSegmentsInformationRowIndex === i || selectionSegments.isSelected(row)}"
              (click)="toggleSelectionSegmentPost(row)" (mouseout)="highlightSegmentsInformationRow($event, i)"
              (mouseover)="highlightSegmentsInformationRow($event, i)" class="waiver-post-row">
            </mat-row>
          </mat-table>
        </div>
      </div>

      <div id="valueAdvp" *ngIf="newWaiverAdvp != null">
        <div class="row mt-4">
          <div class="col">
            <span class="subtitle">New Valorization</span>
          </div>
        </div>
        <div class="mat-elevation-z1 table-post mt-3">
          <ng-container *ngIf="this.isSelectAdvp">
            <div class="row">
              <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <mat-form-field>
                  <mat-label>Base Fare</mat-label>
                  <input matInput value="{{newWaiverAdvp.base_fare}}" readonly
                    class="upper-input">
                </mat-form-field>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <mat-form-field>
                  <mat-label>Reference Min Fare</mat-label>
                  <input matInput value="{{newWaiverAdvp.reference_min_fare}}"
                    readonly class="upper-input">
                </mat-form-field>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3" *ngIf="valorizacionNueva">
                <mat-form-field>
                  <mat-label>Farebasis joined</mat-label>
                  <input matInput value="{{descuentoAdvpFarebasis}}"
                    readonly class="upper-input">
                </mat-form-field>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <mat-form-field>
                  <mat-label>Currency</mat-label>
                  <input matInput value="{{newWaiverAdvp.currency}}" readonly
                    class="upper-input">
                </mat-form-field>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3" *ngIf="valorizacionNueva">
                <button mat-raised-button
                  (click)="calcularNewValorization()" class="background-red w-100">Valorization</button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>



      <ng-container *ngIf="isValid && formG.get('waiverType').value.code === 'FRD' && isLegalAllowed">
        <app-fare-diff #fareDiffComponent [isPrivate]="isPrivate" [isValuated]="isValuated" [isValuating]="isValuating"
          (cxRivalry)="getCxRivalryValue($event)" (isFareValueValid)="getFareValueValid($event)"
          (setValuated)="setValuated($event)" (solicitedValue)="getSolicitedValue($event)">
        </app-fare-diff>
      </ng-container>

      <ng-container *ngIf="isValid">
        <hr>


        <div class="col pl-1 pr-1">
          <span class="subtitle-red">{{ 'onlyAdults' | translate}}</span>
        </div>
        <div class="row mt-4">
          <div class="col text-center">
            <span class="subtitle">{{ 'summary' | translate }}&nbsp;</span><span class="subtitle-bold">{{ 'field.pnr' |
              translate }}&nbsp;{{ pnr ? pnr.pnrNumber : '' }}</span>
          </div>
        </div>

        <div class="row ml-0 mr-0">
          <div class="col pl-1 pr-1">
            <app-booking-info #bookingInfoComponent [booking]="booking" [pnr]="pnr"
              [displayedColumns]="displayedColumnsBookingInfo"></app-booking-info>
          </div>
        </div>

        <div class="row ml-0 mr-0">
          <div class="col pl-1 pr-1">
            <app-passenger-info #passengerInfoComponent [passengers]="passengers"
              [displayedColumns]="displayedColumnsPassengerInfo" [isValuated]="isValuated"></app-passenger-info>
          </div>
        </div>

        <div class="row ml-0 mr-0">
          <div class="col pl-1 pr-1">
            <app-segment-info #segmentInfoComponent [isPrivate]="isPrivate" [isValuated]="isValuated"
              [waiverType]="formG.get('waiverType').value" [segments]="segments" [step]="step"
              [displayedColumns]="displayedColumnsSegmentInfo" (formValid)="getFormValid($event)"></app-segment-info>
          </div>
        </div>

        <div *ngIf="this.waiverOneDealsFirstTrip !== null" class="row ml-0 mr-0">
          <div class="col pl-1 pr-1">
            <div class="row">
              <div class="col">
                <span class="subtitle">{{ 'commissionOneDeal' | translate }}</span>
              </div>
            </div>
            <p>{{ 'firstCommission' | translate }}: {{this.waiverOneDealsFirstTrip[0].commission}}%</p>
            <p *ngIf="this.waiverOneDealsSecondTrip !== null">{{ 'secondCommission' | translate }}:
              {{this.waiverOneDealsSecondTrip[0].commission}}%</p>

          </div>
        </div>

        <hr>

        <div class="row mt-3 mb-3">
          <div class="col-9 text-center">
            <ng-container *ngIf="isValuating">
              <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                </circle>
              </svg>
              <span class="loading-span">{{ 'validatingData' | translate }}</span>
            </ng-container>
          </div>

          <div *ngIf="!(formG.get('waiverType').value.code==='OND')" class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <button mat-raised-button
              [disabled]="(!segmentsValid || !(formG.get('waiverType').value.code==='FRD' ? isFareValueValid : true) || isValuating || invalidP())"
              (click)="calculateWaiver()" class="background-red w-100">{{ 'button.submitValue' | translate }}</button>
          </div>
        </div>
      </ng-container>
    </mat-step>

    <mat-step *ngIf="!isDiplomatic()" [completed]="isAgree">
      <ng-template matStepLabel>
        <span>{{ 'requestSummary' | translate }}</span>
      </ng-template>

      <hr>

      <div class="row mt-4 align-items-end">
        <div class="col-sm-6 col-md-8 col-lg-9 col-xl-9">
          <span class="subtitle">{{ 'requestSummary' | translate }}</span>
        </div>

        <div *ngIf="isValuated" class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
          <app-budget-popup [groupId]="groupId" [branchOfficeId]="branchOfficeId" [reload]="reload">
          </app-budget-popup>
        </div>
      </div>

      <ng-container *ngIf="isValuated && !fareDiff">
        <div class="row mt-4">
          <div class="col">
            <table class="table table-bordered">
              <ng-container *ngIf="isChangeWaiver">
                <tr>
                  <td colspan="2" class="label-th"><span>{{ 'field.onlyContainRestriction' | translate }}
                    </span><span>{{ codeChangeWaiver }}</span></td>
                </tr>
              </ng-container>
              <tr>
                <td class="label-td w-196p">
                  <span>{{ 'field.lowestAvailableRate' | translate }}</span><br><span>{{ 'field.withoutQAndTax' |
                    translate }}</span>
                </td>
                <td>{{ waiverValue.originalFareByPaxWOQs | number }}</td>
              </tr>
              <tr>
                <td class="label-td">
                  <span>{{ 'field.approvedRate' | translate }}</span><br><span>{{ 'field.withoutQAndTax' | translate
                    }}</span>
                </td>
                <td>{{ waiverValue.finalFareByPaxWOQs | number }}</td>
              </tr>
              <tr class="table-active">
                <td class="label-td">{{ 'field.waiverValue' | translate }}</td>
                <td>{{ waiverValue.waiverValue | number }}</td>
              </tr>
              <tr>
                <td colspan="2">
                  <div class="row justify-content-center">
                    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <button mat-raised-button (click)="disagreeWaiverPresale()" class="background-red w-100"
                        [disabled]="isAdding">{{ 'button.deny' | translate }}</button>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <button mat-raised-button (click)="agreeWaiverPresale()" class="background-blue w-100"
                        [disabled]="isAdding">{{ 'button.confirm' | translate }}</button>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <ng-container *ngIf="isAdding || isValuatingCustom">
          <div class="row">
            <div class="col text-center">
              <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                </circle>
              </svg>
              <span class="loading-span">{{ 'validatingData' | translate }}</span>
            </div>
          </div>
        </ng-container>

      </ng-container>

      <ng-container *ngIf="isValuated && fareDiff">
        <div class="row mt-4" *ngIf="!isValuatingCustom">
          <div class="col">
            <table class="table table-bordered">
              <ng-container *ngIf="!isChangeMatch">
                <tr>
                  <td class="label-th" colspan="2">
                    <span>{{ 'field.maxDiscountAvailable' | translate }}</span><span>&nbsp;{{ maxPercentage }}%</span>
                  </td>
                </tr>
              </ng-container>
              <tr>
                <td class="label-td w-248p">{{ 'field.lowestRateWithoutQ' | translate }}</td>
                <td>{{ waiverValue.originalFareByPaxWOQs | number }}</td>
              </tr>
              <tr>
                <td class="label-td">Q</td>
                <td>{{ waiverValue.qsByPax | number }}</td>
              </tr>
              <tr>
                <td class="label-td">{{ 'field.totalWithoutTax' | translate }}</td>
                <td>{{ totalOriginal | number }}</td>
              </tr>
              <tr class="table-active">
                <td class="label-td">{{ 'field.discountApplied' | translate }}*</td>
                <td>
                  <ng-container>
                    <div class="row">
                      <div class="col-7">
                        {{percentageSelected}}%
                      </div>
                      <mat-form-field appearance="fill" class="col-4 percentage">
                        <mat-label>{{ 'button.optionDiscount' | translate }}</mat-label>
                        <mat-select id="percentage" (selectionChange)="parcentageChange()" [(ngModel)]="percentageSelected" required
                        >
                          <mat-option *ngFor="let percentage of availablePercentage" [value]="percentage">
                            <div id="{{ percentage }}">{{ percentage }}%</div>
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </ng-container>
              </tr>
              <tr>
                <td class="label-td">{{ 'field.approvedRateWithoutQ' | translate }}</td>
                <td>{{ waiverValue.finalFareByPaxWOQs | number }}</td>
              </tr>
              <tr>
                <td class="label-td">Q</td>
                <td>{{ waiverValue.qsByPax | number }}</td>
              </tr>
              <tr>
                <td class="label-td">{{ 'field.totalWithoutTax' | translate }}</td>
                <td>{{ totalFinal | number }}</td>
              </tr>
              <tr class="table-active">
                <td class="label-td">{{ 'field.waiverValue' | translate }}</td>
                <td>{{ waiverValue.waiverValue | number }}</td>
              </tr>
              <tr>
                <td colspan="2">
                  <div class="row justify-content-center">
                    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <button mat-raised-button (click)="disagreeWaiverPresale()" class="background-red w-100"
                        [disabled]="isAdding">{{ 'button.deny' | translate }}</button>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <button mat-raised-button (click)="agreeWaiverPresale()" class="background-blue w-100"
                        [disabled]="isAdding">{{ 'button.confirm' | translate }}</button>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <div class="label-display">
              <span>*</span><span>{{ 'tooltip.faresByPax' | translate }}</span>
            </div>
          </div>
        </div>

        <ng-container *ngIf="isAdding || isValuatingCustom">
          <div class="row">
            <div class="col text-center">
              <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                </circle>
              </svg>
              <span class="loading-span">{{ 'validatingData' | translate }}</span>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div class="row mt-4">
        <div class="col">
          <a href="javascript:void(0);" (click)="showDetail()" class="subtitle">{{ 'detailRequest' | translate }}</a>
          <span [ngClass]="{ 'arrow-down': !isShowDetail, 'arrow-up': isShowDetail }"></span>
        </div>
      </div>

      <ng-container *ngIf="isShowDetail && isValid">
        <hr>

        <div class="row mt-4">
          <div class="col text-center">
            <span class="subtitle">{{ 'summary' | translate }}&nbsp;</span><span class="subtitle-bold">{{ 'field.pnr' |
              translate }}&nbsp;{{ pnr ? pnr.pnrNumber : '' }}</span>
          </div>
        </div>

        <div class="row ml-0 mr-0">
          <div class="col pl-1 pr-1">
            <app-booking-info #bookingInfoComponent [booking]="booking" [pnr]="pnr"
              [displayedColumns]="displayedColumnsBookingInfo"></app-booking-info>
          </div>
        </div>

        <div class="row ml-0 mr-0">
          <div class="col pl-1 pr-1">
            <app-passenger-info #passengerInfoComponent [passengers]="passengers" [isValuated]="isValuated"
              [displayedColumns]="displayedColumnsPassengerInfo"></app-passenger-info>
          </div>
        </div>

        <div class="row ml-0 mr-0">
          <div class="col pl-1 pr-1">
            <app-segment-info #segmentInfoComponent [isPrivate]="isPrivate" [isValuated]="isValuated"
              [waiverType]="formG.get('waiverType').value" [segments]="segments" [step]="step"
              [displayedColumns]="displayedColumnsSegmentInfo">
            </app-segment-info>
          </div>
        </div>
      </ng-container>
    </mat-step>

    <mat-step *ngIf="isDiplomatic()">
      <ng-template matStepLabel>
        <span>{{ 'requestSummary' | translate }}</span>
      </ng-template>

      <hr>

      <div class="row mt-3">
        <div class="col">
          <span class="subtitle">{{ 'requestSummary' | translate }}</span>
        </div>
      </div>

      <form id="resumenWaiverForm" #resumenWaiverForm="ngForm">
        <ng-container *ngIf="!isAdding">
          <div class="row mt-3">
            <div class="col">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <th id="waiverSummaryType" scope="row" class="text-nowrap" width="25%">
                      {{ 'field.type' | translate }}
                    </th>
                    <td colspan="2" class="text-table-content" width="75%">{{ getWaiverTypeSelected().name }}</td>
                  </tr>

                  <tr id="waiverSummaryDocumentNumber" class="border-bottom-green-2px">
                    <th *ngIf="pnr?.pnrNumber" scope="row" class="text-nowrap">{{ 'field.pnr' | translate }}</th>
                    <th *ngIf="!pnr?.pnrNumber && passengers[0]?.ticketNumber" scope="row" class="text-nowrap">{{ 'field.tkt' | translate }}</th>
                    <td colspan="2" class="text-table-content">{{ pnr?.pnrNumber || passengers[0]?.ticketNumber }}</td>
                  </tr>

                  <tr id="waiverValor" class="border-none">
                    <th></th>
                    <td class="border-none"></td>
                    <td class="cell-green text-valor-waiver" width="25%">
                      <span>{{ 'field.waiverValueUsd' | translate }}</span>
                      <strong>{{ (0 | number: '1.2-2': 'en-US') }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <button mat-raised-button id="adj-btn" matTooltip="{{ 'tooltip.allowedExtensions' | translate }}"
                matTooltipPosition="after" [disabled]="files.length == 2" (click)="onClick()"
                class="background-blue w-100">{{ 'button.attachFile' | translate }}</button>
              <mat-hint class="opcional mt-1">{{ 'tooltip.maxSize' | translate }}</mat-hint>
            </div>

            <div class="col-8 col-md-8 col-lg-6 col-xl-6 containerAdjunto">
              <ul class="filename-list">
                <li class="filename-list-item no-files" *ngIf="files.length == 0">
                  <span class="filename-span">{{ 'warning.fileRequired' | translate }}</span>
                </li>
                <li class="filename-list-item" *ngFor="let file of files" matTooltipPosition="after"
                  [matTooltip]="file.data.name" [@fadeInOut]="file.state">
                  <span class="filename-span" id="file-label">
                    {{ file.data.name }}
                  </span>
                  <a title="Cancel" (click)="cancelFile(file)" *ngIf="file.canCancel">
                  <mat-icon *ngIf="!file.deletingFile" class="cancelAdjunto">close</mat-icon>
                    <div class="deleting-loader" *ngIf="file.deletingFile">
                      <svg class="spinner" width="24px" height="24px" viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                        </circle>
                      </svg>
                    </div>
                  </a>
                </li>
              </ul>
              <input type="file" id="fileUpload" name="fileUpload" multiple="multiple" accept="{{accept}}"
                style="display:none;" />
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <label class="text-nowrap camp-txt-lib">{{ 'field.addComment' | translate }}</label>
              <mat-hint class="opcional">{{ 'tooltip.optional' | translate }}</mat-hint>
            </div>

            <div class="col-8 col-md-8 col-lg-6 col-xl-6">
              <div class="text-libre-tab">
                <textarea matInput name="aditionalComment" #message placeholder="{{ 'field.enterComment' | translate }}"
                  maxlength="500" [(ngModel)]="aditionalComment" class="aditional-comment"></textarea>
                <mat-hint class="text-area" align="end">{{ message.value.length }} / 500</mat-hint>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="loader loading" *ngIf="isAdding">
          <div class="loading-div">
            <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
            </svg>
            <span class="loading-span">{{ 'sendingRequest' | translate }}</span>
          </div>
        </div>

        <hr>

        <div class="row mb-3 justify-content-between">
          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <button mat-raised-button matStepperPrevious id="step2BackButton" [disabled]="isAdding"
              class="btn-volver w-100">{{ 'button.back' | translate }}</button>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <button mat-raised-button matStepperNext id="sendRequestButton"
              [disabled]="isAdding || resumenWaiverForm.invalid  || !validateFile() || inProgress"
              (click)="agreeWaiverPresale()" class="background-red w-100">{{ 'button.sendRequest' | translate }}</button>
          </div>
        </div>
      </form>
    </mat-step>

    <mat-step *ngIf="!isDiplomatic()">
      <ng-template matStepLabel>
        <span>{{ 'requestResult' | translate }}</span>
      </ng-template>

      <hr>

      <div class="row mt-5 align-items-end">
        <div class="col-sm-6 col-md-8 col-lg-9 col-xl-9">
          <span class="subtitle">{{ 'requestResult' | translate }}</span>
        </div>

        <div *ngIf="isAgree" class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
          <app-budget-popup [groupId]="groupId" [branchOfficeId]="branchOfficeId" [reload]="reload">
          </app-budget-popup>
        </div>
      </div>

      <ng-container *ngIf="isAgree">
        <app-request-result #RequestResultComponent [waiverResult]="waiverResult" [isFarediff]="fareDiff" [isBrMarket]="isBrMarket" [isSelfManaged]="isSelfManaged">
        </app-request-result>
      </ng-container>

      <div class="row justify-content-end">
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
          <button mat-raised-button (click)="disagreeWaiverPresale()" class="background-red w-100">{{ 'button.finish' |
            translate }}</button>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>

<div class="container">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="latam-sans breadcrumb-item">
            <a>{{ "menu.portalBda" | translate }}</a>
          </li>
          <li class="breadcrumb-item latam-sans active" aria-current="page">
            <a>{{ "bda.title" | translate }}</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <span class="title"> {{ "bda.title" | translate }}</span>
    </div>
  </div>

  <hr />

  <ng-container>
    <div class="row mt-3">
      <div class="col">
        <form #loadSalesForm="ngForm">
          <div class="row">
            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-form-field>
                <mat-label>{{ "field.bulkLoadType" | translate }}</mat-label>
                <mat-select
                  id="loadSalesSelect"
                  name="loadSalesSelect"
                  (selectionChange)="valueSelect(fileUpload)"
                  required
                  #fileUpload
                >
                  <mat-option
                    *ngFor="let loadSales of loadSalesTypeList"
                    [value]="loadSales"
                  >
                    {{ "field.format" | translate }} {{ loadSales.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div
            style="margin-top: auto; margin-bottom: auto"
            *ngIf="format == '1.0'"
            (click)="ExampleArchivo('1.0')"
            >
              <a style="margin-top: auto; margin-bottom: auto; cursor: pointer"
                >Baixar Modelo BDA 1.0</a
              >
            </div>
            <div
              style="margin-top: auto; margin-bottom: auto"
              *ngIf="format == '2.0'"
              (click)="ExampleArchivo('2.0')"
            >
              <a style="margin-top: auto; margin-bottom: auto; cursor: pointer"
                >Baixar Modelo BDA 2.0</a
            >
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 ml-auto">
              <button
                mat-raised-button
                id="downloadManual"
                (click)="downloadBdaManual()"
                class="background-blue w-100"
              >
                Baixar manual
              </button>
            </div>
          </div>

          <div class="row pt-3">
            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <button
                mat-raised-button
                id="attachFile"
                matTooltip="{{ 'tooltip.allowedFormats' | translate }}"
                [disabled]="files.length === 1 || format === ''"
                (click)="validateFormat()"
                class="background-blue w-100"
              >
                {{ "button.attachFile" | translate }}
              </button>
            </div>

            <div
              class="col-sm-6 col-md-8 col-lg-9 col-xl-9 background-gray fileNameContainer"
            >
              <ul class="filename-list">
                <li *ngIf="files.length == 0" style="list-style: none">
                  <span class="filename-span" style="position: absolute">{{
                    "warning.fileRequired" | translate
                  }}</span>
                </li>
                <ng-container *ngIf="files.length > 0">
                  <li
                    *ngFor="let file of files"
                    [matTooltip]="file.data.name"
                    [matTooltipPosition]="'below'"
                    style="list-style: none; margin-top: 15px"
                  >
                    <span id="file-label" class="filename-span">{{
                      file.data.name
                    }}</span>
                    <a
                      *ngIf="file.canCancel"
                      title="Cancel"
                      (click)="cancelFile(file)"
                    >
                      <mat-icon *ngIf="!file.deletingFile" class="cancelAdjunto"
                        >close</mat-icon
                      >
                      <div *ngIf="file.deletingFile" class="deleting-loader">
                        <svg
                          class="spinner"
                          width="24px"
                          height="24px"
                          viewBox="0 0 66 66"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            class="path"
                            fill="none"
                            stroke-width="6"
                            stroke-linecap="round"
                            cx="33"
                            cy="33"
                            r="30"
                          ></circle>
                        </svg>
                      </div>
                    </a>
                  </li>
                </ng-container>
              </ul>
              <input
                type="file"
                id="fileUpload"
                name="fileUpload"
                multiple="multiple"
                accept="{{ accept }}"
                style="display: none"
              />
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="row mt-3 justify-content-center">
      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <button
          mat-raised-button
          [disabled]="files.length == 0"
          (click)="initBulkLoad()"
          class="background-red w-100"
        >
          {{ "button.startBulkLoad" | translate }}
        </button>
        <mat-hint class="opcional mt-1">{{
          "tooltip.maxSizeStorage" | translate
        }}</mat-hint>
      </div>
      <div *ngIf="loadSalesProcess" class="col-1 mt-5px">
        <svg
          class="spinner"
          width="30px"
          height="30px"
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            class="path"
            fill="none"
            stroke-width="6"
            stroke-linecap="round"
            cx="33"
            cy="33"
            r="30"
          ></circle>
        </svg>
      </div>
    </div>
  </ng-container>
</div>

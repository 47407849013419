import { AgencyGroup } from './agency-group.model';
import { Market } from './market.model';
import { BranchOffice } from './branch-office.model';

export class AgencyCreate {
  id: number;
  name: string;
  market: Market;
  country: string;
  city: string;
  iata: string;
  executive: string;
  agencyCode: number;
  agencyBranch: number;
  officeId: string;
  pcc: string;
  active: boolean;
  agencyGroup: AgencyGroup;
  piaAgencyCode: string;
  piaAgencyType: string;
  agencyBranchOffice: BranchOffice;
  agencyId: string;


}

export class UserAgencysCsvElement {
  public name: string;
  public lastname: string;
  public email: string;
  public active: string;
  public pos: string;
  public agencyId: string;
  public agencyName: string;
  public rol: string;
  public piaAgencyCode: string;
  public groupId: string;
  public groupName: string;
  public branchId: string;
  public branchName: string;

  constructor($userData: any) {
    this.name = $userData.name;
    this.lastname = $userData.lastname;
    this.email = $userData.email;
    this.active = $userData.active;
    this.pos = $userData.pos;
    this.agencyId = $userData.agencyId;
    this.agencyName = $userData.agencyName;
    this.rol = $userData.rol;
    this.piaAgencyCode = $userData.piaAgencyCode;
    this.groupId = $userData.groupId;
    this.groupName = $userData.groupName;
    this.branchId = $userData.branchId;
    this.branchName = $userData.branchName;
  }
}

<ng-container *ngIf="infoUser$ | async as infoUser">
  <div class="container">
    <div class="row">
      <div class="col">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="latam-sans breadcrumb-item">
              <a>{{ 'menu.latamManagement' | translate }}</a>
            </li>
            <li class="breadcrumb-item latam-sans active" aria-current="page">
              <a>{{ 'menu.userProfile' | translate }}</a>
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <span id="userProfileTitle" class="title">{{ 'menu.userProfile' | translate }}</span>
      </div>
    </div>

    <hr>

    <div *ngIf="loading" class="loading">
      <div class="loading-div">
        <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
        <span class="loading-span">{{ 'loading' | translate }}</span>
      </div>
    </div>

    <ngx-loading [show]="updating"></ngx-loading>

    <table *ngIf=!loading class="table table-bordered mt-4">
      <thead>
        <tr>
          <td scope="col" colspan="2" class="title">{{ 'configuration' | translate }}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td id="tradeNameTh" class="text-nowrap">
            <span>{{ 'field.waiverNotiCreated' | translate }}</span>
          </td>
          <td class="text-table-content">
            <mat-slide-toggle disableRipple [(ngModel)]="userLatamConfigurations.notifications"
              (click)="updateNotifications()"></mat-slide-toggle>
          </td>
        </tr>
        <tr>
          <td id="tradeNameTh" scope="row" class="text-nowrap">
            <span>{{ 'field.notiEmail' | translate }}</span>
          </td>
          <td class="text-table-content">
            <div class="row justify-content-center">
              <div class="col-12 col-md-10 col-lg-7 col-xl-6">
                <mat-form-field>
                  <mat-label>{{ 'field.pos' | translate }}</mat-label>
                  <mat-select id="posSelect" name="pos" [(ngModel)]="selectedPos" #pos="ngModel">
                    <mat-option *ngFor="let pos of infoUser.market" [value]="pos">
                      {{ pos }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-12 col-md-10 col-lg-7 col-xl-6 mt-3">
                <button mat-raised-button [disabled]="loading || selectedPos === 'POS'"
                  (click)="enableAgenciesNotificationByMarket(selectedPos)"
                  class="background-red w-100">{{ 'button.activateNotiEmail' | translate }}</button>
              </div>

              <div class="w-100 d-block d-xl-none"></div>

              <div class="col-12 col-md-10 col-lg-7 col-xl-6 mt-3">
                <button mat-raised-button [disabled]="loading || selectedPos === 'POS'"
                  (click)="disableAgenciesNotificationsByMarket(selectedPos)"
                  class="background-red w-100">{{ 'button.deactivateNotiEmail' | translate }}</button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>


<div id="fileErrorsModal"  class="modal-content small-modal">
  <div class="modal-header">
    <h5 class="modal-title"><mat-icon >error</mat-icon> {{ 'field.invalidData' | translate }}</h5>
    <button class="close" (click)="closeDialog()">
      <mat-icon class="close-icon">clear</mat-icon>
    </button> 
  </div>

  <div class="modal-body">

    <div class="row">
      <div class="col">
        <div class="table-container"> <!-- Contenedor para scroll -->
          <mat-table id="fileErrorList" #table [dataSource]="dataSource"  matSort>   
      
            <ng-container matColumnDef="rowNumber">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.rowNumber' | translate }}</mat-header-cell>        
              <mat-cell *matCellDef="let element">{{ element.rowNumber | dashWhenEmpty }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="columnName">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.columnNumber' | translate }}</mat-header-cell>        
              <mat-cell *matCellDef="let element">{{ element.columnName | dashWhenEmpty }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="providedValue">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.provideValue' | translate }}</mat-header-cell>        
              <mat-cell *matCellDef="let element">{{ element.providedValue | dashWhenEmpty }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="errorMessage">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.errorMessage' | translate }}</mat-header-cell>        
              <mat-cell *matCellDef="let element">{{ element.errorMessage | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container id="fileErrorList-no-data-found" matColumnDef="no-data-found" >
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell class="no-data-found-row" *matCellDef="let item">{{ 'bda.dataNotFound' | translate }}</mat-cell>
            </ng-container>
      
            <mat-header-row *matHeaderRowDef="displayedColumns" class="latam-user-row" id="waiver-manual-management-displayedColumns"></mat-header-row>
            <mat-row id="fileErrorList-no-data-found-row" *matRowDef="let row; columns: displayedColumns; let i = index;"
            ></mat-row>
            
          </mat-table>    
        </div>
      </div>
    
    </div>
  </div>

</div>

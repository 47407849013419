<mat-stepper #stepper>
  <mat-step>
    <app-search-reservation
      (reservationInfoEvent)="handleReservationInfo($event)"
      (stepperBack)="stepperBack($event)"
      [enableAllSearchTypes]="false"
      [waiverModule]="'POL'"
    >
    </app-search-reservation>
  </mat-step>
  <mat-step>
    <app-reservation-detail *ngIf="reservationInfoObtained && !validatingPassengers"
      [reservationInfo]="reservationInfo"
      [searchType]="'PNR'"
      [enableSelection]="false"
      [waiverModule]="'POL'"
      (continueProcess)="handlePassengersValidation($event)"
      (stepperBackEvent)="stepperBack($event)"
    >
    </app-reservation-detail>
    <div class="validating-passengers"
      *ngIf="validatingPassengers"
    >
      <mat-progress-spinner
                  class="validate-spinner"
                  mode="indeterminate"
                  diameter="50">
      </mat-progress-spinner>
      <br>
      <span class="regular">{{ 'waiverPolicy.validatingExceptions' | translate}}.</span>
    </div>
  </mat-step>
  <mat-step>
    <app-request-summary *ngIf="passengersSelected && segmentsSelected && !validatingPassengers && acceptedDocument"
      [selectionPassengers]="selectedPassengers"
      [reservationInfo]="reservationInfo"
      [waiverTypes]="waiverTypes"
      [waiverMotives]="waiverMotives"
      [waiverModule]="'POL'"
      [documentationNeeded]="documentationNeeded"
      (continueProcess)="handleRequest($event)"
      (stepperBackEvent)="stepperBack($event)"
    >

    </app-request-summary>
  </mat-step>
  <mat-step>
    <app-waiver-result
      [dataWaiverResult]="dataWaiverResult"
      (newRequestOutput)="newRequest()"
    >

    </app-waiver-result>
  </mat-step>
</mat-stepper>

import { Subscription } from 'rxjs';

export class FileUploadModel {
  data: File;
  state: string;
  inProgress: boolean;
  progress: number;
  canRetry: boolean;
  canCancel: boolean;
  deletingFile?: boolean;
  sub?: Subscription;

}

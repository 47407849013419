import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import { WaiverPostsaleService } from '../../../services/waiver-postsale.service';
import { ConstantsService } from '../../../services/constants.service';
import { MotiveType } from '../../../models/motive-type.model';
import { AgencyService } from '../../../services/agency.service';
import { ChangeNameFlightSegment } from '../../../models/change-name-flightsegment.model';
import { ChangeNameRemark } from '../../../models/change-name-remark.model';
import { ChangeNamePassenger } from '../../../models/change-name-passenger.model';
import { ChangeNameRequest } from '../../../models/validation-request-type.model';
import { MessageService } from '../../../services/message.service';
import { TranslateService } from '@ngx-translate/core';
import { CorrectNameService } from '../../../services/correct-name.service';
import { ModalValidateCorrectNameComponent } from '../modal-validate-correct-name/modal-validate-correct-name.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ModalErrorCorrectNameComponent } from '../modal-error-correct-name/modal-error-correct-name.component';

@Component({
  selector: 'app-form-correctname',
  templateUrl: './form-correctname.component.html',
  styleUrls: ['./form-correctname.component.css']
})
export class FormCorrectnameComponent implements OnInit {

  @Input() recordLocator: any;
  @Input() passengers: any;
  @Input() segments: any;
  @Input() remarks: any;
  oldFullName: string;
  newFullName: string;
  messageError: string;
  date: Date; 
  departureDate: string;
  returnDate: string;
  passengerForm: FormGroup;
  formattedDepartureTimeGo: string;
  formattedDepartureTimeReturn: string;
  loadingInfo: boolean = false;
  motiveTypes: MotiveType[] = [];
  segmentCount: { [key: number]: number } = {};
  @Output() correctionConfirmed: EventEmitter<any> = new EventEmitter();
  
  constructor(private waiverPostsaleService: WaiverPostsaleService,  
    private fb: FormBuilder,    
    private agencyService : AgencyService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private correctNameService: CorrectNameService, 
    public dialog: MatDialog
  ){ }

  
  ngOnInit(): void {    
    this.motiveTypes = this.getMotive();
    this.initPassengerForm();
    this.extractDepartureTimes();
    this.extractDates();
  }

  private initPassengerForm(): void {
      this.passengerForm = this.fb.group({
          firstName: [this.passengers[0]?.firstName || '', [Validators.required, this.soloMayusculas()]],
          lastName: [this.passengers[0]?.lastName || '', [Validators.required, this.soloMayusculas()]],
          paxNumber: [{value: this.passengers[0]?.paxNumber || ''}],
          type: [{value: this.passengers[0]?.type || '', disabled: true}],
          motif: ['', [Validators.required]],
          // ida
          classGo: [this.segments[0]?.class || ''],
          statusGO: [this.segments[0]?.status || ''],
          flightGo: [this.getFlightGo()],
          source: [this.segments[0]?.source || ''],      
          destination: [this.segments[0]?.destination || ''],
          departureTimeGo: [this.segments[0]?.departureDateTime || ''],
          

          classReturn: [this.getClassReturn()],
          statusReturn: [this.getStatusReturn()],
          flightReturn: [this.getFlightReturn()],
          sourceReturn: [this.getSourceReturn()],     
          destinationReturn: [this.getDestinationReturn()],
          departureTimeReturn: [this.getDepartureTimeReturn()]
      });
  }

  private getFlightGo(): string {
      return this.segments[0] ? `${this.segments[0].carrierMarketing} ${this.segments[0].flightNumber}` : '';
  }

  private getClassReturn(): string {
      return this.segments.length > 0 ? this.segments[this.segments.length - 1]?.class || '' : '';
  }

  private getStatusReturn(): string {
      return this.segments.length > 0 ? this.segments[this.segments.length - 1]?.status || '' : '';
  }

  private getFlightReturn(): string {
      return this.segments.length > 0 
          ? `${this.segments[this.segments.length - 1].carrierMarketing} ${this.segments[this.segments.length - 1].flightNumber}` 
          : '';
  }

  private getSourceReturn(): string {
      return this.segments.length > 0 ? this.segments[this.segments.length - 1]?.source || '' : '';
  }

  private getDestinationReturn(): string {
      return this.segments.length > 0 ? this.segments[this.segments.length - 1]?.destination || '' : '';
  }

  private getDepartureTimeReturn(): string {
      return this.segments.length > 0 ? this.segments[this.segments.length - 1]?.departureDateTime || '' : '';
  }

 
  private extractDepartureTimes(): void {
      this.formattedDepartureTimeGo = this.extractTimeFromISODate(this.passengerForm.get('departureTimeGo').value);
      this.formattedDepartureTimeReturn = this.extractTimeFromISODate(this.passengerForm.get('departureTimeReturn').value);
  }


  private extractDates(): void {
      this.departureDate = this.extractDate(this.passengerForm.get('departureTimeGo').value);
      this.returnDate = this.extractDate(this.passengerForm.get('departureTimeReturn').value);
  }



  onFormSubmit() {
    const paxNumber = this.passengerForm.get('paxNumber').value; 
    const passengerData = this.passengers.find(p => p.paxNumber === paxNumber); 

   
    this.oldFullName = passengerData ? `${passengerData.firstName} ${passengerData.lastName}` : ''; 
    this.newFullName = this.passengerForm.get('firstName').value + " " + this.passengerForm.get('lastName').value;
    this.date = new Date();
  }

  

  getMotive(): MotiveType[] {
    return [
      new MotiveType(1, 'correctName.motives.tresLetras'),
      new MotiveType(3, 'correctName.motives.nombresApellidosInvertidos'),
      new MotiveType(4, 'correctName.motives.agregarNombreApellido'),
      new MotiveType(6, 'correctName.motives.duplicidadNombre'),
      new MotiveType(7, 'correctName.motives.correccionAdicionExclusion')
    ];
  }

  validationRequestType() {
    const paxNumber = this.passengerForm.get('paxNumber')?.value;
    const passengerData = this.passengers.find(p => p.paxNumber === paxNumber);
  
    const passenger = new ChangeNamePassenger(
      null,
      passengerData?.firstName,
      passengerData?.lastName,
      this.passengerForm.get('firstName')?.value,
      this.passengerForm.get('lastName')?.value,
      paxNumber 
    );
    
    this.loadingInfo = true;
    const validationRequestType = this.loadRequest(passenger);

    this.correctNameService.validate(validationRequestType).subscribe({
      next: (data) => {
        this.loadingInfo = false; 
        const serviceStatus = data.serviceStatus; 

        if (serviceStatus && serviceStatus.code < 0) {
          this.handleErrorCode(serviceStatus.code); 
        } else {
            const passengerServiceStatus = data.passengers[0]?.serviceStatus;
            if (passengerServiceStatus && passengerServiceStatus.code < 0) {             
              this.handleErrorCode(passengerServiceStatus.code);
            } else if (passengerServiceStatus && passengerServiceStatus.code > 0) {
              this.openModalValidate();
            }
        }
      },
      error: (error: HttpErrorResponse) => {
        Swal.fire({            
          html: '<p>' + this.translateService.instant('login.error.loadError') + '</p>',
          confirmButtonText: this.translateService.instant('button.confirm'),
          confirmButtonColor: '#d33',
          icon: 'error',
          allowOutsideClick: false
        });
        this.loadingInfo = false;
      }
    });
}

handleErrorCode(code) {
    let translatedMessage;
    switch (code) {
        case -1:
            translatedMessage = this.translateService.instant('correctName.rechazoAnticipacion');
            break;
        case -2:
            translatedMessage = this.translateService.instant('correctName.rechazoCodeshare');
            break;
        case -3:
            translatedMessage = this.translateService.instant('correctName.rechazoOtraAerolinea');
            break;
        case -4:
            translatedMessage = this.translateService.instant('correctName.rechazoSinCambios');
            break;
        case -5:
            translatedMessage = this.translateService.instant('correctName.cambioNombreNoPosible');
            break;
        default:
            translatedMessage = this.translateService.instant('correctName.genericError'); 
    }
    this.openModalError(translatedMessage);
}
  


transactionType() {
  const paxNumber = this.passengerForm.get('paxNumber')?.value;
  const passenger = new ChangeNamePassenger(
    this.passengerForm.get('motif').value,
    this.passengers.find(p => p.paxNumber === paxNumber)?.firstName,
    this.passengers.find(p => p.paxNumber === paxNumber)?.lastName,
    this.passengerForm.get('firstName')?.value,
    this.passengerForm.get('lastName')?.value,
    paxNumber
  );

  const validationRequestType = this.loadRequest(passenger);

  this.correctNameService.transaction(validationRequestType).subscribe({
    next: data => {
      this.loadingInfo = false; 
      const serviceStatus = data.passengers[0].serviceStatus;
      
      if (serviceStatus && serviceStatus.code < 0) {
        this.handleGenericError(serviceStatus.code); 
        return; 
      }

      const messages = data.passengers.map(passenger => 
        passenger.serviceStatus?.message ? passenger.serviceStatus?.message : ' ').join(", ");
      this.onFormSubmit();
      
      let example: any = {
        oldName: this.oldFullName,
        newName: this.newFullName,
        motive: this.getMotive().find(motive => motive.id == this.passengerForm.get('motif').value).name,
        date: this.date
      };
      
      this.correctionConfirmed.emit(example);
    },
    error: err => {
      this.loadingInfo = false;
      console.error(err);
    }
  });
}

handleGenericError(code) {
  let translatedMessage;
  switch (code) {
    case -1:
      translatedMessage = this.translateService.instant('correctName.noSePudoCorregirNombre'); 
      break;
    default:
      translatedMessage = this.translateService.instant('correctName.noSePudoCorregirNombre'); 
  }
  this.openModalError(translatedMessage);
}


    loadRequest(passenger: ChangeNamePassenger) {
      let passengers: ChangeNamePassenger[] = [passenger];

      let flightSegments: ChangeNameFlightSegment[] = this.segments.map(seg => new ChangeNameFlightSegment(
        seg?.departureDateTime.slice(0, 19), 
        seg?.flightNumber, 
        null, 
        null, 
        seg?.carrierMarketing,
        seg?.codeshareAirlineCode, 
        seg?.segmentNumber
      ));

      let remarksInputValidate: ChangeNameRemark[] = [];  
      this.remarks.forEach( remark => {
        remarksInputValidate.push(new ChangeNameRemark(remark.remark.text));      
      })    
      const validationRequestType = new ChangeNameRequest(this.recordLocator, null, passengers, flightSegments, remarksInputValidate);
      return validationRequestType;
    }



    openModalValidate() {
      const dialogRef = this.dialog.open(ModalValidateCorrectNameComponent);
      
      const firstName = this.passengers.find(passenger => passenger.paxNumber === this.passengerForm.get('paxNumber').value).firstName;
      const lastName = this.passengers.find(passenger => passenger.paxNumber === this.passengerForm.get('paxNumber').value).lastName;
      
      dialogRef.componentInstance.oldFullName = firstName + " " + lastName;
      dialogRef.componentInstance.newFullName = this.passengerForm.get('firstName').value + " " + this.passengerForm.get('lastName').value;
      
      dialogRef.afterClosed().subscribe((response) => {
        if (response) {
          this.loadingInfo = true; 
          this.transactionType();  
        } else {
          this.loadingInfo = false; 
        }
      });
    }
    

  openModalError(message: string) {
    const dialogRef = this.dialog.open(ModalErrorCorrectNameComponent);
    dialogRef.componentInstance.messageError = message;
  }

  extractTimeFromISODate(isoDate: string): string {
    const date = new Date(isoDate);
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }
  

  get firstName() {
    return this.passengerForm.get('firstName');
  }

  get lastName() {
    return this.passengerForm.get('lastName');
  }


  extractDate(dateStr: string): string {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat('es-ES', { month: 'long' }).format(date);
    const year = date.getFullYear();
    const formattedDate = `${day} de ${month} del ${year}`;
    return formattedDate;
  }


  actualizarCampos(event: any) {
    const selectedPaxNumber = event.value; 
    const selectedPassenger = this.passengers.find(p => p.paxNumber === selectedPaxNumber);
    
    if (selectedPassenger) {
        this.passengerForm.get('firstName').setValue(selectedPassenger.firstName);
        this.passengerForm.get('lastName').setValue(selectedPassenger.lastName);
    }
  }

  getFormattedName(passenger: any): string {
    const fullName = `${passenger.firstName} ${passenger.lastName}`;
    return fullName.replace(/MR/g, ' ');
  }

  onFirstNameInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/MR/g, ' '); 
    this.passengerForm.get('firstName').setValue(input.value); 
  }

  soloMayusculas(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valor = control.value;
      const esValido = /^[A-ZÀ-ÖØ-ÝÑ\s]*$/.test(valor);
      return esValido ? null : { noMayusculas: true }; 
    };
  }

  contarSegmentNumbers(): number {
    const countMap = this.segments.reduce((acc, seg) => {
      acc[seg.segmentNumber] = (acc[seg.segmentNumber] || 0) + 1;
      return acc;
    }, {} as { [key: number]: number });
    const values = Object.values(countMap) as number[];
    return values.reduce((total, count) => total + count, 0);
  }

}

<div class="container">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="latam-sans breadcrumb-item">
            <a>{{ 'menu.waivers' | translate }}</a>
          </li>
          <li class="breadcrumb-item latam-sans active" aria-current="page">
            <a>{{ 'waiverReport' | translate }}</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <span class="title" id="reportTitle">{{ 'waiverReport' | translate }}</span>
    </div>
  </div>

  <hr>

  <div class="row mt-3">
    <div class="col">
      <span class="subtitle">{{ 'periodReport' | translate }}</span>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
      <mat-form-field>
        <mat-label>{{ 'field.rangeFrom' | translate }}</mat-label>
        <input id="startDateInput" matInput [max]="(endDate && endDate.toDate() < currentDate) ? endDate : currentDate"
          [matDatepicker]="startPicker" name="startDate"
          [(ngModel)]="startDate" #startDateInput="ngModel" readonly (dateChange)="startDateChanged($event)">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker (opened)="setCalendarLanguage()"></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
      <mat-form-field>
        <mat-label>{{ 'field.rangeTo' | translate }}</mat-label>
        <input id="endDateInput" matInput [min]="startDate"
          [max]="(startDate != null && adapter.addCalendarYears(startDate,1).toDate() < currentDate) ? adapter.addCalendarYears(startDate, 1) : currentDate"
          [matDatepicker]="endPicker" name="endDate"
          [(ngModel)]="endDate" #endDateInput="ngModel" readonly (dateChange)="endDateChanged($event)">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker (opened)="setCalendarLanguage()"></mat-datepicker>
      </mat-form-field>
    </div>

    <div *ngIf="profiles.readAgencyReports"
      class="col-sm-6 col-md-4 col-lg-3 col-xl-3 offset-sm-6 offset-md-0 offset-lg-0 offset-xl-0">
      <div class="row">
        <div class="col">
          <a mat-button csvLink [data]="report" [delimiter]="';'"
            [disabled]="!startDate||!endDate||report.length===0||loading" [filename]="reportFilename"
            [headers]="reportHeaders"
            class="mat-raised-button background-red w-100">{{ 'button.generateReport' | translate }}</a>
        </div>

        <div *ngIf="loading" class="col-1 mt-5px">
          <svg class="spinner" width="24px" height="24px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
          </svg></div>
      </div>
    </div>
  </div>
</div>

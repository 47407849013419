import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';

import { BusinessException, Exception, SystemException } from '../../../../models/Exception.model';
import { Language } from '../../../../models/language.model';
import { LatamRole } from '../../../../models/latam-role.model';
import { Market } from '../../../../models/market.model';
import { Profile } from '../../../../models/profile.model';
import { SidebarMenuModel } from '../../../../models/SidebarMenu.model';
import { UserLatam } from '../../../../models/user-latam.model';
import { UserLatamPosition } from '../../../../models/user-latam-position.model';

import { LatamService } from '../../../../services/latam.service';
import { MarketService } from './../../../../services/market.service';
import { MessageService } from './../../../../services/message.service';
import { ProfilingService } from '../../../../services/profiling.service';
import { SessionInfoService } from '../../../../services/session-info.service';
import { SidebarShareDataService } from '../../../../services/sidebar-share-data.service';
import { LatamOutsourcingService } from '../../../../services/latam-outsourcing.service';
import { MessageErrorService } from '../../../../services/message-error.service';


@Component({
  selector: 'app-create-latam-user',
  templateUrl: './create-latam-user.component.html',
  styleUrls: ['./create-latam-user.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CreateLatamUserComponent implements OnInit {
  @ViewChild('createLatamUserForm') createLatamUserForm: NgForm;
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_LATAM, SidebarMenuModel.SUBMENU_LATAM_USER_CREATION);
  markets: Market[] = [];
  userMarketIds: number | number[];
  userLatamPositions: UserLatamPosition[];
  latamRoles: LatamRole[];
  languages: Language[];
  userLatam: UserLatam = new UserLatam();
  loading: boolean = false;
  isAdmin: boolean = false;
  profiles = new Profile();
  readonly userOutsourcingRole = 7;
  postNotAllowed = ['PR', 'PA', 'CR'];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private latamService: LatamService,
    private marketService: MarketService,
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private profilesService: ProfilingService,
    private sessionInfoService: SessionInfoService,
    private sidebarShareDataService: SidebarShareDataService,
    private outsourcingService: LatamOutsourcingService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.checkIfIsAdmin();

    this.sessionInfoService.getSessionInfoAsPromise()
      .then(
        session => {
          this.userMarketIds = session.marketId;
          this.loadMarkets();
        }
      )
      .catch(
        error => {
          console.log('error', error);
        }
      );

    this.latamService.getUserLatamPositions().subscribe((data: UserLatamPosition[]) => {
      this.userLatamPositions = data;
    });

    this.latamService.getRolesLatam().subscribe((data: LatamRole[]) => {
      this.sessionInfoService.getSessionInfoAsPromise()
        .then(
          sessionInfo => {
            if (sessionInfo.isAdminPiaUserLatam()) {
              this.latamRoles = data.filter(role => role.id > 3);
            } else {
              this.latamRoles = data.filter(element => {
                return sessionInfo.isUserRoleIdGreaterThan(element.id);
              });
            }
          }
        )
        .catch(
          error => {
            console.log('error', error);
          }
        );
    });

    this.latamService.getLanguages().subscribe((data: Language[]) => {
      this.languages = data;
    });
  }

  private loadMarkets() {
    this.marketService.getMarkets().subscribe(
      data => {
        this.markets = this.getMarketsFilter(data);
        if (!this.isAdmin){
          if (typeof this.userMarketIds === 'number') {
            const userMarketIdsAux: number = this.userMarketIds;
            this.markets = this.markets.filter(item => userMarketIdsAux === item.id);
          } else {
            const userMarketIdsAux: number[] = this.userMarketIds;
            this.markets = this.markets.filter(item => userMarketIdsAux.indexOf(item.id) >= 0);
          }
        }
      },
      (error: HttpErrorResponse) => {
        console.log('error', error);
      }
    );
  }

  createUser(){
    const success = resp => {
      this.loading = false;
      this.showSuccessMessage();
    };

    const failure = (error: HttpErrorResponse) => {
      this.loading = false;
      this.messageErrorService.sendError(error, 'error.createUser');
    };

    this.loading = true;
    if (this.userLatam.role.id === this.userOutsourcingRole){
      this.outsourcingService.createOutsourcingUser(this.userLatam).subscribe(success, failure);
    } else{
      this.latamService.createUserLatam(this.userLatam).subscribe(success, failure);
    }
  }


  /**
   * shows a message of success
   */
  showSuccessMessage() {
    swal.fire({
      title: `<p>${ this.translateService.instant('popup.wellDone') }</p>`,
      html: `<p>${ this.translateService.instant('success.createUser') }</p>`,
      icon: 'success',
      confirmButtonText: this.translateService.instant('button.confirm'),
      confirmButtonColor: '#d33',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        this.router.navigate(['/latam/users/list']);
      }
    });
  }

  returnToList() {
    // Se incorpora this.activatedRoute , queryParams para que actualize al hacer el volver en register si hay cambios
    this.router.navigate(['../list'], { relativeTo: this.activatedRoute });
  }

  checkIfIsAdmin(){
    this.sessionInfoService.getSessionInfoAsPromise()
      .then(
        sessionInfo => {
          if (sessionInfo.role === 'ADMIN') {
              this.isAdmin = true;
          } else {
            this.isAdmin = false;
          }
    });

  }

  private getMarketsFilter(data: Market[]){
    return data.filter((market: Market) => !this.postNotAllowed.includes(market.name));
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserLatam } from '../models/user-latam.model';
import { RouteLocaleExtractorService } from './route-locale-extractor.service';
import { StorageService } from './storage.service';

@Injectable()
export class LatamOutsourcingService {

  constructor(private routeLocaleExtractorService: RouteLocaleExtractorService,
    private http: HttpClient) { }
    headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    urlBase = `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlUserAdmin}`;

    createOutsourcingUser(userLatam: UserLatam): Observable<UserLatam>{
        const locale = this.routeLocaleExtractorService.getLocale();
        const url = `${this.urlBase}${locale}/latam/outsourcing/users`;

        return this.http.post<UserLatam>(url, userLatam, {headers: this.headers});
    }

    deleteOutsourcingUser(userId: number): Observable<any>{
      const locale = this.routeLocaleExtractorService.getLocale();
      const url = `${this.urlBase}${locale}/latam/outsourcing/users/${userId}`;

      return this.http.delete(url, {headers: this.headers});
    }
}

import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  ofLabel: string;

  constructor(private translateService: TranslateService) {
    super();

    this.translateService.onLangChange.subscribe(
      (e: Event) => {
        this.getAndInitTranslations();
      }
    );

    this.getAndInitTranslations();
  }

  getAndInitTranslations() {
    this.translateService.get([
      'paginator.itemsPerPage',
      'paginator.nextPage',
      'paginator.previousPage',
      'paginator.ofLabel',
      'paginator.firstPageLabel',
      'paginator.lastPageLabel'
    ]).subscribe(
      translation => {
        this.itemsPerPageLabel = translation['paginator.itemsPerPage'];
        this.nextPageLabel = translation['paginator.nextPage'];
        this.previousPageLabel = translation['paginator.previousPage'];
        this.ofLabel = translation['paginator.ofLabel'];
        this.firstPageLabel = translation['paginator.firstPageLabel'];
        this.lastPageLabel = translation['paginator.lastPageLabel'];
        this.changes.next();
      }
    );
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.ofLabel} ${length}`;
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${this.ofLabel} ${length}`;
  }
}

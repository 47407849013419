import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Profile } from '../../../models/profile.model';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';
import { AgencyStatus } from '../../../interfaces/agency-status.interface';

import { AgencyService } from '../../../services/agency.service';
import { MessageService } from '../../../services/message.service';
import { ProfilingService } from '../../../services/profiling.service';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';
import { MessageErrorService } from '../../../services/message-error.service';


@Component({
  selector: 'app-search-agency',
  templateUrl: './search-agency.component.html',
  styleUrls: ['./search-agency.component.css']
})
export class SearchAgencyComponent implements OnInit {
  disableIata = false;
  disableName = false;
  loading = false;
  validSearch = false;

  statusAgency: AgencyStatus;

  selectedRowIndex = -1;

  agencyName: string;
  agencyIata: string;

  dataAgency = new MatTableDataSource();
  displayedColumns = ['tradeName', 'iataCode', 'zipCode', 'actions'];
  message = {
    from: '',
    icon: 'warning',
    detail: '',
    status: 0,
    show: false
  };
  profiles = new Profile();
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_AGENCIES, SidebarMenuModel.SUBMENU_AGENCY_SEARCH);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('name') name: string;
  @ViewChild('iata') iata: string;

  constructor(
    private router: Router,
    private agencyService: AgencyService,
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private profilesService: ProfilingService,
    private sidebarShareDataService: SidebarShareDataService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
  }

  /**
   * Busca la información de una agencia a partir de su IATA, la búsqueda se realiza a través
   * del servicio de búsqueda de agencias proporcionado por el servicio CDP de LATAM
   */
  async getAgencyByIata() {
    this.message.show = false;
    this.loading = true;

    const getAgencyByIataPromise = this.agencyService.getAgencyByIata(this.agencyIata).toPromise();

      await getAgencyByIataPromise.then(agency =>{
        //DATA DE LA TABLA
        this.agencyService.agency = agency;


        const dataResume  = {
            tradeName: agency.agencyData.legalName,
            pos: agency.address.country, // TODO: No se muestra en la pantalla
            status: agency.status.status, // TODO: No se muestra en la pantalla
            iataCode: agency.iata.iataCode, // TODO: No se muestra en la pantalla
            zipCode: agency.address.zipCode // TODO: Se cambio este por el officeId que no viene de CDP
          };

        if(this.dataAgency.data.length === 1) {
          this.dataAgency.data = [];
        }

        this.dataAgency.data.push(dataResume);
        this.dataAgency.paginator = this.paginator;
        this.dataAgency.sort = this.sort;


        this.message.status = 0;
        this.validSearch = true;
        this.loading = false;
    }).catch((err: HttpErrorResponse) =>{
      console.log(err);
              this.validSearch = false;
        this.showMessage(err, 'iata');
        this.loading = false;
    });
  }



  /**
   * Detecta cuando hay un cambio en el campo de búsqueda por iata para resetear y desabilitar el campo de busqueda por nombre
   * Si el cambio corresponde a dejar el campo vacío, se habilita el campo de busqueda por nombre nuevamente
   *
   * @param {string} value (valor que se ha ingresado en el campo de busqueda por iata)
   */
  public iataFieldChanged(value: string) {
    if (value) {
      this.agencyName = '';
      this.disableName = true;
    } else {
      this.disableName = false;
    }
  }

  /**
   * Muestra un mensaje de error cuando no se han encontrado agencias en la busqueda
   *
   * @param e object of error / contains the error detail
   */
  public showMessage(e: HttpErrorResponse, from: string) {
    this.message.from = from;
    this.message.show = true;
    if (e.status === 404) {
      this.message.status = 2;
      console.log('no data found');
    } else {
      this.message.status = 1;
    }
  }


  public searchActivate() {
    this.loading = true;
    this.getAgencyExistInLatamPrivate(this.agencyIata);
  }

  highlightRow(event: Event, rowIndex: number) {
    this.selectedRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  async getAgencyExistInLatamPrivate(iataCode: string){
    this.loading = true;

    const getAgencyByStatusByIataPromise = this.agencyService.getAgencyStatusByIata(iataCode).toPromise();

    await getAgencyByStatusByIataPromise.then( data => {
        this.statusAgency = data;
        if(this.statusAgency.id !== null){
          if(this.statusAgency.active === true){
            this.loading = false;
            this.messageService.showWarningMessage(
              this.translateService.instant('button.confirm'),
              this.translateService.instant('error.agencyAlreadyActive')
            );
          }else{
            this.agencyService.reactivateAgency(this.statusAgency.id).subscribe(() => {
              this.loading = false;
              this.messageService.showSuccessMessage(
                this.translateService.instant('button.confirm'),
                this.translateService.instant('popup.wellDone'),
                this.translateService.instant('success.activateAgency')
              );

            });
          }
        }else{
          this.router.navigate([`/latam/agencies/activation/${this.agencyIata}`])
          .then(() => {
            this.loading = false;
          });
        }
      },
      (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.validatingAgencyData')

        this.loading = false;
        console.log('error', error);
      }
    );
  }
}

import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { WaiverPostsaleService } from '../../../services/waiver-postsale.service';
import Swal from 'sweetalert2';
import { MatStepper } from '@angular/material/stepper';
import { OsiInfo } from '../../../models/osi-info.model';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../../../services/message.service';
import { Market } from '../../../models/market.model';
import { CurrencyConverter } from '../../../interfaces/currency-converter.interface';
import { SessionInfoService } from '../../../services/session-info.service';
import { BudgetService } from '../../../services/budget.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Waiver } from '../../../models/waiver.model';
import { WaiverType } from '../../../models/waiver-type.model';
import { WaiverMotive } from '../../../models/waiver-motive.model';
import { WaiverCategory } from '../../../models/waiver-category.model';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';
import { Exception } from '../../../models/Exception.model';
import { BehaviorSubject } from 'rxjs';
import { WaiverLatamTermsComponent } from '../../../components/waiver-latam-terms/waiver-latam-terms.component';
import { AgencyService } from '../../../services/agency.service';
import { CorrectNameService } from '../../../services/correct-name.service';

@Component({
  selector: 'app-name-seeker',
  templateUrl: './name-seeker.component.html',
  styleUrls: ['./name-seeker.component.css']
})

export class NameSeekerComponent implements OnInit, AfterViewInit {
  PNR: string = 'pnr';
  TKT: string = 'tkt';
  pnrValue: string = '';
  tktValue: string = '';
  loadingReservationInfo: boolean = false;
  invalidCouponsQuantity = 0;
  @ViewChild('stepper') private stepper: MatStepper;

  segmentsWithOneCouponInvalidQuantity = 0;

  displayedColumnsR = ['pnrNumber', 'pnrType', 'bookingCreation', 'NumberOfPassengers', 'pos', 'iata', 'pcc', 'officeId'];
  displayedColumnsPPOS = ['passengerName', 'foid', 'passengerType', 'tkt', 'select'];
  displayedColumnsP = ['passengerName', 'foid', 'passengerType', 'select'];
  displayedColumnsS = ['segmentNumber', 'carrierMkt', 'flightNumberMkt', 'class', 'origin', 'destination', 'dateHourDeparture',
      'status', 'fareBasis', 'select'];
  displayedColumnsTKT = ['tktNumber', 'pnrNum', 'emisionCreationDate', 'posInfo', 'iataInfo', 'pccInfo', 'officeIdInfo'];
  displayedColumnsV = ['voucherNumber', 'carrierTkt', 'flightNumberTkt', 'classTkt', 'originTkt', 'destinationTkt', 'dateHourTkt',
      'farebasis', 'voucherStatus', 'select'];
  displayedColumnsAgencyBudget = ['agencyGroupName', 'budgetTotal', 'budgetBalance', 'budgetUsed'];
  displayedColumnsWaiverResult = ['id', 'waiverPos', 'agencyName', 'iata', 'userName', 'waiverType', 'documentNumber', 'statusDate', 'flightDate'];
  dataReservation: any;
  dataPassengers = new MatTableDataSource();
  dataSegments = new MatTableDataSource();
  dataTkt = new MatTableDataSource();
  dataVoucher = new MatTableDataSource();
  dataAgencyBudget = new MatTableDataSource();
  dataWaiverResult = new MatTableDataSource();
  selectionPassengers = new SelectionModel<Element>(true, []);
  selectionSegments = new SelectionModel<Element>(true, []);
  selectionCoupons = new SelectionModel<Element>(true, []);
  passengerTicketRequestsCompleted: number = 0;
  passengerTicketRequestsWithError: number = 0;
  allPassengersInvalid: boolean = true;
  atLeastOneInfantPass = false;
  infantList: number[] = new Array();
  validSegmentStatusList = ['HK', 'KK'];
  invalidStatusSegmentsQuantity = 0;
  validPrePNR: boolean = false;
  osiInfo: OsiInfo;
  pnr: any;
  booking: any;
  invalidCouponStatusList = ['USED', 'RFND', 'EXCH', 'VOID', 'F', 'R', 'V', 'E'];
  existPassengersWithoutTicket: boolean = true;
  validPostPNR: boolean = false;
  validPostTKT: boolean = false;
  passenger: any;
  segments: any;
  remarks:any;

  correctionInfo: any;

  infantCoupon = false;
  marketRequest: Market = new Market();

  currencyChange: number = 0.0;

  selectedReservationInformationRowIndex: number = -1;
  selectedPassengerInformationRowIndex: number = -1;
  selectedSegmentsInformationRowIndex: number = -1;
  selectedTktInformationRowIndex: number = -1;
  selectedCouponsInformationRowIndex: number = -1;

  showAgencyBudget: boolean = false;

  currencyOptions: any[] = [
    {code: 'USD', suffix: '$'},
    {code: 'ARS', suffix: "AR$"},
    {code: 'AUD', suffix: 'AU$'},
    {code: 'BRL', suffix: "R$"},
    {code: 'CAD', suffix: 'CA$'},
    {code: 'CHF', suffix: 'CHF'},
    {code: 'COP', suffix: 'CO$'},
    {code: 'CLP', suffix: "CL$"}, 
    {code: 'EUR', suffix: '€'},
    {code: 'GBP', suffix: '£'},
    {code: 'MXN', suffix: 'MX$'},
    {code: 'PEN', suffix: "S/."}, 
    {code: 'PYG', suffix: "₲"}
  ];

  selectedWaiverType: string = ''; 
  selectedType: WaiverType = new WaiverType(0, '', '', new WaiverCategory(2, 'Post-Sale', 'POST'));
  selectedMotif: WaiverMotive = new WaiverMotive(4, '', '', false);
  selectedWaiverMotiveId: number = 0;
  selectedCurrency: string = 'USD';
  agencyGroupName: string = '';
  waiverTotalValue: number = 0.00;
  waiverUsdTotalValue: number = 0.00;
  isConvertingCurrency: boolean = false;
  isLoadingAgencyBudget: boolean = false;
  isAgencyBudgetValid: boolean = false;
  agencyGroupBudget: number = 0;
  waiver: Waiver = new Waiver();

  understandingAcceptance: boolean = false;
  termsAcceptance: boolean = false;
  aditionalComment: string = '';

  showTermsError: boolean = false;
  showWaiverTypeErrors: boolean = false;
  
  confirmCorrection: boolean = false;

  recordLocatorForm: FormGroup;
  /* FormGroup para manejar los costos por cada pasajero */
  passengersForm: FormGroup;
  additionalInfo: FormGroup;
  canSubmitWaiver: boolean = false;

  waiverApproved: Waiver = new Waiver();

  waiverAvailables: string[] = ['menu.waiverChanges', 'menu.waiverRefund'];
  waiverSelected: string;
  marketId: any;

  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_WAIVERS, SidebarMenuModel.SUBMENU_NEW_LATAM_WAIVER_REQUEST);

  message = {
      icon: 'warning',
      detail: '',
      status: 0,
      show: false
    };

  loadingSaveWaiver: boolean;
  loadingStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  stepped: boolean = false;
  waiverTypes: WaiverType[];


  constructor(private waiverPostsaleService: WaiverPostsaleService, 
      private translateService: TranslateService,
      private messageService: MessageService,
      private sessionInfoService: SessionInfoService,
      private budgetService: BudgetService,
      private fb: FormBuilder,
      private router: Router,
      private sidebarShareDataService: SidebarShareDataService,
      public dialog: MatDialog,
      private cdr: ChangeDetectorRef,
      private agencyService : AgencyService,
      private correctNameService : CorrectNameService


  ){ }


  navigateToReport() {
    this.router.navigate(['/agency/correct-name/report']);
  }

  ngOnInit(): void {
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.budgetService.triggerReload();
    this.getTypes();
    this.recordLocatorForm = this.fb.group({
      selection: ['PNR', Validators.required],
      pnrValue: [{ value: '', disabled: false }, [Validators.required, Validators.pattern(/^[A-Z]{6}$/)]],
      tktValue: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(/^[0-9]{13}$/)]]
    });

    this.recordLocatorForm.get('selection').valueChanges.subscribe(value => {
      if (value === 'PNR') {
        this.recordLocatorForm.get('pnrValue').enable();
        this.recordLocatorForm.get('tktValue').disable();
      } else if (value === 'TKT') {
        this.recordLocatorForm.get('pnrValue').disable();
        this.recordLocatorForm.get('tktValue').enable();
      }
    });
    
    this.passengersForm = this.fb.group({
      passengers: this.fb.array([])
    });

    this.additionalInfo = this.fb.group({
      aditionalComment: new FormControl(''),
      understandingAcceptance: new FormControl(false),
      termsAcceptance: new FormControl(false)
    });
  }

  ngAfterViewInit(){
    if (!this.stepped){
      this.checkIfFirstStepIsCompleted();
    }
  }

  searchPnrOrTkt(){
      if (this.isSearchingPnr()){
          this.getPnrInfo();
      }
  }

  isSearchingPnr(){
      return  this.recordLocatorForm.get('selection').value === 'PNR';
  }


  pnrOrTktNotFoundAlert(msg: string){
      Swal.fire({
          icon: "error",
          text: msg,
      });
  }

  getTktInfo(){
    this.cleanData();
    this.loadingReservationInfo = true;
    this.waiverPostsaleService.getTkt(this.recordLocatorForm.get('tktValue').value).subscribe({
      next: data => {
        this.dataTkt.data = [{
          tkt: data['tkt'],
          issuer: data['issuer'],
          booking: data['booking'],
          fare: data['fare'],
          passenger: data['passenger'],
          remark: data['remark']
        }];

        console.log(this.passenger, "se recibe la data");
        this.dataVoucher.data = data['coupons'];
        for (let dataVoucher of this.dataVoucher.data) {
          if (this.invalidCouponStatusList.includes(dataVoucher['status'])) { this.invalidCouponsQuantity++; }
        }
        this.validPostTKT = true;
        this.loadingReservationInfo = false;
        this.selectionPassengers.select(data['passenger'])
      },
      error: (error: HttpErrorResponse) => {
        this.validPostTKT = false;
        const msg = this.translateService.instant(
          'waiverLatam.tktNotFound',
          {
            tktValue: this.tktValue
          }
        );
        this.pnrOrTktNotFoundAlert(msg);
        this.loadingReservationInfo = false;
        console.log('error', error);
      }
    });
  }

  cleanData() {
    this.aditionalComment = '';
    this.showTermsError = false;
    this.termsAcceptance = false;
    this.understandingAcceptance = false;
    this.dataReservation.data = [];
    this.dataPassengers.data = [];
    this.dataSegments.data = [];
    this.dataVoucher.data = [];
    this.validPrePNR = false;
    this.validPostPNR = false;
    this.validPostTKT = false;
    this.selectionPassengers.clear();
    this.selectionSegments.clear();
    this.selectionCoupons.clear();
    this.passengerArray.clear();
    this.invalidCouponsQuantity = 0;
    this.passengerTicketRequestsCompleted = 0;
    this.passengerTicketRequestsWithError = 0;
    this.invalidStatusSegmentsQuantity = 0;
    this.infantList = [];
    this.infantCoupon = false;
    this.allPassengersInvalid = true;
    this.atLeastOneInfantPass = false;
    this.cleanWaiverObject();
    this.loadingReservationInfo = false;
    this.stepped = false;
  }

  private cleanWaiverObject() {
    this.waiver.pnr = null;
    this.waiver.booking = null;
    this.waiver.tkt = null;
    this.waiver.fare = null;
    this.waiver.document = null;
    this.waiver.documentNumber = null;
    this.waiver.documentPos = null;
    this.waiver.documentCreationDate = null;
    this.waiver.documentTktPnrReference = null;
    this.waiver.coupons = [];
    this.waiver.passengers = [];
    this.waiver.segments = [];
    this.waiver.aditionalComment = null;
    this.waiver.pnrAgency = null;
    this.waiver.files = null;
  }

  getPnrInfo(): void {
    this.loadingReservationInfo = true;
    this.pnrValue = this.recordLocatorForm.get('pnrValue').value;

    this.agencyService.getPnr(this.pnrValue).subscribe({
        next: data => {
            this.osiInfo = data['osiInfo'];
            this.pnr = data['pnr'];
            this.booking = data['booking'];
            this.passenger = data['passengers'].map((passenger: any) => {
                if (passenger.firstName.includes('MR') || passenger.firstName.includes('MS')) {
                    passenger.firstName = passenger.firstName.replace(/MR|MS/g, '').trim();
                }
                return passenger;
            });

            this.segments = data['segments'];
            this.remarks = data['remarks'];
            this.validPostPNR = true;
            this.loadingReservationInfo = false;
        },

        error: (error: HttpErrorResponse) => {
            this.validPostPNR = false;

            let msg: string;
            if (error.error?.type === "BusinessException" && error.error?.description === "IATA of the PNR does not match the user's group") {
                msg = this.translateService.instant('correctName.iatadoesNotMatch',
                  { pnrValue: this.pnrValue }
                );
            } else {
                msg = this.translateService.instant(
                    'waiverLatam.pnrNotFound',
                    { pnrValue: this.pnrValue }
                );
            }

            this.pnrOrTktNotFoundAlert(msg);
            this.loadingReservationInfo = false;
            console.log('error', error);
        }
    });
}


  private showQuestionMessage(pnrNumber: string, iata: string, osiInfo: OsiInfo) {
    Swal.fire({
      title: this.translateService.instant('acceptUsePnr'),
      icon: 'question',
      html: `<div class='popup-pnr'>${
        this.translateService.instant(
          'confirmUseIata',
          {
            osiInfo: osiInfo.agencyOsi,
            waiverType: this.selectedType.name,
            pnrNumber: pnrNumber,
            iata: iata
          }
        )
      }</div>`,
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('button.yes'),
      cancelButtonText: this.translateService.instant('button.no'),
    }).then((result) => {
      if (result.value) {
        this.validPostPNR = true;
      } else {
        this.validPostPNR = false;
      }
    });
  }
    

  processPassenger(i: number): void {
      let passenger = this.dataPassengers.data[i];
      passenger['allCouponsInvalid'] = true;

      if (!this.isValidTicketNumber(passenger['ticketNumber'])) {
          passenger['invalidTkt'] = true;
          this.passengerTicketRequestsCompleted++;
      } else if (passenger['type'] === 'INF') {
          this.processInfantPassenger(i);
      } else {
          this.processRegularPassenger(i, passenger);
      }
  }

  isValidTicketNumber(ticketNumber: string): boolean {
      return ticketNumber && ticketNumber.trim() !== '';
  }

  processInfantPassenger(i: number): void {
      this.atLeastOneInfantPass = true;
      this.infantList.push(i);
      let passenger = this.dataPassengers.data[i];
      passenger['infantTkt'] = true;
      passenger['allCouponsInvalid'] = false;
      this.existPassengersWithoutTicket = false;
      this.passengerTicketRequestsCompleted++;
  }

  processRegularPassenger(i: number, passenger: any): void {
      this.existPassengersWithoutTicket = false;
      this.waiverPostsaleService.getPnrTkt(passenger['ticketNumber'].substring(0, 13)).subscribe({
          next: data => this.handleTktDataResponse(i, data),
          error: (error: HttpErrorResponse) => this.handleTktDataError(i, error)
      });
  }

  handleTktDataResponse(i: number, data: any): void {
      let passenger = this.dataPassengers.data[i];
      let couponsForDeleteIndexes: number[] = this.filterCoupons(data['coupons']);

      this.removeCoupons(data['coupons'], couponsForDeleteIndexes);
      this.updatePassengerCoupons(i, passenger, data['coupons']);
      this.passengerTicketRequestsCompleted++;
  }

  filterCoupons(coupons: any[]): number[] {
      let couponsForDeleteIndexes: number[] = [];
      for (let j = 0; j < coupons.length; j++) {
          if (!this.isCarrierMarketingFoundOnSegmentList(coupons[j]['carrierMarketing'])) {
              couponsForDeleteIndexes.push(j);
          }
      }
      return couponsForDeleteIndexes;
  }

  isCarrierMarketingFoundOnSegmentList(carrierMarketing: string): boolean {
      return this.dataSegments.data.some(element => element['carrierMarketing'] === carrierMarketing);
  }

  removeCoupons(coupons: any[], couponsForDeleteIndexes: number[]): void {
      couponsForDeleteIndexes.forEach(n => {
          coupons.splice(n, 1);
      });
  }

  updatePassengerCoupons(i: number, passenger: any, coupons: any[]): void {
      for (const element of coupons) {
          element['tkt'] = passenger['ticketNumber'];
          if (element['status'] === 'OK') {
              passenger['allCouponsInvalid'] = false;
          }
      }

      passenger['coupons'] = coupons;

      if (!passenger['allCouponsInvalid']) {
          this.allPassengersInvalid = false;
      }
  }

  handleTktDataError(i: number, error: HttpErrorResponse): void {
      let passenger = this.dataPassengers.data[i];
      this.passengerTicketRequestsCompleted++;
      this.passengerTicketRequestsWithError++;
      passenger['validTktResponse'] = false;
  }

  handlePassengersWithoutTicket(): void {
      this.existPassengersWithoutTicket = false;
      this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.pnrWithoutTkt')
      );
  }
    
  addInfoInfantPassengerCoupons() {
    if (this.infantList.length > 0 && this.infantCoupon === false) {
      let indexPassInfo: number = -1;

      for (let pass = 0; pass < this.dataPassengers.data.length; pass++) {
        if (this.dataPassengers.data[pass]['allCouponsInvalid'] === false
          && this.dataPassengers.data[pass]['coupons'] != null) {
          indexPassInfo = pass;
          break;
        }
      }

      if (indexPassInfo !== -1) {
        for (const element of this.infantList) {
          this.dataPassengers.data[element]['coupons'] = this.dataPassengers.data[indexPassInfo]['coupons'];
        }
        this.infantCoupon = true;
      }
    }
  }
    
    /**
     * returns the information of a tkt
     */
    getTkt(): void {
      this.loadingReservationInfo = true;
      this.cleanData();    
      this.waiverPostsaleService.getTkt(this.tktValue).subscribe({
        next: data => {
          this.dataTkt.data = [{
            tkt: data['tkt'],
            issuer: data['issuer'],
            booking: data['booking'],
            fare: data['fare'],
            passenger: data['passenger'],
            remark: data['remark']
          }];
          this.dataPassengers.data = data['passenger']
          this.dataVoucher.data = data['coupons'];
          this.validPostTKT = true;
          this.loadingReservationInfo = false;
  
          for (let dataVoucher of this.dataVoucher.data) {
            if (this.invalidCouponStatusList.includes(dataVoucher['status'])) { this.invalidCouponsQuantity++; }
          }
        },
        error: (error: HttpErrorResponse) => {
          this.validPostTKT = false;
          this.loadingReservationInfo = false;
          console.log('error', error);
        }
      });
    }

  

    isPnrOrTktValid(tktNumber: NgModel, pnrNumber: NgModel){
      let isPnrOrTktValid: boolean = false;

      if (this.isSearchingPnr()){
          isPnrOrTktValid = pnrNumber.valid;
      } 

      return isPnrOrTktValid;
  }

  highlightReservationInformationRow(event: Event, rowIndex: number) {
      this.selectedReservationInformationRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  highlightPassengerInformationRow(event: Event, rowIndex: number) {
      this.selectedPassengerInformationRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  highlightSegmentsInformationRow(event: Event, rowIndex: number) {
      this.selectedSegmentsInformationRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  highlightTktInformationRow(event: Event, rowIndex: number) {
      this.selectedTktInformationRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  highlightCouponsInformationRow(event: Event, rowIndex: number) {
      this.selectedCouponsInformationRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  toggleSelectionSegmentPost(row) {
      if (this.validSegmentStatusList.includes(row.status) && row.invalidCoupons === 0) {
          this.selectionSegments.toggle(row);
      }
  }

  toggleSelectionPassengerPost(row) {
    let clearData = false;

    if (!row.invalidTkt && !row.allCouponsInvalid && !row.infantTkt) {
        if (!this.selectionPassengers.isSelected(row)) {
            this.addCouponsToSelectedSegments(row);
            if (row.type !== 'INF' && this.atLeastOneInfantPass) {
                this.setInfantTktPropertie(false);
            }
        } else {
            this.removeCouponsFromSelectedSegments(row);
            if (this.infantList.length > 0) {
                clearData = this.updateInfantSelectionState(row);
            }
        }

        this.selectionPassengers.toggle(row);
        this.updateSegmentSelectionState();
        this.updateSegmentsWithInvalidCoupons();

        if (clearData) {
            this.selectionPassengers.clear();
        }
    }
  }
  
  addCouponsToSelectedSegments(row) {
      for (const element of this.dataSegments.data) {
          let coupon = this.findCouponBySourceDestination(
              row.coupons, element['source'], element['destination']
          );
  
          if (!coupon) {
              continue;
          }
  
          element['selectedCoupons'].push(coupon);
  
          if (this.invalidCouponStatusList.includes(coupon['status'])) {
              element['invalidCoupons']++;
          }
      }
  }
  
  removeCouponsFromSelectedSegments(row) {
      for (const element of this.dataSegments.data) {
          let coupon = this.findCouponBySourceDestination(
              row.coupons, element['source'], element['destination']
          );
  
          if (!coupon) {
              continue;
          }
  
          element['selectedCoupons'].splice(
              element['selectedCoupons'].findIndex(c => c['tkt'] === coupon['tkt']), 1
          );
  
          if (this.invalidCouponStatusList.includes(coupon['status'])) {
              element['invalidCoupons']--;
          }
      }
  }
  
  updateInfantSelectionState(row) {
      let selectionAdlt = false;
  
      if (this.selectionPassengers.selected.length - 1 > 0) {
          this.selectionPassengers.selected.forEach((passenger: any) => {
              if (passenger.type !== 'INF' && passenger.ticketNumber !== row.ticketNumber) {
                  selectionAdlt = true;
              }
          });
  
          if (selectionAdlt) {
              this.setInfantTktPropertie(false);
          } else {
              this.setInfantTktPropertie(true);
              return true;  
          }
      } else {
          this.setInfantTktPropertie(true);
      }
  
      return false; 
  }
  
  updateSegmentSelectionState() {
      this.selectionSegments.selected.forEach((s: any) => {
          if (!this.validSegmentStatusList.includes(s.status) || s.invalidCoupons > 0) {
              this.selectionSegments.toggle(s);
          }
      });
  }
  
  updateSegmentsWithInvalidCoupons() {
      this.segmentsWithOneCouponInvalidQuantity = 0;
  
      for (const element of this.dataSegments.data) {
          if (this.validSegmentStatusList.includes(element['status']) && element['invalidCoupons'] > 0) {
              this.segmentsWithOneCouponInvalidQuantity++;
          }
      }
  }


    private setInfantTktPropertie(needDisabled: boolean) {
      if (this.infantList.length > 0 && this.atLeastOneInfantPass) {
        for (const element of this.infantList) {
          if (needDisabled) {
            this.dataPassengers.data[element]['infantTkt'] = true;
          } else {
            this.dataPassengers.data[element]['infantTkt'] = false;
          }
        }
      }
    }

  findCouponBySourceDestination(coupons: Array<any>, src: string, dst: string) {
      return coupons.find(coupon => coupon.source == src && coupon.destination == dst);
    }

    isAllSelectedPassengers() {
    const numSelectedPassengers = this.selectionPassengers.selected.length;
    const numRowsPassengers = this.dataPassengers.data.length;
    let numInvalidPassengers = 0;

    for (let i = 0; i < numRowsPassengers; i++) {
      if (this.dataPassengers.data[i]['invalidTkt'] || this.dataPassengers.data[i]['allCouponsInvalid']) {
        numInvalidPassengers++;
      }
    }

    return numSelectedPassengers === numRowsPassengers - numInvalidPassengers;
  }

  masterTogglePassengers() {
    this.segmentsWithOneCouponInvalidQuantity = 0;

    if (this.isAllSelectedPassengers()) {
        this.clearPassengerSelections();
    } else {
        this.selectValidPassengersAndCoupons();
    }
  }

  clearPassengerSelections() {
      this.setInfantTktPropertie(true);

      this.dataSegments.data.forEach(element => {
          element['selectedCoupons'] = [];
          element['invalidCoupons'] = 0;
      });

      this.selectionPassengers.clear();
  }

  selectValidPassengersAndCoupons() {
      this.setInfantTktPropertie(false);
      
      this.dataPassengers.data.forEach((row: any) => {
          if (!row.invalidTkt && !row.allCouponsInvalid) {
              this.selectCouponsForPassenger(row);
              this.selectionPassengers.select(row);
          }
      });

      this.updateSegmentSelectionState();

      this.dataSegments.data.forEach(element => {
          if (this.validSegmentStatusList.includes(element['status']) && element['invalidCoupons'] > 0) {
              this.segmentsWithOneCouponInvalidQuantity++;
          }
      });
  }

  selectCouponsForPassenger(row: any) {
    this.dataSegments.data.forEach(element => {
        let coupon = this.findCouponBySourceDestination(
            row.coupons, element['source'], element['destination']
        );

        if (coupon) {
            element['selectedCoupons'].push(coupon);

            if (this.invalidCouponStatusList.includes(coupon['status'])) {
                element['invalidCoupons']++;
            }
        }
    });
  }

  isAllSelectedSegments() {
    const numSelectedSegments = this.selectionSegments.selected.length;
    const numRowsSegments = this.dataSegments.data.length;
    let numInvalidSegments = 0;

    for (let i = 0; i < numRowsSegments; i++) {
      if ((!this.validSegmentStatusList.includes(this.dataSegments.data[i]['status']) || this.dataSegments.data[i]['invalidCoupons'] > 0)) {
        numInvalidSegments++;
      } 
    }

    return numSelectedSegments === numRowsSegments - numInvalidSegments;
  }

  masterToggleSegments() {
    if (this.isAllSelectedSegments()) {
      this.selectionSegments.clear();
    } else {
      this.dataSegments.data.forEach((row: any) => {
        if (this.validSegmentStatusList.includes(row.status) && row.invalidCoupons === 0) {
          this.selectionSegments.select(row);
        }
      });
    }
  }

  isAllSelectedCoupons() {
    const numSelectedCoupons = this.selectionCoupons.selected.length;
    const numRowsCoupons = this.dataVoucher.data.length;

    return numSelectedCoupons === (numRowsCoupons - this.invalidCouponsQuantity);
  }

  masterToggleCoupons() {
    this.isAllSelectedCoupons() ?
      this.selectionCoupons.clear() :
      this.dataVoucher.data.forEach((row: any) => {
        if (!this.invalidCouponStatusList.includes(row.status)) {
          this.selectionCoupons.select(row);
        }
      });
  }

  isSelectPassengersPost(): boolean{
    if (this.selectionPassengers.selected.length === 0){
      return false;
    }

    return true;
  }

  isSelectSegmentsPost(): boolean{
    if (this.selectionSegments.selected.length === 0){
      return false;
    }
    return true;
  }

  isSelectCouponsPost(): boolean{
    if (this.selectionCoupons.selected.length === 0){
      return false;
    }
    
    return true;
  }

  nextStep(){
    this.loadPassengers();
  }

  loadPassengers() {
    if (this.isSearchingPnr()){
      this.selectionPassengers.selected.forEach((passenger: any ) => {
        const exists = this.passengerArray.controls.some((passengerGroup: FormGroup) => 
          passengerGroup.controls['name'].value === (passenger.firstName.replace('MR', '') + ' ' + passenger.lastName) &&
          passengerGroup.controls['tktNumber']?.value === passenger.ticketNumber
      );

        if (!exists){
          const passengerGroup = this.fb.group({
            name: [{value: passenger.firstName.replace('MR', '')  + ' ' +  passenger.lastName, disabled: true}],
            cost: [{value: '', disabled: false}],
            tktNumber: passenger.ticketNumber
          });
          
          this.passengerArray.push(passengerGroup);
        }
      });
    } 
  }

  get passengerArray() {
    return this.passengersForm.get('passengers') as FormArray;
  }

  selectWaiverType() {    
    this.selectedType = this.waiverTypes.find(type => type.code === this.selectedWaiverType);
  }

  isCurrencySelected(){
    return this.selectedCurrency !== '';
  }

  updateCostControlState() {
    if (this.isCurrencySelected()) { 
      this.convertCurrency();
    }
  }

  disableAllCostControls(){
    this.passengerArray.controls.forEach(control => {
      const cost = (control as FormGroup).controls['cost'];
      cost.disable();
    });
  }

  enableAllCostControls(){
    this.passengerArray.controls.forEach(control => {
      const cost = (control as FormGroup).controls['cost'];
      cost.enable();
    })
  }

  updateWaiverValue(){
    let finalValue = this.calculateFinalValue();
    finalValue = isNaN(finalValue) ? 0.00 : finalValue;

    this.waiverTotalValue = finalValue;
    this.waiverUsdTotalValue = this.selectedCurrency === 'USD' ? this.waiverTotalValue : this.calculateWithCurrencyChange(finalValue, this.currencyChange);
  }

 
  convertCurrency(){
    let finalValue = this.calculateFinalValue();
    this.waiverTotalValue = finalValue;
    if (this.selectedCurrency === 'USD'){
      this.waiverUsdTotalValue = finalValue;
    } else {
      this.isConvertingCurrency = true;

      let currencyConverter: CurrencyConverter = {
        sourceCurrencyIsoCode: 'USD',
        destinationCurrencyIsoCode: this.selectedCurrency,
        amount: finalValue
      };

      this.disableAllCostControls();
      this.waiverPostsaleService.changeCurrencyWaiverAmount(currencyConverter)
      .subscribe({
        next: (data: any) => {
          this.currencyChange = data.amount;
          this.waiverUsdTotalValue = this.calculateWithCurrencyChange(finalValue, this.currencyChange);
          this.isConvertingCurrency = false;
          this.enableAllCostControls();
        }, 
        error: (err) => {
          this.selectedCurrency = 'USD';
          this.waiverUsdTotalValue = this.calculateWithCurrencyChange(finalValue, 1);
          this.isConvertingCurrency = false;
          this.enableAllCostControls();
        }
      });
    }
  }

  calculateFinalValue(){
    let finalValue: number = 0.00;
    this.passengerArray.controls.forEach(control => {
      const cost = (control as FormGroup).controls['cost'];
      let costValue =  cost.value.replace(/\./g, '').replace(',', '.');
      finalValue += isNaN(parseFloat(costValue)) ? 0.00 : parseFloat(costValue);
    });

    return isNaN(finalValue) ? 0.00 : finalValue;
  }

  loadBudget(){
    if (this.recordLocatorForm.valid){
      this.isLoadingAgencyBudget = true;
      this.sessionInfoService.getSessionInfoAsPromise()
      .then(
        sessionInfo => {
          this.agencyGroupName = sessionInfo.agency;
          this.getAmountAvailable(sessionInfo.matrix_id);
        }
      )
      .catch(
        error => {
          console.log('error', error);
        }
      );
    }
  }

  private getAmountAvailable(agencyGroupId) {
      this.budgetService.getMonthlyAfterSalesBudgetByGroup(agencyGroupId).subscribe({
        next: data => {
          this.showAgencyBudget = data?.agencyGroup?.showBudget;

          this.dataAgencyBudget.data = [{
                agencyGroupName: this.agencyGroupName,
                budgetTotal: data.assignedAmount.toFixed(2),
                budgetBalance: (data.assignedAmount - data.busyAmount).toFixed(2),
                budgetUsed: data.busyAmount.toFixed(2),
            }];

          this.agencyGroupBudget = (data.assignedAmount - data.busyAmount)
          
          if (this.agencyGroupBudget <= 0){
            const msg = 'Você não possui saldo suficiente para a transação.Não será possível continuar com seu pedido.';
            this.pnrOrTktNotFoundAlert(msg);
            this.isAgencyBudgetValid = false;
            this.reloadLocation();
          } else {
            this.searchPnrOrTkt();
            this.isAgencyBudgetValid = true;
          }
          this.isLoadingAgencyBudget = false;
        },
        error: (error: HttpErrorResponse) => {
          const msg = 'Você não possui saldo suficiente para a transação.Não será possível continuar com seu pedido.';
          this.pnrOrTktNotFoundAlert(msg);
          this.isAgencyBudgetValid = false;
          this.isLoadingAgencyBudget = false;
          this.router.navigateByUrl('/agency/home');
        }
    });
  }

  isLoading() {
    return this.loadingReservationInfo ||
      (this.validPostPNR && this.passengerTicketRequestsCompleted !== this.dataPassengers.data.length);
  }

  openTermsModal(){
    const dialogRef = this.dialog.open(WaiverLatamTermsComponent, {
      height: '480px',
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.termsAcceptance = true;
      }
    })
  }

  reloadLocation() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  summaryInfoWaiver(): Waiver {
    if (this.dataReservation.data.length !== 0) {
        this.populateWaiverFromReservation();
    } else if (this.dataTkt.data.length !== 0) {
        this.populateWaiverFromTicket();
    }

    this.setCommonWaiverFields();
    this.waiver.files = this.initializeFiles();

    return this.waiver;
  }

  populateWaiverFromReservation() {
      this.waiver.pnr = this.dataReservation.data[0]['pnr'];
      this.waiver.booking = this.dataReservation.data[0]['booking'];
      this.waiver.passengers = this.selectionPassengers.selected;
      this.waiver.segments = this.sortSegments(this.selectionSegments.selected);
      this.waiver.document = 'pnr';
      this.waiver.documentNumber = this.dataReservation.data[0]['pnr']['pnrNumber'];
      this.waiver.documentCreationDate = this.dataReservation.data[0]['pnr']['createDateTime'];
      this.waiver.documentPos = this.dataReservation.data[0]['booking']['pos'];
      this.waiver.numberOfPassenger = this.dataPassengers.data.length;
      this.waiver.valorization = this.waiverUsdTotalValue.toString();
  }

  populateWaiverFromTicket() {
      this.waiver.tkt = this.dataTkt.data[0]['tkt'];
      this.waiver.booking = this.dataTkt.data[0]['booking'];
      this.waiver.fare = this.dataTkt.data[0]['fare'];
      this.waiver.coupons = this.sortCoupons(this.selectionCoupons.selected);
      this.waiver.document = 'tkt';
      this.waiver.documentNumber = this.dataTkt.data[0]['tkt']['tktNumber'];
      this.waiver.documentCreationDate = this.dataTkt.data[0]['tkt']['createDateTime'];
      this.waiver.documentPos = this.dataTkt.data[0]['issuer']['pos'];
      this.waiver.documentTktPnrReference = this.dataTkt.data[0]['tkt']['pnr'];
      this.waiver.passengers[0] = this.dataTkt.data[0]['passenger'];
      this.waiver.remark = this.dataTkt.data[0]['remark']['text'];
      this.waiver.numberOfPassenger = this.waiver.passengers.length;
      this.waiver.valorization = this.waiverUsdTotalValue.toString();
  }

  setCommonWaiverFields() {
      this.waiver.aditionalComment = this.aditionalComment;
      this.waiver.type = this.selectedType;
      this.waiver.motive = this.selectedMotif;
      this.waiver.category = this.selectedType.waiverCategory;
  }

  sortSegments(segments) {
      return segments.sort(
          (a, b) => (a.segmentNumber > b.segmentNumber) ? 1 : ((b.segmentNumber > a.segmentNumber) ? -1 : 0)
      );
  }

  sortCoupons(coupons) {
      return coupons.sort(
          (a, b) => (a.couponNumber > b.couponNumber) ? 1 : ((b.couponNumber > a.couponNumber) ? -1 : 0)
      );
  }

  initializeFiles() {
      return [];
  }

  validatePresaveConditions(){
    this.showWaiverTypeErrors = this.selectedWaiverType === '';
    
    return !this.showWaiverTypeErrors && this.isValorizationValid();
  }

  saveWaiverAutoApproved(): void{
    if (this.validatePresaveConditions()){
      this.showWaiverTypeErrors = false;
      this.loadingSaveWaiver = true;
      this.waiver = this.summaryInfoWaiver();

      if (parseFloat(this.waiver.valorization) < this.agencyGroupBudget){
        this.waiverPostsaleService.saveWaiverAutoApproved(this.waiver)
          .subscribe({
            next: (data: Waiver) => {
              this.dataWaiverResult.data = [data];
              this.loadingSaveWaiver = false;
              this.budgetService.triggerReload();
              Swal.fire({
                title: 'Waiver Aprobado',
                text: `Waiver (folio) ${data.folio}`,
                icon: "success"
              });
            },
            error: (error: HttpErrorResponse) => {
              const ex: Exception = new Exception('', this.getErrorMessage(error));
              this.messageService.showErrorMessage(ex, 'Aceptar');
              this.loadingSaveWaiver = false;
              this.router.navigateByUrl('/agency/home');

            }
          });
      } else {
        this.showWaiverTypeErrors = false;
        this.loadingSaveWaiver = false;
        const ex: Exception = new Exception('', 'El valor de la solicitud supera el presupuesto disponible.');
        this.messageService.showErrorMessage(ex, 'Aceptar');
      }
    }
  }

  getErrorMessage(error: HttpErrorResponse): string{
    let errorMessage: string = '';
    if (error.message === 'Error processing budget decrement'){
      errorMessage = 'No hay presupuesto disponible para la solicitud';
    } else if (error.message === 'Not enough budget in WIS for waiver value'){
      errorMessage = 'No hay presupuesto disponible en WIS para la solicitud';
    } else {
      errorMessage = 'Ha ocurrido un error en la solicitud de waiver.'
    }

    return errorMessage;
  }

  areConditionsAccepted(): boolean{
    if (!this.understandingAcceptance || !this.termsAcceptance){
      this.canSubmitWaiver = false;
    }

    return this.understandingAcceptance && this.termsAcceptance;
  }

  isValorizationValid(): boolean{
    return this.calculateFinalValue()>0 && this.calculateFinalValue != null;
  }

  letSubmit(): void {
    if (this.areConditionsAccepted()){
      this.canSubmitWaiver = true;
      this.showTermsError = false;
    } else {
      this.canSubmitWaiver = false;
      this.showTermsError = true;
    }
  }
  
  calculateWithCurrencyChange(originalAmount: number, currencyChange){
    const result: number = originalAmount / currencyChange;
    return Math.round(result * 100.0) / 100.0;
  }
  
  stepperBack(step: number){
    if (step == 1){
      this.cleanData();
      this.checkIfFirstStepIsCompleted();
    }
    if (step == 2){
      this.passengerArray.clear();
    }
  }

  checkIfFirstStepIsCompleted(){
    const interval = setInterval(() => {
      if ((this.validPostPNR && this.passengerTicketRequestsCompleted == this.dataPassengers.data.length) && !this.loadingReservationInfo){
        this.stepped = true;
        clearInterval(interval);
      }
    }, 1000); 
  }

  newRequest(){
    this.router.navigate([this.router.url]).then(() => {
      this.cleanRecordLocator();
      this.cleanData();
    });
  }

  cleanRecordLocator(){
    this.recordLocatorForm.get('pnrValue').reset();
    this.recordLocatorForm.get('tktValue').reset();
  }

  getTypes() {
    const marketId: any = this.sessionInfoService.getSessionInfo().marketId;
    this.waiverPostsaleService.getTypesByMarket(marketId).subscribe({
      next: data => {
        this.waiverTypes = data.filter(type => type.name.includes('Waiver:'));
      },
      error: (error: HttpErrorResponse) => {
        console.log('error', error);
      }
    });
  }

  isWaiverAvailable(code: string){
    return this.waiverTypes ? this.waiverTypes.some(type => type.code === code) : false;
  }

  finalStep(e: any){
    this.correctionInfo = e;
    this.stepper.next();
    this.confirmCorrection = true;
  }

  resetStepper() {
    this.stepper.reset();    

    this.cleanRecordLocator();
    this.validPostPNR = false; 
    this.loadingReservationInfo = false; 
    this.confirmCorrection = false; 
    this.passenger = [];
    this.segments = [];
    this.remarks = [];
    this.pnrValue = '';
}

}
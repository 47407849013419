<div class="content" >
  <div class="container-img">
    <div class="arrayLeft">
      <button mat-icon-button class="return-button" (click)="onBackClick()"> 
        <img  src="assets/images/correct-name/caretLeft.svg" />
        <span id="buttonBack">
          {{ 'button.back' | translate }}
        </span>
      </button>           
    </div>
  </div>

  <div class="name-success">
    <div class="center-succes">
      <img  src="assets/images/correct-name/changeNameSuccessful.svg"       />     
      <span>
        {{ 'correctName.nameCarried' | translate }}
      </span>
      <span>
        {{ 'correctName.succes' | translate }}
      </span>
    </div>
    
  </div> 
  <div class="center-card">   
    <div class="info-container">       
              <div class="image-center">          
                <div class="check-confirm">               
                  
                    <img  src="assets/images/correct-name/lineAndCross.svg" />
                
                  <div class="center-text">                
                    <span id="name">
                      {{ 'correctName.withErrors' | translate }}
                      <br>
                    </span> 
                    <span id="fullName">
                    {{ oldFullName}}
                    </span> 
                    <span>  </span>
                  </div>                
                                    
                </div>    
                <div class="check-confirm">
                  <img  src="assets/images/correct-name/check.svg" />
                  <div class="center-text"> 
                    <span id="name" > 
                      {{ 'correctName.correctNames' | translate }}
                      <br>
                    </span>
                    <span id="fullName">
                      {{ newFullName}}
                    </span> 
                  </div>                   
                </div>   
                <div class="linea">
                  <hr id="linea">  
                </div>  
                              
              </div>                         
                
             
              <!-- salto de linea-->  
              
                  <div class="text-info">               
                
                    <div class="center-text">                
                      <span id="name">
                        {{ 'correctName.changeName' | translate }}
                        <br>
                      </span> 
                      <span id="fullName">
                        {{motive | translate}}
                      </span> 
                      <span>  </span>
                    </div>                
                                    
                  </div>
                  <div class="text-info">             
                
                    <div class="center-text">                
                      <span id="name">
                        {{ 'correctName.modification' | translate }}
                        <br>
                      </span> 
                      <span id="fullName">
                        {{ date | dateFormat: 'DD-MMM-YY' | uppercase | dashWhenEmpty }}
                      </span> 
                      <span>  </span>
                    </div>                
                                    
                  </div>
              


                        
     </div>  
  </div>     
</div>

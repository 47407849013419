import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation, LOCALE_ID } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';

import { BusinessException, Exception, SystemException } from '../../../models/Exception.model';
import { DownloadAgencyReportCSV } from '../../../models/download-agency-report-CSV.model';
import { Profile } from '../../../models/profile.model';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';

import { AgencyReportService } from '../../../services/agency-report.service';
import { MessageService } from '../../../services/message.service';
import { ProfilingService } from '../../../services/profiling.service';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MessageErrorService } from '../../../services/message-error.service';


@Component({
  selector: 'app-agency-report',
  templateUrl: './agency-report.component.html',
  styleUrls: ['./agency-report.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AgencyReportComponent implements OnInit {
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_WAIVERS, SidebarMenuModel.SUBMENU_AGENCY_REPORT);
  currentDate: Date = new Date();
  startDate: Moment;
  endDate: Moment;
  diffDays: number;
  report: DownloadAgencyReportCSV[] = [];
  reportFilename: string;
  loading: boolean = false;
  reportHeaders: any = [
    { label: 'Id', key: 'id' },
    { label: 'Status', key: 'status' },
    { label: 'Request Day/Hour', key: 'creationDate' },
    { label: 'Status Day/Hour', key: 'statusDate' },
    { label: 'PNR / TKT', key: 'documentNumber' },
    { label: 'Requested by', key: 'user' },
    { label: 'Flight Date', key: 'departureDate' },
    { label: 'Type', key: 'type' },
    { label: 'Reason', key: 'motive' },
    { label: 'Remarks', key: 'aditionalComment' },
    { label: 'Status Remarks', key: 'statusComment' },
    { label: 'Agency PNR', key: 'pnrAgency' },
    { label: 'Denial Reason', key: 'rejectionMotive' },
    { label: 'Waiver Record ', key: 'folio' },
    { label: 'Valorization', key: 'valorization' }
  ];
  profiles = new Profile();

  constructor(
    @Inject(LOCALE_ID) locale: string,
    public adapter: DateAdapter<any>,
    private agencyReportService: AgencyReportService,
    private messageService: MessageService,
    private profilesService: ProfilingService,
    private messageErrorService: MessageErrorService,
    private sidebarShareDataService: SidebarShareDataService,
    private translateService: TranslateService) {
      this.adapter.setLocale(locale);
  }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
  }

  startDateChanged(startDate: MatDatepickerInputEvent<Moment>) {
    if (this.endDate) {
      if (startDate) {
        this.diffDays = (this.endDate.toDate().getTime() - startDate.value.toDate().getTime()) / (24 * 60 * 60 * 1000);
        if (this.diffDays > 365) {
          this.endDate = this.adapter.addCalendarYears(startDate.value, 1);
        }
      }
      this.loading = true;
      this.agencyReportService.getWaiversBetweenDates(startDate.value.toDate(), this.endDate.toDate()).subscribe({
        next: data => {
          this.report = data;
          if (this.report.length === 0) {
            this.messageService.showWarningMessage(
              this.translateService.instant('button.confirm'),
              this.translateService.instant('notFound.waivers')
            );
          } else {
            this.reportFilename = 'waiver_report_' +
              this.agencyReportService.parseDateToDateString(startDate.value.toDate()) + '_' +
              this.agencyReportService.parseDateToDateString(this.endDate.toDate()) + '.csv';
          }
          this.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.messageErrorService.sendError(error, 'error.generateReport');

          this.loading = false;
          console.log('error', error);
        }
    });
    }
  }

  endDateChanged(endDate: MatDatepickerInputEvent<Moment>) {
    if (this.startDate) {
      this.loading = true;
      this.agencyReportService.getWaiversBetweenDates(this.startDate.toDate(), endDate.value.toDate()).subscribe({
        next: data => {
          this.report = data;
          if (this.report.length === 0) {
            this.messageService.showWarningMessage(
              this.translateService.instant('button.confirm'),
              this.translateService.instant('notFound.waivers')
            );
          } else {
            this.reportFilename = 'waiver_report_' +
              this.agencyReportService.parseDateToDateString(this.startDate.toDate()) + '_' +
              this.agencyReportService.parseDateToDateString(endDate.value.toDate()) + '.csv';
          }
          this.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.messageErrorService.sendError(error, 'error.generateReport');

          this.loading = false;
          console.log('error', error);
        }
    });
    }
  }

  setCalendarLanguage() {
    this.adapter.setLocale(localStorage.getItem('language'));
  }
}

import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-waiver-latam-terms',
  templateUrl: './waiver-latam-terms.component.html',
  styleUrls: ['./waiver-latam-terms.component.css']
})
export class WaiverLatamTermsComponent {
  readonly dialogRef = inject(MatDialogRef<WaiverLatamTermsComponent>);

  acceptTermsAndConditions(): void {
    this.dialogRef.close(true);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {MatExpansionModule} from '@angular/material/expansion';
import { CsvModule } from '@ctrl/ngx-csv';

import { ShareModule } from '../share-module/share.module';

import { AgencyReportComponent } from './agency-report/agency-report.component';
import { CreateWaiverComponent } from './create-waiver/create-waiver.component';
import { DetailWaiverComponent } from './detail-waiver/detail-waiver.component';
import { DetailListWaiverAgencyComponent } from './detail-list-waiver-agency/detail-list-waiver-agency.component';
import { DetailListWaiverLatamComponent } from './detail-list-waiver-latam/detail-list-waiver-latam.component';
import { LatamReportComponent } from './latam-report/latam-report.component';
import { ListWaiverAgencyComponent } from './list-waiver-agency/list-waiver-agency.component';
import { ListWaiverAgentComponent } from './list-waiver-agent/list-waiver-agent.component';
import { ListWaiverLatamComponent } from './list-waiver-latam/list-waiver-latam.component';

import { AgencyGroupService } from '../../services/agency-group.service';
import { AgencyReportService } from '../../services/agency-report.service';
import { BudgetService } from '../../services/budget.service';
import { FileStorageService } from '../../services/file-storage.service';
import { LatamReportService } from '../../services/latam-report.service';
import { MarketService } from './../../services/market.service';
import { WaiverPostsaleService } from '../../services/waiver-postsale.service';
import { ListWaiverOutsourcingComponent } from './list-waiver-outsourcing/list-waiver-outsourcing.component';
import { WaiverPostsaleOutsourcingService } from '../../services/waiver-postsale-outsourcing.service';
import { WaiverListTableComponent } from './list-waiver-outsourcing/waiver-list-table/waiver-list-table.component';
import { RejectListWaiversLatamComponent } from './reject-list-waivers-latam/reject-list-waivers-latam.component';
import { TermsConditionsModule } from '../terms-conditions-module/terms-conditions.module';


@NgModule({
    declarations: [
        AgencyReportComponent,
        CreateWaiverComponent,
        DetailListWaiverAgencyComponent,
        DetailListWaiverLatamComponent,
        DetailWaiverComponent,
        LatamReportComponent,
        ListWaiverAgencyComponent,
        ListWaiverAgentComponent,
        ListWaiverLatamComponent,
        ListWaiverOutsourcingComponent,
        WaiverListTableComponent,
        RejectListWaiversLatamComponent
    ],
    imports: [
        CommonModule,
        CsvModule,
        FormsModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatExpansionModule,
        MatTooltipModule,
        ReactiveFormsModule,
        ShareModule,
        TermsConditionsModule
    ],
    providers: [
        AgencyGroupService,
        AgencyReportService,
        BudgetService,
        FileStorageService,
        LatamReportService,
        MarketService,
        WaiverPostsaleService,
        WaiverPostsaleOutsourcingService,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter
        }
    ]
})
export class WaiverPostsaleModule { }

<div>
  <div *ngIf="loadingAgencyGroupBudget" class="loader loading">
    <div class="loading-div">
      <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
      <span class="loading-span">{{ 'loading' | translate }}</span>
    </div>
  </div>

  <!-- Informacion del PPTO de Agencia Primeros 6 meses -->
  <ng-container *ngIf="agencyGroupBudgetLoaded && !loadingAgencyGroupBudget">
    <mat-table #table [dataSource]="firstSemesterBudgetGroup" class="table-budget">
      <!-- Position -->
      <ng-container matColumnDef="position">
        <mat-header-cell *matHeaderCellDef>{{ 'field.currentYear' | translate }}</mat-header-cell>
        <mat-cell class="item-table-budget" *matCellDef="let element">{{ element.rowName | translateBudgetRowName }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ene">
        <mat-header-cell *matHeaderCellDef>{{ 'months.ene' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="input-content" *ngIf="element.input">
            <input [(ngModel)]="element.amount[0]" type="number" min="0" placeholder="$USD 0"
              class="form-control input-control" name="januaryInput" #januaryInput="ngModel" disabled />
          </div>
          <span *ngIf="!element.input">{{ element.amount[0] | number: '1.2-2':'en-US' }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="feb">
        <mat-header-cell *matHeaderCellDef>{{ 'months.feb' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="input-content" *ngIf="element.input">
            <input [(ngModel)]="element.amount[1]" type="number" min="0" placeholder="$USD 0"
              class="form-control input-control" name="februaryInput" #februaryInput="ngModel" />
          </div>
          <span *ngIf="!element.input">{{ element.amount[1] | number: '1.2-2':'en-US' }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="mar">
        <mat-header-cell *matHeaderCellDef>{{ 'months.mar' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="input-content" *ngIf="element.input">
            <input [(ngModel)]="element.amount[2]" type="number" min="0" placeholder="$USD 0"
              class="form-control input-control" name="marchInput" #marchInput="ngModel" />
          </div>
          <span *ngIf="!element.input">{{ element.amount[2] | number: '1.2-2':'en-US' }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="abr">
        <mat-header-cell *matHeaderCellDef>{{ 'months.abr' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="input-content" *ngIf="element.input">
            <input [(ngModel)]="element.amount[3]" type="number" min="0" placeholder="$USD 0"
              class="form-control input-control" name="aprilInput" #aprilInput="ngModel" />
            <mat-icon matSuffix class="icon-edit-input">edit</mat-icon>
          </div>
          <span *ngIf="!element.input">{{ element.amount[3] | number: '1.2-2':'en-US' }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="may">
        <mat-header-cell *matHeaderCellDef>{{ 'months.may' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="input-content" *ngIf="element.input">
            <input [(ngModel)]="element.amount[4]" type="number" min="0" placeholder="$USD 0"
              class="form-control input-control" name="mayInput" #mayInput="ngModel" />
            <mat-icon matSuffix class="icon-edit-input">edit</mat-icon>
          </div>
          <span *ngIf="!element.input">{{ element.amount[4] | number: '1.2-2':'en-US' }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="jun">
        <mat-header-cell *matHeaderCellDef>{{ 'months.jun' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="input-content" *ngIf="element.input">
            <input [(ngModel)]="element.amount[5]" type="number" min="0" placeholder="$USD 0"
              class="form-control input-control" name="juneInput" #juneInput="ngModel" />
            <mat-icon matSuffix class="icon-edit-input">edit</mat-icon>
          </div>
          <span *ngIf="!element.input">{{ element.amount[5] | number: '1.2-2':'en-US' }}</span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="firstSemesterColumns" class="budget-row"></mat-header-row>
      <mat-row *matRowDef="let row; columns: firstSemesterColumns;" class="budget-row"></mat-row>
    </mat-table>

    <!-- Informacion del PPTO de Agencia ultimos 6 meses -->
    <mat-table #table [dataSource]="secondSemesterBudgetGroup" class="table-budget">
      <ng-container matColumnDef="position">
        <mat-header-cell *matHeaderCellDef>{{ 'field.currentYear' | translate }}</mat-header-cell>
        <mat-cell class="item-table-budget" *matCellDef="let element">{{ element.rowName | translateBudgetRowName }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="jul">
        <mat-header-cell *matHeaderCellDef>{{ 'months.jul' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="input-content" *ngIf="element.input">
            <input [(ngModel)]="element.amount[6]" type="number" min="0" placeholder="$USD 0"
              class="form-control input-control" name="julyInput" #julyInput="ngModel" />
            <mat-icon matSuffix class="icon-edit-input">edit</mat-icon>
          </div>
          <span *ngIf="!element.input">{{element.amount[6] | number: '1.2-2':'en-US'}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ago">
        <mat-header-cell *matHeaderCellDef>{{ 'months.ago' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="input-content" *ngIf="element.input">
            <input [(ngModel)]="element.amount[7]" type="number" min="0" placeholder="$USD 0"
              class="form-control input-control" name="augustInput" #augustInput="ngModel" />
            <mat-icon matSuffix class="icon-edit-input">edit</mat-icon>
          </div>
          <span *ngIf="!element.input">{{element.amount[7] | number: '1.2-2':'en-US'}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sep">
        <mat-header-cell *matHeaderCellDef>{{ 'months.sep' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="input-content" *ngIf="element.input">
            <input [(ngModel)]="element.amount[8]" type="number" min="0" placeholder="$USD 0"
              class="form-control input-control" name="septemberInput" #septemberInput="ngModel" />
            <mat-icon matSuffix class="icon-edit-input">edit</mat-icon>
          </div>
          <span *ngIf="!element.input">{{element.amount[8] | number: '1.2-2':'en-US'}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="oct">
        <mat-header-cell *matHeaderCellDef>{{ 'months.oct' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="input-content" *ngIf="element.input">
            <input [(ngModel)]="element.amount[9]" type="number" min="0" placeholder="$USD 0"
              class="form-control input-control" name="octoberInput" #octoberInput="ngModel" />
            <mat-icon matSuffix class="icon-edit-input">edit</mat-icon>
          </div>
          <span *ngIf="!element.input">{{element.amount[9] | number: '1.2-2':'en-US'}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="nov">
        <mat-header-cell *matHeaderCellDef>{{ 'months.nov' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="input-content" *ngIf="element.input">
            <input [(ngModel)]="element.amount[10]" type="number" min="0" placeholder="$USD 0"
              class="form-control input-control" name="novemberInput" #novemberInput="ngModel" />
            <mat-icon matSuffix class="icon-edit-input">edit</mat-icon>
          </div>
          <span *ngIf="!element.input">{{element.amount[10] | number: '1.2-2':'en-US'}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="dic">
        <mat-header-cell *matHeaderCellDef>{{ 'months.dic' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="input-content" *ngIf="element.input">
            <input [(ngModel)]="element.amount[11]" type="number" min="0" placeholder="$USD 0"
              class="form-control input-control" name="decemberInput" #decemberInput="ngModel" />
            <mat-icon matSuffix class="icon-edit-input">edit</mat-icon>
          </div>
          <span *ngIf="!element.input">{{element.amount[11] | number: '1.2-2':'en-US'}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="total">
        <mat-header-cell class="total-item" *matHeaderCellDef>{{ 'field.anualTotal' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="item-table-budget">
          {{ element.total | number: '1.2-2':'en-US' | formatNumber }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="secondSemesterColumns" class="budget-row"></mat-header-row>
      <mat-row *matRowDef="let row; columns: secondSemesterColumns;" class="budget-row"></mat-row>
    </mat-table>
  </ng-container>
  <!-- 
                MCPMLT-5049 https://projectmanagement.appslatam.com/browse/MCPMLT-5049

                En una investigacion previa con dante, los group agency tienen un atributo en la bd para poder mostrar o no el budget,
                y esta agencia esta desactivado para mostrar el budget, se lo comente a piero pero el no puede activarla, siendo que 
                tiene un perfil con todos los privilegios.

                El problema estaria aca, ya que este es el texto que se ocupa en la casilla para activar la visualizacion del
                budget. 

              -->
  <div class="row mt-3 ml-3 mb-3 mr-3" *ngIf="agencyGroupBudgetLoaded && !loadingAgencyGroupBudget">
    <div class="col text-left">
      <mat-checkbox [(ngModel)]="initialShowBudget" disabled>{{ 'field.showAgencyBudget' | translate }}</mat-checkbox>
    </div>
  </div>
</div>
<ng-container *ngIf="infoUser$ | async as infoUser">
  <div class="mb-3">
    <div *ngIf="loading" class="loading">
      <div class="loading-div">
        <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
        <span class="loading-span">{{ 'loading' | translate }}</span>
      </div>
    </div>

    <ng-container *ngIf="!loading">
      <mat-table #table [dataSource]="dataSource" matSort class="table-user">
        <ng-container matColumnDef="association">
          <mat-header-cell id="association" *matHeaderCellDef mat-sort-header>{{ 'field.association' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.association | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell id="name" *matHeaderCellDef mat-sort-header>{{ 'field.name' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.name | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastname">
          <mat-header-cell id="lastname" *matHeaderCellDef mat-sort-header>{{ 'field.surname' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.lastname | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
          <mat-header-cell id="email" *matHeaderCellDef mat-sort-header>{{ 'field.email' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.email | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="position">
          <mat-header-cell id="position" *matHeaderCellDef mat-sort-header>{{ 'field.position' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.position | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="role">
          <mat-header-cell id="role" *matHeaderCellDef mat-sort-header>{{ 'field.role' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span *ngFor="let role of element.roles; let i = index">
              {{ role.name | dashWhenEmpty }}<span *ngIf="i !== element.roles.length - 1">,&nbsp;</span>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell id="actions" *matHeaderCellDef>{{ 'button.actions' | translate }}</mat-header-cell>

          <mat-cell *matCellDef="let element">
            <ng-container *ngIf="infoUser.isAgencyRoleGreaterThan(element.roles) || infoUser.isLatamSession()">
              <button mat-icon-button [matMenuTriggerFor]="menu" class="action-icon">
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #menu="matMenu">
                <button mat-menu-item
                  *ngIf="profiles.updateAgencyGroupUserManagementLT || profiles.updateAgencyGroupUserManagement || profiles.updateBranchOfficeUser"
                  class="btn-mat-menu" (click)="openEditUserDialog(element)">
                  <span class="item-mat-menu">{{ 'button.edit' | translate }}</span>
                </button>

                <button mat-menu-item
                  *ngIf="profiles.deleteAgencyGroupUserManagementLT || profiles.deleteAgencyGroupUserManagement || profiles.deleteBranchOfficeUser"
                  class="btn-mat-menu" (click)="showUserDeleteConfirmation(element)">
                  <span class="item-mat-menu">{{ 'button.delete' | translate }}</span>
                </button>
              </mat-menu>
            </ng-container>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="no-data-found">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell class="no-data-found-row" *matCellDef="let item">{{ 'notFound.noUsersFound' | translate }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="loading-data-error">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell class="loading-data-error-row" *matCellDef="let item">
            <span class="cdk-visually-hidden" #translatedTooltipMessage>{{ 'button.refresh' | translate }}</span>
            <button mat-icon-button (click)="refreshAgencyUsers()" class="refresh-spinner">
              <mat-icon [matTooltip]="translatedTooltipMessage.innerHTML" [matTooltipPosition]="'right'">refresh
              </mat-icon>
            </button>
            <br>
            <span>{{ 'error.loadingUsers' | translate }}</span>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" class="user-row"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (mouseover)="highlightRow($event, row)"
          (mouseout)="highlightRow($event, row)" [ngClass]="{ 'highlight': selectedRowIndex === row.id }"
          class="user-row"></mat-row>
        <mat-row *matRowDef="let row; columns: ['no-data-found']; when: noUsersDataFound"></mat-row>
        <mat-row *matRowDef="let row; columns: ['loading-data-error']; when: loadingUsersDataError"></mat-row>
      </mat-table>

      <ng-container *ngIf="usersDataFound && !loadUsersNoDataError">
        <mat-paginator [pageSizeOptions]="[10, 30, 50]" [showFirstLastButtons]="false" (page)="pageEvent($event)"
          class="table-paginator">
        </mat-paginator>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
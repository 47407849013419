<div class="container">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="latam-sans breadcrumb-item">
            <a>{{ 'menu.latamManagement' | translate }}</a>
          </li>
          <li class="breadcrumb-item latam-sans active" aria-current="page">
            <a>{{ 'menu.feedback' | translate }}</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <span class="title" id="feedbackTitle">{{ 'menu.feedback' | translate }}</span>
    </div>
  </div>

  <hr>

  <div class="row">
    <div class="col">
      <span class="subtitle">{{ 'periodReport' | translate }}</span>
    </div>
  </div>

  <div class="row mt-4 justify-content-start">
    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
      <mat-form-field>
        <mat-label>{{ 'field.rangeFrom' | translate }}</mat-label>
        <input matInput id="startDateInput" name="startDate" #startDateInput="ngModel" readonly [matDatepicker]="startPicker" [max]="currentDate"
          [(ngModel)]="startDate" (dateChange)="startDateChanged($event)">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker (opened)="setCalendarLanguage()">
        </mat-datepicker>
      </mat-form-field>
    </div>

    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
      <mat-form-field>
        <mat-label>{{ 'field.rangeTo' | translate }}</mat-label>
        <input id="endDateInput" matInput [min]="startDate" [max]="currentDate" [matDatepicker]="endPicker" name="endDate" [(ngModel)]="endDate" #endDateInput="ngModel"
          readonly (dateChange)="endDateChanged($event)">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker (opened)="setCalendarLanguage()">
        </mat-datepicker>
      </mat-form-field>
    </div>

    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 offset-sm-6 offset-md-0 offset-lg-0 offset-xl-0">
      <a mat-button csvLink [delimiter]="';'" [filename]="reportFilename" [data]="feedbacks"
        [disabled]="!startDate || !endDate || feedbacks.length === 0 || loading"
        class="background-red mat-raised-button w-100">
        {{ 'button.generateReport' | translate }}
        <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
      </a>
    </div>
  </div>
</div>

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';

import { BusinessException, Exception, SystemException } from '../../../models/Exception.model';
import { Market } from '../../../models/market.model';
import { Profile } from '../../../models/profile.model';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';

import { MarketService } from './../../../services/market.service';
import { MessageService } from './../../../services/message.service';
import { ProfilingService } from '../../../services/profiling.service';
import { SessionInfoService } from '../../../services/session-info.service';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';
import { MessageErrorService } from '../../../services/message-error.service';


@Component({
  selector: 'app-waiver-automation',
  templateUrl: './waiver-automation.component.html',
  styleUrls: ['./waiver-automation.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WaiverAutomationComponent implements OnInit {
  loading: boolean = true;

  selectedPosRowIndex: number = -1;

  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['pos', 'automate'];
  profiles = new Profile();
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_LATAM, SidebarMenuModel.SUBMENU_WAIVER_AUTOMATION);

  constructor(
    private marketService: MarketService,
    private messageService: MessageService,
    private profilesService: ProfilingService,
    private messageErrorService: MessageErrorService,
    private sessionInfoService: SessionInfoService,
    private sidebarShareDataService: SidebarShareDataService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.getSessionMarkets();
  }

  getSessionMarkets() {
    this.loading = true;

    this.marketService.getSessionMarkets().subscribe(
      data => {
        this.dataSource.data = data;
        this.loading = false;
      },
      (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.loading');

        this.loading = false;
        console.log('error', error);
      }
    );
  }

  /**
   * method to save request in the database
   */
  save(): void {
    this.loading = true;

    this.sessionInfoService.getSessionInfoAsPromise()
      .then(
        session => {
          if (session.isLatamSession()) {
            this.marketService.saveMarkets(<Market[]>this.dataSource.data).subscribe(
              data => {
                this.messageService.showSuccessMessage(
                  this.translateService.instant('button.confirm'),
                  this.translateService.instant('popup.wellDone'),
                  this.translateService.instant('success.update')
                );

                this.getSessionMarkets();
                this.loading = false;
              },
              (error: HttpErrorResponse) => {
                this.messageErrorService.sendError(error, 'error.request');

                this.loading = false;
                console.log('error', error);
              }
            );
          } else {
            this.messageService.showErrorMessage(
              new Exception(SystemException, this.translateService.instant('forbidden.automation')),
              this.translateService.instant('button.confirm')
            );

            this.loading = false;
          }
        }
      )
      .catch(
        error => {
          console.log('error', error);
        }
      );
  }

  highlightRow(event: Event, rowIndex: number) {
    this.selectedPosRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  toggleSelectionRow(event: MouseEvent, row: Market) {
    let targetClassName: string = event.target['className'];

    if (!targetClassName.includes('mat-slide-toggle')) {
      row.waiverEnableAutoapprove = !row.waiverEnableAutoapprove;
    }
  }
}

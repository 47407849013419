import { Component } from '@angular/core';

@Component({
  selector: 'app-list-agency-header',
  templateUrl: './list-agency-header.component.html',
  styleUrls: ['./list-agency-header.component.css']
})
export class ListAgencyHeaderComponent {

}

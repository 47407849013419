import { Component } from '@angular/core';

@Component({
  selector: 'app-country-images',
  templateUrl: './country-images.component.html',
  styleUrls: ['./country-images.component.css']
})
export class CountryImagesComponent {

}

<div class="container">
  <app-list-agency-header></app-list-agency-header>

  <div class="row justify-content-between">
    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
      <button mat-raised-button *ngIf="(profiles.createPostSaleRequest || profiles.createWaiverRequest ) && sessionInfo.role != 'MANUAL_MTZ'" (click)="nuevaSolicitud()"
        class="background-red w-100">{{ 'button.newRequest' | translate }}</button>
    </div>

    <app-list-waiver-agent-budget class="col-sm-6 col-md-4 col-lg-3 col-xl-3"></app-list-waiver-agent-budget>
  </div>

  <!-- TAB -->
  <mat-card>
    <mat-card-content>
      <mat-tab-group #tabGroup>
        <mat-tab>
          <ng-template mat-tab-label>
            <span>{{ 'pendings' | translate }}</span>
          </ng-template>

          <app-waiver-list-postsale [displayedColumns]="displayedColumnsPending" [tab]="'pendientes'" [user]="'agency'"></app-waiver-list-postsale>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <span>{{ 'approveds' | translate }}</span>
          </ng-template>

          <app-waiver-list-postsale [displayedColumns]="displayedColumnsApproved" [tab]="'aprobados'" [user]="'agency'"></app-waiver-list-postsale>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <span>{{ 'denieds' | translate }}</span>
          </ng-template>

          <app-waiver-list-postsale [displayedColumns]="displayedColumnsRejected" [tab]="'rechazados'" [user]="'agency'"></app-waiver-list-postsale>
        </mat-tab>

        <mat-tab *ngIf="sessionInfo.role != 'MANUAL_MTZ'">
          <ng-template mat-tab-label>
            <mat-chip id="countBadge"></mat-chip>
            <span>{{ 'pendingsAgency' | translate }}</span>
          </ng-template>

          <app-waiver-list-postsale [displayedColumns]="displayedColumnsPendingAgency" [tab]="'pendientes-agencia'" [user]="'agency'"></app-waiver-list-postsale>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>

import { Component, Inject, LOCALE_ID, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


import { SidebarMenuModel } from '../../../models/SidebarMenu.model';


import { UntypedFormGroup } from '@angular/forms';
import { Profile } from '../../../models/profile.model';
import { ProfilingService } from '../../../services/profiling.service';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';


@Component({
  selector: 'app-list-waiver-agent',
  templateUrl: './list-waiver-agent.component.html',
  styleUrls: ['./list-waiver-agent.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ListWaiverAgentComponent implements OnInit {
  locale: string;

  displayedColumnsPending = ['id', 'type', 'motive', 'documentNumber', 'creationDate', 'documentCloserDepartureDate', 'actions'];
  displayedColumnsApproved = ['id', 'type', 'motive', 'documentNumber', 'statusDate', 'documentCloserDepartureDate', 'actions'];
  displayedColumnsRejected = ['id', 'type', 'motive', 'documentNumber', 'statusDate', 'documentCloserDepartureDate', 'actions'];
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_WAIVERS, SidebarMenuModel.SUBMENU_WAIVER_MANAGEMENT);

  public  formFilter: UntypedFormGroup;
  profiles = new Profile();

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private activatedRoute: ActivatedRoute,
    private profilesService: ProfilingService,
    private router: Router,
    private sidebarShareDataService: SidebarShareDataService) {
      this.locale = locale;
  }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);

  }

  /*
   * Metodo que redirecciona a la creación de nueva solicitud de waiver
   */
  nuevaSolicitud() {
    this.router.navigate(['../request'], { relativeTo: this.activatedRoute });
  }

}

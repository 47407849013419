<form [formGroup]="formG" class="reset-form">
  <div class="row justify-content-center mb-3">
    <div id="message" class="col-sm-8 col-md-8 col-lg-6 col-xl-6">
      <span class="subtitle">{{ 'login.resetPassTitle' | translate }}</span>

      <div
        [ngClass]="formG.controls['newP'].hasError('required') || formG.controls['newP'].hasError('hasLetter') ? 'text-error' : 'text-success'"
        class="mt-2 mb-1">
        <div class="icon-div">
          <i class="material-icons">
            {{ formG.controls['newP'].hasError('required') ||
              formG.controls['newP'].hasError('hasLetter') ? 'cancel' : 'check_circle' }}
          </i>
        </div>
        <div [innerHTML]="'login.contentLetter' | translate" class="icon-text"></div>
      </div>

      <div
        [ngClass]="formG.controls['newP'].hasError('required') || formG.controls['newP'].hasError('hasNumber') ? 'text-error' : 'text-success'"
        class="mb-1">
        <div class="icon-div">
          <i class="material-icons">
            {{ formG.controls['newP'].hasError('required') ||
              formG.controls['newP'].hasError('hasNumber') ? 'cancel' : 'check_circle' }}
          </i>
        </div>
        <div [innerHTML]="'login.contentNumber' | translate" class="icon-text"></div>
      </div>

      <div
        [ngClass]="formG.controls['newP'].hasError('required') || formG.controls['newP'].hasError('minlength') ? 'text-error' : 'text-success'"
        class="mb-1">
        <div class="icon-div">
          <i class="material-icons">
            {{ formG.controls['newP'].hasError('required') ||
              formG.controls['newP'].hasError('minlength') ? 'cancel' : 'check_circle' }}
          </i>
        </div>
        <div [innerHTML]="'login.minlength' | translate" class="icon-text"></div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-sm-8 col-md-8 col-lg-6 col-xl-6">
      <mat-form-field>
        <mat-label>{{ 'login.field.newPassword' | translate }}</mat-label>
        <input matInput id="newP" formControlName="newP" [type]="hide ? 'password' : 'text'" required>
        <mat-icon matSuffix (click)="hide = !hide"
          [ngClass]="formG.controls.newP.invalid && (formG.controls.newP.dirty || formG.controls.newP.touched) ? 'eye-icon-error' : 'eye-icon'">
          {{ hide ? 'visibility_off' : 'visibility' }}
        </mat-icon>
        <mat-error
          *ngIf="formG.controls.newP.invalid && (formG.controls.newP.dirty || formG.controls.newP.touched)">
          {{ 'login.error.invalidPass' | translate }}</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-sm-8 col-md-8 col-lg-6 col-xl-6">
      <mat-form-field>
        <mat-label>{{ 'login.field.confirmPassword' | translate }}</mat-label>
        <input matInput id="confirmP" formControlName="confirmP" [type]="hidePass ? 'password' : 'text'">
        <mat-icon matSuffix (click)="hidePass = !hidePass"
          [ngClass]="formG.controls.confirmP.invalid && (formG.controls.confirmP.dirty || formG.controls.confirmP.touched) ? 'eye-icon-error' : 'eye-icon'">
          {{ hidePass ? 'visibility_off' : 'visibility' }}</mat-icon>
        <mat-error
          *ngIf="formG.controls.confirmP.invalid && (formG.controls.confirmP.dirty || formG.controls.confirmP.touched) && formG.hasError('notSame')">
          Passwords do not match
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row justify-content-center mt-4">
    <div class="col-sm-8 col-md-8 col-lg-6 col-xl-6">
      <button [disabled]="!formG.valid || isDisabled" (click)="resetPass()"
        class="btn btn-Latam w-100">{{ 'button.save' | translate }}</button>
    </div>
  </div>
</form>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monthName',
  pure: false
})
export class MonthNamePipe implements PipeTransform {
  transform(value: number): string {
    const language = localStorage.getItem('language');
    let dash = '—';

    if (!value) {
      return dash;
    }

    let monthNames: string[] = [];

    switch (language) {
      case 'es':
        monthNames = [
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre'
        ];
        break;
      case 'pt':
        monthNames = [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro'
        ];
        break;
      default:
        monthNames = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ];
        break;
    }

    return monthNames[value];
  }
}


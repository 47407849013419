<div class="container">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="latam-sans breadcrumb-item"><a>{{ 'menu.portalBda' | translate }}</a></li>
          <li class="breadcrumb-item latam-sans active" aria-current="page">
            <a>{{ 'bda.reprocess.title' | translate }}</a></li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col">

      <span class="title"> {{'bda.reprocess.subTitleUpload' | translate}}</span>
    </div>

  </div>

  <hr>

  <ng-container>
    <div class="row mt-3">
    <div class="col">
      <form #loadSalesForm="ngForm">

        <div class="row pt-3">
          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <button mat-raised-button id="attachFile" matTooltip="{{ 'tooltip.allowedFormats' | translate }}"
              [disabled]="files.length === 1" (click)="validateFormat()"
              class="background-blue w-100">{{ 'button.attachFile' | translate }}</button>
          </div>

          <div class="col-sm-6 col-md-8 col-lg-9 col-xl-9 background-gray fileNameContainer">
            <ul class="filename-list">
              <li *ngIf="files.length == 0" style="list-style: none; " id="download-sales-report-filename-span">
                <span class="filename-span" style="position: absolute;">{{ 'warning.fileRequired' | translate }}</span>
              </li>
              <ng-container *ngIf="files.length > 0" id="download-sales-report-files">
                <li *ngFor="let file of files"  [matTooltip]="file.data.name" [matTooltipPosition]="'below'"
                   style="list-style: none; margin-top: 15px;">
                  <span id="file-label" class="filename-span" >{{ file.data.name }}</span>
                  <a *ngIf="file.canCancel" title="Cancel" (click)="cancelFile(file)" id="download-sales-report-cancel">
                    <mat-icon *ngIf="!file.deletingFile" class="cancelAdjunto">close</mat-icon>
                    <div *ngIf="file.deletingFile" class="deleting-loader">
                      <svg class="spinner" width="24px" height="24px" viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                        </circle>
                      </svg>
                    </div>
                  </a>
                </li>
              </ng-container>
            </ul>
            <input type="file" id="fileUpload" name="fileUpload" multiple="multiple" accept="{{ accept }}"
              style="display:none;" />
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="row mt-3 justify-content-center">
    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
      <button mat-raised-button
        [disabled]="files.length == 0"
        (click)="initBulkLoad()" class="background-red w-100">{{ 'button.startBulkLoad' | translate }}</button>
        <mat-hint class="opcional mt-1">{{ 'tooltip.maxSizeStorage' | translate }}</mat-hint>
    </div>
    <div *ngIf="loadSalesProcess" class="col-1 mt-5px">
      <svg class="spinner" width="30px" height="30px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
    </div>
  </div>

</ng-container>

<div class="row">
  <div class="col">
    <span class="title"> {{'bda.reprocess.subTitleDownload' | translate}}</span>
  </div>

</div>
<hr>

<div class="row">
  <div class="col">

    <mat-table id="fileList" #table [dataSource]="dataSource"  matSort>
      <ng-container matColumnDef="name" >
        <mat-header-cell *matHeaderCellDef mat-sort-header class="file-name-cell-width">{{ 'bda.field.name' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="file-name-cell file-name-cell-width">
          <mat-icon class="icon-color">description</mat-icon>
          {{ element.name | uppercase }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="updated">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'bda.field.updated' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.updated | dateFormat: 'DD-MMM-YY HH:mm:ss' | dashWhenEmpty }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="size" id="download-sales-report-size">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'bda.field.size' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ bytesToSize(element.size)  }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="no-data-found" id="download-sales-report-no-data-found">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell class="no-data-found-row" *matCellDef="let item">{{ 'bda.dataNotFound' | translate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions" id="download-sales-report-actions">
        <mat-header-cell *matHeaderCellDef>{{ 'button.actions' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-button type="button" [disabled]="element.inProgress"   matTooltip="{{ 'bda.tooltip.downloadFile' | translate }}" (click)="downloadFile(element)"
            class="btn button-icon" >
            <mat-icon *ngIf="!element.inProgress" class="icon-green">download</mat-icon>
            <span *ngIf="element.inProgress" class="spinner-border spinner-border-sm"></span>
          </button>

          <button mat-button type="button" icon-green matTooltip="{{ 'bda.tooltip.deleteFile' | translate }}" (click)="deleteFile(element)"
            class="btn button-icon" >
            <mat-icon color="warn">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>



      <mat-header-row *matHeaderRowDef="displayedColumns" class="latam-user-row" id="download-sales-report-displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"
      [ngClass]="{ 'highlight': selectedRowIndex === i }" (mouseover)="highlightRow($event, i)"
      (mouseout)="highlightRow($event, i)" class="latam-user-row" id="download-sales-report-hightlight"></mat-row>
      <mat-row *matRowDef="let row; columns: ['no-data-found']; when: dataNotfound" id="download-sales-report-no-data-found-row"></mat-row>
    </mat-table>
    <div *ngIf="!noFilesFound">
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 30, 50]" [showFirstLastButtons]="false">
      </mat-paginator>
    </div>
  </div>

</div>

</div>

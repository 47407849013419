import { ChangeNameFlightSegment } from './change-name-flightsegment.model';
import { ChangeNamePassenger } from './change-name-passenger.model';
import { ChangeNameRemark } from './change-name-remark.model';



export class ChangeNameRequest{
  ValidationRequestType
  recordLocator: string;
  channel: string;
  passengers: ChangeNamePassenger[];
  flightSegments: ChangeNameFlightSegment[];
  remarks: ChangeNameRemark[];

  constructor(
    recordLocator: string,
    channel: string,
    passengers: ChangeNamePassenger[],
    flightSegments: ChangeNameFlightSegment[],
    remarks: ChangeNameRemark[]
  ) {
    this.recordLocator = recordLocator;
    this.channel = channel;    
    this.passengers = passengers;
    this.flightSegments = flightSegments;
    this.remarks = remarks;
  }
}







<div class="container">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="latam-sans breadcrumb-item"><a>{{ 'menu.latamManagement' | translate }}</a></li>
          <li class="breadcrumb-item latam-sans active" aria-current="page">
            <a>{{ 'menu.budgetBulkLoad' | translate }}</a></li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col">

      <span class="title"> {{'menu.budgetBulkLoad' | translate}}</span>
    </div>

  </div>
<hr>

<ng-container >
  <div class="row mt-3">
    <div class="col">
      <form  id="attachFileForm" #bulkLoadForm="ngForm">
        <div class="row">
          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-form-field>
              <mat-select id="budgetType" name="budgetType" #bulkLoadSelect="ngModel"
                placeholder="{{ 'field.chooseOption' | translate }}" [(ngModel)]="budgetType" (selectionChange)="selectionBudgetTypeChange($event)" required>
                <mat-option *ngFor="let budgetType of budgetTypes" [value]="budgetType">
                  {{ budgetType.name | translateBudgetTypeName}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="budgetTypeRequired" class="budget-management">{{ 'field.error.required' | translate }}
                </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row pt-3">
          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <button id="attachFileButton" mat-raised-button  matTooltip="{{ 'tooltip.allowedFormats' | translate }}"
              [disabled]="filesAttached.length === 1" (click)="onClickAttachFile()"
              class="background-blue w-100">{{ 'button.attachFile' | translate }}</button>
          </div>

          <div class="col-sm-6 col-md-8 col-lg-9 col-xl-9">
            <ul class="fileAttachedName-list">
              <li *ngIf="filesAttached.length == 0" class="fileAttachedName-list-item filesAttached">
                <span class="fileAttachedName-span">{{ 'warning.fileRequired' | translate }}</span>
              </li>
              <li *ngFor="let file of filesAttached" [matTooltip]="file.data.name" [matTooltipPosition]="'below'"
                [@fadeInOut]="file.state" class="fileAttachedName-list-item">
                <span id="file-label" class="fileAttachedName-span">{{ file.data.name }}</span>
                <a *ngIf="file.canCancel" title="Cancel" (click)="cancelFile(file)">
                  <mat-icon *ngIf="!file.deletingFile" class="cancelAdjunto">close</mat-icon>
                  <div *ngIf="file.deletingFile" class="deleting-loader">
                    <svg class="spinner" width="24px" height="24px" viewBox="0 0 66 66"
                      xmlns="http://www.w3.org/2000/svg">
                      <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                      </circle>
                    </svg>
                  </div>
                </a>
              </li>
            </ul>
            <input type="file" id="fileUpload" name="fileUpload" multiple="multiple" accept="{{ accept }}"
              style="display:none;" />
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="row mt-3 justify-content-center">
    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
      <button id="startBulkLoadBudgetButton" mat-raised-button
        [disabled]="isBudgetTypeSelected == null || filesAttached.length == 0 || budgetBulkLoadProcessing == true"
        (click)="startBulkLoadBudget()" class="background-red w-100">{{ 'button.startBulkLoad' | translate }}</button>
    </div>
    <div *ngIf="budgetBulkLoadProcessing" class="col-1 mt-5px">
      <svg class="spinner" width="30px" height="30px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
    </div>
    <div *ngIf="!budgetBulkLoadProcessing" class="col-1">
      <mat-icon [ngClass]="{ 'icon-green-hidden': !bulkLoadSuccessfully }" class="icon-green">check</mat-icon>
    </div>
  </div>

</ng-container>
<div class="row">
  <div class="col">
    <span class="title"> {{'field.subtitleHistoric' | translate}}</span>
  </div>

</div>
<hr>
<!--Table-->

<div id="spinner" *ngIf="bulkLoadListloading" class="loading">
  <div class="loading-div">
    <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
    <span class="loading-span">{{ 'loading' | translate }}</span>
  </div>
</div>

<div class="row" >
  <div class="col">

    <mat-table id="bulkLoadList" #table [dataSource]="dataSource"  matSort *ngIf="!bulkLoadListloading">
      <ng-container matColumnDef="executionDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.date' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.executionDate | dateFormat: 'DD-MMM-YY HH:mm:ss' | dashWhenEmpty }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="userCreation">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.username' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.userCreation.name + ' ' +  element.userCreation.lastname | uppercase | dashWhenEmpty }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="budgetType">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.budgetType' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.budgetType | uppercase | dashWhenEmpty | translateBudgetTypeName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.status' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.status | uppercase | dashWhenEmpty }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="errorDesc">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'field.errorMessage' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.errorDesc | uppercase | dashWhenEmpty }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="no-data-found" id="waiver-manual-management-no-data-found">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell class="no-data-found-row" *matCellDef="let item">{{ 'bda.dataNotFound' | translate }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns" class="latam-user-row" id="waiver-manual-management-displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"
      [ngClass]="{ 'highlight': selectedRowIndex === i }" (mouseover)="highlightRow($event, i)"
      (mouseout)="highlightRow($event, i)" class="latam-user-row" id="waiver-manual-management-latam-user-row"></mat-row>
      <mat-row *matRowDef="let row; columns: ['no-data-found']; when: dataNotfound" id="waiver-manual-management-no-data-found-row"></mat-row>
    </mat-table>

    <div class="row">
      <div class="col-2">
        <button id="refreshButton"  mat-button class="background-blue w-100 " (click)="getBudgetBulkLoadList(0,10)">
          {{ 'button.refresh' | translate }}
        </button>
      </div>
      <div  class="col-10" *ngIf="!noBulkLoadListFound">
        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 30, 50]" [showFirstLastButtons]="false"
        (page)="pageEvent($event)">
        </mat-paginator>
      </div>
    </div>
  </div>

</div>

</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateBudgetTypeName',
  pure: false
})
export class TranslateBudgetTypeNamePipe implements PipeTransform {

  transform(value: string): string {
    const language = localStorage.getItem('language');

    if (language === 'es') {
      return this.getSpanishTranslation(value);
    } else if (language === 'en') {
      return this.getEnglishTranslation(value);
    } else if (language === 'pt') {
      return this.getPortugueseTranslation(value);
    } else {
      return this.getEnglishTranslation(value);
    }
  }

  getSpanishTranslation(value: string): string {
    switch (value) {
      case '1':
        return 'Post venta';
      case '2':
        return 'Presale';
      default: return value;
    }
  }

  getEnglishTranslation(value: string): string {
    switch (value) {
      case 'Post venta': 
      case '1':
        return 'After Sale';
      case '2': 
      case 'Preventa':
        return 'Presale';
    }
  }

  getPortugueseTranslation(value: string): string {
    switch (value) {
      case 'Post venta': 
      case '1':
        return 'Pós-venda';
      case 'Preventa': 
      case '2':
        return 'Pré-venda';
    }
  }

}

export class SolicitedValue {

    value: number;
    totalQs: number;
    totalValue: number;

    constructor(
        value: number,
        totalQs: number,
        totalValue: number
    ) {
        this.value = value;
        this.totalQs = totalQs;
        this.totalValue = totalValue;
    }

}

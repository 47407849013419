export class Language {
  id: number;
  name: string;
  code: string;

  constructor(
    id: number,
    name: string) {
      this.id = id;
      this.name = name;
  }
}

import { trigger, state, style, animate, transition, group } from '@angular/animations';

export const slideInOutAnimation = trigger('slideInOut', [
  state('in', style({ 'max-height': '280px', opacity: '1' })),
  state('out', style({ 'max-height': '35px' })),
  transition('in => out', [
    group([
      animate('800ms ease-in-out', style({ 'max-height': '35px' }))
    ])
  ]),
  transition('out => in', [
    group([
      animate('800ms ease-in-out', style({ 'max-height': '210px' })),
      animate('100ms ease-in-out', style({ opacity: '1' }))
    ])
  ])
]);

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';

import { Exception } from '../models/Exception.model';


@Injectable()
export class MessageService {

  constructor(private translateService: TranslateService) {}

  public showErrorMessage(error: Exception, buttonText: string): void {
    let message = '';

    if (error.status === 403) {
      message = this.translateService.instant('forbidden.access');
    } else if (error.status === 404) {
      message = this.translateService.instant('error.notFound');
    } else {
      message = error.description;
    }

    if (!swal.isVisible()) {
      swal.fire({
        icon: 'error',
        html: `<p>${ message }</p>`,
        confirmButtonText: buttonText,
        confirmButtonColor: '#d33',
        allowOutsideClick: false
      });
    }
  }

  public showInfoMessage(buttonText: string, message: string): void {
    swal.fire({
      icon: 'info',
      html: `<p>${ message }</p>`,
      confirmButtonText: buttonText,
      confirmButtonColor: '#d33',
      allowOutsideClick: false
    });
  }

  public showSuccessMessage(buttonText: string, title: string, message: string): void {
    swal.fire({
      icon: 'success',
      title: `<p>${ title }</p>`,
      html: `<p>${ message }</p>`,
      confirmButtonText: buttonText,
      confirmButtonColor: '#d33',
      allowOutsideClick: false
    });
  }

  public showWarningMessage(buttonText: string, message: string): void {
    swal.fire({
      icon: 'warning',
      html: `<p>${ message }</p>`,
      confirmButtonText: buttonText,
      confirmButtonColor: '#d33',
      allowOutsideClick: false
    });
  }
}

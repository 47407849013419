import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ShareModule } from '../share-module/share.module';
import { TermsConditionsModule } from '../terms-conditions-module/terms-conditions.module';

import { DialogBudgetComponent } from '../../components/budget-popup/dialog-budget/dialog-budget.component';
import { FareDiffComponent } from './fare-diff/fare-diff.component';
import { RequestResultComponent } from './request-result/request-result.component';
import { WaiverListComponent } from './waiver-list/waiver-list.component';
import { WaiverListDetailComponent } from './waiver-list-detail/waiver-list-detail.component';
import { WaiverPresaleComponent } from './waiver-presale.component';

import { WaiverPresaleService } from '../../services/waiver-presale.service';


@NgModule({
    declarations: [
        DialogBudgetComponent,
        FareDiffComponent,
        WaiverListComponent,
        RequestResultComponent,
        WaiverPresaleComponent,
        WaiverListDetailComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDialogModule,
        MatExpansionModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatTooltipModule,
        ReactiveFormsModule,
        ShareModule,
        TermsConditionsModule
    ],
    providers: [
        WaiverPresaleService
    ]
})
export class WaiverPresaleModule { }

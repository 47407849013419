<div class="mt-4 mb-3">
  <div class="row">
    <div class="col" *ngIf="!isValuated">
      <span class="subtitle">{{ 'passengerInfoSel' | translate}}</span><span class="subtitle-2">{{ 'passengerInfoPre' | translate}}</span>
    </div>
    <div class="col" *ngIf="isValuated">
      <span class="subtitle">{{ 'passengerInfo' | translate }} {{'passengerInfoPre' | translate}}</span>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-table [dataSource]="dataSource" class="table-passenger-info mat-elevation-z1">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>{{ 'field.passengerName' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
          <div *ngIf="isPassengerInfOrChd(element)" class="subtitle-2">
              {{ (element.firstName | partialDisplay:'#' : 1 | dashWhenEmpty) + ' ' + (element.lastName | partialDisplay:'#' : 1 | dashWhenEmpty) }}
          </div>
          <div *ngIf="!isPassengerInfOrChd(element)">
            {{ (element.firstName | partialDisplay:'#' : 1 | dashWhenEmpty) + ' ' + (element.lastName | partialDisplay:'#' : 1 | dashWhenEmpty) }}
          </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="foId">
          <mat-header-cell *matHeaderCellDef>{{ 'field.foid' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.foId | partialDisplay:'#' : 1 | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef>{{ 'field.passengerType' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">—</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ticketNumber">
          <mat-header-cell *matHeaderCellDef>{{ 'field.associatedTkt' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.ticketNumber | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container isVi matColumnDef="select" >
          <mat-header-cell *matHeaderCellDef class="pt-2 ">

          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="pt-2" matTooltipClass="redTooltip" matTooltip="{{ 'tooltip.childInfPasenger' | translate }}"
          [matTooltipDisabled]="disableTooltip(element) " matTooltipPosition="below" >
            <mat-checkbox   color="accent" [checked]="element.isSelected"   [disabled]="isValuated || element.type === 'CHLD' || element.type === 'INF'"
            (change)="$event ? toggleSelectionPassenger(element) : null"  >
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" class="pax-info-row"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"
          [ngClass]="{'highlight': selectedRow === i}" (mouseover)="highlightRow($event, i)"
          (mouseout)="highlightRow($event, i)" class="pax-info-row"></mat-row>
      </mat-table>
    </div>
  </div>
</div>

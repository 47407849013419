<div class="modal-header">
  <p *ngIf="validPrePNR || validPostPNR" class="title summary-title">{{ ('pnrSummary' | translate) + ' ' + pnrNumber }}
  </p>
  <p *ngIf="validPostTKT" class="title summary-title">{{ ('tktSummary' | translate) + ' ' + ticket }}</p>
  <a class="close-modal" (click)="closeDialog()">
    <span class="close-icon">
      <i class="material-icons">clear</i>
    </span>
  </a>
</div>

<div class="modal-content post-sale">
  <!-- DESPLIEGUE POR PNR -->
  <!-- informacion de reserva -->
  <ng-container *ngIf="(validPrePNR || validPostPNR) && !loading">
    <!--IMPLEMENTAR!! *ngIf="selectedTypeSearch == 1"-->
    <div class="row mt-4 mr-3 ml-3">
      <div class="col">
        <span class="subtitle">{{ 'bookingInformation' | translate }}</span>
      </div>
    </div>

    <div class="mat-elevation-z1 mt-3 table-detail-post">
      <mat-table #table [dataSource]="dataReservation">
        <ng-container matColumnDef="pnrNumber">
          <mat-header-cell *matHeaderCellDef>{{ 'field.pnr' | translate }}</mat-header-cell>
          <mat-cell id="waiverDetailPNRNumber" *matCellDef="let data">{{ data.pnr.pnrNumber | dashWhenEmpty }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="pnrType">
          <mat-header-cell *matHeaderCellDef>{{ 'field.pnrType' | translate }}</mat-header-cell>
          <mat-cell id="waiverDetailPNRType" *matCellDef="let data">{{ data.pnr.type | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="reservationCreationDate">
          <mat-header-cell *matHeaderCellDef>{{ 'field.bookingCreation' | translate }}</mat-header-cell>
          <mat-cell id="waiverDetailCreationDateTime" *matCellDef="let data">
            {{ data.pnr.createDateTime | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="NumberOfPassengers">
          <mat-header-cell *matHeaderCellDef>{{ 'field.numberPassengers' | translate }}</mat-header-cell>
          <mat-cell id="waiverDetailPassengersNumber" *matCellDef="let data">
            {{ dataPassengers?.data?.length | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="pos">
          <mat-header-cell *matHeaderCellDef>{{ 'field.pos' | translate }}</mat-header-cell>
          <mat-cell id="waiverDetailPOS" *matCellDef="let data">{{ data.booking.pos | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="iata">
          <mat-header-cell *matHeaderCellDef>{{ 'field.iata' | translate }}</mat-header-cell>
          <mat-cell id="waiverDetailIATA" *matCellDef="let data">{{ data.booking.iata | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="pcc">
          <mat-header-cell *matHeaderCellDef>{{ 'field.pcc' | translate }}</mat-header-cell>
          <mat-cell id="waiverDetailPPC" *matCellDef="let data">{{ data.booking.ppc | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="officeId">
          <mat-header-cell *matHeaderCellDef>{{ 'field.officeId' | translate }}</mat-header-cell>
          <mat-cell id="waiverDetailOfficeID" *matCellDef="let data">{{ data.booking.officeId | dashWhenEmpty }}
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumnsR" class="waiver-detail-row"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsR;" class="waiver-detail-row"></mat-row>
      </mat-table>
    </div>
  </ng-container>

  <!-- informacion pasajero pre -->
  <ng-container *ngIf="validPrePNR && !loading">
    <!-- IMPLEMENTAR!!*ngIf="selectedTypeSearch == 1"-->
    <div class="row mt-4 mr-3 ml-3">
      <div class="col">
        <span class="subtitle">{{ 'passengerInfoPre' | translate }}</span>
      </div>
    </div>

    <div class="mat-elevation-z1 mt-3 table-detail-post">
      <mat-table #table [dataSource]="dataPassengers">
        <ng-container matColumnDef="passengerName">
          <mat-header-cell *matHeaderCellDef>{{ 'field.passengerName' | translate }}</mat-header-cell>
          <mat-cell name="waiverPreDetailPassengerName" *matCellDef="let data">
            {{ (data.firstName | partialDisplay: '#': 1 | dashWhenEmpty) + ' ' + (data.lastName | partialDisplay: '#': 1 | dashWhenEmpty) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="foid">
          <mat-header-cell *matHeaderCellDef>{{ 'field.foid' | translate }}</mat-header-cell>
          <mat-cell name="waiverPreDetailPassengerFoid" *matCellDef="let data">
            {{ data.foid | partialDisplay: '#' : -2 | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="passengerType">
          <mat-header-cell *matHeaderCellDef>{{ 'field.passengerType' | translate }}</mat-header-cell>
          <mat-cell name="waiverPreDetailPassengerType" *matCellDef="let data">{{ data.type | dashWhenEmpty }}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsP" class="waiver-detail-row"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsP;" class="waiver-detail-row"></mat-row>
      </mat-table>
    </div>
  </ng-container>

  <!-- informacion de pasajeros pos -->
  <ng-container *ngIf="validPostPNR && !loading">
    <div class="row mt-4 mr-3 ml-3">
      <div class="col">
        <span class="subtitle">{{ 'passengerInfo' | translate }}</span>
      </div>
    </div>

    <div class="mat-elevation-z1 mt-3 table-detail-post">
      <mat-table #table [dataSource]="dataPassengers">
        <ng-container matColumnDef="passengerName">
          <mat-header-cell *matHeaderCellDef>{{ 'field.passengerName' | translate }}</mat-header-cell>
          <mat-cell name="waiverPosDetailPassengerName" *matCellDef="let data">
            {{ (data.firstName | partialDisplay:'#' : 1 | dashWhenEmpty) + ' ' + (data.lastName | partialDisplay:'#' : 1 | dashWhenEmpty) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="foid">
          <mat-header-cell *matHeaderCellDef>{{ 'field.foid' | translate }}</mat-header-cell>
          <mat-cell name="waiverPosDetailPassengerFoid" *matCellDef="let data">
            {{ data.foid | partialDisplay:'#' :-2 | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="passengerType">
          <mat-header-cell *matHeaderCellDef>{{ 'field.passengerType' | translate }}</mat-header-cell>
          <mat-cell name="waiverPosDetailPassengerType" *matCellDef="let data">{{ data.type | dashWhenEmpty }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tkt">
          <mat-header-cell *matHeaderCellDef>{{ 'field.tkt' | translate }}</mat-header-cell>
          <mat-cell name="waiverPosDetailPassengerTkt" *matCellDef="let data">{{ data.ticketNumber | dashWhenEmpty }}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsPPOS" class="waiver-detail-row"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsPPOS;" class="waiver-detail-row"></mat-row>
      </mat-table>
    </div>
  </ng-container>

  <!-- informacion segmento -->
  <ng-container *ngIf="(validPrePNR || validPostPNR) && !loading">
    <!--IMPLEMENTAR!! *ngIf="selectedTypeSearch == 1"-->
    <div class="row mt-4 mr-3 ml-3">
      <div class="col">
        <span class="subtitle">{{ 'segmentsInformation' | translate }}</span>
      </div>
    </div>

    <div class="mat-elevation-z1 mt-3 mb-4 table-detail-post">
      <mat-table #table [dataSource]="dataSegments">
        <ng-container matColumnDef="segmentNumber">
          <mat-header-cell *matHeaderCellDef>{{ 'field.segmentNumber' | translate }}</mat-header-cell>
          <mat-cell name="waiverDetailSegmentNumber" *matCellDef="let data">{{ data.segmentNumber | dashWhenEmpty }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="carrierMkt">
          <mat-header-cell *matHeaderCellDef>{{ 'field.mktCarrier' | translate }}</mat-header-cell>
          <mat-cell name="waiverDetailSegmentCarrierMKT" *matCellDef="let data">
            {{ data.carrierMarketing | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="flightNumberMkt">
          <mat-header-cell *matHeaderCellDef>{{ 'field.mktFlightNumber' | translate }}</mat-header-cell>
          <mat-cell name="waiverDetailSegmentFlightNumber" *matCellDef="let data">
            {{ data.flightNumber | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="class">
          <mat-header-cell *matHeaderCellDef>{{ 'field.class' | translate }}</mat-header-cell>
          <mat-cell name="waiverDetailSegmentClass" *matCellDef="let data">{{ data.class | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="origin">
          <mat-header-cell *matHeaderCellDef>{{ 'field.from' | translate }}</mat-header-cell>
          <mat-cell name="waiverDetailSegmentoSource" *matCellDef="let data">{{ data.source | dashWhenEmpty }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="destination">
          <mat-header-cell *matHeaderCellDef>{{ 'field.to' | translate }}</mat-header-cell>
          <mat-cell name="waiverDetailSegmentDestination" *matCellDef="let data">{{ data.destination | dashWhenEmpty }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dateHourDeparture">
          <mat-header-cell *matHeaderCellDef>{{ 'field.departureDate' | translate }}</mat-header-cell>
          <mat-cell name="waiverDetailSegmentDepartureDateTime" *matCellDef="let data">
            {{ data.departureDateTime | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>{{ 'field.segmentStatus' | translate }}</mat-header-cell>
          <mat-cell name="waiverDetailSegmentStatus" *matCellDef="let data">{{ data.status | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsS" class="waiver-detail-row"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsS;" class="waiver-detail-row"></mat-row>
      </mat-table>
    </div>
  </ng-container>

  <!-- DESPLIEGUE PNR O TKT -->
  <!-- informacion ticket -->
  <ng-container *ngIf="validPostTKT && !loading">
    <!-- IMPEMENTAR!! *ngIf="selectedTypeSearch == 2"-->
    <div class="row mt-4 mr-3 ml-3">
      <div class="col">
        <span class="subtitle">{{ 'tktInformation' | translate }}</span>
      </div>
    </div>

    <div class="mat-elevation-z1 mt-3 table-detail-post">
      <mat-table #table [dataSource]="dataTkt">
        <ng-container matColumnDef="tktNumber">
          <mat-header-cell *matHeaderCellDef>{{ 'field.tkt' | translate }}</mat-header-cell>
          <mat-cell id="waiverDetailTKTNumber" *matCellDef="let data">{{ data.tkt.tktNumber | dashWhenEmpty }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="pnrNum">
          <mat-header-cell *matHeaderCellDef>{{ 'field.pnr' | translate }}</mat-header-cell>
          <mat-cell id="waiverDetailTKTPNR" *matCellDef="let data">{{ data.tkt.pnr | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="emisionCreationDate">
          <mat-header-cell *matHeaderCellDef>{{ 'field.issueDate' | translate }}</mat-header-cell>
          <mat-cell id="waiverDetailTKTCreationDateTime" *matCellDef="let data">
            {{ data.tkt.createDateTime | dateFormat: 'DD-MMM-YY' | uppercase | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="posInfo">
          <mat-header-cell *matHeaderCellDef>{{ 'field.pos' | translate }}</mat-header-cell>
          <mat-cell id="waiverDetailTKTPos" *matCellDef="let data">{{ data.issuer.pos | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="iataInfo">
          <mat-header-cell *matHeaderCellDef>{{ 'field.iata' | translate }}</mat-header-cell>
          <mat-cell id="waiverDetailTKTIata" *matCellDef="let data">{{ data.booking.iata | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="pccInfo">
          <mat-header-cell *matHeaderCellDef>{{ 'field.pcc' | translate }}</mat-header-cell>
          <mat-cell id="waiverDetailTKTPPC" *matCellDef="let data">{{ data.booking.ppc | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="officeIdInfo">
          <mat-header-cell *matHeaderCellDef>{{ 'field.officeId' | translate }}</mat-header-cell>
          <mat-cell id="waiverDetailTKTOfficeID" *matCellDef="let data">{{ data.booking.officeId | dashWhenEmpty }}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsTKT" class="waiver-detail-row"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsTKT;" class="waiver-detail-row"></mat-row>
      </mat-table>
    </div>
  </ng-container>

  <!-- informacion cupon -->
  <ng-container *ngIf="validPostTKT && !loading">
    <!--IMPLEMENTAR!! *ngIf="selectedTypeSearch == 2"-->
    <div class="row mt-4 mr-3 ml-3">
      <div class="col">
        <span class="subtitle">{{ 'couponInformation' | translate }}</span>
      </div>
    </div>

    <div class="mat-elevation-z1 mt-3 mb-3 table-detail-post">
      <mat-table #table [dataSource]="dataVoucher">
        <ng-container matColumnDef="voucherNumber">
          <mat-header-cell *matHeaderCellDef>{{ 'field.couponNumber' | translate }}</mat-header-cell>
          <mat-cell name="waiverDetailCouponNumber" *matCellDef="let data">{{ data.couponNumber | dashWhenEmpty }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="carrierTkt">
          <mat-header-cell *matHeaderCellDef>{{ 'field.carrier' | translate }}</mat-header-cell>
          <mat-cell name="waiverDetailCouponCarrier" *matCellDef="let data">{{ data.carrierMarketing | dashWhenEmpty }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="flightNumberTkt">
          <mat-header-cell *matHeaderCellDef>{{ 'field.flightNumber' | translate }}</mat-header-cell>
          <mat-cell name="waiverDetailCouponFlightNumber" *matCellDef="let data">{{ data.flightNumber | dashWhenEmpty }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="classTkt">
          <mat-header-cell *matHeaderCellDef>{{ 'field.class' | translate }}</mat-header-cell>
          <mat-cell name="waiverDetailCouponClass" *matCellDef="let data">{{ data.class | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="originTkt">
          <mat-header-cell *matHeaderCellDef>{{ 'field.from' | translate }}</mat-header-cell>
          <mat-cell name="waiverDetailCouponSource" *matCellDef="let data">{{ data.source | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="destinationTkt">
          <mat-header-cell *matHeaderCellDef>{{ 'field.to' | translate }}</mat-header-cell>
          <mat-cell name="waiverDetailCouponDestination" *matCellDef="let data">{{ data.destination | dashWhenEmpty }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dateHourTkt">
          <mat-header-cell *matHeaderCellDef>{{ 'field.departureDate' | translate }}</mat-header-cell>
          <mat-cell name="waiverDetailCouponDepartureDateTime" *matCellDef="let data">
            {{ data.departureDateTime | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="farebasis">
          <mat-header-cell *matHeaderCellDef>{{ 'field.farebasis' | translate }}</mat-header-cell>
          <mat-cell name="waiverDetailCouponFareBasis" *matCellDef="let data">{{ data.fareBasis | dashWhenEmpty }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="voucherStatus">
          <mat-header-cell *matHeaderCellDef>{{ 'field.couponStatus' | translate }}</mat-header-cell>
          <mat-cell name="waiverDetailCouponStatus" *matCellDef="let data">{{ data.status | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsV" class="waiver-detail-row"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsV;" class="waiver-detail-row"></mat-row>
      </mat-table>
    </div>
  </ng-container>
</div>

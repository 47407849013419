<div class="container">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="latam-sans breadcrumb-item">
            <a>{{ 'menu.waivers' | translate }}</a>
          </li>
          <li class="breadcrumb-item latam-sans active">
            <a>{{ 'menu.preSaleManagement' | translate }}</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <span class="title">{{ 'menu.preSaleManagement' | translate }}</span>
    </div>
  </div>

  <hr>

  <div class="row justify-content-between">
    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
      <button mat-raised-button *ngIf="profiles.createPreSaleRequest && sessionInfo.role != 'MANUAL_MTZ'" (click)="nuevaSolicitud()"
        class="background-red w-100">{{ 'button.newRequest' | translate }}</button>
    </div>

    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
      <app-budget-popup [groupId]="groupId" [branchOfficeId]="branchOfficeId" [reload]="reload"></app-budget-popup>
    </div>
  </div>

  <mat-card>
    <mat-card-content>
      <mat-tab-group #tabGroup (selectedTabChange)="tabChanged($event)">
        <mat-tab>
          <ng-template mat-tab-label>
            <span>{{ 'approved' | translate }}</span>
          </ng-template>

          <app-waiver-list-presale [displayedColumns]="displayedColumnsApproved" [tab]="'approved'"
            [searchApproved]="searchApproved"></app-waiver-list-presale>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <span>{{ 'issueds' | translate }}</span>
          </ng-template>

          <app-waiver-list-presale [displayedColumns]="displayedColumnsIssued" [tab]="'issued'"
            [searchIssued]="searchIssued"></app-waiver-list-presale>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <span>{{ 'expireds' | translate }}</span>
          </ng-template>

          <app-waiver-list-presale [displayedColumns]="displayedColumnsExpired" [tab]="'expired'"
            [searchExpired]="searchExpired"></app-waiver-list-presale>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <span>{{ 'denieds' | translate }}</span>
          </ng-template>

          <app-waiver-list-presale [displayedColumns]="displayedColumnsRejected" [tab]="'rejected'"
            [searchRejected]="searchRejected"></app-waiver-list-presale>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <span>{{ 'pendings' | translate }}</span>
          </ng-template>

          <app-waiver-list-presale [displayedColumns]="displayedColumnsPending" [tab]="'pending'"
            [searchPending]="searchPending"></app-waiver-list-presale>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>

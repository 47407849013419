import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import swal from 'sweetalert2';

import { Market } from '../../../../models/market.model';
import { Profile } from '../../../../models/profile.model';
import { SessionInfo } from '../../../../models/SessionInfo.model';
import { SidebarMenuModel } from '../../../../models/SidebarMenu.model';
import { UserLatamCsvElement } from '../../../../models/UserLatamCsvElement.model';
import { UserLatamTableElement } from '../../../../models/user-latam-table-element.model';

import { LatamService } from '../../../../services/latam.service';
import { MessageService } from './../../../../services/message.service';
import { ProfilingService } from '../../../../services/profiling.service';
import { SessionInfoService } from '../../../../services/session-info.service';
import { SidebarShareDataService } from '../../../../services/sidebar-share-data.service';
import { LatamOutsourcingService } from '../../../../services/latam-outsourcing.service';
import { MessageErrorService } from '../../../../services/message-error.service';


@Component({
  selector: 'app-list-latam-users',
  templateUrl: './list-latam-users.component.html',
  styleUrls: ['./list-latam-users.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ListLatamUsersComponent implements OnInit {
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_LATAM, SidebarMenuModel.SUBMENU_LATAM_USERS_LIST);
  noLatamUsers = false;
  loading = false;
  infoUser$: Promise<SessionInfo> = this.sessionInfoService.getSessionInfoAsPromise();
  loadingUsers = false;
  downloading = false;
  downloadingSuccessfully = false;

  userLatamTableElements: UserLatamTableElement[] = [];
  dataSource = new MatTableDataSource<any>();
  displayedColumns = ['latamUserId', 'markets', 'name', 'lastname', 'roleName', 'userLatamPositionName', 'actions'];
  selectedRowIndex: number = -1;
  userLatamCsv: UserLatamCsvElement[] = [];
  pipe = new DatePipe('en-US');

  latamUserIdFilter = new UntypedFormControl();
  marketsFilter = new UntypedFormControl();
  nameFilter = new UntypedFormControl();
  lastnameFilter = new UntypedFormControl();
  roleNameFilter = new UntypedFormControl();
  userLatamPositionNameFilter = new UntypedFormControl();
  profiles = new Profile();

  userOutsourcing = "Usuario Externo";
  filteredValues = { latamUserId: '', markets: '', name: '', lastname: '', roleName: '', userLatamPositionName: '', topFilter: false};

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  noLatamUsersFound = (index, item) => item.noData;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private latamService: LatamService,
    private messageErrorService: MessageErrorService,
    private messageService: MessageService,
    private profilesService: ProfilingService,
    private sessionInfoService: SessionInfoService,
    private sidebarShareDataService: SidebarShareDataService,
    private translateService: TranslateService,
    private outsourcingService: LatamOutsourcingService) { }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.loadUsers();
    this.filterWaiver();
    this.dataSource.filterPredicate = this.customFilterPredicate();
  }

  private filterWaiver() {
    this.latamUserIdFilter.valueChanges.subscribe((latamUserIdFilterValue) => {
      this.updateFilters('latamUserId', latamUserIdFilterValue);
    });
    this.marketsFilter.valueChanges.subscribe((marketsFilterValue) => {
      this.updateFilters('markets', marketsFilterValue);
    });
    this.nameFilter.valueChanges.subscribe((nameFilterValue) => {
      this.updateFilters('name', nameFilterValue);
    });
    this.lastnameFilter.valueChanges.subscribe((lastnameFilterValue) => {
      this.updateFilters('lastname', lastnameFilterValue);
    });
    this.roleNameFilter.valueChanges.subscribe((roleNameFilterValue) => {
      this.updateFilters('roleName', roleNameFilterValue);
    });
    this.userLatamPositionNameFilter.valueChanges.subscribe((userLatamPositionNameFilterValue) => {
      this.updateFilters('userLatamPositionName', userLatamPositionNameFilterValue);
    });
  }
  updateFilters(col: string , value: any) {
    this.filteredValues[col] = value;
    this.dataSource.filter = JSON.stringify(this.filteredValues);
    this.dataSource.paginator.firstPage();
    this.filteredValues['topFilter'] = false;
  }

  loadUsers(): void {
    this.loading = true;

    this.latamService.getActiveUsers().subscribe({
      next: data => {
        if (data.length === 0) {
          this.noLatamUsers = true;
          this.dataSource.data = [{ noData: true }];
          this.dataSource.paginator = null;
        } else {
          this.userLatamTableElements = [];
          data.forEach((value: UserLatamTableElement) => {
            this.userLatamTableElements.push(new UserLatamTableElement(value));
          });
          this.dataSource.data = this.userLatamTableElements;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = (dataAccesor, header) =>
            (!isNaN(dataAccesor[header]) || !dataAccesor[header]) ? dataAccesor[header] : (dataAccesor[header] ?
              dataAccesor[header] : dataAccesor[header].toLocaleLowerCase());
        }

        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.loadingUsers');

        this.loading = false;
        console.log('error', error);
  }});
  }

  openCreateLatamUser(): void {
    this.router.navigate(['/latam/users/create']);
  }

  public openEditProfile(id: number) {
    this.router.navigate(['../edit'], { relativeTo: this.activatedRoute, queryParams: { id: id } });
  }

  public openUserProfile(id: number) {
    this.router.navigate(['../profile'], { relativeTo: this.activatedRoute, queryParams: { id: id } });
  }

  highlightRow(event: Event, rowIndex: number) {
    this.selectedRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  /**
   * Metodo para desplegar el mensaje de confirmacion
   * @param user
   */
  public showWarningMessage(userId: number, userRole: string) {
    const success = (resp) => {
      this.loading = false;
      this.messageService.showSuccessMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('popup.wellDone'),
          this.translateService.instant('success.deleteUser')
      );
      if (resp != null) {
        this.loadUsers();
      }
    };

    const failure = (error: HttpErrorResponse) => {
      this.messageErrorService.sendError(error, 'error.deleteUser');
      this.loading = false;
    };

    swal.fire(this.messageErrorService.prepareDeleteMessage('popup.deleteUser')).then((result) => {
      if (result.value) {
        this.loading = true;
        if (userRole === this.userOutsourcing){
          this.outsourcingService.deleteOutsourcingUser(userId).subscribe({next: success, error: failure});
        }else{
          this.latamService.deleteUserLatam(userId).subscribe({next: success, error: failure});
        }
      }
    });
  }

  downloadLatamUsersCSV() {
    this.downloading = true;
    this.downloadingSuccessfully = false;

    this.latamService.getActiveUsersCSV().subscribe({
      next: data => {
        this.userLatamCsv = data;
        let options = {
          fieldSeparator: ';',
          headers: [
            this.translateService.instant('field.pos'),
            this.translateService.instant('field.username'),
            this.translateService.instant('field.name'),
            this.translateService.instant('field.surname'),
            this.translateService.instant('field.email'),
            this.translateService.instant('field.role'),
            this.translateService.instant('field.position'),
            'ID',
            this.translateService.instant('field.creationDate'),
            this.translateService.instant('field.active')
          ]
        };
        // tslint:disable-next-line: no-unused-expression
        new AngularCsv(this.userLatamCsv, 'export_LatamUsers_' + this.pipe.transform(new Date, 'ddMMyyyy'), options);
        this.downloading = false;
        this.downloadingSuccessfully = true;
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.loadingUsers');

        this.downloading = false;
        console.log('error', error);
      }
  });
  }

  /**
   * Metodo que permite filtrar las columnas de la tabla de usuarios
   * @param filterValue
   */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private  customFilterPredicate() {
    const myFilterPredicate = function(data: any, filter: string): boolean {
      let searchString = JSON.parse(filter);
      // ID latamUserId
      let userNameFound = data.latamUserId.toString().trim().toLowerCase().indexOf(searchString.latamUserId.toLowerCase()) !== -1;
      // markets
      let marketsFound = false;

      if (searchString.markets.toLowerCase() === 'multiples' && data.markets.length > 1) {
        marketsFound = true;
      }

      for (let element of data.markets) {
        let market = (element as Market);

        if (market.name.toString().trim().toLowerCase().indexOf(searchString.markets.toLowerCase()) !== -1 ) {
          marketsFound = true;
        }
      }

      // name
      let nameFound = data.name.toString().trim().toLowerCase().indexOf(searchString.name.toLowerCase()) !== -1;
      // lastname
      let lastnameFound = (data.lastname === null && searchString.lastname === '') ||
        (data.lastname !== null && data.lastname.toString().trim().toLowerCase().indexOf(searchString.lastname.toLowerCase()) !== -1);
      // roleName
      let roleNameFound = (data.roleName === null && searchString.roleName === '') ||
        (data.roleName !== null && data.roleName.toString().trim().toLowerCase().indexOf(searchString.roleName.toLowerCase()) !== -1);
      // userLatamPositionName
      let userLatamPositionNameFound =
        data.userLatamPositionName.toString().trim().toLowerCase().indexOf(searchString.userLatamPositionName.toLowerCase()) !== -1;

      if (searchString.topFilter) {
        return userNameFound || marketsFound || nameFound || lastnameFound || roleNameFound || userLatamPositionNameFound;
      } else {
        return userNameFound && marketsFound && nameFound && lastnameFound && roleNameFound && userLatamPositionNameFound;
      }
    };

    return myFilterPredicate;
  }
}

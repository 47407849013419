import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { LoaderService } from '../../../services/loader.service';
import { LoginAgencyService } from '../../../services/login-agency.service';
import { LoginService } from './../../../services/login.service';


@Component({
  selector: 'app-login-agency',
  templateUrl: './login-agency.component.html',
  styleUrls: ['./login-agency.component.css'],
})
export class LoginAgencyComponent implements OnInit {
  hide = true;
  isDisabled = false;
  errorMessage: string;
  formG: UntypedFormGroup;
  isOutsourcing = true;

  @Output() message =  new EventEmitter<string>();

  constructor(
    private formB: UntypedFormBuilder,
    private router: Router,
    private loaderService: LoaderService,
    private loginAgencyService: LoginAgencyService,
    private loginService: LoginService) { }

  ngOnInit() {
    if (this.router.url.includes("outsourcing")){
      this.isOutsourcing = true;
    }
    this.buildForm();
  }

  buildForm() {
    this.formG = this.formB.group({
      email: [null, Validators.required],
      cred: [null, Validators.required]
    });
  }

  login() {
    if (this.formG.valid) {
      this.loaderService.show();
      this.isDisabled = true;

      this.loginAgencyService.login(this.formG.get('email').value, this.formG.get('cred').value)
        .subscribe({
          next: data => {
            localStorage.setItem('userN', data.displayName);
            localStorage.setItem('userMail', data.email);
            localStorage.setItem('idToken', data.idToken);
            localStorage.setItem('refreshToken', data.refreshToken)

            this.loginAgencyService.isAuthorized(this.isOutsourcing).subscribe({
              next: resp => {
                localStorage.setItem('sessionToken', resp.sessionToken);
                this.loginService.setCurrentLoginState(true);
                this.router.navigateByUrl('/agency/home');
                this.isDisabled = false;
                this.loaderService.hide();
              },
              error: (error: HttpErrorResponse) => {
                this.loginService.setCurrentLoginState(false);

                if (error.status === 401 || error.status === 403 || error.status === 404) {
                  this.errorMessage = 'login.error.400';
                } else if (error.status === 500) {
                  this.errorMessage = 'login.error.500';
                } else {
                  this.errorMessage = 'login.error.error';
                }

                this.message.emit(this.errorMessage);
                this.isDisabled = false;
                this.loaderService.hide();
              }
          });
          }
        , error: error => {
          this.message.emit('login.error.invalidLogin');
          this.isDisabled = false;
          this.loaderService.hide();
        }})
    } else {
      this.validateAllFormFields(this.formG);
    }
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);

      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  recoveryPassword() {
    this.loginAgencyService.recoveryPassword(this.formG.get('email').value);
  }
}

<div class="info-container">
  <div  class="Center-image">
    <button mat-dialog-close mat-icon-button  >
      <img  src="assets/images/correct-name/Close.svg" />
    </button>      
  </div>    
  <div class="confirmChange">
    <img  src="assets/images/correct-name/confirmChangeName.svg" />    
  </div>

  <span class="subtitle-name">
    {{ 'correctName.important' | translate }}
  </span>  

  <div class="info-button">
    <span id="name">
      {{ messageInformation }}
    </span> 
    <br>
    <button mat-dialog-close class="my-custom-button"
    mat-stroked-button mat-flat-button >
      <span>       
        OK
      </span>                
    </button>

  </div>  
 
</div>

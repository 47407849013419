<div class="row ml-4 mt-4 mr-4">
  <div class="col">
    <table class="table table-bordered">
      <tr>
        <td class="label-td w-20">{{ ('field.approvedFareQs' | translate) + '(' + waiverResult?.currency + ')' }}</td>
        <td class="w-28">{{ waiverResult?.baseFare }}</td>

        <td class="label-td w-23">{{ 'field.farebasis' | translate }}</td>
        <td>{{ waiverResult?.farebasis }}</td>
      </tr>

      <tr>
        <td class="label-td">{{ 'field.fareBuilding' | translate }}</td>
        <td>{{ waiverResult?.fareConstruction }}</td>

        <td class="label-td">{{ 'field.taxesCode' | translate }}</td>
        <td>{{ waiverResult?.taxesCode }}</td>
      </tr>
      <tr>
        <td class="label-td">{{ ('field.waiverValue' | translate) + ' (' + waiverResult?.currency + ')' }}</td>
        <td colspan="3">{{ waiverResult?.waiverValue }}</td>
      </tr>
    </table>
  </div>
</div>
<div class="row ml-4 mt-4 mr-4" *ngIf="isFarediff && !isBrMarket">
  <div class="col">
    <table class="table table-bordered">
      <tr>
        <td colspan="2" class="label-td">
          <span>{{ 'field.applyDiscountGDS' | translate }}</span>
        </td>
      </tr>
      <tr>
        <td class="label-td w-20"><span>SABRE</span></td>
        <td>{{ waiverResult?.hostCommand.sabre }}</td>
      </tr>
      <tr>
        <td class="label-td"><span>{{ 'field.referenceAmadeus' | translate }}</span></td>
        <td>{{ waiverResult?.hostCommand.amadeus }}<br>
          <span>{{ 'field.informApprovedDiscount' | translate }}</span>
        </td>
      </tr>
      <tr>
        <td class="label-td"><span>{{ 'field.referenceTravelPort' | translate }}</span></td>
        <td>{{ waiverResult?.hostCommand.travelPort }}<br>
          <span>{{ 'field.informApprovedDiscount' | translate }}</span>
        </td>
      </tr>
    </table>
  </div>
</div>

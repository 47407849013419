<div id="modal-content" class="modal-content small-modal">
  <div id="modal-header" class="modal-header">
    <h5 id="modal-title" class="modal-title">{{ 'uploadFile' | translate }}</h5>
    <button id="close-button" class="close" (click)="closeDialog()">
      <mat-icon class="close-icon">clear</mat-icon>
    </button>
  </div>

  <div id="modal-body" class="modal-body">
    <div class="row">
      <!-- Input file-->
      <div id="language-selection" class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <mat-form-field>
          <mat-label>{{ 'field.language' | translate }}</mat-label>
          <mat-select #languagesSelect="ngModel"  id="languagesSelect" name="languagesSelect" [(ngModel)]="waiverUserManualFile.language" required>
            <mat-option *ngFor="let language of languages" [value]="language.code">
              {{ language.name }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="languagesSelect.errors && (languagesSelect.dirty || languagesSelect.touched) && languagesSelect.errors.required">
            {{ 'field.error.required' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <div id="category-selection" class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <mat-form-field>
          <mat-label>{{ 'field.waiverCategory' | translate }}</mat-label>
          <mat-select #waiverCategorySelect="ngModel" id="waiverCategorySelect" name="waiverCategorySelect" [(ngModel)]="waiverUserManualFile.waiverCategory" required>
            <mat-option *ngFor="let category of categories" [value]="category.code">
              {{ category.name | translate }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="waiverCategorySelect.errors && (waiverCategorySelect.dirty || waiverCategorySelect.touched) && waiverCategorySelect.errors.required">
            {{ 'field.error.required' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <div id="type-selection" class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <mat-form-field>
          <mat-label>{{ 'field.type' | translate }}</mat-label>
          <mat-select #typeSelect="ngModel" id="typeSelect" name="typeSelect" [(ngModel)]="waiverUserManualFile.waiverType" required>
            <mat-option *ngFor="let type of types" [value]="type.code">
              {{ type.name }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="typeSelect.errors && (typeSelect.dirty || typeSelect.touched) && typeSelect.errors.required">
            {{ 'field.error.required' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <!-- Attach file -->
      <div id="file-attachment" class="col">
        <div class="containerAdjunto">
          <button id="attach-file-button" mat-button class="mat-raised-button background-blue" [disabled]="files.length > 0" (click)="validateFormat()">
            {{ 'button.attachFile' | translate }}
          </button>
        </div>
      </div>

      <!-- Documents -->
      <div id="file-list" class="col-sm-6 col-md-8 col-lg-9 col-xl-9 fileNameContainer">
        <ul class="filename-list">
          <li *ngIf="files.length == 0" style="list-style: none; ">
            <span class="filename-span" style="position: absolute;">{{ 'warning.fileRequired' | translate }}</span>
          </li>
          <ng-container *ngIf="files.length > 0">
            <li *ngFor="let file of files"  [matTooltip]="file.data.name" [matTooltipPosition]="'below'"
               style="list-style: none; margin-top: 15px;">
              <span id="file-label" class="filename-span" >{{ file.data.name }}</span>
              <a *ngIf="file.canCancel" title="Cancel" (click)="cancelFile(file)">
                <mat-icon *ngIf="!file.deletingFile" class="cancelAdjunto">close</mat-icon>
                <div *ngIf="file.deletingFile" class="deleting-loader">
                  <svg class="spinner" width="24px" height="24px" viewBox="0 0 66 66"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                    </circle>
                  </svg>
                </div>
              </a>
            </li>
          </ng-container>
        </ul>
        <input type="file" id="fileUpload" name="fileUpload" multiple="multiple" accept=".pdf"
          style="display:none;" />
      </div>
    </div>

    <div id="generate-report-button-wrapper" class="generate-report-button-wrapper mt-3">
      <button mat-button class="mat-raised-button background-red" (click)="initBulkLoad()"
        [disabled]="files.length == 0 || !waiverUserManualFile.waiverType || !waiverUserManualFile.language
        || !waiverUserManualFile.waiverCategory || !waiverUserManualFile.waiverType" >
        {{ 'button.uploadFile' | translate }}</button>
    </div>
  </div>
</div>


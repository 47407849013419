export class Pnr {
  pnrNumber: string;
  createDateTime: string;
  type: string;

  constructor(
    pnrNumber: string,
    createDateTime: string,
    type: string
  ) {
    this.pnrNumber = pnrNumber;
    this.createDateTime = createDateTime;
    this.type = type;
  }
}

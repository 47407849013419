import { Component, OnInit } from '@angular/core';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';

@Component({
    selector: 'app-new-waiver-home',
    templateUrl: './new-waiver-home.component.html',
    styleUrls: ['./new-waiver-home.component.css']
})
export class NewWaiverHomeComponent implements OnInit{
    sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_WAIVERS, SidebarMenuModel.SUBMENU_NEW_LATAM_WAIVER_REQUEST);

    constructor(
        private sidebarShareDataService: SidebarShareDataService,
    ){ }
    
    ngOnInit(): void {
        this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    }
}

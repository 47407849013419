import { Market } from './market.model';

export class MonthlyBudget {
  budget: any[];
  market: Market;
  groupId: number;
  showBudget: boolean;
  showPresaleBudget: boolean;
  branchOfficeId: number;


  constructor(
    budget?: any[],
    market?: Market,
    groupId?: number,
    showBudget?: boolean,
    showPresaleBudget?: boolean,
    branchOfficeId?: number) {
      this.budget = budget;
      this.market = market;
      this.groupId = groupId;
      this.showBudget = showBudget;
      this.showPresaleBudget = showPresaleBudget;
      this.branchOfficeId = branchOfficeId;
  }
}

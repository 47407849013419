<h2 mat-dialog-title>{{ 'waiverLatam.termsAndConditions2' | translate}}</h2>
<mat-dialog-content class="mat-typography">
  <div class="paragraph-container">
    <p>{{ 'waiverLatam.termsAndConditionsP1' | translate }}</p>
    <p>{{ 'waiverLatam.termsAndConditionsP2' | translate }}</p>
  </div>
</mat-dialog-content>

<div class="actions-container">
  <button mat-flat-button (click)="acceptTermsAndConditions()" class="accept">
        {{ 'button.confirm' | translate }}
  </button>
</div>

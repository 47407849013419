
export class ChangeNamePassenger{  
  criterionCode: string;
  name: string;
  lastName: string;
  newName: string;
  newLastName: string;
  paxNumber: string;

  constructor(
    criterionCode: string,
    name: string,
    lastName: string,
    newName: string,
    newLastName: string,
    paxNumber: string

  ){
   this.criterionCode = criterionCode;
   this.name = name;
   this.lastName = lastName;
   this.newName = newName;
   this.newLastName = newLastName;
   this.paxNumber = paxNumber;
  }
}
import { Component, OnInit } from '@angular/core';

import { SessionInfoService } from '../../../services/session-info.service';
import { UrlService } from '../../../services/url.service';


@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent implements OnInit {
  errorCode: string;
  constructor(
    private sessionInfoService: SessionInfoService,
    private urlService: UrlService) { }

  ngOnInit() {

    this.urlService.getErrorCode().subscribe(data => {
      this.errorCode = data;
    });
  }

  toRoot() {
    this.sessionInfoService.redirectTypeOfSession();
  }
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { WaiverPostsaleService } from '../../../services/waiver-postsale.service';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { DownloadAgencyCorrectNameReport } from '../../../models/download-agency-report-correctname.model';
import { CorrectNameService } from '../../../services/correct-name.service';
import { DatePipe } from '@angular/common';
import { MessageErrorService } from '../../../services/message-error.service'; // Asegúrate de que este servicio esté importado
import { MessageService } from '../../../services/message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent {
  loading: boolean = false;
  currentDate = new Date();
  startDate: Moment;
  endDate: Moment;
  report: DownloadAgencyCorrectNameReport[] = [];
  private datepipe: DatePipe = new DatePipe('en-US');

  // Mapeo de los criterios
  private criterionMap: { [key: number]: string } = {
    1: 'Correccion por adicion/exclusion de nombres',
    2: 'Duplicidad de nombre/apellido (incluye ambos)',
    3: 'Correccion por datos duplicados (nombre/apellido)',
    4: 'Nombre o apellido invertido (sin ambos)',
    5: 'Campo nombre invertido: NOMBRE/APELLIDO',
    6: 'Errores ortograficos (max. 3 caracteres)',
    8: 'Añadir nombre, mantener apellido',
    7: 'Añadir apellido, mantener nombre'
  };

  constructor(
    private waiverPostsaleService: WaiverPostsaleService,
    private router: Router,
    private adapter: DateAdapter<Moment>,
    private correctNameService: CorrectNameService,
    private messageErrorService: MessageErrorService,
    private messageService: MessageService,
    private translateService: TranslateService 
  ) {}

  // Método para navegar a otra vista
  navigateToMain() {
    this.router.navigate(['/agency/correct-name']);
  }

  startDateChanged(startDate: MatDatepickerInputEvent<Moment>) {
    if (this.endDate && startDate.value > this.endDate) {
      this.endDate = null;
    }
    this.startDate = startDate.value;
  }

  endDateChanged(endDate: MatDatepickerInputEvent<Moment>) {
    this.endDate = endDate.value;
  }

  setCalendarLanguage() {
    this.adapter.setLocale('es');
  }

  generateReport() {
    this.loading = true;
  
    // Validar fechas
    if (!this.startDate || !this.endDate) {
      this.loading = false;
      this.messageService.showWarningMessage(
        this.translateService.instant('button.confirm'), // Suponiendo que quieras usar un texto de botón aquí
        this.translateService.instant('info.dateRangeInvalid')
      );
      return;
    }
  
    // Validar que la fecha de inicio sea anterior a la de fin
    if (this.startDate.isAfter(this.endDate)) {
      this.loading = false;
      this.messageService.showWarningMessage(
        this.translateService.instant('button.confirm'), // Texto del botón
        this.translateService.instant('info.startDateBeforeEndDate')
      );
      return;
    }
  
    const formattedStartDate = this.datepipe.transform(this.startDate.toDate(), 'yyyy-MM-ddTHH:mm:ss');
    const formattedEndDate = this.datepipe.transform(this.endDate.toDate(), 'yyyy-MM-ddTHH:mm:ss');
  
    this.correctNameService.getNameCorrectionsBetweenDates(formattedStartDate, formattedEndDate)
      .subscribe({
        next: (data: DownloadAgencyCorrectNameReport[]) => {
          this.report = data;
          this.downloadCsv(this.report);
        },
        error: (error: HttpErrorResponse) => {
          this.messageErrorService.sendError(error, 'error.generateReport'); // Manejo de errores
          console.error('Error al generar el reporte: ', error);
          this.loading = false;
        },
        complete: () => this.loading = false
      });
  }
  


  private downloadCsv(data: DownloadAgencyCorrectNameReport[]) {
    const csvContent = this.convertToCsv(data);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'reporte_correcciones.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private convertToCsv(data: DownloadAgencyCorrectNameReport[]): string {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(';'));

    for (const row of data) {
      const convertedRow = {
        ...row,
        criterionCode: this.criterionMap[row.criterionCode] || '' // Asigna información basada en criterionCode
      };
      csvRows.push(headers.map(header => JSON.stringify(convertedRow[header])).join(';'));
    }

    return csvRows.join('\n');
  }
}

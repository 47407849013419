import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { SessionInfoService } from '../services/session-info.service';


@Injectable()
export class SessionAuthAgencyGuard implements CanActivate {

  constructor(
    private router: Router,
    private sessionInfoService: SessionInfoService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('Guard activated for:', state.url);
    this.sessionInfoService.getSessionInfoAsPromise()
      .then(
        infoUser => {
          if (infoUser && infoUser.isLatamSession()) {
            this.router.navigateByUrl('/401',  { skipLocationChange: true } );
            return false;

          }
          this.sessionInfoService.validateRolePath(route, state, infoUser.role);
        }
      );

    if (localStorage.getItem('idToken') && localStorage.getItem('sessionToken')) {
      return true;
    }

    this.router.navigate(['']);
    return false;
  }
}

<form class="row mt-4" [formGroup]="formG">
  <ng-container *ngIf="!isPrivate">
    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
      <mat-form-field>
        <mat-label>{{ 'field.carrier' | translate }}</mat-label>
        <mat-select formControlName="carrier" required
          (selectionChange)="carrierChange()">
          <mat-option *ngFor="let carrier of carriers" [value]="carrier.name">
            {{ carrier.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="!formG.get('carrier').valid">{{ 'field.error.required' | translate }}</mat-error>
      </mat-form-field>
    </div>
  </ng-container>

  <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
    <mat-form-field>
      <mat-label>{{ 'field.baseFareWanted' | translate }}</mat-label>
      <input matInput type="text"
        formControlName="baseFareDesired" required appOnlyPositiveNumber maxlength="4" (keyup)="setTotalFare()"
        (change)="setTotalFare()" (blur)="setTotalFare(); paramChange()">
      <mat-error *ngIf="!formG.get('baseFareDesired').valid">{{ 'field.error.required' | translate }}</mat-error>
    </mat-form-field>
    <p class="label-info-small">{{ 'tooltip.enterPublicFare' | translate }}</p>
    <p class="label-info-small">{{ 'tooltip.noPrivateFare' | translate }}</p>
  </div>

  <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
    <mat-form-field>
      <mat-label>"Q"</mat-label>
      <input matInput type="text" formControlName="queues" required
        appOnlyPositiveNumber maxlength="4" (keyup)="setTotalFare()" (change)="setTotalFare()"
        (blur)="setTotalFare(); paramChange()">
      <mat-error *ngIf="!formG.get('queues').valid">{{ 'field.error.required' | translate }}</mat-error>
    </mat-form-field>
    <p class="label-info-small">{{ 'tooltip.amountQs' | translate }}</p>
  </div>

  <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
    <mat-form-field>
      <mat-label>{{ 'field.totalFareValue' | translate }}</mat-label>
      <input matInput type="text" readonly
        [value]="totalFareValue">
    </mat-form-field>
  </div>
</form>

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { LoginService } from './login.service';
import { SessionInfoService } from './session-info.service';
import { UserService } from './user.service';


@Injectable()
export class LoginLatamService {

  constructor(
    private router: Router,
    private loginService: LoginService,
    private sessionInfoService: SessionInfoService,
    private userService: UserService) { }

  isAuthorized(): Observable<any> {
    return this.userService.getUserSessionToken();
  }

  logout() {
    this.sessionInfoService.cleanSession();
    this.loginService.setCurrentLoginState(false);
    this.router.navigate(['/login/latam']);
  }
}

<ng-container *ngIf="profiles.readAgencyProfile || profiles.readAgencyProfileLT">
  <ng-container *ngIf="infoUser$ | async as infoUser">
    <div class="container">
      <div class="row">
        <div class="col">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="latam-sans breadcrumb-item"><a>{{ 'menu.agenciesManagement' | translate }}</a></li>
              <li class="breadcrumb-item latam-sans active" aria-current="page">
                <a>{{ 'branchOfficeProfile' | translate }}</a></li>
            </ol>
          </nav>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <span class="title">{{ 'branchOfficeProfile' | translate }}</span>
          <span class="red-title" *ngIf="!loading">{{ branchOffice.name }}</span>
        </div>
      </div>

      <hr>

      <div *ngIf="loading" class="loading">
        <div class="loading-div">
          <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
          </svg>
          <span class="loading-span">{{ 'loading' | translate }}</span>
        </div>
      </div>

      <ng-container *ngIf="!loading">
        <mat-accordion>
          <mat-expansion-panel class="profile-branch-office" [expanded]="true" hideToggle="false">
            <mat-expansion-panel-header>
              <mat-panel-title id="usersDivTitle" class="profile-branch-office-title">
                {{ 'partnerAgencies' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <app-associated-iatas [matrixId]="matrixId" [branchOfficeId]="branchOffice.id"></app-associated-iatas>
          </mat-expansion-panel>

          <mat-expansion-panel class="profile-branch-office">
            <mat-expansion-panel-header>
              <mat-panel-title id="usersDivTitle" class="profile-branch-office-title">{{ 'users' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <!-- <ng-container *ngIf="profiles.createBranchOfficeUser"> -->
              <div class="row ml-3 mb-3 mr-3">
                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <button mat-raised-button id="createUserButton" (click)="openCreateUserDialog()"
                    class="background-red w-100">{{ 'menu.createUser' | translate }}</button>
                </div>
              </div>
            <!-- </ng-container> -->

            <app-user-list [matrixId]="matrixId" [branchOfficeId]="branchOffice.id" [reloadUsers]="reloadUsers"
              [typeUser]="'branchOffice'"></app-user-list>
          </mat-expansion-panel>
        </mat-accordion>

        <ng-container *ngIf="infoUser.isLatamSession() || infoUser.isAdminAgencyGroupUser() || infoUser.isManualAgencyGroupUser()">
          <div class="row mt-4 mb-4">
            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <button mat-raised-button id="backButton" (click)="goBack()"
                class="btn-volver w-100">{{ 'button.back' | translate }}</button>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

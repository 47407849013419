import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';

import { Profile } from '../../../models/profile.model';
import { WaiverRejectionMotive } from '../../../models/waiver-rejection-motive.model';

import { slideInOutAnimation } from '../../../constants/constants';
import { MessageErrorService } from '../../../services/message-error.service';
import { MessageService } from '../../../services/message.service';
import { ProfilingService } from '../../../services/profiling.service';
import { WaiverPostsaleService } from '../../../services/waiver-postsale.service';

@Component({
  selector: 'app-reject-list-waivers-latam',
  templateUrl: './reject-list-waivers-latam.component.html',
  styleUrls: ['./reject-list-waivers-latam.component.css'],
  animations: [slideInOutAnimation],
  encapsulation: ViewEncapsulation.None
})
export class RejectListWaiversLatamComponent implements OnInit {

  approveReject = true;
  confirmApproval = false;
  correctNameCheck = false;
  confirmRejection = false;
  loading = false;
  loadingData = false;
  checkCorrectNameStatus= false;

  idParam = 0;
  countReject = 0;
  animationState = 'out';
  approvalComment: string;
  // currencyCode: string;
  folioWaiver: string;
  rejectionComment: string;
  tab = '';
  // taxCurrencyCode: string;
  waiverValue: string;

  dataAprobacion = new MatTableDataSource();
  dataRechazo = new MatTableDataSource();
  dataReservation = new MatTableDataSource();
  dataPassengers = new MatTableDataSource();
  dataSegments = new MatTableDataSource();
  dataSolicitud = new MatTableDataSource();
  dataTkt = new MatTableDataSource();
  dataVoucher = new MatTableDataSource();

  motives: {};
  objectWaivers =[];
  objectWaiver = {
    id: 0,
    document: '',
    documentNumber: ''
  };
  profiles = new Profile();
  selectedMotive: WaiverRejectionMotive = new WaiverRejectionMotive(-1, '');

  constructor(
    public dialogRef: MatDialogRef<RejectListWaiversLatamComponent>,
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private profilesService: ProfilingService,
    private translateService: TranslateService,
    private waiverPostsaleService: WaiverPostsaleService) { }

  ngOnInit() {
    console.log("waivers en ventana: ",this.objectWaivers);
    this.idParam = this.objectWaiver.id;
    this.profiles = this.profilesService.getProfile();
    this.getRejectionMotives();
  }

  private getRejectionMotives(): void {
    this.loadingData = true;

    this.waiverPostsaleService.getRejectionMotives().subscribe({
      next: data => {
        this.motives = data;
        this.loadingData = false;
      },
      error: (error: HttpErrorResponse) => {
        this.loadingData = false;
        console.log('error', error);
      }
  });
  }



  closeDialog() {
    this.dialogRef.close();
  }

  rejectWaiver() {

    this.loading = true;
    this.objectWaivers.forEach(element => {

    this.waiverPostsaleService.putRejectWaiver(element.id, this.selectedMotive.id, this.rejectionComment).subscribe(
      data => {
        if(this.objectWaivers.indexOf(element)+1 == this.objectWaivers.length){
          this.dialogRef.close('reload-rejected');
          this.messageService.showSuccessMessage(
          this.translateService.instant('button.back'),
          this.translateService.instant('popup.wellDone'),
          this.translateService.instant('success.deniedWaivers')
        );
        }
    });

        this.loading = false;
      },
      (error: HttpErrorResponse) => {
        this.dialogRef.close('error');
        this.messageErrorService.sendError(error, 'error.deniedWaiver');

        this.loading = false;
        console.log('error', error);
      });
      this.loading = false;
        }

  toggleShowDiv(divName: string) {
    if (divName === 'divFooter') {
      this.animationState = this.animationState === 'out' ? 'in' : 'out';
    }
  }

  toggleShowTab(divName: string) {
    if (divName === 'divFooter') {
      this.animationState = this.animationState === 'out' ? 'in' : 'out';
      this.approveReject = false;
    }
  }


}

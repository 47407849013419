
import {map} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { LoginService } from './login.service';
import { SessionInfoService } from './session-info.service';
import { UserService } from './user.service';
import { FirebaseLoginResponse } from '../models/firebase-login-response.model';
import { StorageService } from './storage.service';


@Injectable()
export class LoginAgencyService {

  urlBase = StorageService.getDnsLatamtrade() + environment.endpoints.urlUserAdmin;
  pathBase = this.urlBase + "/api/firebase/";
  pathLogin = this.pathBase + "login";
  refreshToken = this.pathBase + "refresh/token";
  headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

  constructor(
    private firebaseAuth: AngularFireAuth,
    private router: Router,
    private loginService: LoginService,
    private sessionInfoService: SessionInfoService,
    private http: HttpClient,
    private userService: UserService) {
  }

  changePassword(token: string, newpass: string): Promise<void> {
    return this.firebaseAuth.confirmPasswordReset(token, newpass);
  }

  isAuthorized(isOutsourcing: boolean) {
    if (isOutsourcing){
      this.userService.getOutsourcingUserSessionToken();
    }
    return this.userService.getUserSessionToken();
  }

  login(email: string, cred: string): Observable<FirebaseLoginResponse> {
    return this.http.post<FirebaseLoginResponse>(this.pathLogin, {email, password: cred, returnSecureToken: true}, { headers: this.headers });
  }

  logout(isOutsourcing: boolean) {
    this.sessionInfoService.cleanSession();
    this.loginService.setCurrentLoginState(false);
    const path = isOutsourcing ? ['/login/outsourcing'] : ['/login/agency'];
    this.router.navigate(path);
  }

  recoveryPassword(email: string): Promise<void> {
    const actionCodeSettings = {
      url: StorageService.getDnsLogin(),
      handleCodeInApp: false
    };

    return this.firebaseAuth.sendPasswordResetEmail(email, actionCodeSettings);
  }

  renewToken(refreshToken: string): Observable<string> {
    return this.http.post<FirebaseLoginResponse>(this.pathLogin, {refreshToken, grantType: 'refresh_token'}, { headers: this.headers }).pipe(map(resp => resp.idToken));
  }

  verifyResetCode(token: string): Promise<string> {
    return this.firebaseAuth.verifyPasswordResetCode(token);
  }
}

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Exception } from '../models/Exception.model';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../services/message.service';
import { SessionInfoService } from '../services/session-info.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private translateService: TranslateService,
    private sessionInfoService: SessionInfoService,
    private messageService: MessageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const verificateUrl = request.url.includes('assets');
    const idToken = localStorage.getItem('idToken');
    const verificateUrlLogin = request.url.includes('api/firebase/login');
    const googleCloudStorageApi = 'storage.googleapis.com';
    const verificateUrlSession = request.url.includes('users/info');
    const userInfoToken = this.sessionInfoService.getToken();
    let headers = request.clone().headers;

    if (idToken && !verificateUrl && !verificateUrlLogin && !request.url.includes(googleCloudStorageApi)) {
      headers = headers.append('Authorization', 'Bearer ' + idToken);
      headers = headers.append('X-Application-Name', 'PORTAL AGENCIA');
    }

    if (userInfoToken && !verificateUrl && !verificateUrlSession) {
      headers = headers.set("SessionInfo", userInfoToken);
    }

    const reqClone = request.clone({ headers });
    return next.handle(reqClone).pipe(tap(this.getError()));
  }

  getError() {
    return {
      next: () => null,
      error: (error: any) => {
        this.validateServiceAvailability(error);
        return throwError(() => error);
      },
    };
  }

  validateServiceAvailability(response: HttpErrorResponse) {
    if (
      [HttpStatusCode.BadGateway, HttpStatusCode.ServiceUnavailable].includes(
        response.status
      )
    ) {
      this.messageService.showErrorMessage(
        new Exception(
          'Unavailable service',
          this.translateService.instant('error.globalError'),
          response.status
        ),
        this.translateService.instant('button.confirm')
      );
    }
    return response;
  }
}

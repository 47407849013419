import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { FileDownload } from '../../models/file-download.model';

import { FileStorageService } from '../../services/file-storage.service';


@Component({
  selector: 'app-download-list',
  templateUrl: './download-list.component.html',
  styleUrls: ['./download-list.component.css']
})
export class DownloadListComponent {

  @ViewChild('downloadFileLink') downloadFileLink: ElementRef;
  @Input() files: FileDownload[];

  constructor(
    private fileStorageService: FileStorageService) { }



  public async download(file: FileDownload): Promise<void> {
    const blob = await this.fileStorageService.downloadFile(file.locationStorage);

    const url = window.URL.createObjectURL(blob);

    const link = this.downloadFileLink.nativeElement;
    link.href = url;
    link.download = file.name;
    link.click();
    window.URL.revokeObjectURL(url);

  }
}

<div class="container">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="latam-sans breadcrumb-item">
            <a>{{ 'menu.latamManagement' | translate }}</a>
          </li>
          <li class="latam-sans breadcrumb-item">
            <a>{{ 'menu.userManagement' | translate }}</a>
          </li>
          <li class="breadcrumb-item latam-sans active" aria-current="page">
            <a>{{ 'menu.createLatamUser' | translate }}</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <span class="title">{{ 'menu.createLatamUser' | translate }}</span>
    </div>
  </div>

  <hr>

  <div *ngIf="loading" class="loading">
    <div class="loading-div">
      <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
      <span class="loading-span">{{ 'loading' | translate }}</span>
    </div>
  </div>

  <ng-container *ngIf="!loading">
    <div class="row mt-3">
      <div class="col">
        <span class="subtitle">{{ 'enterUserData' | translate }}</span>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <form #createLatamUserForm="ngForm">
          <div class="row">
            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-form-field>
                <mat-label>{{ 'field.username' | translate }}</mat-label>
                <input #latamUserId="ngModel" id="latamUserId" name="latamUserId" matInput [(ngModel)]="userLatam.latamUserId" required>
                <mat-error
                  *ngIf="latamUserId.errors && (latamUserId.dirty || latamUserId.touched) && latamUserId.errors.required">
                  {{ 'field.error.required' | translate }}</mat-error>
              </mat-form-field>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-form-field>
                <mat-label>{{ 'field.name' | translate }}</mat-label>
                <input #name="ngModel" id="name" name="name" matInput
                  [(ngModel)]="userLatam.name" required>
                <mat-error *ngIf="name.errors && (name.dirty || name.touched) && name.errors.required">
                  {{ 'field.error.required' | translate }}</mat-error>
              </mat-form-field>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-form-field>
                <mat-label>{{ 'field.surname' | translate }}</mat-label>
                <input #lastName="ngModel" id="lastName" name="lastName" matInput [(ngModel)]="userLatam.lastname" required>
                <mat-error *ngIf="lastName.errors && (lastName.dirty || lastName.touched) && lastName.errors.required">
                  {{ 'field.error.required' | translate }}</mat-error>
              </mat-form-field>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-form-field>
                <mat-label>{{ 'field.email' | translate }}</mat-label>
                <input #email="ngModel" id="email" name="email" matInput
                  [(ngModel)]="userLatam.email" pattern="\b[a-zA-Z0-9._-]+@[a-zA-Z-]+\.[a-zA-Z.]+" maxlength="100"
                  required>
                <mat-error *ngIf="email.errors && (email.dirty || email.touched) && email.errors.required">
                  {{ 'field.error.required' | translate }}</mat-error>
                <mat-error *ngIf="email.errors && (email.dirty || email.touched) && email.errors.pattern">
                  {{ 'field.error.invalidEmail' | translate }}</mat-error>
              </mat-form-field>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-form-field>
                <mat-label>{{ 'field.role' | translate }}</mat-label>
                <mat-select #rolesSelect="ngModel" id="rolesSelect" name="rolesSelect" [(ngModel)]="userLatam.role" required>
                  <mat-option *ngFor="let latamRole of latamRoles" [value]="latamRole">
                    {{ latamRole.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="rolesSelect.errors && (rolesSelect.dirty || rolesSelect.touched) && rolesSelect.errors.required">
                  {{ 'field.error.required' | translate }}</mat-error>
              </mat-form-field>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-form-field>
                <mat-label>{{ 'field.position' | translate }}</mat-label>
                <mat-select #positionsSelect="ngModel" id="positionsSelect" name="positionsSelect" [(ngModel)]="userLatam.userLatamPosition" required>
                  <mat-option *ngFor="let position of userLatamPositions" [value]="position">
                    {{ position.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="positionsSelect.errors && (positionsSelect.dirty || positionsSelect.touched) && positionsSelect.errors.required">
                  {{ 'field.error.required' | translate }}</mat-error>
              </mat-form-field>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-form-field>
                <mat-label>{{ 'field.selectPos' | translate }}</mat-label>
                <mat-select #selectedOptions #marketsSelect="ngModel" id="marketsSelect" name="marketsSelect" [(ngModel)]="userLatam.markets"
                  [disabled]="markets.length === 0" multiple required>
                  <mat-option #marketOption *ngFor="let market of markets"
                    [disabled]="selectedOptions.selected.length == 6 && !marketOption.selected && ((userLatam.role!=null && userLatam.role.id!=4) || userLatam.role==null) && !isAdmin"
                    [value]="market" class="select-pos">
                    {{ market.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="marketsSelect.errors && (marketsSelect.dirty || marketsSelect.touched) && marketsSelect.errors.required">
                  {{ 'field.error.required' | translate }}</mat-error>
              </mat-form-field>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-form-field>
                <mat-label>{{ 'field.language' | translate }}</mat-label>
                <mat-select #languagesSelect="ngModel" id="languagesSelect" name="languagesSelect" [(ngModel)]="userLatam.language" required>
                  <mat-option *ngFor="let language of languages" [value]="language">
                    {{ language.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="languagesSelect.errors && (languagesSelect.dirty || languagesSelect.touched) && languagesSelect.errors.required">
                  {{ 'field.error.required' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="row mt-4 justify-content-between">
      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <button mat-raised-button id="backButton" (click)="returnToList()"
          class="btn-volver w-100">{{ 'button.back' | translate }}</button>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <button mat-raised-button id="crearButton"
          [disabled]="createLatamUserForm.invalid || !profiles.createUserManagement" (click)="createUser()"
          class="background-red w-100">{{ 'button.create' | translate }}</button>
      </div>
    </div>
  </ng-container>
</div>

import { Component, OnInit } from '@angular/core';
import { SidebarMenuModel } from '../../models/SidebarMenu.model';
import { SidebarShareDataService } from '../../services/sidebar-share-data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(100, 100);

  constructor(private sidebarShareDataService: SidebarShareDataService) { }

  ngOnInit() {
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
  }

}

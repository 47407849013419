<ng-container *ngIf="infoUser$ | async as infoUser">
  <div class="container">
    <div class="row">
      <div class="col">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="latam-sans breadcrumb-item">
              <a>{{ 'menu.latamManagement' | translate }}</a>
            </li>
            <li class="latam-sans breadcrumb-item">
              <a>{{ 'menu.userManagement' | translate }}</a>
            </li>
            <li class="breadcrumb-item latam-sans active" aria-current="page">
              <a>{{ 'menu.latamUserProfile' | translate }}</a>
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <span class="title">{{ 'menu.latamUserProfile' | translate }}</span>
      </div>
    </div>

    <hr>

    <!-- formulario -->
    <div *ngIf="loading" class="loading">
      <div class="loading-div">
        <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
        <span class="loading-span">{{ 'loading' | translate }}</span>
      </div>
    </div>

    <ng-container *ngIf="!loading">
      <div class="row mt-3">
        <div class="col">
          <span class="subtitle">{{ 'userData' | translate }}</span>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <form #profileLatamUserForm="ngForm">
            <div class="row">
              <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-form-field>
                  <mat-label>{{ 'field.username' | translate }}</mat-label>
                  <input id="latamUserId" name="latamUserId" #latamUserId="ngModel" matInput
                    [(ngModel)]="userLatam.latamUserId" disabled>
                </mat-form-field>
              </div>

              <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-form-field>
                  <mat-label>{{ 'field.name' | translate }}</mat-label>
                  <input id="name" name="name" #name="ngModel" matInput [(ngModel)]="userLatam.name" disabled>
                </mat-form-field>
              </div>

              <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-form-field>
                  <mat-label>{{ 'field.surname' | translate }}</mat-label>
                  <input id="lastName" name="lastName" #lastName="ngModel" matInput [(ngModel)]="userLatam.lastname" disabled>
                </mat-form-field>
              </div>

              <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-form-field>
                  <mat-label>{{ 'field.email' | translate }}</mat-label>
                  <input id="email" name="email" #email="ngModel" matInput [(ngModel)]="userLatam.email" pattern="\b[a-zA-Z0-9._-]+@[a-zA-Z]+\.[a-zA-Z.]+"
                    maxlength="100" disabled>
                </mat-form-field>
              </div>

              <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-form-field>
                  <mat-label>{{ 'field.role' | translate }}</mat-label>
                  <mat-select id="rolesSelect" name="rolesSelect" #rolesSelect="ngModel" [(ngModel)]="userLatam.role"
                    [compareWith]="compareRole" disabled>
                    <mat-option *ngFor="let latamRole of latamRoles" [value]="latamRole">
                      {{ latamRole.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-form-field>
                  <mat-label>{{ 'field.position' | translate }}</mat-label>
                  <mat-select id="positionsSelect" name="positionsSelect" #positionsSelect="ngModel"
                    [(ngModel)]="userLatam.userLatamPosition" [compareWith]="comparePosition" disabled>
                    <mat-option *ngFor="let position of userLatamPositions" [value]="position">
                      {{ position.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-form-field>
                  <mat-label>{{ 'field.selectPos' | translate }}</mat-label>
                  <mat-select id="marketsSelect" name="marketsSelect" #marketsSelect="ngModel" #selectedOptions
                    [(ngModel)]="userLatam.markets" [compareWith]="compareMarket" multiple disabled>
                    <mat-option *ngFor="let market of markets" #marketOption [value]="market" class="select-pos">
                      {{ market.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-form-field>
                  <mat-label>{{ 'field.language' | translate }}</mat-label>
                  <mat-select id="languagesSelect" name="languagesSelect" #languagesSelect="ngModel"
                    [(ngModel)]="userLatam.language" [compareWith]="compareLanguage" disabled>
                    <mat-option *ngFor="let language of languages" [value]="language">
                      {{ language.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div *ngIf="infoUser.isLatamSession()" class="row mt-4">
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
          <!-- este btn lleva al search user -->
          <button mat-raised-button id="backButton" (click)="returnToList()"
            class="btn-volver w-100">{{ 'button.back' | translate }}</button>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

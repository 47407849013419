import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

@Component({
  selector: 'app-detail-waiver',
  templateUrl: './detail-waiver.component.html',
  styleUrls: ['./detail-waiver.component.css']
})
export class DetailWaiverComponent {

  displayedColumnsR = ['pnrNumber', 'pnrType', 'reservationCreationDate', 'NumberOfPassengers', 'pos', 'iata', 'pcc', 'officeId'];
  displayedColumnsPPOS = ['passengerName', 'foid', 'passengerType', 'tkt'];
  displayedColumnsP = ['passengerName', 'foid', 'passengerType'];
  displayedColumnsS = ['segmentNumber', 'carrierMkt', 'flightNumberMkt', 'class', 'origin', 'destination', 'dateHourDeparture', 'status'];
  displayedColumnsTKT = ['tktNumber', 'pnrNum', 'emisionCreationDate', 'posInfo', 'iataInfo',
    'pccInfo', 'officeIdInfo']; // 'tktUsd', 'taxUsd'];
  displayedColumnsV = ['voucherNumber', 'carrierTkt', 'flightNumberTkt', 'classTkt', 'originTkt',
    'destinationTkt', 'dateHourTkt', 'farebasis', 'voucherStatus'];
  dataReservation = new MatTableDataSource();
  dataPassengers = new MatTableDataSource();
  dataSegments = new MatTableDataSource();
  dataTkt = new MatTableDataSource();
  dataVoucher = new MatTableDataSource();
  selectionPassangers = new SelectionModel();
  selectionSegments = new SelectionModel();
  validPrePNR: boolean = false;
  validPostPNR: boolean = false;
  validPostTKT: boolean = false;
  pnrNumber: string = '';
  ticket: string = '';
  loading: boolean = false;

  constructor(public dialogRef: MatDialogRef<DetailWaiverComponent>) { }

  /**
   * method to close the modal
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * method to close the modal
   */
  closeDialog() {
    this.dialogRef.close();
  }



}

import { AgencyChannel } from './agency-channel.model';
import { AgencyTier } from './agency-tier.model';
import { Market } from './market.model';
import { UserSales } from './user-sales.mode';

export class AgencyGroup {
    id: number;
    name: string;
    agencyTier: AgencyTier;
    agencyChannel: AgencyChannel;
    market: Market;
    pptoWaiver: number;
    pptoAd: number;
    showBudget: boolean;
    branchOfficesNumber: number;
    userOfficesNumber: number;
    adminSales: UserSales;
    salesExecutive: UserSales;
    showPresaleBudget: boolean;
    allowPrivateFare: boolean;
    allowPreSaleModule: boolean;
    allowPostSaleModule: boolean;
    selfManaged: boolean;
    mainIata: string;

    constructor(
        name?: string,
        agencyTier?: AgencyTier,
        agencyChannel?: AgencyChannel,
        market?: Market,
        pptoWaiver?: number,
        pptoAd?: number,
        showBudget?: boolean,
        branchOfficesNumber?: number,
        userOfficesNumber?: number,
        adminSales?: UserSales,
        salesExecutive?: UserSales,
        showPresaleBudget?: boolean,
        allowPrivateFare?: boolean,
        allowPreSaleModule?: boolean,
        allowPostSaleModule?: boolean,
        selfManaged?: boolean,
        mainIata?: string
        ) {
            this.name = name;
            this.agencyTier = agencyTier;
            this.agencyChannel = agencyChannel;
            this.market = market;
            this.pptoWaiver = pptoWaiver;
            this.pptoAd = pptoAd;
            this.showBudget = showBudget;
            this.branchOfficesNumber = branchOfficesNumber;
            this.userOfficesNumber = userOfficesNumber;
            this.adminSales = adminSales;
            this.salesExecutive = salesExecutive;
            this.showPresaleBudget = showPresaleBudget;
            this.allowPrivateFare = allowPrivateFare;
            this.allowPreSaleModule = allowPreSaleModule;
            this.allowPostSaleModule = allowPostSaleModule;
            this.selfManaged = selfManaged;
            this.mainIata = mainIata;
    }
}

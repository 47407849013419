<div class="row">
  <div class="col">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="latam-sans breadcrumb-item">
          <a>{{ 'menu.waivers' | translate }}</a>
        </li>
        <li class="breadcrumb-item latam-sans active">
          <a>{{ 'menu.afterSaleManagement' | translate }}</a>
        </li>
      </ol>
    </nav>
  </div>
</div>

<div class="row">
  <div class="col">
    <span class="title">{{ 'menu.afterSaleManagement' | translate }}</span>
  </div>
</div>

<hr>

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, LOCALE_ID } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatOptionSelectionChange } from '@angular/material/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AgencyChannel } from '../../../models/agency-channel.model';
import { AgencyGroup } from '../../../models/agency-group.model';
import { AgencyTier } from '../../../models/agency-tier.model';
import { BudgetType } from '../../../models/budget-type.model';
import { Market } from '../../../models/market.model';
import { Profile } from '../../../models/profile.model';
import { SessionInfo } from './../../../models/SessionInfo.model';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';
import { User } from '../../../models/user.model';

import { CreateBranchOfficeComponent } from '../create-branch-office/create-branch-office.component';
import { CreateEditUserComponent } from '../../user-module/create-edit-user/create-edit-user.component';

import { AgencyGroupService } from '../../../services/agency-group.service';
import { AgencyService } from '../../../services/agency.service';
import { BudgetService } from '../../../services/budget.service';
import { MarketService } from './../../../services/market.service';
import { MessageService } from '../../../services/message.service';
import { ProfilingService } from '../../../services/profiling.service';
import { SessionInfoService } from '../../../services/session-info.service';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';
import { MessageErrorService } from '../../../services/message-error.service';


@Component({
  selector: 'app-profile-agency-group',
  templateUrl: './profile-agency-group.component.html',
  styleUrls: ['./profile-agency-group.component.css']
})
export class ProfileAgencyGroupComponent implements OnInit {
  loading = false;
  reloadUsers = false;

  infoUser$: Promise<SessionInfo> = this.sessionInfoService.getSessionInfoAsPromise();
  profiles = new Profile();
  channelList: AgencyChannel[];
  group = new AgencyGroup();
  markets: Market[];
  tierList: AgencyTier[];
  budgetTypes: BudgetType[] = [];
  budgetType: BudgetType = new BudgetType(null, null);

  private language: string;
  private sidebarMenu = new SidebarMenuModel(SidebarMenuModel.MENU_AGENCIES, SidebarMenuModel.SUBMENU_AGENCY_GROUP);

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private agencyGroupService: AgencyGroupService,
    private messageErrorService: MessageErrorService,
    private agencyService: AgencyService,
    private budgetService: BudgetService,
    private marketService: MarketService,
    private messageService: MessageService,
    private profilesService: ProfilingService,
    private sessionInfoService: SessionInfoService,
    private sidebarShareDataService: SidebarShareDataService,
    private translateService: TranslateService) {
      this.language = locale;
  }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);

    this.activatedRoute.queryParams.subscribe(params => {
      this.group.id = params.id;

      if (!this.group.id) {
        this.infoUser$
          .then(
            sessionInfo => {
              this.group.id = sessionInfo.matrix_id;
              this.loadAgencyGroupProfile();
            }
          )
          .catch(
            error => {
              console.log('error', error);
            }
          );
      } else {
        this.loadAgencyGroupProfile();
      }

      this.loadMarkets();
      this.loadTiers();
      this.loadChannels();
      this.getBudgetTypes(this.language);
    });
  }

  private getBudgetTypes(language: string): void {
    this.budgetTypes = this.budgetService.getBudgetTypes();
  }

  public selectionBudgetTypeChange(event: MatOptionSelectionChange): void {
    this.budgetType = <BudgetType>event.source.value;
  }

  private loadAgencyGroupProfile(): void {
    this.loading = true;

    this.agencyGroupService.getAgencyGroupById(this.group.id).subscribe({
      next: data => {
        this.group = data;
        this.loading = false;
        // TO DO reload lstado de agencias
        // this.loadAgenciesListByGroup(this.group.id);
      },
      error: (error: HttpErrorResponse) => {
        if (error.error!.status === 403) {
          this.infoUser$.then( sessionInfo => {
            if (sessionInfo.isLatamSession()) {
              this.router.navigateByUrl('/latam/home');
            } else {
              this.router.navigateByUrl('/agency/home');
            }
          });
        }
        this.messageErrorService.sendError(error, 'error.loadingAgencyGroupInfo');

        this.loading = false;
        console.log('error', error);
      }
  });
  }

  private loadMarkets() {
    this.marketService.getMarkets().subscribe({
      next: data => {
        this.markets = data;
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.loadingPos');

        console.log('error', error);
      }
  });
  }

  /**
   * Funcion que carga las opciones del comboBox channel
   */
  private loadChannels(): void {
    this.agencyService.getAgencyChannelList().subscribe({
      next: data => {
        this.channelList = data['channels'];
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.loadingChannels');

        console.log('error', error);
      }
  });
  }

  /**
   * Funcion que carga los tiers de la BD
   */
  private loadTiers(): void {
    this.agencyGroupService.getTierList().subscribe({
      next: data => {
        this.tierList = data;
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.loadingTiers');

        console.log('error', error);
      }
  });
  }

  /**
   * Redireccionamiento al listado del grupo agencia
   */
  private returnToList(): void {
    this.router.navigate(['../list'], { relativeTo: this.activatedRoute });
  }

  private compareMarket(market1: Market, market2: Market): boolean {
    return market1 && market2 ? market1.id === market2.id : market1 === market2;
  }

  private compareTier(tier1: AgencyTier, tier2: AgencyTier): boolean {
    return tier1 && tier2 ? tier1.id === tier2.id : tier1 === tier2;
  }

  private compareChannel(channel2_1: AgencyChannel, channel2_2: AgencyChannel): boolean {
    return channel2_1 && channel2_2 ? channel2_1.id === channel2_2.id : channel2_1 === channel2_2;
  }

  /*
  * Crear Sucursal
  */
  private openCreateBranchOfficeDialog(): void {
    const dialogRef = this.dialog.open(CreateBranchOfficeComponent);
    dialogRef.afterClosed().subscribe(() => {
      this.loadAgencyGroupProfile();
    });
  }

  private openCreateUserDialog(): void {
    this.reloadUsers = false;
    const dialogRef = this.dialog.open(CreateEditUserComponent);
    dialogRef.componentInstance.user = new User();
    dialogRef.componentInstance.user.agencyGroup = this.group;
    dialogRef.componentInstance.user.agencyBranchOffice = null;
    dialogRef.componentInstance.isCreate = true;

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.reloadUsers = true;
      }
    });
  }
}

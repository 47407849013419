<div class="container">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="latam-sans breadcrumb-item">
            <a>{{ 'menu.agenciesManagement' | translate }}</a>
          </li>
          <li class="breadcrumb-item latam-sans active" aria-current="page">
            <a>{{ 'menu.agencyGroupManagement' | translate }}</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <span id="registerAgencyTitle" class="title">{{ 'menu.agencyGroupManagement' | translate }}</span>
    </div>
  </div>

  <hr>

  <ng-container *ngIf="profiles.createAgencyGroupManagment">
    <div class="row">
      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <button mat-raised-button id="activarAgenciaButton" (click)="openCreateGroupDialog()"
          class="background-red w-100">{{ 'button.createGroup' | translate }}</button>
      </div>
    </div>
  </ng-container>

  <div *ngIf="loading || dataSource.data.length == 0" class="loading">
    <div class="loading-div">
      <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
      <span class="loading-span">{{ 'loading' | translate }}</span>
    </div>
  </div>

  <ngx-loading [show]="updating"></ngx-loading>

  <div *ngIf="!loading" class="table-group mt-3 mb-3" [hidden]="dataSource.data.length === 0">
    <mat-table #table matSort [dataSource]="dataSource" class="scrolling">
      <ng-container matColumnDef="name">
        <mat-header-cell id="nameColumnHeader" *matHeaderCellDef class="title">
          <div>
            <span>{{ 'field.name' | translate }}</span>
            <span mat-sort-header></span>
          </div>
          <div *ngIf="agenciesGroupsDataFound" class="div-filter">
            <mat-form-field class="filter">
              <input matInput [formControl]="nameFilter">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.name | dashWhenEmpty }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="agencyTierName">
        <mat-header-cell id="agencyTierNameColumnHeader" *matHeaderCellDef class="title">
          <div>
            <span>{{ 'field.tier' | translate }}</span>
            <span mat-sort-header></span>
          </div>
          <div *ngIf="agenciesGroupsDataFound" class="div-filter">
            <mat-form-field class="filter">
              <input matInput [formControl]="agencyTierNameFilter">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.agencyTierName | dashWhenEmpty }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="branchOfficesNumber">
        <mat-header-cell id="branchOfficesNumberColumnHeader" *matHeaderCellDef class="title">
          <div>
            <span>{{ 'field.numberOfBranches' | translate }}</span>
            <span mat-sort-header></span>
          </div>
          <div *ngIf="agenciesGroupsDataFound" class="div-filter">
            <mat-form-field class="filter">
              <input matInput [formControl]="branchOfficesNumberFilter">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.branchOfficesNumber }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="marketName">
        <mat-header-cell id="marketNameColumnHeader" *matHeaderCellDef class="title">
          <div>
            <span>{{ 'field.pos' | translate }}</span>
            <span mat-sort-header></span>
          </div>
          <div *ngIf="agenciesGroupsDataFound" class="div-filter">
            <mat-form-field class="filter">
              <input matInput [formControl]="marketNameFilter">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.marketName | dashWhenEmpty}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="pptoWaiver">
        <mat-header-cell id="pptoWaiverColumnHeader" *matHeaderCellDef class="title">
          <div>
            <span>{{ 'field.waiverBudget' | translate }}</span>
            <span mat-sort-header></span>
          </div>
          <div *ngIf="agenciesGroupsDataFound" class="div-filter">
            <mat-form-field class="filter">
              <input matInput [formControl]="pptoWaiverFilter">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.pptoWaiver | dashWhenEmpty}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="pptoAd">
        <mat-header-cell id="pptoAdColumnHeader" *matHeaderCellDef class="title">
          <div>
            <span>{{ 'field.adBudget' | translate }}</span>
            <span mat-sort-header></span>
          </div>
          <div *ngIf="agenciesGroupsDataFound" class="div-filter">
            <mat-form-field class="filter">
              <input matInput [formControl]="pptoAdFilter">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.pptoAd | dashWhenEmpty}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="notifications">
        <mat-header-cell id="notificationsColumnHeader" *matHeaderCellDef class="title">
          <div>
            <span>{{ 'field.notifyRequests' | translate }}</span>
            <span mat-sort-header></span>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-checkbox name="notificationsCheckbox" color="accent" [(ngModel)]="element.notification"
            (click)="updateWaiverNotifications(element)" [disabled]="updating" class="agency-group-check">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions" *ngIf="profiles.readAgencyGroupProfileLT">
        <mat-header-cell id="actionsColumnHeader" *matHeaderCellDef class="title">
          <div class="ml-3">
            <span>
              {{ 'button.actions' | translate }}
            </span>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button [matMenuTriggerFor]="menu"
            [disabled]="!profiles.updateAgencyGroupManagement && !profiles.readAgencyGroupProfileLT">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngIf="profiles.readAgencyGroupProfileLT" class="btn-mat-menu">
              <span (click)="openGroupProfile(element.id)" class="item-mat-menu">{{ 'button.view' | translate }}</span>
            </button>
            <button mat-menu-item *ngIf="profiles.updateAgencyGroupManagement" class="btn-mat-menu">
              <span (click)="openEditProfile(element.id)" class="item-mat-menu">{{ 'button.edit' | translate }}</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="no-data-found">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell class="no-data-found-row" *matCellDef="let item">{{ 'error.notAgencyGroupsFound' | translate }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="loading-data-error">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell class="loading-data-error-row" *matCellDef="let item">
          <span class="cdk-visually-hidden" #translatedTooltipMessage>{{ 'button.refresh' | translate }}</span>
          <button mat-icon-button (click)="reloadAgenciesGroups()" class="refresh-spinner">
            <mat-icon [matTooltip]="translatedTooltipMessage.innerHTML" [matTooltipPosition]="'right'">refresh
            </mat-icon>
          </button>
          <br>
          <span>{{ 'error.loadingAgencyGroup' | translate }}</span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'highlight': selectedRowIndex === row.id}"
        (mouseover)="highlightRow($event,row)" (mouseout)="highlightRow($event, row)" class="agency-group-row">
      </mat-row>
      <mat-row *matRowDef="let row; columns: ['no-data-found']; when: noAgenciesGroupsDataFound"></mat-row>
      <mat-row *matRowDef="let row; columns: ['loading-data-error']; when: loadingAgenciesGroupsDataError"></mat-row>
    </mat-table>

    <ng-container *ngIf="agenciesGroupsDataFound">
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 30, 50]" [showFirstLastButtons]="false"
         [hidden]="dataSource.data.length === 0">
      </mat-paginator>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="showBudget">
  <button (click)="openDialogBudget()" [disabled]="loading" class="mat-raised-button btn-budget w-100">
    {{ 'USD ' + (branchOfficeId ?
       (monthlyBudgetBranchOffice?.assignedAmount - monthlyBudgetBranchOffice?.spentAmount):
       (monthlyBudgetAgencyGroup?.assignedAmount - monthlyBudgetAgencyGroup?.busyAmount) | number) }}
    <span *ngIf="loading" class="spinner-border spinner-border-sm spinner"></span>
  </button>
  <div class="row">
    <div class="col text-center">
      <span class="budget-label">{{ 'budgetAvailable' | translate }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col text-center">
      <span class="budget-label">
        {{ branchOfficeId ?
           (monthlyBudgetBranchOffice?.month | monthName) + ' ' +  monthlyBudgetBranchOffice?.year :
           (monthlyBudgetAgencyGroup?.month | monthName) + ' ' +  monthlyBudgetAgencyGroup?.year }}
      </span>
    </div>
  </div>
</ng-container>
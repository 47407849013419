<div class="modal-header">
  <div class="row ml-0 mr-0">
    <div class="col-11 col-md-6 col-lg-6 col-xl-7 order-3 order-md-1 order-lg-1 order-xl-1 title">
      <p>
        {{ ('requestDetailId' | translate) + ' ' + idWaiver }}
      </p>
    </div>

    <div
      class="col-11 col-md-5 col-lg-5 col-xl-4 order-1 order-md-2 order-lg-2 order-xl-2 pt-0 pl-0 pb-0 pr-0 alert-waiver-agency alert"
      [ngClass]="{ 'alert-success': tab === 'aprobados',
        'alert-warning': tab === 'pendientes',
        'alert-error': tab === 'rechazados',
        'alert-warning-two': tab === 'pendientes-agencia'
      }">
      <div class="alert-icon"
        [ngClass]="{ 'alert-icon-success': tab === 'aprobados',
          'alert-icon-warning': tab === 'pendientes',
          'alert-icon-error': tab === 'rechazados',
          'alert-icon-warning-two': tab === 'pendientes-agencia'
        }">
        <ng-container *ngIf="tab === 'aprobados'">
          <i class="material-icons">done</i>
        </ng-container>
        <ng-container *ngIf="tab === 'pendientes'">
          <i class="material-icons">query_builder</i>
        </ng-container>
        <ng-container *ngIf="tab === 'pendientes-agencia'">
          <i class="material-icons">warning</i>
        </ng-container>
        <ng-container *ngIf="tab === 'rechazados'">
          <i class="material-icons">clear</i>
        </ng-container>
      </div>

      <div class="alert-text w-100"
        [ngClass]="getStyleClass()">
        <ng-container *ngIf="tab === 'aprobados'">
          <p class="mb-0">{{ 'approved' | translate }}</p>
        </ng-container>
        <ng-container *ngIf="tab === 'pendientes'">
          <p class="mb-0">{{ 'pending' | translate }}</p>
        </ng-container>
        <ng-container *ngIf="tab === 'rechazados'">
          <p class="mb-0">{{ 'denied' | translate }}</p>
        </ng-container>
        <ng-container *ngIf="tab === 'pendientes-agencia'">
          <p class="mb-0">{{ 'pendingAgency' | translate}}</p>
        </ng-container>
      </div>
    </div>

    <div class="col-1 order-2 order-md-3 order-lg-3 order-xl-3 pr-0">
      <div class="close-modal">
        <button class="btn-close-modal" (click)="closeDialog()">
          <mat-icon class="close-icon">clear</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal-content detail-modal">
  <div *ngIf="loading" class="loading">
    <div class="loading-div">
      <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
      <span class="loading-span">{{ 'sendingRequest' | translate }}</span>
    </div>
  </div>

  <div *ngIf="!loading" class="modal-content-body">
    <!-- info aprobado -->
    <div *ngIf="tab == 'aprobados'">
      <div class="row mt-4 ml-3 mr-3">
        <div class="col">
          <span class="subtitle">{{ 'approvalInformation' | translate }}</span>
        </div>
      </div>

      <div class="table-waiver-agency mat-elevation-z1">
        <mat-table #table [dataSource]="dataSource">
          <ng-container matColumnDef="dateAprob">
            <mat-header-cell *matHeaderCellDef>{{ 'field.approvalDateHour' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">
              {{ object.waiver.statusDate | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="commentAprob">
            <mat-header-cell *matHeaderCellDef>{{ 'field.remarks' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">{{ object.waiver.statusComment | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="folioAprob">
            <mat-header-cell *matHeaderCellDef>{{ 'field.waiverRecord' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">{{ object.waiver.folio | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="waiverValueAprob">
            <mat-header-cell *matHeaderCellDef>{{ 'field.waiverValueUsd' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">
              {{ object.waiver.valorization | number: '1.2-2':'en-US' | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsA" class="waiver-gency-row"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsA;" class="waiver-gency-row"></mat-row>
        </mat-table>
      </div>
    </div>

    <!-- info rechazo  -->
    <div *ngIf="tab == 'rechazados'">
      <div class="row mt-4 ml-3 mr-3">
        <div class="col">
          <span class="subtitle">{{ 'denialInformation' | translate }}</span>
        </div>
      </div>

      <div class="table-waiver-agency mat-elevation-z1">
        <mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="dateRech">
            <mat-header-cell *matHeaderCellDef>{{ 'field.denialDateHour' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">
              {{ object.waiver.statusDate | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="motivoRech">
            <mat-header-cell *matHeaderCellDef>{{ 'field.denialReason' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">{{ object.waiver.rejectionMotive?.name | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="commentRech">
            <mat-header-cell *matHeaderCellDef>{{ 'field.remarks' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">{{ object.waiver.statusComment | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsR" class="waiver-gency-row"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsR;" class="waiver-gency-row"></mat-row>
        </mat-table>
      </div>
    </div>

    <!-- info pendiente agencia  -->
    <div *ngIf="tab === 'pendientes-agencia'">
      <div class="row mt-4 ml-3 mr-3">
        <div class="col">
          <span class="subtitle">{{ 'pendingAgencyObservation' | translate }}</span>
        </div>
      </div>

      <div class="table-waiver-agency mat-elevation-z1">
        <mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="datePA">
            <mat-header-cell *matHeaderCellDef>{{ 'field.pendingAgencyDateHour' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">
              {{ object.waiver.statusDate | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="commentPA">
            <mat-header-cell *matHeaderCellDef>{{ 'field.remarks' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">{{ object.waiver.statusComment | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsPA" class="waiver-gency-row"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsPA;" class="waiver-gency-row"></mat-row>
        </mat-table>
      </div>
    </div>

    <!-- info solicitud -->
    <div class="row mt-4 ml-3 mr-3">
      <div class="col">
        <span class="subtitle">{{ 'requestInformation' | translate }}</span>
      </div>
    </div>

    <div class="table-waiver-agency mat-elevation-z1">
      <mat-table #table [dataSource]="dataSource">
        <ng-container matColumnDef="solicitante">
          <mat-header-cell *matHeaderCellDef>{{ 'field.requestedBy' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let object">
            {{ (object.waiver.user.name | dashWhenEmpty) + ' ' + (object.waiver.user.lastname | dashWhenEmpty) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tipo">
          <mat-header-cell *matHeaderCellDef>{{ 'field.type' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let object">{{ object.waiver.type.name | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="motivo">
          <mat-header-cell *matHeaderCellDef>{{ 'field.reason' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let object">{{ object.waiver.motive?.name | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="date_hour">
          <mat-header-cell *matHeaderCellDef>{{ 'field.requestDateHour' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let object">
            {{ object.waiver.creationDate | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" class="waiver-gency-row"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="waiver-gency-row"></mat-row>
      </mat-table>
    </div>

    <div class="table-waiver-agency mat-elevation-z1 mt-3">
      <mat-table #table [dataSource]="dataSource">
        <ng-container matColumnDef="pnrAgencia">
          <mat-header-cell *matHeaderCellDef>{{ 'field.agencyPnr' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let object">{{object.waiver.pnrAgency | dashWhenEmpty}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="files">
          <mat-header-cell *matHeaderCellDef>{{ 'field.attachedFiles' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let object"><span *ngIf="object.waiver.files.length==0">—</span>
            <app-download-list *ngIf="object.waiver.files.length!=0" [files]="object.waiver.files"></app-download-list>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="coment">
          <mat-header-cell *matHeaderCellDef>{{ 'field.remarks' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let object">{{object.waiver.aditionalComment | dashWhenEmpty}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsCo" class="waiver-gency-row"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsCo;" class="waiver-gency-row"></mat-row>
      </mat-table>
    </div>

    <!-- detalle pnr -->
    <div *ngIf="document == 'pnr'">
      <div class="row mt-4 mr-0 ml-0">
        <div class="col text-center title">
          <p>{{ 'pnrDetail' | translate }}</p>
        </div>
      </div>

      <!-- info reserva -->
      <div class="row ml-3 mr-3">
        <div class="col">
          <span class="subtitle">{{ 'bookingInformation' | translate }}</span>
        </div>
      </div>

      <div class="table-waiver-agency mat-elevation-z1">
        <mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="pnrNumber">
            <mat-header-cell *matHeaderCellDef>{{ 'field.pnr' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">{{ object.waiver.documentNumber | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="pnrType">
            <mat-header-cell *matHeaderCellDef>{{ 'field.pnrType' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">{{ object.waiver.documentPnrType | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="reservationCreationDate">
            <mat-header-cell *matHeaderCellDef>{{ 'field.bookingCreation' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">
              {{ object.waiver.documentCreationDate | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="numberOfPassengers">
            <mat-header-cell *matHeaderCellDef>{{ 'field.numberPassengers' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">{{ object.waiver.passengers.length }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="pos">
            <mat-header-cell *matHeaderCellDef>{{ 'field.pos' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">{{ object.waiver.documentPos | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="iata">
            <mat-header-cell *matHeaderCellDef>{{ 'field.iata' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">{{ object.waiver.documentIata | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="pcc">
            <mat-header-cell *matHeaderCellDef>{{ 'field.pcc' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">{{ object.waiver.documentPcc | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="officeId">
            <mat-header-cell *matHeaderCellDef>{{ 'field.officeId' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">{{ object.waiver.documentOfficeId | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsP" class="waiver-gency-row"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsP;" class="waiver-gency-row"></mat-row>
        </mat-table>
      </div>

      <!-- info pasajero -->
      <div class="row mt-4 ml-3 mr-3">
        <div class="col">
          <span class="subtitle">{{ 'passengerInfo' | translate }}</span>
        </div>
      </div>

      <div class="table-waiver-agency mat-elevation-z1">
        <mat-table #table [dataSource]="dataPassengers">
          <ng-container matColumnDef="passengerName">
            <mat-header-cell *matHeaderCellDef>{{ 'field.passengerName' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">{{ data.firstName | partialDisplay:'#' : 1 | dashWhenEmpty }}
              {{data.lastName | partialDisplay:'#' : 1 | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="foid">
            <mat-header-cell *matHeaderCellDef>{{ 'field.foid' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">{{ data.foid | partialDisplay:'#' :-2 | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="passengerType">
            <mat-header-cell *matHeaderCellDef>{{ 'field.passengerType' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">{{ data.type | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <!-- Sólo se muestra si el waiver es post-venta -->
          <ng-container matColumnDef="passengerTKT">
            <mat-header-cell *matHeaderCellDef>{{ 'field.associatedTkt' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">{{ data.ticketNumber | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsI" class="waiver-gency-row"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsI;" class="waiver-gency-row"></mat-row>
        </mat-table>
      </div>

      <!-- info segmento -->
      <div class="row mt-4 ml-3 mr-3">
        <div class="col">
          <span class="subtitle">{{ 'segmentInformation' | translate }}</span>
        </div>
      </div>

      <div class="table-waiver-agency mat-elevation-z1 mb-3">
        <mat-table #table [dataSource]="dataSegments">
          <ng-container matColumnDef="segmentNumber">
            <mat-header-cell *matHeaderCellDef>{{ 'field.segmentNumber' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.segmentNumber | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="carrierMarketing">
            <mat-header-cell *matHeaderCellDef>{{ 'field.mktCarrier' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.carrierMarketing | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="flightNumber">
            <mat-header-cell *matHeaderCellDef>{{ 'field.mktFlightNumber' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.flightNumber | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="class">
            <mat-header-cell *matHeaderCellDef>{{ 'field.class' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.class | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="source">
            <mat-header-cell *matHeaderCellDef>{{ 'field.from' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.source | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="destination">
            <mat-header-cell *matHeaderCellDef>{{ 'field.to' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.destination | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="departureDateTime">
            <mat-header-cell *matHeaderCellDef>{{ 'field.departureDate' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.departureDateTime | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>{{ 'field.segmentStatus' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.status | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsS" class="waiver-gency-row"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsS;" class="waiver-gency-row"></mat-row>
        </mat-table>
      </div>
    </div>

    <!-- detalle tkt -->
    <div *ngIf="document=='tkt'">
      <div class="row mt-4 mr-0 ml-0">
        <div class="col text-center title">
          <p>{{ 'tktDetail' | translate }}</p>
        </div>
      </div>

      <!-- Información del ticket -->
      <div class="row ml-3 mr-3">
        <div class="col">
          <span class="subtitle">{{ 'tktInformation' | translate }}</span>
        </div>
      </div>

      <div class="table-waiver-agency mat-elevation-z1">
        <mat-table #table [dataSource]="dataSource">
          <ng-container matColumnDef="passengerTKT">
            <mat-header-cell *matHeaderCellDef>{{ 'field.tkt' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">{{ object.waiver.documentNumber | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="pnrNumber">
            <mat-header-cell *matHeaderCellDef>{{ 'field.pnr' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">{{ object.waiver.documentTktPnrReference | dashWhenEmpty }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="emisionCreationDate">
            <mat-header-cell *matHeaderCellDef>{{ 'field.issueDate' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">
              {{ object.waiver.documentCreationDate | dateFormat: 'DD-MMM-YY' | uppercase | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="pos">
            <mat-header-cell *matHeaderCellDef>{{ 'field.pos' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">{{ object.waiver.documentPos | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="iata">
            <mat-header-cell *matHeaderCellDef>{{ 'field.iata' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">{{ object.waiver.documentIata | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="pcc">
            <mat-header-cell *matHeaderCellDef>{{ 'field.pcc' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">{{ object.waiver.documentPcc | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="officeId">
            <mat-header-cell *matHeaderCellDef>{{ 'field.officeId' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let object">{{ object.waiver.documentOfficeId | dashWhenEmpty }}
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsT" class="waiver-gency-row"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsT" class="waiver-gency-row"></mat-row>
        </mat-table>
      </div>

      <!-- Información del cupón -->
      <div class="row mt-4 ml-3 mr-3">
        <div class="col">
          <span class="subtitle">{{ 'couponInformation' | translate }}</span>
        </div>
      </div>

      <div class="table-waiver-agency mat-elevation-z1 mb-3">
        <mat-table #table [dataSource]="dataCoupons">
          <ng-container matColumnDef="voucherNumber">
            <mat-header-cell *matHeaderCellDef>{{ 'field.couponNumber' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">{{ data.couponNumber | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="carrierMarketing">
            <mat-header-cell *matHeaderCellDef>{{ 'field.carrier' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.carrierMarketing | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="flightNumber">
            <mat-header-cell *matHeaderCellDef>{{ 'field.flightNumber' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.flightNumber | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="class">
            <mat-header-cell *matHeaderCellDef>{{ 'field.class' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.class | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="source">
            <mat-header-cell *matHeaderCellDef>{{ 'field.from' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.source | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="destination">
            <mat-header-cell *matHeaderCellDef>{{ 'field.to' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.destination | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="departureDateTime">
            <mat-header-cell *matHeaderCellDef>{{ 'field.departureDate' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.departureDateTime | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="farebasis">
            <mat-header-cell *matHeaderCellDef>{{ 'field.farebasis' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">{{ data.fareBasis | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="voucherStatus">
            <mat-header-cell *matHeaderCellDef>{{ 'field.couponStatus' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">{{ data.status | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsC" class="waiver-gency-row"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsC;" class="waiver-gency-row"></mat-row>
        </mat-table>
      </div>
    </div>
  </div>

  <div *ngIf = "tab === 'pendientes-agencia' && !loading">
    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'button.updateRequest' | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="col justify-content-center">
          <div class="row mt-4 justify-content-center">
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <button mat-raised-button id="adj-btn" matTooltip="{{ 'tooltip.allowedExtensions' | translate }}"
                matTooltipPosition="after" [disabled]="files.length == 2" (click)="onClick()"
                class="background-blue w-100">{{ 'button.attachFile' | translate }}</button>
              <mat-hint class="opcional mt-1">{{ 'tooltip.maxSize' | translate }}</mat-hint>
            </div>

            <div class="col-6 col-md-8 col-lg-6 col-xl-6 containerAdjunto">
              <ul class="filename-list">
                <li class="filename-list-item no-files" *ngIf="files.length == 0">
                  <span class="filename-span">{{ 'warning.fileRequired' | translate }}</span>
                </li>
                <li class="filename-list-item" *ngFor="let file of files" matTooltipPosition="after"
                  [matTooltip]="file.data.name" [@fadeInOut]="file.state">
                  <span class="filename-span" id="file-label">
                    {{ file.data.name }}
                  </span>
                  <a title="Cancel" (click)="cancelFile(file)" *ngIf="file.canCancel">
                    <mat-icon *ngIf="!file.deletingFile" class="cancelAdjunto">close</mat-icon>
                    <div class="deleting-loader" *ngIf="file.deletingFile">
                      <svg class="spinner" width="24px" height="24px" viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                        </circle>
                      </svg>
                    </div>
                  </a>
                </li>
              </ul>
              <input type="file" id="fileUpload" name="fileUpload" multiple="multiple" accept="{{accept}}"
                style="display:none;" />
            </div>
          </div>

          <div class="row mt-3 justify-content-center">
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <label class="text-nowrap camp-txt-lib">{{ 'field.addComment' | translate }}</label>
              <mat-hint class="opcional">{{ 'tooltip.optional' | translate }}</mat-hint>
            </div>

            <div class="col-8 col-md-8 col-lg-6 col-xl-6">
              <div class="text-libre-tab">
                <textarea matInput name="statusComment" #message placeholder="{{ 'field.enterComment' | translate }}"
                  maxlength="500" [(ngModel)]="statusComment" class="aditional-comment"></textarea>
                <mat-hint class="text-area" align="end">{{ message.value.length }} / 500</mat-hint>
              </div>
            </div>
          </div>

          <div class="row mt-3 ml-0 mr-0 justify-content-center">
            <div class="col-md-3">
              <button mat-raised-button id="rejectWaiverButton"
                (click)="updateWaiverWithAgencyCorrections()"
                class="background-blue w-100">Enviar observaciones</button>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <!-- forms aprobar - rechazar - agencia pendiente-->
  <ng-container *ngIf="tab == 'pendientes' && profiles.waiverApproveRejectManual">
    <div class="btn-div">
      <div [@slideInOut]="animationState" class="divFooter">
        <mat-card class="edit-waiver">
          <mat-card-content>
            <mat-tab-group #tabGroup class="tab-group"
              [ngClass]="{'tab-min': approveReject, 'tab-max': !approveReject }"
              (selectChange)="toggleShowTab('divFooter')">
              <mat-tab>
                <ng-template mat-tab-label>
                  <div (click)="toggleShowTab('divFooter')">{{ 'button.approve' | translate }}</div>
                  <div class="bottom-border-approve"> </div>
                </ng-template>

                <form>

                  <div class="row mt-4 ml-3 mr-3 ml-0 mr-0">

                    <div class="col-6 col-md-3 col-lg-3 col-xl-3">
                      <mat-form-field>
                        <mat-label>{{ 'field.waiverValueUsd' | translate }}</mat-label>
                        <input matInput id="waiverValueInput" name="wValue" #wValue="ngModel"
                          pattern="([0-9])+((.|,)[0-9][0-9]?)?" required [maxlength]="20" [(ngModel)]="waiverValue" 
                          [disabled]="sessionInfo.isSelfManaged"/>
                      </mat-form-field>
                    </div>

                    <div class="col-6 col-md-3 col-lg-3 col-xl-3" [hidden]="sessionInfo.isSelfManaged">
                      <textarea matInput name="approvalComment" #messageAprobar maxlength="500" [(ngModel)]="approvalComment"
                        placeholder="{{ 'field.enterComment' | translate }}" class="textarea-approve"></textarea>
                      <mat-hint class="comment-hint">{{ messageAprobar.value.length }} / 500</mat-hint>
                    </div>

                    <div class="col-6 col-md-3 col-lg-3 col-xl-3 pl-0">
                      <div class="row ml-0 mr-0 justify-content-center">
                        <div class="col-12 col-lg-10 col-xl-10 pl-0 pr-0 text-center">
                          <mat-checkbox ngDefaultControl id="approveCheck" name="confirmApproval" type="checkbox"
                            [(ngModel)]="confirmApproval">{{ 'field.confirm' | translate }}
                          </mat-checkbox>
                        </div>
                      </div>

                      <div class="row ml-0 mr-0 justify-content-center">
                        <div class="col-12 col-lg-10 col-xl-10 pl-0 pr-0">
                          <button mat-raised-button id="approveWaiverButton"
                            [disabled]="!confirmApproval || !wValue.value || (wValue.errors && (wValue.dirty || wValue.touched))"
                            (click)="approveWaiver()"
                            class="btn-approval w-100">{{ 'button.approveRequest' | translate }}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label id="detail-list-waiver-latam-deny">
                  <div (click)="toggleShowTab('divFooter')">{{ 'button.deny' | translate }}</div>
                </ng-template>

                <form #rechazoWaiverForm="ngForm">
                  <div class="row mt-4 ml-3 mr-3 ml-0 mr-0">
                    <div class="col-6 col-md-4 col-lg-4 col-xl-4" id="detail-list-waiver-latam-motive-reject">
                      <mat-form-field>
                        <mat-label>{{ 'field.reason' | translate }}</mat-label>
                        <mat-select ngDefaultControl name="motivo" #motive="ngModel" required [(ngModel)]="selectedMotive">
                          <mat-option *ngFor="let motive of motives" [value]="motive">{{ motive.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="motive.errors && (motive.dirty || motive.touched) && motive.errors.required">
                          {{ 'field.error.selectReasonRejection' | translate }}</mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-6 col-md-4 col-lg-4 col-xl-4" id="detail-list-waiver-latam-rejection-comment">
                      <ng-container *ngIf="selectedMotive.id != -1">
                        <textarea matInput name="rejectionComment" #messageRechazar maxlength="500" [required]="selectedMotive.id == 4"
                          placeholder="{{ 'field.enterComment' | translate }}" [(ngModel)]="rejectionComment" class="textarea-approve"></textarea>
                        <mat-hint class="comment-hint">{{ messageRechazar.value.length }} / 500</mat-hint>
                      </ng-container>
                    </div>

                    <div class="col-6 col-md-4 col-lg-4 col-xl-4 offset-6 offset-md-0 offset-lg-0 offset-xl-0" id="detail-list-waiver-latam-confirm">
                      <div class="row ml-0 mr-0 justify-content-center">
                        <div class="col-12 col-lg-10 col-xl-10 pl-0 pr-0 text-center">
                          <mat-checkbox ngDefaultControl id="rejectCheck" name="confirmRejection" type="checkbox"
                            [(ngModel)]="confirmRejection">
                            {{ 'field.confirm' | translate }}</mat-checkbox>
                        </div>
                      </div>

                      <div class="row ml-0 mr-0 justify-content-center" id="detail-list-waiver-latam-reject-button">
                        <div class="col-12 col-lg-10 col-xl-10 pl-0 pr-0 ">
                          <button mat-raised-button id="rejectWaiverButton"
                            [disabled]="!confirmRejection || selectedMotive.id == -1 || rechazoWaiverForm.invalid"
                            (click)="rejectWaiver()"
                            class="background-red w-100">{{ 'button.denyRequest' | translate }}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </mat-tab>


            </mat-tab-group>
          </mat-card-content>
        </mat-card>
      </div>

      <button mat-raised-button *ngIf="tab == 'pendientes'"
        (click)="approveReject = !approveReject; toggleShowDiv('divFooter')" class="hide-button">
        <mat-icon matSuffix>{{ approveReject ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
      </button>
    </div>
  </ng-container>
</div>

import { Component, OnInit } from '@angular/core';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-waiver-home',
    templateUrl: './waiver-home.component.html',
    styleUrls: ['./waiver-home.component.css']
})
export class WaiverHomeComponent implements OnInit{

    sideBarMenuOptions: any[] = [
        {
            route: 'waivers/latam', 
            sideBarMenu: new SidebarMenuModel(
                SidebarMenuModel.MENU_WAIVERS, 
                SidebarMenuModel.SUBMENU_WAIVER_COURTESY_REQUEST
            )
        },
        {
            route: 'waivers/policies', 
            sideBarMenu: new SidebarMenuModel(
                SidebarMenuModel.MENU_WAIVERS, 
                SidebarMenuModel.SUBMENU_WAIVER_POLICY_REQUEST
            )
        }
    ];

	currentModule: string;

    constructor(
        private sidebarShareDataService: SidebarShareDataService,
        private readonly router: Router,
		private readonly route: ActivatedRoute
	){ }	
    
	
    ngOnInit(): void {
		this.currentModule = this.route.snapshot?.data['module'];
        
        this.setSideBarMenuForCurrentModule();
    }

	private setSideBarMenuForCurrentModule(){
		const actualRoute: string = this.router.url;

        const siderBarMenu: SidebarMenuModel = this.sideBarMenuOptions
            .find(sideBar => actualRoute.includes(sideBar.route))
            ?.sideBarMenu;

        this.sidebarShareDataService.changePanelOpened(siderBarMenu);
	}
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

import { AgencyChannel } from '../../../models/agency-channel.model';
import { AgencyGroup } from '../../../models/agency-group.model';
import { AgencyTier } from '../../../models/agency-tier.model';
import { Market } from '../../../models/market.model';
import { Profile } from '../../../models/profile.model';
import { UserSales } from '../../../models/user-sales.mode';

import { AgencyGroupService } from '../../../services/agency-group.service';
import { AgencyService } from '../../../services/agency.service';
import { MarketService } from './../../../services/market.service';
import { MessageService } from '../../../services/message.service';
import { ProfilingService } from '../../../services/profiling.service';
import { MessageErrorService } from '../../../services/message-error.service';
import { AgencyGroupUtilService } from '../../../services/agency-group-util.service';


@Component({
  selector: 'app-create-agency-group',
  templateUrl: './create-agency-group.component.html',
  styleUrls: ['./create-agency-group.component.css']
})
export class CreateAgencyGroupComponent implements OnInit {
  group: AgencyGroup = new AgencyGroup();

  tierList: AgencyTier[] = [];
  channelList: AgencyChannel[] = [];
  markets: Market[] = [];
  adminSalesList: UserSales[] = [];
  salesExecutiveList: UserSales[] = [];

  loading: boolean = false;
  userMarketIds: number | number[];

  profiles = new Profile();
  postNotAllowed = ['PR', 'PA', 'CR'];

  constructor(
    public dialogRef: MatDialogRef<CreateAgencyGroupComponent>,
    private agencyGroupService: AgencyGroupService,
    private agencyService: AgencyService,
    private marketService: MarketService,
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private profilesService: ProfilingService,
    private translateService: TranslateService,
    private agencyGroupUtil: AgencyGroupUtilService) { }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.loadTiers();
    this.loadChannels();
    this.loadMarkets();
    this.loadAdminSaless();
    this.loadSalesExecutives();
    this.group.allowPrivateFare = false;
    this.group.allowPreSaleModule = false;
    this.group.allowPostSaleModule = false;
    this.group.showBudget = false;
    this.group.selfManaged = false;
  }

  /**
   * Funcion que carga los tiers de la BD
   */
  private loadTiers() {
    this.agencyGroupUtil.loadTiers(this);
  }

  /**
   * metodo para cerrar la modal
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * Funcion que carga las opciones del comboBox channel
   */
  private loadChannels() {
    this.agencyGroupUtil.loadChannels(this);
  }

  private loadMarkets() {
    this.marketService.getMarkets().subscribe({
      next: data => {
        this.markets = this.getMarketsFilter(data);

        if (typeof this.userMarketIds === 'number') {
          const userMarketIdsAux: number = this.userMarketIds;
          this.markets = this.markets.filter(item => userMarketIdsAux === item.id);
        } else {
          const userMarketIdsAux: number[] = this.userMarketIds;
          this.markets = this.markets.filter(item => userMarketIdsAux.indexOf(item.id) >= 0);
        }
      },
      error: (error: HttpErrorResponse) => {
        console.log('error', error);
      }
  });
  }

  private getMarketsFilter(data: Market[]){
    return data.filter((market: Market) => !this.postNotAllowed.includes(market.name));
  }

  /**
   * Funcion que carga las opciones del comboBox adminSales
   */
  private loadAdminSaless() {
    this.agencyGroupUtil.loadAdminSales(this);
  }

  /**
   * Funcion que carga las opciones del comboBox salesExecutive
   */
  private loadSalesExecutives() {
    this.agencyGroupUtil.loadSalesExecutives(this);
  }


  public saveGroup(): void {
    this.loading = true;

    this.agencyGroupService.createAgencyGroup(this.group)
      .subscribe({
        next: data => {
          this.loading = false;

          this.messageService.showSuccessMessage(
            this.translateService.instant('button.confirm'),
            this.translateService.instant('popup.wellDone'),
            this.translateService.instant('success.createAgencyGroup')
          );

          this.closeDialog();
        },
        error: (error: HttpErrorResponse) => {
          this.messageErrorService.sendError(error, 'error.createAgencyGroup');

          this.loading = false;
          console.log('error', error);
        }
  });
  }
}

<div class="container">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="latam-sans breadcrumb-item">
            <a>{{ 'menu.agenciesManagement' | translate }}</a>
          </li>
          <li class="latam-sans breadcrumb-item">
            <a>{{ 'menu.activateAgency' | translate }}</a>
          </li>
          <li class="breadcrumb-item latam-sans active" aria-current="page">
            <a>{{ agency?.agencyData?.tradeName | dashWhenEmpty }}</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <span id="sectionTitle" class="title">{{ 'menu.activateAgency' | translate }}</span>
    </div>
  </div>

  <hr>

  <div *ngIf="loading" class="loading">
    <div class="loading-div">
      <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
      <span class="loading-span">{{ 'loading' | translate }}</span>
    </div>
  </div>

  <div *ngIf="!loading" class="row">
    <div class="col">
      <span id="agencyInformationSectionTitle" class="subtitle">{{ 'agencyInformation' | translate }}</span>
    </div>
  </div>

  <div *ngIf="!loading" class="row mt-3">
    <div class="col table-responsive">
      <table class="table table-bordered">
        <tbody>
          <tr class="table-tuple">
            <th class="text-nowrap" scope="row">{{ 'field.tradeName' | translate }}</th>
            <td class="text-content">{{ agency?.agencyData?.tradeName | dashWhenEmpty }}</td>
            <th class="text-nowrap" scope="row">{{ 'field.typeOfTarget' | translate }}</th>
            <td class="text-content">{{ agency?.segmentation[0]?.value | dashWhenEmpty }}</td>
          </tr>
          <tr class="table-tuple">
            <th class="text-nowrap" scope="row">{{ 'field.agencyGroup' | translate }}</th>
            <td class="text-content">{{  agency?.agencyGroup[0]?.value | dashWhenEmpty }}</td>
            <th class="text-nowrap" scope="row">{{ 'field.channel1' | translate }}</th>
            <td class="text-content">{{ agency?.channelType[1]?.value | dashWhenEmpty }}</td>
          </tr>
          <tr class="table-tuple">
            <th class="text-nowrap" scope="row">{{ 'field.iata' | translate }}</th>
            <td class="text-content">{{ agency?.iata?.iataCode | dashWhenEmpty }}</td>
            <th class="text-nowrap" scope="row">{{ 'field.channel2' | translate }}</th>
            <td class="text-content">{{ agency?.channelType[2]?.value | dashWhenEmpty }}</td>
          </tr>
          <tr class="table-tuple">
            <th class="text-nowrap" scope="row">{{ 'field.taxId' | translate }}</th>
            <td class="text-content">{{ agency?.agencyData?.taxId | dashWhenEmpty }}</td>
            <th class="text-nowrap" scope="row">{{ 'field.channel3' | translate }}</th>
            <td class="text-content">{{ agency?.channelType[3]?.value | dashWhenEmpty }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Contact Information -->
  <div *ngIf="!loading" class="row mt-4">
    <div class="col">
      <span class="subtitle">{{ 'contactInformation' | translate }}</span>
    </div>
  </div>

  <div *ngIf="!loading" class="row mt-3">
    <div class="col">
      <table class="table table-bordered">
        <tbody>
          <tr class="table-tuple">
            <th class="text-nowrap" scope="row">{{ 'field.address' | translate }}</th>
            <td class="text-content">{{ agency?.address?.address | dashWhenEmpty }}</td>
            <th class="text-nowrap" scope="row">{{ 'field.country' | translate }}</th>
            <td class="text-content">{{ agency?.address?.country | dashWhenEmpty }}</td>
          </tr>
          <tr class="table-tuple">
            <th class="text-nowrap" scope="row">{{ 'field.city' | translate }}</th>
            <td class="text-content">{{ agency?.address?.city | dashWhenEmpty }}</td>
            <th class="text-nowrap" scope="row">{{ 'field.phoneNumber' | translate }}</th>
            <td class="text-content">
              {{ agency?.address?.number | dashWhenEmpty }}
            </td>
          </tr>
          <tr class="table-tuple">
            <th class="text-nowrap" scope="row">{{ 'field.state' | translate }}</th>
            <td class="text-content">{{ agency?.address?.state | dashWhenEmpty }}</td>
            <th class="text-nowrap" scope="row">{{ 'field.legalRepresentative' | translate }}</th>
            <td class="text-content"> {{ agency?.agencyData?.legalName | dashWhenEmpty }} </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Additional Information -->
  <div *ngIf="!loading" class="row mt-4">
    <div class="col table-responsive">
      <span class="subtitle">{{ 'additionalInformation' | translate }}</span>
    </div>
  </div>

  <form #agencyActivationForm="ngForm" *ngIf="!loading">
    <div *ngIf="!saving" class="row mt-2">
      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <mat-form-field>
          <mat-label>{{ 'field.officeId' | translate }}</mat-label>
          <input matInput id="officeId" name="officeId" #officeId="ngModel" [(ngModel)]="officeID" [maxlength]="10"
            pattern="([a-zA-Z0-9])+" />
          <mat-error *ngIf="officeId.errors && (officeId.dirty || officeId.touched) && officeId.errors.pattern">
            {{ 'field.error.invalidOfficeId' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <mat-form-field>
          <mat-label>{{ 'field.pcc' | translate }}</mat-label>
          <input matInput id="pccControl" name="pccControl" #pccControl="ngModel" [(ngModel)]="pcc" [maxlength]="10" pattern="([a-zA-Z0-9])+" />
          <mat-error
            *ngIf="pccControl.invalid && (pccControl.dirty || pccControl.touched) && pccControl.errors.pattern">
            {{ 'field.error.invalidPCC' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <mat-form-field>
          <mat-label>{{ 'field.market' | translate }}</mat-label>
          <mat-select id="marketsSelect" name="marketsSelect" #marketsSelect="ngModel" [(ngModel)]="selectedMarket" required
            (selectionChange)="getAgencyGroupList(selectedMarket.id)">
            <mat-option #marketOption *ngFor="let market of markets" [value]="market">
              {{ market.name }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="marketsSelect.invalid && (marketsSelect.dirty || marketsSelect.touched) && marketsSelect.errors.required">
            {{ 'field.error.required' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <mat-form-field>
          <mat-label>{{ 'field.agencyGroup' | translate }}</mat-label>
          <mat-select id="agencyGroup" name="agencyGroup" #agencyGroup="ngModel" [(ngModel)]="selectedGroup"
            [disabled]="agencyGroupList.length === 0">
            <mat-option *ngFor="let agencyGroup of agencyGroupList" [value]="agencyGroup">
              {{ agencyGroup.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <mat-checkbox id="generatePiaCode" name="generatePiaCode" [(ngModel)]="generatePiaCode"
          (change)="checkedPia($event)">{{ 'field.generatePiaCode' | translate }}</mat-checkbox>
      </div>

      <div *ngIf="generatePiaCode" class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <mat-form-field>
          <mat-label>{{ 'field.piaAgencyType' | translate }}</mat-label>
          <mat-select id="piaAgencyType" name="piaAgencyType" #piaAgencyType="ngModel" [(ngModel)]="selectedPiaAgencyType" required
            [disabled]="piaAgencyTypeList.length === 0">
            <mat-option *ngFor="let piaAgencyType of piaAgencyTypeList" [value]="piaAgencyType">
              {{ piaAgencyType.name }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="piaAgencyType.invalid && (piaAgencyType.dirty || piaAgencyType.touched) && piaAgencyType.errors.required">
            {{ 'field.error.required' | translate }}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="saving" class="loader loading">
      <div class="loading-div">
        <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
        <span class="loading-span">{{ 'loading' | translate }}</span>
      </div>
    </div>

    <hr>

    <div class="row mb-3 justify-content-between" *ngIf="profiles.createActivateAgency && !loading">
      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <button mat-raised-button id="backButton" [disabled]="saving" (click)="returnToSearch()"
            class="btn-volver w-100">{{ 'button.back' | translate }}</button>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <button mat-raised-button id="activeButton"
          [disabled]="!profiles.createActivateAgency || agencyActivationForm.invalid || saving"
          (click)="activateAgency()" class="background-red w-100">{{ 'button.activate' | translate }}</button>
      </div>
    </div>
  </form>

</div>

import { Component, OnInit } from '@angular/core';
import { FareRegulationService } from '../../../services/fare-regulation.service';
import { AncillaryDetail, Coupon, CouponBaggage, FareDetailResponse, FlightDetail, TicketInfo } from '../../../interfaces/fare-detail-response.interface';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { SessionInfoService } from '../../../services/session-info.service';

@Component({
  selector: 'app-fare-regulation',
  templateUrl: './fare-regulation.component.html',
  styleUrls: ['./fare-regulation.component.css']
})
export class FareRegulationComponent implements OnInit {

  baggagePanelOpenState = false;
  seatsPanelOpenState = false;

  identifier: string = '';
  searchBy: string = 'PNR';
  loading: boolean = false;
  fareInfoLoaded: boolean = false;
  fareDetails: FareDetailResponse;

  currentSegmentIndex = 0;
  currentPassengerIndex = 0;
  currentItineraryIndex = 0;

  groupId: number;

  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_FARE_REGULATION, SidebarMenuModel.MENU_FARE_REGULATION);


  constructor(private readonly fareRegulationService: FareRegulationService,
    private readonly sidebarService: SidebarShareDataService,
    private readonly translateService: TranslateService,
    private readonly sessionInfoService: SessionInfoService
  ) { }


  ngOnInit(): void {
    this.sidebarService.changePanelOpened(this.sidebarMenu);
    this.sessionInfoService
      .getSessionInfoAsPromise()
      .then((currentUser) => {
        this.groupId = currentUser.matrix_id;
      })
      .catch(() => {
        this.groupId = null;
      })
  }

  searchOrderFareDetails() {
    this.resetInfo();
    this.loading = true;
    this.fareRegulationService.getFareDetails(this.identifier, this.searchBy, this.groupId)
      .subscribe({
        next: (data) => {
          this.fareInfoLoaded = true;
          this.fareDetails = data;
        },
        error: (err) => {
          let msg: string;
          if (err.error.errors === 'IataNotRecognizedException'){
            msg = this.translateService.instant('fareRegulation.iataNotRecognizedError');
          } else {
            msg = this.searchBy === 'PNR'
            ? this.translateService.instant(
              'fareRegulation.searchByPnrError',
              {
                value: this.identifier
              }
            )
            : this.translateService.instant(
              'fareRegulation.searchByOrderError',
              {
                value: this.identifier
              });
          }  

          Swal.fire({
                icon: "error",
                text: msg,
          });

          this.fareInfoLoaded = false;
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        }
      });
  }

  clearRecordLocator() {
    this.identifier = '';
    this.searchBy = 'PNR';
  }

  nextSegment(): void {
    if (this.currentSegmentIndex < this.getCurrentItinerary().segments.length - 1) {
      this.currentSegmentIndex++;
    }
  }

  previousSegment(): void {
    if (this.currentSegmentIndex > 0) {
      this.currentSegmentIndex--;
    }
  }

  private getCurrentItinerary(): FlightDetail {
    return this.fareDetails.flightDetails[this.currentItineraryIndex];
  }

  resetSegmentIndex(event: MatTabChangeEvent) {
    this.currentItineraryIndex = event.index;
    this.currentSegmentIndex = 0;
  }

  findPassengerTkt(tickets: TicketInfo[]) {
    return tickets.find(tkt => tkt.type === 'FLIGHT');
  }

  nextPassenger(): void {
    if (this.currentPassengerIndex < this.fareDetails.passengers.length - 1) {
      this.currentPassengerIndex++;
    }
  }

  previousPassenger(): void {
    if (this.currentPassengerIndex > 0) {
      this.currentPassengerIndex--;
    }
  }

  isCurrentRoute(coupon: Coupon){
    const actualOrigin = this.fareDetails.flightDetails[this.currentItineraryIndex].segments[this.currentSegmentIndex].origin;
    const actualDestination = this.fareDetails.flightDetails[this.currentItineraryIndex].segments[this.currentSegmentIndex].destination;
    return `${actualOrigin.iata}-${actualDestination.iata}` === `${coupon.origin}-${coupon.destination}`;
  }

  getAdditionalBaggage(){
    let ancillaryDetails: AncillaryDetail[] = [];

    this.fareDetails.passengers[this.currentPassengerIndex].tickets.forEach(
      tkt => {
        if (tkt.type === 'ANCILLARY' && tkt.ancillaryDetails != null){
          if (tkt.ancillaryDetails[0].groupCode === 'BG' && this.isAncillaryIncludedInRoute(tkt.coupons)){
            ancillaryDetails.push(tkt.ancillaryDetails[0]);
          }
        }
      }
    );

    return ancillaryDetails;
  }

  getSeatCode(ticket: TicketInfo){
    const actualOrigin = this.fareDetails.flightDetails[this.currentItineraryIndex].segments[this.currentSegmentIndex].origin;
    const actualDestination = this.fareDetails.flightDetails[this.currentItineraryIndex].segments[this.currentSegmentIndex].destination;
    const coupon = ticket.coupons.find(coupon => `${coupon.origin}-${coupon.destination}` === `${actualOrigin.iata}-${actualDestination.iata}`)
    return coupon.seatCode != null && coupon.seatCode !== '' ? coupon.seatCode : 'N/A';
  }

  isAncillaryIncludedInRoute(coupons: Coupon[]){
    const actualOrigin = this.fareDetails.flightDetails[this.currentItineraryIndex].segments[this.currentSegmentIndex].origin;
    const actualDestination = this.fareDetails.flightDetails[this.currentItineraryIndex].segments[this.currentSegmentIndex].destination;
    return coupons.some(coupon => `${coupon.origin}-${coupon.destination}` === `${actualOrigin.iata}-${actualDestination.iata}`);
  }

  getFare(){
    const fareBasis = this.fareDetails.flightDetails[this.currentItineraryIndex].farebasis;
    return this.fareRegulationService.identifyFare(fareBasis);
  }

  resetInfo(){
    this.currentItineraryIndex = 0;
    this.currentPassengerIndex = 0;
    this.currentSegmentIndex = 0;
    this.fareInfoLoaded = false;
  }

}

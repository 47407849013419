import { HostCommand } from './hostCommand.model';

export class WaiverResult {
  baseFare: number;
  currency: string;
  farebasis: string;
  headerInfoText: string;
  fareConstruction: string;
  taxesCode: string;
  waiverValue: number;
  hostCommand: HostCommand;

  constructor(
    baseFare: number,
    currency: string,
    farebasis: string,
    headerInfoText: string,
    fareConstruction: string,
    taxesCode: string,
    hostCommand: HostCommand) {
      this.baseFare = baseFare;
      this.currency = currency;
      this.farebasis = farebasis;
      this.headerInfoText = headerInfoText;
      this.fareConstruction = fareConstruction;
      this.taxesCode = taxesCode;
      this.hostCommand = hostCommand;
    }
  }

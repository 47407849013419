import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { Filter } from '../../interfaces/filter.interface';
import * as moment from 'moment';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {

  formFilter: UntypedFormGroup;

  @Input() item: Filter;
  @Input() tab: string;
  @Output() sendFilters: EventEmitter<HttpParams> = new EventEmitter<HttpParams>();
  @Output() cleanFilters: EventEmitter<HttpParams> = new EventEmitter<HttpParams>();

  constructor(private formB: UntypedFormBuilder) { }

  ngOnInit() {
    this.formFilter = this.initForm();
  }

  initForm(): UntypedFormGroup {
    return this.formB.group({
      id: new UntypedFormControl(''),
      type: new UntypedFormControl(''),
      pnr: new UntypedFormControl(''),
      agency: new UntypedFormControl(''),
      executive: new UntypedFormControl(''),
      applicant: new UntypedFormControl(''),
      statusDateFrom: new UntypedFormControl(''),
      statusDateTo: new UntypedFormControl(''),
      flightDateFrom: new UntypedFormControl(''),
      flightDateTo: new UntypedFormControl(''),
      flightDate: new UntypedFormControl(''),
      valueWaiver: new UntypedFormControl(''),
    })
  }

  cleanForm(){
    this.formFilter.reset();
    this.cleanFilters.emit();
  }

  sendFilterData(){
    console.log("ID: ",this.formFilter.value["id"])
    let statusDateFrom: string = "";
    let statusDateTo: string = "";
    if (this.isNotNullAndEmpty("statusDateFrom") && this.isNotNullAndEmpty("statusDateTo")){
      statusDateFrom = moment(this.formFilter.value["statusDateFrom"]).format('yyyy-MM-DD')
      // statusDateFrom = new Date(this.formFilter.value["statusDateFrom"]).toLocaleString('es-CL');
      statusDateTo = moment(this.formFilter.value["statusDateTo"]).format('yyyy-MM-DD');
      // statusDateTo = new Date(this.formFilter.value["statusDateTo"]).toLocaleString('es-CL');
    }
    console.log(statusDateFrom);
    console.log(statusDateTo);
    const params = new HttpParams()
      .set('id', this.isNotNullAndEmpty("id") ? this.formFilter.value["id"] : 'n')
      .set('pnr', this.isNotNullAndEmpty("pnr") ? this.formFilter.value["pnr"] : '')
      .set('valueWaiver', this.isNotNullAndEmpty("valueWaiver") ? this.formFilter.value["valueWaiver"] : '')
      .set('statusDateFrom', this.isNotNullAndEmpty("statusDateFrom") ? statusDateFrom : 'n')
      .set('statusDateTo', this.isNotNullAndEmpty("statusDateTo") ? statusDateTo : 'n')
      .set('executive', this.isNotNullAndEmpty("executive") ? this.formFilter.value["executive"] : '')
      .set('agency', this.isNotNullAndEmpty("agency") ? this.formFilter.value["agency"] : '')
      .set('applicant', this.isNotNullAndEmpty("applicant") ? this.formFilter.value["applicant"] : '')
      .set('type', this.isNotNullAndEmpty("type") ? this.formFilter.value["type"] : '');
    this.sendFilters.emit(params);
  }

  isNotNullAndEmpty(field: string): boolean{
    return this.formFilter.value[field] != '' && this.formFilter.value[field] != null;
  }

}

<div class="mt-4 mb-3">
  <div class="row ml-3 mr-3">
    <div class="col">
      <span class="subtitle">{{ 'requestInformation' | translate }}</span>
    </div>
  </div>

  <div class="row ml-3 mr-3">
    <div class="col">
      <mat-table #table [dataSource]="dataSource" class="table-request-info mat-elevation-z1">

        <ng-container matColumnDef="userName">
          <mat-header-cell *matHeaderCellDef>{{ 'field.requestedBy' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.userName | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="typeName">
          <mat-header-cell *matHeaderCellDef>{{ 'field.type' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.typeName | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="pnrAgency">
          <mat-header-cell *matHeaderCellDef>{{ 'field.agencyPnr' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.pnrAgency | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="aditionalComment">
          <mat-header-cell *matHeaderCellDef>{{ 'field.remarks' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.aditionalComment | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="creationDate">
          <mat-header-cell *matHeaderCellDef>{{ 'field.approvalDateHour' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.creationDate | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="motive">
          <mat-header-cell *matHeaderCellDef>{{ 'field.reason' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.motive | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" class="waiver-request-info-row"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="waiver-request-info-row"></mat-row>
      </mat-table>
    </div>
  </div>
</div>

import { ExceptionType } from "./exception-type.model";

export class WaiverMotive {
    public id: number;
    public code: string;
    public name: string;
    public attachmentFile: boolean;
    public calculateWis: boolean;
    public saveWis: boolean;
    public exceptionTypeName: string;

    public constructor(id?: number, code?: string, name?: string, attachmentFile?: boolean, exceptionTypeName? : string) {
        this.id = id;
        this.code = code;
        this.name = name;
        this.attachmentFile = attachmentFile;
        this.exceptionTypeName = exceptionTypeName;
    }
}

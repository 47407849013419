export class Budget {
  public id: number;
  public month: number;
  public assignedValue: number;
  public spentValue: number;
  public currentAmount: number;
  public differenceValue: number;

  public constructor(
    id: number,
    month: number,
    assignedValue: number,
    spentValue: number,
    currentAmount: number,
    differenceValue?: number) {
      this.id = id;
      this.month = month;
      this.assignedValue = assignedValue;
      this.spentValue = spentValue;
      this.currentAmount = currentAmount;
      this.differenceValue = differenceValue;
  }
}

import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Renderer2,
} from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { TranslateService } from "@ngx-translate/core";

import { SessionInfo } from "./../../models/SessionInfo.model";


import { CommonsService } from "../../services/commons.service";
import { LoginAgencyService } from "./../../services/login-agency.service";
import { LoginLatamService } from "../../services/login-latam.service";
import { SessionInfoService } from "./../../services/session-info.service";
import { DOCUMENT } from "@angular/common";
import { environment } from "../../../environments/environment";
import { ChatBotUtilService } from "../../services/chat-bot-util.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  chatScript = this.renderer2.createElement("script");
  language: string;
  languages: string[];
  chatProperties: any = environment.chatProperties;
  infoUser$: Promise<SessionInfo> = this.sessionInfoService.getSessionInfoAsPromise();
  markets = [
    [1, "AR"],
    [2, "Asia"],
    [3, "AU"],
    [4, "BO"],
    [5, "BR"],
    [6, "CL"],
    [7, "CO"],
    [8, "DE"],
    [9, "EC"],
    [10, "ES"],
    [11, "FR"],
    [12, "IT"],
    [13, "MX"],
    [14, "NZ"],
    [15, "PE"],
    [16, "PY"],
    [17, "UK"],
    [18, "US"],
    [19, "UY"],
    [20, "PT"]
  ];

  @Input() sidenav: MatSidenav;

  constructor(
    private loginAgencyService: LoginAgencyService,
    private loginLatamService: LoginLatamService,
    private commonsService: CommonsService,
    private sessionInfoService: SessionInfoService,
    private translateService: TranslateService,
    private renderer2: Renderer2,
    private chatBotUtilService: ChatBotUtilService,
    private el: ElementRef,
    @Inject(DOCUMENT) private document
  ) {}

  ngOnInit() {
    this.language = localStorage.getItem("language");

    if (this.sidenav) {
      this.sidenav.open();
    }
    this.getLanguages();
    console.log(this.sessionInfoService.getSessionInfo());
    this.markets.forEach((element) => {
      if (element[0] === this.sessionInfoService.getSessionInfo().marketId) {
        this.loadChat(element[1]);
      }
    });
  }

  logout() {
    this.sessionInfoService
      .getSessionInfoAsPromise()
      .then((session: SessionInfo) => {
        if (session.isLatamSession()) {
          this.loginLatamService.logout();
          this.deleteChat();
        } else if (session.isLatamExternalSession()) {
          this.loginAgencyService.logout(true);
          this.deleteChat();
        } else {
          this.loginAgencyService.logout(false);
          this.deleteChat();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  getLanguages() {
    this.languages = this.commonsService.getLanguages();
  }

  changedLanguage(value: string) {
    this.loadChat(value);
    this.language = value;
    localStorage.setItem("language", this.language);
    this.translateService.use(this.language);
  }

  redirect() {
    this.sessionInfoService.redirectTypeOfSession();
  }

  deleteChat() {
    let el = document.getElementById("chat");
    let chat = document.getElementsByClassName("embeddedServiceHelpButton");
    this.renderer2.removeChild(this.document.body, chat[0]);
    this.renderer2.removeChild(this.document.body, el);
  }

  loadChat(country) {
    let styles: string = this.chatBotUtilService.loadCss()
    const ES = "es";
    const EN_US = "en-US";
    const PT_BR = 'pt-br';

    const lenguajesMap = {
      "AR": ES,
      "AU": EN_US,
      "BO": ES,
      "BR": PT_BR,
      "CL": ES,
      "CO": ES,
      "DE": "DE",
      "EC": ES,
      "ES": ES,
      "FR": "FR",
      "IT": "IT",
      "MX": ES,
      "NZ": EN_US,
      "PE": ES,
      "PT": PT_BR,
      "PY": ES,
      "UK": EN_US,
      "US": EN_US,
      "UY": ES,
    };

    if (lenguajesMap.hasOwnProperty(country)) {
      const lang = lenguajesMap[country];
      this.chatScript.text = this.chatBotUtilService.generateChatScriptV2(lang, country);
    }

    this.renderer2.createElement("chatDiv");
    let styleSheet = document.createElement("style");

    styleSheet.innerText = styles;
    this.chatScript.type = "text/javascript";
    this.chatScript.id = "chat";

    this.renderer2.appendChild(this.document.body, this.chatScript);
    this.renderer2.appendChild(this.document.body, styleSheet);
  }
}

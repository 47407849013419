<div class="search-main-container">
  <div class="test">
      <span class="search-by">{{ 'waiverLatam.searchBy' | translate}}</span>
      <div class="search-inputs">
          <form [formGroup]="recordLocatorForm" (ngSubmit)="searchPnrOrTkt()">
            <mat-form-field appearance="outline" *ngIf="!enableAllSearchTypes" >
              <input
                style="color: #00000099;"
                matInput
                maxlength="6"
                formControlName="selection"
                readonly
              >
            </mat-form-field>

              <mat-form-field *ngIf="enableAllSearchTypes" id="all-search-types" appearance="outline">

                  <mat-select formControlName="selection" value="PNR">
                      <mat-option id="search-pnr" value="PNR">PNR</mat-option>
                      <mat-option id="search-pnr" value="TKT" >TKT</mat-option>
                  </mat-select>

                  <mat-error *ngIf="recordLocatorForm.get('selection').hasError('required')">
                      {{ 'field.error.required' | translate }}
                  </mat-error>

              </mat-form-field>

              <mat-form-field *ngIf="recordLocatorForm.get('selection').value === 'PNR'" appearance="outline" >
                  <mat-label>PNR</mat-label>
                  <input
                      matInput
                      formControlName="pnrValue"
                      maxlength="6"
                      [disabled]="loadingReservationInfo"
                  >
                  <mat-error
                      *ngIf="recordLocatorForm.get('pnrValue').hasError('required')">
                          {{ 'field.error.required' | translate }}
                  </mat-error>
                  <mat-error
                      *ngIf="recordLocatorForm.get('pnrValue').hasError('pattern')">
                          {{ 'field.error.invalidPnr' | translate }}
                  </mat-error>
              </mat-form-field>

              <mat-form-field *ngIf="recordLocatorForm.get('selection').value === 'TKT'" appearance="outline" >
                  <mat-label id="tkt-input-label">TKT</mat-label>
                  <input
                      matInput
                      formControlName="tktValue"
                      maxlength="13"
                      [disabled]="loadingReservationInfo"
                  >
                  <mat-error
                      *ngIf="recordLocatorForm.get('tktValue').hasError('required')">
                          {{ 'field.error.required' | translate }}
                  </mat-error>
                  <mat-error  *ngIf="recordLocatorForm.get('tktValue').hasError('pattern')">
                          {{ 'field.error.invalidTkt' | translate }}
                  </mat-error>
              </mat-form-field>

              <button mat-flat-button [disabled]="loadingReservationInfo || isLoading()">
                      <span *ngIf="!loadingReservationInfo && !isLoading()">
                          {{ 'button.search' | translate }}
                      </span>
                      <mat-progress-spinner
                        *ngIf="loadingReservationInfo || isLoading()"
                        class="custom-spinner"
                        diameter="20"
                        mode="indeterminate"
                      > 
                      </mat-progress-spinner>
              </button>
          </form>
      </div>
  </div>

  <div class="info" *ngIf="waiverModule === 'POL'">
      <img  src="assets/images/waiver-latam/search.svg" />
      <h2>
          {{'waiverPolicy.howToRequest.title' | translate}}
      </h2>
      <p>{{'waiverPolicy.howToRequest.p1' | translate}}</p>
      <p>{{'waiverPolicy.howToRequest.p2' | translate}}</p>

      <div class="list" style="font-family: 'LatamSansBold', sans-serif; color:#1B0088;">
        <ul>
          <li>{{'waiverPolicy.howToRequest.infoList.fullName' | translate}}</li>
          <li>{{'waiverPolicy.howToRequest.infoList.latamPass' | translate}}</li>
          <li>{{'waiverPolicy.howToRequest.infoList.documentCode' | translate}}</li>
          <li>{{'waiverPolicy.howToRequest.infoList.newDateAndRoutes' | translate}}</li>
          <li>{{'waiverPolicy.howToRequest.infoList.originalTicket' | translate}}</li>
        </ul>
      </div>

      <p [innerHTML]="'waiverPolicy.howToRequest.p3' | translate"></p>
      <p>{{'waiverPolicy.howToRequest.p4' | translate}}</p>
      <p [innerHTML]="'waiverPolicy.howToRequest.p5' | translate"></p>
  </div>
</div>
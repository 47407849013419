import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-latam-report',
  template: '<app-latam-report-base [isOutsourcing]="isOutsourcing"></app-latam-report-base>'
})
export class LatamReportComponent implements OnInit {

  isOutsourcing = false;

  constructor(private router: Router){}

  ngOnInit(): void {
    if (this.router.url.includes("outsourcing")){
      this.isOutsourcing = true;
    }
  }

}

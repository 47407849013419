export class Booking {
  iata: string;
  ppc: string;
  officeId: string;
  pos: string;
  accountCode: string;

  constructor(
    iata: string,
    ppc: string,
    officeId: string,
    pos: string,
    accountCode: string
  ) {
    this.iata = iata;
    this.ppc = ppc;
    this.officeId = officeId;
    this.pos = pos;
    this.accountCode = accountCode;
  }
}

import { WaiverCategory } from './waiver-category.model';

export class WaiverType {
  id: number;
  code: string;
  name: string;
  waiverCategory: WaiverCategory;

  constructor(
    id?: number,
    code?: string,
    name?: string,
    waiverCategory?: WaiverCategory) {
      this.id = id;
      this.code = code;
      this.name = name;
      this.waiverCategory = waiverCategory;
  }
}

<div class="container">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="latam-sans breadcrumb-item">
            <a>{{ 'menu.latamManagement' | translate }}</a>
          </li>
          <li class="breadcrumb-item latam-sans active" aria-current="page">
            <a>{{ 'menu.bulkLoad' | translate }}</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <span id="reportTitle" class="title">{{ 'menu.bulkLoad' | translate }}</span>
    </div>
  </div>

  <hr>

  <div *ngIf="loading" class="loading">
    <div class="loading-div">
      <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
      <span class="loading-span">{{ 'loading' | translate }}</span>
    </div>
  </div>

  <ng-container *ngIf="!loading">
    <div class="row mt-4">
      <div class="col">
        <span class="subtitle">{{ 'loadTypeToDo' | translate }}</span>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <form #bulkLoadForm="ngForm">
          <div class="row">
            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-form-field>
                <mat-label>{{ 'field.bulkLoadType' | translate }}</mat-label>
                <mat-select id="bulkLoadSelect" name="bulkLoadSelect" #bulkLoadSelect="ngModel" [(ngModel)]="selectedBulkLoadType" required>
                  <mat-option *ngFor="let bulkLoad of bulkLoadTypeList" [value]="bulkLoad">
                    {{ bulkLoad.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="bulkLoadSelect.errors && (bulkLoadSelect.dirty || bulkLoadSelect.touched) && bulkLoadSelect.errors.required">
                  {{ 'field.error.required' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row pt-3">
            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <button mat-raised-button id="adj-btn" matTooltip="{{ 'tooltip.allowedFormats' | translate }}"
                [disabled]="files.length === 1" (click)="onClick()"
                class="background-blue w-100">{{ 'button.attachFile' | translate }}</button>
            </div>

            <div class="col-sm-6 col-md-8 col-lg-9 col-xl-9">
              <ul class="filename-list">
                <li *ngIf="files.length == 0" class="filename-list-item no-files">
                  <span class="filename-span">{{ 'warning.fileRequired' | translate }}</span>
                </li>
                <li *ngFor="let file of files" [matTooltip]="file.data.name" [matTooltipPosition]="'below'"
                  [@fadeInOut]="file.state" class="filename-list-item">
                  <span id="file-label" class="filename-span">{{ file.data.name }}</span>
                  <a *ngIf="file.canCancel" title="Cancel" (click)="cancelFile(file)">
                    <mat-icon *ngIf="!file.deletingFile" class="cancelAdjunto">close</mat-icon>
                    <div *ngIf="file.deletingFile" class="deleting-loader">
                      <svg class="spinner" width="24px" height="24px" viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                        </circle>
                      </svg>
                    </div>
                  </a>
                </li>
              </ul>
              <input type="file" id="fileUpload" name="fileUpload" multiple="multiple" accept="{{ accept }}"
                style="display:none;" />
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="row mt-3 justify-content-center">
      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <button mat-raised-button
          [disabled]="selectedBulkLoadType == null || files.length == 0 || bulkLoadProcess == true"
          (click)="initBulkLoad()" class="background-red w-100">{{ 'button.startBulkLoad' | translate }}</button>
      </div>
      <div *ngIf="bulkLoadProcess" class="col-1 mt-5px">
        <svg class="spinner" width="30px" height="30px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
      <div *ngIf="!bulkLoadProcess" class="col-1">
        <mat-icon [ngClass]="{ 'icon-green-hidden': !bulkLoadSuccessfully }" class="icon-green">check</mat-icon>
      </div>
    </div>
  </ng-container>
</div>

import { HttpErrorResponse } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';
import { FileUploadModel } from '../../../../models/file-upload.model';
import { SidebarMenuModel } from '../../../../models/SidebarMenu.model';
import { BdaService } from '../../../../services/bda.service';
import { MessageService } from '../../../../services/message.service';
import { SidebarShareDataService } from '../../../../services/sidebar-share-data.service';
import { ControlBda } from '../../../../interfaces/control-bda.interface'
import { ProfilingService } from '../../../../services/profiling.service';
import { DatePipe } from '@angular/common';
import { UserService } from '../../../../services/user.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { FileBdaStorage } from '../../../../models/file-bda-storage.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MessageErrorService } from '../../../../services/message-error.service';
import { ManualUtilService } from '../../../../services/manual-util.service';

@Component({
  selector: 'app-download-sales-report',
  templateUrl: './download-sales-report.component.html',
  styleUrls: ['./download-sales-report.component.css'],
  animations: [
    trigger(
      'fadeInOut',
      [
        state('in', style({ opacity: 100 })),
        transition('* => void', [animate(300, style({ opacity: 0 }))])
      ]
    )
  ],
  encapsulation: ViewEncapsulation.None
})

export class DownloadSalesReportComponent implements OnInit {
  @ViewChild('loadSalesForm') loadSalesForm: NgForm;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  encodedFile: string;

  dataSource = new MatTableDataSource<any>();
  public displayedColumns: string[] = ['name', 'updated','size','actions'];
  dataNotfound = (index, item) => item.noData;
  selectedRowIndex: number = -1;
  downloading = false;
  downloadingSuccessfully = false;

  fileList: FileBdaStorage[];
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_VENTA_PORTAL, SidebarMenuModel.SUBMENU_VENTA_PORTAL);

  noFilesFound = false;
  textFile: File;
  aux: string;
  loading: boolean = true;
  validCsvFormat: boolean = false;
  oAuthToken: string;


  sizeExcelFile: number[] = [20, 49, 15, 15, 50];
  large: number;
  result3: string[];
  typeFileId: number;
  format: string = "";
  errores: string = "";


  // ini adjuntar archivos
  /** File extension that accepted, same as 'accept' of <input type='file' />. By the default, it's set to 'image/*'. */
  @Input() accept = '.csv';
  /** Allow you to add handler after its completion. Bubble up response text from remote. */
  @Output() complete = new EventEmitter<string>();
  public files: Array<FileUploadModel> = [];
  files2: FileUploadModel;

  allowedFileFormats = ['application/csv', 'text/csv', 'application/vnd.ms-excel'];
  csvText: File;
  loadSalesSuccessfully: boolean = false;
  loadSalesProcess: boolean = false;
  operationfinishOK: boolean = false;

  // fin adjuntar archivos
  constructor(
    private bdaService: BdaService,
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private sidebarShareDataService: SidebarShareDataService,
    private translateService: TranslateService,
    private profilingService: ProfilingService,
    private userService: UserService,
    private manualUtilService: ManualUtilService
  ) { }

  ngOnInit() {
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.loadFiles();
  }

/**
   * Metodo para subir el archivo
   */
validateFormat() {
  const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
  const reader = new FileReader();

  reader.onload = () => {
    let myRegex = /^([a-zA-Z0-9 ._-]+)$/;
    const fileName = this.files[0].data.name;
    let nameCaract = myRegex.test(fileName);
    let nameSize = fileName.length;
    let error = false;
    this.errores = "";

    const binaryString = reader.result;
    reader.readAsBinaryString
    this.encodedFile = btoa(binaryString.toString());
    let result1 = reader.result as string;
    let result2 = result1.split("\r\n");

    this.result3 = result2[0].split(";");

    if (this.result3[this.result3.length - 1] == "") {
      this.result3.pop();
    }

    error = this.validateFile(nameSize, error, nameCaract, fileName);


    if ((this.typeFileId == 0 && this.result3.length != this.sizeExcelFile[0]) || (this.typeFileId == 1 && this.result3.length != this.sizeExcelFile[1])) {
      this.errores = this.errores.concat(this.translateService.instant('info.selectTipe') + "<br>")
      this.cancelFile(this.files[0]);
      error = true;
    }


    if(error){
      this.manualUtilService.showError(this.errores);
    }


  }


  fileUpload.onchange = () => {

    let index = fileUpload.files.length - 1;
    const file = fileUpload.files[index];


    if (!this.allowedFileFormats.includes(file.type) || file.size > 200097152 ) {
      this.messageService.showWarningMessage(
        this.translateService.instant('button.confirm'),
        this.translateService.instant('warning.invalidFileFormat')
      );
    } else {
      this.files.push({
        data: file, state: 'in', inProgress: false, progress: 0, canRetry: false, canCancel: true
      });
      reader.readAsBinaryString(this.files[0].data);
    }
  };
  fileUpload.value = '';
  fileUpload.click();
}

  private validateFile(nameSize: number, error: boolean, nameCaract: boolean, fileName: string) {
    if (nameSize >= 50) {
      this.errores = this.errores.concat(this.translateService.instant('info.fileNameSize') + "<br>");
      this.cancelFile(this.files[0]);
      error = true;
    }

    if (!nameCaract || fileName.indexOf('\.') != fileName.lastIndexOf('\.')) {
      this.errores = this.errores.concat(this.translateService.instant('info.fileNameCaract') + "<br>");
      this.cancelFile(this.files[0]);
      error = true;
    }
    return error;
  }

  /**
   * @param file
   */
  cancelFile(file: FileUploadModel) {
    const index = this.files.indexOf(file);
    if (index > -1) {
      this.files.splice(index, 1);
    }
    this.validCsvFormat = false;
  }

    /**
   * Metodo que inicia el proceso de carga masiva
   */
  public initBulkLoad() {
    const file = this.files[0].data;
    const exDate = new Date(Date.now());
    const datepipe: DatePipe = new DatePipe('en-US')
    let formattedDate = datepipe.transform(exDate, 'yyyy-MM-ddTHH:mm:ss.sss')
    let controlDataBDA = new ControlBda();
    controlDataBDA.filename = file.name.split(' ').join('_');
    controlDataBDA.userEmail = localStorage.getItem('userMail');
    controlDataBDA.fileFormat = 'REPROCESS';

    controlDataBDA.executionDate = formattedDate.toString() + "Z"
    controlDataBDA.agencyGroup = this.profilingService.decodedTokenTemp.association_name


    swal.fire({
      title: this.translateService.instant('bda.wait'),
      html: this.translateService.instant('bda.process'),// add html attribute if you want or remove
      allowOutsideClick: false,
      allowEscapeKey: false,
      heightAuto: false,
      onBeforeOpen: () => {
        swal.showLoading()
      },

    });
    let oAuthToken;

    this.userService.getAgencyUserToken().subscribe({ next: result => {
      oAuthToken = result.sessionToken;

      this.bdaService.uploadFile(file, oAuthToken, controlDataBDA).subscribe({

        next: data => {

          swal.fire({
            html: '<p>' + this.translateService.instant('success.loadSuccess') + '</p>',
            confirmButtonText: this.translateService.instant('button.confirm'),
            confirmButtonColor: '#d33',
            icon: 'success',
            allowOutsideClick: false
          }).then((result) => {
            if (result.value) {
              //Success
              this.loadFiles();
              this.refreshComponent();
            }
          });
        },
        error: this.proccessError()
    });
    },
      error: this.proccessError()
  });
    this.cancelFile(this.files[0]);
  }

  proccessError(){
    return (error: HttpErrorResponse) => {
      console.log('error', error);
      if (this.messageErrorService.sendErrorSecondVersion(error)){
        this.refreshComponent();
      }
    };
  }

  /**
   * Obtener los archvios del Bucket de error
   */
  public loadFiles() {
    console.log('loading files');
    this.userService.getAgencyUserToken().subscribe({next: result => {
      this.oAuthToken = result.sessionToken;

      this.bdaService.getFiles(this.oAuthToken).subscribe({

        next: data => {
          if (data === undefined || data.length === 0) {
            this.noFilesFound = true;
            this.dataSource.data = [{ noData: true }];
            this.dataSource.paginator = null;
          } else {
              const folderPrefix = 'portalbdaerror/';
              data.sort((a, b) => {
                const dateA = new Date(a.updated);
                const dateB = new Date(b.updated);
                return dateB.getTime() - dateA.getTime();
              });
              // Filtrar y eliminar el elemento que tiene el nombre "portalbdaerror/"
            // y eliminar el prefijo de los otros nombres de archivo
              this.dataSource.data = data
              .filter(item => item.name !== folderPrefix)
              .map(item => ({
                ...item,
                name: item.name.startsWith(folderPrefix) ? item.name.substring(folderPrefix.length) : item.name
              }));

              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;

              this.loading = false;
            }
        },


        error: (error: HttpErrorResponse) => {
          if (this.messageErrorService.sendErrorSecondVersion(error)){
            this.refreshComponent();
          }
          console.log('error', error);
        }
    });
    },
      error: (error: HttpErrorResponse) => {
        console.log('error', error);



      }

  });
  }

  /**
  * Metodo que sirve para refrescar la pagina
  */
  refreshComponent() {
    if (this.operationfinishOK) {
      this.loadSalesSuccessfully = false;
      this.loadSalesProcess = false;
      this.operationfinishOK = false;
      this.loading = true;
      this.fileList = [];
      this.files = [];
      this.ngOnInit();
    }
  }

  valueSelect(event: any) {
    this.format = event.value.name;
    this.typeFileId = event.value.id;
    this.large = this.sizeExcelFile[event.value.id];
  }

  highlightRow(event: Event, rowIndex: number) {
    this.selectedRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }


  bytesToSize(bytes: number): string {
    const kilobytes = bytes / 1024;
    if (kilobytes < 1024) {
      return kilobytes.toFixed(1) + ' KB';
    } else {
      const megabytes = kilobytes / 1024;
      return megabytes.toFixed(1) + ' MB';
    }
  }

  downloadFile(fileBdaStorage: FileBdaStorage) {
    this.manualUtilService.downloadFile(
      fileBdaStorage,
      (name, token) => this.bdaService.downloadFile(name, token),
      this.oAuthToken
    );
  }


  deleteFile(fileBdaStorage: FileBdaStorage) {
    this.manualUtilService.deleteFile(
      fileBdaStorage.name,
      (location) => this.bdaService.deleteFile(location, this.oAuthToken),
      this.loadFiles.bind(this)
    );
  }

}

<div class="container">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="latam-sans breadcrumb-item">
            <a>{{ 'menu.waivers' | translate }}</a>
          </li>
          <li class="breadcrumb-item latam-sans active" aria-current="page">
            <a>{{ 'menu.waiverAfterSalesRequest' | translate }}</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <span class="title">{{ 'menu.waiverAfterSalesRequest' | translate }}</span>
    </div>
    <div>
      <app-waiver-terms-conditions [waiverCategory]="'post'"></app-waiver-terms-conditions>
    </div>
  </div>

  <hr>

  <mat-horizontal-stepper #stepper [linear]="true">
    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>

    <mat-step [completed]="(validPrePNR && isSelectPassengersPost() && isSelectSegmentsPost())
                           ||(isValidPostPNR() && isSelectPassengersPost() && isSelectSegmentsPost())
                           ||(isValidPostTKT() && isSelectCouponsPost())">
      <ng-template matStepLabel>
        <span>{{ 'waiverSettings' | translate }}</span>
      </ng-template>

      <hr>

      <!-- seleccion waiver -->
      <div class="row mt-3">
        <div class="col">
          <span class="subtitle">{{ 'selectWaiver' | translate }}</span>
        </div>
      </div>

      <form id="solicitudWaiverForm" #solicitudWaiverForm="ngForm">
        <div class="row mt-4">
          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-form-field>
              <mat-label>{{ 'field.waiverType' | translate }}</mat-label>
              <mat-select id="waiverTypeSelect" name="type" #type="ngModel" [(ngModel)]="selectedType"
                (selectionChange)="getWaiverMotif(); motif.reset();">
                <mat-option *ngFor="let type of waiverType" [value]="type">
                  {{ type.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-form-field>
              <mat-label>{{ 'field.waiverReason' | translate }}</mat-label>
              <mat-select id="waiverMotiveSelect" name="motif" #motif="ngModel" required [disabled]="!selectedType"
                [(ngModel)]="selectedMotif">
                <mat-option *ngFor="let motif of waiverModif" [value]="motif">
                  {{ motif.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="motif.errors && (motif.dirty || motif.touched) && motif.errors.required">
                {{ 'field.error.required' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- buscar por PNR -->
        <ng-container *ngIf="selectedTypeCategory == 'PRE' && motif.touched && !motif.errors">
          <div id="pnrPreSearchDiv" class="row">
            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-form-field>
                <mat-label>{{ 'field.pnr' | translate }}</mat-label>
                <input matInput id="pnrPreInput" name="pnrNumber" #pnrNumber="ngModel" maxlength="6"
                  oninput="this.value = this.value.toUpperCase()" pattern="\b([A-Z]{6})+" required
                  [(ngModel)]="waiver.pnrNumber">
                <mat-error
                  *ngIf="pnrNumber.errors && (pnrNumber.dirty || pnrNumber.touched) && pnrNumber.errors.required">
                  {{ 'field.error.required' | translate }}</mat-error>
                <mat-error
                  *ngIf="pnrNumber.errors && (pnrNumber.dirty || pnrNumber.touched) && pnrNumber.errors.pattern">
                  {{ 'field.error.invalidPnr' | translate }}</mat-error>
              </mat-form-field>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <button mat-raised-button id="pnrPreSearchButton" [disabled]="solicitudWaiverForm.invalid || isLoading()"
                (click)="getPnrPresale()" class="background-blue w-100">{{ 'button.search' | translate }}</button>
            </div>
          </div>

          <div id="pnrPreMessageDiv" *ngIf="message.show" class="row">
            <div class="col-md-8 col-lg-6 col-xl-6">
              <div role="alert" class="alert alert-warning pt-0 pl-0 pr-0 pb-0">
                <div class="alert-icon-div bg-warning-alert-dark">
                  <i class="material-icons">error_outline</i>
                </div>
                <div class="alert-text-div">
                  <p id="pnrPreBusinessExceptionMessage" *ngIf="message.status===0" class="mb-0">{{ message.detail }}
                  </p>
                  <p id="pnrPreSystemExceptionMessage" *ngIf="message.status===1" class="mb-0">
                    {{ 'error.notAvailable' | translate }}
                  </p>
                  <p id="pnrPreNoDataFoundMessage" *ngIf="message.status===2" class="mb-0">
                    {{ 'error.notDataFound' | translate }}</p>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- buscar por Ticket o PNR -->
        <div class="row" *ngIf="selectedTypeCategory == 'POST' && motif.touched && !motif.errors">
          <div id="pnrTktSelect" class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-form-field>
              <mat-label>{{ 'field.searchType' | translate }}</mat-label>
              <mat-select name="typeSearch" #searchType="ngModel"
                required [(ngModel)]="selectedTypeSearch" (selectionChange)="getTypeSearch()">
                <mat-option *ngIf="selectedType.id != 8" value="1">{{ 'field.pnr' | translate }}</mat-option>
                <mat-option value="2">{{ 'field.tkt' | translate }}</mat-option>
              </mat-select>
              <mat-error
                *ngIf="searchType.errors && (searchType.dirty || searchType.touched) && searchType.errors.required">
                {{ 'field.error.required' | translate }}</mat-error>
            </mat-form-field>
          </div>

          <ng-container *ngIf="selectedTypeSearch == 1">
            <div id="pnrPostSearchDiv" *ngFor="let pnr of pnrList; let i = index;" class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-form-field>
                <mat-label>{{ 'field.pnr' | translate }}</mat-label>
                <input matInput id="pnrPostInput" name="pnrNumber{{i}}" #pnrNumber="ngModel" maxlength="6"
                  oninput="this.value = this.value.toUpperCase()" pattern="\b([A-Z]{6})+" required
                  [(ngModel)]="pnr.pnrNumber">
                <mat-error
                  *ngIf="pnrNumber.errors && (pnrNumber.dirty || pnrNumber.touched) && pnrNumber.errors.required">
                  {{ 'field.error.required' | translate }}</mat-error>
                <mat-error
                  *ngIf="pnrNumber.errors && (pnrNumber.dirty || pnrNumber.touched) && pnrNumber.errors.pattern">
                  {{ 'field.error.invalidPnr' | translate }}</mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <button mat-raised-button matStepperNext [hidden]="selectedType.id != 2" [disabled]="pnrList.length <= 1"
                (click)="removeFieldPNRTKT(i)" class="background-red w-100">{{ 'button.deletePNR' | translate }}</button>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 offset-sm-6 offset-md-0 offset-lg-0 offset-xl-0">
              <button mat-raised-button id="pnrPostSearchButton" [disabled]="solicitudWaiverForm.invalid || isLoading()"
                (click)="getListPnrPostSale()" class="background-blue w-100">{{ 'button.search' | translate }}</button>
            </div>
          </ng-container>

          <ng-container *ngIf="selectedTypeSearch == 2">
            <div id="tktSearchDiv" *ngFor="let tkt of ticketList; let i = index;" class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-form-field>
                <mat-label>{{ 'field.tkt' | translate }}</mat-label>
                <input matInput id="tktInput" name="ticket{{i}}" #ticket="ngModel" maxlength="13" pattern="\b([0-9]{13})+" required
                  [(ngModel)]="tkt.ticket">
                <mat-error *ngIf="ticket.errors && (ticket.dirty || ticket.touched) && ticket.errors.required">
                  {{ 'field.error.required' | translate }}
                </mat-error>
                <mat-error *ngIf="ticket.errors && (ticket.dirty || ticket.touched) && ticket.errors.pattern">
                  {{ 'field.error.invalidTkt' | translate }}</mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <button mat-raised-button matStepperNext [hidden]="selectedType.id != 2" [disabled]="ticketList.length <= 1"
                  (click)="removeFieldPNRTKT(i)" class="background-red w-100">{{ 'button.deletePNR' | translate }}</button>
                  </div>
                  <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 offset-sm-6 offset-md-0 offset-lg-0 offset-xl-0">
              <button mat-raised-button id="tktSearchButton" [disabled]="solicitudWaiverForm.invalid || isLoading()"
                (click)="getListTkt()" class="background-blue w-100">{{ 'button.search' | translate }}</button>
            </div>
          </ng-container>

          <div id="pnrTktPostMessageDiv" *ngIf="message.show" class="col-md-8 col-lg-6 col-xl-6">
            <div class="alert alert-warning pt-0 pl-0 pr-0 pb-0" role="alert">
              <div class="alert-icon-div bg-warning-alert-dark">
                <i class="material-icons">error_outline</i>
              </div>
              <div class="alert-text-div">
                <p id="pnrTktPostBusinessExceptionMessage" *ngIf="message.status === 0" class="mb-0">
                  {{ message.detail }}</p>
                <p id="pnrTktPostSystemExceptionMessage" *ngIf="message.status === 1" class="mb-0">
                  {{ 'error.notAvailable' | translate }}</p>
                <p id="pnrTktPostNoDataFoundExceptionMessage" *ngIf="message.status === 2" class="mb-0">
                  {{ 'error.notDataFound' | translate }}</p>
                <p id="pnrTktPostErrorLoadingTktInfoForPnrPostMessage" *ngIf="message.status === 3" class="mb-0">
                  {{ 'error.loading' | translate }}</p>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="invalidCouponsQuantity > 0" class="row">
          <div id="invalidCouponsAlertDiv" class="col-md-8 col-lg-6 col-xl-6">
            <div class="alert alert-warning pt-0 pl-0 pr-0 pb-0" role="alert">
              <div class="alert-icon-div bg-warning-alert-dark">
                <i class="material-icons">error_outline</i>
              </div>
              <div class="alert-text-div">
                <p class="mb-0">{{ 'warning.couponInvalidStatus' | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </form>

     <!-- Informacion reserva PRE -->
     <ng-container id="pnrInfoDiv"
      *ngIf="validPrePNR && !isLoading()">
      <div class="row mt-4" id="create-waiver-subtitle">
        <div class="col">
          <span class="subtitle">{{ 'bookingInformation' | translate }}</span>
        </div>
      </div>

      <div class="mat-elevation-z1 table-post mt-3">
        <mat-table #table [dataSource]="dataReservation">
          <ng-container matColumnDef="pnrNumber" id="create-waiver-pnrNumber">
            <mat-header-cell *matHeaderCellDef>{{ 'field.pnr' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">{{ data.pnr.pnrNumber | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="pnrType" id="create-waiver-pnrType">
            <mat-header-cell *matHeaderCellDef>{{ 'field.pnrType' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">{{ data.pnr.type | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="bookingCreation" id="create-waiver-bookingCreation">
            <mat-header-cell *matHeaderCellDef>{{ 'field.bookingCreation' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">
              {{ data.pnr.createDateTime | dateFormat:'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="NumberOfPassengers" id="create-waiver-numberOfPassengers">
            <mat-header-cell *matHeaderCellDef>{{ 'field.numberPassengers' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">{{ dataPassengers?.data?.length }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="pos" id="create-waiver-pos">
            <mat-header-cell *matHeaderCellDef>{{ 'field.pos' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">{{ data.booking.pos | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="iata" id="create-waiver-iata">
            <mat-header-cell *matHeaderCellDef>{{ 'field.iata' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">{{ data.booking.iata | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="pcc" id="create-waiver-pcc">
            <mat-header-cell *matHeaderCellDef>{{ 'field.pcc' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">{{ data.booking.ppc | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="officeId" id="create-waiver-officeId">
            <mat-header-cell *matHeaderCellDef>{{ 'field.officeId' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">{{ data.booking.officeId | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsR" class="waiver-post-row"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsR; let i = index"
            [ngClass]="{ 'highlight': selectedReservationInformationRowIndex === i }"
            (mouseout)="highlightReservationInformationRow($event, i)"
            (mouseover)="highlightReservationInformationRow($event, i)" class="waiver-post-row"></mat-row>
        </mat-table>
      </div>
     </ng-container>

     <!-- informacion de pasajeros PRE -->
     <ng-container id="preSalePNRPassengersDiv" *ngIf="validPrePNR && !isLoading()">
      <div class="row mt-4" id="create-waiver-subtitle">
        <div class="col">
          <span class="subtitle">{{ 'passengerInfo' | translate }}</span>
        </div>
      </div>

      <div class="mat-elevation-z1 table-post mt-3" id="create-waiver-passengerName">
        <mat-table id="passengersPrePNRTable" #table [dataSource]="dataPassengers">
          <ng-container matColumnDef="passengerName">
            <mat-header-cell *matHeaderCellDef>{{ 'field.passengerName' | translate }}
            </mat-header-cell>
            <mat-cell [ngClass]="{ 'mat-cell-invalid': data.infantTkt }" *matCellDef="let data">
              {{ (data.firstName | partialDisplay: '#' : 1 | dashWhenEmpty) + ' ' + (data.lastName | partialDisplay: '#' : 1 | dashWhenEmpty) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="foid" id="create-waiver-foid">
            <mat-header-cell *matHeaderCellDef>{{ 'field.foid' | translate }}</mat-header-cell>
            <mat-cell [ngClass]="{ 'mat-cell-invalid': data.infantTkt }" *matCellDef="let data">
              {{ data.foid | partialDisplay:'#' :-2 | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="passengerType" id="create-waiver-passengerType">
            <mat-header-cell *matHeaderCellDef>{{ 'field.passengerType' | translate }}
            </mat-header-cell>
            <mat-cell [ngClass]="{ 'mat-cell-invalid': data.infantTkt }" *matCellDef="let data">
              {{ data.type | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="select" id="create-waiver-select">
            <mat-header-cell *matHeaderCellDef class="pt-2 ">
              <mat-checkbox color="accent" [checked]="selectionPassengers.hasValue() && isAllSelectedPassengers()"
                [disabled]="allPassengersInvalid"
                [indeterminate]="selectionPassengers.hasValue() && !isAllSelectedPassengers()"
                (change)="$event ? masterTogglePassengers() : null">
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="pt-2">
              <mat-checkbox color="accent" [checked]="selectionPassengers.isSelected(row)" [disabled]="row.infantTkt"
                (change)="$event ? toggleSelectionPassengerPre(row) : null" (click)="$event.stopPropagation()">
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsP" class="waiver-post-row"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsP; let i = index"
            [ngClass]="{ 'highlight': selectedPassengerInformationRowIndex === i || selectionPassengers.isSelected(row) }"
            (click)="toggleSelectionPassengerPre(row)" (mouseout)="highlightPassengerInformationRow($event, i)"
            (mouseover)="highlightPassengerInformationRow($event, i)" class="waiver-post-row">
          </mat-row>
        </mat-table>
      </div>
     </ng-container>

     <!-- informacion de segmentos PRE -->
     <div id="pnrSegmentsDiv"
        *ngIf="validPrePNR && !isLoading()">
        <div class="row mt-4">
          <div class="col">
            <span class="subtitle">{{ 'segmentsInformation' | translate }}</span>
          </div>
        </div>

        <div class="mat-elevation-z1 table-post mt-3">
          <mat-table id="segmentsTable" #table [dataSource]="dataSegments">
            <ng-container matColumnDef="segmentNumber" id="create-waiver-segmentNumber">
              <mat-header-cell *matHeaderCellDef>{{ 'field.segmentNumber' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                *matCellDef="let data">{{ data.segmentNumber | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="carrierMkt" id="create-waiver-carrierMkt">
              <mat-header-cell *matHeaderCellDef>{{ 'field.mktCarrier' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0}"
                *matCellDef="let data">{{ data.carrierMarketing | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="flightNumberMkt" id="create-waiver-flightNumberMkt">
              <mat-header-cell *matHeaderCellDef>{{ 'field.mktFlightNumber' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                *matCellDef="let data">{{ data.flightNumber | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="class" id="create-waiver-class">
              <mat-header-cell *matHeaderCellDef>{{ 'field.class' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                *matCellDef="let data">{{ data.class | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="origin" id="create-waiver-origin">
              <mat-header-cell *matHeaderCellDef>{{ 'field.from' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0}"
                *matCellDef="let data">{{ data.source | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="destination" id="create-waiver-destination">
              <mat-header-cell *matHeaderCellDef>{{ 'field.to' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0}"
                *matCellDef="let data">{{ data.destination | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="dateHourDeparture" id="create-waiver-dateHourDeparture">
              <mat-header-cell *matHeaderCellDef>{{ 'field.departureDate' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                *matCellDef="let data">
                {{ data.departureDateTime | dateFormatWithoutLocale: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="status" id="create-waiver-status">
              <mat-header-cell *matHeaderCellDef>{{ 'field.segmentStatus' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                *matCellDef="let data">{{ data.status | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <!-- Este campo no se debe mostrar, pero permite obtener informacion del farebasis NO eliminar -->
            <ng-container matColumnDef="fareBasis" id="create-waiver-fareBasis">
              <mat-header-cell *matHeaderCellDef [hidden]="true">{{ 'field.farebasis' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data" [hidden]="true">{{ data.fareBasis | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef class="pt-2">
                <mat-checkbox color="accent" [checked]="selectionSegments.hasValue() && isAllSelectedSegmentsPre()"
                  [disabled]="(segmentsWithOneCouponInvalidQuantity + invalidStatusSegmentsQuantity) === dataSegments.data.length"
                  [indeterminate]="selectionSegments.hasValue() && !isAllSelectedSegmentsPre()"
                  (change)="$event ? masterToggleSegmentsPre() : null;">
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="pt-2">
                <div id="tooltipSegmentPRE" *ngIf="selectedType.waiverCategory.code === 'PRE'"
                  matTooltip="{{ 'tooltip.invalidSegment' | translate }}" matTooltipPosition="before"
                  [matTooltipDisabled]="validSegmentStatusList.includes(row.status)">
                  <mat-checkbox color="accent" [checked]="selectionSegments.isSelected(row)"
                    [disabled]="!validSegmentStatusList.includes(row.status)"
                    (change)="$event ? toggleSelectionSegment(row) : null;" (click)="$event.stopPropagation()">
                  </mat-checkbox>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsS" class="waiver-post-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsS; let i = index"
              [ngClass]="{ 'highlight': selectedSegmentsInformationRowIndex === i || selectionSegments.isSelected(row)}"
              (click)="toggleSelectionSegment(row)" (mouseout)="highlightSegmentsInformationRow($event, i)"
              (mouseover)="highlightSegmentsInformationRow($event, i)" class="waiver-post-row">
            </mat-row>
          </mat-table>
        </div>
      </div>


      <!-- Informacion reserva POST -->
      <ng-container
        *ngIf="(isValidPostPNR() && isPassengerTicketRequestsCompletedPost())">
        <hr>
      </ng-container>
      <mat-tab-group  [hidden]="disablePnrTabs" >

        <mat-tab id="pnrTktTabs" label="{{i+1}}" *ngFor="let pnr of tabNavList; let i = index;">
      <!-- informacion de reserva -->
      <ng-container id="pnrInfoDiv"
        *ngIf="((isValidPostPNR() && isPassengerTicketRequestsCompletedPost() && dataReservationList[i]?.data[0]?.passengerTicketRequestsWithError == 0)) && !isLoading()">
        <div class="row mt-4">
          <div class="col">
            <span class="subtitle">{{ 'bookingInformation' | translate }}</span>
          </div>
        </div>

        <div class="mat-elevation-z1 table-post mt-3">
          <mat-table #table [dataSource]="dataReservationList[i]">
            <ng-container matColumnDef="pnrNumber" id="create-waiver-post-pnrNumber">
              <mat-header-cell *matHeaderCellDef>{{ 'field.pnr' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.pnr.pnrNumber | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="pnrType" id="create-waiver-post-pnrType">
              <mat-header-cell *matHeaderCellDef>{{ 'field.pnrType' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.pnr.type | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="bookingCreation" id="create-waiver-post-bookingCreation">
              <mat-header-cell *matHeaderCellDef>{{ 'field.bookingCreation' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">
                {{ data.pnr.createDateTime | dateFormat:'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="NumberOfPassengers" id="create-waiver-post-numberOfPassengers">
              <mat-header-cell *matHeaderCellDef>{{ 'field.numberPassengers' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ dataPassengersList[i]?.data?.length }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="pos" id="create-waiver-post-pos">
              <mat-header-cell *matHeaderCellDef>{{ 'field.pos' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.booking.pos | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="iata" id="create-waiver-post-iata">
              <mat-header-cell *matHeaderCellDef>{{ 'field.iata' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.booking.iata | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="pcc" id="create-waiver-post-pcc">
              <mat-header-cell *matHeaderCellDef>{{ 'field.pcc' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.booking.ppc | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="officeId" id="create-waiver-post-officeId">
              <mat-header-cell *matHeaderCellDef>{{ 'field.officeId' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.booking.officeId | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsR" class="waiver-post-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsR; let i = index"
              [ngClass]="{ 'highlight': selectedReservationInformationRowIndex === i }"
              (mouseout)="highlightReservationInformationRow($event, i)"
              (mouseover)="highlightReservationInformationRow($event, i)" class="waiver-post-row"></mat-row>
          </mat-table>
        </div>
      </ng-container>

      <!-- informacion de pasajeros pos -->
      <ng-container id="postSalePNRPassengersDiv"
        *ngIf="(dataReservationList[i]?.data[0]?.passengerTicketRequestsWithError == 0 && isValidPostPNR() && isPassengerTicketRequestsCompletedPost()) && !isLoading()">
        <div class="row mt-4">
          <div class="col">
            <span class="subtitle">{{ 'passengerInfo' | translate }}</span>
          </div>
        </div>

        <div class="mat-elevation-z1 table-post mt-3">
          <mat-table id="passengersPostPNRTable" #table [dataSource]="dataPassengersList[i]">
            <ng-container matColumnDef="passengerName" id="create-waiver-post-passengerName">
              <mat-header-cell *matHeaderCellDef>{{ 'field.passengerName' | translate }}</mat-header-cell>
              <mat-cell [ngClass]="{ 'mat-cell-invalid': data.invalidTkt||data.allCouponsInvalid || data.infantTkt }"
                *matCellDef="let data">
                {{ (data.firstName | partialDisplay: '#' : 1 | dashWhenEmpty) + ' ' + (data.lastName | partialDisplay: '#' : 1 | dashWhenEmpty) }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="foid" id="create-waiver-post-foid">
              <mat-header-cell *matHeaderCellDef>{{ 'field.foid' | translate }}</mat-header-cell>
              <mat-cell [ngClass]="{ 'mat-cell-invalid': data.invalidTkt||data.allCouponsInvalid || data.infantTkt }"
                *matCellDef="let data">{{ data.foid | partialDisplay:'#' :-2  | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="passengerType" id="create-waiver-post-passengerType">
              <mat-header-cell *matHeaderCellDef>{{ 'field.passengerType' | translate }}
              </mat-header-cell>
              <mat-cell [ngClass]="{ 'mat-cell-invalid': data.invalidTkt||data.allCouponsInvalid || data.infantTkt }"
                *matCellDef="let data">{{ data.type | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="tkt" id="create-waiver-post-tkt">
              <mat-header-cell *matHeaderCellDef>{{ 'field.tkt' | translate }}</mat-header-cell>
              <mat-cell [ngClass]="{ 'mat-cell-invalid': data.invalidTkt||data.allCouponsInvalid || data.infantTkt }"
                *matCellDef="let data">{{ data.ticketNumber | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef class="pt-2">
                <mat-checkbox color="accent" [checked]="dataReservationList[i].data[0].selectionPassengers.hasValue() && isAllSelectedPassengers(i)"
                  [disabled]="dataReservationList[i].data[0].allPassengersInvalid"
                  [indeterminate]="dataReservationList[i].data[0].selectionPassengers.hasValue() && !isAllSelectedPassengers(i)"
                  (change)="$event ? masterTogglePassengersPost(i) : null">
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="pt-2">
                <div id="tooltipPassenger" *ngIf="!(row.infantTkt)" matTooltip="{{ 'tooltip.notHaveTkt' | translate }}"
                  matTooltipPosition="before"
                  [matTooltipDisabled]="!(row.invalidTkt || row.allCouponsInvalid || row.infantTkt)">
                  <mat-checkbox (click)="$event.stopPropagation()" color="accent"
                    (change)="$event ? toggleSelectionPassengerPost(i, row) : null"
                    [checked]="dataReservationList[i]?.data[0].selectionPassengers.isSelected(row)"
                    [disabled]="row.invalidTkt || row.allCouponsInvalid || row.infantTkt">
                  </mat-checkbox>
                </div>

                <div id="tooltipPassengerINF" *ngIf="row.infantTkt"
                  matTooltip="{{ 'tooltip.adultPassengerRequired' | translate }}" matTooltipPosition="before"
                  [matTooltipDisabled]="!(row.infantTkt)">
                  <mat-checkbox color="accent" [checked]="dataReservationList[i]?.data[0].selectionPassengers.isSelected(row)"
                    [disabled]="row.invalidTkt || row.allCouponsInvalid || row.infantTkt"
                    (change)="$event ? toggleSelectionPassengerPost(i, row) : null" (click)="$event.stopPropagation()">
                  </mat-checkbox>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsPPOS" class="waiver-post-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsPPOS; let i = index"
              [ngClass]="{ 'highlight': selectedPassengerInformationRowIndex === i || dataReservationList[i]?.data[0].selectionPassengers.isSelected(row) }"
              (click)="toggleSelectionPassengerPost(i, row)" (mouseout)="highlightPassengerInformationRow($event, i)"
              (mouseover)="highlightPassengerInformationRow($event, i)" class="waiver-post-row">
            </mat-row>
          </mat-table>
        </div>
      </ng-container>

      <!-- informacion segmento POST -->
      <div id="pnrSegmentsDiv"
        *ngIf="((dataReservationList[i]?.data[0]?.passengerTicketRequestsWithError == 0 && isValidPostPNR() && isPassengerTicketRequestsCompletedPost())) && !isLoading()">
        <div class="row mt-4">
          <div class="col">
            <span class="subtitle">{{ 'segmentsInformation' | translate }}</span>
          </div>
        </div>

        <div class="mat-elevation-z1 table-post mt-3">
          <mat-table id="segmentsTable" #table [dataSource]="dataSegmentsList[i]">
            <ng-container matColumnDef="segmentNumber" id="create-waiver-post-seg-segmentNumber">
              <mat-header-cell *matHeaderCellDef>{{ 'field.segmentNumber' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                *matCellDef="let data">{{ data.segmentNumber | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="carrierMkt" id="create-waiver-post-seg-carrierMkt">
              <mat-header-cell *matHeaderCellDef>{{ 'field.mktCarrier' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0}"
                *matCellDef="let data">{{ data.carrierMarketing | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="flightNumberMkt" id="create-waiver-post-seg-flightNumberMkt">
              <mat-header-cell *matHeaderCellDef>{{ 'field.mktFlightNumber' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                *matCellDef="let data">{{ data.flightNumber | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="class" id="create-waiver-post-seg-class">
              <mat-header-cell *matHeaderCellDef>{{ 'field.class' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                *matCellDef="let data">{{ data.class | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="origin" id="create-waiver-post-seg-origin">
              <mat-header-cell *matHeaderCellDef>{{ 'field.from' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0}"
                *matCellDef="let data">{{ data.source | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="destination" id="create-waiver-post-seg-destination">
              <mat-header-cell *matHeaderCellDef>{{ 'field.to' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0}"
                *matCellDef="let data">{{ data.destination | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="dateHourDeparture" id="create-waiver-post-seg-dateHourDeparture">
              <mat-header-cell *matHeaderCellDef>{{ 'field.departureDate' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                *matCellDef="let data">
                {{ data.departureDateTime | dateFormatWithoutLocale: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="status" id="create-waiver-post-seg-status">
              <mat-header-cell *matHeaderCellDef>{{ 'field.segmentStatus' | translate }}</mat-header-cell>
              <mat-cell
                [ngClass]="{ 'mat-cell-invalid': !validSegmentStatusList.includes(data.status) || data.invalidCoupons > 0 }"
                *matCellDef="let data">{{ data.status | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <!-- Este campo no se debe mostrar, pero permite obtener informacion del farebasis NO eliminar -->
            <ng-container matColumnDef="fareBasis" id="create-waiver-post-seg-fareBasis">
              <mat-header-cell *matHeaderCellDef [hidden]="true">{{ 'field.farebasis' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data" [hidden]="true">{{ data.fareBasis | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef class="pt-2">
                <mat-checkbox color="accent" [checked]="dataReservationList[i].data[0].selectionSegments.hasValue() && isAllSelectedSegments(i)"
                  [disabled]="(segmentsWithOneCouponInvalidQuantity + dataReservationList[i].data[0].invalidStatusSegmentsQuantity) === dataSegmentsList[i].data.length"
                  [indeterminate]="dataReservationList[i].data[0].selectionSegments.hasValue() && !isAllSelectedSegments(i)"
                  (change)="$event ? masterToggleSegments(i) : null;">
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="pt-2">
                <div id="tooltipSegmentPOST" *ngIf="selectedType.waiverCategory.code === 'POST'"
                  matTooltip="{{ 'tooltip.invalidSegmentCoupon' | translate }}" matTooltipPosition="before"
                  [matTooltipDisabled]="!(!validSegmentStatusList.includes(row.status) || row.invalidCoupons > 0)">
                  <mat-checkbox color="accent" [checked]="dataReservationList[i]?.data[0].selectionSegments.isSelected(row)"
                    [disabled]="!validSegmentStatusList.includes(row.status) || row.invalidCoupons > 0"
                    (change)="$event ? toggleSelectionSegmentPost(row, i) : null;" (click)="$event.stopPropagation()">
                  </mat-checkbox>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsS" class="waiver-post-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsS; let i = index"
              [ngClass]="{ 'highlight': selectedSegmentsInformationRowIndex === i || dataReservationList[i]?.data[0].selectionSegments.isSelected(row)}"
              (click)="toggleSelectionSegmentPost(row)" (mouseout)="highlightSegmentsInformationRow($event, i)"
              (mouseover)="highlightSegmentsInformationRow($event, i)" class="waiver-post-row">
            </mat-row>
          </mat-table>
        </div>
      </div>
      </mat-tab>
      </mat-tab-group>

      <!-- informacion ticket -->
      <mat-tab-group  [hidden]="disableTktTabs" >

        <mat-tab id="pnrTktTabs" label="{{ticketIndex+1}}" *ngFor="let pnr of tabNavList; let ticketIndex = index;">
      <ng-container id="tktInfoDiv" *ngIf="isValidPostTKT() && !isLoading()">
        <div class="row mt-4">
          <div class="col">
            <span class="subtitle">{{ 'tktInformation' | translate }}</span>
          </div>
        </div>

        <div class="mat-elevation-z1 table-post mt-3">
          <mat-table #table [dataSource]="dataTktList[ticketIndex]">
            <ng-container matColumnDef="tktNumber">
              <mat-header-cell *matHeaderCellDef>{{ 'field.tkt' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.tkt.tktNumber | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="pnrNum">
              <mat-header-cell *matHeaderCellDef>{{ 'field.pnr' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.tkt.pnr | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="emisionCreationDate">
              <mat-header-cell *matHeaderCellDef>{{ 'field.issueDate' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">
                {{ data.tkt.createDateTime | dateFormat: 'DD-MMM-YY' | uppercase | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="posInfo" class="posInfo">
              <mat-header-cell *matHeaderCellDef>{{ 'field.pos' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.issuer.pos | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="iataInfo" class="iataInfo">
              <mat-header-cell *matHeaderCellDef>{{ 'field.iata' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.booking.iata | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="pccInfo" class="pccInfo">
              <mat-header-cell *matHeaderCellDef>{{ 'field.pcc' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.booking.ppc | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="officeIdInfo" class="officeIdInfo">
              <mat-header-cell *matHeaderCellDef>{{ 'field.officeId' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.booking.officeId | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsTKT" class="waiver-post-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsTKT; let i = index"
              [ngClass]="{ 'highlight': selectedTktInformationRowIndex === i }"
              (mouseover)="highlightTktInformationRow($event, i)" (mouseout)="highlightTktInformationRow($event, i)"
              class="waiver-post-row">
            </mat-row>
          </mat-table>
        </div>
      </ng-container>

      <!-- informacion cupon -->
      <ng-container id="tktCouponsDiv" *ngIf="isValidPostTKT() && !isLoading()">
        <div class="row mt-4">
          <div class="col">
            <span class="subtitle">{{ 'couponInformation' | translate }}</span>
          </div>
        </div>

        <div class="mat-elevation-z1 table-post mt-3">
          <mat-table id="couponsTable" #table [dataSource]="dataVoucherList[ticketIndex]">
            <ng-container matColumnDef="voucherNumber">
              <mat-header-cell *matHeaderCellDef>{{ 'field.couponNumber' | translate }}</mat-header-cell>
              <mat-cell [ngClass]="{'mat-cell-invalid': invalidCouponStatusList.includes(data.status)}"
                *matCellDef="let data">{{ data.couponNumber | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="carrierTkt">
              <mat-header-cell *matHeaderCellDef>{{ 'field.carrier' | translate }}</mat-header-cell>
              <mat-cell [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                *matCellDef="let data">{{ data.carrierMarketing | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="flightNumberTkt">
              <mat-header-cell *matHeaderCellDef>{{ 'field.flightNumber' | translate }}</mat-header-cell>
              <mat-cell [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                *matCellDef="let data">{{ data.flightNumber | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="classTkt">
              <mat-header-cell *matHeaderCellDef>{{ 'field.class' | translate }}</mat-header-cell>
              <mat-cell [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                *matCellDef="let data">{{ data.class | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="originTkt">
              <mat-header-cell *matHeaderCellDef>{{ 'field.from' | translate }}</mat-header-cell>
              <mat-cell [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                *matCellDef="let data">{{ data.source | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="destinationTkt">
              <mat-header-cell *matHeaderCellDef>{{ 'field.to' | translate }}</mat-header-cell>
              <mat-cell [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                *matCellDef="let data">{{ data.destination | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="dateHourTkt">
              <mat-header-cell *matHeaderCellDef>{{ 'field.departureDate' | translate }}</mat-header-cell>
              <mat-cell [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                *matCellDef="let data">
                {{ data.departureDateTime | dateFormatWithoutLocale: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="farebasis">
              <mat-header-cell *matHeaderCellDef>{{ 'field.farebasis' | translate }}</mat-header-cell>
              <mat-cell [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                *matCellDef="let data">{{ data.fareBasis | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="voucherStatus">
              <mat-header-cell *matHeaderCellDef>{{ 'field.couponStatus' | translate }}</mat-header-cell>
              <mat-cell [ngClass]="{ 'mat-cell-invalid': invalidCouponStatusList.includes(data.status) }"
                *matCellDef="let data">{{ data.status | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef class="pt-2">
                <mat-checkbox color="accent" name="generalCouponsCheckbox"
                  [checked]="dataTktList[ticketIndex].data[0].selectionCoupons.hasValue() && isAllSelectedCoupons(ticketIndex)"
                  [disabled]="dataVoucherList[ticketIndex].data.length==invalidCouponsQuantity"
                  [indeterminate]="dataTktList[ticketIndex].data[0].selectionCoupons.hasValue() && !isAllSelectedCoupons(ticketIndex)"
                  (change)="$event ? masterToggleCoupons(ticketIndex) : null;">
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="pt-2">
                <div id="tooltip" matTooltip="{{ 'tooltip.invalidCoupon' | translate }}" matTooltipPosition="before"
                  [matTooltipDisabled]="!invalidCouponStatusList.includes(row.status)">
                  <mat-checkbox name="couponsCheckbox" color="accent" [checked]="dataTktList[ticketIndex].data[0].selectionCoupons.isSelected(row)"
                    [disabled]="invalidCouponStatusList.includes(row.status)"
                    (change)="$event ? dataTktList[ticketIndex].data[0].selectionCoupons.toggle(row) : null;" (click)="$event.stopPropagation()">
                  </mat-checkbox>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsV" class="waiver-post-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsV; let i = index"
              [ngClass]="{ 'highlight': selectedCouponsInformationRowIndex === i || dataTktList[ticketIndex].data[0].selectionCoupons.isSelected(row) }"
              (click)="toggleSelectionCouponsRowPost(row, ticketIndex)" (mouseout)="highlightCouponsInformationRow($event, i)"
              (mouseover)="highlightCouponsInformationRow($event, i)" class="waiver-post-row">
            </mat-row>
          </mat-table>
        </div>
      </ng-container>
      </mat-tab>
      </mat-tab-group>
      <div *ngIf="isLoading()" class="loader loading">
        <div class="loading-div">
          <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
          </svg>
          <span class="loading-span">{{ 'loading' | translate }}</span>
        </div>
      </div>

      <hr>

      <!-- button next -->
      <div class="row mt-4 mb-3 justify-content-end">
        <div></div>
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
          <button mat-raised-button matStepperNext [hidden]="selectedType.id != 2" [disabled]="isTypeSearchPnrOrTicket()"
            (click)="addFieldPNRTKT()" class="background-red w-100">{{ 'button.add' | translate }}</button>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
          <button mat-raised-button matStepperNext id="waiverRequestStep1NextButton" [disabled]="(!validPrePNR || selectionPassengers.selected.length < 1 || selectionSegments.selected.length < 1)
          &&(!isValidPostPNR() || !isSelectPassengersPost()|| !isSelectSegmentsPost())
          &&(!isValidPostTKT() || !isSelectCouponsPost())" (click)="calculateWaiver()"
            class="background-red w-100">{{ 'button.next' | translate }}</button>
        </div>
      </div>
    </mat-step>

    <mat-step *ngIf="selectedTypeCategory == 'POST' && waivers">
      <ng-template matStepLabel id="create-waiver-post-summ-request">
        <span>{{ 'requestSummary' | translate }}</span>
      </ng-template>

      <hr>

      <div class="row mt-3" id="create-waiver-post-summ-sub">
        <div class="col">
          <span class="subtitle">{{ 'requestSummary' | translate }}</span>
        </div>
      </div>

      <form id="resumenWaiverForm" #resumenWaiverForm="ngForm">
        <mat-tab-group>

          <mat-tab id="pnrTktTabs" label="{{i+1}}" *ngFor="let pnr of tabNavList; let i = index;">
        <ng-container *ngIf="!loadingSave">
          <div class="row mt-3">
            <div class="col">
              <table class="table table-bordered">
                <tbody>
                  <tr id="create-waiver-post-summ-type">
                    <th id="waiverSummaryType" scope="row" class="text-nowrap" width="25%">
                      {{ 'field.type' | translate }}
                    </th>
                    <td colspan="2" class="text-table-content" width="75%">{{ selectedType?.name }}</td>
                  </tr>

                  <tr id="waiverSummaryMotive">
                    <th scope="row" class="text-nowrap">{{ 'field.reason' | translate }}</th>
                    <td colspan="2" class="text-table-content">{{ selectedMotif?.name }}</td>
                  </tr>

                  <tr id="waiverSummaryDocumentNumber" class="border-bottom-green-2px">
                    <th *ngIf="pnrList[i]?.pnrNumber" scope="row" class="text-nowrap">{{ 'field.pnr' | translate }}</th>
                    <th *ngIf="ticketList[i]?.ticket" scope="row" class="text-nowrap">{{ 'field.tkt' | translate }}</th>
                    <td colspan="2" class="text-table-content">{{ pnrList[i]?.pnrNumber || ticketList[i]?.ticket }}
                      <a class="btn-verDetalle" (click)="openDialog(i)">{{ 'button.viewDetail' | translate }}</a>
                    </td>
                  </tr>

                  <tr id="waiverValor" *ngIf="verifyCalculateWaiver" class="border-none">
                    <th></th>
                    <td class="border-none"></td>
                    <td class="cell-green text-valor-waiver" width="25%">
                      <span>{{ 'field.waiverValueUsd' | translate }}</span>
                      <strong>{{ valorWaiver != 'NA' ? (valorWaiver | number: '1.2-2': 'en-US') : valorWaiver }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <label class="text-nowrap camp-txt-lib">{{ 'field.agencyPnr' | translate }}</label>
              <mat-hint class="opcional">{{ 'tooltip.optional' | translate }}</mat-hint>
            </div>

            <div *ngIf="dataReservationList.length !== 0 || dataTktList.length !== 0" class="col-6 col-md-4 col-lg-3 col-xl-3">
              <mat-form-field>
                <mat-label>{{ 'field.enterAgencyPnr' | translate }}</mat-label>
                <div *ngIf="dataReservationList.length !== 0">
                  <input matInput name="pnrAgency" #pnrAgency="ngModel" maxlength="10"
                  pattern="\b[0-9a-zA-ZáéíóúÁÉÍÓÚÑñ]+" [(ngModel)]="dataReservationList[i]?.data[0].pnrAgency">
                  <mat-error
                  *ngIf="pnrAgency.errors && (pnrAgency.dirty || pnrAgency.touched) && pnrAgency.errors.pattern">
                  {{ 'field.error.invalidData' | translate }}</mat-error>
                </div>
                <div *ngIf="dataTktList.length !== 0">
                  <input matInput name="pnrAgency" #pnrAgency="ngModel" maxlength="10"
                  pattern="\b[0-9a-zA-ZáéíóúÁÉÍÓÚÑñ]+" [(ngModel)]="dataTktList[i].data[0].pnrAgency">
                <mat-error
                  *ngIf="pnrAgency.errors && (pnrAgency.dirty || pnrAgency.touched) && pnrAgency.errors.pattern">
                  {{ 'field.error.invalidData' | translate }}</mat-error>
                </div>
              </mat-form-field>
            </div>

            <div class="col-1 pr-0 pl-0">
              <i matTooltip="{{ 'tooltip.optionalInformation' | translate }}" matTooltipPosition="after"
                class="material-icons info">error_outline</i>
            </div>
          </div>

          <div *ngIf="selectedMotif?.attachmentFile && dataReservationList.length !== 0" class="row mt-3">
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <button mat-raised-button id="adj-btn" matTooltip="{{ 'tooltip.allowedExtensions' | translate }}"
                matTooltipPosition="after" [disabled]="dataReservationList[i]?.data[0].files.length == 2" (click)="onClick(i)"
                class="background-blue w-100">{{ 'button.attachFile' | translate }}</button>
              <mat-hint class="opcional mt-1">{{ 'tooltip.maxSize' | translate }}</mat-hint>
              <mat-hint *ngIf="pnrList?.length > 1" class="opcional mt-1">{{ 'tooltip.optional' | translate }}</mat-hint>
            </div>

            <div class="col-8 col-md-8 col-lg-6 col-xl-6 containerAdjunto">
              <ul class="filename-list">
                <li class="filename-list-item no-files" *ngIf="dataReservationList[i]?.data[0].files.length == 0">
                  <span class="filename-span">{{ 'warning.fileRequired' | translate }}</span>
                </li>
                <li id="create-waiver-post-summ-item" class="filename-list-item" *ngFor="let file of dataReservationList[i]?.data[0].files" matTooltipPosition="after"
                  [matTooltip]="file.data.name">
                  <span class="filename-span" id="file-label">
                    {{ file.data.name }}
                  </span>
                  <div id="create-waiver-post-summ-del-load" *ngIf="file.data.locationStorage === undefined" class="deleting-loader">
                    <svg class="spinner" width="24px" height="24px" viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                        </circle>
                      </svg>
                  </div>
                  <a id="create-waiver-post-summ-cancel" title="Cancel" (click)="cancelFile(file, i)" *ngIf="file.canCancel && file.data.locationStorage !== undefined">
                    <mat-icon *ngIf="!file.deletingFile" class="cancelAdjunto">close</mat-icon>
                    <div class="deleting-loader" *ngIf="file.deletingFile">
                      <svg class="spinner" width="24px" height="24px" viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                        </circle>
                      </svg>
                    </div>
                  </a>
                </li>
              </ul>
              <input type="file" id="fileUpload-{{i}}" name="fileUpload-{{i}}" multiple="multiple" accept="{{accept}}"
                style="display:none;" />
            </div>
          </div>



          <div *ngIf="selectedMotif?.attachmentFile && dataTktList.length !== 0" class="row mt-3">
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <button mat-raised-button id="adj-btn" matTooltip="{{ 'tooltip.allowedExtensions' | translate }}"
                matTooltipPosition="after" [disabled]="dataTktList[i]?.data[0].files.length == 2" (click)="onClick(i)"
                class="background-blue w-100">{{ 'button.attachFile' | translate }}</button>
              <mat-hint class="opcional mt-1">{{ 'tooltip.maxSize' | translate }}</mat-hint>
              <mat-hint *ngIf="dataTktList?.length > 1" class="opcional mt-1">{{ 'tooltip.optional' | translate }}</mat-hint>
            </div>

            <div class="col-8 col-md-8 col-lg-6 col-xl-6 containerAdjunto">
              <ul class="filename-list">
                <li class="filename-list-item no-files" *ngIf="dataTktList[i]?.data[0].files.length == 0">
                  <span class="filename-span">{{ 'warning.fileRequired' | translate }}</span>
                </li>
                <li class="filename-list-item" *ngFor="let file of dataTktList[i]?.data[0].files" matTooltipPosition="after"
                  [matTooltip]="file.data.name">
                  <span class="filename-span" id="file-label">
                    {{ file.data.name }}
                  </span>
                  <div *ngIf="file.data.locationStorage === undefined" class="deleting-loader">
                    <svg class="spinner" width="24px" height="24px" viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                        </circle>
                      </svg>
                  </div>
                  <a title="Cancel" (click)="cancelFile(file, i)" *ngIf="file.canCancel && file.data.locationStorage !== undefined">
                    <mat-icon *ngIf="!file.deletingFile" class="cancelAdjunto">close</mat-icon>
                    <div class="deleting-loader" *ngIf="file.deletingFile">
                      <svg class="spinner" width="24px" height="24px" viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                        </circle>
                      </svg>
                    </div>
                  </a>
                </li>
              </ul>
              <input type="file" id="fileUpload-{{i}}" name="fileUpload-{{i}}" multiple="multiple" accept="{{accept}}"
                style="display:none;" />
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <label class="text-nowrap camp-txt-lib">{{ 'field.addComment' | translate }}</label>
              <mat-hint class="opcional">{{ (selectedType?.code === 'RED' ? 'tooltip.required' : 'tooltip.optional') | translate }}</mat-hint>
            </div>

            <div class="col-8 col-md-8 col-lg-6 col-xl-6">
              <div *ngIf="dataReservationList.length !== 0" class="text-libre-tab">
                <textarea matInput name="aditionalComment{{i}}" #message placeholder="{{ 'field.enterComment' | translate }}"
                  maxlength="500" [(ngModel)]="dataReservationList[i].data[0].aditionalComment" [required]="selectedType?.code === 'RED'" lass="aditional-comment"></textarea>
                <mat-hint class="text-area" align="end">{{ message.value.length }} / 500</mat-hint>
              </div>
              <div *ngIf="dataTktList.length !== 0" class="text-libre-tab">
                <textarea matInput name="aditionalComment{{i}}" #message placeholder="{{ 'field.enterComment' | translate }}"
                  maxlength="500" [(ngModel)]="dataTktList[i].data[0].aditionalComment" [required]="selectedType?.code === 'RED'" class="aditional-comment"></textarea>
                <mat-hint class="text-area" align="end">{{ message.value.length }} / 500</mat-hint>
              </div>
            </div>
          </div>

        </ng-container>
        </mat-tab>



      </mat-tab-group>
        <div *ngIf="loadingSave" class="loader loading">
          <div class="loading-div">
            <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
            </svg>
            <span class="loading-span">{{ 'sendingRequest' | translate }}</span>
          </div>
        </div>

        <hr>
        <div *ngIf="!loadingSave && selectedMotif?.attachmentFile && ((dataReservationList.length != 0 && dataReservationList.length != 1) || (dataTktList.length != 0 && dataTktList.length != 1))">
          <div class="row mt-3 ml-1">
            <label class="text-nowrap camp-txt-lib">{{ 'messageAttachAll' | translate }}</label>
          </div>

          <div class="row mt-3">
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <button mat-raised-button id="adj-btn" matTooltip="{{ 'tooltip.allowedExtensions' | translate }}"
                matTooltipPosition="after" [disabled]="files.length == 2" (click)="onClickAll()"
                class="background-blue w-100">{{ 'button.attachFile' | translate }}</button>
              <mat-hint class="opcional mt-1">{{ 'tooltip.maxSize' | translate }}</mat-hint>
            </div>

            <div class="col-8 col-md-8 col-lg-6 col-xl-6 containerAdjunto">
              <ul class="filename-list">
                <li class="filename-list-item no-files" *ngIf="files.length == 0">
                  <span class="filename-span">{{ 'warning.fileRequired' | translate }}</span>
                </li>
                <li class="filename-list-item" *ngFor="let file of files" matTooltipPosition="after"
                  [matTooltip]="file.data.name">
                  <span class="filename-span" id="file-label">
                    {{ file.data.name }}
                  </span>
                  <div *ngIf="file.data.locationStorage === undefined" class="deleting-loader">
                    <svg class="spinner" width="24px" height="24px" viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                        </circle>
                      </svg>
                  </div>
                  <a title="Cancel" (click)="cancelFile(file)" *ngIf="file.canCancel && file.data.locationStorage !== undefined">
                    <mat-icon *ngIf="!file.deletingFile" class="cancelAdjunto">close</mat-icon>
                    <div class="deleting-loader" *ngIf="file.deletingFile">
                      <svg class="spinner" width="24px" height="24px" viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                        </circle>
                      </svg>
                    </div>
                  </a>
                </li>
              </ul>
              <input type="file" id="fileUpload" name="fileUpload" multiple="multiple" accept="{{accept}}"
                style="display:none;" />
            </div>
          </div>
        </div>


        <div class="row mb-3 justify-content-between">
          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <button mat-raised-button matStepperPrevious id="step2BackButton" [disabled]="loadingSave"
              class="btn-volver w-100">{{ 'button.back' | translate }}</button>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <button mat-raised-button matStepperNext id="sendRequestButton"
              [disabled]="loadingUpload || loadingSave || (!profiles.createPostSaleRequest && !profiles.createWaiverRequest ) || resumenWaiverForm.invalid  || !validateFilePost() || !validateValorization()"
              (click)="saveListWaiver()" class="background-red w-100">{{ 'button.sendRequest' | translate }}</button>
          </div>
        </div>
      </form>
    </mat-step>

    <mat-step *ngIf="selectedTypeCategory == 'PRE'">
      <ng-template matStepLabel>
        <span>{{ 'requestSummary' | translate }}</span>
      </ng-template>

      <hr>

      <div class="row mt-3">
        <div class="col">
          <span class="subtitle">{{ 'requestSummary' | translate }}</span>
        </div>
      </div>

      <form id="resumenWaiverForm" #resumenWaiverForm="ngForm">
        <mat-tab-group>

          <mat-tab id="pnrTktTabs" label="{{i+1}}" *ngFor="let pnr of tabNavList; let i = index;">
        <ng-container *ngIf="!loadingSave">
          <div class="row mt-3">
            <div class="col">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <th id="waiverSummaryType" scope="row" class="text-nowrap" width="25%">
                      {{ 'field.type' | translate }}
                    </th>
                    <td colspan="2" class="text-table-content" width="75%">{{ selectedType?.name }}</td>
                  </tr>

                  <tr id="waiverSummaryMotive">
                    <th scope="row" class="text-nowrap">{{ 'field.reason' | translate }}</th>
                    <td colspan="2" class="text-table-content">{{ selectedMotif?.name }}</td>
                  </tr>

                  <tr id="waiverSummaryDocumentNumber" class="border-bottom-green-2px">
                    <th *ngIf="this.waiver.pnrNumber" scope="row" class="text-nowrap">{{ 'field.pnr' | translate }}</th>
                    <th *ngIf="this.waiver.ticket" scope="row" class="text-nowrap">{{ 'field.tkt' | translate }}</th>
                    <td colspan="2" class="text-table-content">{{ this.waiver.pnrNumber || this.waiver.ticket }}
                      <a class="btn-verDetalle" (click)="selectedType.waiverCategory.code === 'PRE' ? openDialogPre(i) : openDialog(i)">{{ 'button.viewDetail' | translate }}</a>
                    </td>
                  </tr>

                  <tr id="waiverValor" *ngIf="verifyCalculateWaiver" class="border-none">
                    <th></th>
                    <td class="border-none"></td>
                    <td class="cell-green text-valor-waiver" width="25%">
                      <span>{{ 'field.waiverValueUsd' | translate }}</span>
                      <strong>{{ valorWaiver != 'NA' ? (valorWaiver | number: '1.2-2': 'en-US') : valorWaiver }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div *ngIf="selectedMotif?.attachmentFile" class="row mt-3">
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <button mat-raised-button id="adj-btn" matTooltip="{{ 'tooltip.allowedExtensions' | translate }}"
                matTooltipPosition="after" [disabled]="files.length == 2" (click)="onClick(i)"
                class="background-blue w-100">{{ 'button.attachFile' | translate }}</button>
              <mat-hint class="opcional mt-1">{{ 'tooltip.maxSize' | translate }}</mat-hint>
            </div>

            <div class="col-8 col-md-8 col-lg-6 col-xl-6 containerAdjunto">
              <ul class="filename-list">
                <li class="filename-list-item no-files" *ngIf="files.length == 0">
                  <span class="filename-span">{{ 'warning.fileRequired' | translate }}</span>
                </li>
                <li class="filename-list-item" *ngFor="let file of files" matTooltipPosition="after"
                  [matTooltip]="file.data.name" [@fadeInOut]="file.state">
                  <span class="filename-span" id="file-label">
                    {{ file.data.name }}
                  </span>
                  <a title="Cancel" (click)="cancelFile(file)" *ngIf="file.canCancel">
                    <mat-icon *ngIf="!file.deletingFile" class="cancelAdjunto">close</mat-icon>
                    <div class="deleting-loader" *ngIf="file.deletingFile">
                      <svg class="spinner" width="24px" height="24px" viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                        </circle>
                      </svg>
                    </div>
                  </a>
                </li>
              </ul>
              <input type="file" id="fileUpload{{i}}" name="fileUpload{{i}}" multiple="multiple" accept="{{accept}}"
                style="display:none;" />
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <label class="text-nowrap camp-txt-lib">{{ 'field.addComment' | translate }}</label>
              <mat-hint class="opcional">{{ 'tooltip.optional' | translate }}</mat-hint>
            </div>

            <div class="col-8 col-md-8 col-lg-6 col-xl-6">
              <div class="text-libre-tab">
                <textarea matInput name="aditionalComment{{i}}" #message placeholder="{{ 'field.enterComment' | translate }}"
                  maxlength="500" [(ngModel)]="aditionalComment" class="aditional-comment"></textarea>
                <mat-hint class="text-area" align="end">{{ message.value.length }} / 500</mat-hint>
              </div>
            </div>
          </div>
        </ng-container>
        </mat-tab>
      </mat-tab-group>
        <div *ngIf="loadingSave" class="loader loading">
          <div class="loading-div">
            <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
            </svg>
            <span class="loading-span">{{ 'sendingRequest' | translate }}</span>
          </div>
        </div>

        <hr>

        <div class="row mb-3 justify-content-between">
          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <button mat-raised-button matStepperPrevious id="step2BackButton" [disabled]="loadingSave"
              class="btn-volver w-100">{{ 'button.back' | translate }}</button>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <button mat-raised-button matStepperNext id="sendRequestButton"
              [disabled]="loadingSave || (!profiles.createPostSaleRequest && !profiles.createWaiverRequest ) || resumenWaiverForm.invalid  || !validateFile() || !validateValorization()"
              (click)="selectedType.waiverCategory.code === 'PRE' ? saveWaiver() : saveListWaiver()" class="background-red w-100">{{ 'button.sendRequest' | translate }}</button>
          </div>
        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</div>

<div class="table-waivers-latam">
  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="id" id="waiver-list-table-id">
      <mat-header-cell *matHeaderCellDef class="title">
        <div>
          <span>{{ 'field.idRequest' | translate }}</span>
          <span mat-sort-header></span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let object">{{ object.id | dashWhenEmpty }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="marketName" id="waiver-list-table-marketName">
      <mat-header-cell *matHeaderCellDef class="title">
        <span>{{ 'field.pos' | translate }}</span>
        <span mat-sort-header></span>
      </mat-header-cell>
      <mat-cell *matCellDef="let object">{{ object.marketName | dashWhenEmpty }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="agency" id="waiver-list-table-agency">
      <mat-header-cell *matHeaderCellDef class="title">
        <div>
          <span>{{ 'field.agency' | translate }}</span>
          <span mat-sort-header></span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let object">
        {{ (object.branchOffice ? object.branchOffice : object.agencyGroup) | dashWhenEmpty }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="agencyIata" id="waiver-list-table-agencyIata">
      <mat-header-cell *matHeaderCellDef class="title">
        <div>
          <span>{{ 'field.iata' | translate }}</span>
          <span mat-sort-header></span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let object">{{ object.agencyIata | dashWhenEmpty }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="executive" id="waiver-list-table-executive">
      <mat-header-cell *matHeaderCellDef class="title">
        <div>
          <span>{{ 'field.executiveSale' | translate }}</span>
          <span mat-sort-header></span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let object">{{ object.executive| dashWhenEmpty }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="type" id="waiver-list-table-type">
      <mat-header-cell *matHeaderCellDef class="title">
        <div>
          <span>{{ 'field.type' | translate }}</span>
          <span mat-sort-header></span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let object">{{ object.type | dashWhenEmpty }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="motive" id="waiver-list-table-motive">
      <mat-header-cell *matHeaderCellDef class="title">
        <div>
          <span>{{ 'field.reason' | translate }}</span>
          <span mat-sort-header></span>
        </div>
       </mat-header-cell>
      <mat-cell *matCellDef="let object">{{ object.motive | dashWhenEmpty }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="documentNumber" id="waiver-list-table-documentNumber">
      <mat-header-cell *matHeaderCellDef class="title">
        <div>
          <span>{{ 'field.pnrTkt' | translate }}</span>
          <span mat-sort-header></span>
        </div>
       </mat-header-cell>
      <mat-cell *matCellDef="let object">{{ object.documentNumber | dashWhenEmpty }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="statusDate" id="waiver-list-table-statusDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="title">
        <span>{{ getTableHeader() | translate }}</span>
        </mat-header-cell>
      <mat-cell *matCellDef="let object">
        {{ object.statusDate | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="documentCloserDepartureDate" id="waiver-list-table-documentCloserDepartureDate">
      <mat-header-cell *matHeaderCellDef class="title">
        <div>
          <span>{{ 'field.flightDate' | translate }}</span>
          <span mat-sort-header></span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let object">
        {{ object.documentCloserDepartureDate | dateFormat: 'DD-MMM-YY' | uppercase | dashWhenEmpty }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions" id="waiver-list-table-actions">
      <mat-header-cell *matHeaderCellDef class="title">{{ 'button.actions' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let object">
        <button class="action">
          <span (click)="openDialog(getTab(), object)">{{ 'button.view' | translate }}</span>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="no-data-found" id="waiver-list-table-no-data-found">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell class="no-data-found-row" *matCellDef="let item">{{ 'error.notDeniedWaivers' | translate }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="loading-data-error" id="waiver-list-table-loading-data-error">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell class="loading-data-error-row" *matCellDef="let item">
        <span class="cdk-visually-hidden" #translatedTooltipMessage>{{ 'button.refresh' | translate }}</span>
        <button mat-icon-button (click)="reloadTableEvent()" class="refresh-spinner">
          <mat-icon [matTooltip]="translatedTooltipMessage.innerHTML" [matTooltipPosition]="'right'">refresh
          </mat-icon>
        </button>
        <br>
        <span>{{ getErrorMessage() | translate }}</span>
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns" class="waivers-latam-row" id="waiver-list-table-waivers-latam-row"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
      class="waivers-latam-row" id="waiver-list-table-waivers-latam-row"></mat-row>
    <mat-row id="waiver-list-table-no-data-found-row" *matRowDef="let row; columns: ['no-data-found']; when: isEmptyData"></mat-row>
    <mat-row id="waiver-list-table-loading-data-error-row" *matRowDef="let row; columns: ['loading-data-error']; when: errorLoading">
    </mat-row>
  </mat-table>
  <mat-paginator
    (page)="pageChanged($event)"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="true">
  </mat-paginator>
</div>

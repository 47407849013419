<div class="row mt-4 mr-3 ml-3">
  <div class="col">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="latam-sans breadcrumb-item">
          <a>{{ 'menu.latamManagement' | translate }}</a>
        </li>
        <li class="breadcrumb-item latam-sans active" aria-current="page">
          <a>{{ 'menu.budgetManagement' | translate }}</a>
        </li>
      </ol>
    </nav>
  </div>
</div>

<div class="row mt-4 mr-3 ml-3">
  <div class="col">
    <span class="title">{{ 'menu.latamBudgetManagement' | translate }}</span>
  </div>
</div>

<hr>

<div *ngIf="loading" class="loading-div">

  <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
    <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
  </svg>
  <span class="loading-span">{{ 'loading' | translate }}</span>
</div>

<ng-container *ngIf="!loading">
  <div class="row mt-4 mr-3 mb-3 ml-3">
    <div class="col">
      <span class="subtitle">{{ 'partnerAgencies' | translate }}</span>
    </div>
  </div>

  <app-monthly-budget [errorLoadingData]="errorLoadingData" [displayedColumns]="displayedColumns" [budget]="budget"
    [agencyGroupCurrentAmount]="agencyGroupCurrentAmount" (budgetAux)="setBudget($event)"
    (newTotalCurrentAmount)="setNewTotalCurrentAmount($event)" (refreshingBudget)="refreshBudget($event) " (validatesave) ="setValidateSave($event)">
  </app-monthly-budget>

  <div class="row justify-content-between mt-3 mr-3 ml-3 mb-4">
    <div class="col-2">
      <button mat-raised-button id="backButton" (click)="goBack()"
        class="btn-volver w-100">{{ 'button.back' | translate }}</button>
    </div>
    <ng-container *ngIf="budget.length > 0">
      <div class="col-2">
        <button mat-raised-button id="save" [disabled]="newTotalCurrentAmount < 0 || !validateSave" (click)="saveBudget()"
          class="background-red w-100">{{ 'button.save' | translate }}</button>
      </div>
    </ng-container>
  </div>
</ng-container>

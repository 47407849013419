<div class="container">
  <app-list-agency-header></app-list-agency-header>
  <div class="row justify-content-between">
    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
      <button mat-raised-button *ngIf="profiles.readWaiverPostSaleManagementAgency || profiles.readWaiverManagementAgency" (click)="nuevaSolicitud()"
        class="background-red w-100">{{ 'button.newRequest' | translate }}</button>
    </div>

    <app-list-waiver-agent-budget></app-list-waiver-agent-budget>
  </div>

  <mat-card>
    <mat-card-content>
      <mat-tab-group #tabGroup>
        <mat-tab>
          <ng-template mat-tab-label>
            <span>{{ 'pendings' | translate }}</span>
          </ng-template>
          <app-waiver-list-postsale [displayedColumns]="displayedColumnsPending" [tab]="'pendientes'" [user]="'agent'"></app-waiver-list-postsale>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <span>{{ 'approveds' | translate }}</span>
          </ng-template>

          <app-waiver-list-postsale [displayedColumns]="displayedColumnsApproved" [tab]="'aprobados'" [user]="'agent'"></app-waiver-list-postsale>
        </mat-tab>

        <mat-tab label="Rechazados">
          <ng-template mat-tab-label>
            <span>{{ 'denieds' | translate }}</span>
          </ng-template>

          <app-waiver-list-postsale [displayedColumns]="displayedColumnsRejected" [tab]="'rechazados'" [user]="'agent'"></app-waiver-list-postsale>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>

<div class="modal-content small-modal">
  <div class="modal-header">
    <h5 class="modal-title">{{ 'field.branchOfficeName' | translate }}</h5>
    <!-- <button class="close" (click)="closeDialog()">
      <mat-icon class="close-icon">clear</mat-icon>
    </button> -->
  </div>

  <div class="modal-body">
    <form #createBranchOfficeForm="ngForm">
      <div *ngIf="loading" class="loader loading">
        <div class="loading-div">
        <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
        <span class="loading-span">{{ 'loading' | translate }}</span>
      </div>
      </div>

      <div *ngIf="!loading">
        <div class="row">
          <div class="col-12">
            <div class="form-group" [ngClass]="{'has-error':name.invalid &&  (name.dirty || name.touched)}">
              <mat-form-field>
                <mat-label>{{ 'field.branchOfficeName' | translate }}</mat-label>
                <input id="branchOfficeNameInput" matInput
                  name="name" [(ngModel)]="branchOfficeName" #name="ngModel"
                  pattern="[A-zÀ-ú:\u3000\u3400-\u4DBF\u4E00-\u9FFF\0-9]+" maxlength="25" required>
                <span matPrefix>{{prefix}}</span>
                <mat-error *ngIf="name.errors && (name.dirty || name.touched) && name.errors.required">
                  {{ 'field.error.required' | translate }}</mat-error>
                <mat-error *ngIf="name.errors && (name.dirty || name.touched) && name.errors.pattern">
                  {{ 'field.error.invalidData' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer" *ngIf="profiles.createBranchOfficeLT">
    <button mat-raised-button id="closeButton" (click)="closeDialog()"
      class="btn-volver btn-w">{{ 'button.cancel' | translate }}</button>
    <button mat-raised-button id="createUserButton" data-dismiss="modal"
      [disabled]="createBranchOfficeForm.invalid || isCreatingBranchOffice" (click)="saveBranchOffice()"
      class="background-red btn-w float-right">{{ 'button.create' | translate }}</button>
  </div>
</div>

<form [formGroup]="formG" class="login-form">
  <div class="row justify-content-center">
    <div class="col-sm-8 col-md-8 col-lg-6 col-xl-6">
      <mat-form-field>
        <mat-label>{{ 'login.field.email' | translate }}</mat-label>
        <input matInput email id="email" type="email" formControlName="email" required>
        <mat-error
          *ngIf="formG.controls.email.invalid && (formG.controls.email.dirty || formG.controls.email.touched) && formG.controls.email.errors.required">
          {{ 'field.error.required' | translate }}</mat-error>
        <mat-error
          *ngIf="formG.controls.email.invalid && (formG.controls.email.dirty || formG.controls.email.touched) && formG.controls.email.errors.email && !formG.controls.email.errors.required">
          {{ 'field.error.invalidEmail' | translate }}</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-sm-8 col-md-8 col-lg-6 col-xl-6">
      <mat-form-field>
        <mat-label>{{ 'login.field.password' | translate }}</mat-label>
        <input matInput id="cred" formControlName="cred"
          [type]="hide ? 'password' : 'text'" required>
        <mat-icon matSuffix (click)="hide = !hide"
          [ngClass]="formG.controls['cred'].hasError('required') && (formG.controls.cred.dirty || formG.controls.cred.touched) ? 'eye-icon-error' : 'eye-icon'">
          {{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
        <mat-error
          *ngIf="formG.controls.cred.invalid && (formG.controls.cred.dirty || formG.controls.cred.touched) && formG.controls.cred.errors.required">
          {{ 'field.error.required' | translate }}</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row justify-content-center mt-4">
    <div class="col-sm-8 col-md-8 col-lg-6 col-xl-6">
      <button [disabled]="!formG.valid || isDisabled" (click)="login()"
        class="btn btn-Latam w-100">{{ 'button.signIn' | translate }}</button>
    </div>
  </div>
</form>

export class DownloadLatamReportBase {
  public id: number;
  public waiverCategory: string;
  public type: string;
  public status: string;
  public creationDate: Date;
  public latamUserId: string;
  public statusUser: string;
  public statusDate: Date;
  public documentNumber: string;
  public pos: string;
  public agencyGroup: string;
  public user: string;
  public departureDate: Date;
  public motive: string;
  public aditionalComment: string;
  public statusComment: string;
  public pnrAgency: string;
  public rejectionMotive: string;
  public folio: string;
  public valorization: number;
  public tkt: string;
  public iataPnr: string;
  public iataOsi: string;
  public accountCode: string;
  public branchOffice: string;
  public tier: string;
  public channel: string;
  public adminSales: string;
  public userSales: string;
  public originalFare: number;
  public approvedFare: number;
  public totalQ: number;
  public discount: string;
  public oriDes: string;
  public cabin: string;
  public codeShare: string;
  public pax: number;
  public carrier: string;
  public desiredFare: number;
  public requestQ: number;


  public constructor(
    id: number,
    waiverCategory: string,
    type: string,
    status: string,
    creationDate: Date,
    latamUserId: string,
    statusUser: string,
    statusDate: Date,
    documentNumber: string,
    pos: string,
    agencyGroup: string,
    user: string,
    departureDate: Date,
    motive: string,
    aditionalComment: string,
    statusComment: string,
    pnrAgency: string,
    rejectionMotive: string,
    folio: string,
    valorization: number,
    tkt: string,
    iataPnr: string,
    iataOsi: string,
    accountCode: string,
    branchOffice: string,
    tier: string,
    channel: string,
    adminSales: string,
    userSales: string,
    originalFare: number,
    approvedFare: number,
    totalQ: number,
    discount: string,
    oriDes: string,
    cabin: string,
    codeShare: string,
    pax: number,
    carrier: string,
    desiredFare: number,
    requestQ: number
  ) {
    this.id = id;
    this.waiverCategory = waiverCategory;
    this.type = type;
    this.status = status;
    this.creationDate = creationDate;
    this.latamUserId = latamUserId;
    this.statusUser = statusUser;
    this.statusDate = statusDate;
    this.documentNumber = documentNumber;
    this.pos = pos;
    this.agencyGroup = agencyGroup;
    this.user = user;
    this.departureDate = departureDate;
    this.motive = motive;
    this.aditionalComment = aditionalComment;
    this.statusComment = statusComment;
    this.pnrAgency = pnrAgency;
    this.rejectionMotive = rejectionMotive;
    this.folio = folio;
    this.valorization = valorization;
    this.tkt = tkt;
    this.iataPnr = iataPnr;
    this.iataOsi = iataOsi;
    this.accountCode = accountCode;
    this.branchOffice = branchOffice;
    this.tier = tier;
    this.channel = channel;
    this.adminSales = adminSales;
    this.userSales = userSales;
    this.originalFare = originalFare;
    this.approvedFare = approvedFare;
    this.totalQ = totalQ;
    this.discount = discount;
    this.oriDes = oriDes;
    this.cabin = cabin;
    this.codeShare = codeShare;
    this.pax = pax;
    this.carrier = carrier;
    this.desiredFare = desiredFare;
    this.requestQ = requestQ;
  }
}

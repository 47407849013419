<div class="modal-header">
  <div class="title">
    <h2 class="terminos" id="termsH2">{{ 'terms' | translate }}</h2>
    <h1 class="condiciones" id="conditionsH1">{{ 'conditions' | translate }}</h1>
  </div>
</div>
<div id="modalContent" (scroll)="onScroll()" class="modal-content">
  <div class="loading-div" *ngIf="pdfLoading">
    <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
    <span class="loading-span">{{ 'loading' | translate }}</span>
  </div>
  <div>
    <pdf-viewer [src]="pdfSrc" (after-load-complete)="pdfLoaded()" [render-text]="true" [original-size]="false"
      [autoresize]="true"></pdf-viewer>
    <div class="latam-welcome mb-5">
      <h1 class="latam-trade mr-5">{{ 'latamTrade' | translate }}</h1>
      <h3 class="bienvenida ml-5">{{ 'welcomeMessage' | translate }}</h3>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="row">
    <div class="col-6">
      <button mat-raised-button id="backButton" (click)="logout()"
        class="reject-button background-gray">{{ 'button.exit' | translate }}</button>
    </div>
    <div class="col-6">
      <button mat-raised-button id="acceptButton"
        [disabled]="pdfLoading || (scrollTop + clientHeight) <= scrollHeight*0.98" (click)="agreeTermsConditions()"
        class="accept-button background-red">
        <span class="mat-button-wrapper" id="acceptButtonSpan">{{ 'button.iAgree' | translate }}</span>
        <div class="mat-button-ripple mat-ripple" matripple=""></div>
        <div class="mat-button-focus-overlay"></div>
      </button>
    </div>
  </div>
</div>
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Language } from '../../../../models/language.model';
import { LatamRole } from '../../../../models/latam-role.model';
import { Market } from '../../../../models/market.model';
import { SessionInfo } from '../../../../models/SessionInfo.model';
import { SidebarMenuModel } from '../../../../models/SidebarMenu.model';
import { UserLatam } from '../../../../models/user-latam.model';
import { UserLatamPosition } from '../../../../models/user-latam-position.model';

import { LatamService } from '../../../../services/latam.service';
import { MarketService } from './../../../../services/market.service';
import { SessionInfoService } from '../../../../services/session-info.service';
import { SidebarShareDataService } from '../../../../services/sidebar-share-data.service';
import { MessageErrorService } from '../../../../services/message-error.service';


@Component({
  selector: 'app-user-profile-latam',
  templateUrl: './user-profile-latam.component.html',
  styleUrls: ['./user-profile-latam.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UserProfileLatamComponent implements OnInit {
  @ViewChild('profileLatamUserForm') profileLatamUserForm: NgForm;
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_LATAM, SidebarMenuModel.SUBMENU_LATAM_USER_CREATION);
  markets: Market[];
  userLatamPositions: UserLatamPosition[];
  latamRoles: LatamRole[];
  languages: Language[];
  userLatam: UserLatam = new UserLatam();
  loading: boolean = true;
  infoUser$: Promise<SessionInfo> = this.sessionInfoService.getSessionInfoAsPromise();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private latamService: LatamService,
    private marketService: MarketService,
    private messageErrorService: MessageErrorService,
    private sessionInfoService: SessionInfoService,
    private sidebarShareDataService: SidebarShareDataService) {
  }

  ngOnInit() {
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.activatedRoute.queryParams.subscribe(params => {
      this.userLatam.id = params.id;
      this.loadUserLatamProfile();

      this.latamService.getUserLatamPositions().subscribe((data: UserLatamPosition[]) => {
        this.userLatamPositions = data;
      });
      this.marketService.getMarkets().subscribe((data: Market[]) => {
        this.markets = data;
      });
      this.latamService.getLanguages().subscribe((data: Language[]) => {
        this.languages = data;
      });
    });
  }

  /**
   * metodo que obtiene el rol del usuario
   */
  private loadLatamRoleListByUserRole() {
    this.latamService.getRolesLatam().subscribe({
      next: data => {
        if (this.userLatam != null && this.userLatam.role.id === 1) {
          this.latamRoles = data;
        } else {
          let latamRolesData: LatamRole[] = [];
          data.map((value: LatamRole) => {
            if (value.id !== 1) {
              latamRolesData.push(new LatamRole(value.id, value.name));
            }
          });
          this.latamRoles = latamRolesData;
        }
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.loading = false;
        console.log('Error loading user Latam Role', error);
      }
  });
  }

  /**
   * Carga la informacion del usuario
   */
  private loadUserLatamProfile() {
    this.latamService.getUserLatamById(this.userLatam.id).subscribe({
      next: data => {
        if (data.name != null) {
          this.userLatam = data;
          this.loadLatamRoleListByUserRole();
        }
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.getUser');

        this.returnToList();
        this.loading = false;
        console.log('error', error);
      }
  });
  }

  compareMarket(market1: Market, market2: Market) {
    return market1 && market2 ? market1.id === market2.id : market1 === market2;
  }

  compareRole(role1: LatamRole, role2: LatamRole) {
    return role1 && role2 ? role1.id === role2.id : role1 === role2;
  }

  compareLanguage(language1: Language, language2: Language) {
    return language1 && language2 ? language1.id === language2.id : language1 === language2;
  }

  comparePosition(position1: UserLatamPosition, position2: UserLatamPosition) {
    return position1 && position2 ? position1.id === position2.id : position1 === position2;
  }

  returnToList() {
    // Se incorpora this.activatedRoute , queryParams para que actualize al hacer el volver en register si hay cambios
    this.router.navigate(['../list'], { relativeTo: this.activatedRoute });
  }
}

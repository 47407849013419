import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewChecked, Component, EventEmitter, OnInit, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Carrier } from '../../../models/carrier.model';
import { SolicitedValue } from '../../../models/solicited-value.model';

import { WaiverPresaleService } from '../../../services/waiver-presale.service';


@Component({
  selector: 'app-fare-diff',
  templateUrl: './fare-diff.component.html',
  styleUrls: ['./fare-diff.component.css']
})
export class FareDiffComponent implements OnInit, OnChanges, AfterViewChecked {

  totalFareValue: number = null;
  baseFareDesiredAux: number = null;
  queuesAux: number = null;

  carriers: Carrier[];

  formG: UntypedFormGroup;

  @Input() isPrivate: boolean = false;
  @Input() isValuated: boolean = false;
  @Input() isValuating: boolean = false;
  @Output() isFareValueValid: EventEmitter<boolean> = new EventEmitter();
  @Output() cxRivalry: EventEmitter<string> = new EventEmitter();
  @Output() solicitedValue: EventEmitter<SolicitedValue> = new EventEmitter();
  @Output() setValuated: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private formB: UntypedFormBuilder,
    private waiverPresaleService: WaiverPresaleService) { }

  ngOnInit() {
    this.buildForm();

    if (!this.isPrivate) {
      this.getCarriers();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isValuating) {
      if (changes.isValuating.currentValue) {
        this.formG.get('carrier').disable();
        this.formG.get('baseFareDesired').disable();
        this.formG.get('queues').disable();
      } else {
        if (!changes.isValuating.firstChange) {
          this.formG.get('carrier').enable();
          this.formG.get('baseFareDesired').enable();
          this.formG.get('queues').enable();
        }
      }
    }
  }

  ngAfterViewChecked() {
    this.isFareValueValid.emit(this.formG.valid);
  }

  buildForm() {
    this.formG = this.formB.group({
      carrier: [null, !this.isPrivate ? Validators.required : null],
      baseFareDesired: [null, Validators.required],
      queues: [null, Validators.required]
    });
  }

  setTotalFare() {
    if (this.formG.get('baseFareDesired').value && this.formG.get('queues').value) {
      this.totalFareValue = Number(this.formG.get('baseFareDesired').value) + Number(this.formG.get('queues').value);

      this.solicitedValue.emit(new SolicitedValue(
        this.formG.get('baseFareDesired').value,
        this.formG.get('queues').value,
        this.totalFareValue
      ));
    }
  }

  private getCarriers(): void {
    this.waiverPresaleService.getCarriers().subscribe(
      data => {
        this.carriers = data;
      },
      (error: HttpErrorResponse) => {
        console.log('error getCarriers', error);
      }
    );
  }

  carrierChange() {
    this.cxRivalry.emit(this.formG.get('carrier').value);

    if (this.isValuated) {
      this.setValuated.emit(false);
    }
  }

  paramChange() {
    if (this.isValuated) {
      if (this.baseFareDesiredAux !== this.formG.get('baseFareDesired').value ||
          this.queuesAux !== this.formG.get('queues').value) {
            this.setValuated.emit(false);
          }
    }

    this.baseFareDesiredAux = this.formG.get('baseFareDesired').value;
    this.queuesAux = this.formG.get('queues').value;
  }

}

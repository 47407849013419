<div class="modal-header">
  <div class="row ml-0 mr-0">
    <div class="col-11 col-md-6 col-lg-6 col-xl-7 order-3 order-md-1 order-lg-1 order-xl-1 title">
      <p>{{ ('requestDetail' | translate) }}</p>
    </div>

    <div
      class="col-11 col-md-5 col-lg-5 col-xl-4 order-1 order-md-2 order-lg-2 order-xl-2 pt-0 pl-0 pb-0 pr-0 alert-waiver-latam alert"
      [ngClass]="{  'alert-warning': tab === 'pendientes' }">
      <div class="alert-icon"
        [ngClass]="{ 'alert-icon-warning': tab === 'pendientes'}">
        <ng-container *ngIf="tab === 'pendientes'">
          <i class="material-icons">query_builder</i>
        </ng-container>
      </div>
      <div class="alert-text w-100"
        [ngClass]="{ 'alert-text-warning': tab === 'pendientes' }">
        <ng-container *ngIf="tab === 'pendientes'">
          <p class="mb-0">{{ 'pending' | translate }}</p>
        </ng-container>
      </div>
    </div>

    <div class="col-1 order-2 order-md-3 order-lg-3 order-xl-3 pr-0">
      <div class="close-modal">
        <button class="btn-close-modal" (click)="closeDialog()">
          <mat-icon class="close-icon">clear</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="tab == 'pendientes' && profiles.updateWaiverPostSaleManagement">
  <div class="btn-div">
    <div  class="divFooter">
      <div class="row ml-1 mr-1 justify-content-center">

      <p class="reject-text">{{ ('requestReject' | translate) }}</p>

      </div>
      <mat-card class="edit-waiver">
        <mat-card-content>
          <mat-tab-group #tabGroup class="tab-group"
            [ngClass]="{'tab-min': approveReject, 'tab-max': !approveReject }"
            (selectChange)="toggleShowTab('divFooter')">

            <mat-tab>
              <ng-template mat-tab-label>
                <div (click)="toggleShowTab('divFooter')">{{ 'button.deny' | translate }}</div>
              </ng-template>
                  <form #rechazoWaiverForm="ngForm">
                    <div class="row mt-4 ml-3 mr-3 ml-0 mr-0">
                      <div class="col-6 col-md-4 col-lg-4 col-xl-4" id="reject-list-waivers-motive">
                        <mat-form-field>
                          <mat-label>{{ 'field.reason' | translate }}</mat-label>
                          <mat-select ngDefaultControl name="motivo" #motive="ngModel" required [(ngModel)]="selectedMotive">
                            <mat-option *ngFor="let motive of motives" [value]="motive">{{ motive.name }}
                            </mat-option>
                          </mat-select>
                          <mat-error
                            *ngIf="motive.errors && (motive.dirty || motive.touched) && motive.errors.required">
                            {{ 'field.error.selectReasonRejection' | translate }}</mat-error>
                        </mat-form-field>
                      </div>

                      <div class="col-6 col-md-4 col-lg-4 col-xl-4" id="reject-list-waivers-rejectionComment">
                        <ng-container *ngIf="selectedMotive.id != -1">
                          <textarea matInput name="rejectionComment" #messageRechazar maxlength="500" [required]="selectedMotive.id == 4"
                            placeholder="{{ 'field.enterComment' | translate }}" [(ngModel)]="rejectionComment" class="textarea-approve"></textarea>
                          <mat-hint class="comment-hint">{{ messageRechazar.value.length }} / 500</mat-hint>
                        </ng-container>
                      </div>

                      <div class="col-6 col-md-4 col-lg-4 col-xl-4 offset-6 offset-md-0 offset-lg-0 offset-xl-0">
                        <div class="row ml-0 mr-0 justify-content-center" id="reject-list-waivers-confirmRejection">
                          <div class="col-12 col-lg-10 col-xl-10 pl-0 pr-0 text-center">
                            <mat-checkbox ngDefaultControl id="rejectCheck" name="confirmRejection" type="checkbox"
                              [(ngModel)]="confirmRejection">
                              {{ 'field.confirm' | translate }}</mat-checkbox>
                          </div>
                        </div>

                        <div class="row ml-0 mr-0 justify-content-center" id="reject-list-waivers-rejectWaiverButton">
                          <div class="col-12 col-lg-10 col-xl-10 pl-0 pr-0 "
                            *ngIf="profiles.updateWaiverPostSaleManagement">
                            <button mat-raised-button id="rejectWaiverButton"
                              [disabled]="!confirmRejection || selectedMotive.id == -1 || rechazoWaiverForm.invalid"
                              (click)="rejectWaiver()"
                              class="background-red w-100">{{ 'button.denyRequest' | translate }}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </mat-tab>
          </mat-tab-group>
        </mat-card-content>
      </mat-card>
        </div>

        <button mat-raised-button *ngIf="tab == 'pendientes'"
          (click)="approveReject = !approveReject; toggleShowDiv('divFooter')" class="hide-button">
          <mat-icon matSuffix>{{ approveReject ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
        </button>
      </div>

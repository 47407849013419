import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NameSeekerComponent } from './name-seeker/name-seeker.component';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ShareModule } from '../share-module/share.module';
import { TermsConditionsModule } from '../terms-conditions-module/terms-conditions.module';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule, RouterOutlet } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FormCorrectnameComponent } from './form-correctname/form-correctname.component';
import { ModalValidateCorrectNameComponent } from './modal-validate-correct-name/modal-validate-correct-name.component';
import { SuccessfulNameChangeComponent } from './successful-name-change/successful-name-change.component';
import { ModalErrorCorrectNameComponent } from './modal-error-correct-name/modal-error-correct-name.component';
import { ReportComponent } from './report/report.component';
import { ModalInformationTypepnrComponent } from './modal-information-typepnr/modal-information-typepnr.component';

@NgModule({
  declarations: [
    NameSeekerComponent,
    FormCorrectnameComponent,
    ModalValidateCorrectNameComponent,
    SuccessfulNameChangeComponent,
    ModalErrorCorrectNameComponent,
    ReportComponent,
    ModalInformationTypepnrComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatChipsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    ShareModule,
    TermsConditionsModule,
    MatFormFieldModule,
    NgxMaskDirective, 
    NgxMaskPipe,
    MatProgressSpinnerModule,
    RouterModule,
    MatSidenavModule
  ]
})
export class CorrectNameModule { }

export class FeedbackCSV {
  public home: string;
  public date: string;
  public evaluation: number;
  public comment: string;

  public constructor(
    home: string,
    date: string,
    evaluation: number,
    comment: string) {
      this.home = home;
      this.date = date;
      this.evaluation = evaluation;
      this.comment = comment;
  }
}

<hr>

<ng-container>
  <div *ngIf="loading" class="loader loading">
    <div class="loading-div">
      <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
      <span class="loading-span">{{ 'loading' | translate }}</span>
    </div>
  </div>
  <ng-container *ngIf="(!loading && form != null)" id="containerForm1">
    <div class="row" style="align-items: baseline">
      <span _ngcontent-c13="" class="title">{{'statusTicket.estado' | translate}}</span>
    </div>
    <div class="row" style="align-items: baseline">
      <div class="mt-2 col-md-6 col-lg-6 col-sm-12">
        <mat-form-field class="example-full-width">
          <mat-label>{{'statusTicket.tktNmbr' | translate}}</mat-label>
          <input type="text" matInput [value]="statusTicket[0].tktnbr" readonly />
        </mat-form-field>
      </div>
      <div class="mt-2 col-md-6 col-lg-6 col-sm-12">
        <mat-form-field class="example-full-width">
          <mat-label>{{'statusTicket.pnr' | translate}}</mat-label>
          <input type="text" matInput [value]="statusTicket[0].pnr" readonly />
        </mat-form-field>
      </div>
      <div class="mt-2 col-md-6 col-lg-6 col-sm-12">
        <mat-form-field class="example-full-width">
          <mat-label>{{'statusTicket.issuing_type' | translate}}</mat-label>
          <input type="text" matInput [value]="statusTicket[0].issuing_type" readonly />
        </mat-form-field>
      </div>
      <div class="mt-2 col-md-6 col-lg-6 col-sm-12">
        <mat-form-field class="example-full-width">
          <mat-label>{{'statusTicket.issDate' | translate}}</mat-label>
          <input type="text" matInput [value]="statusTicket[0].iss_date" readonly />
        </mat-form-field>
      </div>
      <div class="mt-2 col-md-6 col-lg-6 col-sm-12">
        <mat-form-field class="example-full-width">
          <mat-label>{{'statusTicket.iata' | translate}}</mat-label>
          <input type="text" matInput [value]="statusTicket[0].iata_agency8" readonly />
        </mat-form-field>
      </div>
      <div class="mt-2 col-md-6 col-lg-6 col-sm-12">
        <mat-form-field class="example-full-width">
          <mat-label>{{'statusTicket.ldr_fare' | translate}}</mat-label>
          <input type="text" matInput [value]="statusTicket[0].ldr_fare" readonly />
        </mat-form-field>
      </div>
    </div>
    <table class="table table-hover table-bordered ">
      <thead>
        <tr>
          <th>N°</th>
          <th>{{'statusTicket.route' | translate}}</th>
          <th>{{'statusTicket.conexion' | translate}}</th>
          <th>{{'statusTicket.fltOperation' | translate}}</th>
          <th>{{'statusTicket.scheduledDate' | translate}}</th>
          <th>{{'statusTicket.farebasis_code' | translate}}</th>
          <th>{{'statusTicket.ticketStatus' | translate}}</th>
          <th>{{'statusTicket.newMastTkt' | translate}}</th>
          <th>{{'statusTicket.mastTkt' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let status of statusTicket">
          <tr>
            <td>{{ status.coupon }}</td>
            <td>{{ status.route }}</td>
            <td>{{ status.routing_seg }}</td>
            <td>{{ status.oper_flight }}</td>
            <td>{{ status.scheduled_dep_date }}</td>
            <td>
              <button mat-raised-button value="{{ status.farebasis_code }}" [disabled]="status.farebasis_code.length < 8" (click)="openDialog(status.farebasis_code)">{{
                status.farebasis_code }}</button>
            </td>
            <td>{{ status.coupon_status_code }}</td>
            <td>{{ status.new_msttkt }}</td>
            <td>{{ status.msttkt }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </ng-container>

</ng-container>
<div class="mt-4 mb-3">
  <div class="row ml-3 mr-3">
    <div class="col">
      <ng-container *ngIf="tab === 'approved'">
        <span class="subtitle">{{ 'approvalInformation' | translate }}</span>
      </ng-container>
      <ng-container *ngIf="tab === 'issued'">
        <span class="subtitle">{{ 'issuedInformation' | translate }}</span>
      </ng-container>
      <ng-container *ngIf="tab === 'expired'">
        <span class="subtitle">{{ 'expiredInformation' | translate }}</span>
      </ng-container>
      <ng-container *ngIf="tab === 'rejected'">
        <span class="subtitle">{{ 'denialInformation' | translate }}</span>
      </ng-container>
    </div>
  </div>

  <div class="row ml-3 mr-3">
    <div class="col">
      <mat-table #table [dataSource]="dataSource" class="table-info mat-elevation-z1">
        <ng-container matColumnDef="creationDate">
          <ng-container *ngIf="tab==='issued' || tab === 'approved'">
            <mat-header-cell *matHeaderCellDef>{{ 'field.approvalDateHour' | translate }}</mat-header-cell>
          </ng-container>
          <ng-container *ngIf="tab==='pending'">
            <mat-header-cell *matHeaderCellDef>{{ 'field.requestDateHour' | translate }}</mat-header-cell>
          </ng-container>
          <mat-cell *matCellDef="let element">{{ element.creationDate | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="statusDate">
          <ng-container *ngIf="tab==='issued'">
            <mat-header-cell *matHeaderCellDef>{{ 'field.issuedDateHour' | translate }}</mat-header-cell>
          </ng-container>
          <ng-container *ngIf="tab==='expired'">
            <mat-header-cell *matHeaderCellDef>{{ 'field.expiredDateHour' | translate }}</mat-header-cell>
          </ng-container>
          <ng-container *ngIf="tab==='rejected'">
            <mat-header-cell *matHeaderCellDef>{{ 'field.denialDateHour' | translate }}</mat-header-cell>
          </ng-container>
          <mat-cell *matCellDef="let element">{{ element.statusDate | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="rejectionMotive">
          <mat-header-cell *matHeaderCellDef>{{ 'field.denialReason' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.rejectionMotive | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="statusComment">
          <mat-header-cell *matHeaderCellDef>{{ 'field.remarks' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.statusComment | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="folio">
          <mat-header-cell *matHeaderCellDef>{{ 'field.waiverRecord' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.folio | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="paxOriginalBaseFare">
          <span class="cdk-visually-hidden" #messageOriginalBaseFare>{{ 'field.withoutQAndTaxes' | translate }}</span>
          <mat-header-cell *matHeaderCellDef [matTooltip]="messageOriginalBaseFare.innerHTML">{{ 'tooltip.originalRateUsd' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.paxOriginalBaseFare | number: '1.2-2':'en-US' | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="paxBaseFare">
          <span class="cdk-visually-hidden" #messageBaseFare>{{ 'field.withoutQAndTaxes' | translate }}</span>
          <mat-header-cell *matHeaderCellDef [matTooltip]="messageBaseFare.innerHTML">{{ 'tooltip.approvedRateUsd' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.paxBaseFare | number: '1.2-2':'en-US' | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="discountRate">
          <mat-header-cell *matHeaderCellDef>{{ 'field.discount' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.discountRate | dashWhenEmpty }}<span *ngIf="element.discountRate">%</span></mat-cell>
        </ng-container>

        <ng-container matColumnDef="valorization">
          <mat-header-cell *matHeaderCellDef>{{ 'field.waiverValueUsd' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.valorization | number: '1.2-2':'en-US' | dashWhenEmpty }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" class="waiver-info-row"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="waiver-info-row"></mat-row>
      </mat-table>
    </div>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { PiaAgencyType } from '../models/pia-agency-type.model';
import { PiaCode } from '../models/piaCode.model';
import { StorageService } from './storage.service';


@Injectable()
export class AgencyPiaService {
  urlBase = `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlAgency}`;

  constructor(private http: HttpClient) { }

  getAgencyPiaTypesList(): Observable<PiaAgencyType[]> {
    return this.http.get<PiaAgencyType[]>(`${this.urlBase}/pia/agency/type`);
  }

  getPiaCodes(agencyGroupId: number, branchOfficeId: number): Observable<PiaCode[]> {
    if (branchOfficeId) {
      return this.http.get<PiaCode[]>(`${this.urlBase}/pia/agency/iata/codes?branchOfficeId=${branchOfficeId}`);
    } else {
      return this.http.get<PiaCode[]>(`${this.urlBase}/pia/agency/iata/codes?matrixId=${agencyGroupId}`);
    }
  }
}

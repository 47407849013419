import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyPositiveNumber]'
})
export class OnlyPositiveNumberDirective {
  // Allow positive numbers
  private regex: RegExp = new RegExp(/^[0-9]+$/g);
  private specialKeys: Array<string> = [
    'ArrowLeft',
    'ArrowRight',
    'Backspace',
    'Delete',
    'End',
    'Home',
    'Tab'
  ];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    // Allow specialKeys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    if (
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey))) {
        return;
      }

      const current: string = this.el.nativeElement.value;
      const next: string = current.concat(event.key);

      if (next && !String(next).match(this.regex)) {
        event.preventDefault();
      }
  }
}

<div class="row title">
  <div class="col text-center">{{ 'agencyGroupBudget' | translate }}</div>
</div>
<mat-table #table [dataSource]="dataSource">
  <ng-container matColumnDef="name">
    <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="currentAmount">
    <mat-cell *matCellDef="let element">{{ 'USD' + ' ' + (element.currentAmount | number) }}</mat-cell>
  </ng-container>

  <mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"
  (mouseover)="highlightRow($event, i)" (mouseout)="highlightRow($event, i)" [ngClass]="{'table-header': row.name === 'TOTAL', 'highlight': selectedRow === i }"></mat-row>
</mat-table>
<ng-container>
  <div class="row">
    <div class="col">
      <p class="parrafo-login" [innerHTML]="'login.msg' | translate"></p>
    </div>
  </div>
  
  <div class="row justify-content-center">
    <div class="col-sm-8 col-md-8 col-lg-6 col-xl-6">
      <div #loginGoogleBtn id="buttonDiv" ></div>
    </div>
  </div>
  
  
</ng-container>
<div class="container-filters">
  <form [formGroup]="formFilter" (ngSubmit)="sendFilterData()">

    <ng-container *ngIf="item.id">
      <div class="container-filter col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
        <mat-form-field>
          <mat-label>{{ 'field.idRequest' | translate }}</mat-label>
          <input type="text" matInput formControlName="id">
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngIf="item.type">
      <div class="container-filter col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
        <mat-form-field>
          <mat-label>{{ 'field.type' | translate  }}</mat-label>
          <input type="text" matInput formControlName="type">
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngIf="item.pnr">
      <div class="container-filter col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
        <mat-form-field>
          <mat-label>{{ 'field.pnr' | translate }}</mat-label>
          <input type="text" matInput formControlName="pnr">
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngIf="item.agency">
      <div class="container-filter col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
        <mat-form-field>
          <mat-label>{{ 'field.agency' | translate  }}</mat-label>
          <input type="text" matInput formControlName="agency">
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngIf="item.executive">
      <div class="container-filter col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
        <mat-form-field>
          <mat-label>{{ 'field.executiveSale' | translate }}</mat-label>
          <input type="text" matInput formControlName="executive">
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngIf="item.applicant">
      <div class="container-filter col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
        <mat-form-field>
          <mat-label>{{ 'field.requestedBy' | translate }}</mat-label>
          <input type="text" matInput formControlName="applicant">
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngIf="item.statusDateFrom">
      <div class="container-filter col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
        <mat-form-field *ngIf="tab === 'issued'">
          <mat-label>{{ 'field.issuedDateHour' | translate}}</mat-label>
          <input matInput [matDatepicker]="statusDateFrom" formControlName="statusDateFrom">
          <mat-datepicker-toggle matSuffix [for]="statusDateFrom"></mat-datepicker-toggle>
          <mat-datepicker #statusDateFrom></mat-datepicker>
        </mat-form-field>
        <mat-form-field *ngIf="tab === 'expired'">
          <mat-label>{{ 'field.expiredDateHour' | translate}}</mat-label>
          <input matInput [matDatepicker]="statusDateFrom" formControlName="statusDateFrom">
          <mat-datepicker-toggle matSuffix [for]="statusDateFrom"></mat-datepicker-toggle>
          <mat-datepicker #statusDateFrom></mat-datepicker>
        </mat-form-field>
        <mat-form-field *ngIf="tab === 'rejected'">
          <mat-label>{{ 'field.denialDateHour' | translate}}</mat-label>
          <input matInput [matDatepicker]="statusDateFrom" formControlName="statusDateFrom">
          <mat-datepicker-toggle matSuffix [for]="statusDateFrom"></mat-datepicker-toggle>
          <mat-datepicker #statusDateFrom></mat-datepicker>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngIf="item.statusDateTo">
      <div class="container-filter col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
        <mat-form-field *ngIf="tab === 'issued'">
          <mat-label>{{ 'field.issuedDateHour' | translate}}</mat-label>
          <input matInput [matDatepicker]="statusDateTo" formControlName="statusDateTo">
          <mat-datepicker-toggle matSuffix [for]="statusDateTo"></mat-datepicker-toggle>
          <mat-datepicker #statusDateTo></mat-datepicker>
        </mat-form-field>
        <mat-form-field *ngIf="tab === 'expired'">
          <mat-label>{{ 'field.expiredDateHour' | translate}}</mat-label>
          <input matInput [matDatepicker]="statusDateTo" formControlName="statusDateTo">
          <mat-datepicker-toggle matSuffix [for]="statusDateTo"></mat-datepicker-toggle>
          <mat-datepicker #statusDateTo></mat-datepicker>
        </mat-form-field>
        <mat-form-field *ngIf="tab === 'rejected'">
          <mat-label>{{ 'field.denialDateHour' | translate}}</mat-label>
          <input matInput [matDatepicker]="statusDateTo" formControlName="statusDateTo">
          <mat-datepicker-toggle matSuffix [for]="statusDateTo"></mat-datepicker-toggle>
          <mat-datepicker #statusDateTo></mat-datepicker>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngIf="item.flightDateFrom">
      <div class="container-filter col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
        <mat-form-field>
          <mat-label>{{ 'field.flightDate' | translate  }}</mat-label>
          <input type="text" matInput formControlName="flightDateFrom">
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngIf="item.flightDateTo">
      <div class="container-filter col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
        <mat-form-field>
          <mat-label>{{ 'field.flightDate' | translate  }}</mat-label>
          <input type="text" matInput formControlName="flightDateTo">
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngIf="item.valueWaiver">
      <div class="container-filter col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
        <mat-form-field>
          <mat-label>{{ 'field.waiverValueUsd' | translate }}</mat-label>
          <input type="text" matInput formControlName="valueWaiver">
        </mat-form-field>
      </div>
    </ng-container>



    <div class="container-butttons col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
        <button mat-raised-button class="container-filter button-color" (click)="cleanForm()">Limpiar </button>
        <button mat-raised-button class="container-filter button-color" type="submit">Filtrar </button>
      </div>
    <div style="clear: both;"></div>
  </form>
</div>

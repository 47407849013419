<div class="container">
    <div class="content-container">

        <img  src="assets/images/waiver-latam/wallet.svg" alt="wallet-img"/>
        
        <h1>{{ 'waiverLatam.welcomeMessage1' | translate}}<br>{{ 'waiverLatam.welcomeMessage2' | translate}}<b> {{ 'waiverPolicy.moduleName' | translate }}!</b></h1>

        <h2>{{'waiverLatam.aboutNewModule' | translate}}</h2>


        <div class="info">

          <div class="p-container">
            <p [innerHTML]="'waiverPolicy.moduleInfo' | translate"></p>
          </div>


          <div class="list" style="font-family: 'LatamSansBold', sans-serif;">
            <ul>
              <li>{{ 'waiverPolicy.motives.personalEvent' | translate}}</li>
              <li>{{ 'waiverPolicy.motives.medicalReasons' | translate}}</li>
              <li>{{ 'waiverPolicy.motives.bookingErrors' | translate}}</li>
              <li>{{ 'waiverPolicy.motives.documentationIssues' | translate}}</li>
            </ul>
          </div>

          <div class="p-container" style="width: 100%;">
            <p style="font-family: 'LatamSansBold',sans-serif;">{{ 'waiverPolicy.availableWaiverTypes' | translate}}</p>
          </div>

          <div class="p-container">
              <p><b>{{ 'waiverLatam.homeType1' | translate }}</b>{{ 'waiverLatam.homeType1Description' | translate }}</p>
          </div>
          <div class="p-container">
              <p><b>{{ 'waiverLatam.homeType2' | translate }}</b>{{ 'waiverLatam.homeType2Description' | translate }}</p>
          </div>
          <div class="p-container">
              <p><b>{{ 'waiverLatam.homeSeriesGroupsImportant' | translate }}</b>{{ 'waiverLatam.homeSeriesGroupsMessage' | translate }}</p>
          </div>            
      </div>
      <br>
    </div>
</div>